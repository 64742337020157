import Entity from './entity'

const fields = {
  RotationCardImageID: {
    type: Number
  },
  RotationCardID: {
    type: Number
  },
  Extension: {
    type: String
  },
  MimeType: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  }
}

const RotationCardImage = new Entity('RotationCardImage', fields)

RotationCardImage.createMutation(
  'insert',
  'rotationCardImageAdd',
  Entity.onlyKeys(fields, ['RotationCardID']),
  Entity.onlyKeys(fields, ['RotationCardImageID'])
)

RotationCardImage.createMutation(
  'delete',
  'rotationCardImageDelete',
  Entity.onlyKeys(fields, ['RotationCardID', 'RotationCardImageID']),
  []
)

RotationCardImage.createMutation(
  'email',
  'rotationCardEmailImages',
  {
    To: {
      type: String
    },
    ToName: {
      type: String
    },
    Sender: {
      type: String
    },
    SenderName: {
      type: String
    },
    Body: {
      type: String
    },
    Subject: {
      type: String
    },
    Images: {
      type: Array
    }
  },
  []
)

RotationCardImage.createMutation(
  'update',
  'rotationCardImageUpdate',
  {
    RotationCardImageID: {
      type: Number
    },
    Rotation: {
      type: Number
    },
    Crop: {
      type: Object,
      fields: {
        top: {
          type: Number
        },
        bottom: {
          type: Number
        },
        left: {
          type: Number
        },
        right: {
          type: Number
        }
      }
    }
  },
  {
    RotationCardImageID: {
      type: Number
    },
    Extension: {
      type: String
    },
    MimeType: {
      type: String
    },
    AuditAmendedDate: {
      type: String
    }
  }
)

export default RotationCardImage

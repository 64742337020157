import Entity from './entity'

export const fields = {
  ProducerID: {
    type: Number
  },
  ProducerName: {
    type: String
  },
  ProducerCat: {
    type: Number
  },
  ProducerOrder: {
    type: Number
  },
  ProducerCode: {
    type: String
  },
  WineCardUsage: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  UseOnBackOffice: {
    type: Boolean
  }
}

const Producer = new Entity('Producer', fields)

Producer.createQuery('get', 'producerGet', fields)
Producer.createQuery('search', 'producerSearch', fields)

Producer.createMutation('insert', 'producerAdd', fields)
Producer.createMutation(
  'update',
  'producerUpdate',
  Entity.allButKeys(fields, ['WineCardUsage']),
  fields
)
Producer.createMutation(
  'delete',
  'producerDelete',
  {
    ID: {
      type: Number
    },
    AuditAmendedDate: fields.AuditAmendedDate
  },
  []
)

Producer.on('post-query', producer => {
  producer.WineCardUsage =
    producer.WineCardUsage === null ? 0 : producer.WineCardUsage
})

export default Producer

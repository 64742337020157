import { getSalesOrders } from '~/api/salesOrders/oversold'

export const state = () => ({
  loading: false,
  salesOrders: []
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSalesOrders(state, salesOrders) {
    state.salesOrders = salesOrders
  }
}

export const actions = {
  async getSalesOrders({ commit }) {
    try {
      commit('setLoading', true)
      const response = await getSalesOrders()
      commit('setSalesOrders', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

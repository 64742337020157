import Entity from './entity'

export const fields = {
  WineColourID: {
    type: Number
  },
  WineColour: {
    type: String
  }
}

const WineColourDropDown = new Entity('WineColourDropDown', fields)
WineColourDropDown.createQuery('get', 'wineColourDropDown')

export default WineColourDropDown

<template>
  <div class="d-flex justify-content-center align-items-center mb-3">
    <b-form-group
      class="mx-3 mb-0"
      label-size="sm"
      label-cols="4"
      label="Wine Filter"
      label-for="wine-filter"
    >
      <b-form-input
        id="wine-filter"
        v-model="wineFilter"
        type="text"
        size="sm"
        :disabled="loading"
        :class="wineFilterClass"
      />
    </b-form-group>
    <b-form-group
      class="mx-3 mb-0"
      label-size="sm"
      label-cols="4"
      label="Note Filter"
      label-for="note-filter"
    >
      <b-form-input
        id="note-filter"
        v-model="noteFilter"
        type="text"
        size="sm"
        :disabled="loading"
        :class="noteFilterClass"
      />
    </b-form-group>
    <b-form-group
      class="mx-3 mb-0"
    >
      <b-form-checkbox
        v-model="stockedWinesOnlyFilter"
        type="text"
        size="sm"
        :disabled="loading"
        :class="stockedWinesOnlyFilterClass"
      >
        Stocked Wines Only
      </b-form-checkbox>
    </b-form-group>
    <b-button :disabled="loading" class="btn wv-btn ml-3 mr-3" size="sm" @click="clearSearch">
      Clear Filters
    </b-button>
   
    <b-spinner type="grow" label="Loading..." />
    
    <b-button class="btn wv-btn mr-0 ml-auto" size="sm" @click="handleExit">
      Exit
    </b-button>
  </div>
</template>

<script>
import { debounce } from 'lodash'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      debounceHandler: null
    }
  },
  computed: {
    wineFilter: {
      get() {
        return this.$store.state.wineCard.notes.wineFilter
      },
      set(value) {
        this.$store.commit('wineCard/notes/setWineFilter', value)
        this.debounceHandler()
      }
    },
    noteFilter: {
      get() {
        return this.$store.state.wineCard.notes.noteFilter
      },
      set(value) {
        this.$store.commit('wineCard/notes/setNoteFilter', value)
        this.debounceHandler()
      }
    },
    stockedWinesOnlyFilter: {
      get() {
        return this.$store.state.wineCard.notes.stockedWinesOnlyFilter
      },
      set(value) {
        this.$store.commit('wineCard/notes/setStockedWinesOnly', value)
        this.debounceHandler()
      }
    },
    wineFilterClass() {
      return {
        red: this.wineFilter.length > 0
      }
    },
    noteFilterClass() {
      return {
        red: this.noteFilter.length > 0
      }
    },
    stockedWinesOnlyFilterClass() {
      return {
        red: this.stockedWinesOnlyFilter
      }
    }
  },
  mounted() {
    this.debounceHandler = debounce(this.handleSearch, 500)
  },
  methods: {
    clearSearch() {
      this.$store.commit('wineCard/notes/resetFilters')
      this.debounceHandler()
    },
    handleExit() {
      this.$emit('exit')
    },
    handleSearch() {
      if (this.loading === false) {
        this.$emit('search')
      }
    }
  }
}
</script>

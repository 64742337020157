import { getUnallocatedSearch } from '../../api/etally'

export const state = () => ({
  loading: false,
  results: [],
  selected: null
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  resetResults(state) {
    state.results = []
    state.selected = null
  },
  setResults(state, results) {
    state.results = results
  },
  setSelected(state, selectedSupplier) {
    state.selected = selectedSupplier
  }
}

export const actions = {
  async search({ commit }, search) {
    commit('resetResults')
    commit('setLoading', true)
    try {
      const result = await getUnallocatedSearch(search)
      if (result) {
        commit('setResults', result)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

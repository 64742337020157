import Entity from './entity'
import { fields as allocField } from './purchaseorderallocation'
import PurchaseOrderTransaction from './purchaseordertransaction'
import PurchaseOrderFields from './fields/purchaseOrder'

export const fields = {
  InventoryID: {
    type: Number
  },
  InventoryStatusID: {
    type: Number
  },
  PurchaseOrderLineID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  Bottles: {
    type: Number
  },
  DutyStatusID: {
    type: Number
  },
  IsVAT: {
    type: Boolean
  },
  WineUsageCodeID: {
    type: Number
  },
  RotationCardID: {
    type: Number
  },
  RotationNumber: {
    type: String
  },
  LCBLocation: {
    type: String
  },
  Price: {
    type: Number
  },
  Description: {
    type: String
  },
  OriginalPackaging: {
    type: Boolean
  },
  OrigPkgCaseSize: {
    type: Number
  },
  DateOversold: {
    type: String
  },
  OversellKey: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  Allocation: {
    type: Object,
    fields: allocField
  },
  PurchaseOrderTransaction: {
    type: Object,
    fields: PurchaseOrderTransaction.fields
  },
  PurchaseOrder: {
    type: Object,
    fields: PurchaseOrderFields
  },
  Allocations: {
    type: Array,
    fields: allocField
  },
  UsageCode: {
    type: String
  }
}

const Inventory = new Entity('Inventory', fields)
Inventory.createQuery('getByRotationNumber', 'inventoryGetByRotationNumber')
Inventory.createQuery('get', 'inventoryGet', fields)

Inventory.createMutation('update', 'inventoryUpdate', fields)
Inventory.createMutation(
  'create',
  'inventoryAdd',
  {
    ...fields,
    Abv: {
      type: Number
    },
    AbvVerified: {
      type: Number
    }
  },
  fields
)

Inventory.createMutation(
  'delete',
  'inventoryDelete',
  Entity.onlyKeys(fields, ['InventoryID', 'AuditAmendedDate']),
  Entity.onlyKeys(fields, [
    'InventoryID',
    'PurchaseOrderTransaction',
    'PurchaseOrder',
    'Allocations'
  ])
)

export default Inventory

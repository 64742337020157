import Entity from '~/models/entity'

export const fields = {
  SPGID: {
    type: Number
  },
  Name: {
    type: String
  },
  PricePer9L: {
    type: Number
  },
  FreeMonths: {
    type: Number
  },
  ExternalFreeMonths: {
    type: Number
  },
  Default: {
    type: Boolean
  }
}

const contactFields = {
  Contacts: {
    type: Array,
    fields: {
      ContactID: {
        type: Number
      },
      ContactName: {
        type: String
      },
      CompanyName: {
        type: String
      }
    }
  }
}

const StoragePricingGroup = new Entity('StoragePricingGroup', fields)

StoragePricingGroup.createQuery(
  'getAll',
  'storagePricingGroupGetAll',
  {
    total: {
      type: Number
    },
    storagePricingGroupPaginatedResults: {
      type: Array,
      fields: {
        ...fields,
        ...contactFields
      }
    }
  },
  {
    PerPage: {
      type: Number
    },
    Page: {
      type: Number
    }
  }
)

StoragePricingGroup.createMutation('add', 'storagePricingGroupAdd', fields, {
  ...fields,
  ...contactFields
})

StoragePricingGroup.createMutation(
  'update',
  'storagePricingGroupUpdate',
  fields,
  {
    ...fields,
    ...contactFields
  }
)

StoragePricingGroup.createMutation(
  'delete',
  'storagePricingGroupDelete',
  {
    SPGID: {
      type: Number
    }
  },
  []
)

export default StoragePricingGroup

import gql from 'graphql-tag'
import { client } from '../graphQLInstance'

export const getStockWineCard = async id => {
  // prettier-ignore
  const wantedCard = await client.query({
    query: gql`
        query RootQueryType{
          stockCardGet(
          RecordId: ${id}) {
            StockCardID
            Description
            SubDescription
            WineVintage
            WineSellingPrice
            WinePriceWeightedAverage
            WineLooseWeightedAverage
            MaxStockPrice
            TotalBottlesAvailable
            TotalBottlesAvailableReserves
            TotalBottlesAvailableBroking
            TotalBottlesOversold
            TotalBottlesNonPub
            TotalBottlesAvailableNonPub
            WineName
            CaseSize
            WineParkerScore
            WineParkerTastingNotes
            WineBurghoundScore
            WineBurghoundTastingNotes
            EmptyCaseInfo
            ReservedBottles,
            LooseBottlePrice,
            FormatName
          }
        }
      `
  })

  return {
    stockCard: wantedCard.data.stockCardGet
  }
}

import { findIndex } from 'lodash'
import Vue from 'vue'
import {
  getEmptyCasesForWineCardID,
  getEmptyCasesForWineCardIDAndPOLID,
  addEmptyCase,
  updateEmptyCase,
  deleteEmptyCase,
  attachEmptyCase,
  detachEmptyCase
} from '@/api/wineCard/emptyCases'

export const state = () => ({
  loading: false,
  saving: false,
  error: null,
  cases: [],
  locationPrefixes: [
    {
      value: 'CUB',
      text: 'CUB'
    },
    {
      value: 'OWC',
      text: 'OWC'
    },
    {
      value: '40W',
      text: '40W'
    }
  ]
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading
  },
  setSaving(state, saving) {
    state.saving = saving
  },
  setError(state, error) {
    state.error = error
  },
  setCases(state, cases) {
    state.cases = cases
  },
  appendCase(state, emptyCase) {
    state.cases.push(emptyCase)
  },
  replaceCase(state, emptyCase) {
    const idx = findIndex(state.cases, { EmptyCaseID: emptyCase.EmptyCaseID })
    if (idx < 0) {
      state.cases.push(emptyCase)
    } else {
      Vue.set(state.cases, idx, emptyCase)
    }
  },
  removeCase(state, emptyCase) {
    const idx = findIndex(state.cases, { EmptyCaseID: emptyCase.EmptyCaseID })
    if (idx >= 0) {
      Vue.delete(state.cases, idx)
    }
  }
}

export const actions = {
  async getForWineCard({ commit }, wineCardId) {
    try {
      commit('setError', null)
      commit('setLoading', true)
      const result = await getEmptyCasesForWineCardID(wineCardId)
      commit('setCases', result)
      return result
    } catch (e) {
      commit('setError', e)
    } finally {
      commit('setLoading', false)
    }
  },
  async getForWineCardAndPolid({ commit }, args) {
    try {
      commit('setError', null)
      commit('setLoading', true)
      const result = await getEmptyCasesForWineCardIDAndPOLID(args)
      commit('setCases', result)
      return result
    } catch (e) {
      commit('setError', e)
    } finally {
      commit('setLoading', false)
    }
  },
  async addEmptyCase({ commit }, emptyCase) {
    try {
      commit('setError', null)
      commit('setSaving', true)
      const result = await addEmptyCase(emptyCase)
      commit('appendCase', result)
      return result
    } catch (e) {
      commit('setError', e)
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async attachEmptyCase({ commit }, emptyCase) {
    try {
      commit('setError', null)
      commit('setSaving', true)
      const result = await attachEmptyCase(emptyCase)
      commit('replaceCase', result)
      return result
    } catch (e) {
      commit('setError', e)
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async detachEmptyCase({ commit }, emptyCase) {
    try {
      commit('setError', null)
      commit('setSaving', true)
      const result = await detachEmptyCase(emptyCase)
      commit('replaceCase', result)
      return result
    } catch (e) {
      commit('setError', e)
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async updateEmptyCase({ commit }, emptyCase) {
    try {
      commit('setError', null)
      commit('setSaving', true)
      const result = await updateEmptyCase(emptyCase)
      commit('replaceCase', result)
      return result
    } catch (e) {
      commit('setError', e)
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async removeEmptyCase({ commit }, emptyCase) {
    try {
      commit('setError', null)
      commit('setSaving', true)
      const result = await deleteEmptyCase({
        ID: emptyCase.EmptyCaseID
      })
      commit('removeCase', emptyCase)
      return result
    } catch (e) {
      commit('setError', e)
      throw e
    } finally {
      commit('setSaving', false)
    }
  }
}

<template>
  <b-modal
    v-model="shown"
    size="md"
    modal-class="wv-modal"
    content-class="shadow"
    footer-class="justify-content-center"
    centered
  >
    <template v-slot:modal-header>
      View Producer
    </template>
    <producer-form view-mode :value="producer" />
    <template v-slot:modal-footer="{ ok }">
      <b-button class="btn wv-btn btn-sm" @click="ok()">
        OK
      </b-button>
      <b-button class="btn wv-btn btn-sm" @click="shown=false">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ProducerForm from '~/components/Forms/ProducerForm'

export default {
  components: {
    ProducerForm
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loadingProp: {
      type: Boolean,
      required: false,
      default: false
    },
    producer: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

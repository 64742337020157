<template>
  <div>
    <div v-if="loading" class="text-center text-primary my-2 mx-auto">
      <b-spinner type="grow" label="Loading..." />
    </div>
    <div v-if="error" show class="text-center text-danger">
      <p class="mb-1">{{ filteredError }}</p>
      <b-button class="btn wv-btn mb-2" @click="handleRetry">
        Retry
      </b-button>
    </div>
    <div v-if="emptyResults" class="text-center text-info">
      <p class="mb-1">Wine-Searcher failed to return results.</p>
      <b-button class="btn wv-btn mb-2" @click="handleRetry">
        Retry
      </b-button>
    </div>
    <vue-custom-scrollbar
      v-if="hasResults"
      class="virtual-scroll scroll-offsets"
      :settings="settings"
    >
      <virtual-table
        :fields="tableFields"
        scroller-class=""
        :items="sortedResults"
        :per-page="perPage"
        :total="total"
        :show-loading="true"
        :estimate-total-scroll-height="false"
        :next-load-request="10"
        :table-tr-class="getRowClass"
        primary-key="id"
        @request-page="fetchPage"
        @sort-changed="performOrderBy"
        @row-clicked="setActiveCard"
        @row-hovered="setHover"
      >
        <template slot="table-colgroup" slot-scope="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.width, maxWidth: field.width, overflow: 'hidden' }"
          >
        </template>
        <template slot="loading">
          <p class="text-center py-2">
            <b-spinner type="grow" label="Loading Please Wait" small />
          </p>
        </template>

        <template slot="sellerName" slot-scope="data">
          <a v-b-tooltip.hover :href="data.item.sellerUrl" target="_blank" :title="data.item.wineDescription">
            {{ data.item.sellerName }}
          </a>
        </template>
        <template slot="winePrice" slot-scope="data">
          <currency v-b-tooltip.hover :value="data.item.winePrice" :title="data.item.wineDescription" />
        </template>
        <template slot="caseInfo" slot-scope="data">
          <span v-b-tooltip.hover :class="caseClass(data.item)" :title="data.item.wineDescription">
            {{ data.item.caseInfo }}
          </span>
        </template>

        <template slot="caseFlags" slot-scope="data">
          <div class="d-flex gap-xs">
            <span
              v-for="flag in flagsArray(data.item.flags)"
              :key="flag"
              v-b-tooltip.hover
              :title="flagLabel(flag)"
              style="border-radius: 999px; display: block; color: #fefefe; height: 15px; width: 15px; font-size: 0.6rem; font-weight: bold; text-align: center; line-height: 15px;"
              :style="{backgroundColor: colours[flag]}"
            >
              {{ flag }}
            </span>
          </div>
        </template>

        <template slot="casePrice" slot-scope="data">
          <currency v-b-tooltip.hover :value="data.item.casePrice" :class="caseClass(data.item)" :title="data.item.wineDescription" />
        </template>
        <template slot="comparison" slot-scope="data">
          <currency v-b-tooltip.hover :value="data.item.comparison" :class="comparisonClass(data.item)" :title="data.item.wineDescription" />
        </template>
      </virtual-table>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { map, sortBy } from 'lodash'
import Currency from '~/components/Base/Currency'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'
import VirtualTable from '~/components/Base/VirtualTable'
export default {
  components: {
    Currency,
    VirtualTable,
    vueCustomScrollbar
  },
  props: {
    listPrice: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      default: process.env.paginate_per_page
    },
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    looseBottlePrice: {
      type: Number,
      default: null
    },
    looseView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      settings: {
        tagname: 'div',
        suppressScrollY: true
      },
      sortBy: 'casePrice',
      sortDesc: false,
      categories: {
        R: 'Retail',
        P: 'Producer',
        A: 'Auction',
        M: 'By Request',
        IB: 'In Bond',
        PA: 'Pre Arrival',
        EP: 'Enprimeur/ Futures',
        DP: 'Duty Paid'
      },
      colours: {
        DP: '#D32F2F',
        R: '#F57C00',
        A: '#FBC02D',
        P: '#388E3C',
        PA: '#00796B',
        IB: '#1976D2',
        M: '#512DA8',
        EP: '#5D4037'
      }
    }
  },
  computed: {
    ...mapState('wineCard/activeCard', {
      wineSearcher: state => state.wineSearcher,
      loading: state => state.wineSearcherLoading,
      error: state => state.wineSearcherError,
      errorMessage: state => state.wineSearcherErrorMessage,
      formatName: state => (state.activeCard ? state.activeCard.format : '')
    }),
    sortedResults() {
      const sortedResults = sortBy(this.extendedResults, this.sortBy)
      if (this.sortDesc) {
        return sortedResults.reverse()
      }
      return sortedResults
    },
    emptyResults() {
      return (
        this.loading === false &&
        this.error === false &&
        this.wineSearcher.length === 0
      )
    },
    hasResults() {
      return (
        this.loading === false &&
        this.error === false &&
        this.wineSearcher.length > 0
      )
    },
    extendedResults() {
      return map(this.wineSearcher, wine => {
        wine.comparison = this.looseView
          ? wine.winePrice - (this.looseBottlePrice || this.listPrice)
          : wine.casePrice - this.listPrice
        return wine
      })
    },
    tableFields() {
      return this.looseView
        ? [
            {
              key: 'sellerName',
              label: 'Seller',
              sortable: true
            },
            {
              key: 'casePrice',
              label: 'Case Price',
              sortable: true,
              tdClass: 'text-right',
              width: '150px'
            },
            {
              key: 'winePrice',
              label: 'Price',
              sortable: true,
              tdClass: 'text-right',
              width: '150px'
            },
            {
              key: 'comparison',
              label: 'Comparison',
              sortable: true,
              tdClass: 'text-right',
              width: '150px'
            }
          ]
        : [
            {
              key: 'sellerName',
              label: 'Seller',
              sortable: true
            },
            {
              key: 'winePrice',
              label: 'Price',
              sortable: true,
              tdClass: 'text-right',
              width: '150px'
            },
            {
              key: 'caseInfo',
              label: 'Case Info',
              sortable: true
            },
            {
              key: 'caseFlags',
              label: 'Flags',
              width: '50px'
            },
            {
              key: 'casePrice',
              label: 'Case Price',
              sortable: true,
              tdClass: 'text-right',
              width: '150px'
            },
            {
              key: 'comparison',
              label: 'Comparison',
              sortable: true,
              tdClass: 'text-right',
              width: '150px'
            }
          ]
    },
    filteredError() {
      return this.errorMessage &&
        this.errorMessage.hasOwnProperty('message') &&
        this.errorMessage.message.includes('Internal Server Error')
        ? 'Internal Server Error'
        : this.errorMessage
    }
  },
  mounted() {
    this.$emit('get-wine-searcher')
  },
  methods: {
    handleRetry() {
      this.$emit('get-wine-searcher')
    },
    fetchPage(page) {
      this.$emit('change-page', page)
    },
    comparisonClass(item) {
      return {
        green: item.comparison > 0,
        red: item.comparison < 0
      }
    },
    caseClass(item) {
      if (item.format !== this.formatName) {
        return 'red'
      } else if (item.casePrice !== item.winePrice) {
        return 'blue'
      }
      return ''
    },
    performOrderBy(sort) {
      this.$emit('change-order', sort.sortBy)
    },
    setActiveCard(item) {
      this.$emit('set-active-item', item)
    },
    setHover(item) {
      this.hoveredId = item.id
    },
    getRowClass(item) {
      if (!item.inBond) {
        return 'row-not-ib'
      }
      return ''
    },
    flagsArray(flags) {
      return flags.split(',')
    },
    flags(flags) {
      return this.flagsArray(flags)
        .map(key => this.categories[key])
        .join(', ')
        .trim()
    },
    flagLabel(flag) {
      if (this.categories[flag]) {
        return this.categories[flag]
      }

      return 'Unknown'
    }
  }
}
</script>

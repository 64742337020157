export const state = () => ({
  showPriceLookupModal: false,
  showEmptyCaseModal: false,
  showLooseBottlesModal: false,
  showReserveLevelModal: false,
  showWineNotesModal: false,
  showWineNotesCreateModal: false,
  showCurrentStockModal: false,
  showNewPurchaseOrderModal: false,
  showNewSalesOrderModal: false,
  showAllNotesModal: false,
  showFavouritesModal: false,
  showWineListExportSettingsModal: false,
  showWineListExportModal: false,
  showWineNotesEditModal: false,
  showContactsSearchModal: false,
  showEmailPromotionTemplateModal: false,
  showEmailPromotionModal: false,
  showExportEmailModal: false,
  salesOrderIsUkDelivery: false,
  showWineCardModal: false,
  wineBrowserSearchStore: 'wineBrowser'
})

export const mutations = {
  setShowPriceLookupModal(state, show) {
    state.showPriceLookupModal = show
  },
  setShowEmptyCaseModal(state, show) {
    state.showEmptyCaseModal = show
  },
  setShowLooseBottlesModal(state, show) {
    state.showLooseBottlesModal = show
  },
  setShowReserveLevelModal(state, show) {
    state.showReserveLevelModal = show
  },
  setShowWineNotesModal(state, show) {
    state.showWineNotesModal = show
  },
  setShowWineNotesCreateModal(state, show) {
    state.showWineNotesCreateModal = show
  },
  setShowCurrentStockModal(state, show) {
    state.showCurrentStockModal = show
  },
  setShowNewPurchaseOrderModal(state, show) {
    state.showNewPurchaseOrderModal = show
  },
  setShowNewSalesOrderModal(state, show) {
    if (typeof show === 'boolean') {
      state.showNewSalesOrderModal = show
    } else {
      state.salesOrderIsUkDelivery =
        show.isUkDelivery === undefined ? false : show.isUkDelivery
      state.showNewSalesOrderModal = true
    }
  },
  setShowAllNotesModal(state, show) {
    state.showAllNotesModal = show
  },
  setShowFavouritesModal(state, show) {
    state.showFavouritesModal = show
  },
  setShowWineListExportSettingsModal(state, show) {
    state.showWineListExportSettingsModal = show
  },
  setShowWineListExportModal(state, show) {
    state.showWineListExportModal = show
  },
  setShowWineNotesEditModal(state, show) {
    state.showWineNotesEditModal = show
  },
  setShowContactsSearchModal(state, show) {
    state.showContactsSearchModal = show
  },
  setShowEmailPromotionTemplateModal(state, show) {
    state.showEmailPromotionTemplateModal = show
  },
  setShowEmailPromotionModal(state, show) {
    state.showEmailPromotionModal = show
  },
  setShowExportEmailModal(state, show) {
    state.showExportEmailModal = show
  },
  setShowWineCardModal(state, show) {
    state.showWineCardModal = show
  },
  setWineBrowserSearchStore(state, store) {
    state.wineBrowserSearchStore = store
  }
}

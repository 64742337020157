/* eslint-disable prettier/prettier */
import gql from 'graphql-tag'
export default class Entity {

  /**
   * Create entity
   * @param name String the name of the entity
   * @param fields Object default fields, must have a type
   */
  constructor(name, fields) {
    this._allowedTypes = [String, Number, Boolean, Array, Object]
    this._ignoredKeys = ['__typename']
    this.mutations = {}
    this.queries = {}
    this.eventHandlers = {}
    this.name = name

    const fieldKeys = Object.keys(fields)
    for (let i = 0; i < fieldKeys.length; i++) {
      if (fields[fieldKeys[i]].type === undefined)
        throw new Error('All fields require a type')
      else if(!this._allowedTypes.includes(fields[fieldKeys[i]].type))
        throw new Error(`Entity '${name}' fields have an invalid type`)
      else if (!this._allowedTypes.includes(fields[fieldKeys[i]].type))
        throw new Error('Unknown type declared')
    }

    this.fields = fields
    this.mutations = {}
  }

  triggerEvent(name, payload) {
    const handlers = this.eventHandlers[name]
    if(handlers) {
      for(let i = 0; i < handlers.length; i++) {
        handlers[i](payload)
      }
    }
  }

  on(name, callback) {
    if(this.eventHandlers[name] === undefined) this.eventHandlers[name] = []
    this.eventHandlers[name].push(callback)
  }

  /**
   * Grabs only specified fields
   * @param fields
   * @param keys
   */
  static onlyKeys(fields, keys) {
    const result = {}

    const fieldKeys = Object.keys(fields)

    for(let i = 0; i < fieldKeys.length; i++) {
      if(keys.includes(fieldKeys[i]))
        result[fieldKeys[i]] = fields[fieldKeys[i]]
    }

    return result
  }

  /**
   * Grabs all but specified fields
   * @param fields Object
   * @param exclKeys Array fields to exclude
   * @returns Object
   */
  static allButKeys(fields, exclKeys) {
    const result = Object.assign({}, fields);

    for(let i = 0; i < exclKeys.length; i++) {
      delete result[exclKeys[i]]
    }

    return result
  }

  /**
   * Gets type based on value
   * @param value
   * @returns {NumberConstructor|BooleanConstructor|StringConstructor|ArrayConstructor, ObjectConstructor} type
   */
  static getTypeFromValue(value) {
    if (typeof value === 'string')
      return String
    else if (typeof value === 'number')
      return Number
    else if (typeof value === 'boolean')
      return Boolean
    else if (Array.isArray(value))
      return Array
    else if (typeof value === 'object')
      return Object
    return null
  }

  /**
   * Construct GraphQL return variable String
   * @param fields
   * @returns string
   */
  constructReturnFieldsString(fields) {
    let queryStr = ''
    const isArray = Array.isArray(fields)
    const queryArray = isArray ? fields : Object.keys(fields)
    for (let i = 0; i < queryArray.length; i++) {
      const key = queryArray[i]
      let handledNested = false
      queryStr += (queryStr.length > 0 ? ',\n' : '')
      if (isArray === false) {
        const fieldType = fields[key].type
        if (fieldType === Object || fieldType === Array) {
          if (fields[key].fields) {
            queryStr += queryArray[i] + '{' + this.constructReturnFieldsString(fields[key].fields) + '}'
          } else {
            queryStr += queryArray[i]
          }
          handledNested = true
        }
      }
      if (handledNested === false) {
        queryStr += queryArray[i]
      }
    }
    return queryStr
  }

  constructQueryFieldString(fields, checkFields, strictChecking) {

    let fieldStr = ''
    const fieldKeys = Object.keys(fields)
    for (let i = 0; i < fieldKeys.length; i++) {
      const field = fieldKeys[i]
      const fieldVal = fields[field]
      // Skip values that were not entered
      if (fieldVal === undefined) continue

      // Format value based on their types
      const fieldType = checkFields[field] !== undefined ? checkFields[field].type : Entity.getTypeFromValue(fieldVal)
      const useStrictChecking = !!(checkFields[field] && checkFields[field].strict === true)
      if(strictChecking === true && checkFields[field] === undefined) {
        continue
      }
      let valFormatted = fieldVal
      if(fieldType === String) {

        if(valFormatted !== null && valFormatted !== undefined) {
          valFormatted = '' + valFormatted
          valFormatted = valFormatted.replace(/\\/g,"\\\\")
          valFormatted = valFormatted.replace(/"/g,'\\"')
          valFormatted = valFormatted.replace(/\n/g,"\\n")
        }
        if (fieldVal && fieldVal.indexOf && fieldVal.indexOf('\n') >= 0) {
          valFormatted = `"""${valFormatted}"""`
        } else {
          valFormatted = `"${valFormatted}"`
        }

      } else if(fieldType === Number) {
        if(typeof fieldVal === 'string' && fieldVal.length === 0) {
          valFormatted = null
        } else{
          valFormatted = fieldVal
        }
      } else if(fieldType === Boolean) {
        valFormatted = fieldVal ? 'true' : 'false'
      } else if(fieldType === Array) {
        valFormatted = '['
        for (let i = 0; i < fieldVal.length; i++) {
          if (i > 0) valFormatted += ','
          if(typeof fieldVal[i] === 'object') {
            valFormatted += '{' + this.constructQueryFieldString(fieldVal[i], checkFields[field] && checkFields[field].fields || {}, useStrictChecking) + '}'
          } else if (typeof fieldVal[i] === 'string') {
            valFormatted += `"${fieldVal[i]}"`
          } else {
            valFormatted += String(fieldVal[i])
          }
        }
        valFormatted += ']'
      } else if(fieldType === Object && fieldVal !== null) {
        valFormatted = '{' + this.constructQueryFieldString(fieldVal, checkFields[field] && checkFields[field].fields || {}, useStrictChecking) + '}'
      }

      // Handle null values
      if(fieldVal === null) {
        valFormatted = 'null'
      }

      fieldStr += (fieldStr.length > 0 ? ', ' : '') + `${field}: ${valFormatted}`
    }
    return fieldStr
  }

  /**
   * Runs a raw GraphQL query
   * @param mutation Boolean whether it is a mutation
   * @param name String name of mutation/query
   * @param fields Object fields to mutate/query with
   * @param query Array/Object of fields to return
   * @param mutationFields Object of fields to format the query
   */
  rawQuery(mutation, name, fields, query, mutationFields) {
    const type = mutation ? 'mutation' : 'query'
    const rootType = mutation ? 'RootMutationType' : 'RootQueryType'
    const checkFields = mutationFields || this.fields
    const queryStr = this.constructReturnFieldsString(query)
    const fieldStr = this.constructQueryFieldString(fields, checkFields)
    // console.log(name, mutationFields)

    const queryStrR = `
      ${type} ${rootType} {
        ${name}${fieldStr.length > 0 ? '(' : ''}${fieldStr}${fieldStr.length > 0 ? ')' : ''}
        ${queryStr.length > 0 ? '{' : ''}
          ${queryStr}
        ${queryStr.length > 0 ? '}' : ''}
      }
    `
    return gql`${queryStrR}`
  }

  /**
   * Create a query
   * @param name
   * @param fields Object or Array the fields to query with. If object,
   * will map the key with a entity type, if array will map to type of the same
   * name.
   * @param alias Optional String the alias for query
   * @param requestFields Object or Array the fields to query with. If object,
   */
  createQuery(alias, name, fields, requestFields) {
    if (fields === undefined) fields = this.fields
    const fieldKeys = Object.keys(fields)
    for (let i = 0; i < fieldKeys.length; i++) {
      if (fields[fieldKeys[i]].type === undefined)
        throw new Error('All fields require a type')
    }

    const query = {
      name,
      fields,
      requestFields
    }

    this.queries[alias] = query
  }

  /**
   * Create a mutation
   * @param type String type of mutation 'insert', 'update' or 'delete'
   * @param name GraphQL name of mutation
   * @param fields Object containing fields to update and their type
   * @param query Array of fields to query back. If nothing is specified,
   * it will query back all default fields.
   */
  createMutation(type, name, fields, query) {
    const fieldKeys = Object.keys(fields)
    for (let i = 0; i < fieldKeys.length; i++) {
      if (fields[fieldKeys[i]].type === undefined)
        throw new Error('All fields require a type')
    }
    this.mutations[type] = {
      name,
      fields,
      query
    }
  }

  /**
   * Run a mutation
   * @param name String the type of mutation to run 'insert', 'update' or 'delete'
   * @param fields
   * @returns {*}
   */
  mutate(name, fields) {
    const fieldsCpy = Object.assign({}, fields)
    const mutation = this.mutations[name]
    if (mutation === undefined)
      throw new Error('Mutation ' + name + ' does not exist')

    const mutationFields = mutation.fields

    const mutationFieldsKeys = Object.keys(mutationFields)
    for(let i = 0; i < mutationFieldsKeys.length; i++) {
      const key = mutationFieldsKeys[i]
      if(fieldsCpy[key] === undefined && mutationFields[key].default !== undefined)
        fieldsCpy[key] = mutationFields[key].default
    }

    const fieldKeys = Object.keys(fieldsCpy)
    for (let i = 0; i < fieldKeys.length; i++) {
      // if an ignored key is present, delete it
      if (this._ignoredKeys.includes(fieldKeys[i])) {
        delete fieldsCpy[fieldKeys[i]]
        continue
      }
      // if there is an extra key that wasn't declared, delete it
      if (Object.keys(mutationFields).indexOf(fieldKeys[i]) === -1) {
        delete fieldsCpy[fieldKeys[i]]
      }
    }

    return this.rawQuery(true, mutation.name, fieldsCpy, mutation.query ? mutation.query : this.fields, mutationFields)
  }

  /**
   * Run a query
   * @param name
   * @param fields Object fields to query with
   * @returns {*}
   */
  query(name, fields) {
    if (fields === undefined) fields = {}
    let requestFields = this.queries[name].requestFields;
    if (requestFields === undefined) requestFields = {}
    return this.rawQuery(false, this.queries[name].name, fields, this.queries[name].fields, requestFields)
  }
}

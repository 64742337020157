<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :ok-only="true"
    @hidden="$emit('hidden')"
    @shown="$emit('shown')"
    @show="$emit('show')"
    @hide="$emit('hide')"
    @ok="$emit('ok')"
    @cancel="$emit('cancel')"
    @change="$emit('change')"
  >
    <template v-slot:modal-header>
      <span class="danger">
        {{ title }}
      </span>
    </template>
    <slot>Are you Sure?</slot>
    <template v-slot:modal-footer="{ok}">
      <b-button class="btn wv-btn btn-secondary btn-sm" @click="ok()">
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: 'Alert'
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

import Entity from './entity'

const fields = {
  ShipperAddrID: {
    type: Number
  },
  ShipperName: {
    type: String
  },
  Addr1: {
    type: String
  },
  Addr2: {
    type: String
  },
  Addr3: {
    type: String
  },
  Addr4: {
    type: String
  },
  Postcode: {
    type: String
  },
  CountryCode: {
    type: String
  },
  ForAttentionOf: {
    type: String
  },
  Telephone: {
    type: String
  },
  Orders: {
    type: Number
  }
}

const ShipperAddress = new Entity('ShipperAddress', fields)

ShipperAddress.createQuery('search', 'shipperAddressSearch', fields)
ShipperAddress.createQuery('get', 'shipperAddressGet', fields)

ShipperAddress.createMutation(
  'insert',
  'shipperAddressAdd',
  Entity.allButKeys(fields, ['Orders', 'AuditAmendedDate']),
  fields
)

ShipperAddress.createMutation(
  'update',
  'shipperAddressUpdate',
  Entity.allButKeys(fields, ['Orders']),
  fields
)

ShipperAddress.createMutation(
  'delete',
  'shipperAddressDelete',
  {
    ID: {
      type: Number
    }
  },
  []
)
export default ShipperAddress

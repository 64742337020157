import { client } from '../graphQLInstance'
import KPIStats from '../../models/kpistats'
import PriceListStatus from '../../models/priceliststatus'

export const getSalesOrderByCustomer = async date => {
  const result = await client.query({
    query: KPIStats.query('get', {
      BaseDate: date
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[KPIStats.queries.get.name]
}

export const getPriceListStatusById = async RecordId => {
  const result = await client.query({
    query: PriceListStatus.query('get', {
      RecordId
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PriceListStatus.queries.get.name]
}

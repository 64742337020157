<template>
  <wine-list-export-settings
    v-model="showWineListExportSettingsModal"
    :item="{}"
    :currencies="currencies"
    :duty-status="dutyStatus"
    :hyper-links-in-email="hyperLinksInEmail"
    :loose-bottle-check="looseBottleCheck"
    :margin-check="marginCheck"
    :output-settings="outputSettings"
    :packaging="packaging"
    :scores="scores"
    :usage-codes="usageCodes"
    :grouping="grouping"
    @change-currencies="updateCurrencies"
    @change-duty-status="updateDutyStatus"
    @change-hyper-links-in-email="updateHyperLinksInEmail"
    @change-loose-bottle-check="updateLooseBottleCheck"
    @change-margin-check="updateMarginCheck"
    @change-output-settings="updateOutputSettings"
    @change-packaging="updatePackaging"
    @change-scores="updateScores"
    @change-usage-codes="updateUsageCodes"
    @change-grouping="updateGrouping"
    @ok="handleOk"
  />
</template>

<script>
import WineListExportSettings from '~/components/Modal/WineListExportSettings'
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    WineListExportSettings
  },
  computed: {
    showWineListExportSettingsModal: {
      get() {
        return this.$store.state.modal.showWineListExportSettingsModal
      },
      set(value) {
        this.$store.commit('modal/setShowWineListExportSettingsModal', value)
      }
    },
    ...mapState('export/settings/', {
      currencies: state => state.currencies,
      dutyStatus: state => state.dutyStatus,
      hyperLinksInEmail: state => state.hyperLinksInEmail,
      looseBottleCheck: state => state.looseBottleCheck,
      marginCheck: state => state.marginCheck,
      outputSettings: state => state.outputSettings,
      packaging: state => state.packaging,
      scores: state => state.scores,
      usageCodes: state => state.usageCodes,
      grouping: state => state.grouping
    })
  },
  methods: {
    handleOk() {
      this.$eventHub.$emit('updated-wine-list-export-settings')
    },
    ...mapMutations({
      updateCurrencies: 'export/settings/setCurrencies',
      updateDutyStatus: 'export/settings/setDutyStatus',
      updateHyperLinksInEmail: 'export/settings/setHyperLinksInEmail',
      updateLooseBottleCheck: 'export/settings/setLooseBottleCheck',
      updateMarginCheck: 'export/settings/setMarginCheck',
      updateOutputSettings: 'export/settings/setOutputSettings',
      updatePackaging: 'export/settings/setPackaging',
      updateScores: 'export/settings/setScores',
      updateUsageCodes: 'export/settings/setUsageCodes',
      updateGrouping: 'export/settings/setGrouping'
    })
  }
}
</script>

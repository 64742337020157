<template>
  <div>
    <a v-if="anchor" href="#" :disabled="disabled" @click="openUrl">
      <slot>
        <img src="/images/Liv-Ex.png" :style="imageStyle" class="w-100 h-auto">
      </slot>
    </a>
    <button v-else :class="buttonClass" :disabled="disabled" :size="size" @click="openUrl">
      <slot>
        <img src="/images/Liv-Ex.png" :style="imageStyle" class="w-100 h-auto">
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    lwin: {
      type: [String, Number],
      required: false,
      default: ''
    },
    vintage: {
      type: [String, Number],
      required: false,
      default: ''
    },
    wineName: {
      type: [String],
      required: false,
      default: ''
    },
    width: {
      type: String,
      required: false,
      default: ''
    },
    anchor: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: 'btn wv-btn wv-btn__image'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    url() {
      if (this.lwin && String(this.lwin).length > 0) {
        let url = 'https://app.liv-ex.com/#/wine-page?lwin11={lwin}{vintage}'
        url = url.replace('{lwin}', encodeURIComponent(String(this.lwin)))
        url = url.replace('{vintage}', encodeURIComponent(String(this.vintage)))
        return url
      }
      let url =
        'https://app.liv-ex.com/#/search?text={keywords}&page=search-results&id=multiple'
      url = url.replace('{keywords}', encodeURIComponent(this.wineName))
      return url
    },
    imageStyle() {
      return {
        width: this.width + 'px'
      }
    }
  },
  methods: {
    openUrl() {
      if (this.disabled === false) {
        window.open(this.url)
        this.$emit('click')
      } else {
        this.$emit('disabled-click')
      }
    }
  }
}
</script>

<style scoped>
img {
  cursor: pointer;
}
</style>

import Entity from './entity'

const fields = {
  DutyTypeID: {
    type: Number
  },
  DutyTypeName: {
    type: String
  }
}

const DutyType = new Entity('DutyType', fields)
DutyType.createQuery('getAll', 'dutyTypeDropDown', fields)

export default DutyType

<template>
  <form ref="requestForm" method="post" :action="actionUrl" :target="target">
    <input type="hidden" name="token" :value="$store.state.user.documentToken">
    <input name="AllSplitCases" :value="value.AllSplitCases ? '1':'0'" type="hidden">
    <input name="AllWholeCases" :value="value.AllWholeCases ? '1':'0'" type="hidden">
    <input name="Preview" :value="value.Preview ? '1':'0'" type="hidden">
    <input name="Scores" :value="value.Scores" type="hidden">
    <input name="SortBy" :value="value.SortBy" type="hidden">
    <input name="SortDesc" :value="value.SortDesc" type="hidden">
    <input name="Type" :value="value.Type" type="hidden">
    <input name="Subject" :value="value.Subject" type="hidden">
    <input name="ExportListId" :value="value.ExportListId" type="hidden">
    <input name="Mode" :value="value.Mode" type="hidden">
    <!-- <input name="WineUsageCodes" :value="value.WineUsageCodes" type="hidden"> -->
    <input v-for="usageCode in value.WineUsageCodes" :key="usageCode" name="WineUsageCodes[]" :value="usageCode" type="hidden">
    <input name="DutyStatusID" :value="value.DutyStatusID" type="hidden">
    <input name="WithOrigPkg" :value="value.WithOrigPkg ? '1':'0' " type="hidden">
    <input name="StockLossType" :value="value.StockLossType" type="hidden">
    <input name="CheckLooseBottles" :value="value.CheckLooseBottles ? '1':'0'" type="hidden">
    <input name="LastReceiptDays" :value="value.LastReceiptDays" type="hidden">
    <input name="Grouping" :value="value.Grouping" type="hidden">
    <input name="LooseOnly" :value="value.LooseOnly ? '1':'0'" type="hidden">
    <input v-for="owc in value.OnlyOWCs" :key="owc" name="OnlyOWCs[]" :value="owc" type="hidden">
    <input v-for="curr in value.Currencies" :key="curr" name="Currencies[]" :value="curr" type="hidden">
    <input v-for="(w,i) in ExcludedUUID" :key="'exclude'+i" name="ExcludedUUID[]" :value="w" type="hidden">
    <input v-for="(w,i) in SplitCaseUUID" :key="'split'+i" name="SplitCaseUUID[]" :value="w" type="hidden">
    <input v-for="(w,i) in WholeCaseUUID" :key="'whole'+i" name="WholeCaseUUID[]" :value="w" type="hidden">
    <input v-for="(w,i) in IncludeAllUUID" :key="'include'+i" name="IncludeAllUUID[]" :value="w" type="hidden">
    <input v-for="link in value.HyperLinks" :key="link" name="HyperLink[]" :value="link" type="hidden">
    <input v-for="category in value.CategoryIds" :key="category" name="CategoryIds[]" :value="category" type="hidden">
    <slot name="form-override" />
  </form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    target: {
      type: String,
      default: 'self'
    }
  },
  computed: {
    ExcludedUUID() {
      return this.value.ExcludedUUID
    },
    SplitCaseUUID() {
      return this.value.SplitCaseUUID
    },
    WholeCaseUUID() {
      return this.value.WholeCaseUUID
    },
    IncludeAllUUID() {
      return this.value.IncludeAllUUID
    },
    actionUrl() {
      return process.env.apiHost + '/document/pricelist'
    }
  },
  methods: {
    submit() {
      this.$refs.requestForm.submit()
    }
  }
}
</script>

import Sort from '~/components/Modal/Export/WineListExport/Sort'
import OutputSettings from '~/components/Modal/Export/WineListExport/OutputSettings'
import File from '~/components/Modal/Export/WineListExport/File'
import EmailSubject from '~/components/Modal/Export/WineListExport/EmailSubject'

export default {
  components: {
    Sort,
    OutputSettings,
    File,
    EmailSubject
  },
  computed: {
    minParkerScore() {
      return this.$store.state.export.settings.minParkerScore
    },
    scoreMode() {
      return this.$store.state.export.settings.scores
    },
    selectedCurrencies() {
      return this.$store.state.export.settings.currencies
    },
    sort: {
      get() {
        return this.$store.state.user.settings.sortExclusions
      },
      set(value) {
        this.$store.dispatch('user/setSetting', {
          key: 'sortExclusions',
          value
        })
      }
    },
    outputSettings: {
      get() {
        if (this.isWineBrowserBased) {
          return this.$store.state.user.settings.wineBrowserPdfExportType
        }
        return this.$store.state.user.settings.purchaseOrderPdfExportType
      },
      set(value) {
        const key = this.isWineBrowserBased
          ? 'wineBrowserPdfExportType'
          : 'purchaseOrderPdfExportType'
        this.$store.dispatch('user/setSetting', { key, value })
      }
    },
    preview: {
      get() {
        if (this.isWineBrowserBased) {
          return this.$store.state.user.settings.wineBrowserPdfFilePreview
        }
        return this.$store.state.user.settings.purchaseOrderPdfFilePreview
      },
      set(value) {
        const key = this.isWineBrowserBased
          ? 'wineBrowserPdfFilePreview'
          : 'purchaseOrderPdfFilePreview'
        this.$store.dispatch('user/setSetting', { key, value })
      }
    },
    file: {
      get() {
        if (this.isWineBrowserBased) {
          return this.$store.state.user.settings.wineBrowserPdfFileType
        }
        return this.$store.state.user.settings.purchaseOrderPdfFileType
      },
      set(value) {
        const key = this.isWineBrowserBased
          ? 'wineBrowserPdfFileType'
          : 'purchaseOrderPdfFileType'
        this.$store.dispatch('user/setSetting', { key, value })
      }
    },
    subject: {
      get() {
        if (this.isWineBrowserBased) {
          return this.$store.state.user.settings.wineBrowserPdfHeading
        }
        return this.$store.state.user.settings.purchaseOrderPdfHeading
      },
      set(value) {
        const key = this.isWineBrowserBased
          ? 'wineBrowserPdfHeading'
          : 'purchaseOrderPdfHeading'
        this.$store.dispatch('user/setSetting', { key, value })
      }
    },
    fileDisabled() {
      return this.outputSettings === 'email'
    },
    settingsReportParameters() {
      return {
        Type: this.file,
        Preview: this.preview,
        Subject: this.subject
      }
    }
  }
}

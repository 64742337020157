import Vue from 'vue'
import { findIndex } from 'lodash'
import { searchPurchaseOrders } from '../../api/web/purchaseOrder'

const searchParameters = {
  WebContactID: null,
  StatusID: 0,
  OwnerUserID: 0,
  DateFrom: '',
  DateTo: '',
  Page: 1,
  PerPage: 100,
  SearchTerm: null,
  IncludeArchived: false
}

export const getters = {}

export const state = () => ({
  loading: false,
  saving: false,
  newTotal: 0,
  purchaseOrders: [],
  total: 0,
  searchParameters: Object.assign({}, searchParameters),
  statuses: [
    {
      value: 1,
      text: 'New'
    },
    {
      value: 2,
      text: 'Incomplete'
    },
    {
      value: 3,
      text: 'Completed'
    },
    {
      value: 4,
      text: 'Converted'
    },
    {
      value: 5,
      text: 'Rejected'
    },
    {
      value: 6,
      text: 'Imported'
    }
  ]
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  setSavingOrder(state, saving) {
    state.savingOrder = saving === true
  },
  setOrders(state, purchaseOrders) {
    state.purchaseOrders = purchaseOrders
  },
  appendOrders(state, purchaseOrders) {
    state.purchaseOrders = state.purchaseOrders.concat(purchaseOrders)
  },
  setTotal(state, total) {
    state.total = total
  },
  setSearchParameter(state, { key, value }) {
    Vue.set(state.searchParameters, key, value)
  },
  resetSearchParameters(state) {
    state.searchParameters = Object.assign({}, searchParameters)
    state.total = 0
    state.purchaseOrders = []
  },
  replacePurchaseOrder(state, purchaseOrder) {
    const idx = findIndex(state.purchaseOrders, { WPOID: purchaseOrder.WPOID })
    if (idx >= 0) {
      Vue.set(state.purchaseOrders, idx, purchaseOrder)
    } else {
      state.purchaseOrders.push(purchaseOrder)
    }
  }
}

export const actions = {
  async searchOrders({ commit }, searchParams) {
    try {
      commit('setLoading', true)
      const salesOrderResponse = await searchPurchaseOrders(searchParams)
      const purchaseOrders = salesOrderResponse.webPurchaseOrderPaginatedResults
      if (searchParams.Page === 1) {
        commit('setTotal', salesOrderResponse.total)
        commit('setOrders', purchaseOrders)
      } else {
        commit('appendOrders', purchaseOrders)
      }
      return purchaseOrders
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

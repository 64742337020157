export const fields = {
  WineCardID: {
    type: Number
  },
  WineVintage: {
    type: Number
  },
  ComputedWineName: {
    type: Number
  },
  WineName: {
    type: Number
  },
  WineParkerScore: {
    type: String
  },
  ParkerSortValue: {
    type: Number
  },
  WineBurghoundScore: {
    type: String
  },
  FormatID: {
    type: Number
  },
  FormatName: {
    type: Number
  },
  CaseSize: {
    type: Number
  },
  WineUsageCodeID: {
    type: Number
  },
  UsageCode: {
    type: Number
  },
  TotalBottles: {
    type: Number
  },
  DutyPaidBottles: {
    type: Number
  },
  InBondBottles: {
    type: Number
  },
  UnallocatedSalesBottles: {
    type: Number
  },
  OversoldBottles: {
    type: Number
  },
  AvailableBottles: {
    type: Number
  },
  ReservesBottles: {
    type: Number
  },
  BrokingBottles: {
    type: Number
  },
  ReservedBottles: {
    type: Number
  },
  SomeStockLoss: {
    type: Number
  },
  WineCostPrice: {
    type: Number
  },
  LooseCostPrice: {
    type: Number
  },
  WineSellingPrice: {
    type: Number
  },
  PurchaseOrderLinesUsage: {
    type: Number
  },
  SalesOrderLinesUsage: {
    type: Number
  },
  PromotionDate: {
    type: Number
  },
  LastSOID: {
    type: Number
  },
  LastPOID: {
    type: Number
  },
  LastPurchasePrice: {
    type: Number
  },
  LastPurchaseDate: {
    type: Number
  },
  LastPurchaseContact: {
    type: Number
  },
  LastOrderDate: {
    type: Number
  },
  LastPrice: {
    type: Number
  },
  LastSalesContact: {
    type: Number
  },
  AverageDaysInStock: {
    type: Number
  },
  NoteCount: {
    type: Number
  },
  WineCardNotesString: {
    type: Number
  },
  OverrideLossCheck: {
    type: Number
  },
  Category: {
    type: String
  },
  CategoryID: {
    type: Number
  },
  CategorySequence: {
    type: Number
  },
  FormatCode: {
    type: Number
  },
  Volume: {
    type: Number
  },
  EmptyCases: {
    type: Number
  },
  PhotoRequired: {
    type: Number
  },
  AuditCreatedDate: {
    type: Number
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedDate: {
    type: Number
  },
  AuditAmendedBy: {
    type: Number
  },
  AuditAmendedByUserName: {
    type: Number
  },
  LivExLWinCode: {
    type: Number
  }
}

import {
  state,
  getters,
  mutations,
  actions
} from '../../wineBrowser/appellations'

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <b-modal
    ref="modal"
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :title="title"
    @hidden="$emit('hidden')"
    @shown="$emit('shown')"
    @show="$emit('show')"
    @hide="$emit('hide')"
    @ok="$emit('ok')"
    @cancel="$emit('cancel')"
    @change="$emit('change')"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <slot>Are you Sure?</slot>
    <template v-slot:modal-footer="{ok,cancel}">
      <slot name="footer">
        <b-button ref="okButton" class="btn wv-btn btn-secondary btn-sm" @click="ok()">
          OK
        </b-button>
        <b-button class="btn wv-btn btn-secondary btn-sm" @click="cancel()">
          Cancel
        </b-button>
      </slot>
    </template>
    <div style="overflow: hidden; height:0; width: 0">
      <input ref="keyHandler" @keydown.enter.prevent="handleEnterEvent">
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return 'Are you Sure?'
      }
    },
    value: {
      type: Boolean,
      required: true
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },
    focusOk: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    shown(shown) {
      if (shown && this.focusOk) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.keyHandler.focus()
          }, 300)
        })
      }
    }
  },
  methods: {
    handleEnterEvent() {
      if (this.focusOk) {
        this.$refs.modal.onOk()
      }
    }
  }
}
</script>

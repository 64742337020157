import {
  getProducers,
  updateProducer,
  addProducer,
  deleteProducer
} from '@/api/administration/producers'
import { find, merge } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  loadingProducer: false,
  list: [],
  activeProducer: null,
  searchTerm: ''
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoadingProducer(state, loading) {
    state.loadingProducer = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  resetList(state) {
    state.list = []
  },
  setSearchTerm(state, term) {
    state.searchTerm = term
  },
  setActiveProducer(state, { ProducerID }) {
    state.activeProducer = find(state.list, { ProducerID })
  },
  updateProducerData(state, producer) {
    const idx = state.list.findIndex(l => l.ProducerID === producer.ProducerID)
    if (idx !== -1) {
      const newProducer = merge({}, state.list[idx], producer)
      Vue.set(state.list, idx, newProducer)
    }
  },
  removeProducerFromList(state, producerId) {
    state.list = state.list.filter(p => p.ProducerID !== producerId)
  },
  addProducerToList(state, producer) {
    state.list.push(producer)
  }
}

export const getters = {
  getProducerList(state) {
    return state.list
  },
  getActiveProducer(state) {
    return state.activeProducer
  }
}

export const actions = {
  setActiveProducer({ commit }, producer) {
    commit('setActiveProducer', producer)
  },
  async loadList({ commit, state }) {
    commit('setLoading', true)
    try {
      const response = await getProducers(state.searchTerm)
      commit('setList', response)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async updateProducer({ commit }, producer) {
    commit('setLoadingProducer', true)
    try {
      const response = await updateProducer(producer)
      commit('updateProducerData', response)
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingProducer', false)
    }
  },
  async deleteProducer({ commit }, producer) {
    commit('setLoadingProducer', true)
    try {
      const result = await deleteProducer(producer)
      if (result) {
        commit('removeProducerFromList', producer.ProducerID)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingProducer', false)
    }
  },
  async addProducer({ commit }, producer) {
    commit('setLoadingProducer', true)
    try {
      const response = await addProducer(producer)
      commit('addProducerToList', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingProducer', false)
    }
  }
}

import Entity from './entity'

const fields = {
  AddrID: {
    type: Number
  },
  AddressDescription: {
    type: String
  },
  IsWarehouse: {
    type: Boolean
  }
}

const AddressDropDown = new Entity('AddressDropDown', fields)
AddressDropDown.createQuery('get', 'addressDropDown')

export default AddressDropDown

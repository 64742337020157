import Entity from './entity'

const fields = {
  WineBrowserSearchParams: {
    type: Object,
    fields: {
      SearchTerm: {
        type: String
      },
      FormatId: {
        type: Number
      },
      UsageId: {
        type: Number
      },
      ValueFrom: {
        type: Number
      },
      ValueTo: {
        type: Number
      },
      CasesFrom: {
        type: Number
      },
      CasesTo: {
        type: Number
      },
      ParkerScore: {
        type: String
      },
      BurghoundScore: {
        type: Number
      },
      CategoryId: {
        type: Number
      },
      AppellationId: {
        type: Number
      },
      Vintage: {
        type: Number
      },
      Vintage2: {
        type: Number
      },
      WineNameSearchID: {
        type: String
      },
      VintageSelection: {
        type: String
      },
      VintageSelectionPreSeventy: {
        type: Boolean
      },
      VintageSelectionNonVintage: {
        type: Boolean
      },
      HideZeroStock: {
        type: Boolean
      },
      LooseBottlesOnly: {
        type: Boolean
      },
      LoosePriceOnly: {
        type: Boolean
      }
    }
  },
  PurchaseOrderSearchParameters: {
    type: Object,
    fields: {
      SearchTerm: {
        type: String
      },
      WineSearch: {
        type: String
      },
      FromTime: {
        type: String
      },
      ToTime: {
        type: String
      },
      Unallocated: {
        type: Boolean
      },
      UnsoldStock: {
        type: Boolean
      },
      WFSOnly: {
        type: Boolean
      }
    }
  },
  POIDs: {
    type: Array
  },
  Last3MonthsPOIDS: {
    type: Boolean
  },
  ExportListID: {
    type: String
  }
}

const WineCardExportList = new Entity('WineCardExportList', {})
WineCardExportList.createMutation('append', 'wineCardExportListAppend', fields)

WineCardExportList.createQuery(
  'owcs',
  'wineCardExportOWCs',
  {
    OrigPkgCaseSize: {
      type: Number
    },
    WineCardCount: {
      type: Number
    }
  },
  fields
)

export default WineCardExportList

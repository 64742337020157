<template>
  <b-button size="sm" class="btn wv-btn" :block="block" @click.prevent="$emit('click')">
    {{ text }}
    <template v-if="hasChosenCases">
      ({{ chosenCases }})
    </template>
    <b-spinner v-if="loading" type="grow" small label="Spinning" />
  </b-button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    wineCard: {
      type: Object,
      required: true
    },
    block: {
      type: Boolean,
      default: false
    },
    chosenCases: {
      type: String,
      default: null
    }
  },
  computed: {
    hasChosenCases() {
      return this.chosenCases !== null && this.chosenCases.length > 0
    },
    text() {
      if (this.loading || !this.wineCard) {
        return 'Loading Empty Case Info'
      }
      if (
        this.wineCard.emptyCaseInfo &&
        this.wineCard.emptyCaseInfo.length > 0
      ) {
        return 'Empty: ' + this.wineCard.emptyCaseInfo
      }
      if (
        this.wineCard.EmptyCaseInfo &&
        this.wineCard.EmptyCaseInfo.length > 0
      ) {
        return 'Empty: ' + this.wineCard.EmptyCaseInfo
      }
      return 'No Empty Cases'
    }
  }
}
</script>

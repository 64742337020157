import { state, getters, mutations } from '../wineBrowser/vintages'
import { map } from 'lodash'
import { getList, getInitialList } from '@/api/reserves/vintages'

export const actions = {
  async get({ commit, state }, force) {
    if (state.loadedInitialList && force !== true) {
      return
    }
    commit('setLoading', true)
    try {
      let result = await getInitialList()
      if (result) {
        result = map(result, p => {
          const key = p.startsWith('Pre') ? 'PRE' : p
          return {
            value: p,
            key: key
          }
        })
        result.push({
          value: 'Non Vintage',
          key: 'NV'
        })
        result.push({
          value: 'All Vintages',
          key: 'ALL'
        })
        commit('setVintages', result)
        commit('setLoadedInitialList', true)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async refreshList({ commit, state }, { searchParameters }) {
    commit('clearExtraVintageInfo')
    commit('setLoading', true)
    const vintageResults = await getList(searchParameters)
    commit('mergeVintageList', vintageResults.available || [])
    commit('disableWinesNotInList', vintageResults.available || [])
    commit('setPreYearVintageForAvailable', vintageResults.available || [])
    commit('setNonVintageForAvailable', vintageResults.available || [])
    commit('setAllVintageForAvailable', vintageResults.available || [])
    commit('setLoading', false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

import { filter, find, findIndex, reduce, without } from 'lodash'
import Vue from 'vue'
import {
  addWebPurchaseOrderAllocation,
  convertPurchaseOrder,
  deleteWebPurchaseOrderAllocation,
  getPurchaseOrder,
  getPurchaseOrderAllocations,
  getPurchaseOrderLines,
  updatePurchaseOrder,
  updateWebPurchaseOrderAllocation,
  deleteWebPurchaseOrderLine
} from '../../api/web/purchaseOrder'

export const state = () => ({
  loading: false,
  saving: false,
  loadingLines: false,
  purchaseOrder: {},
  purchaseOrderLines: [],
  selectedWPOLID: null,
  allocations: []
})

export const getters = {
  selectedPurchaseOrderLine: state => {
    return find(
      state.purchaseOrderLines,
      l => l.WPOLID === state.selectedWPOLID
    )
  },
  getAllocationForImage: state => WPOLIID => {
    return find(state.allocations, a => {
      return a.Images.indexOf(WPOLIID) >= 0
    })
  },
  getAllocation: state => WebAllocationID => {
    return find(state.allocations, { WebAllocationID })
  },
  allocationsForOrderLine: state => WPOLID => {
    return filter(state.allocations, { WPOLID })
  },
  allocationsForOrder: state => WPOID => {
    let allocations = []
    const lines = state.purchaseOrderLines
    lines.forEach(l => {
      const allocs = filter(state.allocations, { WPOLID: l.WPOLID })
      allocations = allocations.concat(allocs)
    })
    return allocations
  },
  unAllocatedBottlesForLine: state => WPOLID => {
    const pol = find(state.purchaseOrderLines, { WPOLID })
    return (
      pol.TotalBottles -
      reduce(
        state.allocations,
        (count, alloc) => {
          if (alloc.WPOLID === WPOLID) {
            count += alloc.Bottles
          }
          return count
        },
        0
      )
    )
  },
  unAllocatedBottlesForOrder: state => WPOID => {
    const pols = state.purchaseOrderLines
    return reduce(
      pols,
      (count, pol) => {
        return (
          count +
          pol.TotalBottles -
          reduce(
            state.allocations,
            (count, alloc) => {
              if (alloc.WPOLID === pol.WPOLID) {
                count += alloc.Bottles
              }
              return count
            },
            0
          )
        )
      },
      0
    )
  },
  allocatedBottlesForLine: state => WPOLID => {
    return reduce(
      state.allocations,
      (count, alloc) => {
        if (alloc.WPOLID === WPOLID) {
          count += alloc.Bottles
        }
        return count
      },
      0
    )
  },
  allocatedImagesForLine: state => WPOLID => {
    return reduce(
      state.allocations,
      (count, alloc) => {
        if (alloc.WPOLID === WPOLID && alloc.Images) {
          count += alloc.Images.length
        }
        return count
      },
      0
    )
  },
  unAllocatedImagesForOrder: state => WPOID => {
    const pols = state.purchaseOrderLines
    return reduce(
      pols,
      (count, pol) => {
        return (
          count +
          reduce(
            state.allocations,
            (count, alloc) => {
              if (alloc.WPOLID === pol.WPOLID) {
                count += pol.Images.length - alloc.Images.length
              }
              return count
            },
            0
          )
        )
      },
      0
    )
  }
}
export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  setLoadingLines(state, loading) {
    state.loadingLines = loading === true
  },
  setPurchaseOrder(state, order) {
    state.purchaseOrder = order
  },
  setPurchaseOrderLines(state, lines) {
    state.purchaseOrderLines = lines
  },
  setSelectedWPOLID(state, wpolid) {
    state.selectedWPOLID = wpolid
  },
  setAllocations(state, allocations) {
    state.allocations = allocations
  },
  mergePurchaseOrder(state, args) {
    state.purchaseOrder = Object.assign({}, state.purchaseOrder, args)
  },
  replacePurchaseOrderLine(state, pol) {
    const idx = findIndex(state.purchaseOrderLines, { WPOLID: pol.WPOLID })
    if (idx >= 0) {
      Vue.set(state.purchaseOrderLines, idx, pol)
    } else {
      state.purchaseOrderLines.push(pol)
    }
  },
  appendAllocations(state, allocation) {
    allocation.forEach(allocation => {
      if (!allocation.WebAllocationID) {
        allocation.WebAllocationID = 'new_' + (state.allocations.length + 1)
      }
      const idx = findIndex(state.allocations, {
        WebAllocationID: allocation.WebAllocationID
      })
      if (idx >= 0) {
        Vue.set(
          state.allocations,
          idx,
          Object.assign({}, state.allocations[idx], allocation)
        )
      } else {
        state.allocations.push(allocation)
      }
    })
  },
  appendAllocation(state, allocation) {
    if (!allocation.WebAllocationID) {
      allocation.WebAllocationID = 'new_' + (state.allocations.length + 1)
    }
    const idx = findIndex(state.allocations, {
      WebAllocationID: allocation.WebAllocationID
    })
    if (idx >= 0) {
      Vue.set(
        state.allocations,
        idx,
        Object.assign({}, state.allocations[idx], allocation)
      )
    } else {
      state.allocations.push(allocation)
    }
  },
  mergeAllocation(state, allocation) {
    const idx = findIndex(state.allocations, {
      WebAllocationID: allocation.WebAllocationID
    })
    if (idx >= 0) {
      Vue.set(
        state.allocations,
        idx,
        Object.assign({}, state.allocations[idx], allocation)
      )
    }
  },
  mergeAllocationWithoutID(state, allocation) {
    const idx = findIndex(state.allocations, {
      WPOLID: allocation.WPOLID,
      WineCardID: allocation.WineCardID
    })
    if (idx >= 0) {
      Vue.set(
        state.allocations,
        idx,
        Object.assign({}, state.allocations[idx], allocation)
      )
    } else {
      state.allocations.push(allocation)
    }
  },
  removeAllocation(state, WebAllocationID) {
    const idx = findIndex(state.allocations, { WebAllocationID })
    if (idx >= 0) {
      Vue.delete(state.allocations, idx)
    }
  },
  removeLine(state, WPOLID) {
    const idx = findIndex(state.purchaseOrderLines, { WPOLID })
    if (idx >= 0) {
      Vue.delete(state.purchaseOrderLines, idx)
    }
    state.allocations = state.allocations.filter(a => a.WPOLID !== WPOLID)
  },
  assignImageToAllocation(state, { WPOLIID, allocationId }) {
    state.allocations = state.allocations.map(a => {
      a.Images = without(a.Images, WPOLIID)
      if (allocationId === a.WebAllocationID) {
        a.Images.push(WPOLIID)
      }
      return a
    })
  },
  setSavingAllocationID(state, ID) {
    state.savingAllocationID = ID
  }
}

export const actions = {
  async loadPurchaseOrder({ commit, state }, { WPOID, force }) {
    try {
      if (state.purchaseOrder.WPOID === WPOID && force !== true) {
        return state.purchaseOrder
      }
      commit('setLoading', true)
      const purchaseOrder = await getPurchaseOrder({ WPOID })
      commit('setPurchaseOrder', purchaseOrder)
      return purchaseOrder
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getPurchaseOrderLines({ commit }, { WPOID }) {
    try {
      commit('setLoadingLines', true)
      const purchaseOrderLines = await getPurchaseOrderLines({ WPOID })
      commit('setPurchaseOrderLines', purchaseOrderLines)
      return purchaseOrderLines
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingLines', false)
    }
  },
  async convertPurchaseOrder({ commit }, conversionArgs) {
    try {
      commit('setSaving', true)
      const purchaseDetails = await convertPurchaseOrder(conversionArgs)
      commit('setPurchaseOrder', purchaseDetails.WebPurchaseOrder)
      commit('setPurchaseOrderLines', purchaseDetails.WebPurchaseOrderLines)
      commit('setAllocations', purchaseDetails.WebPurchaseOrderLineAllocations)
      return purchaseDetails
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async updatePurchaseOrder({ commit }, args) {
    try {
      commit('setSaving', true)
      const purchaseDetails = await updatePurchaseOrder(args)
      commit('setPurchaseOrder', purchaseDetails.WebPurchaseOrder)
      commit('setPurchaseOrderLines', purchaseDetails.WebPurchaseOrderLines)
      commit('setAllocations', purchaseDetails.WebPurchaseOrderLineAllocations)
      return purchaseDetails
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async getPurchaseOrderAllocations({ commit }, { WPOID }) {
    try {
      commit('setLoadingLines', true)
      const allocations = await getPurchaseOrderAllocations({ WPOID })
      commit('appendAllocations', allocations)
      return allocations
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingLines', false)
    }
  },
  async addPurchaseOrderAllocation({ commit }, args) {
    try {
      commit('setSavingAllocationID', args.WebAllocationID)
      const allocations = await addWebPurchaseOrderAllocation(args)
      commit('mergeAllocationWithoutID', allocations)
      return allocations
    } catch (e) {
      throw e
    } finally {
      commit('setSavingAllocationID', 0)
    }
  },
  async updatePurchaseOrderAllocation({ commit }, args) {
    try {
      commit('setSavingAllocationID', args.WebAllocationID)
      const allocations = await updateWebPurchaseOrderAllocation(args)
      commit('mergeAllocationWithoutID', allocations)
      return allocations
    } catch (e) {
      throw e
    } finally {
      commit('setSavingAllocationID', 0)
    }
  },
  async deletePurchaseOrderAllocation({ commit }, WebAllocationID) {
    try {
      commit('setSavingAllocationID', WebAllocationID)
      const allocations = await deleteWebPurchaseOrderAllocation({
        WebAllocationID
      })
      commit('removeAllocation', WebAllocationID)
      return allocations
    } catch (e) {
      throw e
    } finally {
      commit('setSavingAllocationID', 0)
    }
  },
  async removeLine({ commit }, WPOLID) {
    try {
      const allocations = await deleteWebPurchaseOrderLine({
        WPOLID
      })
      commit('removeLine', WPOLID)
      return allocations
    } catch (e) {
      throw e
    }
  }
}

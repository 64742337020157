import {
  state,
  getters,
  mutations,
  actions
} from '../wineBrowser/categorySubRegions'

export default {
  state,
  getters,
  mutations,
  actions
}

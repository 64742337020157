import Entity from './entity'

const fields = {
  UserID: {
    type: Number
  },
  UserName: {
    type: String
  },
  LoginName: {
    type: String
  },
  Password: {
    type: String
  },
  Disabled: {
    type: Boolean
  },
  Superuser: {
    type: Boolean
  },
  Hide: {
    type: Boolean
  },
  Developer: {
    type: Boolean
  },
  SageLoginName: {
    type: String
  },
  SagePassword: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  AuditCreatedBy: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  AuditAmendedBy: {
    type: String
  },
  AuditAmendedByUserName: {
    type: String
  },
  Initials: {
    type: String
  },
  EmailAddress: {
    type: String
  },
  LivExLoginName: {
    type: String
  },
  LivExPassword: {
    type: String
  },
  WineSearcherLoginName: {
    type: String
  },
  WineSearcherPassword: {
    type: String
  },
  BordeauxIndexLoginName: {
    type: String
  },
  BordeauxIndexPassword: {
    type: String
  },
  DisplayDebugs: {
    type: Boolean
  },
  AuthorisationID: {
    type: Number
  }
}

const User = new Entity('User', fields)

User.createQuery('get', 'users', fields)

User.createQuery(
  'getOptions',
  'users',
  Entity.onlyKeys(fields, ['UserID', 'Disabled', 'UserName'])
)

User.createMutation(
  'insert',
  'userAdd',
  Entity.onlyKeys(fields, [
    'UserName',
    'LoginName',
    'Password',
    'Disabled',
    'Superuser',
    'Hide',
    'Developer',
    'SageLoginName',
    'SagePassword',
    'Initials',
    'EmailAddress',
    'LivExLoginName',
    'LivExPassword',
    'WineSearcherLoginName',
    'WineSearcherPassword',
    'BordeauxIndexLoginName',
    'BordeauxIndexPassword',
    'AuthorisationID'
  ]),
  fields
)

User.createMutation(
  'update',
  'userUpdate',
  Entity.onlyKeys(fields, [
    'UserID',
    'UserName',
    'LoginName',
    'Password',
    'Disabled',
    'Superuser',
    'Hide',
    'Developer',
    'SageLoginName',
    'SagePassword',
    'Initials',
    'EmailAddress',
    'LivExLoginName',
    'LivExPassword',
    'WineSearcherLoginName',
    'WineSearcherPassword',
    'BordeauxIndexLoginName',
    'BordeauxIndexPassword',
    'AuditAmendedDate',
    'AuthorisationID'
  ]),
  fields
)

User.createMutation(
  'delete',
  'userDelete',
  {
    ID: fields.UserID,
    AuditAmendedDate: fields.AuditAmendedDate
  },
  []
)

export default User

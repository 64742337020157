<template>
  <b-form>
    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('FormatName') }"
        >
          Format Name
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="formatName"
          v-model="FormatName"
          type="text"
          :state="!$v.FormatName.$error && null"
          :class="{green: isDirty('FormatName') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="50"
        />
        <b-form-invalid-feedback>
          Format name is required
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('FormatCode') }"
        >
          Code
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="formatCode"
          v-model="FormatCode"
          type="text"
          :state="!$v.FormatCode.$error && null"
          :class="{green: isDirty('FormatCode') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="4"
        />
        <b-form-invalid-feedback>
          Format code is required and must be alphanumeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('CaseSize') }"
        >
          Case Size
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="formatCaseSize"
          v-model="CaseSize"
          type="text"
          :state="!$v.CaseSize.$error && null"
          :class="{green: isDirty('CaseSize') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Format case size is required and must be numeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('Volume') }"
        >
          Volume (ml)
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="formatVolume"
          v-model="Volume"
          type="text"
          :state="!$v.Volume.$error && null"
          :class="{green: isDirty('Volume') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Format volume is required and must be numeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('CaseWeightKG') }"
        >
          Case Weight (kg)
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="caseWeight"
          v-model="CaseWeightKG"
          type="text"
          :state="!$v.CaseWeightKG.$error && null"
          :class="{green: isDirty('CaseWeightKG') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Weight is required and must be numeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('SingleInvoiceUnit') }"
        >
          Single invoice unit
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="formatSingleInvoiceUnit"
          v-model="SingleInvoiceUnit"
          type="text"
          :state="!$v.SingleInvoiceUnit.$error && null"
          :class="{green: isDirty('SingleInvoiceUnit') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="20"
        />
        <b-form-invalid-feedback>
          Single invoice unit is required and must be alphanumeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('MultipleInvoiceUnit') }"
        >
          Multiple invoice unit
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="formatMultipleInvoiceUnit"
          v-model="MultipleInvoiceUnit"
          type="text"
          :state="!$v.MultipleInvoiceUnit.$error && null"
          :class="{green: isDirty('MultipleInvoiceUnit') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="20"
        />
        <b-form-invalid-feedback>
          Multiple invoice unit is required and must be alphanumeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('WineSearcherSingle') }"
        >
          Wine-Searcher Single
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="formatMultipleInvoiceUnit"
          v-model="WineSearcherSingle"
          type="text"
          :state="!$v.WineSearcherSingle.$error && null"
          :class="{green: isDirty('WineSearcherSingle') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="50"
        />
        <b-form-invalid-feedback>
          Wine Searcher Single unit is required and must be alphanumeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('WineSearcherMultiple') }"
        >
          Wine-Searcher Multiple
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="formatMultipleInvoiceUnit"
          v-model="WineSearcherMultiple"
          type="text"
          :state="!$v.WineSearcherMultiple.$error && null"
          :class="{green: isDirty('WineSearcherMultiple') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="50"
        />
        <b-form-invalid-feedback>
          Wine Searcher Single unit is required and must be alphanumeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label class="col-form-label-sm p-0 m-0">
          Wine Card Usage
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="wineCardUsage"
          v-model="WineCardUsage"
          type="text"
          trim
          size="sm"
          disabled
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  required,
  integer,
  alphaNum,
  numeric,
  maxLength
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapObjectToVModel([
      'FormatName',
      'FormatCode',
      'CaseSize',
      'Volume',
      'SingleInvoiceUnit',
      'MultipleInvoiceUnit',
      'WineCardUsage',
      'WineSearcherSingle',
      'WineSearcherMultiple',
      'CaseWeightKG'
    ])
  },
  validations: {
    FormatName: {
      required,
      maxLength: maxLength(50)
    },
    FormatCode: {
      alphaNum,
      maxLength: maxLength(4)
    },
    CaseSize: {
      integer,
      required
    },
    Volume: {
      integer,
      required
    },
    CaseWeightKG: {
      numeric,
      required
    },
    SingleInvoiceUnit: {
      required,
      maxLength: maxLength(20)
    },
    MultipleInvoiceUnit: {
      required,
      maxLength: maxLength(20)
    },
    WineSearcherSingle: {
      required,
      maxLength: maxLength(50)
    },
    WineSearcherMultiple: {
      required,
      maxLength: maxLength(50)
    }
  }
}
</script>

let axios

export const setToken = token => {
  axios.setToken('Bearer ' + token)
}

export const setInstance = instance => {
  axios = instance
}

export const getAxios = () => {
  return axios
}

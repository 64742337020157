import ExportTable from '~/components/Modal/Export/WineListExport/ExportTable'
import { merge } from 'lodash'

export default {
  components: {
    ExportTable
  },
  computed: {
    availableStock() {
      return this.$store.state.export.wineList.availableStock
    },
    allWholeCasesChecked() {
      return this.$store.state.export.wineList.allWholeCases
    },
    allSplitCasesChecked() {
      return this.$store.state.export.wineList.allSplitCases
    },
    sortBy() {
      return this.$store.state.export.wineList.sortBy
    },
    sortDesc() {
      return this.$store.state.export.wineList.sortDesc
    }
  },
  methods: {
    loadAvailableStockTable() {
      try {
        this.$store.dispatch(
          'export/wineList/getAvailableStock',
          merge(
            this.$store.getters['export/settings/searchParameters'],
            this.$store.getters['export/wineList/searchParameters'],
            this.$store.getters['wineBrowser/filters/exportParameters']
          )
        )
      } catch (e) {
        this.$notifyError(e)
      }
    },
    handleSort(sortObj) {
      this.$store.dispatch('export/wineList/sortAvailableStock', sortObj)
    },
    handleChangingExclude(item, value) {
      this.$store.commit('export/wineList/setExcluded', {
        UUID: item.UUID,
        ExcludeFlag: value
      })
      this.$store.dispatch('export/wineList/sortAvailableStock', {
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      })
      if (item.CaseSize === 1 || value === true) {
        this.setCases(item, false, false)
      }
    },
    handleChangingWholeCases(item, value) {
      this.setCases(item, value, this.allSplitCasesChecked)
    },
    handleChangingSplitCases(item, value) {
      this.setCases(item, this.allWholeCasesChecked, value)
    },
    handleChangeAllWholeCases(checked) {
      this.$store.commit('export/wineList/setAllWholeCase', checked)
      this.$store.commit('export/wineList/setAllSplitCase', false)
    },
    handleChangeAllSplitCases(checked) {
      this.$store.commit('export/wineList/setAllSplitCase', checked)
      this.$store.commit('export/wineList/setAllWholeCase', false)
    },
    handleChangeIncludeAll(item, value) {
      this.$store.commit('export/wineList/setIncludeAll', {
        UUID: item.UUID,
        IncludeAll: value
      })
    },
    setCases(item, wholeCases, splitCases) {
      if (wholeCases) {
        this.$store.commit('export/wineList/setWholeCase', {
          UUID: item.UUID,
          WholeCases: wholeCases
        })
        this.$store.commit('export/wineList/setSplitCase', {
          UUID: item.UUID,
          SplitCases: !wholeCases
        })
      } else if (splitCases) {
        this.$store.commit('export/wineList/setWholeCase', {
          UUID: item.UUID,
          WholeCases: !splitCases
        })
        this.$store.commit('export/wineList/setSplitCase', {
          UUID: item.UUID,
          SplitCases: splitCases
        })
      } else {
        this.$store.commit('export/wineList/setWholeCase', {
          UUID: item.UUID,
          WholeCases: false
        })
        this.$store.commit('export/wineList/setSplitCase', {
          UUID: item.UUID,
          SplitCases: false
        })
      }
      if (wholeCases || splitCases) {
        this.$store.commit('export/wineList/setExcluded', {
          UUID: item.UUID,
          ExcludeFlag: false
        })
      }
    }
  }
}

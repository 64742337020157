<template>
  <b-form>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Classification Name"
      label-for="ClassificationName"
      :label-class="{green: isDirty('ClassificationName') }"
    >
      <b-form-input
        id="ClassificationName"
        v-model="ClassificationName"
        :state="!$v.ClassificationName.$error && null"
        trim
        size="sm"
        :disabled="viewMode"
        :class="{green: isDirty('ClassificationName') }"
      />
      <b-form-invalid-feedback>
        Classification name is required
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapObjectToVModel(['ClassificationName'])
  },
  validations: {
    ClassificationName: {
      required
    }
  }
}
</script>

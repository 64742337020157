<template>
  <b-card body-class="p-0 w-100">
    <vue-custom-scrollbar
      class="virtual-scroll t-notes-export"
      :settings="settings"
    >
      <virtual-table
        :fields="tableFields"
        :items="paginatedResults"
        :per-page="perPage"
        :total="results.length"
        :show-loading="true"
        :estimate-total-scroll-height="false"
        :table-tr-class="setRowClass"
        class="d-flex flex-column"
        primary-key="UUID"
        @sort-changed="handleChangeSort"
        @request-page="requestPage"
      >
        <template slot="HEAD_WholeCases">
          Whole Cases<br>
          <b-checkbox :checked="allWholeCases" @change="handleChangeAllWholeCases" />
        </template>
        <template slot="HEAD_SplitCases">
          Split Cases<br>
          <b-checkbox :checked="allSplitCases" @change="handleChangeAllSplitCases" />
        </template>
        <template
          slot="WineParkerScore"
          slot-scope="data"
        >
          <template v-if="scoreMode==='orMore'">
            <template v-if="lowestRPScore(data.item.WineParkerScore) >= minParkerScore">
              {{ data.item.WineParkerScore }}
            </template>
          </template>
          <template v-if="scoreMode==='showAll'">
            {{ data.item.WineParkerScore }}
          </template>
        </template>
        <b-checkbox
          slot="ExcludeFlag"
          slot-scope="data"
          :checked="wineCardExcluded(data.item)"
          class="mx-auto"
          @change="handleChangingExcluded(data.item, $event)"
        />
        <stock-level
          slot="AllBottles"
          slot-scope="data"
          :justify="true"
          :total-bottles="data.item.AllBottles"
          :case-size="data.item.FormatCaseSize"
          :bottle-class="{red: wineCardIncludeAll(data.item)}"
          :case-class="{red: wineCardIncludeAll(data.item)}"
        />
        <stock-level
          slot="AvailableBottles"
          slot-scope="data"
          :justify="true"
          :total-bottles="data.item.AvailableBottles"
          :case-size="data.item.FormatCaseSize"
        />
        <stock-level
          slot="DisplayedBottles"
          slot-scope="data"
          :justify="true"
          :total-bottles="getDisplayTotalBottles(data.item)"
          :case-size="data.item.FormatCaseSize"
        />
        <template
          slot="WineSellingPrice"
          slot-scope="data"
        >
          <currency :value="data.item.SellingPrice" />
        </template>
        <template
          slot="OverrideMarginCheck"
          slot-scope="data"
        >
          <template v-if="data.item.OverrideMarginCheck">
            Yes
          </template>
          <template v-else>
            &nbsp;
          </template>
        </template>
        <template
          slot="WholeCases"
          slot-scope="data"
        >
          <template v-if="data.item.WholeCases">
            Yes
          </template>
        </template>
        <template
          slot="SplitCases"
          slot-scope="data"
        >
          <template v-if="data.item.SplitCases">
            Yes
          </template>
        </template>
        <template
          slot="EURPrice"
          slot-scope="data"
        >
          <currency :value="data.item.EURPrice" iso="EUR" />
        </template>
        <template
          slot="HKDPrice"
          slot-scope="data"
        >
          <currency :value="data.item.HKDPrice" iso="HKD" />
        </template>
        <b-checkbox
          slot="WholeCases"
          slot-scope="data"
          :checked="wineCardWholeCase(data.item)"
          class="mx-auto"
          @change="handleChangingWholeCases(data.item, $event)"
        />
        <b-checkbox
          slot="SplitCases"
          slot-scope="data"
          :checked="wineCardSplitCase(data.item)"
          class="mx-auto"
          @change="handleChangingSplitCases(data.item, $event)"
        />
        <template slot="IncludeAll" slot-scope="data">
          <b-checkbox
            v-if="data.item.AllBottles > data.item.AvailableBottles"
            :checked="wineCardIncludeAll(data.item)"
            class="mx-auto"
            @change="handleChangingIncludeAll(data.item, $event)"
          />
          <span v-else>
            &nbsp;
          </span>
        </template>
        <template
          slot="AvailableAvgCostPrice"
          slot-scope="data"
        >
          <currency :value="data.item.AvailableAvgCostPrice" />
        </template>
      </virtual-table>
    </vue-custom-scrollbar>
  </b-card>
</template>

<script>
import StockLevel from '~/components/Base/StockLevel'
import VirtualTable from '~/components/Base/VirtualTable'
import Currency from '../../../Base/Currency'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'

export default {
  components: {
    StockLevel,
    VirtualTable,
    Currency,
    vueCustomScrollbar
  },
  props: {
    results: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    selectedCurrencies: {
      type: Array,
      required: true
    },
    minParkerScore: {
      type: Number,
      required: true
    },
    scoreMode: {
      type: String,
      required: true
    },
    isWineBrowserBased: {
      type: Boolean,
      required: true
    },
    isGroupingCombined: {
      type: Boolean,
      default: true
    },
    allSplitCases: {
      type: Boolean,
      required: true
    },
    allWholeCases: {
      type: Boolean,
      required: true
    },
    sortByExclusion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 1,
      perPage: 74,
      settings: {
        tagname: 'div',
        suppressScrollY: true
      }
    }
  },
  computed: {
    paginatedResults() {
      if (this.sortByExclusion === false) {
        return this.results.slice(0, this.page * this.perPage)
      }
      const nonExcluded = []
      const excluded = []
      this.results.forEach(r => {
        if (this.wineCardExcluded(r)) {
          excluded.push(r)
        } else {
          nonExcluded.push(r)
        }
      })
      return nonExcluded.concat(excluded).slice(0, this.page * this.perPage)
    },
    tableFields() {
      const self = this
      const tableFields = []
      tableFields.push({
        key: 'WineVintage',
        label: 'Vintage',
        width: '50px',
        sortable: true
      })
      tableFields.push({
        key: 'ComputedWineName',
        label: 'Wine',
        width: '200px',
        sortable: true
      })
      if (this.scoreMode !== 'dontShow') {
        tableFields.push({
          key: 'WineParkerScore',
          label: 'Score',
          width: '50px',
          sortable: true
        })
      }
      tableFields.push({
        key: 'FormatName',
        label: 'Format',
        width: '50px',
        sortable: true
      })
      tableFields.push({
        key: 'ExcludeFlag',
        label: 'Exclude',
        tdClass: this.greenBackgroundColourByIndex + ' text-center',
        width: '50px',
        sortable: true
      })
      if (this.isWineBrowserBased === false) {
        tableFields.push({
          key: 'AllBottles',
          label: 'All Stock',
          width: '50px',
          sortable: true,
          tdClass(value, key, item) {
            let classes = self.greenBackgroundColourByIndex(value, key, item)
            if (item.IncludeAll) {
              classes += ' danger'
            }
            return classes
          }
        })
        tableFields.push({
          key: 'IncludeAll',
          label: 'Include All',
          sortable: true,
          tdClass(value, key, item) {
            const tempClass = self.greenBackgroundColourByIndex(
              value,
              key,
              item
            )

            return tempClass + ' text-center'
          },
          width: '50px'
        })
      }
      tableFields.push({
        key: 'AvailableBottles',
        label: this.isWineBrowserBased ? 'Available' : 'Purchases Available',
        tdClass: this.greenBackgroundColourByIndex,
        width: this.isWineBrowserBased ? '50px' : '90px',
        sortable: true
      })
      if (this.isGroupingCombined) {
        tableFields.push({
          key: 'WholeCases',
          label: 'Whole',
          tdClass(value, key, item) {
            const tempClass = self.greenBackgroundColourByIndex(
              value,
              key,
              item
            )

            return tempClass + ' text-center'
          },
          width: '50px',
          sortable: true
        })
        tableFields.push({
          key: 'SplitCases',
          label: 'Split',
          tdClass(value, key, item) {
            const tempClass = self.greenBackgroundColourByIndex(
              value,
              key,
              item
            )

            return tempClass + ' text-center'
          },
          width: '50px',
          sortable: true
        })
      } else {
        tableFields.push({
          key: 'FormattedVolume',
          label: 'Case Size',
          width: '80px',
          tdClass: 'text-center'
        })
        tableFields.push({
          key: 'Repack',
          label: 'Case Type',
          width: '80px',
          tdClass: 'text-center'
        })
      }
      tableFields.push({
        key: 'DisplayedBottles',
        label: 'Displayed',
        width: '100px',
        sortable: true
      })
      tableFields.push({
        key: 'WineSellingPrice',
        label: 'List Price',
        width: '100px',
        sortable: true,
        tdClass: 'text-right'
      })
      tableFields.push({
        key: 'OverrideMarginCheck',
        label: 'Override Margin',
        width: '50px',
        sortable: true
      })
      if (this.isWineBrowserBased === false) {
        tableFields.push({
          key: 'AvailableAvgCostPrice',
          label: 'Cost Price',
          width: '100px',
          sortable: true,
          tdClass(value, key, item) {
            if (item.AvailableAvgCostPrice > item.WineSellingPrice) {
              return 'danger text-right'
            }

            const tempClass = self.greenBackgroundColourByIndex(
              value,
              key,
              item
            )

            return tempClass + ' text-right'
          }
        })
      }
      if (this.currencySelected('EUR')) {
        tableFields.push({
          key: 'EURPrice',
          label: 'Bottle Eur',
          width: '80px',
          sortable: true,
          tdClass: 'text-right'
        })
      }
      if (this.currencySelected('HKD')) {
        tableFields.push({
          key: 'HKDPrice',
          label: 'Case HKD',
          width: '80px',
          sortable: true,
          tdClass: 'text-right'
        })
      }
      return tableFields
    }
  },
  methods: {
    requestPage(page) {
      this.page = page
    },
    currencySelected(code) {
      return this.selectedCurrencies.indexOf(code) >= 0
    },
    lowestRPScore(score) {
      return this.$options.filters.lowestRPScore(score)
    },
    greenBackgroundColourByIndex(value, key, item) {
      return item.index % 2 === 0
        ? 'light-green-background'
        : 'light-green-offset-background'
    },
    getDisplayTotalBottles(item) {
      const key = 'export/wineList/getDisplayTotalBottles'
      return this.$store.getters[key](item.UUID)
    },
    handleChangingExcluded(item, value) {
      this.$emit('change-exclude', item, value)
    },
    handleChangingWholeCases(item, value) {
      this.$emit('change-whole-cases', item, value)
    },
    handleChangingSplitCases(item, value) {
      this.$emit('change-split-cases', item, value)
    },
    handleChangeAllWholeCases(value) {
      this.$emit('change-all-whole-cases', value)
    },
    handleChangeAllSplitCases(value) {
      this.$emit('change-all-split-cases', value)
    },
    handleChangingIncludeAll(item, value) {
      this.$emit('change-include-all', item, value)
    },
    wineCardExcluded(item) {
      const key = 'export/wineList/wineCardExcluded'
      return this.$store.getters[key](item.UUID)
    },
    wineCardWholeCase(item) {
      const key = 'export/wineList/wineCardWholeCase'
      return this.$store.getters[key](item.UUID)
    },
    wineCardSplitCase(item) {
      const key = 'export/wineList/wineCardSplitCase'
      return this.$store.getters[key](item.UUID)
    },
    wineCardIncludeAll(item) {
      const key = 'export/wineList/wineCardIncludeAll'
      return this.$store.getters[key](item.UUID)
    },
    handleChangeSort(sort) {
      this.$emit('sort', sort)
    },
    setRowClass(item) {
      const key = 'export/wineList/getDisplayTotalBottles'
      const totalBottles = this.$store.getters[key](item.UUID)
      if (totalBottles <= 0) {
        return item.index % 2 === 0
          ? 'row-danger-background'
          : 'row-danger-background-lighter'
      }
      return ''
    }
  }
}
</script>
<style>
.t-notes-export table {
  width: 100%;
}

.t-notes-export .virtual-scroll__content table tr,
.t-notes-export .virtual-scroll__content table td {
  background: #f5ebb5;
}

.t-notes-export .virtual-scroll__content table tr:nth-child(odd) td {
  background: #fefcd5;
}

.t-notes-export .virtual-scroll__content table td.light-green-background {
  background: #cadfc7;
}

.t-notes-export
  .virtual-scroll__content
  table
  td.light-green-offset-background {
  background: #e3efe1;
}

.t-notes-export .virtual-scroll__content.ps--active-y table {
  width: calc(100% + 15px);
}

@media (min-width: 1630px) {
  .t-notes-export {
    position: relative;
  }
  .t-notes-export .virtual-scroll__wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .t-notes-export .virtual-scroll__content {
    height: 100%;
  }
}
</style>

<template>
  <span>
    <a v-if="anchor" href="#" :disabled="disabled" @click="openUrl">
      <slot>
        Burghound Web Site
      </slot>
    </a>
    <button v-else :class="buttonClass" :disabled="disabled" @click="openUrl">
      <slot>
        Burghound Web Site
      </slot>
    </button>
  </span>
</template>

<script>
export default {
  props: {
    wineName: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: String,
      required: false,
      default: ''
    },
    anchor: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: 'btn wv-button wv-button-gray-gradient wb-button-no-padding'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url() {
      let url = 'http://www.burghound.com/{keywords}'
      url = url.replace('{keywords}', encodeURIComponent(this.wineName))
      return url
    }
  },
  methods: {
    openUrl() {
      if (this.disabled === false) {
        window.open(this.url)
        this.$emit('click')
      } else {
        this.$emit('disabled-click')
      }
    }
  }
}
</script>

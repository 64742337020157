<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :hide-footer="true"
    size="md"
    ok-title="Update"
    @hidden="$emit('hidden')"
    @ok="save"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <template v-slot:default="{ ok, hidden }">
      <b-row>
        <b-col class="col-12 col-xxl-7 py-0">
          <wine-quantity-form
            ref="form"
            :value="calculatedBottles"
            :show-cases="showCases"
            :bottle-title="item.format"
            @change="handleFormChange"
          />
        </b-col>
        <b-col class="col-12 col-xxl-3 py-0">
          <b-button class="btn wv-btn btn-secondary btn-sm mb-2 d-block w-100" @click="save">
            OK
          </b-button>
          <b-button class="btn wv-btn btn-secondary btn-sm m-0 d-block w-100" @click="shown=false">
            Cancel
          </b-button>
        </b-col>
        <b-col class="col-12 col-xxl-2 py-0">
          <img src="/images/wine-v2-48.png" class="mt-2">
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import WineQuantityForm from '~/components/Forms/WineQuantityForm'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'
import FanOutToSearchStoreMixin from '~/mixins/winebrowser/FanOutToSearchStores'
import { formResultMixin } from '~/mixins/forms'
import { updateReservedStock } from '@/api/wineBrowser/wines'

export default {
  components: {
    WineQuantityForm
  },
  mixins: [formResultMixin, TitleFromWineCard, FanOutToSearchStoreMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      titleSuffix: 'WVR'
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    showCases() {
      return this.item.case_size > 1
    },
    calculatedBottles() {
      if (this.item.case_size === 1) {
        return {
          cases: 0,
          bottles: this.item.reservedBottles
        }
      }
      return this.item.reserve_level
    }
  },
  methods: {
    reset() {
      this.$refs.form.reset()
    },
    async save(evnt) {
      try {
        const valid = await this.$refs.form.validate()
        if (valid) {
          const formBottles = parseInt(this.form.bottles)
          const formCases = parseInt(this.form.cases)

          const bottles = isNaN(formBottles)
            ? this.item.reserve_level.bottles
            : formBottles

          const cases = isNaN(formCases)
            ? this.item.reserve_level.cases
            : formCases

          const caseSize = this.item.case_size
          const reservedBottles = cases * caseSize + bottles
          const updatedReserve = await updateReservedStock({
            id: this.item.id,
            bottles: reservedBottles
          })
          if (updatedReserve) {
            this.commitToSearches('wineCards/replaceResultById', {
              id: this.item.id,
              result: {
                reserve_level: {
                  bottles,
                  cases
                }
              }
            })
            this.shown = false
            this.$notify.success('Reserve level updated!')
          } else {
            throw new Error('Unable to update reserve level.')
          }
        }
      } catch (e) {
        evnt.preventDefault()
        this.shown = true
        this.$emit('error', e)
      }
    }
  }
}
</script>

import Entity from '~/models/entity'
import { fields as inventoryFields } from '~/models/inventory'
import { fields as wineCardFields } from '~/models/winecard'
import { pick } from 'lodash'

export const fields = {
  BRIID: {
    type: Number
  },
  BRID: {
    type: Number
  },
  BRCID: {
    type: Number
  },
  InventoryID: {
    type: Number
  },
  ReservePOID: {
    type: Number
  },
  OriginalSOID: {
    type: Number
  },
  OriginalSODate: {
    type: String
  },
  Price: {
    type: Number
  },
  Bottles: {
    type: Number
  },
  FromDate: {
    type: String
  },
  ToDate: {
    type: String
  },
  Process: {
    type: Boolean
  },
  Waive: {
    type: Boolean
  },
  // Dirty: {
  //   type: Boolean
  // },
  Processed: {
    type: Boolean
  },
  WineCard: {
    type: Array,
    fields: {
      ...pick(wineCardFields, [
        'WineVintage',
        'ComputedWineName',
        'FormatName',
        'WineCardID'
      ]),
      ...{
        Format: {
          type: Array,
          fields: {
            CaseSize: {
              type: Number
            }
          }
        }
      }
    }
  },
  Inventory: {
    type: Array,
    fields: {
      ...pick(inventoryFields, [
        'RotationCardID',
        'RotationNumber',
        'LCBLocation',
        'InventoryStatusID',
        'UsageCode'
      ])
    }
  },
  ArchivedPrice: {
    type: Number
  },
  ArchivedOrigPkgCaseSize: {
    type: Number
  },
  ArchivedRotationCardNumber: {
    type: String
  }
}

const BillingRunInventory = new Entity('BillingRunInventory', fields)

// BillingRunInventory.createQuery('get', 'billingRunGet', fields)
BillingRunInventory.createQuery(
  'getAll',
  'billingRunInventoryGetAll',
  {
    total: {
      type: Number
    },
    billingRunInventoryPaginatedResults: {
      type: Array,
      fields: fields
    }
  },
  {
    // Status: {
    //   type: Number
    // },
    // Search: {
    //   type: String
    // },
    PerPage: {
      type: Number
    },
    Page: {
      type: Number
    }
  }
)

BillingRunInventory.createMutation('update', 'billingRunInventoryUpdate', {
  ...pick(fields, ['BRIID', 'FromDate', 'Price']),
  ...{
    Commit: {
      type: Boolean
    }
  }
})

BillingRunInventory.createMutation(
  'updateBulk',
  'billingRunInventoryBulkUpdate',
  {
    ...pick(fields, ['Price']),
    ...{
      BRIIDS: {
        type: Array,
        fields: {
          BRIID: {
            type: Number
          }
        }
      }
    },
    ...{
      FromDates: {
        type: Array,
        fields: {
          FromDate: {
            type: String
          }
        }
      }
    },
    ...{
      Commit: {
        type: Boolean
      }
    }
  }
)

BillingRunInventory.createMutation(
  'updateBillingRunContact',
  'billingRunContactUpdate',
  {
    ...pick(fields, ['BRID', 'BRCID'])
  }
)

export default BillingRunInventory

import { client } from '../graphQLInstance'
import EmptyCases from '~/models/emptycases'

export const getEmptyCasesForWineCardID = async WineCardID => {
  const result = await client.query({
    query: EmptyCases.query('getAll', { WineCardID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[EmptyCases.queries.getAll.name]
}

export const getEmptyCasesForWineCardIDAndPOLID = async args => {
  const result = await client.query({
    query: EmptyCases.query('getAll', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[EmptyCases.queries.getAll.name]
}

export const attachEmptyCase = async ({ EmptyCaseID, POLID }) => {
  const result = await client.query({
    query: EmptyCases.mutate('attach', { EmptyCaseID, POLID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[EmptyCases.mutations.attach.name]
}

export const detachEmptyCase = async ({ EmptyCaseID, POLID }) => {
  const result = await client.query({
    query: EmptyCases.mutate('detach', { EmptyCaseID, POLID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[EmptyCases.mutations.detach.name]
}

export const addEmptyCase = async args => {
  const result = await client.query({
    query: EmptyCases.mutate('create', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[EmptyCases.mutations.create.name]
}

export const updateEmptyCase = async args => {
  const result = await client.query({
    query: EmptyCases.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[EmptyCases.mutations.update.name]
}

export const deleteEmptyCase = async args => {
  const result = await client.query({
    query: EmptyCases.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[EmptyCases.mutations.delete.name]
}

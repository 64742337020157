import Entity from './entity'

const fields = {
  ContactStatusID: {
    type: Number
  },
  Status: {
    type: String
  }
}

const ContactStatusDropDown = new Entity('ContactStatusDropDown', fields)
ContactStatusDropDown.createQuery('get', 'contactStatusDropDown')

export default ContactStatusDropDown

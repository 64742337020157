<template>
  <div class="my-3">
    <b-form-group
      label="Quantity"
      label-cols-xl="5"
      label-size="sm"
      label-class="pt-4"
    >
      <wine-quantity-form
        ref="qtyForm"
        :value="qtyFormData"
        :show-cases="showCases"
        :bottle-title="bottleTitle"
        @change-cases="cases = $event"
        @change-bottles="bottles = $event"
        @valid="handleFormValid($event,'qtyForm')"
      />
    </b-form-group>
    <b-form-group
      v-if="itemLoosePrice > 0"
      label="Selling Price"
      label-for="salesPrice"
      label-cols-xl="5"
      label-size="sm"
      label-class=""
      class="justify-content-center align-items-center"
    >
      <b-row>
        <b-col>
          <b-button
            class="btn wv-btn wv-btn--xs wv-btn__secondary btn-sm btn-block"
            :pressed="salesPrice === itemSellingPrice"
            @click="salesPrice = itemSellingPrice; priceSet = true"
          >
            List
          </b-button>
        </b-col>
        <b-col>
          <b-button
            class="btn wv-btn wv-btn--xs wv-btn__secondary btn-sm btn-block"
            :pressed="salesPrice === itemLoosePrice"
            @click="salesPrice = itemLoosePrice; priceSet = true"
          >
            Loose
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group
      label="Sales Price £"
      label-for="salesPrice"
      label-cols-xl="5"
      label-size="sm"
    >
      <price-confirmation-change
        v-model="salesPrice"
        size="sm"
        :state="!$v.salesPrice.$error && null"
        :show-confirm="false"
        @keyup="priceSet = true"
      />
      <b-form-invalid-feedback>
        Sales Price is required and must be a number
      </b-form-invalid-feedback>
    </b-form-group>

    <b-modal
      id="modal-1"
      v-model="showSellingPriceModal"
      centered
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      :hide-header-close="true"
      @ok="handleSellingPriceModel"
    >
      <template v-slot:modal-header>
        Are you sure?
      </template>
      <p class="mb-0">
        Sales Price is lower than the 
        <template v-if="salesPrice < itemSellingPrice">List Price</template><template v-if="salesPrice < itemSellingPrice && salesPrice < itemLoosePrice"> &amp; </template><template v-if="salesPrice < itemLoosePrice">Loose Bottle Price</template>.
      </p>
      <template v-slot:modal-footer="{ok,cancel}">
        <b-button class="btn wv-btn btn-secondary btn-sm" @click="ok()">
          Ok
        </b-button>
        <b-button class="btn wv-btn btn-secondary btn-sm" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required, decimal, minValue } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { formObjectMixin } from '../../mixins/forms'
import { mapObjectToVModel } from '../../helpers/helpers'
import PriceConfirmationChange from '../Base/PriceConfirmationChange'
import WineQuantityForm from './WineQuantityForm'

export default {
  components: {
    WineQuantityForm,
    PriceConfirmationChange
  },
  mixins: [validationMixin, formObjectMixin],
  props: {
    bottleTitle: {
      type: String,
      default: 'Bottles'
    },
    showCases: {
      type: Boolean,
      default: true
    },
    usageOptions: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    itemSellingPrice: {
      type: Number,
      required: true
    },
    itemCaseSize: {
      type: Number,
      required: true
    },
    itemLoosePrice: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      showSellingPriceModal: false,
      priceSet: false
    }
  },
  computed: {
    ...mapObjectToVModel(['bottles', 'cases', 'salesPrice']),
    qtyFormData() {
      return {
        bottles: this.value.bottles || null,
        cases: this.value.cases || null
      }
    },
    totalBottles() {
      return (
        parseInt(this.cases || 0) * parseInt(this.itemCaseSize) +
        parseInt(this.bottles || 0)
      )
    }
  },
  watch: {
    totalBottles(bottles) {
      this.$emit('total-bottles', bottles)
    }
    // This auto toggles the prices, it was decided it was annoying
    // bottles(bottles) {
    //   if (
    //     !this.priceSet &&
    //     bottles > 0 &&
    //     this.itemLoosePrice &&
    //     this.salesPrice === this.itemSellingPrice
    //   ) {
    //     this.salesPrice = this.itemLoosePrice
    //   }
    // },
    // cases(cases) {
    //   if (
    //     !this.priceSet &&
    //     cases > 0 &&
    //     this.itemLoosePrice &&
    //     this.salesPrice === this.itemLoosePrice &&
    //     (this.bottles === 0 || this.bottles === undefined)
    //   ) {
    //     this.salesPrice = this.itemSellingPrice
    //   }
    // }
  },
  validations: {
    salesPrice: {
      required,
      decimal,
      minValue: minValue(1)
    }
  },
  subValidations: ['qtyForm'],
  methods: {
    reset() {
      this.formReset()
      this.showSellingPriceModal = false
      return this
    },
    handleSellingPriceModel(ok) {
      this.showSellingPriceModal = false
      this.resolveResponse()
    },
    afterValidate() {
      return new Promise((resolve, reject) => {
        const price = parseFloat(this.salesPrice)
        if (this.totalBottles < 1) {
          return reject(new Error('Invalid quantity entered.'))
        }
        if (price < 1) {
          return reject(new Error('Invalid price.'))
        }
        if (price < this.itemSellingPrice || price < this.itemLoosePrice) {
          this.showSellingPriceModal = true
          return this.awaitResponse(resolve, reject)
        }
        resolve()
      })
    }
  }
}
</script>

<template>
  <group-box :header="true">
    <template slot="header">
      Usage
    </template>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="usageCodes" :value="[WineUsage.PUB]" class="text-left">
          PUB
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="usageCodes" :value="[WineUsage.WFS]" class="text-left">
          WFS
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="usageCodes" :value="[WineUsage.PUB, WineUsage.WFS]" class="text-left">
          PUB & WFS
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="usageCodes" :value="[WineUsage.PUB, WineUsage.BRO]" class="text-left">
          PUB & BRO
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="usageCodes" :value="[WineUsage.NFS]" class="text-left">
          NFS
        </d-form-radio>
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import { WineUsage } from '~/constants/wineUsage'

export default {
  components: {
    GroupBox
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    usageCodes: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.WineUsage = WineUsage
  }
}
</script>

<template>
  <div>
    <data-modal
      v-model="shown"
      heading="Producer Selection"
      search-placeholder="Producer Filter ..."
      primary-key="ProducerID"
      filter-key="ProducerName"
      :loading="producersLoading"
      :fields="fields"
      :items="getProducerList"
      :selected="getActiveProducer"
      :delete-disabled="!isActiveItemDeletable"
      :deleting="deleting"
      scrollbar-class="t-producer-name"
      @shown="refresh"
      @new="toggleNewProducer"
      @edit="toggleEditProducer"
      @view="toggleViewProducer"
      @delete="toggleDeleteProducer"
      @selected="rowSelected"
      @dbl-click="rowDoubleClicked"
      @ok="handleOK"
    >
      <template slot="UseOnBackOffice" slot-scope="data">
        <span v-if="data.item.UseOnBackOffice">
          Yes
        </span>
        <span v-else>
          No
        </span>
      </template>
    </data-modal>
    <new-producer-modal
      v-model="boolNewProducerModal"
      :loading-prop="producerLoading"
      @saved="rowSelected"
    />
    <edit-producer-modal
      v-if="getActiveProducer"
      v-model="boolEditProducerModal"
      :producer="getActiveProducer"
    />
    <view-producer-modal
      v-if="getActiveProducer"
      v-model="boolViewProducerModal"
      :producer="getActiveProducer"
    />
    <confirm-modal
      :value="boolConfirmDeleteModal"
      @ok="deleteProducerConfirm"
      @cancel="deleteProducerCancel"
      @change="deleteProducerChange"
    >
      Are you sure you want to delete the selected producer?
    </confirm-modal>
  </div>
</template>

<script>
import NewProducerModal from '~/components/Modal/Administration/NewProducerModal'
import EditProducerModal from '~/components/Modal/Administration/EditProducerModal'
import ViewProducerModal from '~/components/Modal/Administration/ViewProducerModal'
import ConfirmModal from '~/components/Modal/Confirm'
import { mapGetters, mapState } from 'vuex'
import DataModal from '../../Administration/DataModal'

export default {
  components: {
    NewProducerModal,
    EditProducerModal,
    ViewProducerModal,
    ConfirmModal,
    DataModal
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    dblClickEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      boolNewProducerModal: false,
      boolEditProducerModal: false,
      boolViewProducerModal: false,
      boolConfirmDeleteModal: false,
      deleting: false,
      fields: {
        ProducerName: {
          label: 'Producer Name',
          sortable: true
        },
        WineCardUsage: {
          label: 'Wine Card Usage',
          sortable: true,
          tdClass: 'text-right',
          width: '100px'
        },
        UseOnBackOffice: {
          label: 'On Backoffice',
          sortable: false,
          class: 'text-center'
        }
      }
    }
  },
  computed: {
    ...mapGetters('administration/producers', [
      'getProducerList',
      'getActiveProducer'
    ]),
    ...mapState('administration/producers', {
      producersLoading: state => state.loading,
      producerLoading: state => state.loadingProducer
    }),
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isActiveItemDeletable() {
      return (
        this.getActiveProducer && this.getActiveProducer.WineCardUsage === 0
      )
    }
  },
  methods: {
    toggleNewProducer() {
      this.boolNewProducerModal = !this.boolNewProducerModal
    },
    toggleEditProducer() {
      this.boolEditProducerModal = !this.boolEditProducerModal
    },
    toggleViewProducer() {
      this.boolViewProducerModal = !this.boolViewProducerModal
    },
    toggleDeleteProducer() {
      this.boolConfirmDeleteModal = !this.boolConfirmDeleteModal
    },
    rowSelected(item) {
      this.$store.dispatch('administration/producers/setActiveProducer', item)
    },
    rowDoubleClicked(item) {
      this.$emit('selected', item)
      if (this.dblClickEdit) {
        this.toggleEditProducer()
      }
    },
    cancel() {
      this.$emit('cancel')
      this.shown = false
    },
    ok() {
      this.$emit('selected', this.getActiveProducer)
    },
    refresh() {
      this.$store.dispatch('administration/producers/loadList')
    },
    async deleteProducerConfirm() {
      try {
        this.deleting = true
        this.boolConfirmDeleteModal = false
        await this.$store.dispatch(
          'administration/producers/deleteProducer',
          this.getActiveProducer
        )
        this.$notify.success('Producer was deleted')
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.deleting = false
      }
    },
    deleteProducerCancel() {
      this.boolConfirmDeleteModal = false
    },
    deleteProducerChange() {
      this.boolConfirmDeleteModal = false
    },
    handleOK(item) {
      if (item) {
        this.$emit('selected', item)
      }
      this.$emit('ok', item)
      this.shown = false
    }
  }
}
</script>

<style>
@media (min-width: 1630px) {
  .t-producer-name .virtual-scroll__content {
    height: 100%;
  }
}
</style>

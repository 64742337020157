import ContactWinePromotion from '../../models/contactwinepromotion'
import { client } from '../graphQLInstance'

export const batchUpdateContactWinePromotions = async args => {
  const result = await client.query({
    query: ContactWinePromotion.mutate('batchUpdate', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[ContactWinePromotion.mutations.batchUpdate.name]
}

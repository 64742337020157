<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    footer-class="justify-content-center"
    :hide-header-close="true"
    size="md"
    ok-title="Add Empty Case"
    :title="title"
    :ok-disabled="loading || saving"
    :cancel-disabled="loading || saving"
    @hidden="$emit('hidden')"
    @shown="reset"
    @ok="save"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <div v-if="loading" class="text-center text-primary">
      <b-spinner type="grow" label="Loading..." />
    </div>
    <template v-else>
      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>
      <empty-case-form ref="form" :volume="volume" :value="formData" @change="handleFormChange" />
    </template>
    <template v-slot:modal-footer="{ ok }">
      <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="ok()">
        <b-spinner v-if="saving" type="grow" label="Loading..." small />
        OK
      </b-button>
      <b-button class="btn wv-btn btn-sm" @click="shown=false">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import EmptyCaseForm from '~/components/Forms/EmptyCaseForm'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'
import FanOutToSearchStoreMixin from '~/mixins/winebrowser/FanOutToSearchStores'
import { formResultMixin } from '~/mixins/forms'

export default {
  components: {
    EmptyCaseForm
  },
  mixins: [formResultMixin, TitleFromWineCard, FanOutToSearchStoreMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    caseData: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      error: null
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    loading() {
      return this.$store.state.formats.loading
    },
    saving() {
      return this.$store.state.wineCard.emptyCases.saving
    },
    format() {
      return this.$store.getters['formats/getById'](this.item.format_id)
    },
    volume() {
      if (this.format) {
        return 'x ' + parseInt(this.format.Volume / 10)
      }
      return 'x 0'
    },
    formData() {
      return {
        size: this.caseData.CaseSize,
        notes: this.caseData.Notes,
        comments: this.caseData.Comments,
        location: this.caseData.Location,
        locationPrefix: this.caseData.LocationPrefix,
        type: this.caseData.Type
      }
    }
  },
  methods: {
    async save(e) {
      e.preventDefault()
      if (this.saving) {
        return
      }
      try {
        await this.$refs.form.validate()
        const emptyCase = {
          EmptyCaseID: this.caseData.EmptyCaseID,
          WineCardID: this.item.id,
          ChateauText: this.item.wine_name,
          VintageText: this.item.year,
          FormatText: this.form.size + 'x' + parseInt(this.format.Volume / 10),
          CaseSize: this.form.size,
          Notes: this.form.notes,
          Location: this.form.location,
          LocationPrefix: this.form.locationPrefix,
          Comments: this.form.comments,
          Type: this.form.type,
          AuditAmendedDate: this.caseData.AuditAmendedDate || null
        }
        const savedEmptyCase = await this.$store.dispatch(
          'wineCard/emptyCases/updateEmptyCase',
          emptyCase
        )
        this.commitToSearches('wineCards/setEmptyCasesForId', savedEmptyCase)
        this.$store.dispatch('wineCard/activeCard/reloadActiveCard')

        this.shown = false
      } catch (e) {
        this.error = e
      }
    },
    reset() {
      this.form = {}
      this.error = null
      this.$refs.form.reset()
      this.$store.dispatch('formats/getFormatIfNotExists', this.item.format_id)
    }
  }
}
</script>

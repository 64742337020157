import { getSearchResults } from '@/api/salesOrders/salesMatches'
import { reduce, concat, without, findIndex } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  page: 1,
  perPage: 74,
  total: 0,
  loadedPage: [],
  pageOffsets: {},
  results: [],
  indexedId: {},
  orderBy: null,
  orderByDirection: 'ASC',
  selected: [],
  lnd: false,
  searchParameters: {
    SearchTerm: '',
    WineSearch: '',
    FromTime: null,
    ToTime: null,
    Unallocated: false,
    WithReserves: false,
    WithStorage: false,
    WithSales: false
  }
})

export const getters = {
  pageResults: state => {
    const offset = state.pageOffsets[state.page] || 0
    return state.results.slice(offset, offset + state.perPage)
  },
  selected: state => id => {
    return state.selected.indexOf(id) >= 0
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLnd(state, lnd) {
    state.lnd = lnd === true
  },
  setResults(state, results) {
    state.results = results
    state.indexedId = reduce(
      results,
      (keyMap, r, i) => {
        keyMap[r.id] = i
        return keyMap
      },
      {}
    )
  },
  appendResults(state, results) {
    state.results = concat(state.results, results)
    state.indexedId = reduce(
      results,
      (keyMap, r, i) => {
        keyMap[r.id] = i
        return keyMap
      },
      state.indexedId
    )
  },
  mergeFinalised(state, finalisedSOIDs) {
    finalisedSOIDs.forEach(SOID => {
      const idx = findIndex(state.results, { SOID })
      if (idx >= 0) {
        const order = state.results[idx]
        order.WorkflowStatusDescription = 'Finalised'
        Vue.set(state.results, idx, order)
      }
    })
  },
  resetSelection(state) {
    state.results = []
    state.indexedId = {}
    state.page = 1
    state.loadedPage = []
    state.pageOffsets = {}
    state.setActiveId = null
    state.selected = []
  },
  resetSearchParameters(state) {
    state.lnd = false
    state.searchParameters = {
      SearchTerm: '',
      WineSearch: '',
      FromTime: null,
      ToTime: null,
      Unallocated: false,
      WithReserves: false,
      WithStorage: false
    }
  },
  resetJustSearchFilters(state) {
    state.lnd = false
    state.searchParameters = {
      SearchTerm: state.searchParameters.SearchTerm,
      WineSearch: '',
      FromTime: null,
      ToTime: null,
      Unallocated: false,
      WithReserves: false,
      WithStorage: false
    }
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setPageOffset(state, page) {
    Vue.set(state.pageOffsets, page, state.results.length)
  },
  setLoadedPage(state, page) {
    if (state.loadedPage.indexOf(page) < 0) {
      state.loadedPage.push(page)
    }
  },
  setOrderBy(state, key) {
    state.orderBy = key
  },
  setOrderByDirection(state, direction) {
    state.orderByDirection = direction === 'ASC' ? 'ASC' : 'DESC'
  },
  setSelectedValue(state, { id, value }) {
    if (value) {
      if (state.selected.indexOf(id) < 0) {
        state.selected.push(id)
      }
    } else {
      state.selected = without(state.selected, id)
    }
  },
  setSearchParameter(state, { key, value }) {
    if (key === 'LND') {
      state.lnd = value
    } else {
      Vue.set(state.searchParameters, key, value)
    }
  }
}

export const actions = {
  async performSearch({ commit, state }, { searchParameters }) {
    commit('setLoading', true)
    commit('resetSelection')
    searchParameters.OrderBy = state.orderBy
    searchParameters.OrderByDirection = state.orderByDirection
    const response = await getSearchResults(
      state.page,
      state.perPage,
      searchParameters
    )
    commit('setPageOffset', state.page)
    commit('setResults', response.salesOrderSearchResult)
    commit('mergeFinalised', response.finalised)
    commit('setTotal', response.total)
    commit('setLoadedPage', state.page)
    commit('setLoading', false)
    return response
  },
  async changePage({ commit, state }, { page, searchParameters }) {
    if (state.loadedPage.indexOf(page) >= 0) {
      commit('setPage', page)
      return
    }
    commit('setPage', page)
    commit('setLoading', true)
    searchParameters.OrderBy = state.orderBy
    searchParameters.OrderByDirection = state.orderByDirection
    const response = await getSearchResults(
      page,
      state.perPage,
      searchParameters
    )
    commit('setPageOffset', page)
    commit('appendResults', response.salesOrderSearchResult)
    commit('mergeFinalised', response.finalised)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  },
  changeOrder(
    { commit, state, dispatch },
    { key, searchParameters, forWarehouse }
  ) {
    const doDesc = state.orderBy === key && state.orderByDirection === 'ASC'
    commit('resetSelection')
    commit('setOrderBy', key)
    commit('setOrderByDirection', doDesc ? 'DESC' : 'ASC')
    dispatch('performSearch', { searchParameters, forWarehouse })
  }
}

<template>
  <div>
    <a v-if="anchor" href="#" :disabled="disabled" @click="openUrl">
      <slot>
        <img src="/images/wine-searcher-button.png" :style="imageStyle" class="w-100 h-auto">
      </slot>
    </a>
    <button v-else :class="buttonClass" :disabled="disabled" @click="openUrl">
      <slot>
        <img src="/images/wine-searcher-button.png" :style="imageStyle" class="w-100 h-auto">
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    wineName: {
      type: String,
      required: false,
      default: ''
    },
    vintage: {
      type: [String, Number],
      required: false,
      default: ''
    },
    width: {
      type: String,
      required: false,
      default: ''
    },
    anchor: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: 'btn wv-btn  wv-btn__image'
    }
  },
  computed: {
    url() {
      let url =
        'http://www.wine-searcher.com/find/{keywords}/{vintage}/europe/-/a?Xlist_format=N&Xbottle_size=Case&Xprice_set=&Xprice_min=&Xprice_max=&Xcurrencycode=GBP&Xsavecurrency=Y'
      url = url.replace('{lwin}', encodeURIComponent(this.lwin))
      url = url.replace('{keywords}', encodeURIComponent(this.keywords))
      if (String(this.vintage).toUpperCase() === 'NV') {
        url = url.replace('{vintage}', 'NV')
      } else {
        url = url.replace('{vintage}', encodeURIComponent(String(this.vintage)))
      }
      return url
    },
    keywords() {
      let keywords = this.wineName
      keywords = keywords.replace(/-/g, ' ')
      keywords = keywords.replace(/\s/g, '+')
      return keywords
    },
    imageStyle() {
      return {
        width: this.width + 'px'
      }
    }
  },
  methods: {
    openUrl() {
      if (this.disabled === false) {
        window.open(this.url)
        this.$emit('click')
      } else {
        this.$emit('disabled-click')
      }
    }
  }
}
</script>

import { client } from '../graphQLInstance'
import WarehouseAccount from '../../models/warehouseaccount'

export const getWarehouseAccountByContactId = async id => {
  const result = await client.query({
    query: WarehouseAccount.query('getAll', {
      ContactID: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WarehouseAccount.queries.getAll.name]
}

export const updateWarehouseAccount = async args => {
  const result = await client.query({
    query: WarehouseAccount.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WarehouseAccount.mutations.update.name]
}

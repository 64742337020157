<template>
  <div class="wv-wine-card">
    <wine-card-form
      ref="form"
      :value="defaultWineCard"
      :chosen-wine-name="chosenWineName"
      :chosen-producer="chosenProducer"
      :format-options-prop="formatOptions"
      :category-options-prop="categoryOptions"
      :country-options-prop="countryOptions"
      :wine-colour-options-prop="wineColourOptions"
      :usage-options-prop="usageOptions"
      :loading-prop="formLoading"
      :designation-options="designationOptions"
      :classification-options="classificationOptions"
      :country-region-options="countryRegionOptions"
      :country-sub-region-options="countrySubRegionOptions"
      :vineyard-options="vineyardOptions"
      :saving="loading"
      @add-wine-card-format="toggleNewFormatModal"
      @wine-card-wine-name-modal="toggleWineNamesModal"
      @wine-card-producer-modal="toggleProducersModal"
      @clickNew="newWineCard"
      @clickSave="saveWineCard"
      @scores-cleared="handleScoresCleared"
      @change="handleFormChange"
      @disabled-link-click="handleDisabledClick"
      @updated-using-lwin="handleLwinUpdate"
    />
    <wine-name-modal
      v-model="boolWineNamesModal"
      @selected="wineNameSelected"
      @cancel="toggleWineNamesModal"
      @delete-wine-name="handleDeleteWineName"
    />
    <new-format-modal
      v-model="boolNewFormatModal"
      @saved="newFormatSaved"
    />
    <producers-modal
      v-model="boolProducersModal"
      @selected="producerSelected"
      @cancel="toggleProducersModal"
    />
    <confirm-modal v-model="boolShowNewWineCardConfirmation" @ok="newWineCardConfirmation">
      <p>This will remove any changes that you have made on this screen since the last save</p>
      <p class="m-0">Are you sure you want to continue?</p>
    </confirm-modal>
    <alert-modal v-model="scoresChangedModalAlert" title="Vintage Changed">
      Since you have changed the Vintage, the Parker and <br>
      Burghound Scores and Tasting Notes have been cleared
    </alert-modal>
    <alert-modal v-model="invalidLinkModal" title="Unsaved WineCard">
      Please finish creating this WineCard
      <br>
      before attempting to follow this link.
    </alert-modal>
  </div>
</template>

<script>
import WineCardForm from '~/components/Forms/WineCardForm'
import ConfirmModal from '~/components/Modal/Confirm'
import WineNameModal from '~/components/Modal/Administration/WineNameModal'
import ProducersModal from '~/components/Modal/Administration/ProducersModal'
import NewFormatModal from '~/components/Modal/Administration/NewFormatModal'
import ModalHandersMixin from '~/mixins/winecard/modalHandlers'
import ListMixin from '~/mixins/winecard/lists'
import AlertModal from '~/components/Modal/Alert'
import { formResultMixin } from '~/mixins/forms'
import { mapState } from 'vuex'

export default {
  components: {
    WineCardForm,
    ConfirmModal,
    AlertModal,
    WineNameModal,
    ProducersModal,
    NewFormatModal
  },
  mixins: [ModalHandersMixin, ListMixin, formResultMixin],
  props: {
    useEvents: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      boolShowNewWineCardConfirmation: false,
      scoresChangedModalAlert: false,
      invalidLinkModal: false,
      defaultWineCard: {
        sellAtLoss: false,
        doNotPromote: false,
        mixedCase: false,
        format_id: null,
        categoryId: null,
        countryId: null,
        colourId: 0,
        usageCodeId: 1
      }
    }
  },
  computed: {
    ...mapState('wineCard/activeCard/', {
      loading: state => state.loading,
      activeCard: state => state.activeCard
    })
  },
  fetch({ store }) {
    return Promise.all([
      store.dispatch('lists/refreshFormat', {}),
      store.dispatch('lists/refreshCategory', {}),
      store.dispatch('lists/refreshCountry', {}),
      store.dispatch('lists/refreshWineColour', {}),
      store.dispatch('lists/refreshUsage', {}),
      store.dispatch('lists/refreshDesignations', {}),
      store.dispatch('lists/refreshClassifications', {}),
      store.dispatch('lists/refreshCountryRegions', {}),
      store.dispatch('lists/refreshCountrySubRegions', {}),
      store.dispatch('lists/refreshVineyards', {})
    ])
  },
  mounted() {
    this.$store.commit('menus/setActiveWineCardId', null)
    this.$store.commit('wineCard/activeCard/setActiveBrowserCard', null)
    if (this.$route.params.clone) {
      this.$refs.form.setChangedDetails(this.$route.params.clone)
    } else if (this.useEvents) {
      if (this.$store.state.wineCard.activeCard.toCloneCard) {
        // console.log('found something to clone')
        this.$refs.form.setChangedDetails(
          this.$store.state.wineCard.activeCard.toCloneCard
        )
      }
      this.$store.commit('wineCard/activeCard/setToCloneCard', null)
    }
    if (this.useEvents) {
      return Promise.all([
        this.$store.dispatch('lists/refreshFormat', {}),
        this.$store.dispatch('lists/refreshCategory', {}),
        this.$store.dispatch('lists/refreshCountry', {}),
        this.$store.dispatch('lists/refreshWineColour', {}),
        this.$store.dispatch('lists/refreshUsage', {})
      ])
    }
  },
  methods: {
    newWineCard() {
      this.boolShowNewWineCardConfirmation = true
    },
    handleScoresCleared() {
      this.scoresChangedModalAlert = true
    },
    newWineCardConfirmation() {
      this.$refs.form.reset()
    },
    handleDisabledClick() {
      this.invalidLinkModal = true
    },
    async saveWineCard() {
      try {
        await this.$refs.form.validate()
        const newWineCard = await this.$store.dispatch(
          'wineCard/activeCard/addCardAndSetActive',
          this.form
        )
        if (this.useEvents) {
          this.$emit('saved', newWineCard)
        } else {
          this.$notify.success('Wine Card Added!')
          this.$router.push({
            name: 'wine-card-id',
            params: { id: newWineCard.id }
          })
        }
      } catch (e) {
        this.$notifyError(e)
      }
    },
    handleDeleteWineName() {
      this.chosenWineName = null
      this.boolWineNamesModal = false
    },
    handleLwinUpdate(data) {
      if (this.useEvents) {
        this.$emit('updated-using-lwin', data)
      }
    }
  }
}
</script>

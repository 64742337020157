import gql from 'graphql-tag'
import { map } from 'lodash'
import { client } from '../graphQLInstance'
import { adaptWineCardFromAPI } from '../../helpers/apiWineCardAdaptor'
import ReservedStock from '../../models/reservedstock'
import WineCard from '../../models/winecard'

const resultParams = `WineCardID,
              WineVintage,
              ComputedWineName,
              WineName,
              WineParkerScore,
              ParkerSortValue,
              WineBurghoundScore,
              FormatID,
              FormatName,
              CaseSize,
              WineUsageCodeID,
              UsageCode,
              TotalBottles,
              DutyPaidBottles,
              InBondBottles,
              UnallocatedSalesBottles,
              OversoldBottles,
              AvailableBottles,
              ReservesBottles,
              BrokingBottles,
              ReservedBottles,
              SomeStockLoss,
              WineCostPrice,
              LooseCostPrice,
              WineSellingPrice,
              PurchaseOrderLinesUsage,
              SalesOrderLinesUsage,
              PromotionDate,
              LastSOID,
      				LastPOID,
      				LastPurchasePrice,
      				LastPurchaseDate,
      				LastPurchaseContact,
              LastOrderDate,
              LastPrice,
              LastSalesContact,
              AverageDaysInStock,
              NoteCount,
              WineCardNotesString,
              OverrideLossCheck,
              Category,
              CategoryID,
              CategorySequence,
              FormatCode,
              Volume,
              EmptyCases,
              PhotoRequired,
              AuditCreatedDate,
              AuditCreatedBy,
              AuditAmendedDate,
              AuditAmendedBy,
              AuditAmendedByUserName,
              LivExLWinCode,
              MixedCase,
              MixedCaseDefaultSize,
              LooseBottlePrice,
              HasLooseBottles`

export const getList = async searchTerm => {
  // prettier-ignore
  const result = await client.query({
    query: gql`
        query RootQueryType{
          wineNameSearch${searchTerm ? `(SearchTerm:"${searchTerm}")`:''} {
            WineNameSearchID,
            WineNameSearchDisplay,
            SortPriority
          }
        }
      `
  })
  return result.data.wineNameSearch
}

export const getWarehouseSearchResults = async (
  page,
  perPage,
  searchParameters
) => {
  // prettier-ignore
  const result = await client.query({
    query: gql `
        query RootQueryType{
          warehouseWineBrowserSearch(
          ${searchParameters.term && searchParameters.term.length > 0 ? `SearchTerm: "${searchParameters.term}",`: '' }
          ${searchParameters.format ? `FormatId: ${searchParameters.format},` : ''}
          ${searchParameters.usage ? `UsageId: ${searchParameters.usage},` : ''}
          ${searchParameters.categories ? `CategoryId: ${searchParameters.categories },` : ''}
          ${searchParameters.appellations ? `AppellationId: ${searchParameters.categories },`: '' }
          ${searchParameters.vintage1 ? `Vintage: ${searchParameters.vintage1 },` : ''}
          ${searchParameters.vintage2 ? `Vintage2: ${searchParameters.vintage2 },` : ''}
          ${searchParameters.wines ? `WineNameSearchID:"${searchParameters.wines}",` : ''}
          ${searchParameters.orderBy ? `OrderBy:"${searchParameters.orderBy}",` : ''}
          ${searchParameters && searchParameters.country ? `CountryId: ${searchParameters.country },` : ''}
          ${searchParameters && searchParameters.region ? `CountryRegionId: ${searchParameters.region },` : ''}
          ${searchParameters && searchParameters.subRegion ? `CountrySubRegionId: ${searchParameters.subRegion },` : ''}
          EmptyCasesOnly: ${searchParameters.empty},
          HideZeroStock: ${searchParameters.zero},
          Page: ${page},
          PerPage: ${perPage},
          OrderByDirection: ${searchParameters.orderByDirection},
          ) {
            total, 
            warehouseWineBrowserResults{
              WineCardID,
              WineVintage,
              ComputedWineName,
              FormatName,
              CaseSize,
              TotalBottles,
              DutyPaidBottles,
              InBondBottles,
              UnallocatedSalesBottles,
              OversoldBottles,
              AvailableBottles,
              EmptyCases,
              PhotoRequired
            }
          }
        }
      `
  })

  const tempConst = map(
    result.data.warehouseWineBrowserSearch.warehouseWineBrowserResults,
    app => {
      return {
        id: app.WineCardID,
        name: app.ComputedWineName,
        year: app.WineVintage,
        producer: app.ComputedWineName,
        format: app.FormatName,
        case_size: app.CaseSize,
        duty_paid_stock: {
          bottles: app.DutyPaidBottles % app.CaseSize,
          cases: Math.floor(app.DutyPaidBottles / app.CaseSize)
        },
        in_bond_stock: {
          bottles: app.InBondBottles % app.CaseSize,
          cases: Math.floor(app.InBondBottles / app.CaseSize)
        },
        unallocated_sales: {
          bottles: app.UnallocatedSalesBottles % app.CaseSize,
          cases: Math.floor(app.UnallocatedSalesBottles / app.CaseSize)
        },
        oversold_quantity: {
          bottles: app.OversoldBottles % app.CaseSize,
          cases: Math.floor(app.OversoldBottles / app.CaseSize)
        },
        available_quantity: {
          bottles: app.AvailableBottles % app.CaseSize,
          cases: Math.floor(app.AvailableBottles / app.CaseSize)
        },
        reserves_quantity: {
          bottles: app.ReservesBottles % app.CaseSize,
          cases: Math.floor(app.ReservesBottles / app.CaseSize)
        },
        broking_quantity: {
          bottles: app.BrokingBottles % app.CaseSize,
          cases: Math.floor(app.BrokingBottles / app.CaseSize)
        }
      }
    }
  )
  return {
    total: result.data.warehouseWineBrowserSearch.total,
    wineBrowserResults: tempConst
  }
}

export const getByWineCardId = async wineCardId => {
  const query = gql`query RootQueryType{wineBrowserItem(WineCardID:${wineCardId}){${resultParams}}}`
  const result = await client.query({
    query
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return adaptWineCardFromAPI(result.data.wineBrowserItem)
}

export const getWineCardsByIds = async wineCardIds => {
  const query = gql`query RootQueryType{wineBrowserItems(WineCardIDs:[${wineCardIds}]){${resultParams}}}`
  const result = await client.query({
    query
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data.wineBrowserItems, adaptWineCardFromAPI)
}

export const getSearchResults = async (
  page,
  perPage,
  searchParameters,
  all
) => {
  const query = gql`
        query RootQueryType{
          wineBrowserSearch${all !== undefined && all ? 'All' : ''}(
          ${
            searchParameters.term && searchParameters.term.length > 0
              ? `SearchTerm: "${searchParameters.term}",`
              : ''
          }
          ${
            searchParameters.format
              ? `FormatId: ${searchParameters.format},`
              : ''
          }
          ${searchParameters.usage ? `UsageId: ${searchParameters.usage},` : ''}
          ${searchParameters.from ? `ValueFrom: ${searchParameters.from},` : ''}
          ${searchParameters.to ? `ValueTo: ${searchParameters.to},` : ''}
          ${
            searchParameters.greater
              ? `CasesFrom: ${searchParameters.greater},`
              : ''
          }
          ${searchParameters.less ? `CasesTo: ${searchParameters.less},` : ''}
          ${
            searchParameters.parker && searchParameters.parker.length > 0
              ? `ParkerScore: "${searchParameters.parker}",`
              : ''
          }
          ${
            searchParameters.burghound
              ? `BurghoundScore: ${searchParameters.burghound},`
              : ''
          }
          ${
            searchParameters.categories
              ? `CategoryId: ${searchParameters.categories},`
              : ''
          }
          ${
            searchParameters.appellations
              ? `AppellationId: ${searchParameters.appellations}`
              : ''
          }
          ${
            searchParameters.vintage1
              ? `Vintage: ${searchParameters.vintage1},`
              : ''
          }
          ${
            searchParameters.vintage2
              ? `Vintage2: ${searchParameters.vintage2},`
              : ''
          }
          ${
            searchParameters.wines
              ? `WineNameSearchID:"${searchParameters.wines}",`
              : ''
          }
          ${
            searchParameters.orderBy
              ? `OrderBy:"${searchParameters.orderBy}",`
              : ''
          }
          ${
            searchParameters && searchParameters.countryMultiple
              ? `CountryIdMultiple: "${searchParameters.countryMultiple}",`
              : ''
          }
          ${
            searchParameters && searchParameters.regionMultiple
              ? `CountryRegionIdMultiple: "${searchParameters.regionMultiple}",`
              : ''
          }
          ${
            searchParameters && searchParameters.subRegionMultiple
              ? `CountrySubRegionIdMultiple: "${
                  searchParameters.subRegionMultiple
                }",`
              : ''
          }
           ${
             searchParameters && searchParameters.producerMultiple
               ? `ProducerIdMultiple: "${searchParameters.producerMultiple}",`
               : ''
           }
          HideZeroStock: ${searchParameters.zero},
          LooseBottlesOnly: ${searchParameters.looseBottlesOnly},

          LoosePriceOnly: ${searchParameters.loosePriceOnly},
          Page: ${page},
          PerPage: ${perPage},
          OrderByDirection: ${searchParameters.orderByDirection},
          ) {
            total, 
            wineBrowserResults{
              ${resultParams}
            }
          }
        }
      `
  // prettier-ignore
  const result = await client.query({
    query
	})
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }

  const tempConst = map(
    result.data.wineBrowserSearch.wineBrowserResults,
    adaptWineCardFromAPI
  )
  return {
    total: result.data.wineBrowserSearch.total,
    wineBrowserResults: tempConst
  }
}

export const clearAllPromotions = async () => {
  const result = await client.query({
    query: gql`
      mutation RootMutationType {
        wineCardClearPromotions
      }
    `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.wineCardClearPromotions
}

export const updateBatchPromotions = async args => {
  const result = await client.query({
    query: WineCard.mutate('updateBatchPromotion', args)
  })

  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(
    result.data[WineCard.mutations.updateBatchPromotion.name],
    adaptWineCardFromAPI
  )
}

export const updateReservedStock = async ({ id, bottles }) => {
  const result = await client.query({
    query: ReservedStock.mutate('update', {
      WineCardID: id,
      ReservedBottles: bottles
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[ReservedStock.mutations.update.name]
}

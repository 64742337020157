import BrokingPrice from '~/models/broking/brokingPrice'
import { client } from '~/api/graphQLInstance'

export const updateBrokingPrice = async args => {
  const result = await client.query({
    query: BrokingPrice.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BrokingPrice.mutations.update.name]
}

import Entity from './entity'

const fields = {
  PromotionProfileCode: {
    type: String
  },
  PromotionProfileDescription: {
    type: String
  }
}

const PromotionProfileDropDown = new Entity('PromotionProfileDropDown', fields)
PromotionProfileDropDown.createQuery('get', 'promotionProfileDropDown')
PromotionProfileDropDown.createQuery('getUnused', 'promotionProfileGetUnused')

export default PromotionProfileDropDown

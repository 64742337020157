import { client } from '../graphQLInstance'
import CountrySubRegion from '../../models/countrysubregion'

export const getCountrySubRegions = async args => {
  const result = await client.query({
    query: CountrySubRegion.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountrySubRegion.queries.get.name]
}

export const updateCountrySubRegion = async args => {
  const result = await client.query({
    query: CountrySubRegion.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountrySubRegion.mutations.update.name]
}

export const addCountrySubRegion = async args => {
  const result = await client.query({
    query: CountrySubRegion.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountrySubRegion.mutations.insert.name]
}

export const deleteCountrySubRegion = async args => {
  const result = await client.query({
    query: CountrySubRegion.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountrySubRegion.mutations.delete.name]
}

import { client } from '../graphQLInstance'
import WarehouseAddress from '../../models/warehouseaddress'

export const getWarehouseAddresses = async term => {
  const result = await client.query({
    query: WarehouseAddress.query('search', {
      SearchTerm: term
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WarehouseAddress.queries.search.name]
}

export const addWarehouseAddress = async args => {
  const result = await client.query({
    query: WarehouseAddress.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WarehouseAddress.mutations.insert.name]
}

export const getWarehouseAddress = async args => {
  const result = await client.query({
    query: WarehouseAddress.query('get', {
      RecordId: args.WarehouseAddrID
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WarehouseAddress.queries.get.name]
}

export const updateWarehouseAddress = async args => {
  const result = await client.query({
    query: WarehouseAddress.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WarehouseAddress.mutations.update.name]
}

export const deleteWarehouseAddress = async args => {
  const result = await client.query({
    query: WarehouseAddress.mutate('delete', {
      ID: args.WarehouseAddrID,
      AuditAmendedDate: args.AuditAmendedDate
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WarehouseAddress.mutations.delete.name]
}

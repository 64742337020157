import { client } from '../graphQLInstance'
import SalesOrderOversold from '~/models/salesorderoversold'

export const getSalesOrders = async () => {
  const result = await client.query({
    query: SalesOrderOversold.query('getAll')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderOversold.queries.getAll.name]
}

import Entity from './entity'

export const fields = {
  ContactID: {
    type: Number
  },
  ContactStatusID: {
    type: Number
  },
  CompanyName: {
    type: String
  },
  ContactName: {
    type: String
  },
  Comments: {
    type: String
  },
  Telephone1: {
    type: String
  },
  Telephone2: {
    type: String
  },
  Fax: {
    type: String
  },
  Title: {
    type: String
  },
  FirstName: {
    type: String
  },
  LastName: {
    type: String
  },
  LastInvDate: {
    type: String
  },
  LastPayDate: {
    type: String
  },
  VATNo: {
    type: String
  },
  EORINo: {
    type: String
  },
  REXNo: {
    type: String
  },
  CreditLimit: {
    type: Number
  },
  Balance: {
    type: Number
  },
  SOPaymentTermsID: {
    type: Number
  },
  IsSageCustomer: {
    type: Boolean
  },
  SageCustomerAcctNo: {
    type: String
  },
  IsSageSupplier: {
    type: Boolean
  },
  SageSupplierAcctNo: {
    type: String
  },
  BillingAddrID: {
    type: Number
  },
  DefDeliveryAddrID: {
    type: Number
  },
  DefExportID: {
    type: Number
  },
  DefIntrastatCountry: {
    type: String
  },
  DefIsExport: {
    type: Boolean
  },
  IsTemporaryHold: {
    type: Boolean
  },
  IsBroker: {
    type: Boolean
  },
  Salutation: {
    type: String
  },
  DoNotReview: {
    type: Boolean
  },
  LastReviewDate: {
    type: String
  },
  LastReviewedBy: {
    type: Number
  },
  ShowEuroPrices: {
    type: Boolean
  },
  ShowHongKongPrices: {
    type: Boolean
  },
  ShowUSDPrices: {
    type: Boolean
  },
  ShowSGDPrices: {
    type: Boolean
  },
  ShowCHFPrices: {
    type: Boolean
  },
  ShowWineScore: {
    type: Boolean
  },
  AList: {
    type: Boolean
  },
  SuppressEmails: {
    type: Boolean
  },
  AutomaticReview: {
    type: Boolean
  },
  NoSplitCases: {
    type: Boolean
  },
  MinPriceToOverrideNoSplit: {
    type: Number
  },
  EmailAddress1: {
    type: String
  },
  EmailAddress2: {
    type: String
  },
  EmailAddress3: {
    type: String
  },
  LastReviewDatePrev: {
    type: String
  },
  DoNotPromoteNewWines: {
    type: Boolean
  },
  LastNewPromotionDate: {
    type: String
  },
  LastNewPromotionDatePrev: {
    type: String
  },
  PromotionProfileCode: {
    type: String
  },
  LastAutoReviewDate: {
    type: String
  },
  POPaymentTermsID: {
    type: Number
  },
  AWRSNumber: {
    type: String
  },
  SalesCount: {
    type: Number
  },
  SalesTotal: {
    type: Number
  },
  LastSalesDate: {
    type: String
  },
  PurchasesCount: {
    type: Number
  },
  PurchasesTotal: {
    type: Number
  },
  LastPurchaseDate: {
    type: String
  },
  ReservesCount: {
    type: Number
  },
  ReservesTotal: {
    type: Number
  },
  CurrentReservesTotal: {
    type: Number
  },
  LastReservesDate: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  AuditAmendedByName: {
    type: String
  },
  AuditCreatedByName: {
    type: String
  },
  WebContactID: {
    type: Number
  },
  RelatedContactID: {
    type: Number
  },
  NoEmailContact: {
    type: Boolean
  },
  NoStorageCharge: {
    type: Boolean
  },
  HideReservesOnWebsite: {
    type: Boolean
  },
  SPGID: {
    type: Number
  },
  PricePer9L: {
    type: Number
  }
}

const Contact = new Entity('Contact', fields)

Contact.createQuery('get', 'contactGet', fields)
Contact.createQuery('search', 'contactSearch', {
  ...Entity.onlyKeys(fields, [
    'ContactID',
    'CompanyName',
    'ContactName',
    'PurchasesCount',
    'PurchasesTotal',
    'SalesCount',
    'SalesTotal',
    'ReservesCount',
    'ReservesTotal',
    'CurrentReservesTotal',
    'NoEmailContact',
    'NoStorageCharge',
    'HideReservesOnWebsite',
    'PricePer9L',
    'RelatedContactID',
    'IsSageCustomer',
    'IsSageSupplier'
  ]),
  SuppressFlag: {
    type: Number
  },
  Status: {
    type: String
  },
  CountryCode: {
    type: String
  },
  EmailAddress: {
    type: String
  },
  SageSupplierAccountNumber: {
    type: String
  },
  SageCustomerAccountNumber: {
    type: String
  },
  BrokingCount: {
    type: Number
  }
})

Contact.createMutation(
  'delete',
  'contactDelete',
  {
    ID: fields.ContactID
  },
  []
)
Contact.createMutation(
  'insert',
  'contactAdd',
  Entity.allButKeys(fields, [
    'SalesCount',
    'SalesTotal',
    'PurchasesTotal',
    'PurchasesCount',
    'LastSaleDate',
    'LastPurchaseDate',
    'ReservesCount',
    'ReservesTotal',
    'CurrentReservesTotal',
    'LastReservesDate',
    'PricePer9L'
  ])
)
Contact.createMutation(
  'update',
  'contactUpdate',
  Entity.allButKeys(fields, [
    'SalesCount',
    'SalesTotal',
    'PurchasesTotal',
    'PurchasesCount',
    'LastSaleDate',
    'LastPurchaseDate',
    'ReservesCount',
    'ReservesTotal',
    'CurrentReservesTotal',
    'LastReservesDate',
    'PricePer9L'
  ])
)

Contact.createMutation(
  'skipReviewCycleBatch',
  'contactSkipReviewCycleBatch',
  {
    Contacts: {
      type: Array
    },
    Date: {
      type: String
    }
  },
  []
)

Contact.createQuery(
  'sageAccountNumberGetNext',
  'contactSageAccountNumberGetNext',
  {}
)

Contact.createMutation(
  'createWebContactFromContact',
  'createWebContactFromContact',
  {
    Password: {
      type: String
    },
    ContactID: {
      type: Number
    }
  }
)

Contact.createQuery('contactDocumentsGet', 'contactDocuments', {
  UUID: {
    type: String
  },
  OriginalFilename: {
    type: String
  },
  Size: {
    type: Number
  }
})

Contact.createMutation(
  'deleteDocument',
  'contactDocumentDelete',
  {
    ContactID: {
      type: Number
    },
    UUID: {
      type: String
    }
  },
  []
)

export default Contact

import Entity from './entity'
import { fields as jobFields } from './job'
import { fields as wineCardField } from './winecard'
import { fields as lwinDictionaryFields } from './lwindictionary'

const fields = {
  WineCardID: {
    type: Number
  },
  Region: {
    type: String
  },
  WineName: {
    type: String
  },
  LWIN18: {
    type: String
  },
  LWIN7: {
    type: String
  },
  Vintage: {
    type: String
  },
  Pack: {
    type: String
  },
  BottleSize: {
    type: String
  },
  Status: {
    type: String
  },
  HasChangesAt: {
    type: String
  },
  Changes: {
    type: Array
  },
  WineCard: {
    type: Object,
    fields: wineCardField
  },
  Dictionary: {
    type: Object,
    fields: lwinDictionaryFields
  }
}

const WineMatcher = new Entity('WineMatcherImport', fields)
WineMatcher.createMutation(
  'startImport',
  'wineMatcherStartImport',
  {
    SearchTerm: {
      type: String
    },
    SearchField: {
      type: String
    },
    Tasks: {
      type: Array
    },
    OnlyFiltered: {
      type: Boolean
    },
    DeleteAfterSync: {
      type: Boolean
    }
  },
  jobFields
)

WineMatcher.createMutation(
  'wineMatcherClear',
  'wineMatcherClear',
  {},
  {
    Result: {
      type: Boolean
    }
  }
)

WineMatcher.createQuery(
  'get',
  'liveExWineMatcherGet',
  {
    current_page: {
      type: Number
    },
    last_page: {
      type: Number
    },
    total: {
      type: Number
    },
    liveExMatcherResult: {
      type: Array,
      fields: fields
    }
  },
  {
    Vintage: {
      type: String
    },
    SearchTerm: {
      type: String
    },
    SearchField: {
      type: String
    },
    Page: {
      type: Number
    },
    PerPage: {
      type: Number
    },
    OrderBy: {
      type: String
    },
    OrderByDirection: {
      type: String
    }
  }
)

export default WineMatcher

import Entity from './entity'

const fields = {
  CategoryID: {
    type: Number
  },
  CategoryName: {
    type: String
  },
  CategoryCode: {
    type: String
  },
  CountryID: {
    type: Number
  },
  CountryRegionID: {
    type: Number
  },
  WineColourID: {
    type: Number
  },
  BurghoundScorable: {
    type: Boolean
  },
  UseCategoryID: {
    type: Boolean
  },
  UseMixedWineColour: {
    type: Boolean
  }
}

const CategoryDropDown = new Entity('CategoryDropDown', fields)
CategoryDropDown.createQuery('get', 'categoryDropDown', fields)

export default CategoryDropDown

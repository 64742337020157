<template>
  <div>
    <b-modal
      v-model="shown"
      centered
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      :hide-header-close="true"
      size="sm"
      @hidden="$emit('hidden')"
      @shown="reset"
    >
      <template v-slot:modal-header>
        New Purchase Order Line
      </template>
      <template v-slot:default>
        <div v-if="loading" class="text-center text-primary">
          <b-spinner type="grow" label="Loading..." />
        </div>
        <div v-else>
          <div class="callout">
            {{ title }}
          </div>
          <div v-if="error" class="alert alert-danger">
            {{ error }}
          </div>
          <new-small-purchase-order-form
            ref="form"
            :value="defaultFormData"
            :show-cases="showCases"
            :bottle-title="item.format"
            :mixed-case="item.mixedCase === true"
            :item-case-size="item.case_size"
            :item-selling-price="item.listPrice"
            :usage-options="usageOptions"
            @change="handleFormChange"
          >
            <empty-case-button :disabled="!useEmptyCaseChecked" :block="true" :loading="wineCardLoading" :wine-card="wineCard" @click="openEmptyCasesModal" />
            <b-table v-if="toAttachCases.length > 0" class="mt-2" :fields="attachedCaseField" :items="toAttachCases" />
          </new-small-purchase-order-form>
          <div class="d-flex justify-content-center">
            <live-ex-button :lwin="item.LivExLWinCode" :vintage="item.year" :wine-name="item.wine_name" class="ml-1" />
            <wine-searcher-button :wine-name="item.ComputedWineName" :vintage="item.year" class="mr-1" />
          </div>
        </div>
      </template>
      <div slot="modal-footer">
        <b-button class="btn wv-btn wv-btn__secondary btn-sm" variant="primary" :disabled="saving" @click="save('ib')">
          Add as IB
        </b-button>
        <b-button class="btn wv-btn wv-btn__secondary btn-sm" variant="primary" :disabled="saving" @click="save('dp')">
          Add as DP
        </b-button>
        <b-button class="btn wv-btn btn-sm" @click="shown=false">
          Cancel
        </b-button>
      </div>
    </b-modal>
    <empty-cases-modal
      v-model="showEmptyCasesModal"
      :item="item"
      :show-attach="true"
      :attached-cases="toAttachCases"
      @hidden="shown=true"
      @attach="handleAttachCase"
      @detach="handleDetachCase"
    />
  </div>
</template>

<script>
import EmptyCasesModal from './EmptyCases'
import NewSmallPurchaseOrderForm from '../Forms/NewSmallPurchaseOrderForm'
import WineSearcherButton from '../Base/WineSearcherButton'
import LiveExButton from '../Base/LiveExButton'
import { formResultMixin } from '~/mixins/forms'
import EmptyCaseButton from '~/components/Base/EmptyCaseButton'

export default {
  components: {
    EmptyCasesModal,
    NewSmallPurchaseOrderForm,
    LiveExButton,
    WineSearcherButton,
    EmptyCaseButton
  },
  mixins: [formResultMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    wineCard: {
      type: Object,
      required: true
    },
    wineCardLoading: {
      type: Boolean,
      required: true
    },
    usageOptions: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      showEmptyCasesModal: false,
      silienceReset: false,
      error: false,
      toAttachCases: [],
      attachedCaseField: [
        {
          key: 'TypeName',
          sortable: true,
          label: 'Type',
          width: '55px'
        },
        {
          key: 'FormatText',
          sortable: true,
          label: 'Format',
          width: '55px',
          tdClass: 'text-center'
        },
        {
          key: 'Notes',
          sortable: true,
          label: 'Notes',
          width: '120px'
        },
        {
          key: 'Location',
          sortable: true,
          label: 'Location',
          width: '120px'
        }
      ]
    }
  },
  computed: {
    title() {
      let title = this.item.ComputedWineName + ' (' + this.item.format + ')'

      if (this.item.year) {
        title = this.item.year + ': ' + title
      }

      return title
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    showCases() {
      return this.item.case_size > 1
    },
    defaultFormData() {
      return {
        caseSize:
          this.item.mixedCase === true &&
          this.item.MixedCaseDefaultSize !== null &&
          this.item.MixedCaseDefaultSize !== undefined
            ? this.item.MixedCaseDefaultSize
            : '',
        originalPackaging: this.item.mixedCase === true,
        photoRequired: this.item.photo_required,
        emptyOWX: false,
        usage: this.usage
      }
    },
    saving() {
      return this.$store.state.purchaseOrders.purchaseOrder.saving
    },
    useEmptyCaseChecked() {
      return this.form.emptyOWX === true
    },
    usage() {
      if (
        this.usageOptions.filter(
          function(item) {
            return (
              item.value ===
              this.$store.state.user.settings.defaultPurchaseOrderUsageId
            )
          }.bind(this)
        ).length === 0 &&
        this.usageOptions[0]
      ) {
        return this.usageOptions[0].value
      }
      return this.$store.state.user.settings.defaultPurchaseOrderUsageId
    }
  },
  methods: {
    openEmptyCasesModal() {
      this.silienceReset = true
      this.showEmptyCasesModal = true
    },
    async save(addType) {
      if (this.saving) {
        return
      }
      try {
        this.error = null
        await this.$refs.form.validate()
        const saveData = {
          WineCardID: this.item.id,
          FormatID: this.item.format_id,
          Bottles: this.$refs.form.totalBottles,
          Price: this.form.costPrice,
          AsDutyPaid: addType === 'dp',
          WineUsageCodeID: this.form.usage,
          PhotoRequired: this.form.photoRequired === true,
          UseEmptyOWC: this.useEmptyCaseChecked,
          OriginalPackaging: this.form.originalPackaging === true,
          OrigPkgCaseSize: this.form.originalPackaging ? this.form.caseSize : 0,
          AttachEmptyCases: this.toAttachCases.map(ec => ec.EmptyCaseID)
        }
        this.$emit('save', saveData)
      } catch (e) {
        this.error = e
      }
    },
    reset() {
      this.form = {}
      this.toAttachCases = []
      if (!this.silienceReset) {
        this.$refs.form.reset()
      }
      this.silienceReset = false
    },
    handleAttachCase(emptyCase) {
      this.toAttachCases.push(emptyCase)
    },
    handleDetachCase(emptyCase) {
      this.toAttachCases = this.toAttachCases.filter(
        c => c.EmptyCaseID !== emptyCase.EmptyCaseID
      )
    }
  }
}
</script>

export const state = () => ({
  from: null,
  greater: null,
  to: null,
  less: null
})

export const getters = {
  searchParameters: state => {
    return {
      from: state.from,
      greater: state.greater,
      to: state.to,
      less: state.less
    }
  }
}

export const mutations = {
  setFrom(state, from) {
    state.from = from
  },
  setGreater(state, greater) {
    state.greater = greater
  },
  setTo(state, to) {
    state.to = to
  },
  setLess(state, less) {
    state.less = less
  },
  resetSelection(state) {
    state.from = null
    state.to = null
    state.less = null
    state.greater = null
  }
}

export default {
  state,
  getters,
  mutations
}

import Customerstockcard from '../../models/reserves/customerstockcard'
import { client } from '../graphQLInstance'

export const getCustomerStockCard = async ContactID => {
  const result = await client.query({
    query: Customerstockcard.query('get', { ContactID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Customerstockcard.queries.get.name]
}

export const getCustomerWineReserves = async args => {
  const result = await client.query({
    query: Customerstockcard.query('getStock', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Customerstockcard.queries.getStock.name]
}

export const getCustomerWineAllocations = async args => {
  const result = await client.query({
    query: Customerstockcard.query('getAllocations', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Customerstockcard.queries.getAllocations.name]
}

export const reserveCreateBuyBack = async args => {
  const result = await client.query({
    query: Customerstockcard.mutate('reserveCreateBuyBack', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Customerstockcard.mutations.reserveCreateBuyBack.name]
}

export const updateInventoryUsage = async args => {
  const result = await client.query({
    query: Customerstockcard.mutate('updateInventoryUsage', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Customerstockcard.mutations.updateInventoryUsage.name]
}

import gql from 'graphql-tag'
import wineCardAvailableStock from '../../data/wineCardAvailableStock'
import { client } from '../graphQLInstance'
import WineCard from '../../models/winecard'
import {
  adaptWineCardFromAPI,
  adaptWineCardForAPI
} from '../../helpers/apiWineCardAdaptor'
import LiveExTransaction from '../../models/liveextransaction'
import WineSearcherPrice from '../../models/winesearcherprice'

export const addCard = async wineCard => {
  const apiData = adaptWineCardForAPI(wineCard)
  const result = await client.query({
    query: WineCard.mutate('create', apiData)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return adaptWineCardFromAPI(result.data.wineCardAdd)
}

export const updateCardById = async (id, wineCard) => {
  const apiData = adaptWineCardForAPI(wineCard)
  const result = await client.query({
    query: WineCard.mutate('update', {
      WineCardID: id,
      ...apiData
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return adaptWineCardFromAPI(result.data.wineCardUpdate)
}

export const getAvailableStockByCardId = id => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(wineCardAvailableStock)
    }, 800)
  })
}

export const getCardById = async id => {
  const result = await client.query({
    query: WineCard.query('get', {
      RecordId: Number(id)
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return adaptWineCardFromAPI(result.data.wineCardGet)
}

export const deleteCardById = async id => {
  const result = await client.query({
    query: WineCard.mutate('delete', {
      ID: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return true
}

export const getBordeauxIndexPrices = async ({ WineCardID }) => {
  const result = await client.query({
    query: gql`
    query RootQueryType {
      bordeauxIndexPricesGet(WineCardID: ${WineCardID})
      {
        format,
        bidPrice,
        bidQuantity,
        bids,
        offerPrice,
        offerQuantity,
        offers
      }
    }
    `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.bordeauxIndexPricesGet
}

export const getLiveExLatestTransactions = async fields => {
  const result = await client.query({
    query: LiveExTransaction.query('get', fields)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[LiveExTransaction.queries.get.name]
}

export const getLivExBidOffer = async ({ WineCardID }) => {
  const result = await client.query({
    query: gql`
    query RootQueryType {
      livExBidOfferGet(WineCardID: ${WineCardID})
      {
        format,
        bidPrice,
        bidQuantity,
        bidDate,
        bids,
        offerPrice,
        offerQuantity,
        offerDate,
        offers,
        lastTradePrice,
        lastTradeDate
      }
    }
    `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.livExBidOfferGet
}

export const getWineSearcherPrices = async fields => {
  const result = await client.query({
    query: WineSearcherPrice.query('get', fields)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineSearcherPrice.queries.get.name]
}

export const getByLwin = async LWINs => {
  const result = await client.query({
    query: WineCard.query('getByLwin', LWINs)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineCard.queries.getByLwin.name]
}

import { each, find, without, findIndex, map } from 'lodash'
import {
  getImagePaths,
  updateById,
  uploadPictures,
  deleteRotationCardImage,
  emailImages,
  getByNumber,
  getImagePathsByRotationNumber,
  updateImage
} from '~/api/rotationCard/rotationCard'

import { inventoryGetByRotationNumber, updateInventory } from '~/api/inventory'
import { getTextTemplate } from '~/api/email'

import Vue from 'vue'

export const state = () => ({
  loading: false,
  imagePathsLoading: false,
  rotationCard: null,
  imagePaths: [],
  inventoryLoading: false,
  inventory: null,
  pictureUploading: false,
  emailTemplateLoading: false,
  emailSending: false,
  saving: false
})

export const getters = {
  getImagePathById: state => RotationCardImageID => {
    return find(state.imagePaths, { RotationCardImageID })
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setImagePathsLoading(state, loading) {
    state.imagePathsLoading = loading === true
  },
  setRotationCard(state, rotationCard) {
    state.rotationCard = rotationCard
  },
  setImagePaths(state, paths) {
    state.imagePaths = map(paths, p => {
      p.version = 0
      return p
    })
  },
  appendImagePath(state, path) {
    const currentImageIdx = findIndex(state.imagePaths, {
      RotationCardImageID: path.RotationCardImageID
    })
    if (currentImageIdx < 0) {
      state.imagePaths.push(path)
    } else {
      Vue.set(state.imagePaths, currentImageIdx, path)
    }
  },
  removeImagePath(state, RotationCardImageID) {
    state.imagePaths = without(
      state.imagePaths,
      find(state.imagePaths, { RotationCardImageID })
    )
  },
  setInventoryLoading(state, loading) {
    state.inventoryLoading = loading === true
  },
  setInventory(state, inventory) {
    state.inventory = inventory
  },
  setPictureUploading(state, uploading) {
    state.pictureUploading = uploading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  setCacheBustingById(state, id) {
    const idx = state.imagePaths.findIndex(p => p.RotationCardImageID === id)
    if (idx > -1) {
      Vue.set(state.imagePaths[idx], 'cb', new Date().getTime())
    }
  },
  setEmailTemplateLoading(state, loading) {
    state.emailTemplateLoading = loading === true
  },
  setEmailSending(state, loading) {
    state.emailSending = loading === true
  }
}

export const actions = {
  async emailImages(
    { commit },
    { images, to, toName, subject, body, sender, senderName }
  ) {
    commit('setEmailSending', true)
    try {
      return await emailImages({
        images,
        to,
        toName,
        subject,
        body,
        sender,
        senderName
      })
    } catch (e) {
      throw e
    } finally {
      commit('setEmailSending', false)
    }
  },
  async getRotationEmailTemplate({ commit }) {
    commit('setEmailTemplateLoading', true)
    try {
      return await getTextTemplate('RC-EMAIL')
    } catch (e) {
      throw e
    } finally {
      commit('setEmailTemplateLoading', false)
    }
  },
  async loadRotationCardByNumber({ commit }, number) {
    commit('setLoading', true)
    try {
      const card = await getByNumber(number)
      commit('setRotationCard', card)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getImagePathsByRotationNumber({ commit }, number) {
    commit('setImagePathsLoading', true)
    try {
      const response = await getImagePathsByRotationNumber(number)
      commit('setImagePaths', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setImagePathsLoading', false)
    }
  },
  async getImagePaths({ commit }, id) {
    commit('setImagePathsLoading', true)
    try {
      const response = await getImagePaths(id)
      commit('setImagePaths', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setImagePathsLoading', false)
    }
  },
  async updateRotationCard({ commit }, card) {
    commit('setLoading', true)
    try {
      const updatedCard = await updateById(card)
      commit('setRotationCard', updatedCard)
      return updatedCard
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadInventoryByRotationNumber({ commit }, rotationNumber) {
    commit('setInventoryLoading', true)
    try {
      const response = await inventoryGetByRotationNumber(rotationNumber)
      commit('setInventory', response)
    } catch (e) {
      throw e
    } finally {
      commit('setInventoryLoading', false)
    }
  },
  async updateInventory({ commit }, inventory) {
    commit('setInventoryLoading', true)
    try {
      const updatedInventory = await updateInventory(inventory)
      commit('setInventory', updatedInventory)
      return updatedInventory
    } catch (e) {
      throw e
    } finally {
      commit('setInventoryLoading', false)
    }
  },
  loadFullRotationCardByNumber({ commit, dispatch }, number) {
    return Promise.all([
      dispatch('loadRotationCardByNumber', number),
      dispatch('getImagePathsByRotationNumber', number),
      dispatch('loadInventoryByRotationNumber', number)
    ])
  },
  async uploadPictures({ commit }, args) {
    commit('setPictureUploading', true)
    try {
      const response = await uploadPictures(args)
      if (response.success) {
        each(response.rotationPaths, path => commit('appendImagePath', path))
        return response.rotationPaths
      } else {
        throw response.message
      }
    } catch (e) {
      throw e
    } finally {
      commit('setPictureUploading', false)
    }
  },
  async updatePicture({ commit }, args) {
    commit('setSaving', true)
    try {
      const result = await updateImage(args)
      commit('appendImagePath', result)
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async removePicture({ commit }, { id, rotationId }) {
    commit('setImagePathsLoading', true)
    try {
      await deleteRotationCardImage(id, rotationId)
      commit('removeImagePath', id)
    } catch (e) {
      throw e
    } finally {
      commit('setImagePathsLoading', false)
    }
  },
  cacheBustPictureById({ commit }, id) {
    commit('setCacheBustingById', id)
  }
}

<template>
  <div>
    <b-modal
      id="modal-1"
      v-model="shown"
      centered
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      :hide-header-close="true"
      size="md"
      @ok="save"
      @shown="reset"
    >
      <template v-slot:modal-header>
        New Designation
      </template>
      <designation-form
        ref="form"
        :value="formData"
        @change="handleFormChange"
      />
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="ok()">
          <b-spinner v-if="saving" type="grow" label="Loading..." small />
          OK
        </b-button>
        <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="shown=false">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DesignationForm from '~/components/Forms/DesignationForm'
import { formResultMixin } from '../../../mixins/forms'

export default {
  components: {
    DesignationForm
  },
  mixins: [formResultMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    saving: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    formData() {
      return {
        DesignationName: ''
      }
    }
  },
  methods: {
    cancel() {
      this.$refs.form.reset()
    },
    reset() {
      this.$refs.form.reset()
    },
    async save(e) {
      e.preventDefault()
      try {
        await this.$refs.form.validate()
        this.$emit('save', this.form)
      } catch (e) {
        this.$notifyError(e)
      }
    }
  }
}
</script>

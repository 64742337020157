import { getAvailableStock } from '~/api/export/availableStock'
import { wineCardExportListAppend, availableOWCs } from '~/api/export/export'
import { sortBy as lodashSortBy, keys, filter, map } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  mode: 'wineBrowser',
  exportListId: null,
  availableStock: [],
  availableStockMap: {},
  excludedItems: {},
  wholeCaseItems: {},
  splitCaseItems: {},
  includeAllItems: {},
  loading: false,
  loadingAppends: false,
  allWholeCases: false,
  allSplitCases: false,
  sortBy: null,
  sortDesc: false,
  selectableOWCs: [],
  loadingOWCs: false
})

export const getters = {
  reportParameters: state => {
    const onlyTrueIds = obj => {
      return filter(keys(obj), k => {
        return obj[k] === true
      })
    }
    return {
      ExcludedUUID: onlyTrueIds(state.excludedItems),
      WholeCaseUUID: onlyTrueIds(state.wholeCaseItems),
      SplitCaseUUID: onlyTrueIds(state.splitCaseItems),
      IncludeAllUUID: onlyTrueIds(state.includeAllItems),
      AllWholeCases: state.allWholeCases,
      AllSplitCases: state.allSplitCases,
      SortBy: state.sortBy,
      SortDesc: state.sortDesc,
      ExportListId: state.exportListId,
      Mode: state.mode
    }
  },
  started: state => {
    return state.exportListId !== null
  },
  isWineBrowserBased: state => {
    return state.mode === 'wineBrowser'
  },
  searchParameters: state => {
    return {
      ExportListId: state.exportListId
    }
  },
  getByWineCardUUID: state => UUID => {
    return state.availableStock[state.availableStockMap[UUID]]
  },
  wineCardExcluded: (state, getters) => UUID => {
    if (state.excludedItems[UUID] !== undefined) {
      return state.excludedItems[UUID]
    }
    const item = getters.getByWineCardUUID(UUID)
    return item.ExcludeFlag
  },
  wineCardWholeCase: (state, getters) => UUID => {
    if (state.wholeCaseItems[UUID] !== undefined) {
      return state.wholeCaseItems[UUID]
    }
    const item = getters.getByWineCardUUID(UUID)
    if (state.allWholeCases === true) {
      const excluded = getters.wineCardExcluded(UUID)
      if (excluded === false) {
        return true
      }
    }

    return item.WholeCases
  },
  wineCardSplitCase: (state, getters) => UUID => {
    if (state.splitCaseItems[UUID] !== undefined) {
      return state.splitCaseItems[UUID]
    }
    const item = getters.getByWineCardUUID(UUID)
    if (state.allSplitCases === true) {
      const excluded = getters.wineCardExcluded(UUID)
      if (excluded === false) {
        return true
      }
    }
    return item.SplitCases
  },
  wineCardIncludeAll: (state, getters) => UUID => {
    if (state.includeAllItems[UUID] !== undefined) {
      return state.includeAllItems[UUID]
    }
    const item = getters.getByWineCardUUID(UUID)
    return item.IncludeAll
  },
  getDisplayTotalBottles: (state, getters) => UUID => {
    const item = getters.getByWineCardUUID(UUID)
    if (getters.wineCardExcluded(item.UUID)) {
      return 0
    }
    const includeAll = getters.wineCardIncludeAll(item.UUID)
    const bottles = includeAll ? item.AllBottles : item.AvailableBottles
    if (getters.wineCardWholeCase(item.UUID)) {
      return parseInt(Math.floor(bottles / item.CaseSize) * item.CaseSize)
    } else if (getters.wineCardSplitCase(item.UUID)) {
      return bottles % item.CaseSize
    }
    return bottles
  }
}

export const mutations = {
  setExportId(state, exportID) {
    state.exportListId = exportID
  },
  setAvailableStock(state, availableStock) {
    state.availableStock = map(availableStock, (item, idx) => {
      item.index = idx
      return item
    })
    const stockMap = {}
    for (let i = 0; i < availableStock.length; i++) {
      stockMap[availableStock[i].UUID] = i
    }
    state.availableStockMap = stockMap
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setLoadingAppends(state, loading) {
    state.loadingAppends = loading
  },
  setLoadingOWCs(state, loading) {
    state.loadingOWCs = loading
  },
  setExcluded(state, { UUID, ExcludeFlag }) {
    Vue.set(state.excludedItems, UUID, ExcludeFlag)
  },
  setWholeCase(state, { UUID, WholeCases }) {
    Vue.set(state.wholeCaseItems, UUID, WholeCases)
  },
  setSplitCase(state, { UUID, SplitCases }) {
    Vue.set(state.splitCaseItems, UUID, SplitCases)
  },
  setIncludeAll(state, { UUID, IncludeAll }) {
    Vue.set(state.includeAllItems, UUID, IncludeAll)
  },
  setAllWholeCase(state, selected) {
    state.allWholeCases = selected
    state.wholeCaseItems = {}
    state.splitCaseItems = {}
  },
  setAllSplitCase(state, selected) {
    state.allSplitCases = selected
    state.wholeCaseItems = {}
    state.splitCaseItems = {}
  },
  reset(state) {
    state.exportListId = null
    state.availableStock = []
    state.availableStockMap = {}
    state.excludedItems = {}
    state.wholeCaseItems = {}
    state.splitCaseItems = {}
    state.allWholeCases = false
    state.allSplitCases = false
    state.sortBy = null
    state.sortDesc = false
    state.POIDs = []
    state.CategoryIds = []
    state.purchaseOrderSearchParameters = {}
    state.selectableOWCs = []
  },
  setSortBy(state, sortBy) {
    state.sortBy = sortBy
  },
  setSortDesc(state, desc) {
    state.sortDesc = desc
  },
  setPurchaseOrderMode(state) {
    state.mode = 'purchaseOrder'
  },
  setWineBrowserMode(state) {
    state.mode = 'wineBrowser'
  },
  setSelectableOWCs(state, owcs) {
    state.selectableOWCs = owcs
  }
}

export const actions = {
  async appendToExportedList({ commit }, params) {
    try {
      commit('setLoadingAppends', true)
      const exportID = await wineCardExportListAppend(params)
      commit('setExportId', exportID)
      return exportID
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingAppends', false)
    }
  },
  async getSelectableOWCs({ commit }, params) {
    try {
      commit('setLoadingOWCs', true)
      const owcs = await availableOWCs(params)
      commit('setSelectableOWCs', owcs)
      return owcs
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingOWCs', false)
    }
  },
  async getAvailableStock({ commit }, params) {
    try {
      commit('setLoading', true)
      const availableStock = await getAvailableStock(params)
      commit('setAvailableStock', availableStock)
      return availableStock
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  sortAvailableStock({ state, commit, getters }, { sortBy, sortDesc }) {
    commit('setSortBy', sortBy)
    commit('setSortDesc', sortDesc)
    commit('setLoading', true)
    let availableStock = state.availableStock
    switch (sortBy) {
      case 'ExcludeSort':
        availableStock = lodashSortBy(state.availableStock, function(a) {
          return getters.wineCardExcluded(a.UUID) ? 1 : 0
        })
        break
      case 'WineParkerScore':
        availableStock = lodashSortBy(state.availableStock, 'ParkerSortValue')
        break
      case 'AvailableBottles':
        availableStock = lodashSortBy(state.availableStock, function(a) {
          return getters.getDisplayTotalBottles(a.UUID)
        })
        break
      default:
        availableStock = lodashSortBy(state.availableStock, sortBy)
    }
    if (sortDesc) {
      availableStock = availableStock.reverse()
    }
    commit('setAvailableStock', availableStock)
    commit('setLoading', false)
  }
}

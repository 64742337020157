<template>
  <b-form>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Region Name"
      label-for="Region"
      :label-class="{green: isDirty('Region') }"
    >
      <b-form-input
        id="Region"
        v-model="Region"
        :state="!$v.Region.$error && null"
        trim
        size="sm"
        :disabled="viewMode"
        :class="{green: isDirty('Region') }"
      />
      <b-form-invalid-feedback>
        Country Region is required
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Country"
      label-for="CountryID"
      :label-class="{green: isDirty('CountryID') }"
    >
      <b-form-select
        id="CountryID"
        v-model="CountryID"
        :state="!$v.CountryID.$error && null"
        trim
        size="sm"
        :disabled="viewMode || countryDisabled"
        :options="countryList"
        :class="{green: isDirty('CountryID') }"
      />
      <b-form-invalid-feedback>
        Country is required
      </b-form-invalid-feedback>
    </b-form-group>
    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('UseOnBackOffice') }"
        >
          Visible On Back Office
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-checkbox
          v-model="UseOnBackOffice"
          type="text"
          :state="!$v.UseOnBackOffice.$error && null"
          :class="{green: isDirty('UseOnBackOffice') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="4"
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      required: false
    },
    countries: {
      type: Array,
      required: true
    },
    countryId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapObjectToVModel(['Region', 'CountryID', 'UseOnBackOffice']),
    countryList() {
      return this.countries.map(c => {
        if (c.value && c.text) {
          return c
        }
        return {
          value: c.CountryID,
          text: c.CountryName
        }
      })
    },
    countryDisabled() {
      return this.countryId !== null
    }
  },
  validations: {
    Region: {
      required
    },
    CountryID: {
      required
    },
    UseOnBackOffice: {}
  }
}
</script>

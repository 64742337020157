import { client } from '../graphQLInstance'
import WebContact from '../../models/webcontact'
import WebContactAddress from '../../models/webcontactaddress'
import WebContactFavourites from '../../models/webcontactfavourites'
import WebContactNotifications from '../../models/webcontactnotifications'

export const searchContacts = async args => {
  const result = await client.query({
    query: WebContact.query('search', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebContact.queries.search.name]
}

export const updateContact = async args => {
  let result = null
  try {
    result = await client.query({
      query: WebContact.mutate('update', args)
    })
  } catch (e) {
    console.error(result)
    throw e
  }
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebContact.mutations.update.name]
}

export const detachContact = async args => {
  const result = await client.query({
    query: WebContact.mutate('detach', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebContact.mutations.detach.name]
}

export const getContact = async WebContactID => {
  const result = await client.query({
    query: WebContact.query('get', { WebContactID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebContact.queries.get.name]
}

export const getAddressForContact = async WebContactID => {
  const result = await client.query({
    query: WebContactAddress.query('getAll', { WebContactID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebContactAddress.queries.getAll.name]
}

export const getFavouritesForContact = async WebContactID => {
  const result = await client.query({
    query: WebContactFavourites.query('getAll', { WebContactID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebContactFavourites.queries.getAll.name]
}

export const getNotificationsForContact = async WebContactID => {
  const result = await client.query({
    query: WebContactNotifications.query('getAll', { WebContactID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebContactNotifications.queries.getAll.name]
}

export const deleteFavouritesForContact = async args => {
  const result = await client.query({
    query: WebContactFavourites.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebContactFavourites.mutations.delete.name]
}

export const deleteNotificationsForContact = async args => {
  const result = await client.query({
    query: WebContactNotifications.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebContactNotifications.mutations.delete.name]
}

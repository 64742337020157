<template>
  <div class="my-3">
    <b-form-group
      label="Quantity"
      label-cols-xl="5"
      label-for="qtyForm"
      label-size="sm"
      label-class="pt-4"
    >
      <wine-quantity-form
        id="qtyForm"
        ref="qtyForm"
        :value="qtyFormData"
        :show-cases="showCases"
        :bottle-title="bottleTitle"
        @change-cases="cases = $event"
        @change-bottles="bottles = $event"
      />
    </b-form-group>
    <b-form-group
      label="Cost Price £"
      label-for="costPrice"
      label-cols-xl="5"
      label-size="sm"
    >
      <price-confirmation-change
        id="costPrice"
        v-model="costPrice"
        size="sm"
        :state="!$v.costPrice.$error && null"
        :show-confirm="false"
      />
      <b-form-invalid-feedback>
        Cost Price is required and must be a number
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Usage"
      label-for="costPrice"
      label-cols-xl="5"
      label-size="sm"
    >
      <b-form-select
        id="costPrice"
        v-model="usage"
        size="sm"
        :options="usageOptions"
        :state="!$v.usage.$error && null"
      />
      <b-form-invalid-feedback>
        Please select a usage
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols-xl="5"
    >
      <b-checkbox
        v-model="originalPackaging"
        :disabled="mixedCase"
      >
        <div class="wv-check__label text-left">
          Original Packaging
        </div>
      </b-checkbox>
    </b-form-group>
    <b-form-group
      label="Case Size"
      label-for="caseSize"
      label-cols-xl="5"
      label-size="sm"
    >
      <b-form-input
        id="caseSize"
        v-model="caseSize"
        size="sm"
        :disabled="!originalPackaging || (mixedCase && value.caseSize !== '')"
        :state="!$v.caseSize.$error && null"
      />
      <b-form-invalid-feedback>
        <template v-if="!$v.caseSize.maxValue">
          Case size cannot be bigger than 99
        </template>
        <template v-if="!$v.caseSize.minValue">
          Case size cannot be smaller than 1
        </template>
        <template v-if="!$v.caseSize.integer">
          Case size must be a number
        </template>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols-xl="5"
    >
      <b-checkbox
        v-model="emptyOWX"
        :disabled="!originalPackaging"
      >
        <div class="wv-check__label text-left">
          Use Empty OWC
        </div>
      </b-checkbox>
    </b-form-group>
    <b-form-group>
      <slot />
    </b-form-group>
    <b-form-group
      label-cols-xl="5"
    >
      <b-checkbox
        v-model="photoRequired"
      >
        <div class="wv-check__label text-left">
          Photo Required
        </div>
      </b-checkbox>
    </b-form-group>
    <b-modal
      id="modal-1"
      v-model="showSellingPriceModal"
      modal-class="wv-modal"
      content-class="shadow"
      @ok="handleSellingPriceModel"
    >
      <template v-slot:modal-header>
        Are you sure?
      </template>

      <div class="scoped-warning-modal">
        <img src="/images/warning.png" class="scoped-warning-modal__image">

        <div class="scoped-warning-modal__content">
          <p>Cost Price is higher than the List Price.</p>
        </div>
      </div>

      <template v-slot:modal-footer="{ ok, hide }">
        <b-button class="btn wv-btn wv-btn--w100 btn-sm" @click="ok()">
          Yes
        </b-button>

        <b-button class="btn wv-btn wv-btn--w100 wv-btn--hightlight btn-sm" @click="hide()">
          No
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  integer,
  required,
  requiredIf,
  decimal,
  minValue,
  maxValue
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { formObjectMixin } from '../../mixins/forms'
import { mapObjectToVModel } from '../../helpers/helpers'
import PriceConfirmationChange from '../Base/PriceConfirmationChange'
import WineQuantityForm from './WineQuantityForm'
import UserMixin from '~/mixins/user'

export default {
  components: {
    WineQuantityForm,
    PriceConfirmationChange
  },
  mixins: [validationMixin, formObjectMixin, UserMixin],
  props: {
    bottleTitle: {
      type: String,
      default: 'Bottles'
    },
    showCases: {
      type: Boolean,
      default: true
    },
    itemCaseSize: {
      type: Number,
      required: true
    },
    itemSellingPrice: {
      type: Number,
      required: true
    },
    mixedCase: {
      type: Boolean,
      default: false
    },
    usageOptions: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      showSellingPriceModal: false
    }
  },
  computed: {
    ...mapObjectToVModel([
      'cases',
      'bottles',
      'costPrice',
      'usage',
      'originalPackaging',
      'caseSize',
      'emptyOWX',
      'photoRequired'
    ]),
    qtyFormData() {
      return {
        bottles: this.value.bottles || null,
        cases: this.value.cases || null
      }
    },
    totalBottles() {
      return (
        parseInt(this.cases || 0) * parseInt(this.itemCaseSize) +
        parseInt(this.bottles || 0)
      )
    }
  },
  watch: {
    usage(value) {
      this.userDefaultPurchaseOrderUsageId = value
    },
    originalPackaging(op) {
      if (op) {
        this.caseSize = this.itemCaseSize
      } else {
        this.caseSize = undefined
      }
    }
  },
  validations: {
    usage: {
      required
    },
    costPrice: {
      required,
      decimal,
      minValue: minValue(1)
    },
    caseSize: {
      required: requiredIf('originalPackaging'),
      integer,
      minValue: minValue(0),
      maxValue: maxValue(99)
    },
    cases: {
      integer
    },
    bottles: {
      integer
    }
  },
  methods: {
    reset() {
      this.formReset()
      this.showSellingPriceModal = false
      return this
    },
    handleSellingPriceModel(ok) {
      this.showSellingPriceModal = false
      this.resolveResponse()
    },
    afterValidate() {
      return new Promise((resolve, reject) => {
        const price = parseFloat(this.costPrice)
        if (this.totalBottles < 1) {
          return reject(new Error('Invalid quantity entered.'))
        }
        if (price < 1) {
          return reject(new Error('Invalid price.'))
        }
        if (price > this.itemSellingPrice) {
          this.showSellingPriceModal = true
          return this.awaitResponse(resolve, reject)
        }
        resolve()
      })
    }
  },
  subValidations: ['qtyForm']
}
</script>

<style scoped>
.scoped-warning-modal {
  display: flex;
  align-items: center;
}

.scoped-warning-modal__image {
  width: 90px;
  height: auto;
  margin-right: 15px;
}

.scoped-warning-modal__content {
  width: calc(100% - 90px);
}
</style>

import Entity from '~/models/entity'

const inputFields = {
  Sender: {
    type: String,
    required: true
  },
  SenderName: {
    type: String,
    required: true
  },
  Body: {
    type: String,
    required: true
  },
  Subject: {
    type: String,
    required: true
  },
  Recipients: {
    type: Array,
    fields: {
      ContactID: {
        type: Number
      },
      CompanyName: {
        type: String
      },
      ContactName: {
        type: String,
        required: true
      },
      EmailAddress: {
        type: String,
        required: true
      }
    }
  }
}

// const outputFields = {
//   reserveBuyBackEmail: {
//     type: Boolean
//   }
// }

const BuyBackEmail = new Entity('BuyBackEmail', [])

BuyBackEmail.createMutation(
  'sendEmails',
  'reserveBuyBackEmail',
  inputFields,
  []
)

export default BuyBackEmail

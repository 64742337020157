<template>
  <div>
    <b-row>
      <b-col v-if="showCases" class="py-0">
        <b-form-group
          label="Cases"
          label-size="sm"
          class="m-0"
        >
          <b-form-input
            ref="cases"
            v-model="cases"
            size="sm"
            type="number"
            min="0"
            class="text-center"
            :state="!$v.cases.$error && null"
            trim
            @focus.native="handleFocus"
            @keyup.enter.prevent="$emit('on-enter')"
          />
          <b-form-invalid-feedback>
            Enter a valid number of cases
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col class="py-0">
        <b-form-group
          :label="bottleTitle"
          label-size="sm"
          class="m-0"
        >
          <b-form-input
            v-model="bottles"
            size="sm"
            type="number"
            min="0"
            class="text-center"
            :state="!$v.bottles.$error && null"
            trim
            @focus.native="handleFocus"
            @keyup.enter.prevent="$emit('on-enter')"
          />
          <b-form-invalid-feedback>
            Enter a valid number of bottles
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { integer, requiredUnless, minValue } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { formObjectMixin } from '../../mixins/forms'
import { mapObjectToVModel } from '../../helpers/helpers'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    bottleTitle: {
      type: String,
      default: 'Bottles'
    },
    showCases: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapObjectToVModel(['cases', 'bottles'])
  },
  mounted() {
    if (this.showCases) {
      setTimeout(() => {
        this.$refs.cases.$el.focus()
      }, 1)
    }
  },
  methods: {
    handleFocus(evt) {
      if (evt.target.value === '0') {
        evt.target.select()
      }
    }
  },
  validations: {
    cases: {
      integer,
      required: requiredUnless('bottles'),
      minValue: minValue(0)
    },
    bottles: {
      integer,
      required: requiredUnless('cases'),
      minValue: minValue(0)
    }
  }
}
</script>

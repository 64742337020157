<template>
  <div>
    <span v-if="!justify" v-b-tooltip.hover class="text-nowrap" :title="title" @click="handleClick">
      {{ computedTotalBottles|bottlesToCase(caseSize, showZero, showMinus)|toString }}
    </span>
    <div v-else v-b-tooltip.hover :title="title">
      <b-row>
        <b-col cols="6" class="text-right pr-1">
          <span v-if="stockLevelObj.cases > 0 || showZero" :class="caseClass">
            {{ stockLevelObj.cases }}cs
          </span>
        </b-col>
        <b-col cols="6" class="pl-1">
          <span v-if="stockLevelObj.caseBottles > 0 || showZero" :class="bottleClass">
            {{ stockLevelObj.caseBottles }}{{ stockLevelObj.getBottleUnit(overrideBottleUnitFormatCaseSize) }}
          </span>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caseSize: {
      type: Number,
      required: true
    },
    cases: {
      type: Number,
      required: false,
      default: null
    },
    bottles: {
      type: Number,
      required: false,
      default: null
    },
    totalBottles: {
      type: Number,
      required: false,
      default: null
    },
    justify: {
      type: Boolean,
      required: false,
      default: false
    },
    showZero: {
      type: Boolean,
      required: false,
      default: false
    },
    showMinus: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    caseClass: {
      type: [String, Object],
      required: false,
      default: ''
    },
    bottleClass: {
      type: [String, Object],
      required: false,
      default: ''
    },
    overrideBottleUnitFormatCaseSize: {
      type: [Number],
      default: 0
    }
  },
  computed: {
    computedTotalBottles() {
      if (this.totalBottles === null) {
        return this.caseSize * this.cases + this.bottles
      } else {
        return this.totalBottles
      }
    },
    stockLevelObj() {
      return this.$options.filters.bottlesToCase(
        this.computedTotalBottles,
        this.caseSize,
        this.showZero
      )
    }
  },
  methods: {
    handleClick() {
      if (this.disabled === false) {
        this.$emit('click')
      }
    }
  }
}
</script>

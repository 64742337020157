import Entity from './entity'
import { fields as contactFields } from './contact'

const addressFields = {
  AddrID: {
    type: Number
  },
  Addr1: {
    type: String
  },
  Addr2: {
    type: String
  },
  Addr3: {
    type: String
  },
  Addr4: {
    type: String
  },
  Postcode: {
    type: String
  },
  CountryCode: {
    type: String
  },
  ForAttentionOf: {
    type: String
  },
  Telephone: {
    type: String
  },
  DeliveryCompany: {
    type: String
  }
}

const InvoiceAddress = {
  type: Object,
  fields: {
    ContactAddress: {
      type: Object,
      fields: addressFields
    },
    WebAddress: {
      type: Object,
      fields: addressFields
    },
    Preferred: {
      type: String
    }
  }
}

const DeliveryAddresses = {
  type: Array,
  fields: {
    Action: {
      type: String
    },
    WebContactAddressID: {
      type: Number
    },
    AddrID: {
      type: Number
    },
    Preferred: {
      type: String
    },
    ContactAddress: {
      type: Object,
      fields: addressFields
    },
    WebAddress: {
      type: Object,
      fields: addressFields
    }
  }
}

const fields = {
  WebContactID: {
    type: Number
  },
  ContactID: {
    type: Number
  },
  FirstName: {
    type: String
  },
  LastName: {
    type: String
  },
  EmailAddress: {
    type: String
  },
  EmailVerified: {
    type: Boolean
  },
  EmailVerifiedDate: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  LastLoginDate: {
    type: String
  },
  PreferredCurrencyCode: {
    type: String
  },
  Approved: {
    type: Boolean
  },
  AuditApprovedDate: {
    type: String
  },
  CompanyName: {
    type: String
  },
  Telephone1: {
    type: String
  },
  Telephone2: {
    type: String
  },
  Fax: {
    type: String
  },
  VATNo: {
    type: String
  },
  AWRSNumber: {
    type: String
  },
  Archived: {
    type: Boolean
  }
}

const WebContact = new Entity('WebContact', fields)
WebContact.createQuery(
  'search',
  'webContactSearch',
  {
    current_page: {
      type: Number
    },
    per_page: {
      type: Number
    },
    total: {
      type: Number
    },
    webContactPaginatedResults: {
      type: Array,
      fields: fields
    }
  },
  {
    SearchTerm: {
      type: String
    },
    DateFrom: {
      type: String
    },
    DateTo: {
      type: String
    },
    Approved: {
      type: Boolean
    },
    HasContact: {
      type: Boolean
    },
    EmailVerified: {
      type: Boolean
    },
    ContactID: {
      type: Number
    },
    Page: {
      type: Number
    },
    PerPage: {
      type: Number
    }
  }
)

WebContact.createQuery(
  'get',
  'webContactGet',
  fields,
  Entity.onlyKeys(fields, ['WebContactID'])
)

WebContact.createMutation(
  'detach',
  'webContactDetach',
  Entity.onlyKeys(fields, ['WebContactID']),
  fields
)

WebContact.createMutation(
  'update',
  'webContactUpdate',
  {
    ...Entity.onlyKeys(fields, [
      'WebContactID',
      'FirstName',
      'LastName',
      'EmailAddress',
      'EmailVerified',
      'AuditAmendedDate',
      'Approved',
      'CompanyName',
      'ContactID',
      'Telephone1',
      'Telephone2',
      'Fax',
      'VATNo',
      'AWRSNumber',
      'Archived'
    ]),
    InvoiceAddress,
    DeliveryAddresses,
    AdditionalAddresses: {
      type: Array,
      fields: addressFields
    },
    WPOID: {
      type: Number
    }
  },
  {
    WebContact: {
      type: Object,
      fields
    },
    Addresses: {
      type: Array,
      fields: {
        ...addressFields,
        WebContactAddressID: {
          type: String
        },
        WebContactID: {
          type: String
        },
        Approved: {
          type: Boolean
        },
        IsDelivery: {
          type: Boolean
        }
      }
    },
    Contact: {
      type: Object,
      fields: contactFields
    },
    ContactAddresses: {
      type: Array,
      fields: {
        ...addressFields,
        ContactID: {
          type: Number
        },
        AuditAmendedDate: {
          type: Number
        }
      }
    },
    ContactPrimaryAddress: {
      type: Object,
      fields: addressFields
    }
  }
)

export default WebContact

<template>
  <div>
    <b-row class="mt-2">
      <b-col class="col col-12 col-xxl-10">
        <group-box :body-class="{'light-green-background': customerReviewDirty || usingDefaults}">
          <template slot="header">
            Customer Review (Repeat Wines)
            <b-spinner v-if="loading" type="grow" label="Loading..." small />
          </template>
          <customer-review-form
            ref="customerReview"
            :value="value"
            :loading="loading"
            @is-dirty="customerReviewDirty = $event"
            @change="handleFormChange"
          />
        </group-box>
      </b-col>
      <b-col class="col col-12 col-xxl-2">
        <b-button size="sm" class="wv-btn" :disabled="loading || !hasChanged" block @click="$emit('save')">
          Save
        </b-button>
        <b-button size="sm" class="wv-btn" :disabled="loading" block @click="$emit('cancel')">
          Cancel
        </b-button>
        <b-button size="sm" class="wv-btn" :disabled="reloadDisabled" block @click="clickReload">
          Reload
        </b-button>
        <b-button size="sm" class="wv-btn" :disabled="loading || usingDefaults" block @click="clickReset">
          Reset ...
        </b-button>
      </b-col>
    </b-row>
    <group-box class="mt-3" :body-class="{'light-green-background': newWinePromotionsDirty || usingDefaults}">
      <template slot="header">
        New Wine Promotions (Repeat Wines)
        <b-spinner v-if="loading" type="grow" label="Loading..." small />
      </template>
      <new-wine-promotion-form
        ref="newWinePromotion"
        :value="value"
        :loading="loading"
        @is-dirty="newWinePromotionsDirty = $event"
        @change="handleFormChange"
      />
    </group-box>
  </div>
</template>

<script>
import CustomerReviewForm from '~/components/Forms/PromotionEmailTemplates/CustomerReview'
import NewWinePromotionForm from '~/components/Forms/PromotionEmailTemplates/NewWinePromotion'
import GroupBox from '~/components/Base/GroupBox'
import { formObjectMixin } from '~/mixins/forms'
import { validationMixin } from 'vuelidate'
export default {
  components: {
    CustomerReviewForm,
    NewWinePromotionForm,
    GroupBox
  },
  mixins: [formObjectMixin, validationMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    usingDefaults: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      customerReviewDirty: false,
      newWinePromotionsDirty: false
    }
  },
  computed: {
    reloadDisabled() {
      if (this.usingDefaults) {
        return false
      }
      if (this.loading) {
        return true
      }
      if (this.hasChanged) {
        return false
      }
      return true
    }
  },
  methods: {
    clickReset() {
      this.$emit('reset')
    },
    clickReload() {
      this.$emit('reload')
    }
  },
  subValidations: ['customerReview', 'newWinePromotion']
}
</script>

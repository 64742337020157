import ReportRequestForm from '~/components/Modal/Export/WineListExport/ReportRequestForm'
import { merge } from 'lodash'

export default {
  components: { ReportRequestForm },
  computed: {
    reportFormParameters() {
      return merge(
        {},
        this.$store.getters['export/wineList/reportParameters'],
        this.$store.getters['export/settings/reportParameters'],
        this.settingsReportParameters,
        this.$store.getters['wineBrowser/filters/exportParameters']
      )
    }
  }
}

import StoragePricingGroup from '~/models/storage/storagePricingGroup'
import { client } from '~/api/graphQLInstance'

export const getStoragePricingGroups = async params => {
  const result = await client.query({
    query: StoragePricingGroup.query('getAll', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[StoragePricingGroup.queries.getAll.name]
}

export const addStoragePricingGroup = async params => {
  // console.log('addStoragePricingGroup', params)
  const result = await client.query({
    query: StoragePricingGroup.mutate('add', params)
  })
  // console.log('addStoragePricingGroup Results', result)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = StoragePricingGroup.mutations.add.name
  return result.data[key]
}

export const updateStoragePricingGroup = async params => {
  // console.log('updateStoragePricingGroup', params)
  const result = await client.query({
    query: StoragePricingGroup.mutate('update', params)
  })
  // console.log('updateStoragePricingGroup Results', result)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = StoragePricingGroup.mutations.update.name
  return result.data[key]
}

export const removeStoragePricingGroup = async params => {
  // console.log('removeStoragePricingGroup', params)
  const result = await client.query({
    query: StoragePricingGroup.mutate('delete', params)
  })
  // console.log('removeStoragePricingGroup Results', result)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = StoragePricingGroup.mutations.delete.name
  return result.data[key]
}

import { keys, each } from 'lodash'
import { getAxios } from '~/api/axios'
import { client } from '../graphQLInstance'
import RotationCard from '../../models/rotationcard'
import RotationCardImage from '../../models/rotationcardimage'

export const getById = async id => {
  const result = await client.query({
    query: RotationCard.query('get', {
      RecordId: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RotationCard.queries.get.name]
}

export const getByNumber = async number => {
  const result = await client.query({
    query: RotationCard.query('getByNumber', {
      RotationNumber: number
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RotationCard.queries.getByNumber.name]
}

export const getImagePaths = async id => {
  const result = await client.query({
    query: RotationCard.query('getPaths', {
      RotationCardId: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RotationCard.queries.getPaths.name]
}

export const getImagePathsByRotationNumber = async number => {
  const result = await client.query({
    query: RotationCard.query('getPaths', {
      RotationNumber: number
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RotationCard.queries.getPaths.name]
}

export const updateById = async args => {
  const result = await client.query({
    query: RotationCard.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RotationCard.mutations.update.name]
}

export const uploadPictures = async ({ form, token }) => {
  const formData = new FormData()
  const args = form
  each(keys(args), key => {
    if (key === 'images') {
      for (let i = 0; i < args.images.length; i++) {
        formData.append('images[]', args.images[i])
      }
    } else {
      formData.append(key, args[key])
    }
  })
  const response = await getAxios().post(
    process.env.apiHost + '/rotation/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
  )
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Error ' + response.status + ' from api')
}

export const updateImage = async args => {
  const result = await client.query({
    query: RotationCardImage.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RotationCardImage.mutations.update.name]
}

export const addRotationCardImage = async args => {
  const result = await client.query({
    query: RotationCardImage.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RotationCardImage.mutations.insert.name]
}

export const deleteRotationCardImage = async (id, rotationId) => {
  const result = await client.query({
    query: RotationCardImage.mutate('delete', {
      RotationCardImageID: id,
      RotationCardID: rotationId
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RotationCardImage.mutations.delete.name]
}

export const emailImages = async ({
  to,
  toName,
  subject,
  body,
  images,
  sender,
  senderName
}) => {
  const result = await client.query({
    query: RotationCardImage.mutate('email', {
      To: to,
      ToName: toName,
      Sender: sender,
      SenderName: senderName,
      Subject: subject,
      Body: body,
      Images: images
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RotationCardImage.mutations.email.name]
}

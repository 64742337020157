import Entity from './entity'

const fields = {
  WineCardID: {
    type: Number
  },
  ReservedBottles: {
    type: Number
  }
}

const ReservedStock = new Entity('ReservedStock', fields)
ReservedStock.createMutation('update', 'reservedStockUpdate', fields, {})

export default ReservedStock

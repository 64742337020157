import { client } from '../graphQLInstance'
import Classification from '../../models/classification'

export const getClassifications = async args => {
  const result = await client.query({
    query: Classification.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Classification.queries.get.name]
}

export const updateClassification = async args => {
  const result = await client.query({
    query: Classification.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Classification.mutations.update.name]
}

export const addClassification = async args => {
  const result = await client.query({
    query: Classification.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Classification.mutations.insert.name]
}

export const deleteClassification = async args => {
  const result = await client.query({
    query: Classification.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Classification.mutations.delete.name]
}

import Vue from 'vue'

import { each } from 'lodash'

export const state = () => ({
  billingRuns: [],
  billingRunsIndex: {}
})

export const mutations = {
  appendBillingRun(state, billingRun) {
    const currentIndex = state.billingRunsIndex[billingRun.BRID]
    if (currentIndex !== undefined) {
      Vue.set(state.billingRuns, currentIndex, billingRun)
    } else {
      const currentLength = state.billingRuns.length
      state.billingRuns.push(billingRun)
      Vue.set(state.billingRunsIndex, billingRun.BRID, currentLength)
    }
  },
  removeBillingRunById(state, BRID) {
    const currentIndex = state.billingRunsIndex[BRID]
    if (currentIndex !== undefined) {
      Vue.delete(state.billingRuns, currentIndex)
      state.billingRunsIndex = {}
      each(state.billingRuns, (billingRun, idx) => {
        Vue.set(state.billingRunsIndex, billingRun.BRID, idx)
      })
    }
  }
}

export const actions = {
  appendBillingRun({ commit }, billingRun) {
    commit('appendBillingRun', billingRun)
  },
  closeBillingRun({ commit }, billingRun) {
    commit('removeBillingRunById', billingRun)
  }
}

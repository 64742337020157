import Entity from './entity'

const fields = {
  POID: {
    type: Number
  },
  POLID: {
    type: Number
  },
  OrderDate: {
    type: String
  },
  InventoryID: {
    type: Number
  },
  Contact: {
    type: String
  },
  ContactID: {
    type: Number
  },
  PurchasePrice: {
    type: String
  },
  ReservePrice: {
    type: String
  },
  BrokingPrice: {
    type: String
  },
  InventoryTotalBottles: {
    type: Number
  },
  RotationNumber: {
    type: String
  },
  AvailableTotalBottles: {
    type: Number
  },
  AvailableTotalBottlesReserves: {
    type: Number
  },
  UsageCode: {
    type: String
  },
  DutyStatusName: {
    type: String
  },
  RotationCardImagesCount: {
    type: Number
  },
  OriginalPackaging: {
    type: Number
  },
  Notes: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  MatchedTransaction: {
    type: Number
  },
  LCBLocation: {
    type: String
  },
  ETCID: {
    type: Number
  },
  SageSupplierAcctNo: {
    type: String
  },
  PurchaseOrderTypeID: {
    type: Number
  }
}

const StockCardPurchase = new Entity('StockCardPurchase', fields)

StockCardPurchase.createQuery('get', 'stockCardPurchasesGet', fields)

export default StockCardPurchase

import {
  updateVineyard,
  addVineyard,
  deleteVineyard,
  getVineyards
} from '~/api/administration/vineyard'
import { merge, find } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  saving: false,
  list: [],
  searchTerm: '',
  active: null
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  setList(state, list) {
    state.list = list
  },
  resetList(state) {
    state.list = []
  },
  addToList(state, country) {
    state.list.unshift(country)
  },
  setActive(state, { VineyardID }) {
    state.active = find(state.list, { VineyardID })
  },
  update(state, wineName) {
    const idx = state.list.findIndex(w => w.VineyardID === wineName.VineyardID)
    if (idx > -1) {
      const newWineName = merge({}, state.list[idx], wineName)
      Vue.set(state.list, idx, newWineName)
    }
  },
  removeFromList(state, { VineyardID }) {
    state.list = state.list.filter(wn => wn.VineyardID !== VineyardID)
  }
}

export const actions = {
  async loadList({ commit }) {
    commit('setLoading', true)
    const response = await getVineyards()
    commit('setList', response)
    commit('setLoading', false)
  },
  setActive({ commit }, country) {
    commit('setActive', country)
  },
  async add({ commit }, counry) {
    commit('setSaving', true)
    try {
      const result = await addVineyard(counry)
      commit('addToList', result)
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async update({ commit, rootState, state }, country) {
    commit('setSaving', true)
    try {
      const result = await updateVineyard(country)
      commit('update', result)
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async delete({ commit }, country) {
    commit('setSaving', true)
    try {
      const result = await deleteVineyard(country)
      if (result) {
        commit('removeFromList', country)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  }
}

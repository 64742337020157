<template>
  <div>
    <div class="wv-card" :class="bodyClass" :style="{ 'min-height': minHeight + 'px' }">
      <div
        v-if="header"
        class="wv-card__title "
        :class="[
          large ? 'wv-card__title--large' : '',
          color
        ]"
      >
        <slot name="header">
          {{ header }}
        </slot>
        <button v-if="collapsible" class="wv-card__collapse" @click="$emit('click-collapse')">
          {{ collapseStateFormatted }}
        </button>
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    minHeight: {
      type: String,
      required: false,
      default: null
    },
    header: {
      type: [Boolean, String],
      required: false,
      default: true
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    collapseState: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: ''
    },
    bodyClass: {
      type: [String, Object],
      required: false,
      default: ''
    }
  },
  computed: {
    collapseStateFormatted() {
      return this.collapseState ? '+' : '-'
    }
  }
}
</script>

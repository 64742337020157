import {
  getBillingRun,
  generateBillingRun,
  updateBillingRun,
  processBillingRun,
  duplicateBillingRun,
  deleteBillingRun
} from '~/api/storage/billingRun'
import { getBillingRunContacts } from '~/api/storage/billingRunContact'
import {
  getBillingRunInventories,
  updateBillingRunInventory,
  updateBillingRunInventoryBulk,
  updateBillingRunContact
} from '~/api/storage/billingRunInventory'
// import { without } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  billingRuns: {},
  billingRunContacts: {},
  billingRunInventories: {},
  currentBillingRunId: null,
  // selectedBillingRunContacts: [],
  currentBillingRunContactIndex: null,
  loadingInventories: false,
  invalidInventories: [],
  selectedBillingRunInventories: {},
  dirty: false,
  updatingInventories: false,
  soBillingRunInventories: {}
})

export const getters = {
  getBillingRun: state => {
    return state.billingRuns[state.currentBillingRunId]
  },
  getBillingRunContacts: state => {
    return state.billingRunContacts[state.currentBillingRunId]
  },
  // selectedBillingRunContact: state => id => {
  //   return state.selectedBillingRunContacts.indexOf(id) >= 0
  // },
  hasSelected: state => {
    if (
      state.currentBillingRunId &&
      state.billingRunContacts[state.currentBillingRunId]
    ) {
      const processableContact = Object.values(
        state.billingRunContacts[state.currentBillingRunId]
      ).find(
        billingRunContact =>
          (billingRunContact.Process && !billingRunContact.Processed) ||
          (billingRunContact.Indeterminate && !billingRunContact.Processed)
      )
      return processableContact !== undefined
    } else {
      return false
    }
  },
  hasWaived: state => {
    if (
      state.currentBillingRunId &&
      state.billingRunContacts[state.currentBillingRunId]
    ) {
      const waivableContact = Object.values(
        state.billingRunContacts[state.currentBillingRunId]
      ).find(
        billingRunContact =>
          (billingRunContact.Waive && !billingRunContact.Processed) ||
          (billingRunContact.IndeterminateWaive && !billingRunContact.Processed)
      )
      return waivableContact !== undefined
    } else {
      return false
    }
  },
  getBillingRunContact: state => {
    if (state.currentBillingRunContactIndex === null) {
      return null
    }
    // prettier-ignore
    return state.billingRunContacts[state.currentBillingRunId][state.currentBillingRunContactIndex]
  },
  getBillingRunInventoriesForCurrentBillingRun: state => {
    // prettier-ignore
    const billingRunContacts = state.billingRunContacts[state.currentBillingRunId].map(({ BRCID }) => BRCID)

    return billingRunContacts.reduce((previous, current) => {
      return [...previous, ...(state.billingRunInventories[current] || [])]
    }, [])
  },
  getBillingRunInventories: state => {
    // console.log(
    //   'getter getBillingRunInventories index',
    //   state.currentBillingRunContactIndex
    // )
    if (state.currentBillingRunContactIndex !== false) {
      // prettier-ignore
      const BRCID = state.billingRunContacts[state.currentBillingRunId][state.currentBillingRunContactIndex].BRCID
      // console.log('getter getBillingRunInventories', BRCID)
      return state.billingRunInventories[BRCID]
    } else {
      return {}
    }
  },
  getBillingRunInventoriesForSOID: state => SOID => {
    // console.log('getter getBillingRunInventoriesForSOID', SOID)
    if (state.soBillingRunInventories.hasOwnProperty(SOID)) {
      return state.soBillingRunInventories[SOID]
    } else {
      return []
    }
  },
  hasBillingRunInventories: state => BRCID => {
    // console.log(
    //   'hasBillingRunInventories',
    //   BRCID,
    //   state.billingRunInventories[BRCID],
    //   state.billingRunInventories[BRCID] !== undefined
    // )
    return state.billingRunInventories[BRCID] !== undefined
  },
  loadedInventories: state => {
    return Object.keys(state.billingRunInventories).map(i => parseInt(i))
  },
  loading: state => {
    return state.loading
  },
  dirty: state => {
    if (
      state.currentBillingRunId &&
      state.billingRunContacts[state.currentBillingRunId]
    ) {
      // console.log(
      //   state,
      //   state.billingRunContacts[state.currentBillingRunId],
      //   Object.values(state.billingRunContacts[state.currentBillingRunId])
      // )
      const dirtyContact = Object.values(
        state.billingRunContacts[state.currentBillingRunId]
      ).find(
        billingRunContact =>
          billingRunContact.Total !== billingRunContact.originalTotal ||
          billingRunContact.Process !== billingRunContact.originalProcess ||
          billingRunContact.Waive !== billingRunContact.originalWaive ||
          billingRunContact.originalInventoriesToProcess !==
            billingRunContact.InventoriesToProcess ||
          billingRunContact.originalInventoriesToWaive !==
            billingRunContact.InventoriesToWaive
      )

      const dirtyInventory = undefined
      return dirtyContact !== undefined || dirtyInventory !== undefined
    } else {
      return false
    }
  },
  getInvalidInventories: state => {
    // console.log('getInvalidInventories', state.invalidInventories)
    return state.invalidInventories
  }
}

export const mutations = {
  setLoading(state, loading) {
    // console.log('setLoading', loading)
    state.loading = loading === true
  },
  reset(state, BRID) {
    /*
     * While Potentially useful, this code is ultimately unpredictable and therefore i've removed it
     */

    // if (BRID && state.currentBillingRunContactIndex !== null) {
    //   if (
    //     state.billingRunContacts[BRID] &&
    //     state.currentBillingRunContactIndex >=
    //       state.billingRunContacts[BRID].length
    //   ) {
    //     console.log(
    //       state.billingRunContacts[BRID].length,
    //       state.currentBillingRunContactIndex
    //     )
    //     state.currentBillingRunContactIndex = null
    //   } else if (state.billingRunContacts[BRID]) {
    //     // prettier-ignore
    //     const BRCID = state.billingRunContacts[BRID][state.currentBillingRunContactIndex].BRCID
    //     console.log('reset', BRCID, state.billingRunInventories[BRCID])
    //     if (state.billingRunInventories[BRCID] === undefined) {
    //       this.dispatch('storage/billingRun/getBillingRunInventories', BRCID)
    //     }
    //   } else {
    //     state.currentBillingRunContactIndex = null
    //   }
    // }

    state.currentBillingRunContactIndex = null
  },
  appendBillingRun(state, billingRun) {
    // console.log('appendBillingRun', billingRun)
    Vue.set(state.billingRuns, billingRun.BRID, billingRun)
  },
  setCurrentBillingRun(state, billingRun) {
    state.currentBillingRunId = billingRun.BRID
  },
  appendBillingRunContacts(state, data) {
    // console.log('appendBillingRunContacts', data)

    data.results.forEach(item => {
      item.originalTotal = item.Total
      item.originalProcess = item.Process
      item.originalWaive = item.Waive
      item.originalInventoriesToProcess = item.InventoriesToProcess
      item.originalInventoriesToWaive = item.InventoriesToWaive
      item.Indeterminate =
        item.InventoriesToProcess !== item.InventoriesTotal &&
        item.InventoriesToProcess > 0
      item.IndeterminateWaive =
        item.InventoriesToWaive !== item.InventoriesTotal &&
        item.InventoriesToWaive > 0
      return item
    })

    Vue.set(state.billingRunContacts, data.BRID, data.results)
  },
  setSelectedBillingRunContactValue(state, { BRCID, Process, Waive }) {
    state.dirty = true

    // console.log('setSelectedBillingRunContactValue', BRCID, Process, Waive)

    const index = state.billingRunContacts[state.currentBillingRunId].findIndex(
      item => item.BRCID === BRCID
    )

    if (Waive === null && Process) {
      Waive = false
    }

    if (Process === null && Waive) {
      Process = false
    }

    // console.log('setSelectedBillingRunContactValue', BRCID, Process, Waive)

    const processTotal = Process
      ? state.billingRunContacts[state.currentBillingRunId][index]
          .InventoriesTotal
      : 0

    const waiveTotal = Waive
      ? state.billingRunContacts[state.currentBillingRunId][index]
          .InventoriesTotal
      : 0

    const payload = {
      Indeterminate: false,
      InventoriesToProcess: processTotal,
      InventoriesToWaive: waiveTotal,
      IndeterminateWaive: false
    }

    if (Process !== null) {
      payload.Process = Process
    }

    if (Waive !== null) {
      payload.Waive = Waive
    }

    // console.log(payload)

    const billingRunContact = Object.assign(
      {},
      state.billingRunContacts[state.currentBillingRunId][index],
      payload
    )

    state.billingRunContacts[state.currentBillingRunId].splice(
      index,
      1,
      billingRunContact
    )

    if (Process !== null || Waive !== null) {
      this.commit('storage/billingRun/toggleAllBillingRunInventories', {
        BRCID: BRCID,
        process: Process,
        waive: Waive
      })
    }

    this.commit(
      'storage/billingRun/updateBillingRunContactIndeterminateValue',
      BRCID
    )
  },
  updateBillingRunContactIndeterminateValue(state, BRCID) {
    const index = state.billingRunContacts[state.currentBillingRunId].findIndex(
      item => item.BRCID === BRCID
    )

    if (!state.billingRunContacts[state.currentBillingRunId][index].Processed) {
      if (state.billingRunInventories[BRCID]) {
        const selected = state.billingRunInventories[BRCID].filter(
          item => item.Process
        ).length

        const value =
          state.billingRunInventories[BRCID].length !== selected && selected > 0

        const waived = state.billingRunInventories[BRCID].filter(
          item => item.Waive
        ).length

        const waivedValue =
          state.billingRunInventories[BRCID].length !== waived && waived > 0

        // console.log('updateBillingRunContactIndeterminateValue', BRCID, value)

        const billingRunContact = Object.assign(
          {},
          state.billingRunContacts[state.currentBillingRunId][index],
          {
            Indeterminate: value,
            Process: selected === state.billingRunInventories[BRCID].length,
            InventoriesToProcess: selected,
            IndeterminateWaive: waivedValue,
            Waive: waived === state.billingRunInventories[BRCID].length,
            InventoriesToWaive: waived
          }
        )

        state.billingRunContacts[state.currentBillingRunId].splice(
          index,
          1,
          billingRunContact
        )
      }
    }
  },
  setSelectedBillingRunInventoryValue(state, { BRCID, BRIID, value }) {
    state.dirty = true
    // console.log('setSelectedBillingRunInventoryValue', BRCID, BRIID, value)
    const index = state.billingRunInventories[BRCID].findIndex(
      item => item.BRIID === BRIID
    )
    // console.log(state.billingRunInventories[BRCID][index])

    const payload = { Process: value }
    if (value === true) {
      payload.Waive = false
    }

    const billingRunInventory = Object.assign(
      {},
      state.billingRunInventories[BRCID][index],
      payload
    )

    state.billingRunInventories[BRCID].splice(index, 1, billingRunInventory)

    this.commit(
      'storage/billingRun/updateBillingRunContactIndeterminateValue',
      BRCID
    )
  },
  setWaivedBillingRunInventoryValue(state, { BRCID, BRIID, value }) {
    state.dirty = true
    // console.log('setWaivedBillingRunInventoryValue', BRCID, BRIID, value)
    const index = state.billingRunInventories[BRCID].findIndex(
      item => item.BRIID === BRIID
    )
    // console.log(state.billingRunInventories[BRCID][index])

    const payload = { Waive: value }
    if (value === true) {
      payload.Process = false
    }

    const billingRunInventory = Object.assign(
      {},
      state.billingRunInventories[BRCID][index],
      payload
    )

    state.billingRunInventories[BRCID].splice(index, 1, billingRunInventory)

    this.commit(
      'storage/billingRun/updateBillingRunContactIndeterminateValue',
      BRCID
    )
  },
  toggleAllBillingRunContacts(state) {
    // console.log('toggleAllBillingRunContacts')

    // prettier-ignore
    const unProcessed = state.billingRunContacts[state.currentBillingRunId].filter(item => item.Processed === false && item.Contact.NoStorageCharge === false)

    let value = Object.values(unProcessed).filter(
      item =>
        item.Process === true ||
        item.Indeterminate === true ||
        item.Dirty === true
    )

    value =
      value.length === 0 || value.length !== Object.values(unProcessed).length

    Object.entries(state.billingRunContacts[state.currentBillingRunId]).forEach(
      ([key, billingRunContact]) => {
        if (
          !billingRunContact.Processed &&
          !billingRunContact.Dirty &&
          !billingRunContact.Contact.NoStorageCharge
        ) {
          const waive = value === true ? false : billingRunContact.Waive

          this.commit('storage/billingRun/setSelectedBillingRunContactValue', {
            BRCID: billingRunContact.BRCID,
            Process: value,
            Waive: waive
          })
        }
      }
    )
  },
  toggleAllBillingRunContactsWaived(state) {
    // console.log('toggleAllBillingRunContactsWaived')

    // prettier-ignore
    const unProcessed = state.billingRunContacts[state.currentBillingRunId].filter(item => item.Processed === false && item.Contact.NoStorageCharge === false)

    let waive = Object.values(unProcessed).filter(
      item =>
        item.Waive === true ||
        item.IndeterminateWaive === true ||
        item.Dirty === true
    )

    // console.log('selected', waive)

    waive =
      waive.length === 0 || waive.length !== Object.values(unProcessed).length

    Object.entries(state.billingRunContacts[state.currentBillingRunId]).forEach(
      ([key, billingRunContact]) => {
        if (
          !billingRunContact.Processed &&
          !billingRunContact.Dirty &&
          !billingRunContact.Contact.NoStorageCharge
        ) {
          const process = waive === true ? false : billingRunContact.Process

          this.commit('storage/billingRun/setSelectedBillingRunContactValue', {
            BRCID: billingRunContact.BRCID,
            Process: process,
            Waive: waive
          })
        }
      }
    )
  },
  toggleAllBillingRunInventories(
    state,
    { BRCID, process = null, waive = null }
  ) {
    // console.log('toggleAllBillingRunInventories', BRCID, process, waive)
    if (state.billingRunInventories[BRCID]) {
      state.billingRunInventories[BRCID].forEach(billingRunInventory => {
        // if (billingRunInventory.Inventory.InventoryStatusID === 4) {
        if (process !== null) {
          this.commit(
            'storage/billingRun/setSelectedBillingRunInventoryValue',
            {
              BRCID: BRCID,
              BRIID: billingRunInventory.BRIID,
              value: process
            }
          )
        }
        if (waive !== null) {
          this.commit('storage/billingRun/setWaivedBillingRunInventoryValue', {
            BRCID: BRCID,
            BRIID: billingRunInventory.BRIID,
            value: waive
          })
        }
        // }
      })
    }
  },
  setCurrentBillingRunContact(state, billingRunContact) {
    // console.log('store setCurrentBillingRunContact', billingRunContact)
    // prettier-ignore
    state.currentBillingRunContactIndex = state.billingRunContacts[state.currentBillingRunId].findIndex(brc => billingRunContact.BRCID === brc.BRCID)
  },
  appendBillingRunInventories(state, data) {
    // console.log('appendBillingRunInventories', data)

    if (data.BRCID) {
      // prettier-ignore
      const billingRunContactIndex = state.billingRunContacts[state.currentBillingRunId].findIndex(billingRunContact => data.BRCID === billingRunContact.BRCID)

      // prettier-ignore
      if (state.billingRunContacts[state.currentBillingRunId][billingRunContactIndex]) {

        // prettier-ignore
        const selected = state.billingRunContacts[state.currentBillingRunId][billingRunContactIndex].Process
        // prettier-ignore
        const indeterminate = state.billingRunContacts[state.currentBillingRunId][billingRunContactIndex].Indeterminate

        // prettier-ignore
        const waived = state.billingRunContacts[state.currentBillingRunId][billingRunContactIndex].Waive
        // prettier-ignore
        const indeterminateWaive = state.billingRunContacts[state.currentBillingRunId][billingRunContactIndex].IndeterminateWaive

        // console.log('appendBillingRunInventories', waived, indeterminateWaive)

        data.results.forEach(item => {
          item.originalPrice = item.Price
          item.originalFromDate = item.FromDate
          item.originalProcess = item.Process
          item.originalWaive = item.Waive

          if (!item.Processed) {
            // && item.Inventory.InventoryStatusID === 4
            if (selected) {
              item.Process = true
            } else if (!selected && !indeterminate) {
              item.Process = false
            }

            if (waived) {
              item.Waive = true
            } else if (!waived && !indeterminateWaive) {
              item.Waive = false
            }
          }

          return item
        })

        // state.billingRunInventories[data.BRCID] = data.results
        Vue.set(state.billingRunInventories, data.BRCID, data.results)

        this.commit(
          'storage/billingRun/updateBillingRunContactIndeterminateValue',
          data.BRCID
        )
      }
    } else if (data.SOID) {
      // state.billingRunInventories[data.BRCID] = data.results
      Vue.set(state.soBillingRunInventories, data.SOID, data.results)
    }
  },
  setLoadingInventories(state, bool) {
    state.loadingInventories = bool === true
  },
  setUpdatingInventories(state, bool) {
    state.updatingInventories = bool === true
  },
  setBillingRunPeriods(state, data) {
    state.periods = data.map(period => {
      return { value: period.BRPID, text: period.Name }
    })
  },
  setInventoryValidity(state, { BRIID, valid }) {
    // console.log('setInventoryValidity', BRIID, valid)
    if (valid && state.invalidInventories.includes(BRIID)) {
      state.invalidInventories = state.invalidInventories.filter(
        item => item !== BRIID
      )
    } else if (!state.invalidInventories.includes(BRIID) && !valid) {
      state.invalidInventories.push(BRIID)
    }
  },
  updateBillingRunInventory(state, result) {
    // console.log('commit updateBillingRunInventory ', result)
    if (state.billingRunInventories[result.BRCID]) {
      const index = state.billingRunInventories[result.BRCID].findIndex(
        item => item.BRIID === result.BRIID
      )
      // console.log(state.billingRunInventories[result.BRCID][index])
      const billingRunInventory = Object.assign(
        {},
        state.billingRunInventories[result.BRCID][index],
        result
      )
      state.billingRunInventories[result.BRCID].splice(
        index,
        1,
        billingRunInventory
      )
    }
  },
  updateBillingRunContactData(state, BRCID) {
    // console.log('updateBillingRunContactData', BRCID)

    const index = state.billingRunContacts[state.currentBillingRunId].findIndex(
      item => item.BRCID === BRCID
    )

    if (state.billingRunInventories[BRCID]) {
      const Price = state.billingRunInventories[BRCID].reduce(
        (value, item) => value + item.Price,
        0
      )

      const Units = state.billingRunInventories[BRCID].reduce(
        (value, item) => value + item.Bottles,
        0
      )

      const billingRunContact = Object.assign(
        {},
        state.billingRunContacts[state.currentBillingRunId][index],
        { Total: Price, Bottles: Units }
      )

      state.billingRunContacts[state.currentBillingRunId].splice(
        index,
        1,
        billingRunContact
      )
    }
  }
}

export const actions = {
  async getBillingRun({ commit, dispatch }, BRID) {
    const result = await getBillingRun(BRID)
    // console.log('res', result)
    if (result) {
      commit('appendBillingRun', result)
      commit('setCurrentBillingRun', result)
      dispatch('storage/tabs/appendBillingRun', result, {
        root: true
      })
    }
    return result
  },
  async getBillingRunContacts({ commit, dispatch }, BRID) {
    const result = await getBillingRunContacts({ BRID: BRID })
    if (result) {
      commit('appendBillingRunContacts', { BRID: BRID, results: result })
    }
    return result
  },
  async getBillingRunInventories({ commit }, params) {
    commit('setLoadingInventories', true)
    // console.log('action getBillingRunInventories', params)
    const result = await getBillingRunInventories(params)
    if (result) {
      const payload = { results: result }
      if (params.BRCID) {
        payload.BRCID = params.BRCID
      } else if (params.SOID) {
        payload.SOID = params.SOID
      }
      commit('appendBillingRunInventories', payload)
    }
    commit('setLoadingInventories', false)
    return result
  },
  async updateBillingRunInventory({ commit }, payload) {
    commit('setUpdatingInventories', true)
    try {
      // console.log('action updateBillingRunInventory ', payload)
      const result = await updateBillingRunInventory(payload)
      if (result) {
        // console.log('result updateBillingRunInventory', result)
        commit('setInventoryValidity', {
          BRIID: payload.BRIID,
          valid: true
        })
        commit('updateBillingRunInventory', result)
        commit('updateBillingRunContactData', result.BRCID)
        // commit('appendBillingRunInventories', { BRCID: BRCID, results: result })
      }
      return result
    } catch (e) {
      commit('setInventoryValidity', { BRIID: payload.BRIID, valid: false })
      throw e
    } finally {
      commit('setUpdatingInventories', false)
    }
  },
  async updateBillingRunContact({ commit }, payload) {
    commit('setUpdatingInventories', true)
    try {
      // console.log('action updateBillingRunContact ', payload)
      const result = await updateBillingRunContact(payload)
      if (result) {
        // console.log('result updateBillingRunContact', result)
        if (result.length > 0) {
          result.forEach(res => {
            commit('setInventoryValidity', {
              BRIID: res.BRIID,
              valid: true
            })
            commit('updateBillingRunInventory', res)
          })
          commit('updateBillingRunContactData', result[0].BRCID)
        }
      }
      return result
    } catch (e) {
      // payload.BRIIDS.forEach(BRIID =>
      //   commit('setInventoryValidity', { BRIID: BRIID, valid: false })
      // )
      throw e
    } finally {
      commit('setUpdatingInventories', false)
    }
  },
  async updateBillingRunInventoryBulk({ commit }, payload) {
    commit('setUpdatingInventories', true)
    try {
      // console.log('action updateBillingRunInventoryBulk ', payload)
      const result = await updateBillingRunInventoryBulk(payload)
      if (result) {
        // console.log('result updateBillingRunInventoryBulk', result)
        if (result.length > 0) {
          result.forEach(res => {
            commit('setInventoryValidity', {
              BRIID: res.BRIID,
              valid: true
            })
            commit('updateBillingRunInventory', res)
          })
          commit('updateBillingRunContactData', result[0].BRCID)
        }
      }
      return result
    } catch (e) {
      payload.BRIIDS.forEach(BRIID =>
        commit('setInventoryValidity', { BRIID: BRIID, valid: false })
      )
      throw e
    } finally {
      commit('setUpdatingInventories', false)
    }
  },
  async generateBillingRun({ commit }, payload) {
    // commit('setLoadingInventories', true)
    try {
      // console.log('action generateBillingRun', payload)
      const result = await generateBillingRun(payload)
      if (result) {
        // console.log('result', result)
        commit('appendBillingRun', result)
      }
      return result
    } catch (e) {
      throw e
    }
  },
  async updateBillingRun({ commit }, payload) {
    // commit('setLoadingInventories', true)
    try {
      // console.log('action updateBillingRun', payload)
      const result = await updateBillingRun(payload)
      if (result) {
        // console.log('result', result)
        commit('updateBillingRun', result)
      }
      return result
    } catch (e) {
      throw e
    }
  },
  async processBillingRun({ commit }, { BRID, waive = false }) {
    // commit('setLoadingInventories', true)
    try {
      // console.log('action processBillingRun', BRID, waive)
      const result = await processBillingRun({
        BRID: BRID,
        Waive: waive
      })
      if (result) {
        // console.log('result', result)
        commit('appendBillingRun', result)
      }
      return result
    } catch (e) {
      throw e
    }
  },
  async duplicateBillingRun({ commit }, BRID) {
    // commit('setLoadingInventories', true)
    try {
      // console.log('action duplicateBillingRun', BRID)
      const result = await duplicateBillingRun(BRID)
      if (result) {
        // console.log('result', result)
        // commit('appendBillingRun', result)
      }
      return result
    } catch (e) {
      throw e
    }
  },
  async deleteBillingRun({ commit }, BRID) {
    // commit('setLoadingInventories', true)
    try {
      // console.log('action deleteBillingRun', BRID)
      const result = await deleteBillingRun(BRID)
      if (result) {
        // console.log('result', result)
        // commit('deleteBillingRun', result)
      }
      return result
    } catch (e) {
      throw e
    }
  }
}

<template>
  <edit-wine-note-modal
    v-if="note"
    v-model="showWineNotesEditModal"
    :item="activeCard"
    :loading="wineCardLoading"
    :saving="notesLoading"
    :note="note"
  />
</template>

<script>
import EditWineNoteModal from '~/components/Modal/EditWineNote'

export default {
  components: {
    EditWineNoteModal
  },
  computed: {
    showWineNotesEditModal: {
      get() {
        return this.$store.state.modal.showWineNotesEditModal
      },
      set(value) {
        this.$store.commit('modal/setShowWineNotesEditModal', value)
      }
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeCard
    },
    notesLoading() {
      return this.$store.state.wineCard.notes.loading
    },
    wineCardLoading() {
      return this.$store.state.wineCard.activeCard.loading
    },
    note() {
      return this.$store.getters['wineCard/notes/getSelectedNote']
    }
  },
  watch: {
    showWineNotesEditModal(shown) {
      if (shown) {
        this.$store.dispatch(
          'wineCard/activeCard/getActiveCard',
          this.note.WineCardID
        )
      }
    }
  }
}
</script>

import Entity from '../entity'
import { auditFields } from '../helpers'
import { fields as emptyCaseFields } from '../emptycases'

const lineFields = {
  ETCLID: {
    type: Number
  },
  ETCLGID: {
    type: Number
  },
  Abv: {
    type: Number
  },
  RotationNumberPrefix: {
    type: Number
  },
  RotationNumber: {
    type: Number
  },
  RotationNumberSuffix: {
    type: Number
  },
  RotationNumberManuallySet: {
    type: Boolean
  },
  Location: {
    type: String
  },
  ExpectedBottles: {
    type: Number
  },
  ReceivedBottles: {
    type: Number
  },
  DutyStatusID: {
    type: Number
  },
  OriginalPackaging: {
    type: Boolean
  },
  OrigPkgCaseSize: {
    type: Number
  },
  OriginalPackagingConfirmedBy: {
    type: Number
  },
  OriginalPackagingConfirmedDate: {
    type: String
  },
  FreeCirculation: {
    type: Boolean
  },
  CRD: {
    type: Boolean
  },
  ContainsSulphites: {
    type: Boolean
  },
  Notes: {
    type: String
  },
  NoteType: {
    type: String
  },
  WineCardID: {
    type: Number
  },
  ComputedWineName: {
    type: String
  },
  FormatName: {
    type: String
  },
  FormatVolume: {
    type: Number
  },
  FormatID: {
    type: Number
  },
  WineVintage: {
    type: String
  },
  Litres: {
    type: Number
  },
  Value: {
    type: Number
  },
  Lwin: {
    type: String
  },
  PhotoRequired: {
    type: Boolean
  },
  FormatCaseSize: {
    type: Number
  },
  Changes: {
    type: Array
  },
  PackagingType: {
    type: Number
  },
  PackagingTypeText: {
    type: Number
  },
  PhotoFileName: {
    type: String
  },
  InventoryID: {
    type: Number
  },
  ComputedRotationNumber: {
    type: String
  },
  ImageCount: {
    type: Number
  },
  Returned: {
    type: Number
  },
  ReturnHandled: {
    type: Number
  },
  MixedCase: {
    type: Boolean
  },
  HasOriginalPackagingIssue: {
    type: Boolean
  },
  AllocatedSnapshotConsideredAnOver: {
    type: Boolean
  },
  FinalisedAsNewLine: {
    type: Boolean
  },
  RemovedFromInventory: {
    type: Boolean
  },
  ...auditFields
}

const groupFields = {
  ETCLGID: {
    type: Number
  },
  POLID: {
    type: Number
  },
  Type: {
    type: Number
  },
  POID: {
    type: Number
  },
  CostPrice: {
    type: Number
  },
  Lines: {
    type: Array,
    fields: lineFields
  },
  StockOnOrder: {
    type: Number
  },
  UseEmptyOWC: {
    type: Boolean
  },
  EmptyCases: {
    type: Array,
    fields: emptyCaseFields
  },
  WasConsideredAnOver: {
    type: Boolean
  },
  ...auditFields
}

const fields = {
  ETCID: {
    type: Number
  },
  ...auditFields,
  Status: {
    type: Number
  },
  UID: {
    type: String
  },
  DateReceived: {
    type: String
  },
  VehicleReg: {
    type: String
  },
  BookingRef: {
    type: String
  },
  InspectedBy: {
    type: Number
  },
  InspectedDate: {
    type: String
  },
  CheckedBy: {
    type: Number
  },
  CheckedDate: {
    type: String
  },
  Notes: {
    type: String
  },
  ContactID: {
    type: Number
  },
  Groups: {
    type: Array,
    fields: groupFields
  },
  StartingRotationNumber: {
    type: Number
  },
  HasPurchaseOrderGroups: {
    type: Boolean
  },
  IsEditable: {
    type: Boolean
  },
  POIDs: {
    type: Array
  },
  PreAdviseSentAt: {
    type: String
  },
  Reference: {
    type: String
  },
  IsReserves: {
    type: Number
  }
}

const ETallyCard = new Entity('ETallyCard', fields)

ETallyCard.createMutation(
  'deleteLine',
  'etallyLineDelete',
  {
    ETCLIDs: {
      type: Array
    }
  },
  fields
)

ETallyCard.createMutation(
  'delete',
  'etallyDelete',
  {
    ETCID: {
      type: Number
    }
  },
  {
    Deleted: {
      type: Boolean
    }
  }
)

ETallyCard.createMutation(
  'receiveAll',
  'etallyLineReceiveAll',
  Entity.onlyKeys(fields, ['ETCID']),
  fields
)

ETallyCard.createMutation(
  'updateLine',
  'etallyLineUpdate',
  {
    ...Entity.onlyKeys(lineFields, [
      'ETCLID',
      'ReceivedBottles',
      'ExpectedBottles',
      'DutyStatusID',
      'FreeCirculation',
      'CRD',
      'ContainsSulphites',
      'Abv',
      'Location',
      'WineCardID',
      'OriginalPackaging',
      'OrigPkgCaseSize',
      'Notes',
      'NoteType',
      'RotationNumberSuffix',
      'RotationNumberPrefix',
      'PackagingType'
    ]),
    Split: {
      type: Boolean
    },
    Combine: {
      type: Boolean
    },
    MoveBottles: {
      type: Number
    }
  },
  fields
)
ETallyCard.createMutation(
  'updateGroup',
  'etallyGroupUpdate',
  Entity.onlyKeys(groupFields, ['ETCLGID', 'CostPrice']),
  fields
)

ETallyCard.createMutation(
  'returnLine',
  'etallyLineReturn',
  Entity.onlyKeys(lineFields, ['ETCLID', 'Returned', 'ReturnHandled']),
  lineFields
)

ETallyCard.createMutation(
  'create',
  'createEtallyCard',
  {
    ...Entity.onlyKeys(fields, ['ETCID']),
    PurchaseOrderLines: {
      type: Array,
      fields: {
        POLID: {
          type: Number
        },
        PhotoRequired: {
          type: Boolean
        },
        AttachToGroup: {
          type: Number
        }
      }
    }
  },
  fields
)

ETallyCard.createMutation(
  'createFromWineCard',
  'createEtallyCardFromWineCard',
  {
    WineCardID: {
      type: Number
    },
    Bottles: {
      type: Number
    },
    AsDutyPaid: {
      type: Boolean
    },
    PhotoRequired: {
      type: Boolean
    },
    OriginalPackaging: {
      type: Boolean
    },
    OrigPkgCaseSize: {
      type: Number
    },
    ETCID: {
      type: Number
    }
  },
  fields
)

ETallyCard.createMutation(
  'createFromNoWineCard',
  'createEtallyCardFromNoWineCard',
  {
    LWIN: {
      type: Number
    },
    WineVintage: {
      type: String
    },
    ComputedWineName: {
      type: String
    },
    FormatID: {
      type: Number
    },
    Bottles: {
      type: Number
    },
    AsDutyPaid: {
      type: Boolean
    },
    PhotoRequired: {
      type: Boolean
    },
    OriginalPackaging: {
      type: Boolean
    },
    OrigPkgCaseSize: {
      type: Number
    },
    Abv: {
      type: Number
    },
    ETCID: {
      type: Number
    }
  },
  fields
)

ETallyCard.createMutation(
  'update',
  'etallyCardUpdate',
  Entity.onlyKeys(fields, [
    'ETCID',
    'Status',
    'UID',
    'DateReceived',
    'VehicleReg',
    'BookingRef',
    'InspectedBy',
    'CheckedBy',
    'Notes',
    'ContactID'
  ]),
  fields
)

ETallyCard.createMutation(
  'updateRotations',
  'etallyUpdateRotations',
  {
    ETCID: {
      type: Number
    },
    StartingRotationNumber: {
      type: Number
    },
    RotationNumbers: {
      type: Array,
      fields: {
        ETCLID: {
          type: Number
        },
        RotationNumber: {
          type: Number
        }
      }
    }
  },
  fields
)

ETallyCard.createMutation(
  'clearRotations',
  'etallyClearRotations',
  {
    ETCID: {
      type: Number
    }
  },
  fields
)

ETallyCard.createMutation(
  'manualRotation',
  'etallyManuallySetRotation',
  {
    ETCLID: {
      type: Number
    },
    RotationNumber: {
      type: Number
    }
  },
  fields
)

ETallyCard.createQuery('get', 'eTallyCardGet', fields, {
  ID: {
    type: Number
  }
})

ETallyCard.createQuery(
  'search',
  'eTallySearch',
  {
    total: {
      type: Number
    },
    eTallySearchResults: {
      type: Array,
      fields: {
        UID: {
          type: Number
        },
        ETCID: {
          type: Number
        },
        AuditCreatedDate: {
          type: String
        },
        CompanyName: {
          type: String
        },
        CreatedBy: {
          type: String
        },
        POID: {
          type: String
        }
      }
    }
  },
  {
    Status: {
      type: Number
    },
    Search: {
      type: String
    },
    PerPage: {
      type: Number
    },
    Page: {
      type: Number
    }
  }
)

ETallyCard.createMutation(
  'finalise',
  'etallyFinalise',
  {
    ETCID: {
      type: Number
    },
    PartialFinalisation: {
      type: Boolean
    },
    FinalisedData: {
      type: Array,
      fields: {
        POLID: {
          type: Number
        },
        UpdateTransactionBottlesWithExpected: {
          type: Boolean
        },
        UpdateReceivedBottles: {
          type: Boolean
        },
        Price: {
          type: Number
        },
        MoveToPOID: {
          type: Number
        },
        ETCLIDs: {
          type: Array
        },
        ConfirmedDutyStatusChange: {
          type: Number
        }
      }
    }
  },
  fields
)

ETallyCard.createMutation(
  'sendPreAdvice',
  'etallySendPreAdvice',
  {
    ETCID: {
      type: Number
    }
  },
  fields
)

ETallyCard.createQuery('returnSearch', 'eTallyReturnedLineGet', {
  current_page: {
    type: Number
  },
  last_page: {
    type: Number
  },
  next_page_url: {
    type: String
  },
  per_page: {
    type: Number
  },
  prev_page_url: {
    type: String
  },
  total: {
    type: Number
  },
  etallyReturnedLineResult: {
    type: Array,
    fields: {
      ...lineFields,
      ReturnedAt: {
        type: String
      },
      ReturnHandledAt: {
        type: String
      },
      POID: {
        type: Number
      },
      CardReference: {
        type: String
      },
      ETCID: {
        type: Number
      }
    }
  }
})

export default ETallyCard

<template>
  <div>
    <b-modal
      v-model="shown"
      centered
      size="xl"
      modal-class="wv-modal"
      content-class="shadow remove-footer fill-screen"
      @shown="resetAndLoadEmail"
      @ok="sendEmail"
    >
      <template v-slot:modal-header>
        Send Email
      </template>
      <b-tabs
        v-model="activeTab"
        class="h-100 d-flex flex-column"
        content-class="mt-3 flex-grow-1 d-flex flex-column"
        small
        @input="handleActiveTab"
      >
        <b-tab title="Email" class="h-100" active>
          <div class="d-flex flex-column h-100">
            <b-row class="flex-shrink-1">
              <b-col md="6" class="p-1">
                <recipient
                  ref="recip"
                  :contact-can-change="contactCanChange"
                  :contact="selectedContact"
                  @change-to-name="updateToName"
                  @change-to="updateTo"
                  @change-to-self="updateToSelf"
                  @change-salutation="updateSalutation"
                  @search-contact="handleSearchContact"
                />
              </b-col>
              <b-col md="6" class="p-1">
                <sender
                  ref="sender"
                  :name-label="nameLabel"
                  @change-sender-name="updateSenderName"
                  @change-sender="updateSender"
                  @change-copy-to-self="updateCopyToSelf"
                />
              </b-col>
            </b-row>
            <b-row class="mt-1 flex-grow-1">
              <b-col md="12" class="p-1">
                <email-content
                  class="h-100"
                  @reset="resetAndLoadEmail"
                  @reload="loadEmailContents"
                />
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab v-for="attachment in emailAttachments" :key="attachment.Key" class="h-100" :title="attachment.Name">
          <email-attachment
            class="h-100 d-flex"
            :attachment="attachment"
            :shown="activeTab === 1"
          />
        </b-tab>
      </b-tabs>
      <template v-slot:modal-footer>
        <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="sendEmail">
          <b-spinner v-if="saving" type="grow" label="Loading..." small />
          Send
        </b-button>
        <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="shown=false">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { debounce, keys } from 'lodash'
import UserMixin from '../../mixins/user'
import Recipient from './Export/EmailRecipient'
import Sender from './Export/EmailSender'
import EmailContent from './Export/EmailContent'
import EmailAttachment from './Export/EmailAttachment'

export default {
  components: {
    EmailAttachment,
    Recipient,
    Sender,
    EmailContent
  },
  mixins: [UserMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      activeTab: 0,
      saving: false,
      silenceHidden: false,
      switchTabFirstLoad: false,
      debounceUpdate: debounce(this.loadEmailContents, 500)
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    contactCanChange() {
      return this.$store.state.export.email.contactCanChange
    },
    selectedContact() {
      return this.$store.state.export.email.selectedContact
    },
    nameLabel() {
      const k = keys(this.$store.state.export.email.emailApiArgs)
      if (k.indexOf('WineListExport') >= 0) {
        return 'Very best wishes'
      }
      return 'Name'
    },
    emailAttachments: {
      get() {
        if (this.$store.state.export.email.emailContent) {
          return this.$store.state.export.email.emailContent.Attachments
        }
        return []
      }
    },
    searchTerm() {
      if (
        this.$store.state.export.email &&
        this.$store.state.export.email.options
      ) {
        return this.$store.state.export.email.options.To
      }
      return null
    }
  },
  watch: {
    shown(show, prev) {
      if (prev === false && show) {
        this.switchTabFirstLoad = true
      }
    },
    emailAttachments(attachments) {
      if (this.switchTabFirstLoad && attachments.length > 0) {
        setTimeout(() => {
          this.activeTab = 1
          this.switchTabFirstLoad = false
        }, 0)
      }
    }
  },
  mounted() {
    this.$eventHub.$on('selected-contact', this.contactSelected)
  },
  destroyed() {
    this.$eventHub.$off('selected-contact', this.contactSelected)
  },
  methods: {
    loadEmailContents() {
      this.$store.dispatch('export/email/actionEmail', false)
    },
    updateToName(ToName) {
      this.$store.commit('export/email/mergeOptions', { ToName })
      this.debounceUpdate()
    },
    updateTo(To) {
      this.$store.commit('export/email/mergeOptions', { To })
    },
    updateToSelf(ToSelf) {
      this.$store.commit('export/email/mergeOptions', { ToSelf })
    },
    updateSalutation(Salutation) {
      this.$store.commit('export/email/mergeOptions', { Salutation })
      this.debounceUpdate()
    },
    updateSenderName(SenderName) {
      this.$store.commit('export/email/mergeOptions', { SenderName })
      this.debounceUpdate()
    },
    updateSender(Sender) {
      this.$store.commit('export/email/mergeOptions', { Sender })
    },
    updateCopyToSelf(CopyToSelf) {
      this.$store.commit('export/email/mergeOptions', { CopyToSelf })
      this.userCopyInEmails = CopyToSelf
    },
    resetAndLoadEmail() {
      this.$store.commit('export/email/reset')
      this.$refs.recip.resetData()
      this.$refs.sender.resetData()
      this.$store.commit('export/email/mergeOptions', {
        CopyToSelf: this.userCopyInEmails
      })
      this.loadEmailContents()
    },
    async sendEmail(e) {
      e.preventDefault()
      try {
        await this.$refs.recip.validate()
        await this.$refs.sender.validate()
        this.saving = true
        await this.$store.dispatch('export/email/actionEmail', true)
        this.shown = false
        this.$notify.success('Email has been sent')
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.saving = false
      }
    },
    handleActiveTab(tab) {
      this.activeTab = tab
    },
    handleSearchContact(searchTerm) {
      const key = 'contacts/contacts/setupFilters'
      const paramKey = 'contacts/contacts/searchParameters'
      this.$store.dispatch(key, {
        term: searchTerm,
        status: 'Active',
        show: []
      })
      this.$store.commit('modal/setShowContactsSearchModal', true)
      const searchParams = this.$store.getters[paramKey]
      this.$store.dispatch('contacts/contacts/contactSearch', searchParams)
    },
    contactSelected(contact) {
      this.$store.commit('export/email/setContact', contact)
      this.$store.commit('export/email/mergeOptions', {
        ToName: contact.ContactName || contact.CompanyName
      })
      if (contact.NoEmailContact) {
        this.$store.commit('export/email/mergeOptions', {
          To: this.$store.state.user.user.email
        })
        this.updateToSelf(true)
      } else {
        this.$store.commit('export/email/mergeOptions', {
          To: contact.EmailAddress
        })
      }
    }
  }
}
</script>

<style>
.custom-width-150 {
  width: 150px !important;
}
</style>

import Entity from './entity'

const fields = {
  AppellationID: {
    type: Number
  },
  AppellationName: {
    type: String
  }
}

const AppellationDropDown = new Entity('AppellationDropDown', fields)
AppellationDropDown.createQuery('get', 'appellationDropDown')

export default AppellationDropDown

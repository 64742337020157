import { client } from '../graphQLInstance'
import Country from '../../models/countrydropdown'

export const getCountries = async args => {
  const result = await client.query({
    query: Country.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Country.queries.get.name]
}

export const updateCountry = async args => {
  const result = await client.query({
    query: Country.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Country.mutations.update.name]
}

export const addCountry = async args => {
  const result = await client.query({
    query: Country.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Country.mutations.insert.name]
}

export const deleteCountry = async args => {
  const result = await client.query({
    query: Country.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Country.mutations.delete.name]
}

import { client } from '../graphQLInstance'
import RecommendedWine from '../../models/recommendedwine'

export const getPreviousWines = async id => {
  const result = await client.query({
    query: RecommendedWine.query('getPrevious', {
      ContactID: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RecommendedWine.queries.getPrevious.name]
}

export const getRecommendedWines = async id => {
  const result = await client.query({
    query: RecommendedWine.query('get', {
      ContactID: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[RecommendedWine.queries.get.name]
}

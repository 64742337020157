import {
  getWineSearchers,
  addWineSearcher,
  deleteWineSearcher
} from '@/api/administration/winesearcher'
import { findIndex } from 'lodash'
import Vue from 'vue'
export const state = () => ({
  loading: false,
  saving: false,
  list: []
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  setList(state, list) {
    state.list = list
  },
  appendToList(state, item) {
    state.list.push(item)
  },
  removeFromList(state, { SellerNameMatch }) {
    const idx = findIndex(state.list, { SellerNameMatch })
    if (idx >= 0) {
      Vue.delete(state.list, idx)
    }
  }
}

export const actions = {
  async getList({ commit }) {
    commit('setLoading', true)
    try {
      const response = await getWineSearchers()
      if (response) {
        commit('setList', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async addWineSearcher({ commit }, name) {
    commit('setSaving', true)
    try {
      const response = await addWineSearcher(name)
      commit('appendToList', response)
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async deleteWineSearcher({ commit, dispatch }, seller) {
    commit('setSaving', true)
    try {
      await deleteWineSearcher(seller.SellerNameMatch)
      commit('removeFromList', seller)
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  }
}

<template>
  <new-wine-card
    v-model="showWineCardModal"
    @saved="handleSave"
    @updated-using-lwin="handleUpdatedLwin"
  />
</template>

<script>
import NewWineCard from '~/components/Modal/NewWineCard'

export default {
  components: {
    NewWineCard
  },
  computed: {
    showWineCardModal: {
      get() {
        return this.$store.state.modal.showWineCardModal
      },
      set(value) {
        this.$store.commit('modal/setShowWineCardModal', value)
      }
    }
  },
  methods: {
    handleSave(newWineCard) {
      this.$eventHub.$emit('new-wine-card', newWineCard)
      this.showWineCardModal = false
    },
    handleUpdatedLwin(lwinData) {
      this.$eventHub.$emit('new-wine-card-updated-lwin', lwinData)
    }
  }
}
</script>

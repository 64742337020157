import { client } from '../graphQLInstance'
import Vineyard from '../../models/vineyard'

export const getVineyards = async args => {
  const result = await client.query({
    query: Vineyard.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Vineyard.queries.get.name]
}

export const updateVineyard = async args => {
  const result = await client.query({
    query: Vineyard.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Vineyard.mutations.update.name]
}

export const addVineyard = async args => {
  const result = await client.query({
    query: Vineyard.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Vineyard.mutations.insert.name]
}

export const deleteVineyard = async args => {
  const result = await client.query({
    query: Vineyard.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Vineyard.mutations.delete.name]
}

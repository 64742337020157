<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :hide-footer="true"
    size="xl"
    ok-only
    ok-variant="secondary"
    ok-title="Exit"
    @hidden="$emit('hidden')"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <template v-slot:default="{ ok }">
      <div v-if="loading" class="text-center text-primary my-2">
        <b-spinner type="grow" label="Loading..." />
      </div>
      <div v-else>
        <b-row>
          <b-col class="col-12 col-xxl-10">
            <available-wine-stock-table
              :items="stockItems"
              :require-mandatory="requireMandatory"
              :usage-code-id="item.usageCodeId"
              :sell-at-loss="item.sellAtLoss"
              @updated-running-totals="handleRunningTotals"
            />
          </b-col>
          <b-col class="col-12 col-xxl-2">
            <b-button class="btn wv-btn btn-secondary btn-sm mb-2 d-block w-100" @click="ok()">
              Exit
            </b-button>
            <actions
              :totals="totals"
              :require-mandatory="requireMandatory"
              :sell-at-loss="item.sellAtLoss"
              @mandatory-orig-pkg="handleRequireMandatory"
              @update-sell-at-loss="handleSellAtLoss"
            />
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>
<script>
import AvailableWineStockTable from './CurrentStock/AvailableWineStockTable'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'
import Actions from './CurrentStock/Actions'
import FanOutToSearchStoresMixin from '~/mixins/winebrowser/FanOutToSearchStores'

export default {
  components: {
    AvailableWineStockTable,
    Actions
  },
  mixins: [TitleFromWineCard, FanOutToSearchStoresMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    stockItems: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      totals: {},
      requireMandatory: false,
      titleSuffix: 'Current Available Stock'
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    handleRunningTotals(totals) {
      this.totals = totals
    },
    handleRequireMandatory(isRequired) {
      this.requireMandatory = isRequired
    },
    async handleSellAtLoss(sellAtLoss) {
      try {
        const wineCard = await this.saveWineCardAndSetActive({
          id: this.item.id,
          updatedCardDetails: {
            sellAtLoss: sellAtLoss,
            auditAmendedDate: this.item.auditAmendedDate
          }
        })
        this.commitToSearches('wineCards/setAuditAmendedDate', wineCard)
        this.commitToSearches('wineCards/setSellAtLoss', wineCard)
        this.$notify.success('Wine Card Updated')
      } catch (e) {
        this.$notifyError(e)
      }
    }
  }
}
</script>

<template>
  <group-box :body-class="isValidClass" class="h-100">
    <template slot="header">
      Loose Bottle Price
    </template>
    <b-row>
      <b-col class="col-12 col-xxl-12 mb-2">
        <b-form-group
          label="Avg. Loose Cost"
          label-for="averagePrice"
          label-size="sm"
          label-cols-xxl="6"
          class="form-control--input-121"
        >
          <b-form-input
            id="averagePrice"
            :value="averagePrice | round(2) | currency"
            size="sm"
            disabled
            class="font-weight-bold text-right"
          />
        </b-form-group>
        <b-form-group
          label="Price"
          label-for="looseBottlePrice"
          label-size="sm"
          label-cols-xxl="6"
          class="mb-0"
        >
          <price-confirmation-change
            v-model="editedLooseBottlePrice"
            :input-class="{listPriceClass: true, 'text-right': true, 'font-weight-bold': true, 'form-control form-control-sm': true}"
            :show-confirm="false"
            :show-if-empty="false"
          />
        </b-form-group>

        <b-form-group
          :label="'Price / ' + format_code"
          label-for="pricePerBottle"
          label-size="sm"
          label-cols-xxl="6"
          class="mb-2 mt-2"
        >
          <price-confirmation-change
            v-model="pricePerBottle"
            :input-class="{listPriceClass: true, 'text-right': true, 'font-weight-bold': true, 'form-control form-control-sm': true}"
            :show-confirm="false"
            :show-if-empty="false"
            :disabled="true"
          />
        </b-form-group>
        <b-form-group
          label="Markup"
          label-for="markupPercentage"
          label-size="sm"
          label-cols-xxl="6"
          class="form-control--input-121"
        >
          <b-form-input
            id="listPriceClass"
            :class="{'text-danger' : markupBelowThreshold, 'font-weight-bold text-right' : true}"
            :value="markupPercentage"
            size="sm"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import PriceConfirmationChange from '../../Base/PriceConfirmationChange'
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { minValue, decimal } from 'vuelidate/lib/validators'

export default {
  components: {
    GroupBox,
    PriceConfirmationChange
  },
  mixins: [validationMixin],
  props: {
    averagePrice: {
      type: Number,
      required: true
    },
    averageDaysInStock: {
      type: Number,
      required: true
    },
    availableBottles: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      changedLooseBottlePrice: null
    }
  },
  computed: {
    ...mapState('wineCard/activeCard', {
      looseBottlePrice: state => state.activeBrowserCard.looseBottlePrice,
      caseSize: state => state.activeBrowserCard.case_size,
      format_code: state => state.activeBrowserCard.format_code
    }),
    editedLooseBottlePrice: {
      get() {
        if (this.changedLooseBottlePrice === null) {
          return this.looseBottlePrice
        }
        return this.changedLooseBottlePrice
      },
      set(value) {
        if (value === this.looseBottlePrice) {
          value = null
        }
        this.changedLooseBottlePrice = parseFloat(value)
        if (isNaN(this.changedLooseBottlePrice)) {
          this.changedLooseBottlePrice = null
        } else {
          this.$emit(
            'change-loose-bottle-price',
            parseFloat(value),
            parseFloat(value) >= 0
          )
        }
      }
    },
    isValidClass() {
      return {
        'light-green-background': !this.$v.$invalid && this.anyChanges,
        'pink-background': this.$v.$invalid && this.anyChanges
      }
    },
    anyChanges() {
      return this.changedLooseBottlePrice !== null
    },
    pricePerBottle() {
      return this.editedLooseBottlePrice / this.caseSize
    },
    markupPercentage() {
      if (
        this.editedLooseBottlePrice === 0 ||
        this.editedLooseBottlePrice === null
      ) {
        return 'N/A'
      }
      return this.markUp + '%'
    },
    markupBelowThreshold() {
      if (
        this.editedLooseBottlePrice === 0 ||
        this.editedLooseBottlePrice === null
      ) {
        return false
      }
      return this.markUp < this.$store.state.global.data.MarkUpPercent
    },
    depreciatedMarkupPercentage() {
      return this.depreciatedMarkUp + '%'
    },
    depreciatedMarkUp() {
      if (this.averagePrice === 0) {
        return 0
      }
      if (
        this.editedLooseBottlePrice === 0 ||
        this.editedLooseBottlePrice === null
      ) {
        return 0
      }
      const costOfStorage =
        (this.$store.state.global.data.AnnualBottleStorageCost *
          this.averageDaysInStock *
          this.availableBottles) /
        365.25

      const costOfMoney =
        (this.$store.state.global.data.AnnualDepreciationRate *
          this.averageDaysInStock *
          this.averagePrice) /
        365.25

      const markup =
        ((this.editedLooseBottlePrice -
          this.averagePrice -
          costOfStorage -
          costOfMoney) *
          100) /
        this.averagePrice
      return Math.round(markup)
    },
    markUp() {
      if (this.averagePrice === 0) {
        return 0
      }
      if (
        this.editedLooseBottlePrice === 0 ||
        this.editedLooseBottlePrice === null
      ) {
        return 0
      }
      const markup =
        ((this.editedLooseBottlePrice - this.averagePrice) * 100) /
        this.averagePrice

      return Math.round(markup * 10) / 10
    }
  },
  mounted() {
    // this.$emit('get-bordeaux-index')
  },
  validations: {
    editedLooseBottlePrice: {
      minValue: minValue(0),
      decimal
    }
  }
}
</script>

<template>
  <div>
    <b-modal
      v-model="shown"
      centered
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      :hide-header-close="true"
      size="lg"
      ok-title="Add Case"
      @hidden="updateHiddenEvent"
      @ok="showCreateForm"
      @shown="handleShown"
    >
      <template v-slot:modal-header>
        {{ title }}
      </template>
      <div v-if="loading || wineCardLoading" class="text-center text-primary">
        <b-spinner type="grow" label="Loading..." />
      </div>
      <template v-else>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
        <case-table
          :items="cases"
          :show-attach="hasPolid || showAttach"
          :attached-cases="attachedCases"
          @edit="showEditForm"
          @delete="confirmDeleteEmptyCase"
          @attach="$emit('attach', $event)"
          @detach="$emit('detach', $event)"
        />
      </template>
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn wv-btn btn-sm mr-auto ml-auto" @click="shown=false">
          Exit
        </b-button>
        <b-button class="btn wv-btn btn-sm mr-0" @click="ok()">
          Add Case
        </b-button>
      </template>
    </b-modal>
    <add-empty-case
      v-if="showAddEmptyCase"
      v-model="showAddEmptyCase"
      :item="item"
      @hidden="showAndClearSilence"
    />
    <edit-empty-case
      v-if="showEditEmptyCase"
      v-model="showEditEmptyCase"
      :item="item"
      :case-data="selectedCase"
      @hidden="showAndClearSilence"
    />
    <confirm
      v-model="showDeleteModal"
      title="Are you sure?"
      @ok="deleteEmptyCase"
    >
      Are you sure to want to delete this empty case?
    </confirm>
  </div>
</template>

<script>
import CaseTable from './EmptyCases/CaseTable'
import AddEmptyCase from './AddEmptyCase'
import EditEmptyCase from './EditEmptyCase'
import Confirm from './Confirm'
import FanOutToSearchStoresMixin from '~/mixins/winebrowser/FanOutToSearchStores'
import UserMixin from '~/mixins/user'

export default {
  components: {
    EditEmptyCase,
    CaseTable,
    AddEmptyCase,
    Confirm
  },
  mixins: [FanOutToSearchStoresMixin, UserMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    wineCardLoading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      required: true
    },
    polid: {
      type: Number,
      default: null
    },
    showAttach: {
      type: Boolean,
      default: false
    },
    attachedCases: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      showAddEmptyCase: false,
      showEditEmptyCase: false,
      silenceHidden: false,
      selectedCase: {},
      error: null,
      emptyCaseForDeletion: null,
      showDeleteModal: false
    }
  },
  computed: {
    title() {
      if (this.item) {
        return (
          this.item.year +
          ': ' +
          this.item.wine_name +
          ' (' +
          this.item.format +
          ') - Empty Cases'
        )
      }
      return 'Loading - Empty Cases'
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    loading() {
      return this.$store.state.wineCard.emptyCases.loading
    },
    cases() {
      return this.$store.state.wineCard.emptyCases.cases
    },
    hasPolid() {
      return this.polid !== undefined && this.polid !== null
    }
  },
  watch: {
    item(item) {
      if (item.id !== undefined && this.shown) {
        this.loadEmptyCases(item)
      }
    }
  },
  methods: {
    reset() {
      this.error = null
    },
    showEditForm(item) {
      this.selectedCase = item
      this.silenceHidden = true
      this.showEditEmptyCase = true
    },
    showCreateForm(e) {
      e.preventDefault()
      this.silenceHidden = true
      this.showAddEmptyCase = true
    },
    showAndClearSilence() {
      this.shown = true
      this.silenceHidden = false
    },
    updateHiddenEvent() {
      if (!this.silenceHidden) {
        this.$emit('hidden')
      }
    },
    handleShown() {
      this.reset()
      this.loadEmptyCases(this.item)
    },
    loadEmptyCases(item) {
      if (item.id !== undefined) {
        if (this.hasPolid) {
          this.$store.dispatch('wineCard/emptyCases/getForWineCardAndPolid', {
            WineCardID: item.id,
            POLID: this.polid
          })
        } else {
          this.$store.dispatch('wineCard/emptyCases/getForWineCard', item.id)
        }
      }
    },
    confirmDeleteEmptyCase(emptyCase) {
      this.emptyCaseForDeletion = emptyCase
      // Confirmation disabled on old backend
      // this.showDeleteModal = true
      this.deleteEmptyCase()
    },
    async deleteEmptyCase() {
      try {
        const deletedCase = await this.$store.dispatch(
          'wineCard/emptyCases/removeEmptyCase',
          this.emptyCaseForDeletion
        )
        if (deletedCase) {
          const wineCard = await this.$store.dispatch(
            'wineCard/activeCard/reloadActiveCard'
          )
          this.commitToSearches('wineCards/setEmptyCasesForId', {
            WineCardID: wineCard.id,
            EmptyCaseInfo: wineCard.emptyCaseInfo
          })
        }
      } catch (e) {
        this.error = e
      }
    }
  }
}
</script>

<template>
  <div>
    <b-form-group
      :label-class="labelClass"
      class="mb-2"
      label-cols="2"
      label-size="sm"
      label="Liv-Ex LWin Code"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="code"
          :state="state"
          :class="inputClass"
          :disabled="disabled"
        />

        <b-input-group-append>
          <b-button
            size="sm"
            class="btn wv-btn mx-2"
            :disabled="buttonDisabled || !hasCode"
            @click="clear()"
          >
            Clear
          </b-button>
          <b-button
            size="sm"
            class="btn wv-btn"
            :disabled="buttonDisabled"
            @click="getLiveExCode"
          >
            Find
            <b-spinner v-if="loading" type="grow" small />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <lwin-lookup-modal v-model="showModal" @selected="handleSelected" />
    <confirm-promise ref="confirm">
      <template slot="buttonsBefore">
        <b-button size="sm" class="wv-btn" @click.prevent="$emit('new')">
          Create a new wine card
        </b-button>
      </template>
    </confirm-promise>
  </div>
</template>

<script>
import LwinLookupModal from '../Modal/LwinLookupModal'
import ConfirmPromise from '../Modal/ConfirmPromise'
export default {
  components: {
    LwinLookupModal,
    ConfirmPromise
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    state: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: [String, Object],
      default: null
    },
    inputClass: {
      type: [String, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: true
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    code: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    hasCode() {
      return !!this.value
    }
  },
  methods: {
    async getLiveExCode() {
      if (this.isEditing) {
        const wantToChange = await this.$refs.confirm.confirm(
          'Are you sure',
          'Are you sure you want to change the LWIN of an existing wine card?',
          false
        )
        if (wantToChange === false) {
          return
        }
      }
      this.showModal = true
    },
    handleSelected(item) {
      this.code = item.LWIN7
      this.$emit('selected', item)
    },
    clear() {
      this.code = null
    }
  }
}
</script>

import { setToken } from '../api/graphQLInstance'
import { setToken as setAxiosToken, setInstance } from '../api/axios'

let intervalId = null

export default function({ store, redirect, route }) {
  store.dispatch('user/loadTokenFromLocal')
  if (!store.state.user.authenticated && route.name !== 'index') {
    return redirect('/')
  } else {
    setToken(store.state.user.token) // Graph QL Token
    setInstance(store.$axios)
    setAxiosToken(store.state.user.token)
    if (intervalId === null) {
      intervalId = setInterval(async () => {
        const expiryOffset = -(
          process.env.refresh_before_expiry_mins *
          60 *
          1000
        )
        const now = new Date()
        const refreshDate = new Date(
          store.state.user.user.exp * 1000 + expiryOffset
        )
        if (now >= refreshDate) {
          try {
            const refreshed = await store.dispatch('user/refreshToken')
            if (refreshed === false) {
              store.commit('user/setAuthenticated', false)
              return redirect('/')
            }
          } catch (e) {
            store.commit('user/setAuthenticated', false)
            return redirect('/')
          }
        }
      }, 3600)
    }
  }
}

<template>
  <b-form class="d-flex justify-content-center align-items-center mb-2" @submit.prevent="search">
    <b-form-group
      v-if="wineCard"
      class="mx-2 mb-0"
      label-size="sm"
    >
      <h6 class="m-0">
        {{ title }}
      </h6>
    </b-form-group>
    <b-form-group
      v-else
      class="mx-2 mb-0"
      label-class="text-left"
      label-cols="5"
      label-size="sm"
      :label="label"
      label-for="contact_search"
    >
      <b-form-input id="contact_search" v-model="searchTerm" size="sm" />
    </b-form-group>
    <b-form-group
      v-show="showFilterStatus"
      class="mx-2 mb-0"
      label-class="text-left"
      label="Active"
      label-cols="4"
      label-size="sm"
    >
      <b-select v-model="filterStatus" :options="contactStatusOptions" :disabled="loadingContactStatusOptions" value-field="text" text-field="text" size="sm">
        <template v-slot:first>
          <option value="" />
        </template>
      </b-select>
    </b-form-group>
    <b-form-group
      v-show="showFilterType"
      class="mx-2 mb-0"
      label-class="text-left"
      label="Type"
      label-cols="4"
      label-size="sm"
    >
      <b-select v-model="filterType" size="sm">
        <template v-slot:first>
          <option value="" />
        </template>
        <option value="customers">
          Customers
        </option>
        <option value="suppliers">
          Suppliers
        </option>
        <option value="prospects">
          Prospects
        </option>
      </b-select>
    </b-form-group>
    <b-form-group
      v-show="showFilterProfile"
      class="mx-2 mb-0"
      label-class="text-left"
      label="Profile"
      label-cols="3"
      label-size="sm"
    >
      <b-select v-model="filterProfile" :options="promotionProfileOptions" :disabled="loadingPromotionProfileOptions" size="sm">
        <template v-slot:first>
          <option value="" />
        </template>
      </b-select>
    </b-form-group>
    <b-form-group
      class="mx-2 mb-0"
    >
      <b-button variant="primary" size="sm" class="btn wv-btn" @click="search">
        Search
      </b-button>
      <b-button class="btn wv-btn" size="sm" @click="$emit('clear')">
        Clear
      </b-button>
      <b-button v-if="showNewButton" variant="primary" size="sm" class="btn wv-btn" @click="handleNew">
        New
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { mapState } from 'vuex'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'

export default {
  mixins: [TitleFromWineCard],
  props: {
    showFilters: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectSearchStatus: null,
      selectSearchType: null,
      selectSearchProfile: null
    }
  },
  computed: {
    ...mapState('lists', [
      'contactStatusOptions',
      'loadingContactStatusOptions',
      'promotionProfileOptions',
      'loadingPromotionProfileOptions'
    ]),
    showFilterStatus() {
      return this.showFilters.status
    },
    showFilterTerm() {
      return this.showFilters.term
    },
    showFilterType() {
      return this.showFilters.type
    },
    showFilterProfile() {
      return this.showFilters.profile
    },
    showNewButton() {
      return this.showFilters.new
    },
    wineCard() {
      return this.filters.wineCard
    },
    item() {
      return this.wineCard
    },
    searchTerm: {
      get() {
        return this.filters.term
      },
      set(value) {
        this.$emit('update-filter', 'term', value)
      }
    },
    filterStatus: {
      get() {
        return this.filters.status
      },
      set(value) {
        this.$emit('update-filter', 'status', value)
      }
    },
    filterType: {
      get() {
        return this.filters.type
      },
      set(value) {
        this.$emit('update-filter', 'type', value)
      }
    },
    filterProfile: {
      get() {
        return this.filters.profile
      },
      set(value) {
        this.$emit('update-filter', 'profile', value)
      }
    }
  },
  methods: {
    search() {
      this.$emit('search')
    },
    handleNew() {
      this.$emit('new')
    }
  }
}
</script>

<style>
.qscf-form_dropdown-container .b-dropdown.dropdown {
  width: 100%;
}
</style>

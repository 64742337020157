<template>
  <group-box>
    <template slot="header">
      Inventory
    </template>
    <div v-if="purchasesLoading === true" class="text-center text-primary my-2">
      <b-spinner type="grow" label="Loading..." />
    </div>
    <vue-custom-scrollbar
      v-else
      v-show="hasResults"
      class="virtual-scroll scroll-offsets"
      :settings="settings"
    >
      <virtual-table
        :fields="tableFields"
        scroller-class=""
        :items="purchases"
        :per-page="9999"
        :total="total"
        :show-loading="true"
        :estimate-total-scroll-height="false"
        :table-tr-class="handleRowClass"
        primary-key="ID"
        @row-dblclicked="onRowDblclicked"
      >
        <template slot="Contact" slot-scope="data">
          <span class="cursor-pointer" @click="$emit('click-contact', data.item)">
            {{ data.item.Contact }}
          </span>
        </template>
        <template slot="RotationNumber" slot-scope="data">
          <span v-b-tooltip.hover class="cursor-pointer" :title="data.item.RotationNumber" @click="$emit('click-rotation-number', data.item)">
            {{ data.item.RotationNumber }}
          </span>
        </template>
        <template slot="AvailableTotalBottles" slot-scope="data">
          <stock-level :case-size="data.item.CaseSize" :bottles="bottles(data.item)" :cases="0" :justify="true" />
        </template>
        <template slot="PurchasePrice" slot-scope="data">
          <currency v-b-tooltip.hover.left :value="Number(purchasePrice(data.item))" :title="data.item.UsageCode === WineUsageCode.BRO ? 'Broking Price' : ''" :style="priceStyle(data.item)" />
        </template>
      </virtual-table>
    </vue-custom-scrollbar>
  </group-box>
</template>
<script>
import GroupBox from '~/components/Base/GroupBox'
import userMixin from '~/mixins/user'
import StockLevel from '~/components/Base/StockLevel'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'
import VirtualTable from '~/components/Base/VirtualTable'
import Currency from '~/components/Base/Currency'
import { WineUsageCode } from '~/constants/wineUsage'

export default {
  components: {
    StockLevel,
    GroupBox,
    VirtualTable,
    Currency,
    vueCustomScrollbar
  },
  mixins: [userMixin],
  props: {
    purchases: {
      type: Array,
      required: true
    },
    purchasesLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      settings: {
        tagname: 'div',
        suppressScrollY: true
      }
    }
  },
  computed: {
    hasResults() {
      return this.purchasesLoading === false && this.purchases.length > 0
    },
    tableFields() {
      return [
        {
          key: 'Contact',
          label: 'Contact',
          sortable: false
        },
        {
          key: 'RotationNumber',
          label: 'Rotation',
          sortable: false,
          width: '160px'
        },
        {
          key: 'AvailableTotalBottles',
          label: 'Bottles',
          sortable: false,
          width: '74px'
        },
        {
          key: 'PurchasePrice',
          label: 'Price',
          sortable: false,
          tdClass: 'text-right',
          width: '120px'
        }
      ]
    },
    total() {
      return this.purchases.length
    }
  },
  watch: {
    // STUB
  },
  created() {
    this.WineUsageCode = WineUsageCode
  },
  methods: {
    onRowDblclicked(item) {
      this.$emit('click-po', item)
    },
    handleRowClass(item) {
      let classStr = ''
      if (item.OriginalPackaging === 0) {
        classStr += 'burnt-orange '
      }
      if (item.UsageCode === WineUsageCode.BRO) {
        return classStr + 'row-bro'
      }
      if (item.PurchaseOrderTypeID === 2) {
        return classStr + 'row-reserve'
      }
      if (item.UsageCode === WineUsageCode.WFS) {
        classStr += ' row-success'
      }
      return classStr
    },
    showBroking(item) {
      return item.UsageCode === WineUsageCode.BRO && item.BrokingPrice
    },
    purchasePrice(item) {
      return this.showBroking(item)
        ? item.BrokingPrice
        : item.ReservePrice || item.PurchasePrice
    },
    priceStyle(item) {
      return this.showBroking(item)
        ? { fontWeight: 'bold', paddingLeft: '4px' }
        : {}
    },
    bottles(item) {
      if (WineUsageCode.ReserveCodes.includes(item.UsageCode)) {
        return item.AvailableTotalBottlesReserves
      }
      return item.AvailableTotalBottles
    }
  }
}
</script>

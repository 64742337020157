// import gql from 'graphql-tag'
import { client } from '../graphQLInstance'
import Appellation from '../../models/appellation'

export const getAppellations = async term => {
  const result = await client.query({
    query: Appellation.query('search', {
      SearchTerm: term
    })
  })
  const results = result.data[Appellation.queries.search.name]
  results.WineNameUsage =
    results.WineNameUsage === null ? 0 : results.WineNameUsage
  return results
}

export const addAppellation = async args => {
  const result = await client.query({
    query: Appellation.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Appellation.mutations.insert.name]
}

export const getAppellation = async args => {
  const result = await client.query({
    query: Appellation.query('get', {
      RecordId: args.AppellationID
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const results = result.data[Appellation.queries.get.name]
  results.WineNameUsage =
    results.WineNameUsage === null ? 0 : results.WineNameUsage
  return results
}

export const updateAppellation = async args => {
  const result = await client.query({
    query: Appellation.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Appellation.mutations.update.name]
}

export const deleteAppellation = async args => {
  const result = await client.query({
    query: Appellation.mutate('delete', {
      ID: args.AppellationID,
      AuditAmendedDate: args.AuditAmendedDate
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Appellation.mutations.delete.name]
}

<template>
  <div>
    <navbar-nav :main-items="mainItems" :user="user" />
  </div>
</template>

<script>
import NavbarNav from './NavbarNav.vue'

export default {
  components: {
    NavbarNav
  },
  props: {
    stickyTop: {
      type: Boolean,
      default: true
    },
    navItems: {
      type: Array,
      default() {
        return []
      }
    },
    mainItems: {
      type: Array,
      default() {
        return []
      }
    },
    user: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

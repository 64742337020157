<template>
  <b-modal
    v-model="shown"
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :hide-footer="true"
    size="xxl"
    @hidden="$emit('hidden')"
  >
    <template v-slot:modal-header>
      Wine Card Notes
    </template>
    <template v-slot:default>
      <note-search
        :loading="loading"
        @exit="shown=false"
        @search="searchNotes"
      />
      <note-table-extended
        :per-page="perPage"
        :total="total"
        :page="page"
        :loading="loading"
        :extended-table="true"
        @change-page="changePage"
        @edit="handleEditNote"
        @delete="handleDeleteNote"
        @order-by="handleOrderBy"
      />
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import NoteSearch from './WineNotes/NoteSearch'
import NoteTableExtended from './WineNotes/NoteTableExtended'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'

export default {
  components: {
    NoteTableExtended,
    NoteSearch
  },
  mixins: [TitleFromWineCard],
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('wineCard/notes', {
      loading: state => state.loading,
      notes: state => state.allNotes,
      perPage: state => state.perPage,
      page: state => state.page,
      total: state => state.total
    }),
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    changePage(page) {
      this.$store.dispatch('wineCard/notes/changePage', page)
    },
    searchNotes() {
      this.$store.dispatch('wineCard/notes/getAllNotes')
    },
    handleEditNote(note) {
      this.$store.commit(
        'wineCard/notes/setSelectedNoteId',
        note.WineCardNoteID
      )
      this.$store.commit('modal/setShowWineNotesEditModal', true)
    },
    handleOrderBy(orderBy) {
      const currentOrderBy = this.$store.state.wineCard.notes.orderBy
      const currentOrderByDir = this.$store.state.wineCard.notes
        .orderByDirection
      this.$store.commit('wineCard/notes/setOrderBy', orderBy.sortBy)
      this.$store.commit(
        'wineCard/notes/setOrderByDirection',
        currentOrderBy === orderBy.sortBy && currentOrderByDir !== 'DESC'
          ? 'DESC'
          : 'ASC'
      )
      this.searchNotes()
    },
    async handleDeleteNote(note) {
      try {
        await this.$store.dispatch('wineCard/notes/removeNote', note)
        this.$eventHub.$emit('delete-note', note)
      } catch (e) {
        this.$notifyError(e)
      }
    }
  }
}
</script>

import {
  getWarehouseAccountByContactId,
  updateWarehouseAccount
} from '@/api/contacts/warehouseaccounts'

export const state = () => ({
  list: [],
  loading: false
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setList(state, list) {
    state.list = list
  }
}

export const actions = {
  async loadList({ commit }, contactId) {
    commit('setLoading', true)
    try {
      const response = await getWarehouseAccountByContactId(contactId)
      if (response) {
        commit('setList', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async updateAccount({ commit, dispatch }, args) {
    commit('setLoading', true)
    try {
      const response = await updateWarehouseAccount(args)
      if (response) {
        await dispatch('loadList', args.ContactID)
        return response
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

import {
  addParameter,
  getParameters,
  editParameter,
  deleteParameter
} from '@/api/administration/photoparameters'
import { findIndex } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  parameters: []
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  appendParameter(state, params) {
    if (params.PhotoParameterID) {
      const idx = findIndex(state.parameters, {
        PhotoParameterID: params.PhotoParameterID
      })
      if (idx >= 0) {
        Vue.set(state.parameters, idx, params)
      } else {
        state.parameters.push(params)
      }
    } else {
      state.parameters.push(params)
    }
  },
  removeParameter(state, PhotoParameterID) {
    const idx = findIndex(state.parameters, {
      PhotoParameterID
    })
    if (idx >= 0) {
      Vue.delete(state.parameters, idx)
    }
  },
  setParameters(state, params) {
    state.parameters = params
  }
}

export const actions = {
  async addParameter({ commit }, data) {
    commit('setLoading', true)
    try {
      const response = await addParameter(data)
      if (response) {
        commit('appendParameter', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async editParameter({ commit }, data) {
    commit('setLoading', true)
    try {
      const response = await editParameter(data)
      if (response) {
        commit('appendParameter', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async deleteParameter({ commit }, { PhotoParameterID }) {
    commit('setLoading', true)
    try {
      const response = await deleteParameter({ PhotoParameterID })
      if (response) {
        commit('removeParameter', PhotoParameterID)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getParameters({ commit }) {
    commit('setLoading', true)
    try {
      const response = await getParameters()
      if (response) {
        commit('setParameters', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

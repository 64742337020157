<template>
  <current-stock-modal
    v-if="activeCard"
    v-model="showCurrentStockModal"
    :item="activeCard"
    :loading="stockLoading"
    :stock-items="stockItems"
  />
</template>

<script>
import CurrentStockModal from '~/components/Modal/CurrentStock'

export default {
  components: {
    CurrentStockModal
  },
  computed: {
    showCurrentStockModal: {
      get() {
        return this.$store.state.modal.showCurrentStockModal
      },
      set(value) {
        this.$store.commit('modal/setShowCurrentStockModal', value)
      }
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeBrowserCard
    },
    stockLoading() {
      return this.$store.state.wineCard.activeCard.stockLoading
    },
    stockItems() {
      return this.$store.state.wineCard.activeCard.stockMargins
    }
  },
  watch: {
    showCurrentStockModal(shown) {
      if (shown) {
        this.$store.dispatch('wineCard/activeCard/getStockMargins', {
          WineCardID: this.activeCard.id
        })
      }
    }
  }
}
</script>

import Vue from 'vue'
import { find, cloneDeep } from 'lodash'
import {
  createTallyCard,
  createTallyCardFromWineCard,
  createTallyCardFromNoWineCard,
  getTallyCard,
  updateTallyCard,
  updateTallyLine,
  updateTallyGroup,
  updateRotations,
  deleteTallyLine,
  manuallySetRotation,
  receiveAll,
  clearRotations,
  sendPreAdvice,
  deleteTally,
  returnTallyLine
} from '../../api/etally'

export const state = () => ({
  importMode: 'webpo',
  statuses: [
    {
      value: 1,
      text: 'Draft'
    },
    {
      value: 2,
      text: 'Ready For Checking'
    },
    {
      value: 3,
      text: 'Checked/Finalised'
    }
  ],
  loading: false,
  cards: {},
  manualRotationsAllSetStatus: {}
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  appendCard(state, card) {
    Vue.set(state.cards, card.ETCID, card)
  },
  deleteCard(state, card) {
    Vue.delete(state.cards, card.ETCID)
  },
  setRotationManualAll(state, { card, enabled }) {
    for (let i = 0; i < card.Groups.length; i++) {
      for (let ii = 0; ii < card.Groups[i].Lines.length; ii++) {
        const currentLine = card.Groups[i].Lines[ii]
        if (
          (currentLine.PreviousRotationNumberManuallySet === false ||
            currentLine.PreviousRotationNumberManuallySet === null ||
            currentLine.PreviousRotationNumberManuallySet === undefined) &&
          (currentLine.RotationNumber === null ||
            currentLine.RotationNumber.length === 0)
        ) {
          card.Groups[i].Lines[ii].PreviousRotationNumberManuallySet =
            card.Groups[i].Lines[ii].RotationNumberManuallySet
          card.Groups[i].Lines[ii].RotationNumberManuallySet = enabled
        }
      }
    }
    Vue.set(state.manualRotationsAllSetStatus, card.ETCID, enabled)
    Vue.set(state.cards, card.ETCID, card)
  },
  setRotationManual(state, { card, line }) {
    for (let i = 0; i < card.Groups.length; i++) {
      for (let ii = 0; ii < card.Groups[i].Lines.length; ii++) {
        const currentLine = card.Groups[i].Lines[ii]
        if (currentLine.ETCLID === line.ETCLID) {
          card.Groups[i].Lines[ii].PreviousRotationNumberManuallySet =
            card.Groups[i].Lines[ii].RotationNumberManuallySet
          card.Groups[i].Lines[ii].RotationNumberManuallySet = true
        }
      }
    }
    Vue.set(state.cards, card.ETCID, card)
  },
  updateLine(state, line) {
    const card = find(state.cards, card => {
      for (let i = 0; i < card.Groups.length; i++) {
        for (let ii = 0; ii < card.Groups[i].Lines.length; ii++) {
          const currentLine = card.Groups[i].Lines[ii]
          if (currentLine.ETCLID === line.ETCLID) {
            return true
          }
        }
      }
      return false
    })
    if (card !== undefined) {
      for (let i = 0; i < card.Groups.length; i++) {
        for (let ii = 0; ii < card.Groups[i].Lines.length; ii++) {
          const currentLine = card.Groups[i].Lines[ii]
          if (currentLine.ETCLID === line.ETCLID) {
            Vue.set(card.Groups[i].Lines, ii, line)
            Vue.set(state.cards, card.ETCID, cloneDeep(card))
            return
          }
        }
      }
    }
  },
  setImageCount(state, { ETCLID, ImageCount, card }) {
    for (let i = 0; i < card.Groups.length; i++) {
      for (let ii = 0; ii < card.Groups[i].Lines.length; ii++) {
        const currentLine = card.Groups[i].Lines[ii]
        if (currentLine.ETCLID === ETCLID) {
          card.Groups[i].Lines[ii].ImageCount = ImageCount
        }
      }
    }
    Vue.set(state.cards, card.ETCID, card)
  },
  unsetRotationManual(state, { card, line }) {
    for (let i = 0; i < card.Groups.length; i++) {
      for (let ii = 0; ii < card.Groups[i].Lines.length; ii++) {
        const currentLine = card.Groups[i].Lines[ii]
        if (currentLine.ETCLID === line.ETCLID) {
          if (
            card.Groups[i].Lines[ii].PreviousRotationNumberManuallySet !==
            undefined
          ) {
            card.Groups[i].Lines[ii].RotationNumberManuallySet =
              card.Groups[i].Lines[ii].PreviousRotationNumberManuallySet
          }
        }
      }
    }
    Vue.set(state.cards, card.ETCID, card)
  },
  setRotationNumber(state, { card, line, rotationNumber }) {
    for (let i = 0; i < card.Groups.length; i++) {
      for (let ii = 0; ii < card.Groups[i].Lines.length; ii++) {
        const currentLine = card.Groups[i].Lines[ii]
        if (currentLine.ETCLID === line.ETCLID) {
          card.Groups[i].Lines[ii].RotationNumber = rotationNumber
        }
      }
    }
    Vue.set(state.cards, card.ETCID, card)
  }
}

export const actions = {
  async deleteTally({ commit }, card) {
    commit('setLoading', true)
    try {
      await deleteTally(card.ETCID)
      commit('deleteCard', card)
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async create({ commit }, data) {
    commit('setLoading', true)
    try {
      const card = await createTallyCard(data)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async createFromWineCard({ commit }, data) {
    commit('setLoading', true)
    try {
      const card = await createTallyCardFromWineCard(data)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async createFromNoWineCard({ commit }, data) {
    commit('setLoading', true)
    try {
      const card = await createTallyCardFromNoWineCard(data)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getCard({ commit }, id) {
    commit('setLoading', true)
    try {
      const card = await getTallyCard(id)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async update({ commit }, fields) {
    commit('setLoading', true)
    try {
      const card = await updateTallyCard(fields)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async updateLine({ commit }, fields) {
    commit('setLoading', true)
    try {
      const card = await updateTallyLine(fields)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async updateGroup({ commit }, fields) {
    commit('setLoading', true)
    try {
      const card = await updateTallyGroup(fields)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async deleteLine({ commit }, data) {
    commit('setLoading', true)
    try {
      const card = await deleteTallyLine(data)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async returnLine({ commit }, data) {
    commit('setLoading', true)
    try {
      const line = await returnTallyLine(data)
      if (line) {
        commit('updateLine', line)
      }
      return line
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async receiveAll({ commit }, { ETCID }) {
    commit('setLoading', true)
    try {
      const card = await receiveAll({ ETCID })
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async clearRotations({ commit }, fields) {
    commit('setLoading', true)
    try {
      const card = await clearRotations(fields)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async updateRotations({ commit }, fields) {
    commit('setLoading', true)
    try {
      const card = await updateRotations(fields)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async manuallySetRotation({ commit, state }, fields) {
    commit('setLoading', true)
    try {
      const card = await manuallySetRotation(fields)
      if (card) {
        commit('appendCard', card)
      }
      if (state.manualRotationsAllSetStatus[card.ETCID] === true) {
        commit('setRotationManualAll', { card, enabled: true })
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async sendAdvice({ commit }, { ETCID }) {
    commit('setLoading', true)
    try {
      const card = await sendPreAdvice(ETCID)
      if (card) {
        commit('appendCard', card)
      }
      return card
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

import billingRunContact from '~/models/storage/billingRunContact'
import { client } from '~/api/graphQLInstance'

export const getBillingRunContacts = async params => {
  // console.log('getBillingRunContacts', params)
  const result = await client.query({
    query: billingRunContact.query('getAll', params)
  })
  // console.log('results', result, billingRunContact.queries.getAll.name)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[billingRunContact.queries.getAll.name]
    .billingRunContactPaginatedResults
}

import { client } from '../graphQLInstance'
import Favourites from '../../models/favourites'

export const getUserList = async id => {
  const result = await client.query({
    query: Favourites.query('get', {
      WineCardID: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Favourites.queries.get.name]
}

export const getContactList = async id => {
  const result = await client.query({
    query: Favourites.query('contactGet', {
      ContactID: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Favourites.queries.contactGet.name]
}

export const sendEmail = async ({ id, contacts }) => {
  const result = await client.query({
    query: Favourites.mutate('sendEmail', {
      WineCardID: id,
      Contacts: contacts
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Favourites.mutations.sendEmail.name]
}

import Vue from 'vue'
import {
  getSavedChanges,
  saveChanges,
  removeSavedChanges,
  saveOpenOrders,
  getOpenOrders
} from '@/api/web/openPurchaseOrders'
import { each } from 'lodash'

export const getters = {
  getChangedDetails: state => ({ WPOID }) => {
    return state.changedDetails[WPOID]
  },
  hasChangedDetails: state => ({ WPOID }) => {
    return Object.keys(state.changedDetails).indexOf('' + WPOID) >= 0
  }
}

export const state = () => ({
  purchaseOrders: {},
  changedDetails: {}
})

export const mutations = {
  appendPurchaseOrder(state, { WPOID, WebReference }) {
    Vue.set(state.purchaseOrders, WPOID, { WPOID, WebReference })
  },
  removePurchaseOrder(state, { WPOID, WebReference }) {
    Vue.delete(state.purchaseOrders, WPOID, { WPOID, WebReference })
  },
  setChangedDetails(state, { WPOID, changedDetails }) {
    Vue.set(state.changedDetails, WPOID, changedDetails)
  },
  removeChangedDetails(state, { WPOID }) {
    Vue.delete(state.changedDetails, WPOID)
  }
}

export const actions = {
  reloadOpenPo({ commit, dispatch }) {
    each(getOpenOrders(), po => {
      commit('appendPurchaseOrder', po)
      dispatch('getChangedDetails', po.WPOID)
    })
  },
  appendPurchaseOrder({ commit, state }, { WPOID, WebReference }) {
    commit('appendPurchaseOrder', { WPOID, WebReference })
    saveOpenOrders(state.purchaseOrders)
  },
  removePurchaseOrder({ commit, state }, { WPOID }) {
    commit('removePurchaseOrder', { WPOID })
    commit('removeChangedDetails', { WPOID })
    saveOpenOrders(state.purchaseOrders)
    removeSavedChanges(WPOID)
  },
  getChangedDetails({ commit }, WPOID) {
    const changedDetails = getSavedChanges(WPOID)
    if (changedDetails) {
      commit('setChangedDetails', {
        WPOID,
        changedDetails
      })
    }
  },
  setChangedDetails({ commit }, { WPOID, changedDetails }) {
    commit('setChangedDetails', { WPOID, changedDetails })
    saveChanges(WPOID, changedDetails)
  },
  removeChangedDetails({ commit }, { WPOID }) {
    commit('removeChangedDetails', { WPOID })
    removeSavedChanges(WPOID)
  }
}

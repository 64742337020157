import {
  getPromotionProfiles,
  getPromotionProfile,
  updatePromotionProfile,
  deletePromotionProfile,
  addPromotionProfile
} from '@/api/administration/promotionprofiles'
import { getTextTemplateAll, updateTextTemplate } from '@/api/email'
import {
  without,
  find,
  mapKeys,
  mapValues,
  camelCase,
  keys,
  map,
  merge,
  each
} from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  profileLoading: false,
  list: [],
  activeProfile: {},
  templates: {},
  defaultTemplates: {},
  templateKeyMap: {},
  templatesLoading: false
})

export const getters = {
  getTextTemplateCode: state => key => {
    return state.templateKeyMap[key].TextTemplateCode
  }
}

export const mutations = {
  setList(state, list) {
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setProfileLoading(state, loading) {
    state.profileLoading = loading === true
  },
  setActiveProfile(state, profile) {
    state.activeProfile = profile
  },
  removeProfileFromListByCode(state, PromotionProfileCode) {
    state.list = without(state.list, find(state.list, { PromotionProfileCode }))
  },
  setTemplates(state, templates) {
    const kTemplates = mapKeys(templates, t => camelCase(t.TextTemplateCode))
    state.templates = mapValues(kTemplates, 'Text')
    state.defaultTemplates = mapValues(kTemplates, 'DefaultText')
    state.templateKeyMap = kTemplates
  },
  setTemplate(state, template) {
    const kTemplate = camelCase(template.TextTemplateCode)
    Vue.set(state.templates, kTemplate, template.Text)
    Vue.set(state.defaultTemplates, kTemplate, template.DefaultText)
  },
  setTemplatesLoading(state, loading) {
    state.templatesLoading = loading === true
  }
}

export const actions = {
  async loadList({ commit }) {
    commit('setLoading', true)
    try {
      const response = await getPromotionProfiles()
      if (response) {
        commit('setList', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadActiveProfile({ commit }, code) {
    commit('setProfileLoading', true)
    try {
      const response = await getPromotionProfile(code)
      if (response) {
        commit('setActiveProfile', response)
        return response
      }
    } catch (e) {
      throw e
    } finally {
      commit('setProfileLoading', false)
    }
  },
  async updateProfile({ commit, dispatch }, profile) {
    commit('setProfileLoading', true)
    try {
      return await updatePromotionProfile(profile)
    } catch (e) {
      throw e
    } finally {
      commit('setProfileLoading', false)
    }
  },
  async addProfile({ commit, dispatch }, profile) {
    commit('setProfileLoading', true)
    try {
      return await addPromotionProfile(profile)
    } catch (e) {
      throw e
    } finally {
      commit('setProfileLoading', false)
    }
  },
  async deleteProfile({ commit, dispatch }, profile) {
    commit('setProfileLoading', true)
    try {
      await deletePromotionProfile(profile)
      commit('setActiveProfile', {})
      commit('removeProfileFromListByCode', profile.PromotionProfileCode)
    } catch (e) {
      throw e
    } finally {
      commit('setProfileLoading', false)
    }
  },
  async getTemplates({ commit }) {
    commit('setTemplatesLoading', true)
    try {
      const templates = await getTextTemplateAll()
      commit('setTemplates', templates)
    } catch (e) {
      throw e
    } finally {
      commit('setTemplatesLoading', false)
    }
  },
  async saveTemplates({ commit, state }, templates) {
    commit('setTemplatesLoading', true)
    try {
      const tKeys = keys(templates)
      const requests = map(tKeys, k => {
        const data = merge({}, state.templateKeyMap[k], { Text: templates[k] })
        return updateTextTemplate(data)
      })
      const responses = await Promise.all(requests)
      each(responses, r => commit('setTemplate', r))
    } catch (e) {
      throw e
    } finally {
      commit('setTemplatesLoading', false)
    }
  }
}

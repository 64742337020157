<template>
  <group-box :header="true" class="h-100">
    <template slot="header">
      File
    </template>
    <b-row>
      <b-col>
        <d-form-radio v-model="exportFile" value="pdf" :disabled="disabled" class="text-left">
          PDF
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <d-form-radio v-model="exportFile" value="xlsx" :disabled="disabled" class="text-left">
          Excel
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <d-form-radio v-model="exportFile" value="csv" :disabled="disabled" class="text-left mb-0">
          CSV
        </d-form-radio>
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'

export default {
  components: {
    GroupBox
  },
  props: {
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    preview: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    exportFile: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    changePreview: {
      get() {
        return this.preview
      },
      set(value) {
        this.$emit('change-preview', value)
      }
    }
  }
}
</script>

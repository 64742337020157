import { client } from '../graphQLInstance'
import WineName from '../../models/winename'

export const getWineNames = async term => {
  const result = await client.query({
    query: WineName.query('search', {
      SearchTerm: term
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineName.queries.search.name]
}

export const addWineName = async args => {
  const result = await client.query({
    query: WineName.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineName.mutations.insert.name]
}

export const getWineName = async args => {
  const result = await client.query({
    query: WineName.query('get', {
      RecordId: args.WineNameID
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const results = result.data[WineName.queries.get.name]
  WineName.triggerEvent('post-query', results)
  return results
}

export const updateWineName = async args => {
  const result = await client.query({
    query: WineName.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineName.mutations.update.name]
}

export const deleteWineName = async args => {
  const result = await client.query({
    query: WineName.mutate('delete', {
      ID: args.WineNameID,
      AuditAmendedDate: args.AuditAmendedDate
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineName.mutations.delete.name]
}

import contactsData from '../../data/contacts.json'
import { client } from '../graphQLInstance'
import Contact from '../../models/contact'

export const getContactsList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(contactsData)
    }, 800)
  })
}

export const searchContact = async args => {
  const result = await client.query({
    query: Contact.query('search', {
      SearchTerm: args.SearchTerm,
      Active: args.Active,
      SupplierWineCardId: args.SupplierWineCardId,
      CustomerWineCardId: args.CustomerWineCardId,
      SearchType: args.SearchType,
      PromotionProfileCode: args.PromotionProfileCode,
      PurchasesCount: args.PurchasesCount,
      PurchasesTotalValue: args.PurchasesTotalValue,
      SalesCount: args.SalesCount,
      SalesTotal: args.SalesTotal,
      Status: args.Status
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.queries.search.name]
}

export const getContactById = async id => {
  const result = await client.query({
    query: Contact.query('get', {
      RecordId: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.queries.get.name]
}

export const deleteContactById = async id => {
  const result = await client.query({
    query: Contact.mutate('delete', {
      ID: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.mutations.delete.name]
}

export const addContact = async args => {
  const result = await client.query({
    query: Contact.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.mutations.insert.name]
}

export const updateContact = async args => {
  const result = await client.query({
    query: Contact.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.mutations.update.name]
}

export const getNextSageAccountNumber = async args => {
  const result = await client.query({
    query: Contact.query('sageAccountNumberGetNext', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.queries.sageAccountNumberGetNext.name]
}

export const createWebContactFromContact = async args => {
  const result = await client.query({
    query: Contact.mutate('createWebContactFromContact', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.mutations.createWebContactFromContact.name]
}

export const getContactDocumentsGet = async ContactID => {
  const result = await client.query({
    query: Contact.query('contactDocumentsGet', {
      ContactID: parseInt(ContactID)
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.queries.contactDocumentsGet.name]
}

export const deleteContactDocument = async args => {
  const result = await client.query({
    query: Contact.mutate('deleteDocument', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.mutations.deleteDocument.name]
}

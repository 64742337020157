import gql from 'graphql-tag'
import { client } from '../graphQLInstance'
import PurchaseOrder from '~/models/purchaseorder'
import PurchaseOrderLine from '~/models/purchaseorderline'
import PurchaseOrderTransaction from '~/models/purchaseordertransaction'
import PurchaseOrderAllocation from '~/models/purchaseorderallocation'

export const getTransactionLines = async poid => {
  const result = await client.query({
    query: PurchaseOrderTransaction.query('get', { RecordId: parseInt(poid) })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrderTransaction.queries.get.name]
}

export const getPurchaseOrder = async poid => {
  const result = await client.query({
    query: PurchaseOrder.query('get', { RecordId: parseInt(poid) })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrder.queries.get.name]
}

export const updatePurchaseOrder = async args => {
  const result = await client.query({
    query: PurchaseOrder.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrder.mutations.update.name]
}

export const createPurchaseOrder = async args => {
  const result = await client.query({
    query: PurchaseOrder.mutate('create', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrder.mutations.create.name]
}

export const deletePurchaseOrder = async args => {
  const result = await client.query({
    query: PurchaseOrder.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrder.mutations.delete.name]
}

export const updateSage = async args => {
  const result = await client.query({
    query: PurchaseOrder.mutate('updateSage', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrder.mutations.updateSage.name]
}

export const createPurchaseOrderWithTransaction = async args => {
  const result = await client.query({
    query: PurchaseOrder.mutate('createTransaction', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrder.mutations.createTransaction.name]
}

export const addPurchaseOrderLine = async args => {
  const result = await client.query({
    query: PurchaseOrderLine.mutate('add', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrderLine.mutations.add.name]
}

export const updatePurchaseOrderLine = async args => {
  const result = await client.query({
    query: PurchaseOrderLine.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrderLine.mutations.update.name]
}

export const updatePurchaseOrderLines = async args => {
  const result = await client.query({
    query: PurchaseOrderTransaction.mutate('updateAll', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrderTransaction.mutations.updateAll.name]
}

export const mergePurchaseOrderLines = async args => {
  const result = await client.query({
    query: PurchaseOrderTransaction.mutate('merge', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrderTransaction.mutations.merge.name]
}

export const deletePurchaseOrderLine = async ({ POLID, AuditAmendedDate }) => {
  const result = await client.query({
    query: PurchaseOrderLine.mutate('delete', { POLID, AuditAmendedDate })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrderLine.mutations.delete.name]
}

export const getAllocationsForPurchaseOrderLine = async POLID => {
  const result = await client.query({
    query: PurchaseOrderAllocation.query('getAll', {
      RecordId: parseInt(POLID)
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrderAllocation.queries.getAll.name]
}

export const getAllocatedStockOnOrderSales = async POLID => {
  // prettier-ignore
  const result = await client.query({
    query: gql`
      query RootQueryType {
        allocatedStockOnOrderSales(POLID: ${POLID}){
          POID,
          POLID,
          SOID,
          SOLID,
          CompanyName,
          AllocatedBottles,
          WineCardID,
          CaseSize,
          Oversell,
      }
    }
    `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.allocatedStockOnOrderSales
}

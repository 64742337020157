import Entity from './entity'

const fields = {
  PhotoParameterID: {
    type: Number
  },
  AuditCreatedBy: {
    type: String
  },
  AuditAmendedBy: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  PhotoParameterName: {
    type: String
  },
  MaxPhotoYear: {
    type: Number
  },
  ListPrice: {
    type: Number
  },
  CategoryID: {
    type: Number
  },
  CountryID: {
    type: Number
  },
  CountryRegionID: {
    type: Number
  },
  CountrySubRegionID: {
    type: Number
  },
  WineColourID: {
    type: Number
  },
  WineNameWords: {
    type: String
  },
  WineNameWordMatchMode: {
    type: Number
  },
  ProducerMatchMode: {
    type: Number
  },
  Order: {
    type: Number
  },
  CategoryName: {
    type: String
  },
  CountryName: {
    type: String
  },
  CountryRegionName: {
    type: String
  },
  CountrySubRegionName: {
    type: String
  },
  WineColourName: {
    type: String
  },
  Producers: {
    type: Array,
    fields: {
      ProducerName: {
        type: String
      },
      ProducerID: {
        type: Number
      }
    }
  }
}

const PhotoParameter = new Entity('PhotoParameter', fields)

PhotoParameter.createMutation('insert', 'photoParameterAdd', fields)
PhotoParameter.createMutation('edit', 'photoParameterEdit', fields)
PhotoParameter.createMutation(
  'delete',
  'photoParameterDelete',
  Entity.onlyKeys(fields, ['PhotoParameterID']),
  []
)

PhotoParameter.createQuery('get', 'photoParametersGet', fields)

export default PhotoParameter

import Entity from './entity'

const fields = {
  VineyardID: {
    type: Number
  },
  VineyardName: {
    type: String
  },
  VineyardOrder: {
    type: Number
  },
  VineyardCat: {
    type: Number
  },
  VineyardCode: {
    type: String
  }
}

const Vineyard = new Entity('Vineyard', fields)
Vineyard.createQuery('get', 'vineyardsGet')

Vineyard.createMutation(
  'delete',
  'vineyardDelete',
  {
    VineyardID: fields.VineyardID
  },
  []
)
Vineyard.createMutation('insert', 'vineyardAdd', fields)
Vineyard.createMutation('update', 'vineyardUpdate', fields)

export default Vineyard

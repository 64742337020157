<template>
  <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
    <group-box v-if="loading || bordeauxIndex.length === 0" style="grid-column: span 3;">
      <template slot="header">
        Bordeaux Index
        <b-spinner v-if="loading" type="grow" small />
      </template>
      <div v-if="loading" class="text-center text-primary my-2">
        <b-spinner variant="primary" type="grow" label="Spinning" />
      </div>
      <template v-else-if="bordeauxIndex.length === 0">
        <p class="text-center" style="font-weight: bold; font-size: .8rem; line-height: .8rem; margin-bottom: 2px;">No Results Found</p>
      </template>
    </group-box>
    <template v-if="!loading">
      <group-box v-for="(item) in bordeauxIndex" :key="item.format" class="bordeaux-index">
        <template slot="header">
          Bordeaux Index - {{ item.format }}
        </template>
        <b-row>
          <b-col class="col-12 col-xxl-9">
            <b-row>
              <b-col class="col-12 col-xxl-6">
                <template v-if="item.bidQuantity">
                  <b-form-group
                    v-b-tooltip.hover.left
                    label="B"
                    label-for="bidPrice"
                    label-size="xs"
                    label-cols-xxl="2"
                    class="mb-0"
                    :title="'Best Bid Price: '+ formatCurrency(item.bidPrice)"
                  >
                    <b-form-input
                      id="bidPrice"
                      :value="formatMoney(item.bidPrice)"
                      size="xs"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                  <b-form-group
                    v-b-tooltip.hover.left
                    label=""
                    label-for="bidPrice4"
                    label-size="sm"
                    label-cols-xxl="2"
                    class="mb-0 mt-1"
                    :title="'Best Bid Price -4%: '+ formatCurrency(item.bidPrice * .96)"
                  >
                    <b-form-input
                      id="bidPrice4"
                      :value="formatMoney(item.bidPrice * .96)"
                      size="sm"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.bidQuantity"
                    label="Qty"
                    label-for="bidQuantity"
                    label-size="xs"
                    label-cols-xxl="6"
                    class="mb-0 mt-1"
                  >
                    <b-form-input
                      id="bidQuantity"
                      :value="formatQuantity(item.bidQuantity)"
                      size="xs"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                </template>
                <template v-else>
                  <p class="text-center" style="font-size: .8rem; line-height: 2rem; margin-bottom: 0px; font-weight: bold;">No Bids</p>
                </template>
              </b-col>
              <b-col class="col-12 col-xxl-6">
                <template v-if="item.offerQuantity">
                  <b-form-group
                    v-b-tooltip.hover.right
                    label="O"
                    label-for="offerPrice"
                    label-size="xs"
                    label-cols-xxl="2"
                    class="mb-0"
                    :title="'Best Offer Price: '+ formatCurrency(item.offerPrice)"
                  >
                    <b-form-input
                      id="offerPrice"
                      :value="formatMoney(item.offerPrice)"
                      size="sm"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.offerQuantity"
                    label="Qty"
                    label-for="offerQuantity"
                    label-size="xs"
                    label-cols-xxl="6"
                    class="mb-0 mt-1"
                  >
                    <b-form-input
                      id="offerQuantity"
                      :value="formatQuantity(item.offerQuantity)"
                      size="xs"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                </template>
                <template v-else>
                  <p class="text-center" style="font-size: .8rem; line-height: 2rem; margin-bottom: 0px; font-weight:bold;">No Offers</p>
                </template>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="col-12 col-xxl-3" style="padding-left: 0">
            <b-form-group
              v-if="markup(item)"
              v-b-tooltip.hover.right
              label=""
              label-for="markupPrice"
              label-size="sm"
              label-cols-xxl="0"
              class="mb-0"
              :title="'Markup: '+ formatMarkup(item)"
            >
              <b-form-input
                id="markupPrice"
              
                :value="formatMarkup(item)"
                size="sm"
                disabled
                class="text-right font-weight-bold"
                :class="comparisonClass(markup(item))"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </group-box>
    </template>
  </div>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import { mapState } from 'vuex'
export default {
  components: {
    GroupBox
  },
  computed: {
    ...mapState('wineCard/activeCard', {
      bordeauxIndex: state => state.bordeauxIndex,
      loading: state => state.bordeauxIndexLoading,
      error: state => state.bordeauxIndexError
    }),
    markup() {
      return function(item) {
        if (item.bidPrice > 0 && item.offerPrice > 0) {
          return Number(
            ((item.offerPrice - item.bidPrice) * 100) / item.bidPrice
          ).toFixed(1)
        } else {
          return null
        }
      }
    },
    formatMarkup() {
      return function(item) {
        if (this.markup(item) !== null) {
          return this.markup(item) + '%'
        } else {
          return 'N/A'
        }
      }
    },
    formatMoney() {
      return function(item) {
        return item > 0
          ? this.$options.filters.withCommas(
              this.$options.filters.round(item, 0),
              0
            )
          : '-'
      }
    },
    formatCurrency() {
      return function(item) {
        return item > 0
          ? this.$options.filters.currency(this.$options.filters.round(item, 0))
          : 'N/A'
      }
    },
    formatQuantity() {
      return function(item) {
        return item > 0 ? item : ''
      }
    },
    comparisonClass(item) {
      return function(item) {
        return {
          green: item > 0,
          red: item < 0
        }
      }
    }
  },
  mounted() {
    this.$emit('get-bordeaux-index')
  }
}
</script>

import { client } from '../graphQLInstance'
import SellerNameMatch from '../../models/sellernamematch'

export const getWineSearchers = async () => {
  const result = await client.query({
    query: SellerNameMatch.query('getAll', {})
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SellerNameMatch.queries.getAll.name]
}

export const addWineSearcher = async name => {
  const result = await client.query({
    query: SellerNameMatch.mutate('insert', {
      SellerNameMatch: name
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SellerNameMatch.mutations.insert.name]
}

export const deleteWineSearcher = async name => {
  const result = await client.query({
    query: SellerNameMatch.mutate('delete', {
      SellerNameMatch: name
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SellerNameMatch.mutations.delete.name]
}

import Entity from './entity'

const fields = {
  WarehouseAddrID: {
    type: Number
  },
  WarehouseName: {
    type: String
  },
  Addr1: {
    type: String
  },
  Addr2: {
    type: String
  },
  Addr3: {
    type: String
  },
  Addr4: {
    type: String
  },
  Postcode: {
    type: String
  },
  CountryCode: {
    type: String
  },
  ForAttentionOf: {
    type: String
  },
  Telephone: {
    type: String
  },
  DeliveryCompany: {
    type: String
  },
  Orders: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  }
}

const WarehouseAddress = new Entity('WarehouseAddress', fields)

WarehouseAddress.createQuery('search', 'warehouseAddressSearch', fields)
WarehouseAddress.createQuery('get', 'warehouseAddressGet', fields)

WarehouseAddress.createMutation(
  'insert',
  'warehouseAddressAdd',
  Entity.allButKeys(fields, ['Orders', 'AuditAmendedDate']),
  fields
)

WarehouseAddress.createMutation(
  'update',
  'warehouseAddressUpdate',
  Entity.allButKeys(fields, ['Orders']),
  fields
)

WarehouseAddress.createMutation(
  'delete',
  'warehouseAddressDelete',
  {
    ID: {
      type: Number
    },
    AuditAmendedDate: fields.AuditAmendedDate
  },
  []
)

export default WarehouseAddress

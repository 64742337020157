import BrokingWineCard from '~/models/broking/brokingWineCard'
import { client } from '~/api/graphQLInstance'

export const getBrokingWineCards = async params => {
  const result = await client.query({
    query: BrokingWineCard.query('getAll', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BrokingWineCard.queries.getAll.name]
    .brokingWineCardsPaginatedResults
}

export const updateBrokingPrice = async args => {
  const result = await client.query({
    query: BrokingWineCard.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BrokingWineCard.mutations.update.name]
}

import { sendEmail, updateTextTemplate } from '~/api/email'
import { merge } from 'lodash'
export const state = () => ({
  contactCanChange: false,
  selectedContact: null,
  emailApiArgs: null,
  options: {
    To: '',
    ToName: '',
    Sender: '',
    SenderName: '',
    Body: '',
    Subject: '',
    CopyToSelf: false,
    AttachPDF: false,
    AttachCSV: false,
    AttachXLS: false,
    CustomTemplate: '',
    Salutation: '',
    ToSelf: false
  },
  emailContent: null,
  loadingEmailContent: false,
  savingTextTemplate: false
})
export const getters = {}
export const mutations = {
  setContact(state, contact) {
    state.selectedContact = contact
  },
  setContactCanChange(state, canChange) {
    state.contactCanChange = !!canChange
  },
  emailApiArgs(state, args) {
    state.emailApiArgs = args
  },
  setTo(state, to) {
    state.options.To = to
  },
  setToName(state, toName) {
    state.options.ToName = toName
  },
  setSender(state, sender) {
    state.options.Sender = sender
  },
  setSenderName(state, senderName) {
    state.options.SenderName = senderName
  },
  setBody(state, body) {
    state.options.Body = body
  },
  setSubject(state, subject) {
    state.options.Subject = subject
  },
  setCopyToSelf(state, copyToSelf) {
    state.options.CopyToSelf = copyToSelf
  },
  setEmailContent(state, content) {
    state.emailContent = content
  },
  mergeEmailContent(state, content) {
    state.emailContent = merge({}, state.emailContent, content)
  },
  setLoadingEmailContent(state, loading) {
    state.loadingEmailContent = !!loading
  },
  setSavingTextTemplate(state, saving) {
    state.savingTextTemplate = !!saving
  },
  mergeOptions(state, options) {
    state.options = merge({}, state.options, options)
  },
  reset(state) {
    state.options = {
      To: '',
      ToName: '',
      Sender: '',
      SenderName: '',
      Body: '',
      Subject: '',
      CopyToSelf: false,
      AttachPDF: false,
      AttachCSV: false,
      AttachXLS: false,
      CustomTemplate: '',
      Salutation: '',
      ToSelf: false
    }
    state.emailContent = null
    state.nameLabel = 'Name'
  }
}
export const actions = {
  async actionEmail({ commit, state }, Send) {
    try {
      commit('setLoadingEmailContent', true)
      const apiArgs = merge({}, state.emailApiArgs, state.options, {
        ContactID: state.selectedContact ? state.selectedContact.ContactID : 0,
        Send
      })
      const result = await sendEmail(apiArgs)
      commit('setEmailContent', result)
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingEmailContent', false)
    }
  },
  async updateDefaultTemplate({ commit, state }, args) {
    try {
      commit('setSavingTextTemplate', true)
      const result = await updateTextTemplate(args)
      commit('mergeEmailContent', {
        DefaultTemplate: result.Text,
        DefaultTemplateAuditAmendedDate: result.AuditAmendedDate
      })
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setSavingTextTemplate', false)
    }
  }
}

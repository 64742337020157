import { getChanges } from '../../api/web/changes'

export const state = () => ({
  loading: false,
  newPurchaseOrders: 0,
  newSalesOrders: 0,
  contactUpdates: 0
})

export const getters = {
  total: state => {
    return state.newPurchaseOrders + state.newSalesOrders + state.contactUpdates
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setNewPurchaseOrders(state, total) {
    state.newPurchaseOrders = total
  },
  setNewSalesOrders(state, total) {
    state.newSalesOrders = total
  },
  setContactUpdates(state, total) {
    state.contactUpdates = total
  }
}

export const actions = {
  async update({ commit }) {
    try {
      commit('setLoading', true)
      const changes = await getChanges()
      commit('setNewPurchaseOrders', changes.NewPurchaseOrders)
      commit('setNewSalesOrders', changes.NewSalesOrders)
      commit('setContactUpdates', changes.ContactUpdates)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

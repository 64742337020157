import Entity from './entity'

const fields = {
  Vintage: {
    type: Number
  },
  Wine: {
    type: String
  },
  Score: {
    type: Number
  },
  Stock: {
    type: String
  },
  Price: {
    type: Number
  },
  Method: {
    type: String
  },
  AvailableBottles: {
    type: Number
  },
  CaseSize: {
    type: Number
  },
  ListNumber: {
    type: Number
  },
  FormatCode: {
    type: String
  },
  Orders: {
    type: Number
  }
}

const RecommendedWine = new Entity('RecommendedWine', fields)

RecommendedWine.createQuery(
  'get',
  'customerWineRecommendationGet',
  Entity.allButKeys(fields, ['Orders'])
)
RecommendedWine.createQuery(
  'getPrevious',
  'customerWineHistoryGet',
  Entity.onlyKeys(fields, ['Vintage', 'Wine', 'Score', 'Price', 'Orders'])
)

export default RecommendedWine

<template>
  <b-popover
    target="emailPopover"
    header="Email Address Entry"
    triggers="click"
  >
    <p class="mb-2">To enter multiple recipients of an email, the addresses should be separated by semi-colons, or commas or, when possible, on separate lines.</p>
    <p class="mb-2">To add a display name for each address, it can be added in round brackets after the address.</p>
    <div class="mb-2">
      <p class="m-0">e.g.</p>
      <ul class="p-0 m-0">
        <li>
          <span class="text-primary">
            fred@domain.com
          </span>
        </li>
        <li>
          <span class="text-primary">
            fred@domain.com
          </span>
          <span class="text-danger">
            ;
          </span>
          <span class="text-danger">
            bill@domain.com
          </span>
        </li>
        <li>
          <span class="text-primary">
            fred@domain.com (Fred Smith)
          </span>
        </li>
        <li>
          <span class="text-primary">
            fred@domain.com (Fred Smith)
          </span>
          <span class="text-danger">
            ,
          </span>
          <span class="text-danger">
            bill@domain.com (Bill Jones)
          </span>
        </li>
      </ul>
    </div>
    <p class="mb-2">If multiple addresses are entered, or display names are added in brackets, any name entered in a separate 'Name' field will not be used as the email recipient. However, it may still be used in the body of the email, as in 'Dear <span class="text-primary">Fred and Bill</span>'</p>
    <p class="m-0">Spaces are optional, except where relevant within the Display Name.</p>
    <div class="text-center">
      <b-button size="sm" class="wv-btn" @click="exitPopover">
        Exit
      </b-button>
    </div>
  </b-popover>
</template>

<script>
export default {
  methods: {
    exitPopover() {
      this.$root.$emit('bv::hide::popover')
    }
  }
}
</script>

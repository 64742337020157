<template>
  <b-form>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Vineyard Name"
      label-for="VineyardnName"
      :label-class="{green: isDirty('VineyardnName') }"
    >
      <b-form-input
        id="CVineyardName"
        v-model="VineyardName"
        :state="!$v.VineyardName.$error && null"
        trim
        size="sm"
        :disabled="viewMode"
        :class="{green: isDirty('VineyardName') }"
      />
      <b-form-invalid-feedback>
        Vineyard name is required
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapObjectToVModel(['VineyardName'])
  },
  validations: {
    VineyardName: {
      required
    }
  }
}
</script>

import Entity from './entity'

const fields = {
  WineCardNoteID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  Wine: {
    type: String
  },
  Note: {
    type: String
  },
  ContactID: {
    type: Number
  },
  DateUpdated: {
    type: String
  },
  UpdateUser: {
    type: String
  },
  UpdateUserID: {
    type: Number
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  ContactName: {
    type: String
  }
}

const WineCardNote = new Entity('WineCardNote', fields)
WineCardNote.createMutation(
  'create',
  'wineCardNoteAdd',
  Entity.onlyKeys(fields, ['WineCardID', 'Note']),
  fields
)
WineCardNote.createMutation(
  'update',
  'wineCardNoteUpdate',
  Entity.onlyKeys(fields, [
    'WineCardID',
    'Note',
    'WineCardNoteID',
    'AuditAmendedDate'
  ]),
  fields
)
WineCardNote.createMutation(
  'delete',
  'wineCardNoteDelete',
  Entity.onlyKeys(fields, ['WineCardNoteID']),
  []
)

const getFields = Entity.onlyKeys(fields, [
  'WineCardNoteID',
  'WineCardID',
  'AuditAmendedDate',
  'AuditCreatedDate',
  'DateUpdated',
  'UpdateUser',
  'ContactID',
  'UpdateUserID',
  'ContactName',
  'Note'
])

WineCardNote.createQuery(
  'getByWineCard',
  'wineCardNotesGetByWineCard',
  getFields
)

WineCardNote.createQuery('getAll', 'wineCardNotesAll', {
  total: {
    type: Number
  },
  current_page: {
    type: Number
  },
  wineCardNotesResults: {
    type: Object,
    fields: {
      Wine: {
        type: String
      },
      AvailableBottles: {
        type: Number
      },
      WineVintage: {
        type: Number
      },
      CaseSize: {
        type: Number
      },
      ...getFields
    }
  }
})

export default WineCardNote

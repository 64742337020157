import { client } from '../graphQLInstance'
import CountryRegion from '../../models/countryregion'

export const getCountryRegions = async args => {
  const result = await client.query({
    query: CountryRegion.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountryRegion.queries.get.name]
}

export const updateCountryRegion = async args => {
  const result = await client.query({
    query: CountryRegion.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountryRegion.mutations.update.name]
}

export const addCountryRegion = async args => {
  const result = await client.query({
    query: CountryRegion.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountryRegion.mutations.insert.name]
}

export const deleteCountryRegion = async args => {
  const result = await client.query({
    query: CountryRegion.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountryRegion.mutations.delete.name]
}

import { map } from 'lodash'
import { client } from '../graphQLInstance'
import AppellationDropDown from '../../models/appellationdropdown'

export const getList = async () => {
  const result = await client.query({
    query: AppellationDropDown.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[AppellationDropDown.queries.get.name], app => {
    return {
      value: app.AppellationID,
      text: app.AppellationName
    }
  })
}

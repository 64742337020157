import {
  getSalesOrderByCustomer,
  getPriceListStatusById
} from '@/api/reports/reports'
import moment from 'moment'

export const state = () => ({
  loading: false,
  priceListStatusLoading: false,
  kpiStats: null,
  priceListStatus: null
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoadingPriceListStatus(state, loading) {
    state.priceListStatusLoading = loading === true
  },
  setKPIStats(state, stats) {
    state.kpiStats = stats
  },
  setPriceListStatus(state, status) {
    state.priceListStatus = status
  }
}

export const actions = {
  async loadKPIStats({ commit }, date) {
    commit('setLoading', true)
    try {
      if (date) date = moment(date).format('YYYY-MM-DD HH:mm:SS')
      const result = await getSalesOrderByCustomer(date)
      if (result) commit('setKPIStats', result)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadPriceListStatus({ commit }, id) {
    commit('setLoadingPriceListStatus', true)
    try {
      const result = await getPriceListStatusById(id)
      if (result) commit('setPriceListStatus', result)
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPriceListStatus', false)
    }
  }
}

import { client } from '../graphQLInstance'
import Rate from '../../models/rate'

export const getAllRates = async () => {
  const result = await client.query({
    query: Rate.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Rate.queries.get.name]
}

export const updateRate = async args => {
  const result = await client.query({
    query: Rate.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Rate.mutations.update.name]
}

export const deleteRate = async args => {
  const result = await client.query({
    query: Rate.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Rate.mutations.delete.name]
}

export const addRate = async args => {
  const result = await client.query({
    query: Rate.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Rate.mutations.insert.name]
}

import { client } from '../graphQLInstance'
import Producer from '../../models/producer'

export const getProducers = async searchTerm => {
  const result = await client.query({
    query: Producer.query('search', {
      SearchTerm: searchTerm
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Producer.queries.search.name]
}

export const searchProducers = async params => {
  const result = await client.query({
    query: Producer.query('search', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Producer.queries.search.name]
}

export const addProducer = async args => {
  const result = await client.query({
    query: Producer.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const results = result.data[Producer.mutations.insert.name]
  Producer.triggerEvent('post-query', results)
  return results
}

export const getProducer = async args => {
  const result = await client.query({
    query: Producer.query('get', {
      RecordId: args.ProducerID
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const results = result.data[Producer.queries.get.name]
  Producer.triggerEvent('post-query', results)
  return results
}

export const updateProducer = async args => {
  const result = await client.query({
    query: Producer.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Producer.mutations.update.name]
}

export const deleteProducer = async args => {
  const result = await client.query({
    query: Producer.mutate('delete', {
      ID: args.ProducerID,
      AuditAmendedDate: args.AuditAmendedDate
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Producer.mutations.delete.name]
}

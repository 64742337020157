import { getList } from '@/api/wineBrowser/wines'

export const state = () => ({
  loading: false,
  list: [],
  selected: null
})

export const getters = {
  searchParameters: state => {
    let wines = null
    if (state.selected !== null) {
      wines = state.selected
    }
    return { wines }
  }
}

export const mutations = {
  setSelected(state, selected) {
    state.selected = selected
  },
  setList(state, list) {
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  resetSelection(state) {
    state.selected = null
  }
}

export const actions = {
  async refreshList({ commit }, { searchTerm }) {
    commit('setLoading', true)
    const response = await getList(searchTerm)
    commit('setList', response)
    commit('setLoading', false)
  }
}

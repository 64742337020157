<template>
  <b-modal
    v-model="shown"
    modal-class="wv-modal"
    content-class="shadow"
    footer-class="justify-content-center"
    centered
    @ok="save"
  >
    <template v-slot:modal-header>
      New Appellation
    </template>
    <appellation-form ref="form" :value="formData" @change="handleFormChange" />
    <template v-slot:modal-footer="{ ok }">
      <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="ok()">
        <b-spinner v-if="saving" type="grow" label="Loading..." small />
        OK
      </b-button>
      <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="shown=false">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import AppellationForm from '~/components/Forms/AppellationForm'
import { formResultMixin } from '../../../mixins/forms'

export default {
  components: {
    AppellationForm
  },
  mixins: [formResultMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loadingProp: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      formData: {
        AppellationName: '',
        AppellationOrder: 0,
        AppellationCat: 0,
        AppellationCode: ''
      },
      saving: false
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async save(e) {
      e.preventDefault()
      try {
        await this.$refs.form.validate()
        this.saving = true
        const appellation = await this.$store.dispatch(
          'administration/appellations/addAppellation',
          this.form
        )
        this.shown = false
        this.$notify.success('Appellation has been added')
        this.$emit('saved', appellation)
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.saving = false
      }
    },
    cancel() {
      this.$refs.form.reset()
    },
    reset() {
      this.$refs.form.reset()
    }
  }
}
</script>

import Entity from './entity'

const fields = {
  AddrID: {
    type: Number
  },
  AddressDescription: {
    type: String
  },
  IsWarehouse: {
    type: Boolean
  }
}

const WarehouseAddressDropDown = new Entity('WarehouseAddressDropDown', fields)
WarehouseAddressDropDown.createQuery('get', 'warehouseAddressDropDown')

export default WarehouseAddressDropDown

import Entity from '~/models/entity'

import { fields as WineCardFields } from '~/models/winecard'

export const fields = {
  ...WineCardFields,
  BrokingPrice: {
    type: Number
  }
}

const BrokingWineCard = new Entity('BrokingWineCard', fields)

// BrokingWineCard.createQuery('get', 'brokingWineCardGet', fields)
BrokingWineCard.createQuery(
  'getAll',
  'brokingWineCardGetAll',
  {
    total: {
      type: Number
    },
    brokingWineCardsPaginatedResults: {
      type: Array,
      fields: fields
    }
  },
  {
    // Status: {
    //   type: Number
    // },
    // Search: {
    //   type: String
    // },
    PerPage: {
      type: Number
    },
    Page: {
      type: Number
    }
  }
)

// BrokingWineCard.createMutation('update', 'brokingWineCardUpdate', {
//   BrokingPrice: {
//     type: Number
//   },
//   WineCardID: {
//     type: Number
//   },
//   ContactID: {
//     type: Number
//   }
// })

export default BrokingWineCard

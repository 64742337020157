<template>
  <group-box :header="true" class="h-100">
    <template slot="header">
      Packaging
    </template>
    <template v-if="looseOverride">
      <b-row class="my-1">
        <b-col v-b-tooltip.hover title="Restricted by Filters (Loose Bottles Only)">
          <d-form-radio v-model="packaging" :disabled="true" value="allStock" class="text-left">
            Non OWC &amp; Loose
          </d-form-radio>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row class="my-1">
        <b-col>
          <d-form-radio v-model="packaging" :disabled="allStockDisabled" value="allStock" class="text-left">
            All Stock
          </d-form-radio>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col>
          <d-form-radio v-model="packaging" value="justOwc" class="text-left">
            Just OWC
          </d-form-radio>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col>
          <d-form-radio v-model="packaging" value="choice" class="text-left" @change="openOWCModal">
            Choose OWC's
            <b-button v-if="packaging === 'choice'" size="sm" class="wv-btn mt-1" @click.prevent="openOWCModal">
              {{ selectedOWCs.length > 0 ? selectedOWCs.join(', ') : 'Choose' }}
            </b-button>
          </d-form-radio>
        </b-col>
      </b-row>
      <export-o-w-c-selection-modal v-model="showOWCSelectionComputed" />
    </template>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import ExportOWCSelectionModal from '../../ExportOWCSelectionModal'

export default {
  components: {
    GroupBox,
    ExportOWCSelectionModal
  },
  props: {
    value: {
      type: String,
      required: true
    },
    looseOverride: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOWCSelection: false
    }
  },
  computed: {
    allStockDisabled() {
      return this.grouping === 'BOTH'
    },
    grouping() {
      return this.$store.state.export.settings.grouping
    },
    packaging: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    showOWCSelectionComputed: {
      get() {
        return this.packaging === 'choice' && this.showOWCSelection === true
      },
      set(value) {
        this.showOWCSelection = value
      }
    },
    selectedOWCs() {
      return this.$store.state.export.settings.owcSelection.map(o => 'OWC' + o)
    }
  },
  watch: {
    grouping(grouping) {
      if (this.looseOverride) {
        this.packaging = 'allStock'
      } else if (grouping === 'BOTH' && this.packaging === 'allStock') {
        this.packaging = 'justOwc'
      }
    }
  },
  methods: {
    openOWCModal() {
      this.showOWCSelection = true
    }
  }
}
</script>

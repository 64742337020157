export const saveOpenOrders = orders => {
  window.sessionStorage.setItem('open-wpo', JSON.stringify(orders))
}

export const getOpenOrders = () => {
  const openOrders = window.sessionStorage.getItem('open-wpo')
  try {
    return JSON.parse(openOrders)
  } catch (e) {
    return []
  }
}

export const saveChanges = (WPOID, changedDetails) => {
  window.sessionStorage.setItem(
    'changed-wpoid-' + WPOID,
    JSON.stringify(changedDetails)
  )
}

export const getSavedChanges = WPOID => {
  const changedDetails = window.sessionStorage.getItem('changed-wpoid-' + WPOID)
  try {
    return JSON.parse(changedDetails)
  } catch (e) {
    return null
  }
}

export const removeSavedChanges = WPOID => {
  window.sessionStorage.removeItem('changed-wpoid-' + WPOID)
}

import { client } from '../graphQLInstance'
import StockMargin from '~/models/stockmargin'

export const getStockMargins = async args => {
  const result = await client.query({
    query: StockMargin.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[StockMargin.queries.get.name]
}

import WineStockCard from '../../models/reserves/winestockcard'
import BuyBackEmail from '../../models/reserves/buybackemail'
import { client } from '../graphQLInstance'

export const getWineStockCard = async WineCardID => {
  const result = await client.query({
    query: WineStockCard.query('get', { WineCardID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineStockCard.queries.get.name]
}

export const getWineCustomerReserves = async WineCardID => {
  const result = await client.query({
    query: WineStockCard.query('getStockByCustomer', { WineCardID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  // prettier-ignore
  result.data[WineStockCard.queries.getStockByCustomer.name].map(function (item) {
    item.available_quantity =  {
      bottles: item.AvailableBottles % item.CaseSize,
      cases: Math.floor(item.AvailableBottles / item.CaseSize)
    }
    item.available_received_quantity =  {
      bottles: item.AvailableReceivedBottles % item.CaseSize,
      cases: Math.floor(item.AvailableReceivedBottles / item.CaseSize)
    }
    item.bro_quantity =  {
      bottles: item.BroBottles % item.CaseSize,
      cases: Math.floor(item.BroBottles / item.CaseSize)
    }
    item.bro_received_quantity =  {
      bottles: item.BroReceivedBottles % item.CaseSize,
      cases: Math.floor(item.BroReceivedBottles / item.CaseSize)
    }
    return item
  })
  return result.data[WineStockCard.queries.getStockByCustomer.name]
}

export const getWineCardWineAllocations = async args => {
  const result = await client.query({
    query: WineStockCard.query('getAllocations', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineStockCard.queries.getAllocations.name]
}

export const getEmailAddresses = async args => {
  const result = await client.query({
    query: WineStockCard.query('getEmailAddresses', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineStockCard.queries.getEmailAddresses.name]
}

export const sendEmails = async args => {
  const result = await client.query({
    query: BuyBackEmail.mutate('sendEmails', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BuyBackEmail.mutations.sendEmails.name]
}

import Entity from './entity'
import { fields } from './winebrowsersearchresult'

const WebContactNotifications = new Entity('WebContactNotifications', fields)

WebContactNotifications.createQuery(
  'getAll',
  'webContactNotificationsGet',
  fields,
  {
    WebContactID: {
      type: Number
    }
  }
)

WebContactNotifications.createMutation(
  'delete',
  'webContactNotificationDelete',
  {
    WineCardID: {
      type: Number
    },
    WebContactID: {
      type: Number
    }
  },
  fields
)

export default WebContactNotifications

import { getList } from '@/api/wineBrowser/appellations'

export const state = () => ({
  loading: false,
  list: [],
  selected: []
})

export const getters = {
  searchParameters: state => {
    return {
      appellations: state.selected.length > 0 ? state.selected.join(',') : null
    }
  }
}

export const mutations = {
  setSelected(state, selected) {
    state.selected = selected
  },
  setList(state, list) {
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  resetSelection(state) {
    state.selected = []
  }
}

export const actions = {
  async refreshList({ commit }) {
    commit('setLoading', true)
    const response = await getList()
    commit('setList', response)
    commit('setLoading', false)
  }
}

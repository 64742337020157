import { client } from '../graphQLInstance'
import WebSalesOrder from '../../models/websalesorder'
import WebSalesOrderLine from '../../models/websalesorderline'

export const searchSalesOrders = async args => {
  const result = await client.query({
    query: WebSalesOrder.query('search', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebSalesOrder.queries.search.name]
}

export const getSalesOrderLines = async args => {
  const result = await client.query({
    query: WebSalesOrderLine.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebSalesOrderLine.queries.get.name]
}

export const createSalesOrder = async args => {
  const result = await client.query({
    query: WebSalesOrder.mutate('create', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebSalesOrder.mutations.create.name]
}

export const updateSalesOrder = async args => {
  const result = await client.query({
    query: WebSalesOrder.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebSalesOrder.mutations.update.name]
}

import {
  updateCountrySubRegion,
  addCountrySubRegion,
  deleteCountrySubRegion,
  getCountrySubRegions
} from '~/api/administration/countrysubregion'
import { merge, find } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  saving: false,
  list: [],
  searchTerm: '',
  active: null
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  setList(state, list) {
    state.list = list
  },
  resetList(state) {
    state.list = []
  },
  addToList(state, region) {
    state.list.unshift(region)
  },
  setActive(state, { CountrySubRegionID }) {
    state.active = find(state.list, { CountrySubRegionID })
  },
  update(state, wineName) {
    const idx = state.list.findIndex(
      w => w.CountrySubRegionID === wineName.CountrySubRegionID
    )
    if (idx > -1) {
      const newWineName = merge({}, state.list[idx], wineName)
      Vue.set(state.list, idx, newWineName)
    }
  },
  removeFromList(state, { CountrySubRegionID }) {
    state.list = state.list.filter(
      wn => wn.CountrySubRegionID !== CountrySubRegionID
    )
  }
}

export const actions = {
  async loadList({ commit }) {
    commit('setLoading', true)
    const response = await getCountrySubRegions()
    commit('setList', response)
    commit('setLoading', false)
  },
  setActive({ commit }, item) {
    commit('setActive', item)
  },
  async add({ commit }, item) {
    commit('setSaving', true)
    try {
      const result = await addCountrySubRegion(item)
      commit('addToList', result)
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async update({ commit, rootState, state }, item) {
    commit('setSaving', true)
    try {
      const result = await updateCountrySubRegion(item)
      commit('update', result)
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async delete({ commit }, item) {
    commit('setSaving', true)
    try {
      const result = await deleteCountrySubRegion(item)
      if (result) {
        commit('removeFromList', item)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  }
}

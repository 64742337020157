import {
  addWebhook,
  updateWebhook,
  deleteWebhook,
  getAllWebhooks
} from '../../api/web/webhook'

export const state = () => ({
  loading: false,
  saving: false,
  webhooks: [],
  events: [
    { text: 'Web Contact Updated', value: 'WebContactUpdated' },
    { text: 'Web Contact Created', value: 'WebContactCreated' },
    { text: 'Web Contact Change Password', value: 'WebContactPasswordChanged' }
  ]
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  setWebhooks(state, webhooks) {
    state.webhooks = webhooks
  },
  replaceWebhook(state, webhook) {
    state.webhooks = state.webhooks.map(w => {
      if (w.WebhookID === webhook.WebhookID) {
        return webhook
      }
      return w
    })
  },
  appendWebhook(state, webhook) {
    state.webhooks.push(webhook)
  },
  removeWebhook(state, webhook) {
    state.webhooks = state.webhooks.filter(
      w => w.WebhookID !== webhook.WebhookID
    )
  }
}

export const actions = {
  async getAll({ commit }) {
    try {
      commit('setLoading', true)
      const webhooks = await getAllWebhooks()
      commit('setWebhooks', webhooks)
      return webhooks
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async create({ commit }, args) {
    try {
      commit('setSaving', true)
      const webhook = await addWebhook(args)
      commit('appendWebhook', webhook)
      return webhook
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async update({ commit }, args) {
    try {
      commit('setSaving', true)
      const webhook = await updateWebhook(args)
      commit('replaceWebhook', webhook)
      return webhook
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async remove({ commit }, args) {
    try {
      commit('setSaving', true)
      await deleteWebhook(args)
      commit('removeWebhook', args)
      return true
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  }
}

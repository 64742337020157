import { client } from '../graphQLInstance'
import Webhook from '../../models/webhook'

export const getAllWebhooks = async args => {
  const result = await client.query({
    query: Webhook.query('getAll', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Webhook.queries.getAll.name]
}

export const addWebhook = async args => {
  const result = await client.query({
    query: Webhook.mutate('add', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Webhook.mutations.add.name]
}

export const updateWebhook = async args => {
  const result = await client.query({
    query: Webhook.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Webhook.mutations.update.name]
}

export const deleteWebhook = async args => {
  const result = await client.query({
    query: Webhook.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Webhook.mutations.delete.name]
}

import Vue from 'vue'
import { has, map, each, keys, find, without, concat } from 'lodash'

export const formObjectMixin = {
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      changedFormData: {},
      subFormValidations: {},
      awaitFormResponse: false,
      awaitResponseCallback: null,
      hasChanged: false
    }
  },
  methods: {
    valueIsEmpty(key) {
      return (
        this[key] === null ||
        this[key] === undefined ||
        String(this[key]).length === 0
      )
    },
    handleFormChange(fields) {
      each(keys(fields), key => {
        Vue.set(this.changedFormData, key, fields[key])
      })
      this.$emit('change', this.changedFormData)
    },
    setChangedDetails(details) {
      let hasChanged = false
      each(keys(details), key => {
        Vue.set(this.changedFormData, key, details[key])
        hasChanged = true
      })
      if (this.$options.subValidations) {
        each(this.$options.subValidations, subFormKey => {
          if (this.$refs[subFormKey]) {
            const result = this.$refs[subFormKey].setChangedDetails(details)
            if (result === true) {
              hasChanged = true
            }
          }
        })
      }
      if (hasChanged) {
        this.hasChanged = true
      }
      return hasChanged
    },
    formReset() {
      this.changedFormData = {}
      this.hasChanged = false
      if (this.$v) {
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
      if (this.$options.subValidations) {
        each(this.$options.subValidations, subFormKey => {
          this.$nextTick(() => {
            if (this.$refs[subFormKey]) {
              this.$refs[subFormKey].reset()
            }
          })
        })
      }
    },
    reset() {
      this.formReset()
      return this
    },
    isDirty(key) {
      if (key) {
        if (has(this.changedFormData, key) === false) {
          return false
        }
        if (
          this.changedFormData[key] === null ||
          this.changedFormData[key] === '' ||
          this.changedFormData[key] === undefined
        ) {
          return false
        }
        if (has(this.value, key)) {
          return this.value[key] !== this.changedFormData[key]
        }
        return true
      } else {
        const hasChanged = find(keys(this.changedFormData), key => {
          if (has(this.value, key)) {
            return this.value[key] !== this.changedFormData[key]
          }
          if (
            this.changedFormData[key] === null ||
            this.changedFormData[key] === '' ||
            this.changedFormData[key] === undefined
          ) {
            return false
          }
          return true
        })
        return hasChanged !== undefined
      }
    },
    formValidate() {
      this.awaitFormResponse = false
      this.awaitResponseCallback = false
      if (this.$v) {
        this.$v.$touch()
      }
      if (this.$options.subValidations) {
        each(this.$options.subValidations, subFormKey => {
          if (this.$refs[subFormKey] && this.$refs[subFormKey].$v) {
            this.$refs[subFormKey].$v.$touch()
          }
        })
      }
    },
    triggerValueEvents() {
      if (this.value) {
        each(keys(this.value), key => {
          if (this[key] !== undefined) {
            this.$set(this.changedFormData, key, this[key])
          }
          this.$emit('change-' + key, this[key])
        })
        this.$emit('change', this.changedFormData)
      }
    },
    async validate() {
      this.triggerValueEvents()
      this.formValidate()
      await this.formIsValid()
      await this.afterValidate()
      return true
    },
    afterValidatePromises() {
      let promises = [new Promise(resolve => resolve(true))]
      if (this.$options.subValidations) {
        each(this.$options.subValidations, subFormKey => {
          promises = concat(promises, this.$refs[subFormKey].afterValidate())
        })
      }
      return promises
    },
    afterValidate() {
      return Promise.all(this.afterValidatePromises())
    },
    awaitResponse(resolve, reject) {
      this.awaitFormResponse = false
      this.awaitResponseCallback = { resolve, reject }
    },
    resolveResponse() {
      this.awaitResponseCallback.resolve()
      this.awaitResponseCallback = null
    },
    rejectResponse(e) {
      if (this.awaitResponseCallback) {
        this.awaitResponseCallback.reject(e)
      }
    },
    formIsValid() {
      return new Promise(async (resolve, reject) => {
        if (this.awaitFormResponse === true) {
          return reject(new Error('Waiting For Response'))
        }
        if (this.$v) {
          this.$v.$touch()
          if (this.$v.$anyError === true) {
            return reject(new Error('Please review the errors'))
          }
        }
        if (this.$options.subValidations) {
          try {
            const subValidation = map(this.$options.subValidations, v => {
              return this.$refs[v] && this.$refs[v].isValid()
            })
            await Promise.all(subValidation)
          } catch (e) {
            return reject(e)
          }
        }
        resolve(true)
      })
    },
    isValid() {
      return this.formIsValid()
    }
  },
  watch: {
    changedFormData: {
      handler() {
        this.hasChanged = this.isDirty()
        if (this.hasChanged === false) {
          if (this.$options.subValidations) {
            each(this.$options.subValidations, subFormKey => {
              if (this.$refs[subFormKey] && this.hasChanged === false) {
                this.hasChanged = this.$refs[subFormKey].isDirty()
              }
            })
          }
        }
        this.$emit('is-dirty', this.hasChanged)
      },
      deep: true
    }
  }
}

export const formResultMixin = {
  data() {
    return {
      form: {},
      dirtyForms: []
    }
  },
  computed: {
    anyFormDirty() {
      return this.dirtyForms.length > 0
    }
  },
  methods: {
    handleFormChange(fields, formName) {
      each(keys(fields), key => {
        this.$set(this[formName || 'form'], key, fields[key])
      })
    },
    getFormValue(key, defaultValue) {
      const formKeys = keys(this.form)
      if (formKeys.indexOf(key) >= 0) {
        return this.form[key]
      }
      return defaultValue
    },
    isDirtyForm(formName) {
      return this.dirtyForms.indexOf(formName) >= 0
    },
    setDirtyForm(formName, dirty) {
      if (dirty) {
        if (this.dirtyForms.indexOf(formName) < 0) {
          this.dirtyForms.push(formName)
        }
      } else {
        this.dirtyForms = without(this.dirtyForms, formName)
      }
    },
    resetDirtyForms() {
      each(this.dirtyForms, formRef => {
        if (this.$refs[formRef]) {
          this.$refs[formRef].reset()
        }
      })
    }
  }
}

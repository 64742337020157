<template>
  <div>
    <div v-if="loading && page === 1" class="text-center text-primary my-2 mx-auto">
      <b-spinner type="grow" label="Loading..." />
    </div>
    <vue-custom-scrollbar
      v-else
      class="virtual-scroll t-notes-note"
      :settings="settings"
    >
      <virtual-table
        :fields="tableFields"
        scroller-class=""
        :items="notes"
        :per-page="perPage"
        :current-page="page"
        :total="total"
        :show-loading="true"
        :estimate-total-scroll-height="false"
        primary-key="id"
        @request-page="$emit('change-page', $event)"
        @sort-changed="performOrderBy"
      >
        <template slot="table-colgroup" slot-scope="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.width, maxWidth: field.width, overflow: 'hidden' }"
          >
        </template>
        <template slot="DateUpdated" slot-scope="data">
          <date :date="data.item.DateUpdated" />
        </template>
        <template slot="actions" slot-scope="data">
          <div class="d-flex justify-content-end">
            <b-button size="sm" class="btn wv-btn mr-1" @click="$emit('edit',data.item)">
              Edit
            </b-button>
            <b-button size="sm" class="btn wv-btn" @click="$emit('delete',data.item)">
              Delete
            </b-button>
          </div>
        </template>
      </virtual-table>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import Date from '~/components/Base/Date'
import VirtualTable from '~/components/Base/VirtualTable'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'
import { mapState } from 'vuex'

export default {
  components: {
    Date,
    VirtualTable,
    vueCustomScrollbar
  },
  props: {
    perPage: {
      type: Number,
      default: process.env.paginate_per_page
    },
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },
    extendedTable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      settings: {
        tagname: 'div',
        suppressScrollY: true
      }
    }
  },
  computed: {
    ...mapState('wineCard/notes', {
      notes: state => state.notes
    }),
    tableFields() {
      const tableFields = []
      tableFields.push({
        key: 'DateUpdated',
        label: 'Date',
        sortable: true,
        width: '100px'
      })
      tableFields.push({
        key: 'Note',
        sortable: true
      })
      tableFields.push({
        key: 'UpdateUser',
        sortable: true,
        width: '100px'
      })
      tableFields.push({
        key: 'actions',
        sortable: false,
        label: '',
        width: '113px',
        tdClass: 'last-td'
      })
      return tableFields
    }
  },
  methods: {
    performOrderBy(sortBy) {
      this.$emit('order-by', sortBy)
    }
  }
}
</script>
<style>
.t-notes-note table {
  width: 100%;
}

.t-notes-note .virtual-scroll__content.ps--active-y table {
  width: calc(100% + 15px);
}

.t-notes-note .virtual-scroll__content:not(.ps--active-y):after {
  display: none;
}

.t-notes-note .last-td {
  position: relative;
}

.t-notes-note .last-td .wv-btn {
  position: absolute;
  height: 100% !important;
  width: 50%;
  top: 0;
  left: 0;
}

.t-notes-note .last-td .wv-btn + .wv-btn {
  left: 50%;
}

@media (min-width: 1630px) {
  .t-notes-note .virtual-scroll__content {
    height: 203px;
  }
}
</style>

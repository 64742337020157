import { getList } from '@/api/administration/winematcher'
import { reduce, concat, merge, has } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  page: 1,
  perPage: 300,
  total: 0,
  loadedPage: [],
  results: [],
  orderBy: null,
  orderByDirection: 'ASC',
  indexedId: {},
  pageOffsets: {},
  searchParameters: {
    SearchTerm: null,
    SearchField: null
  },
  filterParams: {
    Tasks: [
      'Classification',
      'Country',
      'Designation',
      'LWINCode',
      'Producer',
      'Vintage',
      'WineColour',
      'WineName',
      'Region',
      'SubRegion',
      'Abv'
    ],
    OnlyFiltered: true,
    DeleteAfterSync: true
  }
})

export const getters = {
  pageResults: state => {
    const offset = state.pageOffsets[state.page] || 0
    return state.results.slice(offset, offset + state.perPage)
  },
  allResults: state => {
    return state.results
  },
  getPage: state => page => {
    const offset = state.pageOffsets[page]
    if (offset === undefined) return null
    return state.results.slice(offset, offset + state.perPage)
  }
}

export const mutations = {
  setFilterTasks(state, tasks) {
    state.filterParams.Tasks = tasks
  },
  setFilterOnlyFiltered(state, onlyFiltered) {
    state.filterParams.OnlyFiltered = onlyFiltered === true
  },
  setFilterDeleteAfterSync(state, deleteAfter) {
    state.filterParams.DeleteAfterSync = deleteAfter === true
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSearchParameters(state, params) {
    state.searchParameters = params
  },
  setResults(state, results) {
    state.results = results
    state.indexedId = reduce(
      results,
      (keyMap, r, i) => {
        keyMap[r.id] = i
        return keyMap
      },
      {}
    )
  },
  appendResults(state, results) {
    state.results = concat(state.results, results)
    state.indexedId = reduce(
      state.results,
      (keyMap, r, i) => {
        keyMap[r.id] = i
        return keyMap
      },
      {}
    )
  },
  replaceResultsForPage(state, { page, results }) {
    if (has(state.pageOffsets, page)) {
      const offset = state.pageOffsets[page]
      for (let i = 0; i < results.length; i++) {
        Vue.set(state.results, offset + i, results[i])
      }
    } else {
      Vue.set(state.pageOffsets, page, state.results.length)
      state.results = concat(state.results, results)
      state.indexedId = reduce(
        state.results,
        (keyMap, r, i) => {
          keyMap[r.id] = i
          return keyMap
        },
        {}
      )
    }
  },
  replaceResultById(state, { id, result }) {
    const wineCardIdx = state.indexedId[id]
    const current = state.results[wineCardIdx]
    if (wineCardIdx !== undefined) {
      const merged = merge(current, result)
      Vue.set(state.results, wineCardIdx, merged)
    } else {
      state.results.push(result)
    }
  },
  resetSelection(state) {
    state.results = []
    state.indexedId = {}
    state.page = 1
    state.loadedPage = []
    state.pageOffsets = {}
    state.searchParameters = {
      SearchTerm: null,
      SearchField: null
    }
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setPageOffset(state, page) {
    Vue.set(state.pageOffsets, page, state.results.length)
  },
  setLoadedPage(state, page) {
    if (state.loadedPage.indexOf(page) < 0) {
      state.loadedPage.push(page)
    }
  },
  setOrderBy(state, key) {
    state.orderBy = key
  },
  setOrderByDirection(state, direction) {
    state.orderByDirection = direction === 'ASC' ? 'ASC' : 'DESC'
  }
}

export const actions = {
  async performSearch({ commit, state }, { searchParameters }) {
    try {
      commit('setLoading', true)
      searchParameters.OrderBy = state.orderBy
      searchParameters.OrderByDirection = state.orderByDirection
      const response = await getList({
        Page: state.page,
        PerPage: state.perPage,
        ...searchParameters
      })

      commit('setPageOffset', state.page)
      commit('setResults', response.liveExMatcherResult)
      commit('setTotal', response.total)
      commit('setLoadedPage', state.page)
      commit('setLoading', false)
    } catch (e) {
      commit('setPageOffset', 1)
      commit('setResults', [])
      commit('setTotal', 0)
      commit('setLoadedPage', 1)
      commit('setLoading', false)
      throw e
    }
  },
  async changePage({ commit, state }, { page, searchParameters }) {
    if (state.loadedPage.indexOf(page) >= 0) {
      commit('setPage', page)
      return
    }
    commit('setLoading', true)
    searchParameters.OrderBy = state.orderBy
    searchParameters.OrderByDirection = state.orderByDirection
    searchParameters.Page = page
    searchParameters.PerPage = state.perPage
    const response = await getList(searchParameters)
    commit('setPageOffset', page)
    commit('appendResults', response.liveExMatcherResult)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  },
  changeOrder({ commit, state, dispatch }, { key, searchParameters }) {
    const doDesc = state.orderBy === key && state.orderByDirection === 'ASC'
    commit('resetSelection')
    commit('setOrderBy', key)
    commit('setOrderByDirection', doDesc ? 'DESC' : 'ASC')
    dispatch('performSearch', { searchParameters })
  },
  clearAll({ commit }) {
    commit('setPageOffset', 1)
    commit('setResults', [])
    commit('setTotal', 0)
    commit('setLoadedPage', 1)
    commit('setLoading', false)
  }
}

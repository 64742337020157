export const auditFields = {
  AuditAmendedDate: {
    type: String
  },
  AuditAmendedBy: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  AuditCreatedBy: {
    type: Number
  }
}

import Entity from './entity'

const fields = {
  POID: {
    type: Number
  },
  OrderDate: {
    type: String
  },
  CompanyName: {
    type: String
  },
  UsageCode: {
    type: String
  },
  AvailableBottles: {
    type: Number
  },
  OriginalPackaging: {
    type: String
  },
  Include: {
    type: Boolean
  },
  RunningTotal: {
    type: Number
  },
  CostPrice: {
    type: Number
  },
  ListPrice: {
    type: Number
  },
  CaseSize: {
    type: Number
  },
  Margin: {
    type: Number
  },
  RunningMargin: {
    type: Number
  },
  TransactionOrder: {
    type: Number
  }
}

const StockMargin = new Entity('StockMargin', fields)

StockMargin.createQuery('get', 'stockMarginsGet', fields)

export default StockMargin

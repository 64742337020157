import { searchTallyCard } from '../../api/etally'

export const state = () => ({
  perPage: 60,
  page: 1,
  results: [],
  total: 0,
  loading: false,
  loadedPage: 0
})

export const getters = {
  pagedResults: state => {
    return state.results.slice(0, state.page * state.perPage)
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  appendResults(state, results) {
    state.results = [].concat(state.results).concat(results)
  },
  reset(state) {
    state.results = []
    state.page = 1
    state.total = 0
    state.loadedPage = 0
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setLoadedPage(state, loadedPage) {
    state.loadedPage = loadedPage
  }
}

export const actions = {
  async search({ commit, state }, fields) {
    commit('setLoading', true)
    try {
      commit('setPage', 1)
      const cards = await searchTallyCard({
        PerPage: state.perPage,
        Page: state.page,
        ...fields
      })
      if (cards) {
        commit('setTotal', cards.total)
        commit('appendResults', cards.eTallySearchResults)
        commit('setLoadedPage', 1)
      }
      return cards
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async changePage({ commit, state }, { page, searchParameters }) {
    if (state.loadedPage >= page) {
      commit('setPage', page)
      return
    }
    commit('setLoading', true)
    const response = await searchTallyCard({
      PerPage: state.perPage,
      Page: page,
      ...searchParameters
    })
    commit('appendResults', response.eTallySearchResults)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  }
}

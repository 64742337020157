<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :hide-footer="true"
    size="xl-alt"
    body-class="px-2 py-0"
    @hide="checkForChanges"
  >
    <template v-slot:modal-header>
      {{ title }} - Loose Bottle Price
    </template>
    <div v-if="loading" class="text-center text-primary mt-4 mb-2">
      <b-spinner variant="primary" type="grow" label="Spinning" />
    </div>
    <div v-else>
      <b-row>
        <b-col class="col-12 col-xxl-4 pt-4">
          <current-list-price
            :cost-price="item.average_cost"
            :selling-price="item.listPrice"
            :average-days-in-stock="item.average_days_in_stock"
            :promotion-date="item.promotion_date"
            :available-bottles="item.availableBottles"
            :promo-user-changed="promoUserChanged"
            :case-size="item.case_size"
            css-class=""
            @change-list-price="updateListPrice"
            @change-promotional-date="updatePromotionalDate"
          />
          <empty-case-button class="mt-2" :loading="wineCardLoading" :wine-card="wineCard" @click="$emit('empty-cases')">
            Empty Cases Button.
          </empty-case-button>
        </b-col>
        <b-col class="col-12 col-xxl-8">
          <b-row>
            <b-col class="col-12 col-xxl-6">
              <loose-bottle-price
                class="pt-2"
                :average-price="item.average_loose_cost"
                :loose-bottle-price="looseBottlePrice"
                :average-days-in-stock="item.average_days_in_stock"
                :available-bottles="item.availableBottles"
                @change-loose-bottle-price="updateLooseBottlePrice"
              />
            </b-col>
            <b-col class="col-12 col-xxl-6">  
              <actions
                class="mb-1 mt-xl-0"
                :has-changes="hasChanges"
                :is-valid="isValid"
                :item="item"
                @cancel="resetChanges"
                @ok="shown=false"
                @change="handleGetWineSearcher"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-12">
              <loose-inventory
                :purchases="purchases"
                :loading-purchases="loadingPurchases"
                @click-rotation-number="clickRotationNumber"
                @click-contact="clickContact"
                @click-po="clickPO"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <stock-table 
            :list-price="listPrice / item.case_size" 
            :loose-bottle-price="looseBottlePrice / item.case_size"
            :loose-view="true"
            @get-wine-searcher="handleGetWineSearcher"
          />
        </b-col>
      </b-row>
    </div>
    <confirm-modal v-model="showConfirmation" @ok="emitChange" @cancel="handleCancelChange">
      <p v-if="changedLooseBottlePrice === 0">
        <b>Attention:</b> This will remove the Loose Bottle Price
      </p>
      <p v-if="hasBroStock && changedLooseBottlePrice !== null">
        <b>Attention:</b> This wine has BRO stock
      </p>
      This will change the {{ changed }}, are you sure?
    </confirm-modal>
  </b-modal>
</template>

<script>
import CurrentListPrice from './PriceLookup/CurrentListPrice'
// import LastPurchase from './PriceLookup/LastPurchase'
// import LastSale from './PriceLookup/LastSale'
import Actions from './PriceLookup/Actions'
import StockTable from './PriceLookup/StockTable'
import EmptyCaseButton from '~/components/Base/EmptyCaseButton'
import ConfirmModal from '~/components/Modal/Confirm'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'
import LooseBottlePrice from './LooseBottles/LooseBottlePrice'
import LooseInventory from './PriceLookup/LooseInventory.vue'
import { WineUsageCode } from '~/constants/wineUsage'

export default {
  components: {
    CurrentListPrice,
    // LastPurchase,
    // LastSale,
    Actions,
    StockTable,
    EmptyCaseButton,
    ConfirmModal,
    LooseBottlePrice,
    LooseInventory
  },
  mixins: [TitleFromWineCard],
  props: {
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    wineCard: {
      type: Object,
      required: true
    },
    wineCardLoading: {
      type: Boolean,
      required: true
    },
    globals: {
      type: Object,
      required: true
    },
    globalLoading: {
      type: Boolean,
      required: true
    },
    promoUserChanged: {
      type: String,
      default: null
    },
    loadingPurchases: {
      type: Boolean,
      required: true
    },
    purchases: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showWineCardInfo: true,
      changedListPrice: null,
      changedPromotionalDate: null,
      changedLooseBottlePrice: null,
      showConfirmation: false,
      isValid: false,
      changed: ''
    }
  },
  computed: {
    loading() {
      return (
        this.wineCardLoading || this.globalLoading || this.browserCardLoading
      )
    },
    browserCardLoading() {
      return this.$store.state.wineCard.activeCard.activeBrowserCardLoading
    },
    showBordeauxIndex() {
      return this.item.format.toUpperCase() === 'BOTTLE'
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    hasChanges() {
      return (
        this.changedListPrice !== null ||
        this.changedPromotionalDate !== null ||
        this.changedLooseBottlePrice !== null
      )
    },
    listPrice() {
      if (this.changedListPrice !== null) {
        return this.changedListPrice
      }
      return this.item.listPrice
    },
    looseBottlePrice() {
      if (this.changedLooseBottlePrice !== null) {
        return this.changedLooseBottlePrice
      }
      return this.item.looseBottlePrice
    },
    promotionalDate() {
      if (this.changedPromotionalDate !== null) {
        return this.changedPromotionalDate
      }
      return this.item.promotion_date
    },
    hasBroStock() {
      if (this.purchasesLoading) {
        return null
      }
      return (
        this.purchases.filter(item => item.UsageCode === WineUsageCode.BRO)
          .length > 0
      )
    }
  },
  methods: {
    updateLooseBottlePrice(value, isValid) {
      this.changedLooseBottlePrice = value
      this.isValid = isValid
    },
    updateListPrice(value, isValid) {
      this.changedListPrice = value
      this.isValid = isValid
    },
    updatePromotionalDate(value, isValid) {
      this.changedPromotionalDate = value
      this.isValid = isValid
    },
    checkForChanges(evn) {
      if (this.hasChanges) {
        if (
          this.changedListPrice !== null ||
          this.changedLooseBottlePrice !== null
        ) {
          evn.preventDefault()
          this.showConfirmation = true
          this.changed = this.getChanged()
          this.shown = true
        } else {
          this.emitChange()
        }
      }
    },
    emitChange() {
      this.showConfirmation = false
      const changes = {}
      if (this.changedListPrice !== null) {
        changes.listPrice = this.listPrice
      }
      if (this.changedLooseBottlePrice !== null) {
        changes.looseBottlePrice = this.looseBottlePrice
      }
      this.$emit('change', this.item, changes)
      if (this.changedPromotionalDate !== null) {
        this.$emit('change-promo-date', this.item, this.changedPromotionalDate)
      }
      this.resetChanges()
    },
    resetChanges() {
      this.changedLooseBottlePrice = null
      this.changedListPrice = null
      this.changedPromotionalDate = null
      this.shown = false
    },
    handleGetBoardeauxIndex() {
      this.$emit('get-bordeaux-index', this.item)
    },
    handleGetLivEx() {
      this.$emit('get-liv-ex', this.item)
    },
    handleGetWineSearcher() {
      this.$emit(
        'get-wine-searcher',
        this.item,
        this.$store.state.user.settings.showAllSellers,
        this.$store.state.user.settings.showOtherCaseSizes
      )
    },
    handleCancelChange() {
      this.showConfirmation = false
      this.resetChanges()
    },
    getChanged() {
      const changed = []
      if (this.changedLooseBottlePrice !== null) {
        changed.push('Loose Bottle Price')
      }
      if (this.changedListPrice !== null) {
        changed.push('List Price')
      }
      return changed.join(' and ')
    },
    clickRotationNumber(item) {
      this.$emit('click-rotation-number', item)
    },
    clickContact(item) {
      this.$emit('click-contact', item)
    },
    clickPO(item) {
      this.$emit('click-po', item)
    }
  }
}
</script>

<template>
  <empty-cases-modal
    v-if="activeCard"
    v-model="showEmptyCaseModal"
    :wine-card-loading="loading"
    :item="activeCard"
    @hidden="handleHidden"
  />
</template>

<script>
import EmptyCasesModal from '~/components/Modal/EmptyCases'

export default {
  components: {
    EmptyCasesModal
  },
  computed: {
    showEmptyCaseModal: {
      get() {
        return this.$store.state.modal.showEmptyCaseModal
      },
      set(value) {
        this.$store.commit('modal/setShowEmptyCaseModal', value)
      }
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeCard
    },
    loading() {
      return this.$store.state.wineCard.activeCard.loading
    }
  },
  methods: {
    handleHidden() {
      this.$store.dispatch('wineCard/activeCard/reloadActiveCard')
    }
  }
}
</script>

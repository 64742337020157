import WineNameModal from '~/components/Modal/Administration/WineNameModal'
import ProducersModal from '~/components/Modal/Administration/ProducersModal'
import NewFormatModal from '~/components/Modal/Administration/NewFormatModal'
import Vue from 'vue'

export default {
  components: {
    WineNameModal,
    ProducersModal,
    NewFormatModal
  },
  data() {
    return {
      boolWineNamesModal: false,
      boolProducersModal: false,
      boolNewFormatModal: false,
      chosenWineName: null,
      chosenProducer: null
    }
  },
  methods: {
    toggleWineNamesModal() {
      this.boolWineNamesModal = !this.boolWineNamesModal
    },
    toggleProducersModal() {
      this.boolProducersModal = !this.boolProducersModal
    },
    toggleNewFormatModal() {
      this.boolNewFormatModal = !this.boolNewFormatModal
    },
    wineNameSelected(wineName) {
      this.chosenWineName = wineName
      this.toggleWineNamesModal()
    },
    producerSelected(producer) {
      this.chosenProducer = producer
      this.toggleProducersModal()
    },
    newFormatSaved(format) {
      this.$store.dispatch('lists/refreshFormat', {
        force: true
      })
      Vue.set(this.defaultWineCard, 'format_id', format.FormatID)
    }
  }
}

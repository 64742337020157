import Entity from './entity'

const fields = {
  RateType: {
    type: Number
  },
  StartDate: {
    type: String
  },
  RateTypeName: {
    type: String
  },
  RateValue: {
    type: Number
  },
  PercentRate: {
    type: Boolean
  },
  LitreRate: {
    type: Boolean
  }
}

const Rate = new Entity('Rate', fields)

Rate.createQuery('get', 'ratesGetAll', fields)

Rate.createMutation(
  'insert',
  'rateAdd',
  Entity.onlyKeys(fields, ['RateType', 'StartDate', 'RateValue']),
  fields
)
Rate.createMutation(
  'update',
  'rateUpdate',
  Entity.onlyKeys(fields, ['RateType', 'StartDate', 'RateValue']),
  fields
)
Rate.createMutation(
  'delete',
  'rateDelete',
  Entity.onlyKeys(fields, ['RateType', 'StartDate']),
  []
)

export default Rate

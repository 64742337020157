<template>
  <group-box class="h-100" body-class="p-2">
    <template slot="header">
      Sender
    </template>
    <b-row class="m-0 p-0">
      <b-col class="m-0 p-0">
        <b-form-group
          label-size="xs"
          label-cols="4"
          class="m-0"
        >
          <template slot="label">
            <b-radio v-model="emailSender" value="fromCompany">
              Company
            </b-radio>
          </template>
        </b-form-group>
        <b-form-group
          label-size="xs"
          label-cols="4"
          class="m-0"
        >
          <template slot="label">
            <b-radio v-model="emailSender" value="fromSelf">
              Personal
            </b-radio>
          </template>
        </b-form-group>
      </b-col>
      <b-col class="m-0 p-0 text-right">
        <b-form-group
          label-size="xs"
          label-cols="4"
          class="m-0"
        >
          <b-checkbox v-model="CopyToSelf">
            Copy to Self
          </b-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group
      label-size="xs"
      label-cols="4"
      label="Email"
      class="m-0"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="EmailAddress"
          :state="!$v.EmailAddress.$error && null"
        />
        <b-form-invalid-feedback>
          Email Address is invalid
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
    <b-form-group
      label-size="xs"
      label-cols="4"
      :label="nameLabel"
      class="m-0"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="Name"
          :state="!$v.Name.$error && null"
        />
        <b-form-invalid-feedback>
          {{ nameLabel }} is invalid
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import EmailMixin from '~/mixins/email'
import { validationMixin } from 'vuelidate'
import { formObjectMixin } from '~/mixins/forms'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    GroupBox
  },
  mixins: [validationMixin, EmailMixin, formObjectMixin],
  props: {
    nameLabel: {
      type: String,
      default: 'Name'
    }
  },
  data() {
    return {
      emailSender: 'fromCompany'
    }
  },
  computed: {
    fromCompany() {
      return this.emailSender === 'fromCompany'
    },
    Name: {
      get() {
        if (this.fromCompany) {
          return this.getEmailSenderContactDetails().name
        }
        return this.$store.state.user.user.name
      },
      set(value) {
        this.$emit('change-sender-name', value)
      }
    },
    EmailAddress: {
      get() {
        if (this.fromCompany) {
          return this.getEmailSenderContactDetails().email
        }
        return this.$store.state.user.user.email
      },
      set(value) {
        this.$emit('change-sender', value)
      }
    },
    CopyToSelf: {
      get() {
        return this.$store.state.export.email.options.CopyToSelf
      },
      set(value) {
        this.$emit('change-copy-to-self', value)
      }
    }
  },
  watch: {
    emailSender() {
      this.resetData()
    }
  },
  methods: {
    resetData() {
      this.$emit('change-sender-name', this.Name)
      this.$emit('change-sender', this.EmailAddress)
    }
  },
  validations: {
    Name: {
      required
    },
    EmailAddress: {
      required,
      email
    }
  }
}
</script>

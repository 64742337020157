/* eslint-disable no-unused-vars */
import Vue from 'vue'
import { filter, map, difference, findIndex, each, reduce } from 'lodash'
import { getList, getInitialList } from '@/api/wineBrowser/vintages'

export const state = () => ({
  loading: false,
  vintages: [],
  vintageSelectedRange: [],
  selectedVintages: [],
  preYearCutoffOffset: 45,
  loadedInitialList: false,
  hasMerged: false,
  lastRequest: null
})

export const getters = {
  searchParameters: (state, getters) => () => {
    if (state.vintageSelectedRange.length > 0) {
      let firstValue = state.vintageSelectedRange[0]
      if (firstValue === 'PRE') {
        return {
          vintage1: '1900',
          vintage2: getters.getPreVintage
        }
      } else if (firstValue === 'ALL') {
        firstValue = ''
      }
      if (typeof firstValue === 'string' && firstValue.toUpperCase() === 'NV') {
        firstValue = -1
      }
      return {
        vintage1: firstValue,
        vintage2: state.vintageSelectedRange[1] || ''
      }
    }
    return {}
  },
  getPreVintage: state => {
    if (state.vintages.length > 4) {
      return parseInt(state.vintages[state.vintages.length - 4].value)
    }
    return null
  }
}

export const mutations = {
  resetSelection(state) {
    state.vintageSelectedRange = []
    state.selectedVintages = []
  },
  setVintages(state, list) {
    state.vintages = list
    state.hasMerged = false
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoadedInitialList(state, loaded) {
    state.loadedInitialList = loaded === true
  },
  setSelectedVintages(state, vintages) {
    let selectedVintages = filter(state.vintages, (vintage, i) => {
      if (
        vintages.length === 2 &&
        Number(vintage.value) >= Number(vintages[0]) &&
        Number(vintage.value) <= Number(vintages[1])
      ) {
        return true
      }
      if (vintages.length === 1) {
        if (Number(vintages[0]) === Number(vintage.value)) {
          return true
        }
        const preIndex = state.vintages.length - 3
        if (vintages[0] === 'PRE' && i === preIndex) {
          return true
        }
        const nvIndex = state.vintages.length - 2
        if (vintages[0] === 'NV' && i === nvIndex) {
          return true
        }
        const allIndex = state.vintages.length - 1
        if (vintages[0] === 'ALL' && i === allIndex) {
          return true
        }
      }
      return false
    })
    selectedVintages = map(selectedVintages, 'key')
    state.vintageSelectedRange = vintages
    Vue.set(state, 'selectedVintages', selectedVintages)
  },
  clearExtraVintageInfo(state) {
    for (const year of state.vintages) {
      delete year.wineCardCount
      delete year.bottlesStockOnHand
      delete year.hasLooseBottles
      delete year.hasLoosePrice
    }
  },
  mergeVintageList(state, vintages) {
    for (const year of vintages) {
      const idx = state.vintages.findIndex(
        p => Number(p.value) === Number(year.value)
      )
      if (idx > -1) {
        Vue.set(state.vintages[idx], 'wineCardCount', year.wineCardCount)
        Vue.set(
          state.vintages[idx],
          'bottlesStockOnHand',
          year.bottlesStockOnHand
        )
        Vue.set(state.vintages[idx], 'hasLooseBottles', year.hasLooseBottles)
        Vue.set(state.vintages[idx], 'hasLoosePrice', year.hasLoosePrice)
        const key = String(year.value).startsWith('Pre') ? 'PRE' : year.value
        Vue.set(state.vintages[idx], 'key', key)
      }
    }
    state.hasMerged = true
  },
  disableWinesNotInList(state, availableVintages) {
    const gotVintages = map(
      map(state.vintages.slice(0, state.vintages.length - 3), 'value'),
      Number
    )
    const newVintages = map(availableVintages, 'value')
    const diffYears = difference(gotVintages, newVintages)
    each(diffYears, year => {
      const idx = findIndex(state.vintages, v => {
        return Number(v.value) === Number(year)
      })
      const vintage = state.vintages[idx]
      vintage.bottlesStockOnHand = 0
      vintage.hasLooseBottles = 0
      vintage.hasLoosePrice = 0
      vintage.wineCardCount = 0
      Vue.set(state.vintages, idx, vintage)
    })
  },
  setPreYearVintageForAvailable(state, availableVintages) {
    const preYearIndex = state.vintages.length - 3
    const preYearVintage = state.vintages[preYearIndex]

    const vintageValue =
      state.vintages[0] !== undefined ? Number(state.vintages[0].value) : 0
    const preYear = vintageValue - state.preYearCutoffOffset
    const preYearAvailableVintages = filter(
      availableVintages,
      p => p.value <= preYear && p.value > 0
    )

    if (preYearVintage) {
      preYearVintage.bottlesStockOnHand = reduce(
        preYearAvailableVintages,
        (sum, v) => {
          return sum + v.bottlesStockOnHand
        },
        0
      )
      preYearVintage.hasLooseBottles = reduce(
        preYearAvailableVintages,
        (sum, v) => {
          return sum + v.hasLooseBottles
        },
        0
      )
      preYearVintage.hasLoosePrice = reduce(
        preYearAvailableVintages,
        (sum, v) => {
          return sum + v.hasLoosePrice
        },
        0
      )
      preYearVintage.wineCardCount = reduce(
        preYearAvailableVintages,
        (sum, v) => {
          return sum + v.wineCardCount
        },
        0
      )
      Vue.set(state.vintages, preYearIndex, preYearVintage)
    }
  },
  setNonVintageForAvailable(state, availableVintages) {
    const nonVintageIndex = state.vintages.length - 2
    const nonVintage = state.vintages[nonVintageIndex]
    const nonVintageAvailableVintages = filter(
      availableVintages,
      p => p.value <= 0
    )

    if (nonVintage) {
      nonVintage.bottlesStockOnHand = reduce(
        nonVintageAvailableVintages,
        (sum, v) => {
          return sum + v.bottlesStockOnHand
        },
        0
      )
      nonVintage.hasLooseBottles = reduce(
        nonVintageAvailableVintages,
        (sum, v) => {
          return sum + v.hasLooseBottles
        },
        0
      )
      nonVintage.hasLoosePrice = reduce(
        nonVintageAvailableVintages,
        (sum, v) => {
          return sum + v.hasLoosePrice
        },
        0
      )
      nonVintage.wineCardCount = reduce(
        nonVintageAvailableVintages,
        (sum, v) => {
          return sum + v.wineCardCount
        },
        0
      )
      Vue.set(state.vintages, nonVintageIndex, nonVintage)
    }
  },
  setAllVintageForAvailable(state, availableVintages) {
    const allVintageIndex = state.vintages.length - 1
    const allVintage = state.vintages[allVintageIndex]

    if (allVintage) {
      allVintage.bottlesStockOnHand = reduce(
        availableVintages,
        (sum, v) => {
          return sum + v.bottlesStockOnHand
        },
        0
      )
      allVintage.hasLooseBottles = reduce(
        availableVintages,
        (sum, v) => {
          return sum + v.hasLooseBottles
        },
        0
      )
      allVintage.hasLoosePrice = reduce(
        availableVintages,
        (sum, v) => {
          return sum + v.hasLoosePrice
        },
        0
      )
      allVintage.wineCardCount = reduce(
        availableVintages,
        (sum, v) => {
          return sum + v.wineCardCount
        },
        0
      )
      Vue.set(state.vintages, allVintageIndex, allVintage)
    }
  },
  setHasMerged(state, merged) {
    state.hasMerged = merged === true
  },
  setLastRequest(state, request) {
    state.lastRequest = request
  }
}

export const actions = {
  async get({ commit, state }, force) {
    if (state.loadedInitialList && force !== true) {
      return
    }
    commit('setLoading', true)
    try {
      let result = await getInitialList()
      if (result) {
        result = map(result, p => {
          const key = p.startsWith('Pre') ? 'PRE' : p
          return {
            value: p,
            key: key
          }
        })
        result.push({
          value: 'Non Vintage',
          key: 'NV'
        })
        result.push({
          value: 'All Vintages',
          key: 'ALL'
        })
        commit('setVintages', result)
        commit('setLoadedInitialList', true)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async refreshList({ commit, state }, { searchParameters }) {
    commit('clearExtraVintageInfo')
    commit('setLoading', true)
    const lastRequest = JSON.stringify(searchParameters)
    commit('setLastRequest', lastRequest)
    const vintageResults = await getList(searchParameters)
    if (state.lastRequest === lastRequest) {
      commit('mergeVintageList', vintageResults.available || [])
      commit('disableWinesNotInList', vintageResults.available || [])
      commit('setPreYearVintageForAvailable', vintageResults.available || [])
      commit('setNonVintageForAvailable', vintageResults.available || [])
      commit('setAllVintageForAvailable', vintageResults.available || [])
      commit('setLoading', false)
    }
  }
}

<template>
  <group-box :header="true">
    <template slot="header">
      Scores
    </template>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="scores" value="dontShow" class="text-left">
          Don't Show
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="scores" value="orMore" class="text-left">
          85 or more
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="scores" value="showAll" class="text-left">
          Show All
        </d-form-radio>
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'

export default {
  components: {
    GroupBox
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    scores: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :hide-footer="true"
    size="xl"
    @hidden="handleHidden"
    @shown="reset"
    @ok="handleCreateNote"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <template v-slot:default="{ ok }">
      <div v-if="loading" class="text-center text-primary my-2">
        <b-spinner variant="primary" type="grow" label="Spinning" />
      </div>
      <div v-else>
        <b-row>
          <b-col class="col-12 col-xxl-10">
            <note-table
              @edit="handleEditNote"
              @delete="handleDeleteNote"
            />
          </b-col>
          <b-col class="col-12 col-xxl-2">
            <b-button class="btn wv-btn btn-secondary btn-sm mb-2 d-block w-100" @click="shown=false">
              Exit
            </b-button>
            <b-button class="btn wv-btn btn-secondary btn-sm mb-2 d-block w-100" @click="ok()">
              Add Note ...
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import NoteTable from './WineNotes/NoteTable'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'

export default {
  components: {
    NoteTable
  },
  mixins: [TitleFromWineCard],
  props: {
    item: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: false,
      default() {
        return null
      }
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      titleSuffix: 'Notes'
    }
  },
  computed: {
    loading() {
      return this.notesLoading || this.browserCardLoading
    },
    ...mapState('wineCard/notes', {
      notesLoading: state => state.loading,
      notes: state => state.notes
    }),
    browserCardLoading() {
      return this.$store.state.wineCard.activeCard.activeBrowserCardLoading
    },
    hasActiveCard() {
      return this.item
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    handleEditNote(note) {
      this.$store.commit(
        'wineCard/notes/setSelectedNoteId',
        note.WineCardNoteID
      )
      this.$store.commit('modal/setShowWineNotesEditModal', true)
    },
    async handleDeleteNote(note) {
      try {
        await this.$store.dispatch('wineCard/notes/removeNote', note)
        this.$eventHub.$emit('delete-note', note)
      } catch (e) {
        this.$notifyError(e)
      }
    },
    handleCreateNote(e) {
      e.preventDefault()
      this.$store.commit('wineCard/activeCard/setActiveBrowserCard', this.item)
      this.$store.commit('modal/setShowWineNotesCreateModal', true)
      this.$eventHub.$once('create-note', this.handleNoteCreated)
    },
    reset() {
      const id = this.item.id ? this.item.id : this.item.WineCardID
      this.$store.dispatch('wineCard/notes/getNotesForCardId', id)
    },
    handleNoteCreated() {
      this.$eventHub.$off('create-note', this.handleNoteCreated)
      this.shown = false
    },
    handleHidden() {
      this.$eventHub.$off('create-note', this.handleNoteCreated)
      this.$emit('hidden')
    }
  }
}
</script>

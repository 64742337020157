import Entity from './entity'
import PurchaseOrderLine from './purchaseorderline'
import fields from './fields/purchaseOrder'

const PurchaseOrder = new Entity('PurchaseOrder', fields)
PurchaseOrder.createQuery('get', 'purchaseOrderGet')

const deliveryFields = {
  ...Entity.onlyKeys(fields, [
    'POID',
    'OrderDate',
    'CustomerOrderNumber',
    'InternalNotes',
    'PublicNotes',
    'BillingAddressID',
    'Reference'
  ]),
  ContactId: {
    type: Number
  },
  SageSupplierAcctNo: {
    type: String
  },
  WorkflowStatusDescription: {
    type: String
  },
  SageUpdatedDate: {
    type: String
  },
  Initials: {
    type: String
  },
  DPLines: {
    type: Number
  },
  IBLines: {
    type: Number
  },
  IncompleteDPLines: {
    type: Number
  },
  IncompleteIBLines: {
    type: Number
  },
  ShowABV: {
    type: Boolean
  }
}

PurchaseOrder.createMutation(
  'createTransaction',
  'purchaseOrderTransactionCreate',
  {
    WineCardID: {
      type: Number
    },
    FormatID: {
      type: Number
    },
    Bottles: {
      type: Number
    },
    Price: {
      type: Number
    },
    AsDutyPaid: {
      type: Boolean
    },
    WineUsageCodeID: {
      type: Number
    },
    PhotoRequired: {
      type: Boolean
    },
    UseEmptyOWC: {
      type: Boolean
    },
    OriginalPackaging: {
      type: Boolean
    },
    OrigPkgCaseSize: {
      type: Number
    },
    AttachEmptyCases: {
      type: Array
    }
  }
)

PurchaseOrder.createMutation(
  'update',
  'purchaseOrderUpdate',
  {
    ...Entity.onlyKeys(fields, [
      'POID',
      'AuditAmendedDate',
      'OrderDate',
      'ImportID',
      'CountryCode',
      'WorkflowStatusID',
      'PublicNotes',
      'InternalNotes',
      'PaymentTermsID',
      'ContactID',
      'SupplierOrderNumber',
      'AddrID'
    ]),
    ContactEmailAddress: {
      type: String
    },
    ContactTelephone: {
      type: String
    }
  },
  fields
)

PurchaseOrder.createMutation(
  'create',
  'purchaseOrderAdd',
  {
    ...Entity.onlyKeys(fields, [
      'OrderDate',
      'ImportID',
      'CountryCode',
      'WorkflowStatusID',
      'PublicNotes',
      'InternalNotes',
      'PaymentTermsID',
      'ContactID',
      'SupplierOrderNumber',
      'AddrID'
    ]),
    ContactEmailAddress: {
      type: String
    },
    ContactTelephone: {
      type: String
    },
    OrderLine: {
      type: Object,
      fields: PurchaseOrderLine.mutations.add.fields
    }
  },
  fields
)

PurchaseOrder.createMutation(
  'updateSage',
  'purchaseOrderUpdateSage',
  Entity.onlyKeys(fields, ['POID']),
  fields
)

PurchaseOrder.createMutation(
  'delete',
  'purchaseOrderDelete',
  Entity.onlyKeys(fields, ['POID', 'AuditAmendedDate']),
  []
)

PurchaseOrder.createQuery('getByCustomer', 'purchaseOrdersGetByCustomer', {
  current_page: {
    type: Number
  },
  last_page: {
    type: Number
  },
  next_page_url: {
    type: String
  },
  per_page: {
    type: Number
  },
  prev_page_url: {
    type: String
  },
  total: {
    type: Number
  },
  purchaseOrderByCustomerResult: {
    type: Array,
    fields: deliveryFields
  }
})

export default PurchaseOrder

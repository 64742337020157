import Entity from './entity'
import PurchaseOrderFields from './fields/purchaseOrder'
import { fields as allocFields } from './purchaseorderallocation'
import { fields as emptyCaseFields } from './emptycases'

const fields = {
  POLID: {
    type: Number
  },
  POID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  TransactionOrder: {
    type: Number
  },
  WineVintage: {
    type: Number
  },
  ComputedWineName: {
    type: String
  },
  FormatID: {
    type: Number
  },
  FormatName: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  StockTotalBottles: {
    type: Number
  },
  SellingPrice: {
    type: Number
  },
  TransactionTotalBottles: {
    type: Number
  },
  Price: {
    type: Number
  },
  ReservePrice: {
    type: Number
  },
  MarkUp: {
    type: Number
  },
  ReserveMarkUp: {
    type: Number
  },
  Value: {
    type: Number
  },
  ReserveValue: {
    type: Number
  },
  DutyStatusID: {
    type: Number
  },
  IsVAT: {
    type: Boolean
  },
  WineUsageCodeID: {
    type: Number
  },
  WineUsageCode: {
    type: String
  },
  AllocatedTotalBottles: {
    type: Number
  },
  UnallocatedTotalBottles: {
    type: Number
  },
  InventoryAllocationUsage: {
    type: Number
  },
  SOORotationNumber: {
    type: String
  },
  PhotoRequired: {
    type: Boolean
  },
  UseEmptyOWC: {
    type: Boolean
  },
  UserInitials: {
    type: String
  },
  LowMarkUp: {
    type: Number
  },
  FormatCode: {
    type: String
  },
  Packaging: {
    type: Boolean
  },
  OrigPkgCaseSize: {
    type: Number
  },
  OriginalPackaging: {
    type: Boolean
  },
  AlertOWC: {
    type: Boolean
  },
  PhotosMissing: {
    type: Boolean
  },
  IncludesWFS: {
    type: Boolean
  },
  AuditAmendedDate: {
    type: String
  },
  SalesAllocationBottles: {
    type: String
  },
  MaxStockPrice: {
    type: Number
  },
  WineCardAuditAmendedDate: {
    type: String
  },
  WineCardAuditAmendedBy: {
    type: Number
  },
  NoteCount: {
    type: Number
  },
  WineCardNotesString: {
    type: String
  },
  LivExLWinCode: {
    type: Number
  },
  Abv: {
    type: Number
  },
  AbvVerified: {
    type: Number
  },
  EmptyCasesString: {
    type: String
  },
  EmptyCases: {
    type: Array,
    fields: emptyCaseFields
  },
  AvailableEmptyCases: {
    type: Array,
    fields: emptyCaseFields
  },
  MergeKey: {
    type: String
  }
}

const PurchaseOrderTransaction = new Entity('PurchaseOrderTransaction', fields)

PurchaseOrderTransaction.createQuery(
  'get',
  'purchaseOrderTransactionsGet',
  fields
)

PurchaseOrderTransaction.createMutation(
  'updateAll',
  'purchaseOrderLinesUpdate',
  {
    ...Entity.onlyKeys(fields, [
      'POID',
      'DutyStatusID',
      'IsVAT',
      'WineUsageCodeID'
    ])
  },
  {
    Transactions: {
      type: Array,
      fields: {
        ...fields,
        Allocations: {
          type: Array,
          fields: allocFields
        }
      }
    },
    PurchaseOrder: {
      type: Object,
      fields: PurchaseOrderFields
    }
  }
)

PurchaseOrderTransaction.createMutation(
  'merge',
  'purchaseOrderLinesMerge',
  {
    POLID: {
      type: Array
    }
  },
  {
    Transactions: {
      type: Array,
      fields: {
        ...fields,
        Allocations: {
          type: Array,
          fields: allocFields
        }
      }
    },
    PurchaseOrder: {
      type: Object,
      fields: PurchaseOrderFields
    }
  }
)

export default PurchaseOrderTransaction

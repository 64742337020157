import ContactsToReview from '~/models/contactstoreview'
import ContactWinesToReview from '../../models/contactwinestoreview'
import Contact from '../../models/contact'
import { client } from '../graphQLInstance'

export const getContactsToReview = async (page, perPage, searchParameters) => {
  const result = await client.query({
    query: ContactsToReview.query('get', {
      Page: page,
      PerPage: perPage,
      ...searchParameters
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[ContactsToReview.queries.get.name]
}

export const getContactWinesToReview = async args => {
  const result = await client.query({
    query: ContactWinesToReview.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[ContactWinesToReview.queries.get.name]
}

export const skipReviewCycleForContactsBatch = async args => {
  const result = await client.query({
    query: Contact.mutate('skipReviewCycleBatch', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.mutations.skipReviewCycleBatch.name]
}

import Entity from './entity'

const fields = {
  ContactID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  WineVintage: {
    type: Number
  },
  ComputedWineName: {
    type: String
  },
  WineName: {
    type: String
  },
  ProducerName: {
    type: String
  },
  FormatName: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  BottlesAvailable: {
    type: Number
  },
  LastReviewDate: {
    type: String
  },
  LastOrderDate: {
    type: String
  },
  LastOrderNumber: {
    type: String
  },
  HighestPrice: {
    type: Number
  },
  ListPrice: {
    type: Number
  },
  AvgCostPrice: {
    type: Number
  },
  PromotionOption: {
    type: Boolean
  },
  OptionDisplay: {
    type: String
  },
  ParkerScore: {
    type: String
  },
  FormatCode: {
    type: String
  }
}

const CustomerWinesToReview = new Entity('CustomerWinesToReview', fields)
CustomerWinesToReview.createQuery('get', 'customerWinesToReviewGet', fields, {
  ContactID: {
    type: Number
  },
  ContactSearchTerm: {
    type: String
  },
  MinDaysBetweenCustomerPromotions: {
    type: Number
  },
  MinDaysFromAutoCustomerPromotions: {
    type: Number
  },
  MinDaysBetweenWinePromotions: {
    type: Number
  },
  ExistingSageAccount: {
    type: Boolean
  },
  ShowAListOnly: {
    type: Boolean
  },
  Fast: {
    type: Boolean
  },
  HighPriceCheck: {
    type: Number
  }
})

export default CustomerWinesToReview

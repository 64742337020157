import { client } from '../graphQLInstance'
import WebChanges from '../../models/webchanges'

export const getChanges = async args => {
  const result = await client.query({
    query: WebChanges.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebChanges.queries.get.name]
}

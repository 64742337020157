export const saveOpenOrders = orders => {
  window.sessionStorage.setItem('open-po', JSON.stringify(orders))
}

export const getOpenOrders = orders => {
  const openOrders = window.sessionStorage.getItem('open-po')
  try {
    return JSON.parse(openOrders)
  } catch (e) {
    return []
  }
}

export const saveChanges = (POID, changedDetails) => {
  window.sessionStorage.setItem(
    'changed-poid-' + POID,
    JSON.stringify(changedDetails)
  )
}

export const getSavedChanges = POID => {
  const changedDetails = window.sessionStorage.getItem('changed-poid-' + POID)
  try {
    return JSON.parse(changedDetails)
  } catch (e) {
    return null
  }
}

export const removeSavedChanges = POID => {
  window.sessionStorage.removeItem('changed-poid-' + POID)
}

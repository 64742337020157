import { client } from '../graphQLInstance'
import SalesOrder from '~/models/salesorder'
import SalesOrderTransaction from '~/models/salesordertransaction'
import SalesOrderLineItem from '~/models/salesorderlineitem'
import SalesOrderAllocation from '~/models/salesorderallocation'
import SalesOrderDeliveryCost from '~/models/salesorderdeliverycost'

function parseEmail(email) {
  return email
    .split(/[,;\r\n]+/)
    .filter(email => email.trim().length > 0)
    .join(';')
}

export const getSalesOrder = async soid => {
  const result = await client.query({
    query: SalesOrder.query('get', { RecordId: parseInt(soid) })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.queries.get.name]
}

export const getSalesOrderTransactions = async soid => {
  const result = await client.query({
    query: SalesOrderTransaction.query('get', { RecordId: parseInt(soid) })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderTransaction.queries.get.name]
}

export const createSalesOrderWithTransaction = async args => {
  const result = await client.query({
    query: SalesOrder.mutate('createWithTransaction', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.mutations.createWithTransaction.name]
}

export const updateSalesOrderLine = async args => {
  const result = await client.query({
    query: SalesOrderTransaction.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderTransaction.mutations.update.name]
}

export const deleteSalesOrderLine = async ({ SOLID, AuditAmendedDate }) => {
  const result = await client.query({
    query: SalesOrderTransaction.mutate('delete', { SOLID, AuditAmendedDate })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderTransaction.mutations.delete.name]
}

export const getAllocationsForSalesOrder = async soid => {
  const result = await client.query({
    query: SalesOrderAllocation.query('getForSOID', {
      SOID: parseInt(soid)
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderAllocation.queries.getForSOID.name]
}

export const getAllocationsForSalesOrderLine = async (solid, isReserves) => {
  const result = await client.query({
    query: SalesOrderAllocation.query('getForSOLID', {
      RecordId: parseInt(solid),
      IsReserves: isReserves === true
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderAllocation.queries.getForSOLID.name]
}

export const deleteSalesOrderAllocation = async ({
  SAID,
  AuditAmendedDate
}) => {
  const result = await client.query({
    query: SalesOrderAllocation.mutate('delete', {
      SAID,
      AuditAmendedDate
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderAllocation.mutations.delete.name]
}

export const deleteSalesOrder = async args => {
  const result = await client.query({
    query: SalesOrder.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.mutations.delete.name]
}

export const deleteSalesOrderDocument = async args => {
  const result = await client.query({
    query: SalesOrder.mutate('deleteDocument', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.mutations.deleteDocument.name]
}

export const updateSalesOrderAllocation = async args => {
  const result = await client.query({
    query: SalesOrderAllocation.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderAllocation.mutations.update.name]
}

export const addSalesOrderAllocation = async args => {
  const result = await client.query({
    query: SalesOrderAllocation.mutate('create', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderAllocation.mutations.create.name]
}

export const addSalesOrderLine = async args => {
  const result = await client.query({
    query: SalesOrderTransaction.mutate('create', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderTransaction.mutations.create.name]
}

export const updateSalesOrder = async args => {
  if (args.DeliveryEmail) {
    args.DeliveryEmail = parseEmail(args.DeliveryEmail)
  }
  const result = await client.query({
    query: SalesOrder.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.mutations.update.name]
}

export const createSalesOrder = async args => {
  if (args.DeliveryEmail) {
    args.DeliveryEmail = parseEmail(args.DeliveryEmail)
  }
  const result = await client.query({
    query: SalesOrder.mutate('create', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.mutations.create.name]
}

export const getDeliveryCost = async args => {
  const result = await client.query({
    query: SalesOrderDeliveryCost.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderDeliveryCost.queries.get.name]
}

export const updateSage = async args => {
  const result = await client.query({
    query: SalesOrder.mutate('updateSage', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.mutations.updateSage.name]
}

export const reserveCreateFromSalesOrder = async args => {
  const result = await client.query({
    query: SalesOrder.mutate('reserveCreateFromSalesOrder', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.mutations.reserveCreateFromSalesOrder.name]
}

export const shippingUpdateBatch = async args => {
  const result = await client.query({
    query: SalesOrder.mutate('shippingUpdateBatch', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.mutations.shippingUpdateBatch.name]
}

export const shippingUpdatePaidBatch = async args => {
  const result = await client.query({
    query: SalesOrder.mutate('shippingUpdatePaidBatch', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.mutations.shippingUpdatePaidBatch.name]
}

export const getShippingDocumentGet = async soid => {
  const result = await client.query({
    query: SalesOrder.query('shippingDocumentGet', {
      SOID: parseInt(soid)
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.queries.shippingDocumentGet.name]
}

export const getSalesOrderLineItems = async soid => {
  const result = await client.query({
    query: SalesOrderLineItem.query('get', { RecordId: parseInt(soid) })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderLineItem.queries.get.name]
}

export const updateSalesOrderLineItem = async args => {
  const result = await client.query({
    query: SalesOrderLineItem.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderLineItem.mutations.update.name]
}

import {
  getFormats,
  addFormat,
  updateFormat,
  deleteFormat
} from '@/api/administration/formats'
import { find, findIndex, merge } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  formatLoading: false,
  list: [],
  format: null,
  searchTerm: ''
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setFormatLoading(state, loading) {
    state.formatLoading = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  resetList(state) {
    state.list = []
  },
  setActiveFormat(state, { FormatID }) {
    state.format = find(state.list, { FormatID })
  },
  setSearchTerm(state, searchTerm) {
    state.searchTerm = searchTerm
  },
  removeFormatFromList(state, { FormatID }) {
    const idx = findIndex(state.list, { FormatID })
    if (idx >= 0) {
      if (state.format && state.format.FormatID === FormatID) {
        state.format = null
      }
      Vue.delete(state.list, idx)
    }
  },
  updateFormatData(state, format) {
    const idx = findIndex(state.list, { FormatID: format.FormatID })
    if (idx >= 0) {
      const mergedFormat = merge({}, state.list[idx], format)
      Vue.set(state.list, idx, mergedFormat)
    }
  },
  addFormatToList(state, format) {
    state.list.push(format)
  }
}

export const getters = {
  getFormatList(state) {
    return state.list
  },
  getActiveFormat(state) {
    return state.format
  }
}

export const actions = {
  async loadList({ commit, state }) {
    commit('resetList')
    commit('setLoading', true)
    const response = await getFormats(state.searchTerm)
    commit('setList', response)
    commit('setLoading', false)
  },
  setActiveFormat({ commit }, format) {
    commit('setActiveFormat', format)
  },
  async deleteFormat({ commit }, format) {
    commit('setFormatLoading', true)
    try {
      await deleteFormat(format)
      commit('removeFormatFromList', format)
    } catch (e) {
      throw e
    } finally {
      commit('setFormatLoading', false)
    }
  },
  async addFormat({ commit }, format) {
    commit('setFormatLoading', true)
    try {
      const response = await addFormat(format)
      commit('addFormatToList', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setFormatLoading', false)
    }
  },
  async updateFormat({ commit }, format) {
    commit('setFormatLoading', true)
    try {
      const response = await updateFormat(format)
      commit('updateFormatData', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setFormatLoading', false)
    }
  }
}

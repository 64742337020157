import LWINDictionary from '../models/lwindictionary'
import { client } from './graphQLInstance'

export const search = async args => {
  const result = await client.query({
    query: LWINDictionary.query('search', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[LWINDictionary.queries.search.name]
}

export const importCode = async args => {
  const result = await client.query({
    query: LWINDictionary.mutate('import', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[LWINDictionary.mutations.import.name]
}

import Entity from './entity'

const fields = {
  ContactID: {
    type: Number
  },
  WarehouseAddrID: {
    type: Number
  },
  WarehouseDescription: {
    type: String
  },
  WarehouseAccount: {
    type: String
  }
}

const WarehouseAccount = new Entity('WarehouseAccount', fields)

WarehouseAccount.createQuery('getAll', 'contactWarehouseGetAll', fields)

WarehouseAccount.createMutation(
  'update',
  'contactWarehouseUpdate',
  Entity.allButKeys(fields, ['WarehouseDescription']),
  []
)

export default WarehouseAccount

import { getEntityAccess } from '@/api/quicksearch/quicksearch'
import Vue from 'vue'
export const state = () => ({
  loading: {},
  list: {},
  searching: {},
  searchTerms: {}
})

export const getters = {
  getList(state) {
    return id => {
      return state.list[id]
    }
  },
  isLoading(state) {
    return id => {
      return state.loading[id]
    }
  },
  isSearching(state) {
    return id => {
      return state.searching[id]
    }
  },
  getSearchTerm: state => entityId => {
    return state.searchTerms[entityId] || ''
  }
}

export const mutations = {
  setLoading(state, { entityType, loading }) {
    Vue.set(state.loading, entityType, loading === true)
  },
  setList(state, { entityType, list }) {
    Vue.set(state.list, entityType, list)
  },
  setSearching(state, { entityType, searching }) {
    Vue.set(state.searching, entityType, searching === true)
  },
  setSearchTerm(state, { entityType, search }) {
    Vue.set(state.searchTerms, entityType, search)
  }
}

export const actions = {
  async getEntityAccess({ commit }, { topN, entityType }) {
    commit('setLoading', { entityType, loading: true })
    try {
      const response = await getEntityAccess({ topN, entityType })
      if (response) {
        commit('setList', { entityType, list: response })
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', { entityType, loading: false })
    }
  }
}

import { client } from '../graphQLInstance'
import OAuthClients from '../../models/oauthclients'

export const getAllOAuth = async args => {
  const result = await client.query({
    query: OAuthClients.query('getAll', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[OAuthClients.queries.getAll.name]
}

export const getAllPermissions = async args => {
  const result = await client.query({
    query: OAuthClients.query('getPermissions', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[OAuthClients.queries.getPermissions.name]
}

export const addClient = async args => {
  const result = await client.query({
    query: OAuthClients.mutate('add', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[OAuthClients.mutations.add.name]
}

export const updateClient = async args => {
  const result = await client.query({
    query: OAuthClients.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[OAuthClients.mutations.update.name]
}

export const deleteClient = async args => {
  const result = await client.query({
    query: OAuthClients.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[OAuthClients.mutations.delete.name]
}

export const changeSecret = async args => {
  const result = await client.query({
    query: OAuthClients.mutate('changeSecret', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[OAuthClients.mutations.changeSecret.name]
}

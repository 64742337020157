import { keys, has, each } from 'lodash'
export const adaptWineBrowserSearchParameters = params => {
  const wineBrowserSearchParametersMap = {
    term: 'SearchTerm',
    format: 'FormatId',
    usage: 'UsageId',
    from: 'ValueFrom',
    to: 'ValueTo',
    greater: 'CasesFrom',
    less: 'CasesTo',
    parker: 'ParkerScore',
    burghound: 'BurghoundScore',
    categories: 'CategoryId',
    appellations: 'AppellationId',
    vintage1: 'Vintage',
    vintage2: 'Vintage2',
    wines: 'WineNameSearchID',
    zero: 'HideZeroStock',
    countryMultiple: 'CountryIdMultiple',
    regionMultiple: 'CountryRegionIdMultiple',
    subRegionMultiple: 'CountrySubRegionIdMultiple',
    producerMultiple: 'ProducerIdMultiple',
    customer: 'ContactID',
    looseBottlesOnly: 'LooseBottlesOnly',
    loosePriceOnly: 'LoosePriceOnly'
  }

  const searchParams = {}
  each(keys(wineBrowserSearchParametersMap), k => {
    if (has(params, k)) {
      searchParams[wineBrowserSearchParametersMap[k]] = params[k]
      if (k === 'vintages') {
        searchParams[wineBrowserSearchParametersMap[k]] = params[k].join(',')
      }
    }
  })
  return searchParams
}

export const adaptWineCardForAPI = clientData => {
  const adaptedObject = {}
  const keys = Object.keys(clientData)
  if (keys.indexOf('id') >= 0) {
    adaptedObject.WineCardID = clientData.id
  }
  if (keys.indexOf('listPrice') >= 0) {
    adaptedObject.WineSellingPrice = clientData.listPrice
  }
  if (keys.indexOf('auditAmendedDate') >= 0) {
    adaptedObject.AuditAmendedDate = clientData.auditAmendedDate
  }
  if (keys.indexOf('rp') >= 0) {
    adaptedObject.WineParkerScore = clientData.rp
  }
  if (keys.indexOf('wineNameId') >= 0) {
    adaptedObject.WineNameID = clientData.wineNameId
  }
  if (keys.indexOf('producerId') >= 0) {
    adaptedObject.ProducerID = clientData.producerId
  }
  if (keys.indexOf('year') >= 0) {
    adaptedObject.WineVintage = clientData.year
    if (isNaN(parseInt(adaptedObject.WineVintage))) {
      adaptedObject.WineVintage = 0
    }
  }
  if (keys.indexOf('format_id') >= 0) {
    adaptedObject.FormatID = clientData.format_id
  }
  if (keys.indexOf('categoryId') >= 0) {
    adaptedObject.CategoryID = clientData.categoryId
  }
  if (keys.indexOf('countryId') >= 0) {
    adaptedObject.CountryID = clientData.countryId
  }
  if (keys.indexOf('percentage') >= 0) {
    adaptedObject.WinePercentOH = clientData.percentage
  }
  if (keys.indexOf('ph') >= 0) {
    adaptedObject.WinePH = clientData.ph
  }
  if (keys.indexOf('extract') >= 0) {
    adaptedObject.WineExtract = clientData.extract
  }
  if (keys.indexOf('so2') >= 0) {
    adaptedObject.WineSO2 = clientData.so2
  }
  if (keys.indexOf('fda') >= 0) {
    adaptedObject.WineFDA = clientData.fda
  }
  if (keys.indexOf('rpTastingNotes') >= 0) {
    adaptedObject.WineParkerTastingNotes = clientData.rpTastingNotes
  }
  if (keys.indexOf('bh') >= 0) {
    adaptedObject.WineBurghoundScore = clientData.bh
  }
  if (keys.indexOf('bhTastingNotes') >= 0) {
    adaptedObject.WineBurghoundTastingNotes = clientData.bhTastingNotes
  }
  if (keys.indexOf('description') >= 0) {
    adaptedObject.WineDescription = clientData.description
  }
  if (keys.indexOf('bookPrice') >= 0) {
    adaptedObject.WineBookPrice = clientData.bookPrice
  }
  if (keys.indexOf('usageCodeId') >= 0) {
    adaptedObject.WineUsageCodeID = clientData.usageCodeId
  }
  if (keys.indexOf('colourId') >= 0) {
    adaptedObject.WineColourID = clientData.colourId
  }
  if (keys.indexOf('promotion_date') >= 0) {
    adaptedObject.PromotionDate = clientData.promotion_date
  }
  if (keys.indexOf('doNotPromote') >= 0) {
    adaptedObject.DoNotPromote = clientData.doNotPromote
  }
  if (keys.indexOf('sellAtLoss') >= 0) {
    adaptedObject.OverrideLossCheck = clientData.sellAtLoss
  }
  if (keys.indexOf('livExLwin') >= 0) {
    adaptedObject.LivExLWinCode = clientData.livExLwin
  }
  if (keys.indexOf('mixedCase') >= 0) {
    adaptedObject.MixedCase = clientData.mixedCase
  }
  if (keys.indexOf('maxStockPrice') >= 0) {
    adaptedObject.MaxStockPrice = clientData.maxStockPrice
  }
  if (keys.indexOf('dutyTypeId') >= 0) {
    adaptedObject.DutyTypeID = clientData.dutyTypeId
  }
  if (keys.indexOf('reserve_level') >= 0) {
    adaptedObject.ReservedBottles =
      clientData.reserve_level.cases * clientData.case_size +
      clientData.reserve_level.bottles
  }
  if (keys.indexOf('clientData.producer') >= 0) {
    adaptedObject.ProducerName = clientData.producer
  }
  if (keys.indexOf('wine_name') >= 0) {
    adaptedObject.WineName = clientData.wine_name
  }
  if (keys.indexOf('DesignationID') >= 0) {
    adaptedObject.DesignationID = clientData.DesignationID
  }
  if (keys.indexOf('ClassificationID') >= 0) {
    adaptedObject.ClassificationID = clientData.ClassificationID
  }
  if (keys.indexOf('CountryRegionID') >= 0) {
    adaptedObject.CountryRegionID = clientData.CountryRegionID
  }
  if (keys.indexOf('CountrySubRegionID') >= 0) {
    adaptedObject.CountrySubRegionID = clientData.CountrySubRegionID
  }
  if (keys.indexOf('Exception') >= 0) {
    adaptedObject.Exception = clientData.Exception
  }
  if (keys.indexOf('VineyardID') >= 0) {
    adaptedObject.VineyardID = clientData.VineyardID
  }
  if (keys.indexOf('LivExLWinExempt') >= 0) {
    adaptedObject.LivExLWinExempt = clientData.LivExLWinExempt
  }
  if (keys.indexOf('LinkedPurchaseOrderLinesUsage') >= 0) {
    adaptedObject.LinkedPurchaseOrderLinesUsage =
      clientData.LinkedPurchaseOrderLinesUsage
  }
  if (keys.indexOf('LinkedSalesOrderLinesUsage') >= 0) {
    adaptedObject.LinkedSalesOrderLinesUsage =
      clientData.LinkedSalesOrderLinesUsage
  }
  if (keys.indexOf('LinkedCardCount') >= 0) {
    adaptedObject.LinkedCardCount = clientData.LinkedCardCount
  }
  if (keys.indexOf('AbvVerified') >= 0) {
    adaptedObject.AbvVerified = clientData.AbvVerified
  }
  if (keys.indexOf('AbvVerifiedByName') >= 0) {
    adaptedObject.AbvVerifiedByName = clientData.AbvVerifiedByName
  }
  if (keys.indexOf('MixedCaseDefaultSize') >= 0) {
    adaptedObject.MixedCaseDefaultSize = clientData.MixedCaseDefaultSize
  }
  if (keys.indexOf('looseBottlePrice') >= 0) {
    adaptedObject.LooseBottlePrice = clientData.looseBottlePrice
  }
  return adaptedObject
}

export const adaptWineCardFromAPI = apiData => {
  const adaptedObject = {}
  if (apiData === null) {
    return null
  }
  const keys = Object.keys(apiData)
  if (keys.indexOf('WineCardID') >= 0) {
    adaptedObject.id = apiData.WineCardID
  }
  if (keys.indexOf('ComputedWineName') >= 0) {
    adaptedObject.ComputedWineName = apiData.ComputedWineName
  }
  if (keys.indexOf('WineName') >= 0) {
    adaptedObject.wine_name = apiData.WineName
  }
  if (keys.indexOf('WineNameID') >= 0) {
    adaptedObject.wineNameId = apiData.WineNameID
  }
  if (keys.indexOf('WineVintage') >= 0) {
    adaptedObject.year = apiData.WineVintage
  }
  if (keys.indexOf('ProducerName') >= 0) {
    adaptedObject.producer = apiData.ProducerName
  }
  if (keys.indexOf('ProducerID') >= 0) {
    adaptedObject.producerId = apiData.ProducerID
  }
  if (keys.indexOf('CategoryID') >= 0) {
    adaptedObject.categoryId = apiData.CategoryID
  }
  if (keys.indexOf('CountryID') >= 0) {
    adaptedObject.countryId = apiData.CountryID
  }
  if (keys.indexOf('WinePercentOH') >= 0) {
    adaptedObject.percentage = apiData.WinePercentOH
  }
  if (keys.indexOf('WinePH') >= 0) {
    adaptedObject.ph = apiData.WinePH
  }
  if (keys.indexOf('WineSO2') >= 0) {
    adaptedObject.so2 = apiData.WineSO2
  }
  if (keys.indexOf('WineFDA') >= 0) {
    adaptedObject.fda = apiData.WineFDA
  }
  if (keys.indexOf('WineParkerTastingNotes') >= 0) {
    adaptedObject.rpTastingNotes = apiData.WineParkerTastingNotes
  }
  if (keys.indexOf('WineBurghoundTastingNotes') >= 0) {
    adaptedObject.bhTastingNotes = apiData.WineBurghoundTastingNotes
  }
  if (keys.indexOf('WineDescription') >= 0) {
    adaptedObject.description = apiData.WineDescription
  }
  if (keys.indexOf('WineBookPrice') >= 0) {
    adaptedObject.bookPrice = apiData.WineBookPrice
  }
  if (keys.indexOf('WineUsageCodeID') >= 0) {
    adaptedObject.usageCodeId = apiData.WineUsageCodeID
  }
  if (keys.indexOf('WineColourID') >= 0) {
    adaptedObject.colourId = apiData.WineColourID
  }
  if (keys.indexOf('DoNotPromote') >= 0) {
    adaptedObject.doNotPromote = apiData.DoNotPromote
  }
  if (keys.indexOf('LivExLWinCode') >= 0) {
    adaptedObject.livExLwin = apiData.LivExLWinCode
  }
  if (keys.indexOf('MixedCase') >= 0) {
    adaptedObject.mixedCase = apiData.MixedCase
  }
  if (keys.indexOf('WineExtract') >= 0) {
    adaptedObject.extract = apiData.WineExtract
  }
  if (keys.indexOf('WineParkerScore') >= 0) {
    adaptedObject.rp = apiData.WineParkerScore
  }
  if (keys.indexOf('WineBurghoundScore') >= 0) {
    adaptedObject.bh = apiData.WineBurghoundScore
  }
  if (keys.indexOf('FormatName') >= 0) {
    adaptedObject.format = apiData.FormatName
  }
  if (keys.indexOf('FormatID') >= 0) {
    adaptedObject.format_id = apiData.FormatID
  }
  if (keys.indexOf('FormatCode') >= 0) {
    adaptedObject.format_code = apiData.FormatCode
  }
  if (keys.indexOf('UsageCode') >= 0) {
    adaptedObject.code = apiData.UsageCode
  }
  if (keys.indexOf('NoteCount') >= 0) {
    adaptedObject.note_count = apiData.NoteCount
  }
  if (keys.indexOf('OverrideLossCheck') >= 0) {
    adaptedObject.sellAtLoss = apiData.OverrideLossCheck
  }
  if (keys.indexOf('WinePriceWeightedAverage') >= 0) {
    adaptedObject.price_weighted_average = apiData.WinePriceWeightedAverage
  }
  if (keys.indexOf('CaseSize') >= 0) {
    adaptedObject.case_size = apiData.CaseSize
  }
  if (keys.indexOf('LastSOID') >= 0) {
    adaptedObject.last_sales_order_id = apiData.LastSOID
  }
  if (keys.indexOf('LastOrderDate') >= 0) {
    adaptedObject.last_order_date = apiData.LastOrderDate
  }
  if (keys.indexOf('LastPrice') >= 0) {
    adaptedObject.last_price = apiData.LastPrice
  }
  if (keys.indexOf('LastSalesContact') >= 0) {
    adaptedObject.last_sales_contact = apiData.LastSalesContact
  }
  if (keys.indexOf('LastPOID') >= 0) {
    adaptedObject.last_purchase_order_id = apiData.LastPOID
  }
  if (keys.indexOf('LastPurchasePrice') >= 0) {
    adaptedObject.last_purchase_price = apiData.LastPurchasePrice
  }
  if (keys.indexOf('LastPurchaseDate') >= 0) {
    adaptedObject.last_purchase_date = apiData.LastPurchaseDate
  }
  if (keys.indexOf('LastPurchaseContact') >= 0) {
    adaptedObject.last_purchase_contact = apiData.LastPurchaseContact
  }
  if (keys.indexOf('EmptyCases') >= 0) {
    adaptedObject.empty_cases = apiData.EmptyCases
  }
  if (keys.indexOf('TotalBottles') >= 0) {
    adaptedObject.total_bottles = apiData.TotalBottles
  }
  if (keys.indexOf('PurchaseOrderLinesUsage') >= 0) {
    adaptedObject.purchase_order_line_usage = apiData.PurchaseOrderLinesUsage
  }
  if (keys.indexOf('SalesOrderLinesUsage') >= 0) {
    adaptedObject.sales_order_line_usage = apiData.SalesOrderLinesUsage
  }
  if (keys.indexOf('PhotoRequired') >= 0) {
    adaptedObject.photo_required = apiData.PhotoRequired
  }
  if (keys.indexOf('SomeStockLoss') >= 0) {
    adaptedObject.some_stock_loss = apiData.SomeStockLoss
  }
  if (keys.indexOf('CaseSize') >= 0) {
    if (keys.indexOf('DutyPaidBottles') >= 0) {
      adaptedObject.duty_paid_stock = {
        bottles: apiData.DutyPaidBottles % apiData.CaseSize,
        cases: Math.floor(apiData.DutyPaidBottles / apiData.CaseSize)
      }
    }
    if (keys.indexOf('InBondBottles') >= 0) {
      adaptedObject.in_bond_stock = {
        bottles: apiData.InBondBottles % apiData.CaseSize,
        cases: Math.floor(apiData.InBondBottles / apiData.CaseSize)
      }
    }
    if (keys.indexOf('UnallocatedSalesBottles') >= 0) {
      adaptedObject.unallocated_sales = {
        bottles: apiData.UnallocatedSalesBottles % apiData.CaseSize,
        cases: Math.floor(apiData.UnallocatedSalesBottles / apiData.CaseSize)
      }
    }
    if (keys.indexOf('OversoldBottles') >= 0) {
      adaptedObject.oversold_quantity = {
        bottles: apiData.OversoldBottles % apiData.CaseSize,
        cases: Math.floor(apiData.OversoldBottles / apiData.CaseSize)
      }
    }
    if (keys.indexOf('AvailableBottles') >= 0) {
      adaptedObject.available_quantity = {
        bottles: apiData.AvailableBottles % apiData.CaseSize,
        cases: Math.floor(apiData.AvailableBottles / apiData.CaseSize)
      }
      adaptedObject.availableBottles = apiData.AvailableBottles
    }
    if (keys.indexOf('ReservesBottles') >= 0) {
      adaptedObject.reserves_quantity = {
        bottles: apiData.ReservesBottles % apiData.CaseSize,
        cases: Math.floor(apiData.ReservesBottles / apiData.CaseSize)
      }
      adaptedObject.reservesBottles = apiData.ReservesBottles
    }
    if (keys.indexOf('BrokingBottles') >= 0) {
      adaptedObject.broking_quantity = {
        bottles: apiData.BrokingBottles % apiData.CaseSize,
        cases: Math.floor(apiData.BrokingBottles / apiData.CaseSize)
      }
      adaptedObject.BrokingBottles = apiData.BrokingBottles
    }
    if (keys.indexOf('ReservedBottles') >= 0) {
      adaptedObject.reserve_level = {
        bottles: apiData.ReservedBottles % apiData.CaseSize,
        cases: Math.floor(apiData.ReservedBottles / apiData.CaseSize)
      }
      adaptedObject.reservedBottles = apiData.ReservedBottles
    }
  }

  if (keys.indexOf('WineCostPrice') >= 0) {
    adaptedObject.average_cost = apiData.WineCostPrice
  }
  if (keys.indexOf('LooseCostPrice') >= 0) {
    adaptedObject.average_loose_cost = apiData.LooseCostPrice
  }
  if (keys.indexOf('WineSellingPrice') >= 0) {
    adaptedObject.listPrice = apiData.WineSellingPrice
  }
  if (keys.indexOf('AverageDaysInStock') >= 0) {
    adaptedObject.average_days_in_stock = apiData.AverageDaysInStock
  }
  if (keys.indexOf('WineCardNotesString') >= 0) {
    adaptedObject.wine_card_notes_string = apiData.WineCardNotesString
  }
  if (keys.indexOf('PromotionDate') >= 0) {
    if (apiData.PromotionDate !== '1900-01-01T00:00:00') {
      adaptedObject.promotion_date = apiData.PromotionDate
    } else {
      adaptedObject.promotion_date = null
    }
  }
  if (keys.indexOf('EmptyCaseInfo') >= 0) {
    adaptedObject.emptyCaseInfo = apiData.EmptyCaseInfo
  }
  if (keys.indexOf('AuditAmendedDate') >= 0) {
    adaptedObject.auditAmendedDate = apiData.AuditAmendedDate
  }
  if (keys.indexOf('MaxStockPrice') >= 0) {
    adaptedObject.maxStockPrice = apiData.MaxStockPrice
  }
  if (keys.indexOf('DutyTypeID') >= 0) {
    adaptedObject.dutyTypeId = apiData.DutyTypeID
  }
  if (keys.indexOf('DesignationID') >= 0) {
    adaptedObject.DesignationID = apiData.DesignationID
  }
  if (keys.indexOf('ClassificationID') >= 0) {
    adaptedObject.ClassificationID = apiData.ClassificationID
  }
  if (keys.indexOf('CountryRegionID') >= 0) {
    adaptedObject.CountryRegionID = apiData.CountryRegionID
  }
  if (keys.indexOf('CountrySubRegionID') >= 0) {
    adaptedObject.CountrySubRegionID = apiData.CountrySubRegionID
  }
  if (keys.indexOf('Exception') >= 0) {
    adaptedObject.Exception = apiData.Exception
  }
  if (keys.indexOf('VineyardID') >= 0) {
    adaptedObject.VineyardID = apiData.VineyardID
  }
  if (keys.indexOf('LivExLWinCode') >= 0) {
    adaptedObject.LivExLWinCode = apiData.LivExLWinCode
  }
  if (keys.indexOf('LivExLWinExempt') >= 0) {
    adaptedObject.LivExLWinExempt = apiData.LivExLWinExempt
  }
  if (keys.indexOf('LinkedPurchaseOrderLinesUsage') >= 0) {
    adaptedObject.LinkedPurchaseOrderLinesUsage =
      apiData.LinkedPurchaseOrderLinesUsage
  }
  if (keys.indexOf('LinkedSalesOrderLinesUsage') >= 0) {
    adaptedObject.LinkedSalesOrderLinesUsage =
      apiData.LinkedSalesOrderLinesUsage
  }
  if (keys.indexOf('LinkedCardCount') >= 0) {
    adaptedObject.LinkedCardCount = apiData.LinkedCardCount
  }
  if (keys.indexOf('AbvVerified') >= 0) {
    adaptedObject.AbvVerified = apiData.AbvVerified
  }
  if (keys.indexOf('AbvVerifiedByName') >= 0) {
    adaptedObject.AbvVerifiedByName = apiData.AbvVerifiedByName
  }
  if (keys.indexOf('MixedCaseDefaultSize') >= 0) {
    adaptedObject.MixedCaseDefaultSize = apiData.MixedCaseDefaultSize
  }
  if (keys.indexOf('LooseBottlePrice') >= 0) {
    adaptedObject.looseBottlePrice = apiData.LooseBottlePrice
  }
  if (keys.indexOf('HasLooseBottles') >= 0) {
    adaptedObject.hasLooseBottles = apiData.HasLooseBottles
  }
  adaptedObject._original = apiData
  return adaptedObject
}

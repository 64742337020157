import WineCardNote from '~/models/winecardnote'
import { client } from '../graphQLInstance'

export const getNotesForCardId = async WineCardID => {
  const result = await client.query({
    query: WineCardNote.query('getByWineCard', { WineCardID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return {
    notes: result.data[WineCardNote.queries.getByWineCard.name]
  }
}

export const getAllNotes = async query => {
  const result = await client.query({
    query: WineCardNote.query('getAll', query)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return {
    notes: result.data[WineCardNote.queries.getAll.name]
  }
}

export const createNote = async payload => {
  const result = await client.query({
    query: WineCardNote.mutate('create', payload)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return {
    note: result.data[WineCardNote.mutations.create.name]
  }
}

export const updateNote = async payload => {
  const result = await client.query({
    query: WineCardNote.mutate('update', payload)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return {
    note: result.data[WineCardNote.mutations.update.name]
  }
}

export const deleteNoteById = async WineCardNoteID => {
  const result = await client.query({
    query: WineCardNote.mutate('delete', { WineCardNoteID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return {
    note: result.data[WineCardNote.mutations.delete.name]
  }
}

import Entity from './entity'
export const fields = {
  CountryID: {
    type: Number
  },
  CountryName: {
    type: String
  },
  UseOnBackOffice: {
    type: Boolean
  }
}

const CountryDropDown = new Entity('CountryDropDown', fields)
CountryDropDown.createQuery('get', 'countryDropDown')

CountryDropDown.createMutation(
  'delete',
  'countryDelete',
  {
    CountryID: fields.CountryID
  },
  []
)
CountryDropDown.createMutation('insert', 'countryAdd', fields)
CountryDropDown.createMutation('update', 'countryUpdate', fields)

export default CountryDropDown

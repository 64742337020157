import Entity from './entity'

const fields = {
  PaymentTerms: {
    type: String
  },
  PaymentTermsID: {
    type: Number
  }
}

const PaymentTermsDropDown = new Entity('PaymentTermsDropDown', fields)
PaymentTermsDropDown.createQuery('get', 'paymentTermsDropDown')

export default PaymentTermsDropDown

import Entity from './entity'

const fields = {
  UUID: {
    type: String
  },
  WineCardID: {
    type: Number
  },
  WineVintage: {
    type: Number
  },
  ComputedWineName: {
    type: String
  },
  ParkerSortValue: {
    type: String
  },
  WineParkerScore: {
    type: String
  },
  FormatName: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  ExcludeFlag: {
    type: Boolean
  },
  AllBottles: {
    type: Number
  },
  IncludeAll: {
    type: Boolean
  },
  AvailableBottles: {
    type: Number
  },
  WholeCases: {
    type: Boolean
  },
  SplitCases: {
    type: Boolean
  },
  DisplayedBottles: {
    type: Number
  },
  WineSellingPrice: {
    type: Number
  },
  SellingPrice: {
    type: Number
  },
  LooseBottlePrice: {
    type: Number
  },
  OverrideMarginCheck: {
    type: Boolean
  },
  AvailableAvgCostPrice: {
    type: Number
  },
  AllAvgCostPrice: {
    type: Number
  },
  EURPrice: {
    type: Number
  },
  HKDPrice: {
    type: Number
  },
  ExcludeSort: {
    type: Boolean
  },
  Category: {
    type: String
  },
  CategorySequence: {
    type: Number
  },
  FormatCode: {
    type: String
  },
  Volume: {
    type: Number
  },
  LastReceiptDate: {
    type: String
  },
  WineSearcherSingle: {
    type: String
  },
  WineSearcherMultiple: {
    type: String
  },
  MixedCase: {
    type: Boolean
  },
  Repack: {
    type: String
  },
  FormattedVolume: {
    type: String
  },
  FormatCaseSize: {
    type: Number
  }
}

const AvailableStock = new Entity('AvailableStock', fields)
AvailableStock.createQuery('get', 'availableStockGet')

export default AvailableStock

import Vue from 'vue'
import { findIndex } from 'lodash'
import { returnTallyLine, searchReturnTallyLines } from '../../api/etally'

export const state = () => ({
  perPage: 100,
  page: 1,
  results: [],
  total: 0,
  sortBy: 'ETCID',
  sortDirection: 'DESC',
  loading: false,
  loadedPage: 0
})

export const getters = {
  pagedResults: state => {
    return state.results.slice(0, state.page * state.perPage)
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  appendResults(state, results) {
    state.results = [].concat(state.results).concat(results)
  },
  reset(state) {
    state.results = []
    state.page = 1
    state.total = 0
    state.loadedPage = 0
    state.sortBy = 'ETCID'
    state.sortDirection = 'DESC'
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setLoadedPage(state, loadedPage) {
    state.loadedPage = loadedPage
  },
  setTableSort(state, sort) {
    state.sortBy = sort.sortBy
    state.sortDirection = sort.sortDesc ? 'DESC' : 'ASC'
  },
  setReturnHandled(state, ETCLID) {
    const idx = findIndex(state.results, { ETCLID })
    // console.log('found', idx)
    if (idx >= 0) {
      const line = state.results[idx]
      line.ReturnHandled = true
      Vue.set(state.results, idx, line)
    }
  }
}

export const actions = {
  async search({ commit, state }, fields) {
    commit('setLoading', true)
    try {
      const cards = await searchReturnTallyLines({
        PerPage: state.perPage,
        Page: state.page,
        OrderBy: state.sortBy,
        OrderByDirection: state.sortDirection,
        ...fields
      })
      if (cards) {
        commit('setTotal', cards.total)
        commit('appendResults', cards.etallyReturnedLineResult)
        commit('setLoadedPage', 1)
      }
      return cards
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async changePage({ commit, state }, { page, searchParameters }) {
    if (state.loadedPage.indexOf(page) >= 0) {
      commit('setPage', page)
      return
    }
    commit('setLoading', true)
    const response = await searchReturnTallyLines({
      PerPage: state.perPage,
      Page: page,
      OrderBy: state.sortBy,
      OrderByDirection: state.sortDirection,
      ...searchParameters
    })
    commit('appendResults', response.etallyReturnedLineResult)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  },
  async handledLine({ commit }, data) {
    commit('setLoading', true)
    try {
      const line = await returnTallyLine(data)
      if (line) {
        commit('setReturnHandled', line.ETCLID)
      }
      return line
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

import WineCardExportList from '../../models/winecardexportlist'
import { client } from '../graphQLInstance'

export const wineCardExportListAppend = async params => {
  const result = await client.query({
    query: WineCardExportList.mutate('append', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineCardExportList.mutations.append.name]
}

export const availableOWCs = async params => {
  const result = await client.query({
    query: WineCardExportList.query('owcs', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineCardExportList.queries.owcs.name]
}

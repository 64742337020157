import Entity from './entity'
import { fields } from './winebrowsersearchresult'

const WebContactFavourites = new Entity('WebContactFavourites', fields)

WebContactFavourites.createQuery('getAll', 'webContactFavouritesGet', fields, {
  WebContactID: {
    type: Number
  }
})

WebContactFavourites.createMutation(
  'delete',
  'webContactFavouriteDelete',
  {
    WineCardID: {
      type: Number
    },
    WebContactID: {
      type: Number
    }
  },
  fields
)

export default WebContactFavourites

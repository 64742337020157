import Vue from 'vue'
import { keys, find } from 'lodash'
import { getTransactionLines } from '../../api/purchaseOrders/purchaseOrders'

export const state = () => ({
  transactions: {},
  tallyTransactions: [],
  activeTransaction: null,
  loading: false
})

export const getters = {
  getLineForPOLID: state => POLID => {
    const k = keys(state.transactions)
    for (let i = 0; i < k.length; i++) {
      const transactions = state.transactions[k[i]]
      for (let ii = 0; ii < transactions.length; ii++) {
        if (state.transactions[k[i]][ii].POLID === POLID) {
          return state.transactions[k[i]][ii]
        }
      }
    }
    return null
  },
  forPoid: state => poid => {
    return state.transactions[poid] || []
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setTransactions(state, { POID, transactions }) {
    Vue.set(state.transactions, POID, transactions)
  },
  setActiveTransaction(state, transaction) {
    state.activeTransaction = transaction
  },
  setTallyTransactions(state, transactions) {
    state.tallyTransactions = transactions
  },
  setActiveTransactionForPOLID(state, POLID) {
    state.activeTransaction = null
    state.tallyTransactions.forEach(poSet => {
      const transaction = find(poSet.transactions, { POLID })
      if (transaction !== undefined) {
        state.activeTransaction = transaction
      }
    })
  },
  setActiveTransactionForETCLID(state, ETCLID) {
    state.activeTransaction = null
    state.tallyTransactions.forEach(poSet => {
      const transaction = find(poSet.transactions, t => {
        return t.FinalisedData.ETCLIDs.indexOf(ETCLID) >= 0
      })
      if (transaction !== undefined) {
        state.activeTransaction = transaction
      }
    })
  },
  setFinalisedDataForPOLID(state, { POLID, data }) {
    for (let i = 0; i < state.tallyTransactions.length; i++) {
      const poSet = state.tallyTransactions[i]
      for (let ii = 0; ii < poSet.transactions.length; ii++) {
        if (state.tallyTransactions[i].transactions[ii].POLID === POLID) {
          const FinalisedData = Object.assign(
            state.tallyTransactions[i].transactions[ii].FinalisedData,
            data
          )
          const newTransactionLine = Object.assign(
            state.tallyTransactions[i].transactions[ii],
            { FinalisedData }
          )
          Vue.set(poSet.transactions, ii, newTransactionLine)
          Vue.set(state.tallyTransactions, i, poSet)
        }
      }
    }
  }
}

export const actions = {
  async getTransactionsForPOIDs({ commit, state }, POIDs) {
    try {
      commit('setLoading', true)
      const responses = await Promise.all(
        POIDs.map(POID => {
          return getTransactionLines(POID)
        })
      )
      responses.forEach(t => {
        commit('setTransactions', { POID: t[0].POID, transactions: t })
      })
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

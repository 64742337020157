import Entity from './entity'

const inputFields = {
  PurchaseOrderEmail: {
    type: Object,
    fields: {
      POID: {
        type: Number
      }
    }
  },
  ETallyEmail: {
    type: Object,
    fields: {
      ETCID: {
        type: Number
      }
    }
  },
  SalesOrderEmail: {
    type: Object,
    fields: {
      SOID: {
        type: Number
      }
    }
  },
  WebPurchaseOrderEmail: {
    type: Object,
    fields: {
      WPOID: {
        type: Number
      }
    }
  },
  WineListExport: {
    type: Object,
    strict: true,
    fields: {
      AllSplitCases: {
        type: Boolean
      },
      AllWholeCases: {
        type: Boolean
      },
      Scores: {
        type: String
      },
      SortBy: {
        type: String
      },
      SortDesc: {
        type: Boolean
      },
      Type: {
        type: String
      },
      Subject: {
        type: String
      },
      ExportListId: {
        type: String
      },
      Mode: {
        type: String
      },
      WineUsageCodes: {
        type: Array
      },
      DutyStatusID: {
        type: Number
      },
      WithOrigPkg: {
        type: Boolean
      },
      StockLossType: {
        type: Number
      },
      CheckLooseBottles: {
        type: Boolean
      },
      LastReceiptDays: {
        type: Number
      },
      Currencies: {
        type: Array
      },
      ExcludedUUID: {
        type: Array
      },
      SplitCaseUUID: {
        type: Array
      },
      WholeCaseUUID: {
        type: Array
      },
      IncludeAllUUID: {
        type: Array
      },
      CategoryIds: {
        type: Array
      },
      HyperLinks: {
        type: Array
      },
      WithAttachments: {
        Type: Boolean
      },
      Grouping: {
        Type: String
      },
      OnlyOWCs: {
        type: Array
      }
    }
  },
  ContactID: {
    type: Number
  },
  To: {
    type: String
  },
  ToName: {
    type: String
  },
  Sender: {
    type: String
  },
  SenderName: {
    type: String
  },
  Body: {
    type: String
  },
  Subject: {
    type: String
  },
  Send: {
    type: Boolean
  },
  CopyToSelf: {
    type: Boolean
  },
  ToSelf: {
    type: Boolean
  },
  AttachPDF: {
    type: Boolean
  },
  AttachCSV: {
    type: Boolean
  },
  AttachXLS: {
    type: Boolean
  },
  CustomTemplate: {
    type: String
  },
  Salutation: {
    type: String
  }
}

const outputFields = {
  Body: {
    type: String
  },
  Subject: {
    type: String
  },
  Sent: {
    type: Boolean
  },
  CanEditBody: {
    type: Boolean
  },
  CanPDFAttach: {
    type: Boolean
  },
  CanXLSAttach: {
    type: Boolean
  },
  CanCSVAttach: {
    type: Boolean
  },
  Attachments: {
    type: Array,
    fields: {
      Name: {
        type: String
      },
      Key: {
        type: String
      }
    }
  },
  DefaultTemplate: {
    type: String
  },
  DefaultTemplateAuditAmendedDate: {
    type: String
  },
  TemplateCode: {
    type: String
  },
  CanEditDefaultTemplate: {
    type: String
  },
  CanEditCustomTemplate: {
    type: String
  }
}

const SendEmail = new Entity('SendEmail', outputFields)
SendEmail.createMutation('send', 'sendEmail', inputFields, outputFields)

export default SendEmail

import Entity from './entity'
import { fields as wineNameFields } from './winename'
import { fields as producerFields } from './producer'
import { fields as countryFields } from './countrydropdown'
import { fields as countryRegionFields } from './countryregion'
import { fields as countrySubRegionFields } from './countrysubregion'
import { fields as designationFields } from './designation'
import { fields as classificationFields } from './classification'
import { fields as wineColourFields } from './winecolourdropdown'

export const fields = {
  LivExLWinCode: {
    type: Number
  },
  Status: {
    type: String
  },
  Producer: {
    type: String
  },
  Country: {
    type: String
  },
  Region: {
    type: String
  },
  SubRegion: {
    type: String
  },
  Colour: {
    type: String
  },
  Type: {
    type: String
  },
  Wine: {
    type: String
  },
  Designation: {
    type: String
  },
  Classification: {
    type: String
  },
  ComputedWineName: {
    type: String
  },
  LWIN7: {
    type: Number
  },
  Abv: {
    type: Number
  },
  AbvVerified: {
    type: Boolean
  },
  Vintage: {
    type: String
  }
}

const LWINDictionary = new Entity('LWINDictionary', fields)
LWINDictionary.createQuery('search', 'livExLWinCodeSearch', fields, {
  SearchTerm: {
    type: String
  },
  Vintage: {
    type: Number
  }
})

LWINDictionary.createMutation(
  'import',
  'livExImportCode',
  {
    LivExLWinCode: {
      type: Number
    }
  },
  {
    WineName: {
      type: Object,
      fields: wineNameFields
    },
    Producer: {
      type: Object,
      fields: producerFields
    },
    Country: {
      type: Object,
      fields: countryFields
    },
    Region: {
      type: Object,
      fields: countryRegionFields
    },
    SubRegion: {
      type: Object,
      fields: countrySubRegionFields
    },
    Designation: {
      type: Object,
      fields: designationFields
    },
    Classification: {
      type: Object,
      fields: classificationFields
    },
    WineColour: {
      type: Object,
      fields: wineColourFields
    },
    Abv: {
      type: Number
    },
    AbvVerified: {
      type: Number
    },
    RelatedWineCards: {
      type: Array,
      fields: {
        WineCardID: {
          type: Number
        },
        FormatID: {
          type: Number
        }
      }
    }
  }
)

export default LWINDictionary

import { map } from 'lodash'
import LargeFormatDropDown from '../models/largeformatdropdown'
import WineUsageCodeDropDown from '../models/wineusagecodedropdown'
import CategoryDropDown from '../models/categorydropdown'
import CountryDropDown from '../models/countrydropdown'
import WineColourDropDown from '../models/winecolourdropdown'
import DutyStatusDropDown from '../models/dutystatusdropdown'
import ContactStatusDropDown from '../models/contactstatusdropdown'
import AddressDropDown from '../models/addressdropdown'
import WarehouseAddressDropDown from '../models/warehouseaddressdropdown'
import IsoCountryDropDown from '../models/isocountrydropdown'
import PaymentTermsDropDown from '../models/paymenttermsdropdown'
import PromotionProfileDropDown from '../models/promotionprofiledropdown'
import WorkflowStatus from '../models/workflowstatus'
import DeliveryInstructionsDropDown from '../models/deliveryinstructionsdropdown'
import Designation from '../models/designation'
import Classification from '../models/classification'
import CountryRegion from '../models/countryregion'
import CountrySubRegion from '../models/countrysubregion'
import OAuthClient from '../models/oauthclients'
import Vineyard from '../models/vineyard'
import BillingRunPeriod from '~/models/storage/billingRunPeriod'
import { client } from './graphQLInstance'
import StoragePricingGroup from '~/models/storage/storagePricingGroup'

export const getFormatList = async searchTerm => {
  const result = await client.query({
    query: LargeFormatDropDown.query('get', {
      SearchTerm: searchTerm
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[LargeFormatDropDown.queries.get.name], app => {
    return {
      value: app.FormatID,
      text: app.FormatName,
      code: app.FormatCode,
      caseSize: app.CaseSize,
      volume: app.Volume
    }
  })
}

export const getUsageList = async searchTerm => {
  const result = await client.query({
    query: WineUsageCodeDropDown.query('get', {
      SearchTerm: searchTerm
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[WineUsageCodeDropDown.queries.get.name], app => {
    return {
      value: app.WineUsageCodeID,
      text: app.UsageCode
    }
  })
}

export const getCategoryList = async searchTerm => {
  const result = await client.query({
    query: CategoryDropDown.query('get', {
      SearchTerm: searchTerm
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[CategoryDropDown.queries.get.name], app => {
    return {
      value: app.CategoryID,
      text: app.CategoryName,
      category: app
    }
  })
}

export const getCountryList = async searchTerm => {
  const result = await client.query({
    query: CountryDropDown.query('get', {
      SearchTerm: searchTerm
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[CountryDropDown.queries.get.name], app => {
    return {
      value: app.CountryID,
      text: app.CountryName,
      UseOnBackOffice: app.UseOnBackOffice
    }
  })
}

export const getCountryRegions = async () => {
  const result = await client.query({
    query: CountryRegion.query('get', {})
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountryRegion.queries.get.name]
}

export const getCountrySubRegions = async args => {
  const result = await client.query({
    query: CountrySubRegion.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CountrySubRegion.queries.get.name]
}

export const getDesignations = async () => {
  const result = await client.query({
    query: Designation.query('get', {})
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Designation.queries.get.name]
}

export const getClassifications = async () => {
  const result = await client.query({
    query: Classification.query('get', {})
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Classification.queries.get.name]
}

export const getWineColourList = async searchTerm => {
  const result = await client.query({
    query: WineColourDropDown.query('get', {
      SearchTerm: searchTerm
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineColourDropDown.queries.get.name]
}

export const getDutyStatus = async searchTerm => {
  const result = await client.query({
    query: DutyStatusDropDown.query('get', {
      SearchTerm: searchTerm
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[DutyStatusDropDown.queries.get.name], app => {
    return {
      value: app.DutyStatusID,
      text: app.DutyStatusName
    }
  })
}

export const getContactStatusList = async () => {
  const result = await client.query({
    query: ContactStatusDropDown.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[ContactStatusDropDown.queries.get.name], app => {
    return {
      value: app.ContactStatusID,
      text: app.Status
    }
  })
}

export const getAddressDropDownById = async args => {
  const result = await client.query({
    query: AddressDropDown.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[AddressDropDown.queries.get.name], app => {
    return {
      value: app.AddrID,
      text: app.AddressDescription,
      IsWarehouse: app.IsWarehouse
    }
  })
}

export const getIsoCountryList = async () => {
  const result = await client.query({
    query: IsoCountryDropDown.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[IsoCountryDropDown.queries.get.name], app => {
    return {
      value: app.CountryCode,
      text: app.CountryName
    }
  })
}

export const getIsoInstastatCountryList = async () => {
  const result = await client.query({
    query: IsoCountryDropDown.query('getInstastat')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[IsoCountryDropDown.queries.getInstastat.name], app => {
    return {
      value: app.CountryCode,
      text: app.CountryName
    }
  })
}

export const getPaymentTermsList = async () => {
  const result = await client.query({
    query: PaymentTermsDropDown.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[PaymentTermsDropDown.queries.get.name], app => {
    return {
      value: app.PaymentTermsID,
      text: app.PaymentTerms
    }
  })
}

export const getPromotionProfileList = async () => {
  const result = await client.query({
    query: PromotionProfileDropDown.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[PromotionProfileDropDown.queries.get.name], app => {
    return {
      value: app.PromotionProfileCode,
      text: app.PromotionProfileDescription
    }
  })
}

export const getUnusedPromotionProfileCodes = async args => {
  const result = await client.query({
    query: PromotionProfileDropDown.query('getUnused', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(
    result.data[PromotionProfileDropDown.queries.getUnused.name],
    app => {
      return {
        value: app.PromotionProfileCode,
        text: app.PromotionProfileDescription
      }
    }
  )
}

export const getWorkflowStatus = async () => {
  const result = await client.query({
    query: WorkflowStatus.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[WorkflowStatus.queries.get.name], app => {
    return {
      value: app.WorkflowStatusID,
      text: app.WorkflowStatusDescription
    }
  })
}

export const getVineyards = async () => {
  const result = await client.query({
    query: Vineyard.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Vineyard.queries.get.name]
}

export const getDeliveryInstructions = async () => {
  const result = await client.query({
    query: DeliveryInstructionsDropDown.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(
    result.data[DeliveryInstructionsDropDown.queries.get.name],
    app => {
      return {
        value: app.DeliveryInstructionsID,
        text: app.DeliveryInstructions,
        IsPhysicalDelivery: app.IsPhysicalDelivery
      }
    }
  )
}

export const getOAuthClients = async () => {
  const result = await client.query({
    query: OAuthClient.query('getAll')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[OAuthClient.queries.getAll.name], app => {
    return {
      value: app.OAuthClientID,
      text: app.Name,
      ClientID: app.ClientID,
      Enabled: app.Enabled
    }
  })
}

export const getWarehouseAddressDropDown = async args => {
  const result = await client.query({
    query: WarehouseAddressDropDown.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[WarehouseAddressDropDown.queries.get.name], app => {
    return {
      value: app.AddrID,
      text: app.AddressDescription,
      IsWarehouse: app.IsWarehouse
    }
  })
}

export const getBillingRunPeriods = async () => {
  const result = await client.query({
    query: BillingRunPeriod.query('getAll')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(result.data[BillingRunPeriod.queries.getAll.name], app => {
    return {
      value: app.BRPID,
      text: app.Name,
      default: app.Default,
      days: app.Days,
      weeks: app.Weeks,
      months: app.Months
    }
  })
}

export const getStoragePricingGroups = async () => {
  const result = await client.query({
    query: StoragePricingGroup.query('getAll')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(
    result.data[StoragePricingGroup.queries.getAll.name]
      .storagePricingGroupPaginatedResults,
    app => {
      return {
        value: app.SPGID,
        text:
          app.Name +
          ' - £' +
          app.PricePer9L +
          ' (Free Months: Int ' +
          app.FreeMonths +
          ', Ext ' +
          app.ExternalFreeMonths +
          ')',
        default: app.Default,
        pricePer9L: app.PricePer9L,
        freeMonths: app.FreeMonths,
        externalFreeMonths: app.ExternalFreeMonths
      }
    }
  )
}

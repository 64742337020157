import { updateCardById } from '@/api/wineCard/card'

export default {
  methods: {
    commitToSearches(path, payload) {
      const searchPrefixes = this.$store.state.global.wineBrowserStores
      const spacer = path[0] === '/' ? '' : '/'
      return searchPrefixes.map(p => {
        return this.$store.commit(p + spacer + path, payload)
      })
    },
    dispatchToSearches(path, payload) {
      const searchPrefixes = this.$store.state.global.wineBrowserStores
      const spacer = path[0] === '/' ? '' : '/'
      return Promise.all(
        searchPrefixes.map(p => {
          return this.$store.dispatch(p + spacer + path, payload)
        })
      )
    },
    setPromoDate(item, promo) {
      this.commitToSearches('wineCards/setPromotionValue', {
        id: item.id,
        value: promo
      })
    },
    async saveWineCardAndSetActive(payload) {
      const loadingKey = 'wineCard/activeCard/setLoading'
      try {
        this.$store.commit(loadingKey, true)
        await this.dispatchToSearches('wineCards/updateByWineCard', payload)
        const result = await updateCardById(
          payload.id,
          payload.updatedCardDetails
        )
        this.$store.commit('wineCard/activeCard/setActiveCard', result)
        await this.dispatchToSearches('wineCards/updateByWineCard', result)
        return result
      } catch (e) {
        throw e
      } finally {
        this.$store.commit(loadingKey, false)
      }
    }
  }
}

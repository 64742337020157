import { getUserList, sendEmail } from '@/api/wineBrowser/favourites'

export const state = () => ({
  loading: false,
  list: []
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  resetList(state) {
    state.list = []
  }
}

export const actions = {
  async loadList({ commit }, id) {
    commit('resetList')
    commit('setLoading', true)
    try {
      const result = await getUserList(id)
      if (result) {
        commit('setList', result)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async sendEmail({ commit }, { id, contacts }) {
    commit('setLoading', true)
    try {
      return await sendEmail({ id, contacts })
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

export const state = () => ({
  selected: []
})

export const getters = {
  searchParameters: state => {
    return {
      categories:
        state.selected.length > 1
          ? state.selected.join(',')
          : state.selected.length === 1
            ? state.selected[0]
            : null
    }
  }
}

export const mutations = {
  setSelected(state, selected) {
    state.selected = selected
  },
  resetSelection(state) {
    state.selected = []
  }
}

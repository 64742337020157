import { client } from '../graphQLInstance'
import SalesOrder from '../../models/salesorder'

export const getSalesOrderSearch = async (page, perPage, searchParameters) => {
  const result = await client.query({
    query: SalesOrder.query('getForShipping', {
      ...searchParameters,
      Page: page,
      PerPage: perPage
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.queries.getForShipping.name]
}

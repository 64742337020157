import {
  getPreviousWines,
  getRecommendedWines
} from '@/api/contacts/recommendedwines'

export const state = () => ({
  loadingPreviousWines: false,
  loadingRecommendedWines: false,
  previousWines: [],
  recommendedWines: []
})

export const getters = {}

export const mutations = {
  setLoadingPreviousWines(state, loading) {
    state.loadingPreviousWines = loading === true
  },
  setLoadingRecommendedWines(state, loading) {
    state.loadingRecommendedWines = loading === true
  },
  setPreviousWines(state, wines) {
    state.previousWines = wines
  },
  setRecommendedWines(state, wines) {
    state.recommendedWines = wines
  }
}

export const actions = {
  async getPreviousWines({ commit }, id) {
    commit('setLoadingPreviousWines', true)
    try {
      const response = await getPreviousWines(id)
      if (response) {
        commit('setPreviousWines', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPreviousWines', false)
    }
  },
  async getRecommendedWines({ commit }, id) {
    commit('setLoadingRecommendedWines', true)
    try {
      const response = await getRecommendedWines(id)
      if (response) {
        commit('setRecommendedWines', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingRecommendedWines', false)
    }
  }
}

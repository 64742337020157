import { client } from '../graphQLInstance'
import PhotoParameter from '../../models/photoparameters'

export const addParameter = async args => {
  const result = await client.query({
    query: PhotoParameter.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PhotoParameter.mutations.insert.name]
}

export const editParameter = async args => {
  const result = await client.query({
    query: PhotoParameter.mutate('edit', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PhotoParameter.mutations.edit.name]
}

export const deleteParameter = async args => {
  const result = await client.query({
    query: PhotoParameter.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PhotoParameter.mutations.delete.name]
}

export const getParameters = async args => {
  const result = await client.query({
    query: PhotoParameter.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PhotoParameter.queries.get.name]
}

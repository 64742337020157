<template>
  <div>
    <b-modal
      v-model="shown"
      centered
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      :hide-header-close="true"
      size="sm"
      @hidden="$emit('hidden')"
      @shown="reset"
    >
      <template v-slot:modal-header>
        New Sales Order Line
      </template>
      <template v-slot:default>
        <div v-if="loading" class="text-center text-primary">
          <b-spinner type="grow" label="Loading..." />
        </div>
        <div v-else>
          <div class="callout">
            {{ title }}
          </div>
          <div v-if="error" class="alert alert-danger">
            {{ error }}
          </div>
          <new-small-sales-order-form
            ref="form"
            :show-cases="showCases"
            :bottle-title="item.format"
            :item-selling-price="item.listPrice"
            :item-loose-price="item.looseBottlePrice"
            :item-case-size="item.case_size"
            :value="defaultValue"
            @change="handleFormChange"
            @total-bottles="totalBottles = $event"
          />
          <div class="d-flex justify-content-center">
            <live-ex-button :lwin="item.LivExLWinCode" :wine-name="item.wine_name" :vintage="item.year" class="ml-1" />
            <wine-searcher-button :wine-name="item.ComputedWineName" :vintage="item.year" class="mr-1" />
          </div>
        </div>
      </template>
      <div slot="modal-footer">
        <b-button class="btn wv-btn wv-btn__secondary btn-sm" variant="primary" :disabled="saving" @click="save('ib')">
          Add as IB
        </b-button>
        <b-button class="btn wv-btn wv-btn__secondary btn-sm" variant="primary" :disabled="saving" @click="save('dp')">
          Add as DP
        </b-button>
        <b-button class="btn wv-btn btn-sm" @click="shown=false">
          Cancel
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="salesIBOrderModalKind"
      v-model="showStockMessageModal"
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      centered
      @ok="handleNotEnoughStockModel"
    >
      <template v-slot:modal-header>
        Insufficient Stock of Any Kind
      </template>

      <p class="mb-0">There isn't enough stock available.<br>If you continue, you may need to create an oversell.</p>

      <template v-slot:modal-footer="{ok}">
        <b-button class="btn wv-btn btn-sm" @click="ok()">
          Ok
        </b-button>

        <b-button class="btn wv-btn btn-sm" @click="showStockMessageModal=false">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="salesIBOrderModalamount"
      v-model="showIBStockMessageModal"
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      centered
      @ok="handleNotEnoughIBStockModel"
    >
      <template v-slot:modal-header>
        Insufficient Stock.
      </template>

      <p v-if="totalWantedBottlesGreaterThanAvailable" class="mb-0">
        There isn't enough In-Bond stock available.<br>If you continue, you may need to create an oversell
      </p>
      <p v-else class="mb-0">
        There isn't enough In-Bond stock available.
        <br> You could cancel and change the order to Duty-Paid or,
        <br>if you continue, you may need to create an oversell.
      </p>

      <template v-slot:modal-footer="{ok}">
        <b-button class="btn wv-btn btn-sm" @click="ok()">
          Ok
        </b-button>

        <b-button class="btn wv-btn btn-sm" @click="showIBStockMessageModal=false">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="salesIBUKOrderModalDelivery"
      v-model="showIBUKStockMessageModal"
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      centered
      @ok="handleUKIBStockModel"
    >
      <template v-slot:modal-header>
        In-Bond Delivery to UK
      </template>

      <p class="mb-0">You are selling In-Bond to a UK delivery address!<br>Please Confirm</p>

      <template v-slot:modal-footer="{ok}">
        <b-button class="btn wv-btn btn-sm" @click="ok()">
          Ok
        </b-button>

        <b-button class="btn wv-btn btn-sm" @click="showIBUKStockMessageModal=false">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NewSmallSalesOrderForm from '../Forms/NewSmallSalesOrderForm'
import WineSearcherButton from '../Base/WineSearcherButton'
import LiveExButton from '../Base/LiveExButton'
import { formResultMixin } from '~/mixins/forms'

export default {
  components: {
    NewSmallSalesOrderForm,
    LiveExButton,
    WineSearcherButton
  },
  mixins: [formResultMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    wineCard: {
      type: Object,
      required: true
    },
    wineCardLoading: {
      type: Boolean,
      required: true
    },
    isUkDelivery: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      error: null,
      loading: false,
      showStockMessageModal: false,
      confirmedInsuffientStock: false,
      showIBStockMessageModal: false,
      confirmedIBInsuffientStock: false,
      showIBUKStockMessageModal: false,
      confirmedIBUKStock: false,
      tempType: null,
      totalBottles: 0
    }
  },
  computed: {
    defaultValue() {
      return {
        salesPrice: this.item.listPrice
      }
    },
    title() {
      let title = this.item.ComputedWineName + ' (' + this.item.format + ')'

      if (this.item.year) {
        title = this.item.year + ': ' + title
      }

      return title
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    showCases() {
      return this.item.case_size > 1
    },
    availableBottles() {
      if (this.item.available_quantity) {
        return (
          this.item.available_quantity.cases * this.item.case_size +
          this.item.available_quantity.bottles
        )
      }
      return 0
    },
    inBondStockBottles() {
      return (
        this.item.in_bond_stock.cases * this.item.case_size +
        this.item.in_bond_stock.bottles
      )
    },
    totalWantedBottlesGreaterThanAvailable() {
      return this.totalBottles > this.availableBottles
    },
    totalWantedBottlesGreaterThanInBond() {
      return this.totalBottles > this.inBondStockBottles
    },
    saving() {
      return this.$store.state.salesOrders.salesOrder.saving
    }
  },
  methods: {
    handleNotEnoughStockModel() {
      this.confirmedInsuffientStock = true
      this.save(this.tempType, true)
    },
    handleNotEnoughIBStockModel() {
      this.confirmedIBInsuffientStock = true
      this.save(this.tempType, true)
    },
    handleUKIBStockModel() {
      this.confirmedIBUKStock = true
      this.save(this.tempType, true)
    },
    async save(addType, withoutValidate) {
      if (this.saving) {
        return
      }
      this.tempType = addType
      try {
        if (!withoutValidate) {
          await this.$refs.form.validate()
        }
        if (addType === 'dp' && this.confirmInsuffientStock() === true) {
          return
        }
        if (addType === 'ib' && this.confirmInsuffientIBStock() === true) {
          return
        }
        if (addType === 'ib' && this.confirmUKIBStock() === true) {
          return
        }
        const saveData = {
          WineCardID: this.item.id,
          FormatID: this.item.format_id,
          Bottles: this.totalBottles,
          Price: this.form.salesPrice,
          AsDutyPaid: addType === 'dp'
        }
        this.$emit('save', saveData)
        this.reset()

        this.shown = false
      } catch (e) {
        this.error = e
      }
    },
    reset() {
      this.confirmedInsuffientStock = false
      this.confirmedIBInsuffientStock = false
      this.showStockMessageModal = false
      this.showIBStockMessageModal = false
      this.showIBUKStockMessageModal = false
      this.confirmedIBUKStock = false
      this.$refs.form.reset()
    },
    confirmInsuffientStock() {
      if (
        this.confirmedInsuffientStock === false &&
        this.totalWantedBottlesGreaterThanAvailable
      ) {
        this.showStockMessageModal = true
        return true
      }
      return false
    },
    confirmInsuffientIBStock() {
      if (
        this.confirmedIBInsuffientStock === false &&
        this.totalWantedBottlesGreaterThanInBond
      ) {
        this.showIBStockMessageModal = true
        return true
      }
      return false
    },
    confirmUKIBStock() {
      if (this.confirmedIBUKStock === false && this.isUkDelivery) {
        this.showIBUKStockMessageModal = true
        return true
      }
      return false
    }
  }
}
</script>

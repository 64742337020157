export const state = () => ({
  format: null,
  from: null,
  greater: null,
  empty: false,
  parker: '',
  usage: null,
  to: null,
  less: null,
  zero: true,
  burghound: null,
  looseBottlesOnly: false,
  loosePriceOnly: false
})

export const getters = {
  searchParameters: state => {
    return {
      format: state.format,
      from: state.from,
      greater: state.greater,
      empty: state.empty,
      parker: state.parker,
      usage: state.usage,
      to: state.to,
      less: state.less,
      zero: state.zero,
      burghound: state.burghound,
      looseBottlesOnly: state.looseBottlesOnly,
      loosePriceOnly: state.loosePriceOnly
    }
  },
  exportParameters: state => {
    return {
      LooseOnly: state.looseBottlesOnly
    }
  }
}

export const mutations = {
  setFormat(state, format) {
    state.format = format
  },
  setFrom(state, from) {
    state.from = from
  },
  setGreater(state, greater) {
    state.greater = greater
  },
  setEmpty(state, empty) {
    state.empty = empty === true
  },
  setParker(state, parker) {
    state.parker = parker
  },
  setUsage(state, usage) {
    state.usage = usage
  },
  setTo(state, to) {
    state.to = to
  },
  setLess(state, less) {
    state.less = less
  },
  setZero(state, zero) {
    state.zero = zero === true
  },
  setLooseBottlesOnly(state, looseBottlesOnly) {
    state.looseBottlesOnly = looseBottlesOnly === true
  },
  setLoosePriceOnly(state, loosePriceOnly) {
    state.loosePriceOnly = loosePriceOnly === true
  },
  setBurghound(state, burghound) {
    state.burghound = burghound
  },
  resetSelection(state) {
    state.format = null
    state.from = null
    state.greater = null
    state.parker = ''
    state.usage = null
    state.to = null
    state.less = null
    state.burghound = null
    state.loosePriceOnly = false
    state.looseBottlesOnly = false
  }
}

import Entity from '~/models/entity'

const fields = {
  WineCard: {
    type: Array,
    fields: {
      WineCardID: {
        type: Number
      },
      WineNameID: {
        type: Number
      },
      WineName: {
        type: String
      },
      ComputedWineName: {
        type: String
      },
      WineVintage: {
        type: String
      },
      Exception: {
        type: String
      },
      WineSellingPrice: {
        type: Number
      },
      WineColourName: {
        type: String
      },
      ProducerName: {
        type: String
      },
      FormatName: {
        type: String
      },
      SubDescription: {
        type: String
      }
    }
  },
  ReservesStockAggregate: {
    type: Array,
    fields: {
      AvailableBottles: {
        type: Number
      },
      AvailableReceivedBottles: {
        type: Number
      },
      AverageCostPrice: {
        type: Number
      },
      CaseSize: {
        type: Number
      },
      BroBottles: {
        type: Number
      },
      BroReceivedBottles: {
        type: Number
      }
    }
  }
}

const wineStockField = {
  ContactID: {
    type: Number
  },
  CompanyName: {
    type: String
  },
  CompanyRef: {
    type: String
  },
  ContactName: {
    type: String
  },
  AvailableBottles: {
    type: Number
  },
  AvailableReceivedBottles: {
    type: Number
  },
  AverageCostPrice: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  CaseSize: {
    type: Number
  },
  NoEmailContact: {
    type: Boolean
  },
  BroBottles: {
    type: Number
  },
  BroReceivedBottles: {
    type: Number
  }
}

const allocationFields = {
  InventoryID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  POID: {
    type: Number
  },
  DateAllocated: {
    type: String
  },
  RotationNumber: {
    type: String
  },
  Price: {
    type: Number
  },
  AvailableBottles: {
    type: Number
  },
  RotationNotes: {
    type: String
  },
  InventoryStatusID: {
    type: Number
  },
  DutyStatusID: {
    type: Number
  },
  OriginalPackaging: {
    type: Boolean
  },
  OrigPkgCaseSize: {
    type: Number
  },
  LCBLocation: {
    type: String
  },
  ImageCount: {
    type: Number
  },
  FormatCaseSize: {
    type: Number
  },
  OriginalSOID: {
    type: Number
  },
  OriginalSODate: {
    type: Date
  },
  PublicNotes: {
    type: String
  },
  UsageCode: {
    type: String
  }
}

const emailAddressFields = {
  ContactID: {
    type: Number
  },
  CompanyName: {
    type: String
  },
  ContactName: {
    type: String
  },
  EmailAddress: {
    type: String
  }
}

const ReserveWine = new Entity('ReserveWine', fields)

ReserveWine.createQuery('get', 'reservesWineStockCardGet', fields)

ReserveWine.createQuery(
  'getStockByCustomer',
  'reservesWineStockGet',
  wineStockField
)

ReserveWine.createQuery(
  'getAllocations',
  'reserveCustomerAllocationsGet',
  allocationFields
)

ReserveWine.createQuery(
  'getEmailAddresses',
  'contactsEmailsGet',
  emailAddressFields
)

export default ReserveWine

<template>
  <div>
    <b-modal
      id="modal-1"
      v-model="shown"
      centered
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      :hide-header-close="true"
      size="md"
      @ok="save"
      @shown="reset"
    >
      <template v-slot:modal-header>
        New Wine Name
      </template>
      <wine-name-form
        ref="form"
        :duty-type-options="dutyTypes"
        :value="formData"
        @change="handleFormChange"
        @toggle-appellations-modal="toggleAppellationsModal"
        @reset-appellation="resetAppellation"
      />
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="ok()">
          <b-spinner v-if="saving" type="grow" label="Loading..." small />
          OK
        </b-button>
        <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="shown=false">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <appellations-modal
      v-model="boolAppellationModal"
      @selected="setAppellation"
    />
  </div>
</template>

<script>
import WineNameForm from '~/components/Forms/WineNameForm'
import AppellationsModal from '~/components/Modal/Administration/AppellationsModal'
import { formResultMixin } from '../../../mixins/forms'
import { map } from 'lodash'

export default {
  components: {
    WineNameForm,
    AppellationsModal
  },
  mixins: [formResultMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    dutyTypesProp: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      selectedAppellation: null,
      boolAppellationModal: false,
      saving: false
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    dutyTypes() {
      return map(this.dutyTypesProp, dutyType => {
        return {
          value: dutyType.DutyTypeID,
          text: dutyType.DutyTypeName
        }
      })
    },
    formData() {
      return {
        Name: '',
        DutyTypeID: 1,
        WineCode: '',
        AppellationName: this.selectedAppellation
          ? this.selectedAppellation.AppellationName
          : ''
      }
    }
  },
  methods: {
    toggleAppellationsModal() {
      this.boolAppellationModal = !this.boolAppellationModal
    },
    setAppellation(item) {
      this.selectedAppellation = item
      this.boolAppellationModal = false
    },
    resetAppellation() {
      this.selectedAppellation = null
    },
    cancel() {
      this.$refs.form.reset()
    },
    reset() {
      this.selectedAppellation = null
      this.$refs.form.reset()
    },
    async save(e) {
      e.preventDefault()
      try {
        await this.$refs.form.validate()
        if (this.selectedAppellation) {
          this.form.AppellationID = this.selectedAppellation.AppellationID
        }
        this.saving = true
        const wineName = await this.$store.dispatch(
          'administration/winenames/addWineName',
          this.form
        )
        this.shown = false
        this.$emit('saved', wineName)
        this.$notify.success('Wine name has been added')
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

import Entity from './entity'
import { fields as wineBrowserFields } from './winebrowsersearchresult'
const fields = {
  ContactID: {
    type: Number
  },
  ContactName: {
    type: String
  },
  EmailAddress1: {
    type: String
  }
}

const Favourites = new Entity('favourites', fields)

Favourites.createQuery('get', 'userListAsFavouritesGet')
Favourites.createQuery(
  'contactGet',
  'contactFavouritesGet',
  wineBrowserFields,
  Entity.onlyKeys(fields, ['ContactID'])
)
Favourites.createMutation(
  'sendEmail',
  'webCustomerFavouriteNotification',
  {
    WineCardID: {
      type: Number
    },
    Contacts: {
      type: Array
    }
  },
  {}
)

export default Favourites

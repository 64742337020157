import Entity from './entity'

const fields = {
  DutyStatusID: {
    type: Number
  },
  DutyStatusName: {
    type: String
  }
}

const DutyStatusDropDown = new Entity('DutyStatusDropDown', fields)
DutyStatusDropDown.createQuery('get', 'dutyStatusDropDown')

export default DutyStatusDropDown

import Entity from './entity'

const fields = {
  WineUsageCodeID: {
    type: Number
  },
  UsageCode: {
    type: String
  }
}

const WineUsageCodeDropDown = new Entity('WineUsageCodeDropDown', fields)
WineUsageCodeDropDown.createQuery('get', 'wineUsageCodeDropDown', fields)

export default WineUsageCodeDropDown

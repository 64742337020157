<template>
  <span>{{ date|formatDate(format) }}</span>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    },
    format: {
      type: String,
      required: false,
      default: 'Do MMM YY'
    }
  }
}
</script>

<template>
  <b-modal
    v-model="shown"
    :modal-class="'wv-modal' + (classProp ? ' ' + classProp : '')"
    content-class="shadow"
    footer-class="justify-content-center"
    :hide-header-close="true"
    centered
    size="custom-md"
    @shown="onShown"
    @cancel="cancel"
  >
    <template v-slot:modal-header>
      {{ heading }}
    </template>
    <div v-if="loading" class="text-center text-primary my-2">
      <b-spinner type="grow" label="Loading..." />
    </div>
    <div v-else>
      <group-box class="my-3">
        <template v-slot:header>
          Search Filter
        </template>
        <d-input-group seamless size="sm">
          <d-input v-model="search" :placeholder="searchPlaceholder" @keyup.enter.prevent="performSearch" />
          <b-button
            size="sm"
            class="wv-btn"
            :disabled="search.length === 0"
            @click="performSearch"
          >
            Apply
          </b-button>
          <b-button
            size="sm"
            class="wv-btn"
            :disabled="search.length === 0"
            @click="clearSearchMethod"
          >
            Clear
          </b-button>
        </d-input-group>
      </group-box>
      <group-box>
        <template v-slot:header>
          Matches
        </template>

        <div class="wv-card__h500">
          <vue-custom-scrollbar
            class="virtual-scroll scroll-offsets"
            :class="scrollbarClass"
            :settings="scrollbarSettings"
          >
            <virtual-table
              class="h-100"
              :fields="fields"
              :items="paginatedItems"
              :total="filteredItems.length"
              :per-page="perPage"
              :table-tr-class="getRowClass"
              :primary-key="primaryKey"
              @row-clicked="rowSelected"
              @row-dblclicked="rowDoubleClicked"
              @sort-changed="handleSortChanged"
              @request-page="requestPage"
            >
              <!-- Pass on all named slots -->
              <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
              <!-- Pass on all scoped slots -->
              <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                <slot :name="slot" v-bind="scope" />
              </template>
            </virtual-table>
          </vue-custom-scrollbar>
        </div>
      </group-box>
    </div>
    <template v-slot:modal-footer>
      <b-row class="w-100">
        <b-col class="px-0">
          <b-button
            size="sm"
            class="wv-btn"
            @click="$emit('new')"
          >
            New
          </b-button>
          <b-button
            size="sm"
            class="wv-btn"
            :disabled="!hasSelectedItem || deleting"
            @click="$emit('edit',selectedItem)"
          >
            Edit
          </b-button>
          <b-button
            size="sm"
            class="wv-btn"
            :disabled="!hasSelectedItem || deleting"
            @click="$emit('view',selectedItem)"
          >
            View
          </b-button>
          <b-button
            size="sm"
            class="wv-btn"
            :disabled="!hasSelectedItem || deleteDisabled || deleting"
            @click="$emit('delete',selectedItem)"
          >
            <b-spinner v-if="deleting" type="grow" label="Loading..." small />
            Delete
          </b-button>
        </b-col>
        <b-col class="text-right px-0">
          <b-button
            :disabled="!hasSelectedItem"
            size="sm"
            class="wv-btn"
            @click="ok()"
          >
            OK
          </b-button>
          <b-button
            size="sm"
            class="wv-btn"
            @click="cancel()"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { sortBy } from 'lodash'
import GroupBox from '~/components/Base/GroupBox'
import VirtualTable from '../Base/VirtualTable'
import VueCustomScrollbar from '~/components/Base/VueCustomScrollbar'

export default {
  components: {
    GroupBox,
    VirtualTable,
    VueCustomScrollbar
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    searchPlaceholder: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    filterKey: {
      type: String,
      required: true
    },
    primaryKey: {
      type: String,
      required: true
    },
    scrollbarClass: {
      type: String,
      default: ''
    },
    selected: {
      type: Object,
      default() {
        return null
      }
    },
    deleteDisabled: {
      type: Boolean,
      default: false
    },
    deleting: {
      type: Boolean,
      default: false
    },
    classProp: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      search: '',
      filterSearch: '',
      perPage: 100,
      page: 1,
      sortBy: null,
      sortDesc: false,
      selectedItem: null,
      scrollbarSettings: {
        tagname: 'div',
        suppressScrollY: true
      }
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    sortedItems() {
      let items = this.items
      if (this.sortBy) {
        items = sortBy(items, this.sortBy)
      }
      if (this.sortDesc) {
        items = items.reverse()
      }
      return items
    },
    paginatedItems() {
      return this.filteredItems.slice(0, this.page * this.perPage)
    },
    filteredItems() {
      if (this.filterSearch.length === 0) {
        return this.sortedItems
      }
      return this.sortedItems.filter(item => {
        if (item[this.filterKey] === undefined) return false
        return item[this.filterKey]
          .toLowerCase()
          .includes(this.search.toLowerCase())
      })
    },
    hasSelectedItem() {
      return this.selectedItem !== null
    }
  },
  watch: {
    selected(item) {
      this.selectedItem = item
    }
  },
  mounted() {
    this.selectedItem = this.selected
  },
  methods: {
    clearSearchMethod() {
      this.search = ''
      this.filterSearch = ''
    },
    performSearch() {
      this.filterSearch = this.search
    },
    getRowClass(item) {
      if (this.selectedItem && this.selectedItem === item) {
        return 'row-selected'
      }
      return ''
    },
    rowSelected(item) {
      this.selectedItem = item
      this.$emit('selected', item)
    },
    rowDoubleClicked(item) {
      this.selectedItem = item
      this.$emit('dbl-click', item)
    },
    handleSortChanged(sort) {
      this.sortBy = sort.sortBy
      this.sortDesc = sort.sortDesc
    },
    requestPage(page) {
      this.page = page
    },
    cancel() {
      this.shown = false
      this.$emit('cancel')
    },
    ok() {
      this.$emit('selected', this.selectedItem)
      this.$emit('ok', this.selectedItem)
    },
    onShown() {
      this.selectedItem = null
      this.$emit('shown')
    }
  }
}
</script>

<style>
.wv-card__h500 {
  height: 500px;
}
</style>

export const saveOpenOrders = orders => {
  window.sessionStorage.setItem('open-so', JSON.stringify(orders))
}

export const getOpenOrders = orders => {
  const openOrders = window.sessionStorage.getItem('open-so')
  try {
    return JSON.parse(openOrders)
  } catch (e) {
    return []
  }
}

export const saveChanges = (SOID, changedDetails) => {
  window.sessionStorage.setItem(
    'changed-soid-' + SOID,
    JSON.stringify(changedDetails)
  )
}

export const getSavedChanges = SOID => {
  const changedDetails = window.sessionStorage.getItem('changed-soid-' + SOID)
  try {
    return JSON.parse(changedDetails)
  } catch (e) {
    return null
  }
}

export const removeSavedChanges = SOID => {
  window.sessionStorage.removeItem('changed-soid-' + SOID)
}

<template>
  <b-modal
    v-model="shown"
    modal-class="wv-modal wv-modal--notes"
    content-class="shadow"
    footer-class="justify-content-center"
    :hide-header-close="true"
    size="xxl"
    centered
    @hidden="$emit('hidden')"
    @shown="handleShown"
    @show="$emit('show')"
    @hide="$emit('hide')"
    @ok="$emit('ok')"
    @cancel="$emit('cancel')"
    @change="$emit('change')"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <template v-slot:default>
      <group-box class="my-3">
        <template v-slot:header>
          Search Filter
        </template>
        <quick-search-contacts-filters-form
          ref="searchForm"
          :show-filters="showFilters"
          :filters="filters"
          :label="title"
          @search="search"
          @new="handleNew"
          @clear="clear"
          @update-filter="updateFilter"
        />
      </group-box>
      <group-box class="mt-4 mb-0 wv-quick-search__contact-modal-matches">
        <template v-slot:header>
          Matches
        </template>
        <div v-if="contactSearchLoading" class="text-center text-primary my-2">
          <b-spinner type="grow" label="Loading..." />
        </div>
        <vue-custom-scrollbar
          v-else
          class="virtual-scroll t-contacts"
          :settings="settings"
        >
          <virtual-table
            class="h-100"
            :fields="tableFields"
            :items="sortedResults"
            :total="contactSearchResults.length"
            :per-page="perPage"
            :table-tr-class="getRowClass"
            primary-key="ContactID"
            @row-clicked="rowSelected"
            @row-dblclicked="rowDoubleClicked"
            @sort-changed="handleSortChanged"
            @request-page="requestPage"
          >
            <template slot="table-colgroup" slot-scope="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width, maxWidth: field.width, overflow: 'hidden' }"
              >
            </template>
            <template slot="loading">
              <p class="text-center py-2">
                <b-spinner type="grow" label="Loading Please Wait" small />
              </p>
            </template>
            <template slot="SuppressFlag" slot-scope="data">
              {{ data.item.SuppressFlag === 1 ? 'Yes' : 'No' }}
            </template>
            <template slot="EmailAddress" slot-scope="data">
              <span :title="data.item.EmailAddress">
                {{ data.item.EmailAddress }}
              </span>
            </template>
            <template slot="CompanyName" slot-scope="data">
              <span :title="data.item.CompanyName">
                {{ data.item.CompanyName }}
              </span>
            </template>
            <template slot="ContactName" slot-scope="data">
              <span :title="data.item.ContactName">
                {{ data.item.ContactName }}
              </span>
            </template>
            <currency slot="PurchasesTotal" slot-scope="data" :value="parseFloat(data.item.PurchasesTotal)" />
            <currency slot="SalesTotal" slot-scope="data" :value="parseFloat(data.item.SalesTotal)" />
            <currency slot="ReservesTotal" slot-scope="data" :value="parseFloat(data.item.ReservesTotal)" />
          </virtual-table>
        </vue-custom-scrollbar>
      </group-box>
    </template>
    <template v-slot:modal-footer>
      <b-button :disabled="selectedItem === null" size="sm" class="btn wv-btn" @click="ok()">
        OK
      </b-button>
      <b-button size="sm" class="btn wv-btn" @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import QuickSearchContactsFiltersForm from '~/components/Forms/QuickSearchContactsFiltersForm'
import Currency from '~/components/Base/Currency'
import GroupBox from '~/components/Base/GroupBox'
import VirtualTable from '~/components/Base/VirtualTable'
import { sortBy } from 'lodash'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'

export default {
  components: {
    QuickSearchContactsFiltersForm,
    Currency,
    GroupBox,
    VirtualTable,
    vueCustomScrollbar
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    showFilters: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableFields: [
        {
          key: 'CompanyName',
          label: 'Company Name',
          sortable: true
        },
        {
          key: 'ContactName',
          label: 'Contact',
          sortable: true,
          width: '225px'
        },
        {
          key: 'CountryCode',
          label: 'Cty',
          sortable: true,
          width: '35px'
        },
        {
          key: 'EmailAddress',
          label: 'Email Address',
          sortable: true,
          width: '225px'
        },
        {
          key: 'SuppressFlag',
          label: 'Suppress?',
          sortable: true,
          width: '65px'
        },
        {
          key: 'SageSupplierAccountNumber',
          label: 'Supp. A/C',
          sortable: true,
          width: '85px'
        },
        {
          key: 'PurchasesCount',
          label: 'Purch.',
          sortable: true,
          width: '60px',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'PurchasesTotal',
          label: 'Purch. £',
          sortable: true,
          width: '90px',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'SageCustomerAccountNumber',
          label: 'Cust. A/C',
          sortable: true,
          width: '85px'
        },
        {
          key: 'SalesCount',
          label: 'Sales',
          sortable: true,
          width: '60px',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'SalesTotal',
          label: 'Sales £',
          sortable: true,
          width: '90px',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'ReservesCount',
          label: 'Res.',
          sortable: true,
          width: '50px',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'CurrentReservesTotal',
          label: 'Res. £',
          sortable: true,
          width: '80px',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
          width: '70px',
          thClass: 'text-center',
          tdClass: 'text-center'
        }
      ],
      selectedItem: null,
      perPage: 100,
      page: 1,
      sortBy: 'CompanyName',
      sortDesc: false,
      settings: {
        tagname: 'div',
        suppressScrollY: true
      }
    }
  },
  computed: {
    ...mapState('contacts/contacts', {
      contactSearchLoading: state => state.contactSearchLoading,
      type: state => state.quickSearch.filters.type,
      contactSearchResults: state => state.contactSearchResults
    }),
    sortedResults() {
      const sortedResults = sortBy(this.contactsPaged, this.sortBy)
      if (this.sortDesc) {
        return sortedResults.reverse()
      }
      return sortedResults
    },
    contactsPaged() {
      return this.contactSearchResults.slice(0, this.perPage * this.page)
    },
    title() {
      if (this.type === 'suppliers') {
        return 'Supplier Search'
      } else if (this.type === 'customers') {
        return 'Customer Search'
      } else if (this.type === 'prospects') {
        return 'Prospect Search'
      }
      return 'Contact Search'
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    // If there is one result, we must have found what we want...
    sortedResults(newResults, oldResults) {
      if (newResults.length === 1) {
        this.rowSelected(newResults[0])
      }
    }
  },
  methods: {
    requestPage(page) {
      this.page = page
    },
    handleSortChanged(sorted) {
      this.sortBy = sorted.sortBy
      this.sortDesc = sorted.sortDesc
    },
    handleShown() {
      this.selectedItem = null
      this.$emit('shown')
    },
    clear() {
      this.resetContactSearchResults()
    },
    rowDoubleClicked(item) {
      this.$emit('selected', item)
      this.shown = false
    },
    rowSelected(item) {
      this.selectedItem = item
    },
    cancel() {
      this.shown = false
    },
    ok() {
      this.$emit('selected', this.selectedItem)
      this.shown = false
    },
    search() {
      this.selectedItem = null
      // eslint-disable-next-line standard/computed-property-even-spacing
      const searchParams = this.$store.getters[
        'contacts/contacts/searchParameters'
      ]
      this.$store.dispatch('contacts/contacts/contactSearch', searchParams)
    },
    handleNew() {
      this.$emit('new')
      this.shown = false
    },
    updateFilter(filter, value) {
      this.updateSearchFilter({ filter, value })
    },
    getRowClass(item) {
      if (this.selectedItem && this.selectedItem.ContactID === item.ContactID) {
        return 'row-selected'
      }
      return ''
    },
    ...mapMutations({
      setContactSearchResults: 'contacts/contacts/setContactSearchResults',
      resetContactSearchResults: 'contacts/contacts/resetContactSearchResults',
      updateSearchFilter: 'contacts/contacts/updateQuickSearchFilters',
      resetFilters: 'contacts/contacts/resetQuickSearchFilters'
    })
  }
}
</script>
<style>
.t-contacts table {
  width: 1423px;
}

@media (min-width: 1630px) {
  .t-contacts .virtual-scroll__content {
    height: calc(100% - 19px);
  }
}
</style>

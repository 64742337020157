<template>
  <div>
    <vue-custom-scrollbar
      class="virtual-scroll t-available-stock"
      :settings="settings"
    >
      <virtual-table
        :fields="tableFields"
        class="available-stock"
        :items="sortedItemsWithRunningTotals"
        :total="sortedItemsWithRunningTotals.length"
        :per-page="perPage"
        :table-tr-class="handleTrClass"
        primary-key="TempID"
        @sort-changed="handleSort"
      >
        <template slot="table-colgroup" slot-scope="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.width, maxWidth: field.width, overflow: 'hidden' }"
          >
        </template>

        <date v-if="data.item.OrderDate" slot="OrderDate" slot-scope="data" :date="data.item.OrderDate" />

        <template slot="CostPrice" slot-scope="data">
          <template v-if="isReserved(data.item)">
            &nbsp;
          </template>
          <currency v-else :value="data.item.CostPrice" />
        </template>


        <stock-level
          slot="RunningTotal"
          slot-scope="data"
          :case-size="data.item.CaseSize"
          :total-bottles="data.item.RunningTotal"
          :show-minus="true"
        />

        <stock-level
          slot="AvailableBottles"
          slot-scope="data"
          :case-size="data.item.CaseSize"
          :total-bottles="data.item.AvailableBottles"
          :show-minus="true"
        />

        <div slot="Include" slot-scope="data" class="text-center">
          <b-form-checkbox
            class="mr-0"
            :checked="getItemIsIncluded(data.item)"
            @change="handleCheckboxChange(data.item, ...arguments)"
          />
        </div>

        <template slot="Margin" slot-scope="data">
          <template v-if="isReserved(data.item) === false">
            {{ (data.item.Margin*100)|round(0) }}%
          </template>
          <template v-else>
            &nbsp;
          </template>
        </template>

        <template slot="RunningMargin" slot-scope="data" class="text-right">
          <template v-if="getItemIsIncluded(data.item) && isReserved(data.item) === false">
            {{ (data.item.RunningMargin*100)|round(0) }}%
          </template>
          <template v-else>
            &nbsp;
          </template>
        </template>
      </virtual-table>
    </vue-custom-scrollbar>

    <confirm
      v-model="showConfirmToggle"
      title="Original Packaging Conflict"
      @ok="enableCheckboxForTmpItem"
    >
      Do you really want to include this line,<br>
      although it doesn't have original packaging?
    </confirm>
  </div>
</template>

<script>
import StockLevel from '~/components/Base/StockLevel'
import Date from '~/components/Base/Date'
import Currency from '~/components/Base/Currency'
import Confirm from '~/components/Modal/Confirm'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'
import VirtualTable from '~/components/Base/VirtualTable'
import Vue from 'vue'
import moment from 'moment'
import { sortBy, map, has, each } from 'lodash'

export default {
  components: {
    Date,
    StockLevel,
    Currency,
    Confirm,
    vueCustomScrollbar,
    VirtualTable
  },
  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    requireMandatory: {
      type: Boolean,
      required: false,
      default: false
    },
    usageCodeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      sortBy: null,
      sortDesc: false,
      runningQuantity: 0,
      defaultCaseSize: 12,
      maxQuantityWithoutLoss: 0,
      maxIncludesLineLoss: false,
      runningCost: 0,
      runningProfit: 0,
      sortedItemsWithRunningTotals: [],
      includedToggled: {},
      showConfirmToggle: false,
      tmpItem: null,
      selectedItem: null,
      perPage: 100,
      page: 1,
      settings: {
        tagname: 'div',
        suppressScrollY: true
      }
    }
  },
  computed: {
    tableFields() {
      return [
        {
          key: 'POID',
          sortable: false,
          width: '49px'
        },
        {
          key: 'OrderDate',
          sortable: false,
          label: 'Order Date',
          width: '85px'
        },
        {
          key: 'CompanyName',
          label: 'Supplier',
          sortable: false
        },
        {
          key: 'UsageCode',
          label: 'Usage',
          sortable: false,
          width: '39px'
        },
        {
          key: 'OriginalPackaging',
          label: 'Orig. Pkg.',
          sortable: false,
          width: '39px'
        },
        {
          key: 'AvailableBottles',
          sortable: false,
          label: 'Available',
          width: '60px'
        },
        {
          key: 'Include',
          label: '',
          width: '50px'
        },
        {
          key: 'RunningTotal',
          width: '80px'
        },
        {
          key: 'CostPrice',
          sortable: false,
          width: '80px',
          tdClass: 'text-right'
        },
        {
          key: 'Margin',
          sortable: false,
          width: '75px',
          tdClass: 'text-right'
        },
        {
          key: 'RunningMargin',
          width: '60px',
          tdClass: 'text-right'
        }
      ]
    },
    sortedItems() {
      let sortedItems
      if (this.sortBy === null) {
        return this.items
      }
      if (this.sortBy === 'OrderDate') {
        sortedItems = sortBy(this.items, d => moment(d.OrderDate).toDate())
      } else {
        sortedItems = sortBy(this.items, this.sortBy)
      }

      if (this.sortDesc) {
        return sortedItems.reverse()
      }
      return sortedItems
    }
  },
  watch: {
    requireMandatory(isMandatory) {
      each(this.sortedItems, item => {
        const usageCode = item.UsageCode === null ? 'PUB' : item.UsageCode
        const originalPack =
          item.OriginalPackaging === null ? '' : item.OriginalPackaging
        const hasOriginalPkg = originalPack.length > 0
        const defaultLineInclude = usageCode === 'PUB' || this.usageCodeId !== 0
        if (isMandatory) {
          this.handleCheckboxChange(item, hasOriginalPkg && defaultLineInclude)
        } else {
          this.handleCheckboxChange(item, defaultLineInclude)
        }
      })
      this.sortedItemsWithRunningTotals = this.calculateRunningTotals()
    }
  },
  mounted() {
    this.sortedItemsWithRunningTotals = this.calculateRunningTotals()
  },
  methods: {
    requestPage(page) {
      this.page = page
    },
    isReserved(item) {
      return item.POID === null
    },
    handleTrClass(item) {
      let classNames = ''
      if (!item) {
        return ''
      }
      if (this.isReserved(item)) {
        return 'red'
      }
      if (!this.getItemIsIncluded(item)) {
        return ''
      }
      if (item.Include) {
        classNames += ' row--selected'
      }
      if (item.RunningMargin < 0) {
        classNames += ' row--loss'
      } else if (item.ListPrice >= item.CostPrice) {
        classNames += ' row--profit'
      }
      return classNames.trim()
    },
    handleSort(sort) {
      this.sortBy = sort.sortBy
      this.sortDesc = sort.sortDesc
      this.sortedItemsWithRunningTotals = this.calculateRunningTotals()
    },
    handleCheckboxChange(item, checked) {
      if (item.POID) {
        const originalPack =
          item.OriginalPackaging === null ? '' : item.OriginalPackaging
        const hasOriginalPkg = originalPack.length > 0
        if (this.requireMandatory && checked && hasOriginalPkg === false) {
          this.showConfirmToggle = true
          Vue.set(this.includedToggled, item.TempID, true)
          Vue.nextTick(() => {
            Vue.set(this.includedToggled, item.TempID, false)
          })
          this.tmpItem = item
          return
        }
      }
      Vue.set(this.includedToggled, item.TempID, checked)
      this.sortedItemsWithRunningTotals = this.calculateRunningTotals()
    },
    enableCheckboxForTmpItem() {
      Vue.set(this.includedToggled, this.tmpItem.TempID, true)
      this.sortedItemsWithRunningTotals = this.calculateRunningTotals()
    },
    getItemIsIncluded(item) {
      if (has(this.includedToggled, item.TempID)) {
        return this.includedToggled[item.TempID]
      }
      return item.Include
    },
    calculateRunningTotals() {
      const sortedItems = JSON.parse(JSON.stringify(this.sortedItems))
      this.runningQuantity = 0
      this.defaultCaseSize = 12
      this.maxQuantityWithoutLoss = 0
      this.maxIncludesLineLoss = false
      this.runningCost = 0
      this.runningProfit = 0
      const items = map(sortedItems, item => {
        if (this.getItemIsIncluded(item)) {
          this.runningQuantity += item.AvailableBottles
          item.RunningTotal = this.runningQuantity
          if (!item.POID) {
            this.maxQuantityWithoutLoss += item.AvailableBottles
          } else {
            this.defaultCaseSize = item.CaseSize
            const prevRunningProfit = this.runningProfit
            this.runningCost +=
              item.AvailableBottles * (item.CostPrice / item.CaseSize)
            this.runningProfit +=
              (item.AvailableBottles * (item.ListPrice - item.CostPrice)) /
              item.CaseSize
            if (this.runningProfit !== 0) {
              item.RunningMargin = this.runningProfit / this.runningCost
            }
            if (this.runningProfit >= 0) {
              this.maxQuantityWithoutLoss += item.AvailableBottles
              if (item.ListPrice < item.CostPrice) {
                this.maxIncludesLineLoss = true
              }
            } else if (prevRunningProfit >= 0) {
              this.maxQuantityWithoutLoss = Math.floor(
                (item.AvailableBottles * prevRunningProfit) /
                  (prevRunningProfit - this.runningProfit)
              )
              if (item.ListPrice < item.CostPrice) {
                this.maxIncludesLineLoss = true
              }
            }
          }
        } else {
          item.RunningTotal = 0
          item.RunningMargin = 0
        }
        return item
      })
      this.$emit('updated-running-totals', {
        runningQuantity: this.runningQuantity,
        defaultCaseSize: this.defaultCaseSize,
        maxQuantityWithoutLoss: this.maxQuantityWithoutLoss,
        maxIncludesLineLoss: this.maxIncludesLineLoss
      })
      return items
    }
  }
}
</script>
<style>
.t-available-stock table {
  width: 900px;
}
@media (min-width: 1630px) {
  .t-available-stock .virtual-scroll__content {
    height: 220px;
  }
}
</style>

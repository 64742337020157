import { getSystemSettings } from '@/api/systemsettings'

export const state = () => ({
  loading: false,
  list: []
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setList(state, list) {
    state.list = list
  }
}

export const actions = {
  async loadList({ commit }) {
    commit('setLoading', true)
    try {
      const response = await getSystemSettings()
      if (response) {
        commit('setList', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

<template>
  <email-promotion-template
    v-model="showEmailPromotionTemplateModal"
  />
</template>

<script>
import EmailPromotionTemplate from '~/components/Modal/EmailPromotionTemplate'
export default {
  components: {
    EmailPromotionTemplate
  },
  computed: {
    showEmailPromotionTemplateModal: {
      get() {
        return this.$store.state.modal.showEmailPromotionTemplateModal
      },
      set(value) {
        this.$store.commit('modal/setShowEmailPromotionTemplateModal', value)
      }
    }
  }
}
</script>

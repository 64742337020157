import Entity from './entity'
import { auditFields } from './helpers'

const fields = {
  FormatID: {
    type: Number
  },
  FormatName: {
    type: String
  },
  FormatCode: {
    type: String
  },
  FormatOrder: {
    type: Number
  },
  FormatCat: {
    type: Number
  },
  CaseSize: {
    type: Number
  },
  Volume: {
    type: Number
  },
  WineCardUsage: {
    type: Number
  },
  SingleInvoiceUnit: {
    type: String
  },
  MultipleInvoiceUnit: {
    type: String
  },
  WineSearcherSingle: {
    type: String
  },
  WineSearcherMultiple: {
    type: String
  },
  CaseWeightKG: {
    type: Number
  },
  ...auditFields
}

const Format = new Entity('Format', fields)

Format.createQuery('get', 'format', fields)
Format.createQuery('search', 'formatSearch', fields)

Format.createMutation(
  'insert',
  'formatAdd',
  Entity.onlyKeys(fields, [
    'FormatID',
    'FormatName',
    'FormatCode',
    'CaseSize',
    'Volume',
    'SingleInvoiceUnit',
    'MultipleInvoiceUnit',
    'WineSearcherSingle',
    'WineSearcherMultiple',
    'CaseWeightKG'
  ]),
  fields
)
Format.createMutation(
  'update',
  'formatUpdate',
  Entity.onlyKeys(fields, [
    'FormatID',
    'FormatName',
    'FormatCode',
    'CaseSize',
    'Volume',
    'SingleInvoiceUnit',
    'MultipleInvoiceUnit',
    'WineSearcherSingle',
    'WineSearcherMultiple',
    'AuditAmendedDate',
    'CaseWeightKG'
  ]),
  fields
)
Format.createMutation(
  'delete',
  'formatDelete',
  {
    ID: {
      type: Number
    },
    AuditAmendedDate: fields.AuditAmendedDate
  },
  []
)

Format.on('post-query', format => {
  format.WineCardUsage =
    format.WineCardUsage === null ? 0 : format.WineCardUsage
})

export default Format

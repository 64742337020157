import {
  addClient,
  updateClient,
  deleteClient,
  getAllOAuth,
  changeSecret,
  getAllPermissions
} from '../../api/web/oauth'

export const state = () => ({
  loading: false,
  saving: false,
  clients: [],
  permissions: []
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  setClients(state, clients) {
    state.clients = clients
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  replaceClient(state, client) {
    state.clients = state.clients.map(w => {
      if (w.OAuthClientID === client.OAuthClientID) {
        return client
      }
      return w
    })
  },
  appendClient(state, client) {
    state.clients.push(client)
  },
  removeClient(state, client) {
    state.clients = state.clients.filter(
      c => c.OAuthClientID !== client.OAuthClientID
    )
  }
}

export const actions = {
  async getAll({ commit }) {
    try {
      commit('setLoading', true)
      const clients = await getAllOAuth()
      commit('setClients', clients)
      return clients
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getPermissions({ commit }) {
    try {
      commit('setLoading', true)
      const permissions = await getAllPermissions()
      commit('setPermissions', permissions)
      return permissions
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async create({ commit }, args) {
    try {
      commit('setSaving', true)
      const client = await addClient(args)
      commit('appendClient', client)
      return client
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async update({ commit }, args) {
    try {
      commit('setSaving', true)
      const client = await updateClient(args)
      commit('replaceClient', client)
      return client
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async changeSecret({ commit }, args) {
    try {
      commit('setSaving', true)
      return await changeSecret(args)
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async remove({ commit }, args) {
    try {
      commit('setSaving', true)
      await deleteClient(args)
      commit('removeClient', args)
      return true
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  }
}

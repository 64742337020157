import Entity from './entity'

export const fields = {
  ClassificationID: {
    type: Number
  },
  ClassificationName: {
    type: String
  },
  ClassificationOrder: {
    type: Number
  },
  ClassificationCat: {
    type: Number
  },
  ClassificationCode: {
    type: String
  }
}

const Classification = new Entity('Classification', fields)
Classification.createQuery('get', 'classificationsGet')

Classification.createMutation(
  'delete',
  'classificationDelete',
  {
    ClassificationID: fields.ClassificationID
  },
  []
)
Classification.createMutation('insert', 'classificationAdd', fields)
Classification.createMutation('update', 'classificationUpdate', fields)

export default Classification

<template>
  <form ref="form" method="post" :action="actionUrl" :target="target">
    <input type="hidden" name="token" :value="$store.state.user.documentToken">
    <input type="hidden" name="SOID" :value="soid">
    <input type="hidden" name="Type" :value="type">
    <slot name="form-override">
      <b-button type="submit" block class="wv-btn wv-btn--xs mt-1" :disabled="disabled">
        <slot>
          PDF
        </slot>
      </b-button>
    </slot>
  </form>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    soid: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      default: 'pdf'
    },
    target: {
      type: String,
      default: 'self'
    },
    isStorage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    actionUrl() {
      return (
        process.env.apiHost +
        '/document/' +
        (this.isStorage ? 'storageSalesOrder' : 'salesOrder')
      )
    }
  },
  methods: {
    submit() {
      this.$refs.form.submit()
    }
  }
}
</script>

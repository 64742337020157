<template>
  <div>
    <vue-custom-scrollbar
      class="virtual-scroll t-cases t-cases--modal"
      :settings="settings"
    >
      <virtual-table
        :fields="tableFields"
        scroller-class=""
        :items="itemsSorted"
        :per-page="perPage"
        :total="total"
        :show-loading="true"
        :estimate-total-scroll-height="false"
        :table-tr-class="getRowClass"
        :next-load-request="10"
        primary-key="id"
        @request-page="fetchPage"
        @sort-changed="performOrderBy"
        @row-clicked="setActiveCard"
        @row-hovered="setHover"
      >
        <template slot="table-colgroup" slot-scope="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.width, maxWidth: field.width, overflow: 'hidden' }"
          >
        </template>
        <template slot="actions" slot-scope="data">
          <div>
            <template v-if="showAttach">
              <b-button v-if="isAttached(data.item) === false" size="sm" class="btn wv-btn mr-1" @click="$emit('attach', data.item)">
                Use
              </b-button>
              <b-button v-else size="sm" class="btn wv-btn mr-1" @click="$emit('detach', data.item)">
                Release
              </b-button>
            </template>

            <b-button size="sm" class="btn wv-btn mr-1" @click="$emit('edit', data.item)">
              Edit
            </b-button>
            <b-button size="sm" class="btn wv-btn" @click="$emit('delete', data.item)">
              Delete
            </b-button>
          </div>
        </template>
      </virtual-table>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import { sortBy, find } from 'lodash'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'
import VirtualTable from '~/components/Base/VirtualTable'
import UserMixin from '~/mixins/user'
export default {
  components: {
    VirtualTable,
    vueCustomScrollbar
  },
  mixins: [UserMixin],
  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    perPage: {
      type: Number,
      default: process.env.paginate_per_page
    },
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    showAttach: {
      type: Boolean,
      default: false
    },
    attachedCases: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      currentPage: 1,
      filter: null,
      filterCount: 0,
      sortBy: 'CaseSize',
      sortDesc: false,
      settings: {
        tagname: 'div',
        suppressScrollY: true
      }
    }
  },
  computed: {
    totalTableRows() {
      if (this.filterCount > 0) {
        return this.filterCount
      }
      return this.items.length
    },
    itemsSorted() {
      const sortedItems = sortBy(this.items, this.sortBy)
      if (this.sortDesc) {
        return sortedItems.reverse()
      }
      return sortedItems
    },
    tableFields() {
      return [
        {
          key: 'TypeName',
          sortable: true,
          label: 'Type',
          width: '55px'
        },
        {
          key: 'FormatText',
          sortable: true,
          label: 'Format',
          width: '55px',
          tdClass: 'text-center'
        },
        {
          key: 'Notes',
          sortable: true,
          label: 'Notes',
          width: '120px'
        },
        {
          key: 'FullLocation',
          sortable: true,
          label: 'Location',
          width: '120px'
        },
        {
          key: 'Comments',
          sortable: true,
          label: 'Comments'
        },
        {
          key: 'actions',
          sortable: false,
          label: 'Actions',
          width: this.showAttach ? '150px' : '113px',
          tdClass: 'last-td'
        }
      ]
    }
  },
  methods: {
    setFilteredTableResult(results) {
      this.currentPage = 1
      this.filterCount = results.length
    },
    performFilter(filterText) {
      this.filter = filterText
    },
    performOrderBy(sort) {
      this.sortBy = sort.sortBy
      this.sortDesc = sort.sortDesc
      this.$emit('change-order', sort.sortBy)
    },
    setActiveCard(item) {
      this.$emit('set-active-item', item)
    },
    setHover(item) {
      this.hoveredId = item.id
    },
    fetchPage(page) {
      this.$emit('change-page', page)
    },
    getRowClass(item) {
      const classes = {}
      classes['row-selected'] = this.showAttach && item.POLID !== null
      return classes
    },
    isAttached(item) {
      if (
        find(this.attachedCases, { EmptyCaseID: item.EmptyCaseID }) !==
        undefined
      ) {
        return true
      }
      return false
    }
  }
}
</script>

<style>
.t-cases--modal table {
  width: 747px;
}

.t-cases--modal .virtual-scroll__content {
  background: #fef8dc;
}

.t-cases--modal .last-td {
  position: relative;
}

@media (min-width: 1630px) {
  .t-cases--modal .virtual-scroll__content {
    height: 170px;
  }
}
</style>

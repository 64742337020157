import Entity from '~/models/entity'

export const fields = {
  BPID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  ContactID: {
    type: Number
  },
  Price: {
    type: Number
  }
}

const BrokingPrice = new Entity('BrokingPrice', fields)

BrokingPrice.createMutation('update', 'brokingWineCardUpdate', {
  BrokingPrice: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  ContactID: {
    type: Number
  }
})

export default BrokingPrice

import Entity from '~/models/entity'

const fields = {
  ContactID: {
    type: Number
  },
  CompanyName: {
    type: String
  },
  ContactName: {
    type: String
  },
  EmailAddress1: {
    type: String
  },
  Telephone1: {
    type: String
  },
  Telephone2: {
    type: Number
  },
  Fax: {
    type: String
  },
  AddrID: {
    type: Number
  },
  Addr1: {
    type: String
  },
  Addr2: {
    type: String
  },
  Addr3: {
    type: String
  },
  Addr4: {
    type: String
  },
  Postcode: {
    type: String
  },
  CountryCode: {
    type: String
  },
  Country: {
    type: String
  },
  IsSageCustomer: {
    type: Boolean
  },
  IsSageSupplier: {
    type: Boolean
  },
  SageCustomerAcctNo: {
    type: String
  },
  SageSupplierAcctNo: {
    type: String
  },
  PricingGroup: {
    type: String
  },
  PricePer9L: {
    type: Number
  },
  FreeMonths: {
    type: Number
  },
  ExternalFreeMonths: {
    type: Number
  },
  RelatedContactID: {
    type: Number
  },
  NoStorageCharge: {
    type: Boolean
  }
}

const wineStockField = {
  WineCardID: {
    type: Number
  },
  ComputedWineName: {
    type: String
  },
  WineVintage: {
    type: String
  },
  FormatName: {
    type: String
  },
  FormatCaseSize: {
    type: Number
  },
  WineSellingPrice: {
    type: Number
  },
  POID: {
    type: String
  },
  LastAllocationDate: {
    type: String
  },
  AverageCostPrice: {
    type: Number
  },
  AvailableBottles: {
    type: Number
  },
  AvailableReceivedBottles: {
    type: Number
  },
  RotationNumber: {
    type: String
  },
  InBondCount: {
    type: Number
  },
  DutyPaidCount: {
    type: Number
  },
  ImageCount: {
    type: Number
  },
  OriginalSOID: {
    type: String
  },
  OriginalSODate: {
    type: String
  },
  UsageCode: {
    type: String
  },
  UsageCodeID: {
    type: Number
  },
  BrokingPrice: {
    type: Number
  },
  NoteCount: {
    type: Number
  },
  WineCardNotesString: {
    type: String
  },
  WineParkerScore: {
    type: String
  }
}

const allocationFields = {
  InventoryID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  POID: {
    type: Number
  },
  DateAllocated: {
    type: String
  },
  RotationNumber: {
    type: String
  },
  Price: {
    type: Number
  },
  AvailableBottles: {
    type: Number
  },
  RotationNotes: {
    type: String
  },
  InventoryStatusID: {
    type: Number
  },
  DutyStatusID: {
    type: Number
  },
  OriginalPackaging: {
    type: Boolean
  },
  OrigPkgCaseSize: {
    type: Number
  },
  LCBLocation: {
    type: String
  },
  ImageCount: {
    type: Number
  },
  FormatCaseSize: {
    type: Number
  },
  OriginalSOID: {
    type: Number
  },
  OriginalSODate: {
    type: Date
  },
  PublicNotes: {
    type: String
  },
  UsageCode: {
    type: String
  },
  UsageCodeID: {
    type: Number
  }
}

const ReserveCustomer = new Entity('ReserveCustomer', fields)

ReserveCustomer.createQuery('get', 'reserveCustomerCardGet', fields)

ReserveCustomer.createQuery(
  'getStock',
  'reserveCustomerStockGet',
  wineStockField
)

ReserveCustomer.createQuery(
  'getAllocations',
  'reserveCustomerAllocationsGet',
  allocationFields
)

ReserveCustomer.createMutation(
  'reserveCreateBuyBack',
  'reserveCreateBuyBack',
  {
    ContactID: {
      type: Number
    },
    Type: {
      type: String
    },
    BuyBacks: {
      type: Array,
      fields: {
        WineCardID: {
          type: Number
        },
        InventoryID: {
          type: Number
        },
        Bottles: {
          type: Number
        },
        DutyStatusID: {
          type: Number
        },
        WineUsageCodeID: {
          type: Number
        },
        Price: {
          type: Number
        }
      }
    }
  },
  {
    SOID: {
      type: Number
    },
    POID: {
      type: Number
    },
    StorageSOID: {
      type: Number
    }
  }
)

ReserveCustomer.createMutation(
  'updateInventoryUsage',
  'inventoryUsageUpdate',
  {
    WineUsageCodeID: {
      type: Number
    },
    InventoryIDs: {
      type: Array
    }
  },
  []
)

export default ReserveCustomer

import Entity from './entity'

export const fields = {
  CountrySubRegionID: {
    type: Number
  },
  CountryRegionID: {
    type: Number
  },
  SubRegion: {
    type: String
  },
  UseOnBackOffice: {
    type: Boolean
  }
}

const CountrySubRegion = new Entity('CountrySubRegion', fields)
CountrySubRegion.createQuery('get', 'countrySubRegionsGet')

CountrySubRegion.createMutation(
  'delete',
  'countrySubRegionDelete',
  {
    CountrySubRegionID: fields.CountrySubRegionID
  },
  []
)
CountrySubRegion.createMutation('insert', 'countrySubRegionAdd', fields)
CountrySubRegion.createMutation('update', 'countrySubRegionUpdate', fields)

export default CountrySubRegion

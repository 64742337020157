import {
  searchContacts,
  updateContact,
  getContact,
  getAddressForContact,
  getFavouritesForContact,
  getNotificationsForContact,
  deleteFavouritesForContact,
  deleteNotificationsForContact,
  detachContact
} from '@/api/web/contact'
import Vue from 'vue'
import { find, findIndex, filter } from 'lodash'

const defaultSearchParams = {
  SearchTerm: '',
  DateFrom: '',
  DateTo: '',
  Approved: false,
  HasContact: null,
  IncludeArchived: false,
  EmailVerified: true,
  PerPage: 100,
  Page: 1,
  OrderBy: 'AuditCreatedDate',
  OrderByDirection: 'DESC'
}

export const state = () => ({
  loading: false,
  loadingContact: false,
  loadingAddresses: false,
  loadingDetach: false,
  total: 0,
  contacts: [],
  selectedWebContactID: null,
  selectedWebContactAddresses: [],
  saving: false,
  searchParameters: Object.assign({}, defaultSearchParams),
  changedWebContactDetails: {},
  changedContactDetails: {},
  changedWebContactInvoiceAddress: {},
  changedContactAltAddress: {},
  changedContactInvoiceAddress: {},
  preferredInvoiceAddress: {},
  // delivery
  changedWebDeliveryAddress: {},
  changedDeliveryAddress: [],
  changedDeliveryAddressMap: [],
  preferredDeliveryAddress: {},
  actionForDeliveryAddress: {},
  // additional
  additionalAddresses: [],
  loadingFavourites: false,
  favourites: [],
  loadingNotifications: false,
  notifications: []
})

export const getters = {
  selectedContactInvoiceAddress: state => {
    return find(
      state.selectedWebContactAddresses,
      c => c.WebContactID === state.selectedWebContactID && !c.IsDelivery
    )
  },
  selectedContactDeliveryAddresses: state => {
    return filter(
      state.selectedWebContactAddresses,
      c => c.WebContactID === state.selectedWebContactID && c.IsDelivery
    )
  },
  selectedContact: state => {
    return find(
      state.contacts,
      c => c.WebContactID === state.selectedWebContactID
    )
  },
  getContact: state => WebContactID => {
    return find(state.contacts, c => c.WebContactID === WebContactID)
  },
  getChangedWebContactDetails: state => WebContactID => {
    return state.changedWebContactDetails[WebContactID]
      ? state.changedWebContactDetails[WebContactID]
      : null
  },
  getChangedContactDetails: state => ContactID => {
    return state.changedContactDetails[ContactID]
      ? state.changedContactDetails[ContactID]
      : null
  },
  getChangedWebContactInvoiceAddress: state => WebContactID => {
    return state.changedWebContactInvoiceAddress[WebContactID]
      ? state.changedWebContactInvoiceAddress[WebContactID]
      : null
  },
  getChangedContactInvoiceAddress: state => ContactID => {
    return state.changedContactInvoiceAddress[ContactID]
      ? state.changedContactInvoiceAddress[ContactID]
      : null
  },
  getChangedContactAltAddress: state => ContactID => {
    return state.changedContactAltAddress[ContactID]
      ? state.changedContactAltAddress[ContactID]
      : null
  },
  getPreferredInvoiceAddress: state => WebContactID => {
    const defaultAddressPreference = 'contact'
    return state.preferredInvoiceAddress[WebContactID]
      ? state.preferredInvoiceAddress[WebContactID]
      : defaultAddressPreference
  },
  getChangedWebDeliveryAddress: state => WebContactAddressID => {
    return state.changedWebDeliveryAddress[WebContactAddressID]
      ? state.changedWebDeliveryAddress[WebContactAddressID]
      : null
  },
  getChangedDeliveryAddress: state => ({ WebContactAddressID, AddrID }) => {
    const existingAddr = find(state.changedDeliveryAddressMap, {
      AddrID,
      WebContactAddressID
    })
    if (existingAddr) {
      return state.changedDeliveryAddress[existingAddr.idx]
    }
    return null
  },
  getPreferredDeliveryAddress: state => WebContactAddressID => {
    return state.preferredDeliveryAddress[WebContactAddressID]
      ? state.preferredDeliveryAddress[WebContactAddressID]
      : null
  },
  getActionForDeliveryAddress: state => WebContactAddressID => {
    return state.actionForDeliveryAddress[WebContactAddressID]
      ? state.actionForDeliveryAddress[WebContactAddressID]
      : null
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoadingAddresses(state, loading) {
    state.loadingAddresses = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  setContacts(state, contacts) {
    state.contacts = contacts
  },
  appendContacts(state, contacts) {
    state.contacts = state.contacts.concat(contacts)
  },
  setTotal(state, total) {
    state.total = total
  },
  setSearchParameter(state, { key, value }) {
    Vue.set(state.searchParameters, key, value)
  },
  setSelectedWebContactID(state, id) {
    state.selectedWebContactID = id
  },
  setSelectedWebContactAddresses(state, addresses) {
    state.selectedWebContactAddresses = addresses
  },
  removeContact(state, WebContactID) {
    state.contacts = filter(
      state.contacts,
      c => c.WebContactID !== WebContactID
    )
  },
  resetSearchParameters(state) {
    state.searchParameters = defaultSearchParams
    state.total = 0
    state.contacts = []
    state.selectedWebContactID = null
  },
  replaceOrAppendContact(state, contact) {
    const idx = findIndex(
      state.contacts,
      c => c.WebContactID === contact.WebContactID
    )
    if (idx >= 0) {
      Vue.set(state.contacts, idx, contact)
    } else {
      state.contacts.push(contact)
    }
  },
  setChangedWebContactDetails(state, { WebContactID, Changes }) {
    const clonedChanges = Object.assign({}, Changes)
    Vue.set(state.changedWebContactDetails, WebContactID, clonedChanges)
  },
  setChangedContactDetails(state, { ContactID, Changes }) {
    const clonedChanges = Object.assign({}, Changes)
    Vue.set(state.changedContactDetails, ContactID, clonedChanges)
  },
  removeChangedWebContactDetails(state, WebContactID) {
    Vue.delete(state.changedWebContactDetails, WebContactID)
  },
  removeChangedContactDetails(state, ContactID) {
    Vue.delete(state.changedContactDetails, ContactID)
  },
  setChangedWebContactInvoiceAddress(state, { WebContactID, Changes }) {
    const clonedChanges = Object.assign({}, Changes)
    Vue.set(state.changedWebContactInvoiceAddress, WebContactID, clonedChanges)
  },
  setChangedWebContactAltAddress(state, { WebContactID, Changes }) {
    const clonedChanges = Object.assign({}, Changes)
    Vue.set(state.changedContactAltAddress, WebContactID, clonedChanges)
  },
  setChangedContactInvoiceAddress(state, { ContactID, Changes }) {
    const clonedChanges = Object.assign({}, Changes)
    Vue.set(state.changedContactInvoiceAddress, ContactID, clonedChanges)
  },
  removeChangedWebContactInvoiceAddress(state, WebContactID) {
    Vue.delete(state.changedWebContactInvoiceAddress, WebContactID)
  },
  removeChangedContactInvoiceAddress(state, ContactID) {
    Vue.delete(state.changedContactInvoiceAddress, ContactID)
  },
  setPreferredInvoiceAddress(state, { WebContactID, use }) {
    Vue.set(state.preferredInvoiceAddress, WebContactID, use)
  },
  removePreferredInvoiceAddress(state, { WebContactID }) {
    Vue.delete(state.preferredInvoiceAddress, WebContactID)
  },
  setChangedWebDeliveryAddress(state, { WebContactAddressID, Changes }) {
    const clonedChanges = Object.assign({}, Changes)
    Vue.set(state.changedWebDeliveryAddress, WebContactAddressID, clonedChanges)
  },
  setChangedDeliveryAddress(state, { AddrID, WebContactAddressID, Changes }) {
    const clonedChanges = Object.assign({}, Changes)
    const existingAddr = find(state.changedDeliveryAddressMap, {
      AddrID,
      WebContactAddressID
    })
    if (existingAddr) {
      Vue.set(state.changedDeliveryAddress, existingAddr.idx, clonedChanges)
    } else {
      const idx = state.changedDeliveryAddress.length
      Vue.set(state.changedDeliveryAddress, idx, clonedChanges)
      state.changedDeliveryAddressMap.push({
        AddrID,
        WebContactAddressID,
        idx
      })
    }
  },
  setPreferredDeliveryAddress(state, { WebContactAddressID, use }) {
    Vue.set(state.preferredDeliveryAddress, WebContactAddressID, use)
  },
  setActionForDeliveryAddress(state, { WebContactAddressID, action }) {
    state.additionalAddresses = filter(state.additionalAddresses, a => {
      return a.selectedAddrID === 0 || a.selectedAddrID !== action
    })
    Vue.set(state.actionForDeliveryAddress, WebContactAddressID, action)
  },
  removeChangedWebDeliveryAddress(state, WebContactAddressID) {
    Vue.delete(state.changedWebDeliveryAddress, WebContactAddressID)
  },
  resetChangeDeliveryAddresses(state) {
    state.changedWebDeliveryAddress = {}
    state.changedDeliveryAddress = []
    state.changedDeliveryAddressMap = []
  },
  removeChangedDeliveryAddresses(state, WebContactAddressID) {
    const keepAddresses = []
    const keepMap = state.changedDeliveryAddressMap
      .filter(m => m.WebContactAddressID !== WebContactAddressID)
      .map((m, idx) => {
        keepAddresses.push(state.changedDeliveryAddress)
        return {
          AddrID: m.AddrID,
          WebContactAddressID: m.WebContactAddressID,
          idx
        }
      })
    state.changedDeliveryAddress = keepAddresses
    state.changedDeliveryAddressMap = keepMap
  },
  removePreferredDeliveryAddress(state, WebContactAddressID) {
    Vue.delete(state.preferredDeliveryAddress, WebContactAddressID)
  },
  removeActionForDeliveryAddress(state, WebContactAddressID) {
    Vue.delete(state.actionForDeliveryAddress, WebContactAddressID)
  },
  createAdditionalAddress(state) {
    state.additionalAddresses.push({
      selectedAddrID: 0,
      changedDetails: {}
    })
  },
  setSelectedDetailsAdditionalAddress(
    state,
    { id, selectedAddrID, changedDetails }
  ) {
    Vue.set(state.additionalAddresses, id, {
      selectedAddrID,
      changedDetails
    })
  },
  resetAdditionalAddress(state) {
    state.additionalAddresses = []
  },
  removeAdditionalAddress(state, id) {
    Vue.delete(state.additionalAddresses, id)
  },
  setLoadingFavourites(state, loading) {
    state.loadingFavourites = loading === true
  },
  setLoadingDetach(state, loading) {
    state.loadingDetach = loading === true
  },
  replaceFavourites(state, favourites) {
    state.favourites = favourites
  },
  setLoadingNotifications(state, loading) {
    state.loadingNotifications = loading === true
  },
  replaceNotifications(state, notifications) {
    state.notifications = notifications
  },
  setLoadingContact(state, loading) {
    state.loadingContact = loading === true
  }
}

export const actions = {
  async searchContact({ commit, state }, args) {
    try {
      commit('setLoading', true)
      const contacts = await searchContacts(args)
      commit('setTotal', contacts.total)
      if (state.searchParameters.Page === 1) {
        commit('setContacts', contacts.webContactPaginatedResults)
      } else {
        commit('appendContacts', contacts.webContactPaginatedResults)
      }
      return contacts
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async updateContact({ commit, state }, args) {
    try {
      commit('setSaving', true)
      const contact = await updateContact(args)
      commit('replaceOrAppendContact', contact.WebContact)
      commit('setSelectedWebContactAddresses', contact.Addresses)
      commit('resetAdditionalAddress')
      return contact
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async getContact({ commit, getters }, WebContactID) {
    try {
      commit('setLoadingContact', true)
      let loadedContact = getters.getContact(WebContactID)
      if (loadedContact) {
        commit('setSelectedWebContactID', WebContactID)
        return loadedContact
      }
      loadedContact = await getContact(WebContactID)
      commit('appendContacts', [loadedContact])
      commit('setSelectedWebContactID', WebContactID)
      return loadedContact
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingContact', false)
    }
  },
  async getAddresses({ commit, getters }, WebContactID) {
    try {
      commit('setLoadingAddresses', true)
      const addresses = await getAddressForContact(WebContactID)
      commit('setSelectedWebContactAddresses', addresses)
      commit('setSelectedWebContactID', WebContactID)
      return addresses
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingAddresses', false)
    }
  },
  async getFavourites({ commit, getters }, WebContactID) {
    try {
      commit('setLoadingFavourites', true)
      const favourites = await getFavouritesForContact(WebContactID)
      commit('replaceFavourites', favourites)
      return favourites
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingFavourites', false)
    }
  },
  async getNotifications({ commit, getters }, WebContactID) {
    try {
      commit('setLoadingNotifications', true)
      const notifications = await getNotificationsForContact(WebContactID)
      commit('replaceNotifications', notifications)
      return notifications
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingNotifications', false)
    }
  },
  async deleteNotification({ commit, state }, args) {
    try {
      commit('setLoadingNotifications', true)
      const notification = await deleteNotificationsForContact(args)
      const notifications = filter(
        state.notifications,
        n => n.WineCardID !== notification.WineCardID
      )
      commit('replaceNotifications', notifications)
      return notification
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingNotifications', false)
    }
  },
  async deleteFavourite({ commit, state }, args) {
    try {
      commit('setLoadingFavourites', true)
      const favourite = await deleteFavouritesForContact(args)
      const favourites = filter(
        state.favourites,
        f => f.WineCardID !== favourite.WineCardID
      )
      commit('replaceFavourites', favourites)
      return favourite
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingFavourites', false)
    }
  },
  detachContact({ commit, state }, args) {
    try {
      commit('setLoadingDetach', true)
      return detachContact(args)
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingDetach', false)
    }
  }
}

import Entity from './entity'

export const fields = {
  AddrID: {
    type: Number
  },
  ContactID: {
    type: Number
  },
  Addr1: {
    type: String
  },
  Addr2: {
    type: String
  },
  Addr3: {
    type: String
  },
  Addr4: {
    type: String
  },
  Postcode: {
    type: String
  },
  CountryCode: {
    type: String
  },
  CountryName: {
    type: String
  },
  ForAttentionOf: {
    type: String
  },
  Telephone: {
    type: String
  },
  DeliveryCompany: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  }
}

const Address = new Entity('Address', fields)

Address.createQuery('get', 'addressGet', fields)
Address.createQuery('getPrimaryAddress', 'contactPrimaryAddressGet', fields)
Address.createQuery('getAnyAddress', 'addressOrWarehouseGet', fields)
Address.createQuery('getAllForContact', 'contactAddressesGet', fields)

Address.createMutation('update', 'addressUpdate', fields)
Address.createMutation(
  'insert',
  'addressAdd',
  Entity.allButKeys(fields, ['AddrID', 'AuditAmendedDate'])
)
Address.createMutation(
  'delete',
  'addressDelete',
  {
    ID: fields.AddrID
  },
  []
)

export default Address

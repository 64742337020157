<template>
  <div>
    <div v-if="loading && page === 1" class="text-center text-primary my-2 mx-auto">
      <b-spinner type="grow" label="Loading..." />
    </div>
    <vue-custom-scrollbar
      v-else
      class="virtual-scroll t-notes-extended"
      :settings="settings"
    >
      <virtual-table
        class=""
        :fields="tableFields"
        :items="notes"
        :per-page="perPage"
        :current-page="page"
        :total="total"
        :show-loading="true"
        :loading="loading"
        :estimate-total-scroll-height="false"
        primary-key="WineCardNoteID"
        @request-page="$emit('change-page', $event)"
        @sort-changed="performOrderBy"
      >
        <template slot="table-colgroup" slot-scope="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.width, maxWidth: field.width, overflow: 'hidden' }"
          >
        </template>
        <template slot="AvailableBottles" slot-scope="data">
          <stock-level
            :justify="true"
            :total-bottles="data.item.AvailableBottles"
            :case-size="data.item.CaseSize"
          />
        </template>
        <template slot="DateUpdated" slot-scope="data">
          <date :date="data.item.DateUpdated" format="DD-MMM-YY HH:mm" />
        </template>
        <template slot="actions" slot-scope="data">
          <b-button size="sm" class="btn wv-btn mr-1" @click="$emit('edit',data.item)">
            Edit
          </b-button>
          <b-button size="sm" class="btn wv-btn" @click="$emit('delete',data.item)">
            Delete
          </b-button>
        </template>
      </virtual-table>
    </vue-custom-scrollbar>
  </div>
</template>
<script>
import Date from '~/components/Base/Date'
import VirtualTable from '~/components/Base/VirtualTable'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'
import StockLevel from '~/components/Base/StockLevel'
import { mapState } from 'vuex'

export default {
  components: {
    Date,
    VirtualTable,
    StockLevel,
    vueCustomScrollbar
  },
  props: {
    perPage: {
      type: Number,
      default: 100
    },
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },
    extendedTable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      settings: {
        tagname: 'div',
        suppressScrollY: true
      }
    }
  },
  computed: {
    ...mapState('wineCard/notes', {
      notes: state => state.notes
    }),
    tableFields() {
      const tableFields = []
      tableFields.push({
        key: 'WineCardNoteID',
        sortable: true,
        width: '53px',
        label: 'WineCardNoteID',
        tdClass: 'text-center'
      })

      tableFields.push({
        key: 'WineVintage',
        sortable: true,
        width: '53px',
        label: 'Vintage',
        tdClass: 'text-center'
      })

      tableFields.push({
        key: 'Wine',
        sortable: true,
        width: '300px'
      })
      tableFields.push({
        key: 'AvailableBottles',
        sortable: true,
        width: '80px',
        label: 'Available'
      })
      tableFields.push({
        key: 'DateUpdated',
        label: 'Date',
        sortable: true,
        width: '100px'
      })
      tableFields.push({
        key: 'Note',
        sortable: true,
        tdClass: 'text-wrap'
      })
      tableFields.push({
        key: 'UpdateUser',
        label: 'User',
        sortable: true,
        width: '100px'
      })
      tableFields.push({
        key: 'actions',
        sortable: false,
        label: '',
        width: '113px',
        tdClass: 'last-td'
      })

      return tableFields
    }
  },
  methods: {
    performOrderBy(sortBy) {
      this.$emit('order-by', sortBy)
    }
  }
}
</script>
<style>
.t-notes-extended table {
  width: 1447px;
}

.t-notes-extended .last-td {
  position: relative;
}

.t-notes-extended .last-td .wv-btn {
  position: absolute;
  height: 100% !important;
  width: 50%;
  top: 0;
  left: 0;
}

.t-notes-extended .last-td .wv-btn + .wv-btn {
  left: 50%;
}

@media (min-width: 1630px) {
  .t-notes-extended .virtual-scroll__content {
    height: 816px;
  }
}
</style>

import { searchContact } from '@/api/reserves/contactMatches'
import { reduce } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  page: 1,
  perPage: 74,
  total: 0,
  loadedPage: [],
  pageOffsets: {},
  results: [],
  indexedId: {},
  selected: null,
  searchParameters: {
    SearchTerm: '',
    Active: 'Active', // status
    SearchType: '', // type
    PromotionProfileCode: '', // profile,
    ReservesCount: 1,
    CurrentReservesTotal: 1,
    BrokingCount: 0
  }
})

export const getters = {
  pageResults: state => {
    const offset = state.pageOffsets[state.page] || 0
    return state.results.slice(offset, offset + state.perPage)
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setResults(state, results) {
    state.results = results
    state.indexedId = reduce(
      results,
      (keyMap, r, i) => {
        keyMap[r.id] = i
        return keyMap
      },
      {}
    )
  },
  resetSelection(state) {
    state.results = []
    state.indexedId = {}
    state.page = 1
    state.loadedPage = []
    state.pageOffsets = {}
    state.setActiveId = null
    state.selected = null
  },
  resetSearchParameters(state, broking = false) {
    // state.lnd = false
    state.searchParameters = {
      SearchTerm: '',
      Active: 'Active', // status
      SearchType: '', // type
      PromotionProfileCode: '', // profile
      ReservesCount: broking ? 0 : 1,
      CurrentReservesTotal: 1,
      BrokingCount: broking ? 1 : 0
    }
  },
  resetJustSearchFilters(state, broking = false) {
    // state.lnd = false
    state.searchParameters = {
      SearchTerm: state.searchParameters.SearchTerm,
      Active: 'Active', // status
      SearchType: '', // type
      PromotionProfileCode: '', // profile
      ReservesCount: broking ? 0 : 1,
      CurrentReservesTotal: 1,
      BrokingCount: broking ? 1 : 0
    }
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setPageOffset(state, page) {
    Vue.set(state.pageOffsets, page, state.results.length)
  },
  setLoadedPage(state, page) {
    if (state.loadedPage.indexOf(page) < 0) {
      state.loadedPage.push(page)
    }
  },
  setSelectedValue(state, { id, value }) {
    if (value) {
      state.selected = id
    } else {
      state.selected = null
    }
  },
  setSearchParameter(state, { key, value }) {
    Vue.set(state.searchParameters, key, value)
  }
}

export const actions = {
  async performSearch({ commit, state }, { searchParameters }) {
    commit('setLoading', true)
    commit('resetSelection')
    const response = await searchContact(searchParameters)
    commit('setPageOffset', state.page)
    commit('setResults', response)
    commit('setTotal', response.length)
    commit('setLoadedPage', state.page)
    commit('setLoading', false)
    return response
  },
  changePage({ commit, state }, { page, searchParameters }) {
    if (state.loadedPage.indexOf(page) >= 0) {
      commit('setPage', page)
      return
    }
    commit('setPage', page)
    commit('setLoading', true)
    commit('setPageOffset', page)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  }
}

import Entity from './entity'

export const fields = {
  WineDescription: {
    type: String
  },
  EmptyCaseID: {
    type: Number
  },
  ChateauText: {
    type: String
  },
  VintageText: {
    type: String
  },
  FormatText: {
    type: String
  },
  Notes: {
    type: String
  },
  Location: {
    type: String
  },
  LocationPrefix: {
    type: String
  },
  Comments: {
    type: String
  },
  FileWriteTime: {
    type: String
  },
  WineCardID: {
    type: Number
  },
  CaseSize: {
    type: Number
  },
  Type: {
    type: Number
  },
  POLID: {
    type: Number
  },
  TypeName: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  EmptyCaseInfo: {
    type: String
  },
  FullLocation: {
    type: String
  }
}

const EmptyCases = new Entity('EmptyCases', fields)
EmptyCases.createQuery('getAll', 'emptyCases', fields, {
  WineCardID: {
    type: Number
  },
  POLID: {
    type: Number
  }
})

EmptyCases.createMutation(
  'attach',
  'emptyCaseAttach',
  Entity.onlyKeys(fields, ['EmptyCaseID', 'POLID']),
  {
    ...fields,
    EmptyCaseInfo: {
      type: String
    }
  }
)

EmptyCases.createMutation(
  'detach',
  'emptyCaseDetach',
  Entity.onlyKeys(fields, ['EmptyCaseID', 'POLID']),
  {
    ...fields,
    EmptyCaseInfo: {
      type: String
    }
  }
)

EmptyCases.createMutation('create', 'emptyCaseAdd', fields, {
  ...Entity.onlyKeys(fields, [
    'WineCardID',
    'ChateauText',
    'VintageText',
    'FormatText',
    'CaseSize',
    'Notes',
    'Location',
    'LocationPrefix',
    'Comments',
    'Type',
    'AuditAmendedDate'
  ]),
  EmptyCaseInfo: {
    type: String
  }
})

EmptyCases.createMutation('update', 'emptyCaseUpdate', fields, {
  ...Entity.onlyKeys(fields, [
    'WineCardID',
    'ChateauText',
    'VintageText',
    'FormatText',
    'CaseSize',
    'Notes',
    'Location',
    'LocationPrefix',
    'Comments',
    'Type',
    'AuditAmendedDate'
  ]),
  EmptyCaseInfo: {
    type: String
  }
})

EmptyCases.createMutation(
  'delete',
  'emptyCaseDelete',
  {
    ID: {
      type: Number
    }
  },
  []
)

export default EmptyCases

import Vue from 'vue'
import {
  getWineStockCard,
  getWineCustomerReserves,
  getWineCardWineAllocations,
  getEmailAddresses,
  sendEmails
} from '~/api/reserves/wineStockCard'

export const state = () => ({
  wineStockCard: {},
  stock: [],
  activeContactID: null,
  allocations: {},
  emailAddresses: [],
  activeWineCardID: null
})

export const getters = {
  getActiveContact: state => {
    for (let i = 0; i < state.stock.length; i++) {
      if (state.stock[i].ContactID === state.activeContactID) {
        return state.stock[i]
      }
    }
    return null
  },
  getActiveContactForContactID: state => contactId => {
    for (let i = 0; i < state.stock.length; i++) {
      if (state.stock[i].ContactID === contactId) {
        return state.stock[i]
      }
    }
    return null
  },
  getActiveAllocations: state => {
    // console.log(
    //   'getActiveAllocations',
    //   state.allocations,
    //   state.activeContactID,
    //   state.allocations[state.activeContactID] || []
    // )
    return state.allocations[state.activeContactID] || []
  },
  getAllocationsForContactID: state => contactId => {
    return state.allocations[contactId] || []
  },
  getIncluded(state) {
    return state.stock.filter(
      p => p.Include !== undefined && p.Include === true
    )
  },
  getIncludedEmailAddresses(state) {
    return state.emailAddresses.filter(
      e => e.Include !== undefined && e.Include === true
    )
  }
}

export const mutations = {
  setWineStockCard(state, wineStockCard) {
    state.wineStockCard = wineStockCard
  },
  setWineCustomerReserves(state, stock) {
    state.stock = stock
  },
  setActiveContactId(state, contactID) {
    state.activeContactID = contactID
    // console.log('setActiveContactId', contactID, state.activeContactID)
  },
  setActiveWineCardId(state, WineCardID) {
    state.activeWineCardID = WineCardID
    // console.log('setActiveWineCardId', WineCardID, state.activeWineCardID)
  },
  setAllocations(state, { contactId, allocations }) {
    // console.log('setting', contactId, allocations)
    Vue.set(state.allocations, contactId, allocations)
  },
  setEmailAddresses(state, emailAddresses) {
    state.emailAddresses = emailAddresses
  },
  updateIncludeState(state, { ContactID, value }) {
    const idx = state.stock.findIndex(p => p.ContactID === ContactID)
    if (idx > -1) {
      Vue.set(state.stock[idx], 'Include', value)
    }
  },
  includeAll(state) {
    state.stock.forEach(function(s, i) {
      if (!state.stock[i].NoEmailContact) {
        Vue.set(state.stock[i], 'Include', true)
      }
    })
  }
}

export const actions = {
  async getWineStockCard({ commit, dispatch }, id) {
    const result = await getWineStockCard(id)
    if (result) {
      commit('setWineStockCard', result)
      dispatch('reserves/reserves/appendWineCard', result.WineCard, {
        root: true
      })
    }
    return result
  },
  async getWineCustomerStock({ commit }, id) {
    const result = await getWineCustomerReserves(id)
    if (result) {
      commit('setWineCustomerReserves', result)
    }
    return result
  },
  async getWineAllocations({ commit, state }, id) {
    const allocations = await getWineCardWineAllocations({
      WineCardID: state.wineStockCard.WineCard.WineCardID,
      ContactID: id
    })
    if (allocations) {
      commit('setActiveContactId', id)
      commit('setAllocations', { contactId: id, allocations })
    }
    return allocations
  },
  async getEmailAddresses({ commit, state }, ids) {
    // console.group('State', state)
    let emailAddresses = await getEmailAddresses({
      ContactIds: ids
    })
    emailAddresses = emailAddresses.map(function(email) {
      email.Include = email.EmailAddress.length > 0
      return email
    })
    // console.log('emailAddresses', emailAddresses)
    if (emailAddresses) {
      commit('setEmailAddresses', emailAddresses)
    }
    return emailAddresses
  },
  async sendEmails({ commit, state }, args) {
    const sentEmails = await sendEmails(args)
    return sentEmails
  }
}

import {
  getAddressById,
  updateAddress,
  addAddress,
  deleteAddressById,
  getPrimaryAddressByContactId,
  getAllForContact
} from '@/api/contacts/addresses'
import { getAddressDropDownById } from '@/api/lists'
import { filter } from 'lodash'

export const state = () => ({
  loading: false,
  address: null,
  addresses: [],
  fullAddresses: []
})

export const getters = {
  addressesWithoutWarehouse: state => {
    return filter(state.addresses, address => {
      return !address.IsWarehouse
    })
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setAddress(state, address) {
    state.address = address
  },
  setAddresses(state, addresses) {
    state.addresses = addresses
  },
  setFullAddresses(state, addresses) {
    state.fullAddresses = addresses
  },
  reset(state) {
    state.address = null
    state.addresses = []
  }
}

export const actions = {
  async loadAddressesForContact({ commit }, id) {
    commit('setLoading', true)
    try {
      const response = await getAddressDropDownById({
        ContactID: id,
        Multiline: false,
        IncludeWarehouses: true,
        FullAddress: false
      })
      commit('setAddresses', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadFullAddressesForContact({ commit }, id) {
    commit('setLoading', true)
    try {
      const response = await getAllForContact(id)
      commit('setFullAddresses', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadPrimaryAddressForContact({ commit }, id) {
    commit('setLoading', true)
    try {
      const response = await getPrimaryAddressByContactId(id)
      commit('setAddress', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadAddress({ commit, state, dispatch }, id) {
    try {
      if (state.address === null || state.address.AddrID !== id) {
        return await dispatch('loadAddressForced', id)
      }
      return state.address
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadAddressForced({ commit, state }, id) {
    commit('setLoading', true)
    try {
      const address = await getAddressById(id)
      commit('setAddress', address)
      return address
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async updateAddress({ commit }, address) {
    commit('setLoading', true)
    try {
      const updatedAddress = await updateAddress(address)
      commit('setAddress', updatedAddress)
      return updatedAddress
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async addAddress({ commit }, address) {
    commit('setLoading', true)
    try {
      const addedAddress = await addAddress(address)
      commit('setAddress', addedAddress)
      return addedAddress
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async deleteAddress({ commit }, id) {
    commit('setLoading', true)
    try {
      return await deleteAddressById(id)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

<template>
  <div>
    <b-row class="mt-2">
      <b-col class="d-flex flex-row">
        <b-form-radio v-model="preset" :value="1" class="text-left mr-4" @change="handleReportPreset(1)">
          Report 1
          <b-button id="report1Popover" variant="link" class="wv-btn wv-btn--info" size="sm">
            <i class="material-icons">
              info
            </i>
          </b-button>
        </b-form-radio>
        <b-form-radio v-model="preset" :value="2" class="text-left mr-4" @change="handleReportPreset(2)">
          Report 2
          <b-button id="report2Popover" variant="link" class="wv-btn wv-btn--info" size="sm">
            <i class="material-icons">
              info
            </i>
          </b-button>
        </b-form-radio>
        <b-form-radio v-model="preset" :value="3" class="text-left mr-4" @change="handleReportPreset(3)">
          Report 3
          <b-button id="report3Popover" variant="link" class="wv-btn wv-btn--info" size="sm">
            <i class="material-icons">
              info
            </i>
          </b-button>
        </b-form-radio>
      </b-col>
    </b-row>
    <standard-popover target="report1Popover" header="Report 1">
      This report will get all stock and combine cases and bottles into the format case size of each wine card
    </standard-popover>
    <standard-popover target="report2Popover" header="Report 2">
      This report will get just OWC stock and display just OWC cases split up by OWC case size. Loose bottles are not displayed.
    </standard-popover>
    <standard-popover target="report3Popover" header="Report 3">
      This will get all stock and split up each case size and output OWC, Repacks and Loose Bottles
    </standard-popover>
  </div>
</template>

<script>
import StandardPopover from '../../../Popovers/StandardPopover'
export default {
  components: {
    StandardPopover
  },
  data() {
    return {
      preset: 0
    }
  },
  methods: {
    handleReportPreset(reportType) {
      this.$emit('change-usage', 'pub')
      this.$emit('change-duty-status', 'allStock')
      this.$emit('change-margin-check', 'nonIndividualLoss')
      this.$emit('change-loose-bottle-check', 'noCheck')
      switch (reportType) {
        case 1:
          this.$emit('change-packaging', 'allStock')
          this.$emit('change-grouping', 'ALL')
          break
        case 2:
          this.$emit('change-packaging', 'justOwc')
          this.$emit('change-grouping', 'BOTH')
          break
        case 3:
          this.$emit('change-packaging', 'allStock')
          this.$emit('change-grouping', 'OWC')
          break
      }
    }
  }
}
</script>

import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('wineCard/activeCard/', {
      loading: state => state.loading
    }),
    ...mapState('lists/', [
      'formatOptions',
      'loadingFormatOptions',
      'categoryOptions',
      'loadingCategoryOptions',
      'usageOptions',
      'loadingUsageOptions',
      'countryOptions',
      'loadingCountryOptions',
      'wineColourOptions',
      'loadingWineColourOptions',
      'designationOptions',
      'loadingDesignationOptions',
      'classificationOptions',
      'loadingClassificationOptions',
      'countryRegionOptions',
      'loadingCountryRegionOptions',
      'countrySubRegionOptions',
      'loadingCountrySubRegionOptions',
      'vineyardOptions',
      'loadingVineyards'
    ]),
    formLoading() {
      return (
        this.loadingFormatOptions ||
        this.loadingCategoryOptions ||
        this.loadingUsageOptions ||
        this.loadingCountryOptions ||
        this.loadingWineColourOptions ||
        this.loadingDesignationOptions ||
        this.loadingClassificationOptions ||
        this.loadingCountryRegionOptions ||
        this.loadingCountrySubRegionOptions ||
        this.loadingVineyards
      )
    }
  }
}

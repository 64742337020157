import Entity from '../entity'

const fields = {
  ID: {
    type: Number
  }
}

const ETallyCardDocument = new Entity('ETallyCardDocument', fields)

ETallyCardDocument.createQuery(
  'get',
  'eTallyDocumentsGet',
  {
    Documents: {
      type: Array,
      fields: {
        ETCDID: {
          type: Number
        },
        UUID: {
          type: String
        },
        Size: {
          type: Number
        },
        Status: {
          type: String
        },
        Filename: {
          type: String
        }
      }
    }
  },
  {
    ETCID: {
      type: Number
    }
  }
)

ETallyCardDocument.createMutation(
  'delete',
  'etallyDocumentDelete',
  {
    UUID: {
      type: String
    }
  },
  {
    ETCDID: {
      type: Number
    },
    UUID: {
      type: String
    },
    Size: {
      type: Number
    },
    Status: {
      type: String
    },
    Filename: {
      type: String
    }
  }
)

export default ETallyCardDocument

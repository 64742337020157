import Entity from './entity'

const fields = {
  RotationCardID: {
    type: Number
  },
  RotationNumber: {
    type: String
  },
  WineCardID: {
    type: Number
  },
  Notes: {
    type: String
  },
  WineDescription: {
    type: String
  },
  WineSubDescription: {
    type: String
  },
  RotationCardUsage: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  PurchaseOrderNumber: {
    type: Number
  },
  Price: {
    type: Number
  },
  WineUsageCodeID: {
    type: Number
  },
  TotalBottles: {
    type: Number
  },
  AllocatedBottles: {
    type: Number
  },
  CaseSize: {
    type: Number
  },
  TallyCards: {
    type: Array,
    fields: {
      ETCID: {
        type: Number
      },
      UID: {
        type: String
      }
    }
  }
}

const imagePathsFields = {
  RotationCardImageID: {
    type: Number
  },
  Extension: {
    type: String
  },
  MimeType: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  }
}

const RotationCard = new Entity('RotationCard', fields)

RotationCard.createQuery('get', 'rotationCardGet')
RotationCard.createQuery('getId', 'rotationCardGetIdFromNumber', [])
RotationCard.createQuery(
  'getPaths',
  'rotationCardImagePathsGet',
  imagePathsFields
)
RotationCard.createQuery('getByNumber', 'rotationCardGetByNumber')

RotationCard.createMutation(
  'update',
  'rotationCardUpdate',
  Entity.onlyKeys(fields, [
    'RotationCardID',
    'Notes',
    'RotationNumber',
    'AuditAmendedDate'
  ])
)

export default RotationCard

import {
  getAllRates,
  updateRate,
  addRate,
  deleteRate
} from '@/api/administration/rates'

import { findIndex } from 'lodash'
import moment from 'moment'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  rateLoading: false,
  list: []
})

export const getters = {
  getRatesById: state => id => {
    return state.list.filter(r => r.RateType === id)
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setRateLoading(state, loading) {
    state.rateLoading = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  addToList(state, rate) {
    state.list.push(rate)
  },
  updateRate(state, rate) {
    const rateDate = moment(rate.StartDate).format('YYYY-MM-DD')
    const idx = findIndex(state.list, r => {
      return (
        r.RateType === rate.RateType &&
        rateDate === moment(r.StartDate).format('YYYY-MM-DD')
      )
    })
    if (idx >= 0) {
      Vue.set(state.list, idx, rate)
    }
  },
  deleteRate(state, rate) {
    const rateDate = moment(rate.StartDate).format('YYYY-MM-DD')
    const idx = findIndex(state.list, r => {
      return (
        r.RateType === rate.RateType &&
        rateDate === moment(r.StartDate).format('YYYY-MM-DD')
      )
    })
    if (idx >= 0) {
      Vue.delete(state.list, idx)
    }
  }
}

export const actions = {
  async loadList({ commit }) {
    commit('setLoading', true)
    try {
      const response = await getAllRates()
      commit('setList', response)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async updateRate({ commit, dispatch, state }, rate) {
    commit('setRateLoading', true)
    try {
      const newRate = await updateRate(rate)
      commit('updateRate', newRate)
      return newRate
    } catch (e) {
      throw e
    } finally {
      commit('setRateLoading', false)
    }
  },
  async deleteRate({ commit, dispatch }, rate) {
    await deleteRate(rate)
    commit('deleteRate', rate)
  },
  async addRate({ commit, dispatch }, rate) {
    commit('setRateLoading', true)
    try {
      const newRate = await addRate(rate)
      commit('addToList', newRate)
      return newRate
    } catch (e) {
      throw e
    } finally {
      commit('setRateLoading', false)
    }
  }
}

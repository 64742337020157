<template>
  <group-box :header="true" class="h-100">
    <template slot="header">
      Email Subject / File Heading
    </template>
    <b-row>
      <b-col>
        <d-input v-model="subject" />
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'

export default {
  components: {
    GroupBox
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    subject: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

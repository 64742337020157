<template>
  <group-box :header="true" class="h-100">
    <template slot="header">
      Sort
    </template>
    <b-row>
      <b-col>
        <d-checkbox v-model="sort" class="text-left mb-0">
          By Exclusion
        </d-checkbox>
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'

export default {
  components: {
    GroupBox
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    sort: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

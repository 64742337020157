import { client } from '../graphQLInstance'
import WineMatcher from '../../models/winematcher'

export const startImport = async args => {
  const result = await client.query({
    query: WineMatcher.mutate('startImport', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineMatcher.mutations.startImport.name]
}

export const getList = async args => {
  const result = await client.query({
    query: WineMatcher.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineMatcher.queries.get.name]
}

export const wineMatcherClear = async () => {
  const result = await client.query({
    query: WineMatcher.mutate('wineMatcherClear')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineMatcher.mutations.wineMatcherClear.name]
}

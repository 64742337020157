import { filter } from 'lodash'
import { parseVintage } from '../../helpers/parseTerm'

export const state = () => ({
  term: '',
  vintageYear: '',
  nameTerm: '',
  noteTerm: '',
  searchOnNextOpen: false
})

export const getters = {
  searchParameters: state => () => {
    const vintages = state.vintageYear.split('-')
    let vintage1 = vintages[0].length > 0 ? vintages[0] : null
    if (vintage1 && vintage1.toUpperCase() === 'NV') {
      vintage1 = -1
    }
    return {
      term: state.term,
      vintage1: vintage1 ? parseInt(vintage1) : null,
      vintage2: vintages[1] ? parseInt(vintages[1]) : null
    }
  },
  searchOnNextOpen: state => {
    return state.searchOnNextOpen
  },
  vintageIsValid: state => {
    return parseVintage(state.vintageYear) !== null
  },
  vintageIsValidAllowEmpty: state => {
    if (state.vintageYear === null || state.vintageYear.length === 0) {
      return true
    }
    return parseVintage(state.vintageYear) !== null
  }
}

export const mutations = {
  setTerm(state, term) {
    state.term = term
  },
  setVintage(state, vintage) {
    if (vintage === null) {
      state.vintageYear = ''
    } else {
      state.vintageYear = '' + vintage
    }
  },
  resetSelection(state) {
    state.term = ''
    state.vintageYear = ''
  },
  setNameTerm(state, nameTerm) {
    state.nameTerm = nameTerm
  },
  setNoteTerm(state, noteTerm) {
    state.noteTerm = noteTerm
  },
  resetNoteSearch(state) {
    state.nameTerm = ''
    state.noteTerm = ''
  },
  setSearchOnNextOpen(state, search) {
    state.searchOnNextOpen = search
  }
}

export const actions = {
  parseNameTerm({ commit }, payload) {
    commit('setNameTerm', payload)
  },
  parseNoteTerm({ commit }, payload) {
    commit('setNoteTerm', payload)
  },
  parseTerm({ commit }, payload) {
    const self = this
    const filteredWordArray = payload
      .replace(/[\s,;:.]/g, ' ')
      .trim()
      .split(' ')
    const returnedChanged = {
      format: null,
      usage: null
    }
    const words = filter(filteredWordArray, w => {
      const format = self.getters['lists/getFormatByCode'](w.toUpperCase())
      const usageCode = self.getters['lists/getUsageByText'](w.toUpperCase())
      const vintage = parseVintage(w)
      if (w.length > 0 && format !== undefined) {
        returnedChanged.format = format.value
        return false
      }
      if (w.length > 0 && usageCode !== undefined) {
        returnedChanged.usage = usageCode.value
        return false
      }
      if (vintage !== null) {
        commit('setVintage', vintage)
        return false
      }
      return true
    })
    commit('setTerm', words.join(' '))
    return returnedChanged
  },
  parseVintage({ commit }, payload) {
    const vintage = parseVintage(payload)
    if (vintage !== null) {
      commit('setVintage', vintage)
    }
  }
}

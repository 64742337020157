import Entity from './entity'

const fields = {
  WebContactAddressID: {
    type: Number
  },
  WebContactID: {
    type: Number
  },
  AddrID: {
    type: Number
  },
  Addr1: {
    type: String
  },
  Addr2: {
    type: String
  },
  Addr3: {
    type: String
  },
  Addr4: {
    type: String
  },
  Postcode: {
    type: String
  },
  CountryCode: {
    type: String
  },
  ForAttentionOf: {
    type: String
  },
  Telephone: {
    type: String
  },
  DeliveryCompany: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  AuditApprovedDate: {
    type: String
  },
  Approved: {
    type: Boolean
  },
  IsDelivery: {
    type: Boolean
  }
}

const WebContactAddress = new Entity('WebContactAddress', fields)

WebContactAddress.createQuery(
  'getAll',
  'webContactAddressesGet',
  fields,
  Entity.onlyKeys(fields, ['WebContactID'])
)

export default WebContactAddress

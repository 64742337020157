export const WineUsage = {
  PUB: 1, // Publicly Available
  WFS: 2, // Wine Fund Stock
  OOC: 3, // Out Of Condition
  NFS: 4, // Not For Sale
  All: 0, // All
  RES: 5, // Reserves
  BRO: 6, // Broking
  ReserveCodes: [5, 6]
}

export const WineUsageCode = {
  PUB: 'PUB', // Publicly Available
  WFS: 'WFS', // Wine Fund Stock
  OOC: 'OOC', // Out Of Condition
  NFS: 'NFS', // Not For Sale
  All: 'All', // All
  RES: 'RES', // Reserves
  BRO: 'BRO', // Broking
  MIX: 'MIX', // Mixed Codes
  ReserveCodes: ['RES', 'BRO'],
  1: 'PUB',
  2: 'WFS',
  3: 'OOC',
  4: 'NFS',
  5: 'RES',
  6: 'BRO'
}

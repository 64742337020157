export const state = () => ({
  format: null,
  from: null,
  greater: null,
  parker: '',
  to: null,
  less: null,
  burghound: null,
  customer: null
})

export const getters = {
  searchParameters: state => {
    return {
      customer: state.customer,
      format: state.format,
      from: state.from,
      greater: state.greater,
      parker: state.parker,
      to: state.to,
      less: state.less,
      burghound: state.burghound
    }
  }
}

export const mutations = {
  setFormat(state, format) {
    state.format = format
  },
  setFrom(state, from) {
    state.from = from
  },
  setGreater(state, greater) {
    state.greater = greater
  },
  setParker(state, parker) {
    state.parker = parker
  },
  setCustomer(state, customer) {
    state.customer = customer
  },
  setTo(state, to) {
    state.to = to
  },
  setLess(state, less) {
    state.less = less
  },
  setBurghound(state, burghound) {
    state.burghound = burghound
  },
  resetSelection(state) {
    state.format = null
    state.from = null
    state.greater = null
    state.parker = ''
    state.customer = null
    state.to = null
    state.less = null
    state.burghound = null
  }
}

export default {
  state,
  getters,
  mutations
}

import { finalise } from '../../api/etally'

export const state = () => ({
  activeLine: null,
  loading: false
})

export const getters = {}

export const mutations = {
  setActiveLine(state, line) {
    state.activeLine = line
  },
  setLoading(state, loading) {
    state.loading = loading === true
  }
}

export const actions = {
  async finalise({ commit, state }, data) {
    try {
      commit('setLoading', true)
      return await finalise(data)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

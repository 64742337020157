import { client } from '../graphQLInstance'
import StockCardSale from '../../models/stockcardsale'

export const getStockCardSales = async args => {
  const result = await client.query({
    query: StockCardSale.query('get', {
      StockCardId: args.id,
      StockBottleThreshold: args.bottleThreshold,
      SoldStock: args.soldStock,
      PurchaseOrderLineId: args.purchaseOrderLineId,
      RotationNumber: args.rotationNumber,
      IncludeReserves:
        args.includeReserves !== undefined ? args.includeReserves : false
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[StockCardSale.queries.get.name]
}

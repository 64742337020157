<template>
  <div class="virtual-scroll__wrap">
    <b-table
      :id="tableHeadId"
      class="virtual-scroll__header"
      :class="tableHeadClass"
      :thead-class="theadClass"
      :fields="fields"
      :style="computedStyles"
      @sort-changed="$emit('sort-changed', $event)"
      @head-clicked="handleHeadClicked"
    >
      <template slot="table-colgroup" slot-scope="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :data-attr="JSON.stringify(field)"
          :style="{ width: field.width, maxWidth: field.width, minWidth: field.width, overflow: 'hidden' }"
        >
      </template>
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />

      <!-- Pass on all scoped slots -->
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </b-table>
    <scroller
      ref="scoller"
      :class="scrollerClass"
      :getter="getter"
      :items="items"
      :total="total"
      :per-page="perPage"
      :next-load-request="nextLoadRequest"
      :show-loading="showLoading"
      :loading-text="loadingText"
      :estimate-total-scroll-height="estimateTotalScrollHeight"
      :throttle-scroll="throttleScroll"
      :throttle-options="throttleOptions"
      :loading="loading"
      :height="height"
      :style="computedStyles"
      :wait-for-updated="waitForUpdated"
      @request-page="$emit('request-page',$event)"
    >
      <slot slot="loading" name="loading" />
      <template v-slot:content="props">
        <slot name="content" v-bind="props">
          <b-table-lite
            ref="table"
            :fields="fields"
            :items="props.items"
            :tbody-tr-class="tableTrClass"
            :class="tableClass"
            :style="computedStyles"
            table-class="no-header"
            :primary-key="primaryKey"
            @row-clicked="handleRowClicked"
            @row-dblclicked="$emit('row-dblclicked', $event)"
            @row-hovered="$emit('row-hovered', $event)"
          >
            <template slot="table-colgroup" slot-scope="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width, maxWidth: field.width, minWidth: field.width, overflow: 'hidden' }"
              >
            </template>
            <!-- Pass on all named slots -->
            <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
            <!-- Pass on all scoped slots -->
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
              <slot :name="slot" v-bind="scope" />
            </template>
          </b-table-lite>
        </slot>
      </template>
    </scroller>
  </div>
</template>

<script>
import { find } from 'lodash'
import Scroller from './AsyncVirtualScroller'
export default {
  components: {
    Scroller
  },
  props: {
    tableClass: {
      type: String,
      required: false,
      default: ''
    },
    scrollerClass: {
      type: String,
      required: false,
      default: ''
    },
    tableHeadId: {
      type: String,
      required: false,
      default: ''
    },
    tableHeadClass: {
      type: String,
      required: false,
      default: ''
    },
    theadClass: {
      type: String,
      required: false,
      default: ''
    },
    tableTrClass: {
      type: [String, Function],
      required: false,
      default: ''
    },
    fields: {
      type: [Array, Object],
      required: false,
      default() {
        return []
      }
    },
    items: {
      type: Array,
      required: false,
      default: null
    },
    getter: {
      type: String,
      required: false,
      default: null
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    nextLoadRequest: {
      type: Number,
      required: false,
      default: 50
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingText: {
      type: String,
      required: false,
      default: 'Loading Please Wait'
    },
    estimateTotalScrollHeight: {
      type: Boolean,
      required: false,
      default: false
    },
    throttleScroll: {
      type: Number,
      required: false,
      default: 100
    },
    throttleOptions: {
      type: Object,
      required: false,
      default() {
        return {
          trailing: true
        }
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: String,
      required: false,
      default: null
    },
    forcedWidth: {
      type: String,
      required: false,
      default: null
    },
    waitForUpdated: {
      type: Boolean,
      required: false,
      default: false
    },
    primaryKey: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      scrollToClass: null
    }
  },
  computed: {
    computedStyles() {
      const styles = []
      if (this.forcedWidth !== null) {
        styles.push('width: ' + this.forcedWidth)
      }
      return styles.join(';')
    }
  },
  updated() {
    this.performScrollToClass()
  },
  methods: {
    handleHeadClicked(item, idx, event) {
      this.$emit('head-clicked', item, idx, event)
    },
    handleRowClicked(item, idx, event) {
      this.$emit('row-clicked', item, idx, event)
    },
    scrollToRowWithClass(className) {
      this.scrollToClass = className
      return this
    },
    scrollParentToChild(parent, child) {
      // Where is the parent on page
      const parentRect = parent.getBoundingClientRect()
      const childRect = child.getBoundingClientRect()
      // Is the child viewable?
      parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top
      return this
    },
    performScrollToClass() {
      if (this.scrollToClass !== null) {
        const className = this.scrollToClass
        this.scrollToClass = null
        setTimeout(() => {
          if (!this.$refs.table) {
            return
          }
          const row = find(this.$refs.table.$children[1].$children, v => {
            return v.$el.classList.contains(className)
          })
          if (row) {
            this.scrollParentToChild(this.$refs.scoller.$el, row.$el)
          }
        }, 0)
      }
      return this
    }
  }
}
</script>

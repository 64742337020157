import Entity from '~/models/entity'

export const fields = {
  BRPID: {
    type: Number
  },
  Name: {
    type: String
  },
  Days: {
    type: Number
  },
  Weeks: {
    type: Number
  },
  Months: {
    type: Number
  },
  Default: {
    type: Boolean
  }
}

const BillingRunPeriod = new Entity('BillingRunPeriod', fields)

// BillingRunPeriod.createQuery('get', 'billingRunGet', fields)
BillingRunPeriod.createQuery('getAll', 'billingRunPeriodGetAll', fields, {})

export default BillingRunPeriod

<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    body-class="vh-95 d-flex flex-column"
    content-class="shadow"
    hide-footer
    size="xl"
    :title="title"
    @hidden="resetWineList"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <template v-slot:default>
      <b-row class="mt-2 flex-shrink-1">
        <b-col class="col col-xxl-2">
          <sort v-model="sort" />
        </b-col>
        <b-col class="col col-xxl-2">
          <output-settings v-model="outputSettings" />
        </b-col>
        <b-col class="col col-xxl-2">
          <file v-model="file" :preview="preview" :disabled="fileDisabled" />
        </b-col>
        <b-col class="col col-xxl-4">
          <email-subject v-model="subject" />
        </b-col>
        <b-col class="col col-xxl-2 d-flex flex-column gap-1">
          <b-button :disabled="loading" size="sm" class="btn wv-btn d-block w-100 flex-grow-1" @click="handleCreate">
            Create
          </b-button>
          <b-button size="sm" class="btn wv-btn d-block w-100 flex-grow-1" :style="{visibility: isWineBrowserBased ? 'visible' : 'hidden'}" :disabled="!isWineBrowserBased" @click="handleClose(true)">
            Save &amp; Exit
          </b-button>
          <b-button size="sm" class="btn wv-btn d-block w-100 flex-grow-1" @click="handleClose(false)">
            Exit
          </b-button>
        </b-col>
      </b-row>
      <b-row class="flex-grow-1">
        <b-col class="d-flex">
          <div v-if="loading" class="text-center text-primary my-2 mx-auto">
            <b-spinner type="grow" label="Loading..." />
          </div>
          <export-table
            v-else
            :results="availableStock"
            :selected-currencies="selectedCurrencies"
            :is-grouping-combined="isGroupingCombined"
            :min-parker-score="minParkerScore"
            :score-mode="scoreMode"
            :is-wine-browser-based="isWineBrowserBased"
            :all-split-cases="allSplitCasesChecked"
            :all-whole-cases="allWholeCasesChecked"
            :sort-by-exclusion="sort"
            class="w-100"
            @change-exclude="handleChangingExclude"
            @change-whole-cases="handleChangingWholeCases"
            @change-split-cases="handleChangingSplitCases"
            @change-all-whole-cases="handleChangeAllWholeCases"
            @change-all-split-cases="handleChangeAllSplitCases"
            @change-include-all="handleChangeIncludeAll"
            @sort="handleSort"
          />
        </b-col>
      </b-row>
      <report-request-form ref="reportRequest" :value="reportFormParameters" />
    </template>
  </b-modal>
</template>

<script>
import SettingsMixin from '~/mixins/modal/wineListExportModal/Settings'
import TableMixin from '~/mixins/modal/wineListExportModal/Table'
import ReportFormMixin from '~/mixins/modal/wineListExportModal/ReportForm'

export default {
  mixins: [SettingsMixin, TableMixin, ReportFormMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      keepExportId: false
    }
  },
  computed: {
    title() {
      let title = 'Create Promotion from selected'
      if (this.isWineBrowserBased) {
        title += ' Wines'
      } else {
        title += ' Purchase Orders'
      }
      return title
    },
    isWineBrowserBased() {
      const key = 'export/wineList/isWineBrowserBased'
      return this.$store.getters[key]
    },
    isGroupingCombined() {
      return this.$store.state.export.settings.grouping === 'ALL'
    },
    loadingAppends() {
      return this.$store.state.export.wineList.loadingAppends
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    loadingAppends(loading) {
      if (loading === false && this.shown) {
        this.loadAvailableStockTable()
      }
    },
    shown(shown) {
      if (shown && this.loadingAppends === false) {
        this.loadAvailableStockTable()
      }
    },
    sort(exclusion) {
      if (exclusion) {
        this.handleSort({
          sortBy: 'ExcludeSort',
          sortDesc: true
        })
      } else {
        this.handleSort({
          sortBy: 'WineVintage',
          sortDesc: false
        })
      }
    }
  },
  methods: {
    handleClose(keep) {
      this.keepExportId = keep
      this.shown = false
    },
    resetWineList() {
      if (!this.keepExportId) {
        this.$store.commit('export/wineList/reset')
      }
    },
    handleCreate() {
      if (this.outputSettings === 'file') {
        this.$refs.reportRequest.submit()
      } else {
        this.$store.commit('export/email/reset')
        this.$store.commit('export/email/setContactCanChange', true)
        this.$store.commit('export/email/emailApiArgs', {
          WineListExport: {
            ...this.reportFormParameters,
            Type: this.file,
            Subject: this.subject,
            WithAttachments: this.outputSettings === 'emailAttached'
          }
        })
        this.$store.commit('modal/setShowExportEmailModal', true)
      }
    }
  }
}
</script>

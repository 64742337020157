import GlobalVars from '../models/globalvars'
import { client } from './graphQLInstance'

export const getGlobals = async () => {
  const result = await client.query({
    query: GlobalVars.query('get', {})
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[GlobalVars.queries.get.name]
}

export const updateGlobals = async globals => {
  const result = await client.query({
    query: GlobalVars.mutate('update', globals)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[GlobalVars.mutations.update.name]
}

import {
  getDeliveryPostcodes,
  getDeliveryCharges,
  importFile
} from '@/api/administration/lcbdeliverycharges'

export const state = () => ({
  loadingDeliveryPostcodes: false,
  deliveryPostcodes: [],
  deliveryCharges: []
})

export const getters = {}

export const mutations = {
  setLoadingDeliveryPostcodes(state, loading) {
    state.loadingDeliveryPostcodes = loading === true
  },
  setDeliveryPostcodes(state, list) {
    state.deliveryPostcodes = list
  },
  setDeliveryCharges(state, list) {
    state.deliveryCharges = list
  }
}

export const actions = {
  async getDeliveryPostcodes({ commit }) {
    commit('setLoadingDeliveryPostcodes', true)
    try {
      const response = await getDeliveryPostcodes()
      if (response) {
        commit('setDeliveryPostcodes', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingDeliveryPostcodes', false)
    }
  },
  async getDeliveryCharges({ commit }) {
    commit('setLoadingDeliveryPostcodes', true)
    try {
      const response = await getDeliveryCharges()
      if (response) {
        commit('setDeliveryCharges', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingDeliveryPostcodes', false)
    }
  },
  async importCharges({ commit, rootState }, file) {
    commit('setLoadingDeliveryPostcodes', true)
    try {
      const results = await importFile(
        file,
        this.$axios,
        rootState.user.documentToken
      )
      commit('setDeliveryPostcodes', results.lcbPostcodes)
      commit('setDeliveryCharges', results.lcbCharges)
      return results
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingDeliveryPostcodes', false)
    }
  }
}

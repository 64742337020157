import {
  getWarehouseAddresses,
  updateWarehouseAddress,
  addWarehouseAddress,
  deleteWarehouseAddress
} from '@/api/administration/warehouseaddresses'
import { find, findIndex, merge } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  loadingWarehouseAddress: false,
  list: [],
  activeWarehouseAddress: null,
  searchTerm: ''
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoadingWarehouseAddress(state, loading) {
    state.loadingWarehouseAddress = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  setActiveWarehouseAddress(state, { WarehouseAddrID }) {
    state.activeWarehouseAddress = find(state.list, { WarehouseAddrID })
  },
  removeWarehouseAddressFromList(state, { WarehouseAddrID }) {
    const idx = findIndex(state.list, { WarehouseAddrID })
    if (idx >= 0) {
      if (
        state.activeWarehouseAddress &&
        state.activeWarehouseAddress.WarehouseAddrID === WarehouseAddrID
      ) {
        state.activeWarehouseAddress = null
      }
      Vue.delete(state.list, idx)
    }
  },
  updateWarehouseAddressData(state, warehouseAddress) {
    const idx = findIndex(state.list, {
      WarehouseAddrID: warehouseAddress.WarehouseAddrID
    })
    if (idx >= 0) {
      const mergedAddr = merge({}, state.list[idx], warehouseAddress)
      Vue.set(state.list, idx, mergedAddr)
    }
  },
  addWarehouseAddressToList(state, warehouseAddress) {
    state.list.push(warehouseAddress)
  }
}

export const getters = {
  getWarehouseAddressList(state) {
    return state.list
  },
  getActiveWarehouseAddress(state) {
    return state.activeWarehouseAddress
  }
}

export const actions = {
  async loadList({ commit, state }) {
    commit('setLoading', true)
    try {
      const response = await getWarehouseAddresses(state.searchTerm)
      commit('setList', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  setActiveWarehouseAddress({ commit }, warehouseAddress) {
    commit('setActiveWarehouseAddress', warehouseAddress)
  },
  async addWarehouseAddress({ commit }, warehouseAddress) {
    commit('setLoadingWarehouseAddress', true)
    try {
      const response = await addWarehouseAddress(warehouseAddress)
      commit('addWarehouseAddressToList', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingWarehouseAddress', false)
    }
  },
  async updateWarehouseAddress({ commit }, warehouseAddress) {
    commit('setLoadingWarehouseAddress', true)
    try {
      const response = await updateWarehouseAddress(warehouseAddress)
      commit('updateWarehouseAddressData', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingWarehouseAddress', false)
    }
  },
  async deleteWarehouseAddress({ commit }, warehouseAddress) {
    commit('setLoadingWarehouseAddress', true)
    try {
      await deleteWarehouseAddress(warehouseAddress)
      commit('removeWarehouseAddressFromList', warehouseAddress)
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingWarehouseAddress', false)
    }
  }
}

import gql from 'graphql-tag'
import { map } from 'lodash'
import { client } from '../graphQLInstance'
import WineCard from '../../models/winecard'

export const getInitialList = async () => {
  const result = await client.query({
    query: WineCard.query('getInitialVintages')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WineCard.queries.getInitialVintages.name]
}

export const getList = async searchParameters => {
  const hasQueryItem =
    searchParameters &&
    (searchParameters.format ||
      searchParameters.categories ||
      searchParameters.appellations ||
      searchParameters.countryMultiple ||
      searchParameters.regionMultiple ||
      searchParameters.subRegionMultiple ||
      searchParameters.producerMultiple ||
      searchParameters.wines)
  // prettier-ignore
  const result = await client.query({
    query: gql `
        query RootQueryType{
          reservesWineCardVintages
          ${hasQueryItem? '(':''}
          ${searchParameters && searchParameters.format ? `FormatId: ${searchParameters.format},` : ''}  
          ${searchParameters && searchParameters.categories ? `CategoryId: ${searchParameters.categories },` : ''}
          ${searchParameters && searchParameters.appellations ? `AppellationId: ${searchParameters.appellations },`: '' }
          ${searchParameters && searchParameters.wines ? `WineNameSearchID:"${searchParameters.wines}",` : ''}
          ${searchParameters && searchParameters.countryMultiple ? `CountryIdMultiple: "${searchParameters.countryMultiple }",` : ''}
          ${searchParameters && searchParameters.regionMultiple ? `CountryRegionIdMultiple: "${searchParameters.regionMultiple }",` : ''}
          ${searchParameters && searchParameters.subRegionMultiple ? `CountrySubRegionIdMultiple: "${searchParameters.subRegionMultiple }",` : ''}
          ${searchParameters && searchParameters.producerMultiple ? `ProducerIdMultiple: "${searchParameters.producerMultiple }",` : ''}
          ${hasQueryItem? ')':''}
          {
            WineVintage,
            WineCardCount,
            BottlesStockOnHand,
            HasLooseBottles,
            HasLoosePrice
          }
        }
      `
  })

  const available = map(result.data.reservesWineCardVintages, app => {
    return {
      value: app.WineVintage,
      text: app.WineVintage,
      wineCardCount: app.WineCardCount,
      bottlesStockOnHand: app.BottlesStockOnHand,
      hasLooseBottles: app.HasLooseBottles,
      hasLoosePrice: app.HasLoosePrice
    }
  })
  return {
    available,
    unavailable: []
  }
}

import Inventory from '../models/inventory'
import { client } from './graphQLInstance'

export const inventoryGetByRotationNumber = async rotationNumber => {
  const result = await client.query({
    query: Inventory.query('getByRotationNumber', {
      RecordCode: rotationNumber
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Inventory.queries.getByRotationNumber.name]
}

export const inventoryGetById = async id => {
  const result = await client.query({
    query: Inventory.query('get', {
      RecordId: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Inventory.queries.get.name]
}

export const updateInventory = async args => {
  const result = await client.query({
    query: Inventory.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Inventory.mutations.update.name]
}

export const deleteInventory = async args => {
  const result = await client.query({
    query: Inventory.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Inventory.mutations.delete.name]
}

export const createInventory = async args => {
  const result = await client.query({
    query: Inventory.mutate('create', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Inventory.mutations.create.name]
}

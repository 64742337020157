import Vue from 'vue'
import { find, findIndex } from 'lodash'
import {
  searchSalesOrders,
  getSalesOrderLines,
  createSalesOrder,
  updateSalesOrder
} from '../../api/web/salesOrder'

const searchParameters = {
  WebContactID: null,
  Converted: false,
  Incomplete: false,
  IncludeArchived: false,
  DateFrom: '',
  DateTo: '',
  Page: 1,
  PerPage: 100
}

export const getters = {
  selectedSalesOrder: state => {
    return find(state.salesOrders, c => c.WSOID === state.selectedWSOID)
  },
  selectedSalesOrderLines: state => {
    return state.salesOrderLines.filter(l => l.WSOID === state.selectedWSOID)
  }
}

export const state = () => ({
  loading: false,
  loadingLines: false,
  savingOrder: false,
  salesOrders: [],
  total: 0,
  searchParameters: Object.assign({}, searchParameters),
  selectedWSOID: null,
  salesOrderLines: []
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoadingLines(state, loading) {
    state.loadingLines = loading === true
  },
  setSavingOrder(state, saving) {
    state.savingOrder = saving === true
  },
  setOrders(state, salesOrders) {
    state.salesOrders = salesOrders
  },
  appendOrders(state, salesOrders) {
    state.salesOrders = state.salesOrders.concat(salesOrders)
  },
  setTotal(state, total) {
    state.total = total
  },
  setSearchParameter(state, { key, value }) {
    Vue.set(state.searchParameters, key, value)
  },
  resetSearchParameters(state) {
    state.searchParameters = Object.assign({}, searchParameters)
    state.total = 0
    state.salesOrders = []
  },
  setSelectedWSOID(state, wsoid) {
    state.selectedWSOID = wsoid
  },
  replaceSalesOrderLines(state, { WSOID, salesOrderLines }) {
    state.salesOrderLines = state.salesOrderLines
      .filter(l => l.WSOID !== WSOID)
      .concat(salesOrderLines)
  },
  replaceSalesOrder(state, salesOrder) {
    const idx = findIndex(state.salesOrders, { WSOID: salesOrder.WSOID })
    if (idx >= 0) {
      Vue.set(state.salesOrders, idx, salesOrder)
    } else {
      state.salesOrders.push(salesOrder)
    }
  },
  setDutyStatusOnLine(state, { DutyStatusID, WSOLID }) {
    const idx = findIndex(state.salesOrderLines, l => l.WSOLID === WSOLID)
    if (idx !== undefined && state.salesOrderLines[idx]) {
      const line = state.salesOrderLines[idx]
      line.DutyStatusID = DutyStatusID
      Vue.set(state.salesOrderLines, idx, line)
    }
  }
}

export const actions = {
  async searchOrders({ commit }, searchParams) {
    try {
      commit('setLoading', true)
      const salesOrderResponse = await searchSalesOrders(searchParams)
      const salesOrders = salesOrderResponse.webSalesOrderPaginatedResults
      if (searchParams.Page === 1) {
        commit('setTotal', salesOrderResponse.total)
        commit('setOrders', salesOrders)
      } else {
        commit('appendOrders', salesOrders)
      }
      return salesOrders
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getSalesOrderLines({ commit }, WSOID) {
    try {
      commit('setLoadingLines', true)
      const salesOrderLines = await getSalesOrderLines({ WSOID })
      commit('replaceSalesOrderLines', {
        WSOID,
        salesOrderLines
      })
      return salesOrderLines
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingLines', false)
    }
  },
  async createSalesOrder({ commit }, args) {
    try {
      commit('setSavingOrder', true)
      const salesOrder = await createSalesOrder(args)
      commit('replaceSalesOrder', salesOrder)
      return salesOrder
    } catch (e) {
      throw e
    } finally {
      commit('setSavingOrder', false)
    }
  },
  async updateSalesOrder({ commit }, args) {
    try {
      commit('setSavingOrder', true)
      const salesOrder = await updateSalesOrder(args)
      commit('replaceSalesOrder', salesOrder)
      return salesOrder
    } catch (e) {
      throw e
    } finally {
      commit('setSavingOrder', false)
    }
  }
}

import Vue from 'vue'

import { each } from 'lodash'

export const state = () => ({
  wineCards: [],
  wineCardIndex: {},
  contacts: [],
  contactIndex: {}
})

export const mutations = {
  appendWineCard(state, wineCard) {
    const currentIndex = state.wineCardIndex[wineCard.WineCardID]
    if (currentIndex !== undefined) {
      Vue.set(state.wineCards, currentIndex, wineCard)
    } else {
      const currentLength = state.wineCards.length
      state.wineCards.push(wineCard)
      Vue.set(state.wineCardIndex, wineCard.WineCardID, currentLength)
    }
  },
  appendContact(state, contact) {
    const currentIndex = state.contactIndex[contact.ContactID]
    if (currentIndex !== undefined) {
      Vue.set(state.contacts, currentIndex, contact)
    } else {
      const currentLength = state.contacts.length
      state.contacts.push(contact)
      Vue.set(state.contactIndex, contact.ContactID, currentLength)
    }
  },
  removeContactById(state, ContactID) {
    const currentIndex = state.contactIndex[ContactID]
    if (currentIndex !== undefined) {
      Vue.delete(state.contacts, currentIndex)
      state.contactIndex = {}
      each(state.contacts, (contact, idx) => {
        Vue.set(state.contactIndex, contact.ContactID, idx)
      })
    }
  },
  removeWineCardById(state, WineCardID) {
    const currentIndex = state.wineCardIndex[WineCardID]
    if (currentIndex !== undefined) {
      Vue.delete(state.wineCards, currentIndex)
      state.wineCardIndex = {}
      each(state.wineCards, (wineCard, idx) => {
        Vue.set(state.wineCardIndex, wineCard.WineCardID, idx)
      })
    }
  }
}

export const actions = {
  appendWineCard({ commit }, wineCard) {
    commit('appendWineCard', wineCard)
  },
  appendContact({ commit }, contact) {
    commit('appendContact', contact)
  },
  closeContact({ commit }, contact) {
    commit('removeContactById', contact)
  },
  closeWineCard({ commit }, wineCard) {
    commit('removeWineCardById', wineCard)
  }
}

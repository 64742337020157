<template>
  <div>
    <b-modal
      v-model="shown"
      centered
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      :hide-header-close="true"
      size="md"
      @ok="save"
      @shown="reset"
    >
      <template v-slot:modal-header>
        Edit Wine Name
      </template>
      <wine-name-form
        ref="form"
        :duty-type-options="dutyTypes"
        :value="formData"
        @change="handleFormChange"
        @toggle-appellations-modal="toggleAppellationsModal"
        @reset-appellation="resetAppellation"
      />
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="ok()">
          <b-spinner v-if="saving" type="grow" label="Loading..." small />
          OK
        </b-button>
        <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="shown=false">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <appellations-modal
      v-model="boolAppellationModal"
      @selected="setAppellation"
    />
  </div>
</template>

<script>
import WineNameForm from '~/components/Forms/WineNameForm'
import AppellationsModal from '~/components/Modal/Administration/AppellationsModal'
import { formResultMixin } from '../../../mixins/forms'
import { merge, map } from 'lodash'

export default {
  components: {
    WineNameForm,
    AppellationsModal
  },
  mixins: [formResultMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    wineName: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    dutyTypesProp: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      selectedAppellation: null,
      boolAppellationModal: false,
      clearedAppellation: false,
      saving: false
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    appellationName() {
      if (this.clearedAppellation) {
        return ''
      }
      if (this.selectedAppellation) {
        return this.selectedAppellation.AppellationName
      }
      return this.wineName.AppellationName
    },
    formData() {
      return {
        Name: this.wineName.Name,
        DutyTypeID: this.wineName.DutyTypeID,
        WineCode: this.wineName.WineCode,
        AppellationName: this.appellationName
      }
    },
    dutyTypes() {
      return map(this.dutyTypesProp, dutyType => {
        return {
          value: dutyType.DutyTypeID,
          text: dutyType.DutyTypeName
        }
      })
    }
  },
  methods: {
    toggleAppellationsModal() {
      this.boolAppellationModal = !this.boolAppellationModal
    },
    setAppellation(item) {
      this.selectedAppellation = item
      this.boolAppellationModal = false
      this.clearedAppellation = false
    },
    resetAppellation() {
      this.selectedAppellation = null
      this.clearedAppellation = true
    },
    async save(e) {
      e.preventDefault()
      try {
        await this.$refs.form.validate()
        if (this.selectedAppellation) {
          this.form.AppellationID = this.selectedAppellation.AppellationID
        }
        if (this.clearedAppellation) {
          this.form.AppellationID = null
        }
        this.saving = true
        const mergedForm = merge({}, this.wineName, this.form)
        await this.$store.dispatch(
          'administration/winenames/updateWineName',
          mergedForm
        )
        this.shown = false
        this.$notify.success('Wine Name Updated')
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.saving = false
      }
    },
    cancel() {
      this.$refs.form.reset()
    },
    reset() {
      this.selectedAppellation = null
      this.clearedAppellation = false
      this.$refs.form.reset()
    }
  }
}
</script>

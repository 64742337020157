import Entity from './entity'

const fields = {
  TotalCost: {
    type: Number
  }
}

const SalesOrderDeliveryCost = new Entity('SalesOrderDeliveryCost', fields)

SalesOrderDeliveryCost.createQuery('get', 'salesOrderDeliveryCost', fields, {
  SOID: {
    type: Number
  },
  Postcode: {
    type: String
  }
})

export default SalesOrderDeliveryCost

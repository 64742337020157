import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';

Vue.use(BootstrapVue, {
  "breakpoints": [
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "xxl"
  ]
});

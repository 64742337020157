import Entity from '../entity'
import { fields as jobFields } from '../job'
const fields = {
  ID: {
    type: Number
  }
}

const ETallyCardImportCSV = new Entity('ETallyCardImportCSV', fields)

ETallyCardImportCSV.createMutation(
  'update',
  'etallyImportCSVColumns',
  {
    ID: {
      type: Number
    },
    BottlesColumn: {
      type: Number
    },
    CasesColumn: {
      type: Number
    },
    PriceColumn: {
      type: Number
    },
    PriceType: {
      type: String
    },
    OriginalPackagingColumn: {
      type: Number
    },
    Mode: {
      type: String
    }
  },
  {
    ...jobFields,
    OwnerID: {
      type: Number
    }
  }
)

export default ETallyCardImportCSV

import Entity from './entity'

const fields = {
  NewPurchaseOrders: {
    type: Number
  },
  NewSalesOrders: {
    type: Number
  },
  ContactUpdates: {
    type: Number
  }
}

const WebChanges = new Entity('WebChanges', fields)
WebChanges.createQuery('get', 'webChanges')

export default WebChanges

import {
  createPurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrder,
  getTransactionLines,
  updatePurchaseOrderLine,
  deletePurchaseOrderLine,
  addPurchaseOrderLine,
  getAllocationsForPurchaseOrderLine,
  getAllocatedStockOnOrderSales,
  deletePurchaseOrder,
  createPurchaseOrderWithTransaction,
  updatePurchaseOrderLines,
  mergePurchaseOrderLines,
  updateSage
} from '@/api/purchaseOrders/purchaseOrders'

import {
  getSavedChanges,
  saveChanges,
  removeSavedChanges,
  saveOpenOrders,
  getOpenOrders
} from '@/api/purchaseOrders/savedChanges'

import {
  updateInventory,
  deleteInventory,
  createInventory
} from '@/api/inventory'

import {
  filter,
  find,
  each,
  map,
  findIndex,
  keys,
  without,
  clone,
  sortBy
} from 'lodash'
import Vue from 'vue'

export const state = () => ({
  po: [],
  poIdx: {},
  loadingPo: false,
  saving: false,
  transactions: [],
  poTransactionIdx: {},
  savingTransactionId: false,
  savingAllocationId: false,
  activeTransactionId: 0,
  activeAllocationId: 0,
  allocations: [],
  allocationsIdx: {},
  loadingAllocations: false,
  allocatedStock: [],
  allocatedStockIdx: [],
  changedDetails: {},
  purchaseOrderTransactionSearches: {},
  toCloseId: null,
  activePOID: null
})

export const getters = {
  getLastPurchaseOrderId: state => {
    const salesOrders = sortBy(state.po, 'POID')
    if (salesOrders.length > 0) {
      return salesOrders[salesOrders.length - 1].POID
    }
    return 0
  },
  getNewPurchaseOrderKey: state => {
    const newPOs = filter(state.po, r => r.isNew)
    const newCount = newPOs.length + 1
    return 'NEW-' + newCount
  },
  getChangesByPOID: state => poid => {
    return state.changedDetails[poid]
  },
  getPurchaseOrderByPOID: state => poid => {
    return find(state.po, po => '' + po.POID === '' + poid)
  },
  getTransactionsForPOID: state => poid => {
    return map(state.poTransactionIdx[poid], idx => state.transactions[idx])
  },
  getAllocatedStockForPOLID: state => polid => {
    return map(state.allocatedStockIdx[polid], idx => state.allocatedStock[idx])
  },
  getActiveTransaction: state => {
    return find(state.transactions, t => t.POLID === state.activeTransactionId)
  },
  getActiveAllocation: state => {
    return find(
      state.allocations,
      t => t.InventoryID === state.activeAllocationId
    )
  },
  getAllocationsForTransaction: state => transaction => {
    if (!transaction) {
      return []
    }
    return map(
      state.allocationsIdx[transaction.POLID],
      idx => state.allocations[idx]
    )
  },
  getActivePurchaseOrder: state => {
    return find(state.po, po => '' + po.POID === '' + state.activePOID)
  }
}

export const mutations = {
  setToClose(state, id) {
    state.toCloseId = id
  },
  setPurchaseOrderTransactionSearches(state, { POID, search }) {
    Vue.set(state.purchaseOrderTransactionSearches, POID, search)
  },
  setChangedDetails(state, { POID, changedDetails }) {
    changedDetails = JSON.parse(JSON.stringify(changedDetails))
    Vue.set(state.changedDetails, POID, changedDetails)
  },
  removeChangedDetails(state, POID) {
    Vue.delete(state.changedDetails, POID)
  },
  appendPurchaseOrder(state, purchaseOrder) {
    const currentIndex = state.poIdx[purchaseOrder.POID]
    if (currentIndex !== undefined) {
      Vue.set(state.po, currentIndex, purchaseOrder)
    } else {
      const currentLength = state.po.length
      state.po.push(purchaseOrder)
      Vue.set(state.poIdx, purchaseOrder.POID, currentLength)
    }
  },
  removePurchaseOrderById(state, POID) {
    const currentIndex = state.poIdx[POID]
    if (currentIndex !== undefined) {
      Vue.delete(state.po, currentIndex)
      state.poIdx = {}
      each(state.po, (po, idx) => {
        Vue.set(state.poIdx, po.POID, idx)
      })
    }
  },
  setLoadingPo(state, loadingPo) {
    state.loadingPo = loadingPo
  },
  setSaving(state, saving) {
    state.saving = saving
  },
  setLoadingAllocations(state, loading) {
    state.loadingAllocations = loading
  },
  replaceAllocations(state, { polid, allocations }) {
    if (state.allocationsIdx[polid]) {
      state.allocations = filter(state.allocations, (a, idx) => {
        return state.allocationsIdx[polid].indexOf(idx) < 0
      })
    }

    const newAllocList = state.allocations.concat(allocations)
    const allocIdx = {}
    each(newAllocList, (alloc, idx) => {
      if (!allocIdx[alloc.POLID]) {
        allocIdx[alloc.POLID] = []
      }
      allocIdx[alloc.POLID].push(idx)
    })

    state.allocations = newAllocList
    state.allocationsIdx = allocIdx
  },
  appendAllocation(state, allocation) {
    const polid = allocation.POLID
    const currentIdx = state.allocations.length
    state.allocations.push(allocation)
    if (polid in state.allocationsIdx === false) {
      Vue.set(state.allocationsIdx, polid, [])
    }
    state.allocationsIdx[polid].push(currentIdx)
  },
  clearAllocations(state) {
    state.allocations = []
    state.allocationsIdx = {}
  },
  replaceAllocation(state, allocation) {
    const idx = findIndex(state.allocations, {
      InventoryID: allocation.InventoryID
    })
    Vue.set(state.allocations, idx, allocation)
  },
  removeAllocation(state, allocation) {
    const idx = findIndex(state.allocations, {
      InventoryID: allocation.InventoryID
    })
    Vue.delete(state.allocations, idx)
    const allocationIdx = state.allocationsIdx[allocation.POLID].indexOf(idx)
    Vue.delete(state.allocationsIdx[allocation.POLID], allocationIdx)
  },
  replaceTransactions(state, { poid, transactions }) {
    if (state.poTransactionIdx[poid]) {
      state.transactions = filter(
        state.transactions,
        t => parseInt(t.POID) !== parseInt(poid)
      )
      Vue.set(state.poTransactionIdx, poid, [])
    }
    each(transactions, transaction => {
      const currentIdx = state.transactions.length
      state.transactions.push(transaction)
      if (transaction.POID in state.poTransactionIdx === false) {
        Vue.set(state.poTransactionIdx, transaction.POID, [])
      }
      state.poTransactionIdx[transaction.POID].push(currentIdx)
    })
  },
  updateTransactionValue(state, mergeData) {
    if (mergeData.POLID) {
      const polidIdx = findIndex(
        state.transactions,
        transaction => transaction.POLID === mergeData.POLID
      )
      if (polidIdx !== undefined) {
        const transaction = state.transactions[polidIdx]
        each(keys(mergeData), key => {
          Vue.set(transaction, key, mergeData[key])
        })
        Vue.set(state.transactions, polidIdx, transaction)
      }
    }
  },
  replaceTransaction(state, Transaction) {
    if (Transaction.POLID !== undefined) {
      const polidIdx = findIndex(
        state.transactions,
        transaction => transaction.POLID === Transaction.POLID
      )
      Vue.set(state.transactions, polidIdx, Transaction)
    }
  },
  removeEmptyCaseFromLine(state, { POLID, EmptyCaseID }) {
    const polidIdx = findIndex(state.transactions, { POLID })
    if (polidIdx >= 0) {
      const line = state.transactions[polidIdx]
      line.EmptyCases = line.EmptyCases.filter(
        ec => ec.EmptyCaseID !== EmptyCaseID
      )
      Vue.set(state.transactions, polidIdx, line)
    }
  },
  addEmptyCaseToLine(state, EmptyCase) {
    const polidIdx = findIndex(state.transactions, { POLID: EmptyCase.POLID })
    if (polidIdx >= 0) {
      const line = state.transactions[polidIdx]
      line.EmptyCases.push(EmptyCase)
      Vue.set(state.transactions, polidIdx, line)
    }
  },
  appendTransaction(state, Transaction) {
    const currentIdx = state.transactions.length
    state.transactions.push(Transaction)
    if (Transaction.POID in state.poTransactionIdx === false) {
      Vue.set(state.poTransactionIdx, Transaction.POID, [])
    }
    state.poTransactionIdx[Transaction.POID].push(currentIdx)
  },
  setSavingTransactionId(state, id) {
    state.savingTransactionId = id
  },
  setSavingAllocationId(state, id) {
    state.savingAllocationId = id
  },
  setActiveTransactionId(state, id) {
    state.activeTransactionId = id
  },
  setActiveAllocationId(state, id) {
    state.activeAllocationId = id
  },
  deleteTransactionById(state, POLID) {
    const idx = findIndex(state.transactions, { POLID })
    if (idx !== undefined) {
      const POID = state.transactions[idx].POID
      state.transactions = without(state.transactions, t => t.POLID === POLID)
      Vue.delete(
        state.poTransactionIdx[POID],
        state.poTransactionIdx[POID].indexOf(idx)
      )
    }
  },
  replaceAllocatedStock(state, allocatedStock) {
    each(allocatedStock, stock => {
      Vue.delete(state.allocatedStockIdx, stock.POLID)
    })
    each(allocatedStock, stock => {
      const currentIdx = state.allocatedStock.length
      state.allocatedStock.push(stock)
      if (stock.POLID in state.allocatedStockIdx === false) {
        Vue.set(state.allocatedStockIdx, stock.POLID, [])
      }
      state.allocatedStockIdx[stock.POLID].push(currentIdx)
    })
  },
  setActivePOID(state, id) {
    state.activePOID = id
  }
}

export const actions = {
  reloadOpenPo({ commit, dispatch }) {
    each(getOpenOrders(), po => {
      commit('appendPurchaseOrder', po)
      dispatch('getChangedDetails', po.POID)
    })
  },
  loadFromId({ dispatch, commit }, { poid, force }) {
    return new Promise(async (resolve, reject) => {
      if (poid.substring(0, 3) !== 'NEW') {
        try {
          await Promise.all([
            dispatch('getPurchaseOrder', { poid, force }),
            dispatch('getTransactions', { poid })
          ])
          resolve()
        } catch (e) {
          reject(e)
        }
      } else {
        try {
          await dispatch('createBlankPurchaseOrder', poid)
          resolve()
        } catch (e) {
          reject(e)
        }
      }
    })
  },
  clonePurchaseOrder({ commit, state, getters }, POID) {
    if (state.poIdx[POID] !== undefined) {
      const purchaseOrder = clone(state.po[state.poIdx[POID]])
      purchaseOrder.POID = getters.getNewPurchaseOrderKey
      purchaseOrder.isNew = true
      commit('appendPurchaseOrder', purchaseOrder)
      saveOpenOrders(state.po)
      return purchaseOrder
    }
  },
  createBlankPurchaseOrder({ commit, getters, state }, POID) {
    if (!POID) {
      POID = getters.getNewPurchaseOrderKey
    }
    if (state.poIdx[POID] === undefined) {
      commit('appendPurchaseOrder', {
        POID: POID,
        isNew: true,
        OrderDate: new Date(),
        WorkflowStatusID: 1
      })
      saveOpenOrders(state.po)
    }
    return POID
  },
  async getPurchaseOrder({ commit, state }, { poid, force }) {
    try {
      const currentIndex = state.poIdx[poid]
      if (currentIndex === undefined || force) {
        commit('setLoadingPo', true)
        const response = await getPurchaseOrder(poid)
        commit('appendPurchaseOrder', response)
        saveOpenOrders(state.po)
        return response
      } else {
        return state.po[state.poIdx[poid]]
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPo', false)
    }
  },
  async createNewPurchaseOrderWithTransaction({ commit, state }, args) {
    try {
      commit('setSaving', true)
      const response = await createPurchaseOrderWithTransaction(args)
      commit('appendPurchaseOrder', response)
      saveOpenOrders(state.po)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async createPurchaseOrder({ commit, state }, args) {
    try {
      commit('setSaving', true)
      const response = await createPurchaseOrder(args)
      commit('appendPurchaseOrder', response)
      saveOpenOrders(state.po)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async updatePurchaseOrder({ commit, state }, args) {
    try {
      commit('setSaving', true)
      const response = await updatePurchaseOrder(args)
      commit('appendPurchaseOrder', response)
      saveOpenOrders(state.po)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async updateSage({ commit, state }, args) {
    try {
      commit('setSaving', true)
      const response = await updateSage(args)
      commit('appendPurchaseOrder', response)
      saveOpenOrders(state.po)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  closePurchaseOrderById({ commit, getters, state }, POID) {
    commit('removePurchaseOrderById', POID)
    commit('removeChangedDetails', POID)
    saveOpenOrders(state.po)
    removeSavedChanges(POID)
  },
  async deletePurchaseOrder({ commit, getters, state }, args) {
    try {
      commit('setSaving', true)
      const purchaseOrder = getters.getPurchaseOrderByPOID(args.POID)
      let response = null
      if (purchaseOrder.isNew !== true) {
        response = await deletePurchaseOrder(args)
      }
      commit('removePurchaseOrderById', args.POID)
      removeSavedChanges(args.POID)
      saveOpenOrders(state.po)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async getTransactions({ commit, state }, { poid }) {
    try {
      commit('setLoadingPo', true)
      const transactions = await getTransactionLines(poid)
      commit('replaceTransactions', { poid, transactions })
      return transactions
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPo', false)
    }
  },
  async updateOrderLine({ commit, state }, args) {
    try {
      commit('setSavingTransactionId', args.POLID)
      commit('setLoadingPo', true)
      const response = await updatePurchaseOrderLine(args)
      commit('replaceTransaction', response.Transaction)
      commit('appendPurchaseOrder', response.PurchaseOrder)
      commit('setActiveTransactionId', response.Transaction.POLID)
      commit('replaceAllocations', {
        polid: response.Transaction.POLID,
        allocations: response.Allocations
      })
      saveOpenOrders(state.po)
      return response
    } catch (e) {
      commit('setActiveTransactionId', 0)
      throw e
    } finally {
      commit('setSavingTransactionId', false)
      commit('setLoadingPo', false)
    }
  },
  async updateOrderLines({ commit, state }, args) {
    try {
      commit('setLoadingPo', true)
      const response = await updatePurchaseOrderLines(args)
      commit('replaceTransactions', {
        poid: args.POID,
        transactions: response.Transactions
      })
      response.Transactions.forEach(t => {
        commit('replaceAllocations', {
          polid: t.POLID,
          allocations: t.Allocations
        })
      })
      commit('appendPurchaseOrder', response.PurchaseOrder)
      commit('clearAllocations')
      saveOpenOrders(state.po)
      return response.Transactions
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPo', false)
      commit('setActiveTransactionId', 0)
    }
  },
  async mergeOrderLines({ commit, state }, args) {
    try {
      commit('setLoadingPo', true)
      const response = await mergePurchaseOrderLines(args)
      commit('replaceTransactions', {
        poid: response.PurchaseOrder.POID,
        transactions: response.Transactions
      })
      response.Transactions.forEach(t => {
        commit('replaceAllocations', {
          polid: t.POLID,
          allocations: t.Allocations
        })
      })
      commit('appendPurchaseOrder', response.PurchaseOrder)
      commit('clearAllocations')
      saveOpenOrders(state.po)
      return response.Transactions
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPo', false)
      commit('setActiveTransactionId', 0)
    }
  },
  async addOrderLine({ commit, state, dispatch }, args) {
    try {
      commit('setLoadingPo', true)
      const response = await addPurchaseOrderLine(args)
      commit('appendTransaction', response.Transaction)
      commit('appendPurchaseOrder', response.PurchaseOrder)
      commit('setActiveTransactionId', response.Transaction.POLID)
      saveOpenOrders(state.po)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPo', false)
    }
  },
  async deleteOrderLine({ commit, state }, transaction) {
    try {
      commit('setSavingTransactionId', transaction.POLID)
      commit('setLoadingPo', true)
      const response = await deletePurchaseOrderLine(transaction)
      commit('deleteTransactionById', transaction.POLID)
      const purchaseOrderResponse = await getPurchaseOrder(transaction.POID)
      commit('appendPurchaseOrder', purchaseOrderResponse)
      saveOpenOrders(state.po)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSavingTransactionId', false)
      commit('setLoadingPo', false)
      commit('setActiveTransactionId', 0)
    }
  },
  async getAllocationsForLine({ commit, state }, { POLID }) {
    try {
      commit('setLoadingAllocations', true)
      const allocations = await getAllocationsForPurchaseOrderLine(POLID)
      commit('replaceAllocations', { polid: POLID, allocations })
      return allocations
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingAllocations', false)
    }
  },
  async updateAllocation({ commit, getters, dispatch }, inventory) {
    try {
      commit('setSavingAllocationId', inventory.InventoryID)
      const inventoryResponse = await updateInventory(inventory)
      commit('replaceAllocations', {
        polid: inventoryResponse.PurchaseOrderTransaction.POLID,
        allocations: inventoryResponse.Allocations
      })
      commit('replaceTransaction', inventoryResponse.PurchaseOrderTransaction)
      commit('appendPurchaseOrder', inventoryResponse.PurchaseOrder)
      saveOpenOrders(state.po)
      return inventoryResponse
    } catch (e) {
      throw e
    } finally {
      commit('setSavingAllocationId', false)
    }
  },
  async createAllocation({ commit, dispatch }, allocation) {
    try {
      commit('setSavingTransactionId', allocation.PurchaseOrderLineID)
      const inventoryResponse = await createInventory(allocation)
      commit('replaceAllocations', {
        polid: allocation.PurchaseOrderLineID,
        allocations: inventoryResponse.Allocations
      })
      commit('replaceTransaction', inventoryResponse.PurchaseOrderTransaction)
      commit('appendPurchaseOrder', inventoryResponse.PurchaseOrder)
      saveOpenOrders(state.po)
      return inventoryResponse
    } catch (e) {
      throw e
    } finally {
      commit('setSavingTransactionId', false)
    }
  },
  async allocatedStockOnOrderSales({ commit, getters }, POLID) {
    try {
      const response = await getAllocatedStockOnOrderSales(POLID)
      commit('replaceAllocatedStock', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSavingAllocationId', false)
    }
  },
  async deleteAllocation({ commit, getters }, inventory) {
    try {
      commit('setSavingAllocationId', inventory.InventoryID)
      const inventoryResponse = await deleteInventory(inventory)
      commit('setActiveAllocationId', false)
      commit('replaceAllocations', {
        polid: inventoryResponse.PurchaseOrderTransaction.POLID,
        allocations: inventoryResponse.Allocations
      })
      commit('replaceTransaction', inventoryResponse.PurchaseOrderTransaction)
      commit('appendPurchaseOrder', inventoryResponse.PurchaseOrder)
      saveOpenOrders(state.po)
      return inventoryResponse
    } catch (e) {
      throw e
    } finally {
      commit('setSavingAllocationId', false)
    }
  },
  getChangedDetails({ commit }, POID) {
    const changedDetails = getSavedChanges(POID)
    if (changedDetails) {
      commit('setChangedDetails', {
        POID,
        changedDetails
      })
    }
  },
  setChangedDetails({ commit }, args) {
    commit('setChangedDetails', args)
    saveChanges(args.POID, args.changedDetails)
  },
  removeChangedDetails({ commit }, POID) {
    commit('removeChangedDetails', POID)
    removeSavedChanges(POID)
  }
}

<template>
  <div>
    <b-row>
      <b-col class="col col-xxl-4">
        <live-ex-button class="w-100" :lwin="item.LivExLWinCode" :wine-name="item.wine_name" :vintage="item.year" />
      </b-col>
      <b-col class="col col-xxl-4">
        <b-checkbox
          class="wv-check text-nowrap"
          :checked="userShowOtherCaseSizes"
          @change="handleChangeFormat"
        >
          <div class="wv-check__label text-left">
            All Formats
          </div>
        </b-checkbox>
      </b-col>
      <b-col class="col col-xxl-4">
        <b-button
          :disabled="!hasChanges || !isValid || isWarehouseUser"
          block
          class="btn wv-btn"
          size="sm"
          @click="$emit('ok')"
        >
          OK
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="col col-xxl-4 pt-0">
        <wine-searcher-button class="w-100" :wine-name="item.ComputedWineName" :vintage="item.year" />
      </b-col>
      <b-col class="col col-xxl-4 pt-0">
        <b-checkbox
          class="wv-check text-nowrap"
          :checked="userShowAllSellers"
          @change="handleChangeSellers"
        >
          <div class="wv-check__label text-left">
            All Sellers
          </div>
        </b-checkbox>
      </b-col>
      <b-col class="col col-xxl-4 pt-0">
        <b-button
          block
          class="btn wv-btn"
          size="sm"
          @click="$emit('cancel')"
        >
          Cancel
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import WineSearcherButton from '~/components/Base/WineSearcherButton'
import LiveExButton from '~/components/Base/LiveExButton'
import UserMixin from '~/mixins/user'

export default {
  components: {
    LiveExButton,
    WineSearcherButton
  },
  mixins: [UserMixin],
  props: {
    hasChanges: {
      type: Boolean,
      required: true
    },
    isValid: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleChangeFormat(value) {
      this.userShowOtherCaseSizes = value
      this.$emit('change')
    },
    handleChangeSellers(value) {
      this.userShowAllSellers = value
      this.$emit('change')
    }
  }
}
</script>

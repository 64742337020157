import Vue from 'vue'
import {
  createSalesOrderWithTransaction,
  getSalesOrder,
  getSalesOrderTransactions,
  updateSalesOrderLine,
  deleteSalesOrderLine,
  getAllocationsForSalesOrderLine,
  deleteSalesOrderAllocation,
  updateSalesOrderAllocation,
  addSalesOrderLine,
  updateSalesOrder,
  createSalesOrder,
  deleteSalesOrder,
  addSalesOrderAllocation,
  getAllocationsForSalesOrder,
  getDeliveryCost,
  updateSage,
  getShippingDocumentGet,
  deleteSalesOrderDocument,
  getSalesOrderLineItems,
  updateSalesOrderLineItem
} from '@/api/salesOrders/salesOrder'

import { getAnyAddressById } from '@/api/contacts/addresses'

import {
  getSavedChanges,
  saveChanges,
  removeSavedChanges,
  saveOpenOrders,
  getOpenOrders
} from '@/api/salesOrders/savedChanges'

import {
  filter,
  map,
  find,
  each,
  findIndex,
  without,
  keys,
  clone,
  reduce,
  sortBy,
  toString
} from 'lodash'

import { SalesOrderType } from '~/constants/salesOrderType'

export const state = () => ({
  so: [],
  soIdx: {},
  loadingSo: false,
  saving: false,
  transactions: [],
  soTransactionIdx: {},
  savingTransactionId: false,
  savingAllocationId: false,
  activeTransactionId: 0,
  activeAllocation: false,
  allocations: [],
  allocationsIdx: {},
  loadingAllocations: false,
  allocatedStock: [],
  allocatedStockIdx: [],
  deliveryAddresses: {},
  loadingDeliveryAddress: false,
  allocationProfits: {},
  loadingAllocationProfits: false,
  changedDetails: {},
  loadingEstimatedDelivery: false,
  estimatedDelivery: -1,
  salesOrderTransactionSearches: {},
  toCloseId: null,
  shippingLineStatus: {},
  shippingDocuments: {},
  savingLineItemId: false,
  lineItems: [],
  soLineItemsIdx: {}
})

export const getters = {
  getLastSalesOrderId: state => {
    const salesOrders = sortBy(state.so, 'SOID')
    if (salesOrders.length > 0) {
      return salesOrders[salesOrders.length - 1].SOID
    }
    return 0
  },
  getNewSalesOrderKey: state => {
    const newSos = filter(state.so, r => r.isNew)
    const newCount = newSos.length + 1
    return 'NEW-' + newCount
  },
  getSalesOrderBySOID: state => soid => {
    return find(state.so, so => {
      return toString(so.SOID) === toString(soid)
    })
  },
  getChangesBySOID: state => soid => {
    return state.changedDetails[soid]
  },
  getShippingDocumentsBySOID: state => soid => {
    return state.shippingDocuments[soid] || []
  },
  getTransactionsForSOID: state => soid => {
    return map(state.soTransactionIdx[soid], idx => state.transactions[idx])
  },
  getShippingLineStatusForSOID: state => soid => {
    const SOLIDs = map(
      state.soTransactionIdx[soid],
      idx => state.transactions[idx]
    ).map(t => t.SOLID)
    const keys = Object.keys(state.shippingLineStatus)
    const transactionKeys = keys.filter(k => SOLIDs.indexOf(parseInt(k)) >= 0)
    const reducedShipping = {}
    transactionKeys.forEach(k => {
      reducedShipping[k] = state.shippingLineStatus[k]
    })
    return reducedShipping
  },
  getDeliveryAddressBySOID: state => soid => {
    return state.deliveryAddresses[soid]
  },
  getAllocatedStockForSOLID: state => solid => {
    return map(state.allocatedStockIdx[solid], idx => state.allocatedStock[idx])
  },
  getActiveTransaction: state => soid => {
    return find(
      state.transactions,
      t => t.SOLID === state.activeTransactionId && t.SOID === parseInt(soid)
    )
  },
  getActiveAllocation: state => {
    if (state.activeAllocation === false) {
      return undefined
    }
    return find(state.allocations, state.activeAllocation)
  },
  getAllocationsForTransaction: state => transaction => {
    if (!transaction) {
      return []
    }
    return map(
      state.allocationsIdx[transaction.SOLID],
      idx => state.allocations[idx]
    )
  },
  getTotalProfitForSOID: state => soid => {
    let profit = {
      value: 0,
      bookValue: 0,
      percentage: 0
    }
    if (state.allocationProfits[soid]) {
      profit = reduce(
        state.allocationProfits[soid],
        (o, a) => {
          o.value +=
            (a.SalePrice - a.CostPrice) * (a.AllocationBottles / a.CaseSize)
          o.bookValue += a.CostPrice * (a.AllocationBottles / a.CaseSize)
          return o
        },
        profit
      )
    }

    if (profit.bookValue > 0) {
      profit.percentage = (profit.value * 100) / profit.bookValue
    }
    return profit
  },
  getLineItemsForSOID: state => soid => {
    return map(state.soLineItemsIdx[soid], idx => state.lineItems[idx])
  },
  getActiveLineItem: state => soid => {
    return find(
      state.lineItems,
      t => t.SOLIID === state.activeLineItemId && t.SOID === parseInt(soid)
    )
  }
}

export const mutations = {
  setToClose(state, id) {
    state.toCloseId = id
  },
  setSalesOrderTransactionSearches(state, { SOID, search }) {
    Vue.set(state.salesOrderTransactionSearches, SOID, search)
  },
  setLoadingEstimatedDelivery(state, loading) {
    state.loadingEstimatedDelivery = loading
  },
  setEstimatedDelivery(state, cost) {
    state.estimatedDelivery = cost
  },
  appendSalesOrder(state, salesOrder) {
    const currentIndex = state.soIdx[salesOrder.SOID]
    if (currentIndex !== undefined) {
      Vue.set(state.so, currentIndex, salesOrder)
    } else {
      const currentLength = state.so.length
      state.so.push(salesOrder)
      Vue.set(state.soIdx, salesOrder.SOID, currentLength)
    }
  },

  removeSalesOrderById(state, SOID) {
    const currentIndex = state.soIdx[SOID]
    if (currentIndex !== undefined) {
      Vue.delete(state.so, currentIndex)
      state.soIdx = {}
      each(state.so, (so, idx) => {
        Vue.set(state.soIdx, so.SOID, idx)
      })
    }
  },
  setShippingLineStatus(state, { SOLID, Shipped }) {
    Vue.set(state.shippingLineStatus, SOLID, Shipped)
  },
  mergeShippingLineStatus(state, shippingItems) {
    state.shippingLineStatus = Object.assign(
      {},
      state.shippingLineStatus,
      shippingItems
    )
  },
  removeFromShippingLineStatus(state, SOLIDs) {
    const filteredLines = {}
    const keys = Object.keys(state.shippingLineStatus)
    const filtedKeys = keys.filter(k => SOLIDs.indexOf(parseInt(k)) < 0)
    filtedKeys.forEach(k => {
      filteredLines[k] = state.shippingLineStatus[k]
    })
    state.shippingLineStatus = filteredLines
  },
  setLoadingSo(state, loadingSo) {
    state.loadingSo = loadingSo
  },
  setSaving(state, saving) {
    state.saving = saving
  },
  setDeliveryAddress(state, { SOID, Address }) {
    Vue.set(state.deliveryAddresses, SOID, Address)
  },
  setLoadingDeliveryAddress(state, loading) {
    state.loadingDeliveryAddress = loading
  },
  setLoadingAllocations(state, loading) {
    state.loadingAllocations = loading
  },
  setLoadingAllocationProfits(state, loading) {
    state.loadingAllocationProfits = loading
  },
  replaceAllocationProfits(state, { soid, allocations }) {
    Vue.set(state.allocationProfits, soid, allocations)
  },
  replaceAllocations(state, { solid, allocations }) {
    if (state.allocationsIdx[solid]) {
      state.allocations = filter(state.allocations, (a, idx) => {
        return state.allocationsIdx[solid].indexOf(idx) < 0
      })
    }

    const newAllocList = state.allocations.concat(allocations)
    const allocIdx = {}
    each(newAllocList, (alloc, idx) => {
      if (!allocIdx[alloc.SOLID]) {
        allocIdx[alloc.SOLID] = []
      }
      allocIdx[alloc.SOLID].push(idx)
    })

    state.allocations = newAllocList
    state.allocationsIdx = allocIdx
  },
  replaceAllocation(state, allocation) {
    let idx = findIndex(state.allocations, {
      SAID: allocation.SAID
    })
    if (idx < 0) {
      idx = findIndex(state.allocations, {
        InventoryID: allocation.InventoryID
      })
    }
    if (idx < 0) {
      const currentIdx = state.allocations.length
      state.allocations.push(allocation)
      if (allocation.SOLID in state.allocationsIdx === false) {
        Vue.set(state.allocationsIdx, allocation.SOLID, [])
      }
      state.allocationsIdx[allocation.SOLID].push(currentIdx)
    } else {
      Vue.set(state.allocations, idx, allocation)
    }
  },
  replaceTransactions(state, { soid, transactions }) {
    if (state.soTransactionIdx[soid]) {
      state.transactions = filter(
        state.transactions,
        t => parseInt(t.SOID) !== parseInt(soid)
      )
      state.soTransactionIdx[soid] = []
    }
    each(transactions, transaction => {
      const currentIdx = state.transactions.length
      state.transactions.push(transaction)
      if (transaction.SOID in state.soTransactionIdx === false) {
        Vue.set(state.soTransactionIdx, transaction.SOID, [])
      }
      state.soTransactionIdx[transaction.SOID].push(currentIdx)
    })
  },
  appendTransaction(state, Transaction) {
    const currentIdx = state.transactions.length
    state.transactions.push(Transaction)
    if (state.soTransactionIdx[Transaction.SOID] === undefined) {
      Vue.set(state.soTransactionIdx, Transaction.SOID, [])
    }
    state.soTransactionIdx[Transaction.SOID].push(currentIdx)
  },
  replaceTransaction(state, Transaction) {
    if (Transaction.SOLID !== undefined) {
      const solidIdx = findIndex(
        state.transactions,
        transaction => transaction.SOLID === Transaction.SOLID
      )
      Vue.set(state.transactions, solidIdx, Transaction)
    }
  },
  updateTransactionValue(state, mergeData) {
    if (mergeData.SOLID) {
      const SOLIDIdx = findIndex(
        state.transactions,
        transaction => transaction.SOLID === mergeData.SOLID
      )
      if (SOLIDIdx !== undefined) {
        const transaction = state.transactions[SOLIDIdx]
        each(keys(mergeData), key => {
          Vue.set(transaction, key, mergeData[key])
        })
        Vue.set(state.transactions, SOLIDIdx, transaction)
      }
    }
  },
  setSavingTransactionId(state, id) {
    state.savingTransactionId = id
  },
  setSavingAllocationId(state, id) {
    state.savingAllocationId = id
  },
  setActiveTransactionId(state, id) {
    state.activeTransactionId = id
  },
  setActiveAllocation(state, { InventoryID, SOLID }) {
    state.activeAllocation = {
      InventoryID,
      SOLID
    }
  },
  removeActiveAllocation(state) {
    state.activeAllocation = false
  },
  deleteTransactionById(state, SOLID) {
    const idx = findIndex(state.transactions, { SOLID })
    if (idx !== undefined) {
      const SOID = state.transactions[idx].SOID
      state.transactions = without(state.transactions, t => t.SOLID === SOLID)
      Vue.delete(
        state.soTransactionIdx[SOID],
        state.soTransactionIdx[SOID].indexOf(idx)
      )
    }
  },
  replaceAllocatedStock(state, allocatedStock) {
    each(allocatedStock, stock => {
      Vue.delete(state.allocatedStockIdx, stock.SOLID)
    })
    each(allocatedStock, stock => {
      const currentIdx = state.allocatedStock.length
      state.allocatedStock.push(stock)
      if (stock.SOLID in state.allocatedStockIdx === false) {
        Vue.set(state.allocatedStockIdx, stock.SOLID, [])
      }
      state.allocatedStockIdx[stock.SOLID].push(currentIdx)
    })
  },
  setChangedDetails(state, { SOID, changedDetails }) {
    changedDetails = JSON.parse(JSON.stringify(changedDetails))
    if (!changedDetails.UseBillingAddress === undefined) {
      changedDetails.UseBillingAddress = false
    }
    Vue.set(state.changedDetails, SOID, changedDetails)
  },
  removeChangedDetails(state, SOID) {
    state.estimatedDelivery = -1
    Vue.delete(state.changedDetails, SOID)
  },
  setShippingDocuments(state, { SOID, documents }) {
    Vue.set(state.shippingDocuments, SOID, documents)
  },
  setSavingLineItemId(state, id) {
    state.savingLineItemId = id
  },
  replaceLineItems(state, { soid, lineItems }) {
    if (state.soLineItemsIdx[soid]) {
      state.lineItems = filter(
        state.lineItems,
        t => parseInt(t.SOID) !== parseInt(soid)
      )
      state.soLineItemsIdx[soid] = []
    }
    each(lineItems, lineItem => {
      const currentIdx = state.lineItems.length
      state.lineItems.push(lineItem)
      if (lineItem.SOID in state.soLineItemsIdx === false) {
        Vue.set(state.soLineItemsIdx, lineItem.SOID, [])
      }
      state.soLineItemsIdx[lineItem.SOID].push(currentIdx)
    })
  },
  allocationsNoteUpdate(state, { WineCardID, Count, NoteString }) {
    state.allocations.map((allocation, index) => {
      if (allocation.WineCardID === WineCardID) {
        Vue.set(allocation, 'NoteCount', Count)
        Vue.set(allocation, 'WineCardNotesString', NoteString)
        Vue.set(state.allocations, index, allocation)
      }
    })
  }
  // appendTransaction(state, Transaction) {
  //   const currentIdx = state.transactions.length
  //   state.transactions.push(Transaction)
  //   if (state.soTransactionIdx[Transaction.SOID] === undefined) {
  //     Vue.set(state.soTransactionIdx, Transaction.SOID, [])
  //   }
  //   state.soTransactionIdx[Transaction.SOID].push(currentIdx)
  // },
  // replaceTransaction(state, Transaction) {
  //   if (Transaction.SOLID !== undefined) {
  //     const solidIdx = findIndex(
  //       state.transactions,
  //       transaction => transaction.SOLID === Transaction.SOLID
  //     )
  //     Vue.set(state.transactions, solidIdx, Transaction)
  //   }
  // },
  // updateTransactionValue(state, mergeData) {
  //   if (mergeData.SOLID) {
  //     const SOLIDIdx = findIndex(
  //       state.transactions,
  //       transaction => transaction.SOLID === mergeData.SOLID
  //     )
  //     if (SOLIDIdx !== undefined) {
  //       const transaction = state.transactions[SOLIDIdx]
  //       each(keys(mergeData), key => {
  //         Vue.set(transaction, key, mergeData[key])
  //       })
  //       Vue.set(state.transactions, SOLIDIdx, transaction)
  //     }
  //   }
  // },
}

export const actions = {
  reloadOpenSo({ commit, dispatch }) {
    each(getOpenOrders(), so => {
      commit('appendSalesOrder', so)
      dispatch('getChangedDetails', so.SOID)
    })
  },
  loadFromId({ dispatch, state }, { soid, force }) {
    return new Promise(async (resolve, reject) => {
      if (soid.toString().substring(0, 3) !== 'NEW') {
        try {
          await dispatch('getSalesOrder', { soid, force })
          const so = state.so[state.soIdx[soid]]
          if (so.SaleOrderTypeID === SalesOrderType.Storage) {
            await dispatch('getLineItems', { soid })
          } else {
            await dispatch('getTransactions', { soid })
          }
          resolve()
        } catch (e) {
          reject(e)
        }
      } else {
        try {
          await dispatch('createBlankSalesOrder', soid)
          resolve()
        } catch (e) {
          reject(e)
        }
      }
    })
  },
  async createSalesOrder({ commit, state }, args) {
    try {
      commit('setSaving', true)
      const response = await createSalesOrder(args)
      commit('appendSalesOrder', response)
      saveOpenOrders(state.so)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  cloneSalesOrder({ commit, state, getters }, SOID) {
    if (state.soIdx[SOID] !== undefined) {
      const salesOrder = clone(state.so[state.soIdx[SOID]])
      salesOrder.SOID = getters.getNewSalesOrderKey
      salesOrder.isNew = true
      commit('appendSalesOrder', salesOrder)
      saveOpenOrders(state.so)
      return salesOrder
    }
  },
  async getSalesOrder({ commit, state }, { soid, force }) {
    try {
      const currentIndex = state.soIdx[soid]
      if (currentIndex === undefined || force) {
        commit('setLoadingSo', true)
        const response = await getSalesOrder(soid)
        commit('appendSalesOrder', response)
        saveOpenOrders(state.so)
        return response
      } else {
        return state.so[state.soIdx[soid]]
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingSo', false)
    }
  },
  createBlankSalesOrder({ commit, getters, state }, SOID) {
    if (!SOID) {
      SOID = getters.getNewSalesOrderKey
    }
    if (state.soIdx[SOID] === undefined) {
      commit('appendSalesOrder', {
        SOID: SOID,
        isNew: true,
        UseBillingAddress: false,
        OrderDate: new Date(),
        WorkflowStatusID: 1,
        Carriage: 0
      })
      saveOpenOrders(state.so)
    }
    return SOID
  },
  async getTransactions({ commit, state }, { soid }) {
    try {
      commit('setLoadingSo', true)
      const transactions = await getSalesOrderTransactions(soid)
      commit('replaceTransactions', { soid, transactions })
      return transactions
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingSo', false)
    }
  },
  async updateOrderLine({ commit, state, dispatch }, args) {
    try {
      commit('setSavingTransactionId', args.SOLID)
      commit('setLoadingSo', true)
      const response = await updateSalesOrderLine(args)
      commit('replaceTransaction', response.Transaction)
      commit('appendSalesOrder', response.SalesOrder)
      saveOpenOrders(state.so)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSavingTransactionId', false)
      commit('setLoadingSo', false)
    }
  },
  async deleteOrderLine({ commit, state }, transaction) {
    try {
      commit('setSavingTransactionId', transaction.SOLID)
      commit('setLoadingSo', true)
      const response = await deleteSalesOrderLine(transaction)
      commit('deleteTransactionById', transaction.SOLID)
      const salesOrderResponse = await getSalesOrder(transaction.SOID)
      commit('appendSalesOrder', salesOrderResponse)
      saveOpenOrders(state.so)
      commit('setActiveTransactionId', 0)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSavingTransactionId', false)
      commit('setLoadingSo', false)
    }
  },
  async getAllocationsForLine({ commit, state }, data) {
    try {
      commit('setLoadingAllocations', true)
      const allocations = await getAllocationsForSalesOrderLine(
        data.transaction.SOLID,
        data.isReserves
      )
      commit('replaceAllocations', {
        solid: data.transaction.SOLID,
        allocations
      })
      return allocations
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingAllocations', false)
    }
  },
  async updateAllocation({ commit, getters, dispatch }, alloc) {
    try {
      commit('setSavingAllocationId', alloc.SAID)
      const response = await updateSalesOrderAllocation(alloc)
      commit('replaceTransaction', response.Transaction)
      commit('appendSalesOrder', response.SalesOrder)
      commit('replaceAllocation', response.Allocation)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSavingAllocationId', false)
    }
  },
  async createAllocation({ commit, dispatch }, alloc) {
    try {
      commit('setSavingTransactionId', alloc.SOLID)
      const response = await addSalesOrderAllocation(alloc)
      commit('replaceTransaction', response.Transaction)
      commit('appendSalesOrder', response.SalesOrder)
      commit('replaceAllocation', response.Allocation)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSavingTransactionId', false)
    }
  },
  async deleteAllocation({ commit, getters, dispatch }, data) {
    try {
      commit('setSavingAllocationId', data.salesAlloc.SAID)
      await deleteSalesOrderAllocation({
        SAID: data.salesAlloc.SAID,
        AuditAmendedDate: data.salesAlloc.SalesAllocAuditAmendedDate
      })
      commit('removeActiveAllocation', false)
      await Promise.all([
        dispatch('getTransactions', { soid: data.salesAlloc.SOID }),
        dispatch('getAllocationsForLine', {
          transaction: data.salesAlloc,
          isReserves: data.isReserves
        })
      ])
    } catch (e) {
      throw e
    } finally {
      commit('setSavingAllocationId', false)
    }
  },
  async addOrderLine({ commit, state, dispatch }, args) {
    try {
      commit('setLoadingSo', true)
      const response = await addSalesOrderLine(args)
      commit('appendTransaction', response.Transaction)
      commit('appendSalesOrder', response.SalesOrder)
      saveOpenOrders(state.so)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingSo', false)
    }
  },
  async createNewSalesOrderWithTransaction({ commit }, args) {
    try {
      commit('setSaving', true)
      const response = await createSalesOrderWithTransaction(args)
      commit('appendSalesOrder', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async updateSalesOrder({ commit, state, getters }, args) {
    try {
      commit('setSaving', true)
      const response = await updateSalesOrder(args)
      commit('appendSalesOrder', response)
      // const updatedLines = getters.getShippingLineStatusForSOID(args.SOID)
      // const keys = Object.keys(updatedLines).map(k => parseInt(k))
      // commit('removeFromShippingLineStatus', keys)
      saveOpenOrders(state.so)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  closeSalesOrderById({ commit, getters, state }, SOID) {
    commit('removeSalesOrderById', SOID)
    commit('removeChangedDetails', SOID)
    saveOpenOrders(state.so)
    removeSavedChanges(SOID)
  },
  async deleteSalesOrder({ commit, getters, state }, args) {
    try {
      commit('setSaving', true)
      const salesOrder = getters.getSalesOrderBySOID(args.SOID)
      let response = null
      if (salesOrder.isNew !== true) {
        response = await deleteSalesOrder(args)
      }
      commit('removeSalesOrderById', args.SOID)
      saveOpenOrders(state.so)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async getDeliveryAddress({ commit }, { SOID, RecordID }) {
    try {
      commit('setLoadingDeliveryAddress', true)
      const Address = await getAnyAddressById(RecordID)
      commit('setDeliveryAddress', {
        SOID,
        Address
      })
      return Address
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingDeliveryAddress', false)
    }
  },
  async getAllocationProfits({ commit }, { SOID }) {
    try {
      commit('setLoadingAllocationProfits', true)
      const allocations = await getAllocationsForSalesOrder(SOID)
      commit('replaceAllocationProfits', {
        soid: SOID,
        allocations
      })
      return allocations
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingAllocationProfits', false)
    }
  },
  async getDeliveryCost({ commit }, args) {
    try {
      commit('setLoadingEstimatedDelivery', true)
      const response = await getDeliveryCost(args)
      commit('setEstimatedDelivery', response.TotalCost)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingEstimatedDelivery', false)
    }
  },
  getChangedDetails({ commit }, SOID) {
    const changedDetails = getSavedChanges(SOID)
    if (changedDetails) {
      commit('setChangedDetails', {
        SOID,
        changedDetails
      })
    }
  },
  async getShippingDocuments({ commit }, SOID) {
    const documents = await getShippingDocumentGet(SOID)
    if (documents) {
      commit('setShippingDocuments', {
        SOID,
        documents
      })
    }
  },
  async deleteSalesOrderDocument({ commit, state }, { SOID, UUID }) {
    try {
      await deleteSalesOrderDocument({ SOID, UUID })
      const documents = state.shippingDocuments[SOID].filter(
        d => d.UUID !== UUID
      )
      commit('setShippingDocuments', {
        SOID,
        documents
      })
    } catch (e) {
      throw e
    }
  },
  setChangedDetails({ commit }, args) {
    commit('setChangedDetails', args)
    saveChanges(args.SOID, args.changedDetails)
  },
  removeChangedDetails({ commit }, SOID) {
    commit('removeChangedDetails', SOID)
    removeSavedChanges(SOID)
  },
  async updateSage({ commit, state }, args) {
    try {
      commit('setSaving', true)
      const response = await updateSage(args)
      commit('appendSalesOrder', response)
      saveOpenOrders(state.so)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  },
  async getLineItems({ commit, state }, { soid }) {
    try {
      // console.log('getLineItems')
      commit('setLoadingSo', true)
      const lineItems = await getSalesOrderLineItems(soid)
      // console.log(lineItems)
      commit('replaceLineItems', { soid, lineItems })
      return lineItems
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingSo', false)
    }
  },
  async updateOrderLineItem({ commit, state, dispatch }, args) {
    try {
      commit('setSavingLineItemId', args.SOLIID)
      commit('setLoadingSo', true)
      const response = await updateSalesOrderLineItem(args)
      // commit('replaceLineItem', response.LineItem)
      commit('appendSalesOrder', response.SalesOrder)
      saveOpenOrders(state.so)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setSavingLineItemId', false)
      commit('setLoadingSo', false)
    }
  }
}

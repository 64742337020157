<template>
  <main :class="{'main': true, 'dev': !isLive, 'reserves': isReserves}">
    <main-navbar :nav-items="userNavbarItems" :main-items="userSidebarItems" :user="user" />
    <div class="main__content container">
      <nuxt class="main__wrapper d-flex flex-column" :class="nuxtContent" />
      <price-lookup-modal-handler />
      <empty-case-modal-handler />
      <reserve-level-modal-handler />
      <wine-notes-modal-handler />
      <add-wine-note-modal-handler />
      <current-stock-modal-handler />
      <new-purchase-order-modal-handler />
      <new-sales-order-modal-handler />
      <all-wine-notes-modal-handler />
      <wine-list-export-settings-handler />
      <wine-list-export-handler />
      <edit-wine-note-modal-handler />
      <contact-search-modal-handler />
      <email-promotion-template-hander />
      <export-email-modal-handler />
      <new-wine-card-handler />
      <confirm-modal v-model="boolShowIdleModal" title="Warning" @change="idleModalChange">
        This application has been inactive for {{ globals.TimeOutWarningMinutes }} minutes.<br>
        This application will close in another {{ globals.TimeOutExitMinutes - globals.TimeOutWarningMinutes }} minutes.<br>
        This will be at approximately {{ timeoutExitTime }}.
      </confirm-modal>
      <loose-bottles-modal-handler />
    </div>
  </main>
</template>

<script>
import MainNavbar from '@/components/layout/MainNavbar/MainNavbar.vue'
import PriceLookupModalHandler from '~/components/Modal/Handler/PriceLookupModal'
import EmptyCaseModalHandler from '~/components/Modal/Handler/EmptyCaseModal'
import ReserveLevelModalHandler from '~/components/Modal/Handler/ReserveLevelModal'
import WineNotesModalHandler from '~/components/Modal/Handler/WineNotesModal'
import AddWineNoteModalHandler from '~/components/Modal/Handler/AddWineNoteModal'
import EditWineNoteModalHandler from '~/components/Modal/Handler/EditWineNoteModal'
import CurrentStockModalHandler from '~/components/Modal/Handler/CurrentStockModal'
import NewPurchaseOrderModalHandler from '~/components/Modal/Handler/NewPurchaseOrderModal'
import NewSalesOrderModalHandler from '~/components/Modal/Handler/NewSalesOrderModal'
import AllWineNotesModalHandler from '~/components/Modal/Handler/AllWineNotes'
import ConfirmModal from '~/components/Modal/Confirm'
import moment from 'moment'
import WineListExportSettingsHandler from '~/components/Modal/Handler/WineListExportSettings'
import WineListExportHandler from '~/components/Modal/Handler/WineListExportModal'
import ContactSearchModalHandler from '~/components/Modal/Handler/ContactSearchModal'
import EmailPromotionTemplateHander from '~/components/Modal/Handler/EmailPromotionTemplate'
import ExportEmailModalHandler from '~/components/Modal/Handler/ExportEmailModal'
import NewWineCardHandler from '~/components/Modal/Handler/NewWineCard'
import LooseBottlesModalHandler from '~/components/Modal/Handler/LooseBottlesModal'
import { mapState } from 'vuex'

export default {
  components: {
    MainNavbar,
    PriceLookupModalHandler,
    EmptyCaseModalHandler,
    ReserveLevelModalHandler,
    WineNotesModalHandler,
    AddWineNoteModalHandler,
    CurrentStockModalHandler,
    NewPurchaseOrderModalHandler,
    NewSalesOrderModalHandler,
    AllWineNotesModalHandler,
    ConfirmModal,
    WineListExportSettingsHandler,
    EditWineNoteModalHandler,
    WineListExportHandler,
    ContactSearchModalHandler,
    EmailPromotionTemplateHander,
    ExportEmailModalHandler,
    NewWineCardHandler,
    LooseBottlesModalHandler
  },
  data() {
    return {
      lastNonIdleAt: null,
      timeoutWarningTimer: null,
      timeoutExitTimer: null,
      boolShowIdleModal: false,
      webCheckInterval: null,
      altPressed: false,
      reservesState: false
    }
  },
  computed: {
    userOfficeOnly() {
      return this.$store.getters['user/isOfficeUser']
    },
    userNavbarItems() {
      return this.navbarItems.filter(n => {
        return n.officeOnly === false || this.userOfficeOnly
      })
    },
    userSidebarItems() {
      return this.sidebarItems.filter(n => {
        return n.officeOnly === false || this.userOfficeOnly
      })
    },
    ...mapState('menus', {
      sidebarItems: state => state.sidebarItems,
      navbarItems: state => state.navbarItems
    }),
    ...mapState('user', {
      user: state => state.user
    }),
    ...mapState('global', {
      globals: state => state.data
    }),
    nuxtContent: function() {
      return this.navbarItems.length > 0
        ? 'nuxt-content'
        : 'nuxt-content-no-nav'
    },
    timeoutExitTime() {
      return moment()
        .add(this.globals.TimeOutExitMinutes, 'minutes')
        .format('HH:mm')
    },
    webTotal() {
      return this.$store.getters['web/changes/total']
    },
    isLive() {
      return process.env.apiHost === 'https://api.wilkinsonvintners.com'
    },
    isReserves() {
      return this.reservesState || this.$route.name.includes('reserves')
    }
  },
  watch: {
    webTotal(total) {
      if (total > 0) {
        this.$store.commit('menus/setWebClass', 'red')
      } else {
        this.$store.commit('menus/setWebClass')
      }
    }
  },
  created() {
    this.$store.dispatch('global/getGlobalsIfNotLoaded').then(() => {
      this.resetWarningTimer()
      window.addEventListener('mousemove', this.nonIdleActivity)
      window.addEventListener('keydown', this.nonIdleActivity)
    })
    window.addEventListener('keydown', this.keydown)
    window.addEventListener('keyup', this.keyup)
    window.addEventListener('resize', this.resizeWindow)
    this.$store.dispatch('user/loadSettingsFromLocal')
    this.createIntervalPOCheck()
    this.$eventHub.$on('set-reserves-state', this.setReservesState)
  },
  destroyed() {
    window.removeEventListener('mousemove', this.nonIdleActivity)
    window.removeEventListener('keydown', this.nonIdleActivity)
    window.removeEventListener('resize', this.resizeWindow)
    if (this.webCheckInterval) {
      clearInterval(this.webCheckInterval)
    }
  },
  methods: {
    createIntervalPOCheck() {
      if (!this.webCheckInterval) {
        this.webCheckInterval = setInterval(() => {
          this.$store.dispatch('web/changes/update')
        }, 1000 * 30)
        this.$store.dispatch('web/changes/update')
      }
    },
    resizeWindow(event) {
      this.$eventHub.$emit('resize-window', event)
    },
    keydown(event) {
      if (event.key === 'Alt') {
        this.altPressed = true
      }
    },
    keyup(event) {
      if (event.key === 'Alt') {
        this.altPressed = false
        // return
      }
      // if (this.altPressed && event.code.startsWith('Digit')) {
      //   let idx = Number(event.code.substr(5, event.code.length)) - 1
      //   // Handle zero press
      //   if (idx === -1) idx = 9
      //   if (idx < this.sidebarItems.length) {
      //     this.$router.push(this.sidebarItems[idx].to)
      //   }
      // }
    },
    idleModalChange(val) {
      this.boolShowIdleModal = val
    },
    nonIdleActivity() {
      this.resetWarningTimer()
    },
    resetWarningTimer() {
      if (this.timeoutWarningTimer !== null) {
        window.clearTimeout(this.timeoutWarningTimer)
      }
      if (this.timeoutExitTimer !== null) {
        window.clearTimeout(this.timeoutExitTimer)
      }
      this.timeoutWarningTimer = window.setTimeout(
        this.warningTimerTick,
        this.globals.TimeOutExitMinutes * 1000 * 60
      )
    },
    warningTimerTick() {
      this.boolShowIdleModal = true
      this.timeoutExitTimer = window.setTimeout(
        this.exitTimerTick,
        (this.globals.TimeOutExitMinutes - this.globals.TimeOutWarningMinutes) *
          1000 *
          60
      )
    },
    exitTimerTick() {
      this.boolShowIdleModal = false
      window.sessionStorage.clear()
      window.localStorage.clear()
      window.location = '/'
    },
    setReservesState(state) {
      this.reservesState = state === true
    }
  }
}
</script>

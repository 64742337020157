<template>
  <group-box :header="true" class="h-100">
    <template slot="header">
      Grouping
    </template>
    <template v-if="looseOverride">
      <b-row class="my-1">
        <b-col v-b-tooltip.hover title="Restricted by Filters (Loose Bottles Only)">
          <d-form-radio v-model="grouping" :disabled="true" value="BOTH" name="grouping" class="text-left">
            Non OWC &amp; Loose
          </d-form-radio>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row class="my-1">
        <b-col>
          <d-form-radio v-model="grouping" value="ALL" name="grouping" class="text-left">
            All Combined
          </d-form-radio>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col>
          <d-form-radio v-model="grouping" value="BOTH" name="grouping" class="text-left">
            Just Cases
          </d-form-radio>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col>
          <d-form-radio v-model="grouping" value="OWC" name="grouping" class="text-left">
            OWC & Loose
          </d-form-radio>
        </b-col>
      </b-row>
    </template>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'

export default {
  components: {
    GroupBox
  },
  props: {
    value: {
      type: String,
      required: true
    },
    looseOverride: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    grouping: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<template>
  <b-popover
    :target="target"
    :header="header"
    triggers="click"
  >
    <p class="mb-2">
      <slot />
    </p>
    <div class="text-center">
      <b-button size="sm" class="wv-btn" @click="exitPopover">
        Exit
      </b-button>
    </div>
  </b-popover>
</template>

<script>
export default {
  props: {
    target: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: true
    }
  },
  methods: {
    exitPopover() {
      this.$root.$emit('bv::hide::popover')
    }
  }
}
</script>

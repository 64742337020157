import { getBillingRuns } from '~/api/storage/billingRun'
import { BillingRunStatus } from '~/constants/billingRunStatus'
import Vue from 'vue'

export const state = () => ({
  perPage: 60,
  page: 1,
  results: [],
  total: 0,
  loading: false,
  loadedPage: 0
})

export const getters = {
  pagedResults: state => {
    return state.results.slice(0, state.page * state.perPage)
  },
  loading: state => {
    return state.loading
  },
  syncingBRIDs: state => {
    return state.results
      .filter(n => n) // remove Nulls...
      .filter(
        billingRun =>
          billingRun.BRSID === BillingRunStatus.Pending ||
          billingRun.BRSID === BillingRunStatus.Processing ||
          billingRun.BRSID === BillingRunStatus.Running
      )
      .map(billingRun => billingRun.BRID)
  },
  latestToDate: state => {
    if (state.results.length === 0) {
      return null
    }
    const latestDateObject = state.results.reduce((latest, current) => {
      const currentDate = new Date(current.ToDate)
      const latestDate = new Date(latest.ToDate)

      return currentDate > latestDate ? current : latest
    }, state.results[0])

    return latestDateObject ? latestDateObject.ToDate : null
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  appendResults(state, results) {
    state.results = [].concat(state.results).concat(results)
  },
  removeResults(state, billingRunIDs) {
    state.results = state.results.filter(
      billingRun => !billingRunIDs.includes(billingRun.BRID)
    )
  },
  updateResults(state, results) {
    // console.log('updateResults', state.results, results)
    results.forEach(billingRun => {
      const idx = state.results.findIndex(
        existingbillingRun => existingbillingRun.BRID === billingRun.BRID
      )
      Vue.set(state.results, idx, billingRun)
    })
  },
  reset(state) {
    state.results = []
    state.page = 1
    state.total = 0
    state.loadedPage = 0
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setLoadedPage(state, loadedPage) {
    state.loadedPage = loadedPage
  }
}

export const actions = {
  async search({ commit, state }, fields) {
    commit('setLoading', true)
    try {
      commit('setPage', 1)
      const billingRuns = await getBillingRuns({
        PerPage: state.perPage,
        Page: state.page,
        ...fields
      })
      if (billingRuns) {
        commit('setTotal', billingRuns.total)
        commit('appendResults', billingRuns.billingRunPaginatedResults)
        commit('setLoadedPage', 1)
      }
      return billingRuns
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async changePage({ commit, state }, { page, searchParameters }) {
    if (state.loadedPage >= page) {
      commit('setPage', page)
      return
    }
    commit('setLoading', true)
    const response = await getBillingRuns({
      PerPage: state.perPage,
      Page: page,
      ...searchParameters
    })
    commit('appendResults', response.billingRunPaginatedResults)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  },
  async updateList({ commit, state }, BRIDS) {
    // console.log('updateList', BRIDS)
    commit('setLoading', true)
    try {
      commit('setPage', 1)
      const billingRuns = await getBillingRuns({
        BRIDS: BRIDS
      })
      if (billingRuns) {
        commit('updateResults', billingRuns.billingRunPaginatedResults)
      }
      return billingRuns
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

import Vue from 'vue'
export const state = () => ({
  columns: [
    {
      key: 'bottles',
      title: 'No. of Bottles',
      column: 'bottles'
    },
    {
      key: 'packs',
      title: 'No. of Packs',
      column: 'packs'
    },
    {
      key: 'cases',
      title: 'Cases (By Volume)',
      column: 'cases'
    },
    {
      key: 'casesPack',
      title: 'Cases (By Pack)',
      column: 'cases'
    },
    {
      key: 'price_per_case',
      title: 'Price (Per Case)',
      column: 'price'
    },
    {
      key: 'price_total_bottles',
      title: 'Price (By Lot)',
      column: 'price'
    },
    {
      key: 'price_per_pack',
      title: 'Price (By Pack)',
      column: 'price'
    },
    {
      key: 'owc',
      title: 'Original Packaging',
      column: 'owc'
    },
    {
      key: 'dutyStatusDPy',
      title: 'Duty Status (Y = Duty Paid)',
      column: 'duty'
    },
    {
      key: 'dutyStatusIBy',
      title: 'Duty Status (Y = In Bond)',
      column: 'duty'
    },
    {
      key: 'dutyStatusKeyed',
      title: 'Duty Status (IB = In Bond, DP = Duty Paid)',
      column: 'duty'
    },
    {
      key: 'packSize',
      title: 'Pack Size',
      column: 'packsize'
    }
  ],
  selectedColumns: {},
  changedData: {},
  currencies: [
    {
      value: 'GBP',
      text: 'GBP'
    },
    {
      value: 'USD',
      text: 'USD'
    },
    {
      value: 'HKD',
      text: 'HKD'
    },
    {
      value: 'EUR',
      text: 'EUR'
    },
    {
      value: 'JPY',
      text: 'JPY'
    },
    {
      value: 'CHF',
      text: 'CHF'
    },
    {
      value: 'SGD',
      text: 'SGD'
    }
  ]
})

export const getters = {
  changedDataValue: state => (key, field, defaultValue) => {
    if (state.changedData[key]) {
      const keys = Object.keys(state.changedData[key])
      if (keys.indexOf(field) >= 0) {
        return state.changedData[key][field]
      }
    }
    return defaultValue
  }
}

export const mutations = {
  resetChangedData(state) {
    state.changedData = {}
  },
  resetSelectedColumns(state) {
    state.selectedColumns = {}
  },
  applySelectedColumns(state, selectedColumns) {
    state.selectedColumns = selectedColumns
  },
  setChangedData(state, { key, field, value }) {
    const dataValues = state.changedData[key] ? state.changedData[key] : {}
    dataValues[field] = value
    Vue.set(state.changedData, key, dataValues)
  },
  setSelectedColumn(state, { key, column, section }) {
    const keys = Object.keys(state.selectedColumns)
    for (let i = 0; i < keys.length; i++) {
      if (state.selectedColumns[keys[i]].key === key) {
        Vue.delete(state.selectedColumns, keys[i])
      } else if (state.selectedColumns[keys[i]].column === column) {
        Vue.delete(state.selectedColumns, keys[i])
      }
    }
    Vue.set(state.selectedColumns, section, { key, column })
  },
  removeSelectedColumn(state, { column }) {
    const keys = Object.keys(state.selectedColumns)
    for (let i = 0; i < keys.length; i++) {
      if (state.selectedColumns[keys[i]].column === column) {
        Vue.delete(state.selectedColumns, keys[i])
      }
    }
  }
}

export const actions = {}

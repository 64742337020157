import BillingRunInventory from '~/models/storage/billingRunInventory'
import { client } from '~/api/graphQLInstance'

export const getBillingRunInventories = async params => {
  // console.log('api getBillingRunInventories', params)

  const result = await client.query({
    query: BillingRunInventory.query('getAll', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BillingRunInventory.queries.getAll.name]
    .billingRunInventoryPaginatedResults
}

export const updateBillingRunInventory = async args => {
  const result = await client.query({
    query: BillingRunInventory.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BillingRunInventory.mutations.update.name]
}

export const updateBillingRunInventoryBulk = async args => {
  const result = await client.query({
    query: BillingRunInventory.mutate('updateBulk', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BillingRunInventory.mutations.updateBulk.name]
}

export const updateBillingRunContact = async args => {
  const result = await client.query({
    query: BillingRunInventory.mutate('updateBillingRunContact', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BillingRunInventory.mutations.updateBillingRunContact.name]
}

import { client } from '../graphQLInstance'
import DutyType from '../../models/dutytype'

export const getDutyTypes = async () => {
  const result = await client.query({
    query: DutyType.query('getAll', {
      SortByDBOrderValue: null
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[DutyType.queries.getAll.name]
}

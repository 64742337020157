<template>
  <group-box>
    <template slot="header">
      Last Purchase
    </template>
    <b-row>
      <b-col class="col-12 col-xxl-6">
        <b-form-group
          label="Price"
          label-for="lastPurchasePrice"
          label-size="sm"
          label-cols-xxl="4"
        >
          <b-form-input
            id="lastPurchasePrice"
            :value="lastPurchasePrice | round(2) | withCommas"
            size="sm"
            disabled
            class="font-weight-bold text-right"
          />
        </b-form-group>
      </b-col>
      <b-col class="col-12 col-xxl-6">
        <b-form-group>
          <b-form-input
            id="formattedLastPurchaseDate"
            :value="formattedLastPurchaseDate"
            size="sm"
            disabled
            class="font-weight-bold"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <b-form-group
          label="Seller"
          label-for="lastPurchaseContact"
          label-size="sm"
          label-cols-xxl="2"
          class="mb-0"
        >
          <b-form-input
            id="lastPurchaseContact"
            :value="lastPurchaseContact"
            size="sm"
            disabled
            class="font-weight-bold"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import moment from 'moment'

export default {
  components: {
    GroupBox
  },
  props: {
    lastPurchasePrice: {
      type: Number,
      required: true
    },
    lastPurchaseDate: {
      type: String,
      required: true
    },
    lastPurchaseContact: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedLastPurchaseDate() {
      if (this.lastPurchaseDate && this.lastPurchaseDate.length > 0) {
        const dateFormat = this.$store.state.user.settings.dateFormat
        return moment(this.lastPurchaseDate).format(dateFormat)
      }
      return ''
    }
  }
}
</script>

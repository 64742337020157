import {
  getCardById,
  addCard,
  deleteCardById,
  getBordeauxIndexPrices,
  getLiveExLatestTransactions,
  getWineSearcherPrices,
  getLivExBidOffer
} from '@/api/wineCard/card'
import { getByWineCardId } from '@/api/wineBrowser/wines'
import { getStockMargins } from '@/api/wineCard/stockMargins'
import { each, map } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  stockLoading: false,
  error: null,
  activeCard: null,
  activeBrowserCard: null,
  stockMargins: [],
  bordeauxIndex: [],
  bordeauxIndexError: false,
  bordeauxIndexLoading: false,
  liveExTransactionsError: false,
  liveExTransactionsLoading: false,
  liveExTransactions: [],
  livExBidOfferError: false,
  livExBidOfferLoading: false,
  livExBidOffer: [],
  wineSearcherError: false,
  wineSearcherErrorMessage: '',
  wineSearcherLoading: false,
  wineSearcher: [],
  activeCardLoadPromises: [],
  activeBrowserCardLoading: false,
  toCloneCard: null
})

export const getters = {
  activeCardHasOrders: state => {
    if (state.activeCard) {
      return (
        state.activeCard.purchase_order_line_usage +
          state.activeCard.sales_order_line_usage >
        0
      )
    }
    return false
  }
}

export const mutations = {
  setActiveCard(state, wineCard) {
    state.activeCard = wineCard
  },
  setToCloneCard(state, wineCard) {
    state.toCloneCard = wineCard
  },
  setActiveBrowserCard(state, wineCard) {
    state.activeBrowserCard = wineCard
  },
  setLoadingActiveBrowserCard(state, loading) {
    state.activeBrowserCardLoading = !!loading
  },
  appendActiveCardPromise(state, promise) {
    state.activeCardLoadPromises.push(promise)
  },
  resetActiveCardPromises(state) {
    state.activeCardLoadPromises = []
  },
  removeActiveCard(state) {
    state.activeCard = null
  },
  setRpScore(state, score) {
    Vue.set(state.activeCard, 'rp', score)
  },
  setWineSellingPrice(state, price) {
    Vue.set(state.activeCard, 'WineSellingPrice', price)
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setStockLoading(state, loading) {
    state.stockLoading = loading === true
  },
  setError(state, error) {
    state.error = error
  },
  setStockMargins(state, margins) {
    state.stockMargins = margins
  },
  clearBordeauxIndex(state) {
    state.bordeauxIndex = []
  },
  setBordeauxIndexLoading(state, loading) {
    state.bordeauxIndexLoading = loading === true
  },
  setBordeauxIndexError(state, error) {
    state.bordeauxIndexError = error === true
  },
  setBordeauxIndex(state, index) {
    state.bordeauxIndex = index
  },
  setLiveExTransactionsLoading(state, loading) {
    state.liveExTransactionsLoading = loading === true
  },
  setLiveExTransactionsError(state, error) {
    state.liveExTransactionsError = error === true
  },
  setLiveExTransactions(state, transactions) {
    state.liveExTransactions = transactions
  },
  setLivExBidOfferLoading(state, loading) {
    state.livExBidOfferLoading = loading === true
  },
  setLivExBidOfferError(state, error) {
    state.livExBidOfferError = error === true
  },
  setLivExBidOffer(state, transactions) {
    state.livExBidOffer = transactions
  },
  setWineSearcherLoading(state, loading) {
    state.wineSearcherLoading = loading === true
  },
  setWineSearcherError(state, error) {
    state.wineSearcherError = error === true
  },
  setWineSearcherErrorMessage(state, error) {
    state.wineSearcherErrorMessage = error
  },
  setWineSearcher(state, transactions) {
    state.wineSearcher = transactions
  }
}

export const actions = {
  async addCardAndSetActive({ commit }, card) {
    commit('removeActiveCard')
    try {
      commit('setLoading', true)
      const result = await addCard(card)
      commit('setActiveCard', result)
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getActiveCard({ commit, state }, id) {
    if (state.activeCard && state.activeCard.id === id) {
      return state.activeCard
    }
    if (state.loading === true) {
      return new Promise((resolve, reject) => {
        commit('appendActiveCardPromise', { resolve, reject })
      })
    }
    commit('setLoading', true)
    commit('setError', null)
    try {
      const result = await getCardById(id)
      await commit('setActiveCard', result)
      each(state.activeCardLoadPromises, promise => promise.resolve(result))
      commit('resetActiveCardPromises')
      commit('setActiveCard', result)
      return result
    } catch (e) {
      commit('setError', e)
      each(state.activeCardLoadPromises, promise => promise.reject(e))
      commit('resetActiveCardPromises')
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getBrowserCard({ commit, state }, id) {
    if (state.activeBrowserCard && state.activeBrowserCard.id === id) {
      return state.activeBrowserCard
    }
    commit('setLoadingActiveBrowserCard', true)
    try {
      const result = await getByWineCardId(id)
      await commit('setActiveBrowserCard', result)
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingActiveBrowserCard', false)
    }
  },
  async reloadActiveCard({ commit, state }) {
    if (state.activeCard) {
      commit('setLoading', true)
      commit('setError', null)
      try {
        const result = await getCardById(state.activeCard.id)
        commit('setActiveCard', result)
        return result
      } catch (e) {
        commit('setError', e)
        throw e
      } finally {
        commit('setLoading', false)
      }
    }
  },
  async deleteCard({ commit, state }, id) {
    try {
      commit('setLoading', true)
      return await deleteCardById(id)
    } catch (e) {
      throw e
    } finally {
      if (state.activeCard.id === id) {
        commit('setActiveCard', null)
      }
      commit('setLoading', false)
    }
  },
  async getStockMargins({ commit }, { WineCardID, WineUsageCodeID }) {
    try {
      commit('setStockLoading', true)
      let margins = await getStockMargins({
        WineCardID,
        WineUsageCodeID
      })
      let marginCounter = 1
      margins = map(margins, m => {
        m.TempID = marginCounter
        marginCounter++
        return m
      })
      commit('setStockMargins', margins)
      return margins
    } catch (e) {
      throw e
    } finally {
      commit('setStockLoading', false)
    }
  },
  async getBordeauxIndexPrices({ commit }, args) {
    commit('clearBordeauxIndex')
    commit('setBordeauxIndexLoading', true)
    commit('setBordeauxIndexError', false)
    try {
      const result = await getBordeauxIndexPrices(args)
      if (result) {
        commit('setBordeauxIndex', result)
      }
    } catch (e) {
      commit('setBordeauxIndexError', true)
      throw e
    } finally {
      commit('setBordeauxIndexLoading', false)
    }
  },
  async getLiveExTransactions({ commit }, args) {
    commit('setLiveExTransactionsLoading', true)
    commit('setLiveExTransactionsError', false)
    try {
      const result = await getLiveExLatestTransactions(args)
      if (result) {
        commit('setLiveExTransactions', result)
      }
    } catch (e) {
      commit('setLiveExTransactionsError', true)
      throw e
    } finally {
      commit('setLiveExTransactionsLoading', false)
    }
  },
  async getBidOffer({ commit }, args) {
    commit('setLivExBidOfferLoading', true)
    commit('setLivExBidOfferError', false)
    try {
      commit('setLivExBidOffer', [])
      const result = await getLivExBidOffer(args)
      if (result) {
        commit('setLivExBidOffer', result)
      }
    } catch (e) {
      commit('setLivExBidOfferError', true)
      throw e
    } finally {
      commit('setLivExBidOfferLoading', false)
    }
  },
  async getWineSearcherPrices({ commit }, args) {
    commit('setWineSearcherLoading', true)
    commit('setWineSearcherError', false)
    try {
      const result = await getWineSearcherPrices(args)
      if (result) {
        commit('setWineSearcher', result)
      }
    } catch (e) {
      commit('setWineSearcherError', true)
      commit('setWineSearcherErrorMessage', e)
      throw e
    } finally {
      commit('setWineSearcherLoading', false)
    }
  }
}

import TextTemplate from '../models/texttemplate'
import SendEmail from '~/models/sendemail'
import { client } from './graphQLInstance'

export const getTextTemplate = async code => {
  const result = await client.query({
    query: TextTemplate.query('get', {
      RecordCode: code
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[TextTemplate.queries.get.name]
}

export const getTextTemplateAll = async () => {
  const result = await client.query({
    query: TextTemplate.query('all')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[TextTemplate.queries.all.name]
}

export const updateTextTemplate = async args => {
  const result = await client.query({
    query: TextTemplate.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[TextTemplate.mutations.update.name]
}

export const sendEmail = async args => {
  const result = await client.query({
    query: SendEmail.mutate('send', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SendEmail.mutations.send.name]
}

<template>
  <wine-list-export-modal
    v-model="showWineListExportModal"
    :loading="loading"
  />
</template>

<script>
import WineListExportModal from '~/components/Modal/WineListExportModal'

export default {
  components: {
    WineListExportModal
  },
  computed: {
    showWineListExportModal: {
      get() {
        return this.$store.state.modal.showWineListExportModal
      },
      set(value) {
        this.$store.commit('modal/setShowWineListExportModal', value)
      }
    },
    loading() {
      return (
        this.$store.state.export.wineList.loading ||
        this.$store.state.export.wineList.loadingAppends
      )
    }
  }
}
</script>

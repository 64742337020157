export const SHIPPING_STATUS = {
  SHIPPED: 1,
  NOTSHIPPED: 2,
  PARTSHIPPED: 3,
  CREDITED: 4
}

export const PAID_STATUS = {
  PAID: 1,
  NOTPAID: 2,
  TERMS: 3,
  PARTPAID: 4
}

import { client } from '../graphQLInstance'
import Address from '../../models/address'

export const getPrimaryAddressByContactId = async id => {
  const result = await client.query({
    query: Address.query('getPrimaryAddress', {
      ContactId: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Address.queries.getPrimaryAddress.name]
}

export const getAllForContact = async id => {
  const result = await client.query({
    query: Address.query('getAllForContact', {
      ContactId: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Address.queries.getAllForContact.name]
}

export const getAnyAddressById = async id => {
  const result = await client.query({
    query: Address.query('getAnyAddress', {
      RecordId: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Address.queries.getAnyAddress.name]
}

export const getAddressById = async id => {
  const result = await client.query({
    query: Address.query('get', {
      RecordId: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Address.queries.get.name]
}

export const updateAddress = async args => {
  const result = await client.query({
    query: Address.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Address.mutations.update.name]
}

export const addAddress = async args => {
  const result = await client.query({
    query: Address.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Address.mutations.insert.name]
}

export const deleteAddressById = async id => {
  const result = await client.query({
    query: Address.mutate('delete', {
      ID: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Address.mutations.delete.name]
}

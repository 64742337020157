import Entity from './entity'
import { fields as wineCardFields } from './winecard'
import { fields as wineCardSearchFields } from './winebrowsersearchresult'

export const fields = {
  WebAllocationID: {
    type: Number
  },
  WPOLID: {
    type: Number
  },
  POLID: {
    type: Number
  },
  AsDutyPaid: {
    type: Boolean
  },
  Bottles: {
    type: Number
  },
  FormatID: {
    type: Number
  },
  OrigPkgCaseSize: {
    type: Number
  },
  OriginalPackaging: {
    type: Boolean
  },
  Price: {
    type: Number
  },
  UseEmptyOWC: {
    type: Boolean
  },
  WineCardID: {
    type: Number
  },
  WineUsageCodeID: {
    type: Number
  },
  PhotoRequired: {
    type: Number
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedBy: {
    type: Number
  },
  AuditCreatedDate: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  WineCard: {
    type: Object,
    fields: Entity.allButKeys(wineCardFields, [
      'LinkedCardCount',
      'LinkedPurchaseOrderLinesUsage',
      'LinkedSalesOrderLinesUsage'
    ])
  },
  WineCardSearch: {
    type: Object,
    fields: wineCardSearchFields
  },
  Images: {
    type: Array
  }
}

const WebPurchaseOrderAllocation = new Entity(
  'WebPurchaseOrderAllocation',
  fields
)

WebPurchaseOrderAllocation.createMutation(
  'add',
  'webPurchaseOrderAllocationAdd',
  Entity.onlyKeys(fields, [
    'WPOLID',
    'AsDutyPaid',
    'Bottles',
    'FormatID',
    'OrigPkgCaseSize',
    'OriginalPackaging',
    'PhotoRequired',
    'Price',
    'UseEmptyOWC',
    'WineCardID',
    'WineUsageCodeID'
  ]),
  fields
)

WebPurchaseOrderAllocation.createMutation(
  'update',
  'webPurchaseOrderAllocationUpdate',
  Entity.onlyKeys(fields, [
    'WebAllocationID',
    'WPOLID',
    'AsDutyPaid',
    'Bottles',
    'FormatID',
    'OrigPkgCaseSize',
    'OriginalPackaging',
    'PhotoRequired',
    'Price',
    'UseEmptyOWC',
    'WineCardID',
    'WineUsageCodeID',
    'Images'
  ]),
  fields
)

WebPurchaseOrderAllocation.createMutation(
  'delete',
  'webPurchaseOrderAllocationDelete',
  Entity.onlyKeys(fields, ['WebAllocationID']),
  []
)

WebPurchaseOrderAllocation.createQuery(
  'get',
  'webPurchaseOrderAllocations',
  fields,
  {
    WPOID: {
      type: Number
    }
  }
)

export default WebPurchaseOrderAllocation

<template>
  <b-form>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Designation Name"
      label-for="Designation"
      :label-class="{green: isDirty('Designation') }"
    >
      <b-form-input
        id="Designation"
        v-model="Designation"
        :state="!$v.Designation.$error && null"
        trim
        size="sm"
        :disabled="viewMode"
        :class="{green: isDirty('Designation') }"
      />
      <b-form-invalid-feedback>
        Designation name is required
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapObjectToVModel(['Designation'])
  },
  validations: {
    Designation: {
      required
    }
  }
}
</script>

import Entity from './entity'

export const fields = {
  CountryRegionID: {
    type: Number
  },
  Region: {
    type: String
  },
  CountryID: {
    type: Number
  },
  UseOnBackOffice: {
    type: Boolean
  }
}

const CountryRegion = new Entity('CountryRegion', fields)
CountryRegion.createQuery('get', 'countryRegionsGet')

CountryRegion.createMutation(
  'delete',
  'countryRegionDelete',
  {
    CountryRegionID: fields.CountryRegionID
  },
  []
)
CountryRegion.createMutation('insert', 'countryRegionAdd', fields)
CountryRegion.createMutation('update', 'countryRegionUpdate', fields)

export default CountryRegion

<template>
  <b-modal
    v-model="shown"
    size="md"
    modal-class="wv-modal"
    content-class="shadow"
    footer-class="justify-content-center"
    centered
  >
    <template v-slot:modal-header>
      View Appellation
    </template>
    <appellation-form view-mode :value="appellation" />
    <template v-slot:modal-footer="{ ok }">
      <b-button class="btn wv-btn btn-sm" @click="ok()">
        OK
      </b-button>
      <b-button class="btn wv-btn btn-sm" @click="shown=false">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import AppellationForm from '~/components/Forms/AppellationForm'

export default {
  components: {
    AppellationForm
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    appellation: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

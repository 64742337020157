import { filter } from 'lodash'

export const parseTerm = payload => {
  const returnData = {
    vintages: [],
    term: null
  }
  const filteredWordArray = payload
    .replace(/[\s,;:.]/g, ' ')
    .trim()
    .split(' ')

  const words = filter(filteredWordArray, w => {
    const vintage = parseVintage(w)
    if (vintage !== null) {
      returnData.vintages = vintage.split
        ? vintage.split('-').map(v => parseInt(v))
        : [vintage]
      return false
    }
    return true
  })
  returnData.term = words.join(' ').trim()
  return returnData
}
export const parseVintage = payload => {
  const now = new Date()
  const testSingleDigit = /^([0-9])$/
  const testFullYear = /^([0-9]{2,4})$/
  const testFullYearRange = /^([0-9]{2,4})-([0-9]{1,4})$/
  const testFromNotToYear = /^([0-9]{2,4})-/
  const testFromYear = /^-([0-9]{1,4})$/
  const testAddYear = /^([0-9]{2,4})\+([0-9]{1,4})$/
  const testTimesYear = /^([0-9]{2,4})\*([0-9]{1,4})$/
  const testDivYear = /^([0-9]{2,4})\/([0-9]{1,4})$/
  if (payload.toUpperCase() === 'NV') {
    return payload.toUpperCase()
  } else if (testSingleDigit.test(payload)) {
    return stringDateToFullYear(payload)
  } else if (testFullYear.test(payload)) {
    return stringDateToFullYear(payload)
  } else if (testFullYearRange.test(payload)) {
    const found = payload.match(testFullYearRange)
    const firstDate = stringDateToFullYear(found[1])
    const lastDate = stringDateToFullYear(found[2], firstDate)
    if (firstDate > lastDate) {
      payload = lastDate + '-' + firstDate
    } else {
      payload = firstDate + '-' + lastDate
    }
    return payload
  } else if (testFromNotToYear.test(payload)) {
    const found = payload.match(testFromNotToYear)
    const firstDate = stringDateToFullYear(found[1])
    payload = firstDate + '-' + now.getFullYear()
    return payload
  } else if (testFromYear.test(payload)) {
    const found = payload.match(testFromYear)
    const firstDate = stringDateToFullYear(found[1])
    payload = '1900-' + firstDate
    return payload
  } else if (testAddYear.test(payload)) {
    const found = payload.match(testAddYear)
    const firstDate = stringDateToFullYear(found[1])
    const lastDate = firstDate + parseInt(found[2])
    if (firstDate > lastDate) {
      payload = lastDate + '-' + firstDate
    } else {
      payload = firstDate + '-' + lastDate
    }
    return payload
  } else if (testTimesYear.test(payload)) {
    const found = payload.match(testTimesYear)
    const firstDate = stringDateToFullYear(found[1])
    const lastDate = firstDate + (parseInt(found[2]) - 1)
    if (firstDate > lastDate) {
      payload = lastDate + '-' + firstDate
    } else {
      payload = firstDate + '-' + lastDate
    }
    return payload
  } else if (testDivYear.test(payload)) {
    const found = payload.match(testDivYear)
    let firstDate = stringDateToFullYear(found[1])
    const div = parseInt(found[2])
    const lastDate = firstDate + div
    firstDate -= div
    if (firstDate > lastDate) {
      payload = lastDate + '-' + firstDate
    } else {
      payload = firstDate + '-' + lastDate
    }
    return payload
  } else {
    return null
  }
}

export const stringDateToFullYear = (year, workFromYear) => {
  const now = new Date()
  const tripYear = now.getFullYear() - 2000
  let intYear = parseInt(year)
  if (workFromYear !== undefined && intYear < 1000) {
    const decade = Math.floor(workFromYear / 10) * 10
    const modm = intYear >= 100 ? 1000 : 100
    const mod = intYear < 10 ? 10 : modm
    const wy = workFromYear % mod
    const diff = intYear - wy
    if (diff <= 0) {
      return decade + 10 + intYear
    }
    return workFromYear + diff
  }
  if (intYear < 100) {
    intYear += intYear < tripYear ? 2000 : 1900
  } else if (intYear < 1000) {
    intYear += intYear < 900 ? 2000 : 1000
  }
  return intYear
}

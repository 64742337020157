<template>
  <b-form>
    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('AppellationName') }"
        >
          Appellation Name
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="appellationName"
          v-model="AppellationName"
          type="text"
          :state="!$v.AppellationName.$error && null"
          :class="{green: isDirty('AppellationName') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Appellation name is required
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('AppellationOrder') }"
        >
          Order
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="order"
          v-model="AppellationOrder"
          type="text"
          :state="!$v.AppellationOrder.$error && null"
          :class="{green: isDirty('AppellationOrder') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Appellation order is required and must be numeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('AppellationCat') }"
        >
          Category
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="category"
          v-model="AppellationCat"
          type="text"
          :state="!$v.AppellationCat.$error && null"
          :class="{green: isDirty('AppellationCat') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Appellation category is required and must be numeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('AppellationCode') }"
        >
          Code
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="code"
          v-model="AppellationCode"
          type="text"
          :state="!$v.AppellationCode.$error && null"
          :class="{green: isDirty('AppellationCode') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="4"
        />
        <b-form-invalid-feedback>
          Appellation code is required and must be alphanumeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('UseOnBackOffice') }"
        >
          Visible On Back Office
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-checkbox
          v-model="UseOnBackOffice"
          type="text"
          :state="!$v.UseOnBackOffice.$error && null"
          :class="{green: isDirty('UseOnBackOffice') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="4"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('UseOnWebsite') }"
        >
          Visible On Website
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-checkbox
          v-model="UseOnWebsite"
          type="text"
          :state="!$v.UseOnWebsite.$error && null"
          :class="{green: isDirty('UseOnWebsite') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="4"
        />
      </b-col>
    </b-row>

    <b-row v-if="viewMode" class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('WineNameUsage') }"
        >
          Wine Name Usage
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="winenameusage"
          v-model="WineNameUsage"
          type="text"
          :state="!$v.WineNameUsage.$error && null"
          :class="{green: isDirty('WineNameUsage') }"
          trim
          size="sm"
          disabled
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  required,
  integer,
  alphaNum,
  maxLength
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapObjectToVModel([
      'AppellationName',
      'AppellationOrder',
      'AppellationCat',
      'AppellationCode',
      'WineNameUsage',
      'UseOnWebsite',
      'UseOnBackOffice'
    ])
  },
  validations: {
    AppellationName: {
      required
    },
    AppellationOrder: {
      integer,
      required,
      default: 0
    },
    AppellationCat: {
      integer,
      required,
      default: 0
    },
    AppellationCode: {
      alphaNum,
      maxLength: maxLength(4)
    },
    WineNameUsage: {
      integer
    },
    UseOnWebsite: {},
    UseOnBackOffice: {}
  }
}
</script>

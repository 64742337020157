import Entity from './entity'

const fields = {
  ContactID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  DoNotPromote: {
    type: Boolean
  },
  ReviewedBy: {
    type: Number
  },
  ReviewedDate: {
    type: String
  },
  EmailSent: {
    type: Boolean
  },
  WinePromotions: {
    type: Array,
    fields: {
      WineCardID: {
        type: Number
      },
      PromotionOption: {
        type: Number
      }
    }
  }
}

const ContactWinePromotion = new Entity('ContactWinePromotion', fields)

ContactWinePromotion.createMutation(
  'batchUpdate',
  'contactUpdatePromotions',
  {
    ContactID: {
      type: Number
    },
    DoNotReview: {
      type: Boolean
    },
    WinePromotions: {
      type: Array,
      fields: {
        WineCardID: {
          type: Number
        },
        PromotionOption: {
          type: Number
        }
      }
    }
  },
  []
)

export default ContactWinePromotion

import Entity from './entity'

export const fields = {
  DesignationID: {
    type: Number
  },
  Designation: {
    type: String
  }
}

const Designation = new Entity('Designation', fields)
Designation.createQuery('get', 'designationsGet')

Designation.createMutation(
  'delete',
  'designationDelete',
  {
    DesignationID: fields.DesignationID
  },
  []
)
Designation.createMutation('insert', 'designationAdd', fields)
Designation.createMutation('update', 'designationUpdate', fields)

export default Designation

/* eslint-disable no-unused-vars */
import {
  getNotesForCardId,
  getAllNotes,
  createNote,
  updateNote,
  deleteNoteById
} from '@/api/wineCard/notes'
import { filter, reduce, findIndex, map, each, merge } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  notes: [],
  indexedId: {},
  page: 1,
  selectedNoteId: null,
  perPage: 74,
  total: 0,
  loadedPage: [],
  pageOffsets: {},
  orderBy: null,
  orderByDirection: 'ASC',
  wineFilter: '',
  noteFilter: '',
  stockedWinesOnlyFilter: false
})

export const getters = {
  getNotesForCardId: state => id => {
    return filter(state.notes, { WineCardID: id })
  },
  getSelectedNote: state => {
    return state.notes[state.indexedId[state.selectedNoteId]]
  },
  getNoteById: state => id => {
    return state.notes[state.indexedId[id]]
  },
  getNoteStringForCardId: state => id => {
    return map(filter(state.notes, { WineCardID: id }), 'Note').join('\n\n')
  }
}

export const mutations = {
  setNotes(state, notes) {
    state.notes = notes
    state.indexedId = reduce(
      notes,
      (keyMap, n, i) => {
        keyMap[n.WineCardNoteID] = i
        return keyMap
      },
      {}
    )
  },
  resetPage(state) {
    state.notes = []
    state.indexedId = {}
    state.page = 1
    state.loadedPage = []
    state.pageOffsets = {}
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  appendNote(state, note) {
    Vue.set(state.indexedId, note.WineCardNoteID, state.notes.length)
    state.notes.push(note)
  },
  replaceNote(state, note) {
    const noteIdx = findIndex(state.notes, {
      WineCardNoteID: note.WineCardNoteID
    })
    if (noteIdx !== undefined && noteIdx >= 0) {
      Vue.set(state.notes, noteIdx, merge(state.notes[noteIdx], note))
    } else {
      this.commit('wineCard/notes/appendNote', note)
    }
  },
  setSelectedNoteId(state, id) {
    state.selectedNoteId = id
  },
  removeNote(state, note) {
    const noteIdx = findIndex(state.notes, {
      WineCardNoteID: note.WineCardNoteID
    })
    if (noteIdx !== undefined) {
      Vue.delete(state.notes, noteIdx)
      state.indexedId = reduce(
        state.notes,
        (keyMap, n, i) => {
          keyMap[n.WineCardNoteID] = i
          return keyMap
        },
        {}
      )
    }
  },
  resetSelection(state) {
    state.notes = []
    state.indexedId = []
    state.stockedWinesOnlyFilter = false
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setWineFilter(state, wineFilter) {
    state.wineFilter = wineFilter
  },
  setNoteFilter(state, noteFilter) {
    state.noteFilter = noteFilter
  },
  setStockedWinesOnly(state, stockedWinesOnly) {
    state.stockedWinesOnlyFilter = stockedWinesOnly === true
  },
  resetFilters(state) {
    state.wineFilter = ''
    state.noteFilter = ''
    state.stockedWinesOnlyFilter = false
  },
  setPageOffset(state, page) {
    Vue.set(state.pageOffsets, page, state.notes.length)
  },
  setLoadedPage(state, page) {
    if (state.loadedPage.indexOf(page) < 0) {
      state.loadedPage.push(page)
    }
  },
  setOrderBy(state, key) {
    state.orderBy = key
  },
  setOrderByDirection(state, direction) {
    state.orderByDirection = direction === 'ASC' ? 'ASC' : 'DESC'
  },
  appendNotes(state, results) {
    each(results, result => this.commit('wineCard/notes/replaceNote', result))
  }
}

export const actions = {
  getNotesForCardId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        commit('setLoading', true)
        const response = await getNotesForCardId(payload)
        commit('setNotes', response.notes)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit('setLoading', false)
      }
    })
  },
  getAllNotes({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      try {
        commit('setLoading', true)
        commit('resetPage', true)
        const query = {
          SearchNotes: state.noteFilter,
          SearchName: state.wineFilter,
          OnlyAvailableStock: state.stockedWinesOnlyFilter,
          Page: state.page,
          PerPage: state.perPage,
          OrderBy: state.orderBy,
          OrderByDirection: state.orderByDirection
        }
        const response = await getAllNotes(query)
        commit('setNotes', response.notes.wineCardNotesResults)
        commit('setPageOffset', response.notes.current_page)
        commit('setTotal', response.notes.total)
        commit('setLoadedPage', response.notes.current_page)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit('setLoading', false)
      }
    })
  },
  createNote({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        commit('setLoading', true)
        const result = await createNote(payload)
        commit('appendNote', result.note)
        resolve(result)
      } catch (e) {
        reject(e)
      } finally {
        commit('setLoading', false)
      }
    })
  },
  updateNote({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        commit('setLoading', true)
        const result = await updateNote(payload)
        commit('replaceNote', result.note)
        resolve(result)
      } catch (e) {
        reject(e)
      } finally {
        commit('setLoading', false)
      }
    })
  },
  removeNote({ commit, getters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        commit('setLoading', true)
        const result = await deleteNoteById(payload.WineCardNoteID)
        commit('removeNote', payload)
        resolve(result)
      } catch (e) {
        reject(e)
      } finally {
        commit('setLoading', false)
      }
    })
  },
  changePage({ commit, state }, page) {
    return new Promise(async (resolve, reject) => {
      try {
        if (state.loadedPage.indexOf(page) >= 0) {
          return
        }
        commit('setPage', page)
        commit('setLoading', true)
        const query = {
          SearchNotes: state.noteFilter,
          SearchName: state.wineFilter,
          OnlyAvailableStock: state.stockedWinesOnlyFilter,
          Page: page,
          PerPage: state.perPage,
          OrderBy: state.orderBy,
          OrderByDirection: state.orderByDirection
        }
        const response = await getAllNotes(query)
        commit('appendNotes', response.notes.wineCardNotesResults)
        commit('setPageOffset', response.notes.current_page)
        commit('setTotal', response.notes.total)
        commit('setLoadedPage', response.notes.current_page)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit('setLoading', false)
      }
    })
  }
}

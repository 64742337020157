import { client } from '../graphQLInstance'
import ShipperAddress from '../../models/shipperaddress'

export const getShipperAddresses = async term => {
  const result = await client.query({
    query: ShipperAddress.query('search', {
      SearchTerm: term
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[ShipperAddress.queries.search.name]
}

export const addShipperAddress = async args => {
  const result = await client.query({
    query: ShipperAddress.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[ShipperAddress.mutations.insert.name]
}

export const getShipperAddress = async args => {
  const result = await client.query({
    query: ShipperAddress.query('get', {
      RecordId: args.ShipperAddrID
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[ShipperAddress.queries.get.name]
}

export const updateShipperAddress = async args => {
  const result = await client.query({
    query: ShipperAddress.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[ShipperAddress.mutations.update.name]
}

export const deleteShipperAddress = async args => {
  const result = await client.query({
    query: ShipperAddress.mutate('delete', {
      ID: args.ShipperAddrID,
      AuditAmendedDate: args.AuditAmendedDate
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[ShipperAddress.mutations.delete.name]
}

import Entity from './entity'
import { fields as wineBrowserSearchFields } from './winebrowsersearchresult'

export const fields = {
  WPOLID: {
    type: Number
  },
  WPOID: {
    type: Number
  },
  TransactionOrder: {
    type: Number
  },
  LivExLWinCode: {
    type: Number
  },
  VintageYear: {
    type: String
  },
  WineName: {
    type: String
  },
  FormatID: {
    type: Number
  },
  Bottles: {
    type: Number
  },
  Cases: {
    type: Number
  },
  CaseSize: {
    type: Number
  },
  FormatCaseSize: {
    type: Number
  },
  TotalBottles: {
    type: Number
  },
  DutyStatusID: {
    type: Number
  },
  OriginalPackaging: {
    type: Boolean
  },
  TakenBottles: {
    type: Number
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedBy: {
    type: Number
  },
  AuditCreatedDate: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  WineCardSearch: {
    type: Object,
    fields: wineBrowserSearchFields
  },
  PackSizeIdent: {
    type: String
  },
  ImportedPrice: {
    type: Number
  },
  PhotoRequired: {
    type: Boolean
  },
  Images: {
    type: Array,
    fields: {
      WPOLIID: {
        type: Number
      },
      UUID: {
        type: String
      },
      ImageName: {
        type: String
      }
    }
  }
}

const WebPurchaseOrderLine = new Entity('WebPurchaseOrderLine', fields)

WebPurchaseOrderLine.createQuery(
  'get',
  'webPurchaseOrderLineItems',
  fields,
  Entity.onlyKeys(fields, ['WPOID'])
)

WebPurchaseOrderLine.createMutation(
  'delete',
  'webPurchaseOrderLineDelete',
  fields,
  Entity.onlyKeys(fields, ['WPOLID'])
)

export default WebPurchaseOrderLine

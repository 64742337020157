import { client } from '../graphQLInstance'
import Contact from '~/models/contact'

export const searchContact = async searchParameters => {
  const result = await client.query({
    query: Contact.query('search', {
      ...searchParameters
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }

  return result.data[Contact.queries.search.name]
}

import { each, has } from 'lodash'

export const objectVModel = (key, field, customGetter) => {
  if (typeof field === 'function') {
    customGetter = field
    field = 'value'
  }
  return {
    get() {
      if (
        has(this.changedFormData, key) &&
        this.changedFormData[key] !== undefined
      ) {
        return this.changedFormData[key]
      }
      let defaultValue
      if (has(this[field], key)) {
        defaultValue = this[field][key]
      }
      if (typeof customGetter === 'function') {
        defaultValue = customGetter.apply(this, [defaultValue])
      }
      return defaultValue
    },
    set(value) {
      let triggerChange = false
      if (!this.changedFormData || this.changedFormData[key] === undefined) {
        if (this.value !== null) {
          if (this.value[key] === undefined) {
            triggerChange = true
          } else if (this.value[key] !== value) {
            triggerChange = true
          }
        }
      } else {
        triggerChange = true
      }
      if (triggerChange) {
        this.$set(this.changedFormData || {}, key, value)
        this.$emit('change-' + key, value)
        this.$emit('change', this.changedFormData)
        this.$emit('input', this.changedFormData)
        if ('$v' in this) {
          if (this.$v[key]) {
            this.$v[key].$touch()
          }
        }
      }
    }
  }
}

export const mapObjectToVModel = (keys, field) => {
  const mappedObject = {}
  if (!field) {
    field = 'value'
  }
  each(keys, key => {
    mappedObject[key] = objectVModel(key, field)
  })
  return mappedObject
}

export const splitWithMultiple = (str, delims) => {
  let delimStr = ''
  for (const delimeter of delims) {
    delimStr = delimStr + delimeter
  }
  const regex = new RegExp(`[${delimStr}]+`)
  return str.split(regex)
}

export const isUndefOrEmpty = value => {
  if (value === undefined) {
    return true
  }
  if (value === null) {
    return true
  }
  if (value.length === 0) {
    return true
  }
  return false
}

import { getCountrySubRegions } from '@/api/lists'

export const state = () => ({
  loading: false,
  list: [],
  selected: [],
  lastRequest: null
})

export const getters = {
  searchParameters: state => {
    return {
      // appellations: state.selected.length > 0 ? state.selected.join(',') : null
    }
  }
}

export const mutations = {
  setSelected(state, selected) {
    state.selected = selected
  },
  setList(state, list) {
    list = list.sort((a, b) => a.SubRegion.localeCompare(b.SubRegion))
    state.list = list
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  resetSelection(state) {
    state.selected = []
  },
  setLastRequest(state, request) {
    state.lastRequest = request
  }
}

export const actions = {
  async refreshList({ state, commit }, payload) {
    commit('setLoading', true)
    if (payload) {
      const lastRequest = JSON.stringify(payload)
      commit('setLastRequest', lastRequest)
      const response = await getCountrySubRegions(payload)
      if (state.lastRequest === lastRequest) {
        commit('setList', response)
        commit('setLoading', false)
      }
    } else {
      commit('setList', [])
      commit('setLoading', false)
    }
  }
}

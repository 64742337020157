import Entity from './entity'

export const fields = {
  InventoryID: {
    type: Number
  },
  TotalBottles: {
    type: Number
  },
  RotationCardID: {
    type: Number
  },
  RotationNumber: {
    type: String
  },
  OriginalPackaging: {
    type: String
  },
  UsageCode: {
    type: String
  },
  LCBLocation: {
    type: String
  },
  DutyStatusID: {
    type: Number
  },
  DutyStatusName: {
    type: String
  },
  Notes: {
    type: String
  },
  InventoryStatusID: {
    type: Number
  },
  CaseSize: {
    type: Number
  },
  AlertOWC: {
    type: Boolean
  },
  WineUsageCodeID: {
    type: Number
  },
  POLID: {
    type: Number
  },
  POID: {
    type: Number
  }
}

const PurchaseOrderAllocation = new Entity('Inventory', fields)
PurchaseOrderAllocation.createQuery(
  'getAll',
  'purchaseOrderLineAllocationGet',
  fields,
  {
    RecordId: {
      type: Number
    }
  }
)

export default PurchaseOrderAllocation

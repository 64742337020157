import {
  getSalesOrderByCustomer,
  getPurchaseOrderByCustomer
} from '@/api/deliveries/deliveries'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  loadingPOPage: false,
  loadingPage: false,
  list: [],
  poList: [],
  poTotal: 0,
  perPage: 50,
  poCurrentPage: 0,
  total: 0,
  currentPage: 0
})

export const getters = {
  getIncluded(state) {
    return state.list.filter(p => p.Include !== undefined && p.Include === true)
  },
  getPOIncluded(state) {
    return state.poList.filter(
      p => p.Include !== undefined && p.Include === true
    )
  }
}

export const mutations = {
  reset(state) {
    state.list = []
    state.poList = []
    state.poTotal = 0
    state.poCurrentPage = 0
    state.total = 0
    state.currentPage = 0
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoadingPage(state, loading) {
    state.loadingPage = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  setPOTotal(state, total) {
    state.poTotal = total
  },
  setTotal(state, total) {
    state.total = total
  },
  setPOCurrentPage(state, page) {
    state.poCurrentPage = page
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setPOList(state, list) {
    state.poList = list
  },
  appendPOList(state, list) {
    state.poList = state.poList.concat(list)
  },
  appendList(state, list) {
    state.list = state.list.concat(list)
  },
  updateIncludeState(state, { SOID, value }) {
    const idx = state.list.findIndex(p => p.SOID === SOID)
    if (idx > -1) {
      Vue.set(state.list[idx], 'Include', value)
    }
  },
  updateIncludePOState(state, { POID, value }) {
    const idx = state.poList.findIndex(p => p.POID === POID)
    if (idx > -1) {
      Vue.set(state.poList[idx], 'Include', value)
    }
  }
}

export const actions = {
  async loadTransactions({ commit }, { ContactID, OrderNumberList }) {
    commit('setLoading', true)
    commit('reset')
    try {
      const responses = await Promise.all([
        getSalesOrderByCustomer({ ContactID, OrderNumberList }),
        getPurchaseOrderByCustomer({ ContactID, OrderNumberList })
      ])
      if (responses) {
        commit('setList', responses[0].salesOrderByCustomerResult)
        commit('setPOList', responses[1].purchaseOrderByCustomerResult)
        commit('setPOTotal', responses[1].total)
        commit('setPOCurrentPage', responses[1].current_page)
        commit('setTotal', responses[0].total)
        commit('setCurrentPage', responses[0].current_page)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadPurchaseOrderPage(
    { commit },
    { ContactID, OrderNumberList, Page }
  ) {
    try {
      commit('setLoadingPOPage', true)
      const response = await getPurchaseOrderByCustomer({
        ContactID,
        OrderNumberList,
        Page
      })
      commit('appendPOList', response.purchaseOrderByCustomerResult)
      commit('setPOCurrentPage', response.current_page)
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPOPage', false)
    }
  },
  async loadSalesOrderPage({ commit }, { ContactID, OrderNumberList, Page }) {
    try {
      commit('setLoadingPage', true)
      const response = await getSalesOrderByCustomer({
        ContactID,
        OrderNumberList,
        Page
      })
      commit('appendList', response.salesOrderByCustomerResult)
      commit('setCurrentPage', response.current_page)
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPage', false)
    }
  }
}

import gql from 'graphql-tag'
import { client } from './graphQLInstance'

export const getSystemSettings = async () => {
  const result = await client.query({
    query: gql`
      query RootQueryType {
        systemSettingsGet {
          SystemSettingsID
          CSVDelimiterASCII
          DateFormat
          QuickSearchTopNRecords
          ArchiveDaysThreshold
          WebBrowsingTimeoutSeconds
        }
      }
    `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.systemSettingsGet
}

import Entity from './entity'

const fields = {
  WebhookID: {
    type: Number
  },
  OAuthClientID: {
    type: Number
  },
  Url: {
    type: String
  },
  OAuthClientName: {
    type: String
  },
  Events: {
    type: Array
  }
}

const Webhooks = new Entity('Webhooks', fields)
Webhooks.createQuery('getAll', 'webhooksGet', fields)

Webhooks.createMutation(
  'delete',
  'webhookDelete',
  {
    WebhookID: {
      type: Number
    }
  },
  {}
)

Webhooks.createMutation(
  'add',
  'webhookAdd',
  Entity.onlyKeys(fields, ['OAuthClientID', 'Url', 'Events']),
  fields
)

Webhooks.createMutation(
  'update',
  'webhookUpdate',
  Entity.onlyKeys(fields, ['OAuthClientID', 'Url', 'WebhookID', 'Events']),
  fields
)

export default Webhooks

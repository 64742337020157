import { without, findIndex } from 'lodash'
import Vue from 'vue'
import { unallocatedSupplierPurchaseOrderLines } from '../../api/etally'

export const state = () => ({
  loading: false,
  results: [],
  selected: [],
  selectedPOID: null
})

export const getters = {
  allSelected: state => {
    return (
      state.results.length === state.selected.length &&
      state.selected.length > 0
    )
  },
  createCardData: state => {
    return state.results
      .filter(r => state.selectedPOID === null || r.POID === state.selectedPOID)
      .filter(r => state.selected.indexOf(r.POLID) >= 0)
      .map(r => {
        return {
          POLID: r.POLID,
          PhotoRequired: r.PhotoRequired
        }
      })
  },
  selectedLines: state => {
    return state.results
      .filter(r => state.selectedPOID === null || r.POID === state.selectedPOID)
      .filter(r => state.selected.indexOf(r.POLID) >= 0)
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  resetResults(state) {
    state.results = []
    state.selected = []
  },
  setResults(state, results) {
    state.results = results
  },
  setSelectedPOID(state, poid) {
    state.selectedPOID = poid
  },
  selectAll(state) {
    state.selected = state.results.map(r => r.POLID)
  },
  selectOnlyDutyStatus(state, dutyStatusID) {
    state.selected = state.results
      .filter(r => r.DutyStatusID === dutyStatusID)
      .map(r => r.POLID)
  },
  selectOnlyReserves(state, isReserves) {
    state.selected = state.results
      .filter(r => r.IsReserves === isReserves)
      .map(r => r.POLID)
  },
  selectExpected(state) {
    state.selected = state.results
      .filter(r => r.ExpectedBottles > 0)
      .map(r => r.POLID)
  },
  resetSelected(state) {
    state.selected = []
  },
  toggleSelected(state, POLID) {
    if (state.selected.indexOf(POLID) >= 0) {
      state.selected = without(state.selected, POLID)
    } else {
      state.selected.push(POLID)
    }
  },
  togglePhotoState(state, POLID) {
    const itemIdx = findIndex(state.results, { POLID })
    if (itemIdx !== undefined) {
      const item = state.results[itemIdx]
      item.PhotoRequired = !item.PhotoRequired
      Vue.set(state.results, itemIdx, item)
    }
  }
}

export const actions = {
  async search({ commit }, search) {
    commit('resetResults')
    commit('setLoading', true)
    try {
      const result = await unallocatedSupplierPurchaseOrderLines(search)
      if (result) {
        commit('setResults', result)
        commit('selectExpected')
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

import {
  getAppellation,
  getAppellations,
  addAppellation,
  updateAppellation,
  deleteAppellation
} from '@/api/administration/appellations'
import { merge, find, findIndex } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  appellationLoading: false,
  list: [],
  appellation: null,
  searchTerm: '',
  activeId: 0
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setAppellationLoading(state, loading) {
    state.appellationLoading = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  setAppellation(state, appellation) {
    state.appellation = appellation
  },
  addToList(state, add) {
    state.list.push(add)
  },
  setSearchTerm(state, searchTerm) {
    state.searchTerm = searchTerm
  },
  resetList(state) {
    state.list = []
  },
  removeAppellationById(state, { AppellationID }) {
    const idx = findIndex(state.list, { AppellationID })
    if (idx >= 0) {
      if (state.appellation === state.list[idx]) {
        state.appellation = null
      }
      Vue.delete(state.list, idx)
    }
  },
  mergeAppellation(state, appellation) {
    const idx = findIndex(state.list, {
      AppellationID: appellation.AppellationID
    })
    if (idx >= 0) {
      const mergedAppellation = merge({}, state.list[idx], appellation)
      Vue.set(state.list, idx, mergedAppellation)
    }
  },
  setActiveAppellation(state, { AppellationID }) {
    state.appellation = find(state.list, { AppellationID })
  }
}

export const getters = {
  getAppellationsList(state) {
    return state.list
  },
  getActiveAppellation(state) {
    return state.appellation
  },
  getSearchTerm(state) {
    return state.searchTerm
  }
}

export const actions = {
  async loadList({ dispatch, commit }) {
    commit('setLoading', true)
    await dispatch('fetchList')
    commit('setLoading', false)
  },
  async fetchList({ commit, state }) {
    commit('resetList')
    const response = await getAppellations(state.searchTerm)
    commit('setList', response)
  },
  async loadAppellation({ commit }, appellation) {
    commit('setAppellationLoading', true)
    const response = await getAppellation(appellation)
    commit('setAppellation', response)
    commit('setAppellationLoading', false)
  },
  async addAppellation({ dispatch, commit, state }, appellation) {
    commit('setAppellationLoading', true)
    try {
      const newAppellation = await addAppellation(appellation)
      commit('addToList', newAppellation)
      return newAppellation
    } catch (e) {
      throw e
    } finally {
      commit('setAppellationLoading', false)
    }
  },
  async updateAppellation({ commit, state }, appellation) {
    try {
      commit('setAppellationLoading', true)
      const appellationCopy = merge({}, state.appellation, appellation)
      const newAppellation = await updateAppellation(appellationCopy)
      commit('mergeAppellation', newAppellation)
      return newAppellation
    } catch (e) {
      throw e
    } finally {
      commit('setAppellationLoading', false)
    }
  },
  async deleteAppellation({ commit }, appellation) {
    try {
      await deleteAppellation(appellation)
      commit('removeAppellationById', appellation)
    } catch (e) {
      throw e
    }
  },
  setActiveAppellation({ commit, rootState }, appellation) {
    commit('setActiveAppellation', appellation)
  },
  resetActiveAppellation({ commit }) {
    commit('setAppellation', {})
  }
}

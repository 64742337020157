import Entity from './entity'

const fields = {
  ContactID: {
    type: Number
  },
  AList: {
    type: Boolean
  },
  CompanyName: {
    type: String
  },
  ContactName: {
    type: String
  },
  Salutation: {
    type: String
  },
  SageCustomerAcctNo: {
    type: String
  },
  EmailAddress1: {
    type: String
  },
  EmailAddress2: {
    type: String
  },
  EmailAddress3: {
    type: String
  },
  DisplayEmailAddress: {
    type: String
  },
  NoSplitCases: {
    type: Boolean
  },
  MinPriceToOverrideNoSplit: {
    type: Number
  },
  LastReviewDate: {
    type: String
  },
  LastAutoReviewDate: {
    type: String
  },
  NextAutoReviewDate: {
    type: String
  },
  RepeatWineCount: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  ShowEuroPrices: {
    type: Boolean
  },
  ShowHongKongPrices: {
    type: Boolean
  },
  ShowUSDPrices: {
    type: Boolean
  },
  ShowSGDPrices: {
    type: Boolean
  },
  ShowCHFPrices: {
    type: Boolean
  },
  ShowWineScore: {
    type: Boolean
  }
}

const CustomersToReview = new Entity('CustomersToReview', fields)

CustomersToReview.createQuery('get', 'customersToReviewGet', fields, {
  ContactSearchTerm: {
    type: String
  },
  MinDaysBetweenCustomerPromotions: {
    type: Number
  },
  MinDaysFromAutoCustomerPromotions: {
    type: Number
  },
  MinDaysBetweenWinePromotions: {
    type: Number
  },
  ExistingSageAccount: {
    type: Boolean
  },
  ShowAListOnly: {
    type: Boolean
  },
  Fast: {
    type: Boolean
  },
  HighPriceCheck: {
    type: Number
  }
})

export default CustomersToReview

import Entity from './entity'

const fields = {
  PriceListStatusID: {
    type: Number
  },
  LastStartTime: {
    type: String
  },
  LastEndTime: {
    type: String
  },
  IsRunning: {
    type: Boolean
  },
  UserID: {
    type: Number
  },
  UserName: {
    type: String
  },
  ExceptionMessage: {
    type: String
  }
}

const PriceListStatus = new Entity('PriceListStatus', fields)
PriceListStatus.createQuery('get', 'priceListStatusGet', fields)

export default PriceListStatus

<template>
  <b-form>
    <b-form-group 
      label="Note"
      label-size="sm"
      size="sm"
    >
      <b-form-textarea
        v-model="note"
        rows="3"
        max-rows="6"
        size="sm"
        :state="!$v.note.$error && null"
        trim
      />
      <b-form-invalid-feedback>
        A note is required
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { formObjectMixin } from '../../mixins/forms'
import { mapObjectToVModel } from '../../helpers/helpers'

export default {
  mixins: [validationMixin, formObjectMixin],
  computed: {
    ...mapObjectToVModel(['note'])
  },
  validations: {
    note: {
      required
    }
  }
}
</script>

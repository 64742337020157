import Entity from './entity'

const fields = {
  ID: {
    type: Number
  },
  Addr1: {
    type: String
  },
  Addr2: {
    type: String
  },
  Addr3: {
    type: String
  },
  Addr4: {
    type: String
  },
  Addr5: {
    type: String
  },
  Phone: {
    type: String
  },
  Fax: {
    type: String
  },
  Email: {
    type: String
  },
  Web: {
    type: String
  },
  VATxxx: {
    type: Number
  },
  CompanyNo: {
    type: String
  },
  VatNo: {
    type: String
  },
  ExchUS: {
    type: Number
  },
  ExchHK: {
    type: Number
  },
  ExchEU: {
    type: Number
  },
  ExchJP: {
    type: Number
  },
  ExchCH: {
    type: Number
  },
  ExchSG: {
    type: Number
  },
  PreviousTempRotationNo: {
    type: Number
  },
  DateLastRefreshedFromLive: {
    type: String
  },
  InitialYearProfit: {
    type: Number
  },
  InitialYearSales: {
    type: Number
  },
  InitialYearPurchase: {
    type: Number
  },
  TimeOutWarningMinutes: {
    type: Number
  },
  TimeOutExitMinutes: {
    type: Number
  },
  WineImagePath: {
    type: String
  },
  CrystalReportsPath: {
    type: String
  },
  BankNameAndAddress: {
    type: String
  },
  BankSortCode: {
    type: String
  },
  BankAccountName: {
    type: String
  },
  BankAccountNo: {
    type: String
  },
  BankSwiftCode: {
    type: String
  },
  BankIBANNo: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  AuditAmendedBy: {
    type: Number
  },
  MarkUpPercent: {
    type: Number
  },
  LCBChargesValidFrom: {
    type: String
  },
  LCBChargesValidTo: {
    type: String
  },
  AnnualDepreciationRate: {
    type: Number
  },
  AnnualBottleStorageCost: {
    type: Number
  },
  LowLivExBidPercent: {
    type: Number
  },
  HighLivExBidPercent: {
    type: Number
  },
  LowLivExOfferPercent: {
    type: Number
  },
  HighLivExOfferPercent: {
    type: Number
  },
  LivExAlertEmailAddresses: {
    type: String
  },
  LivExTuneEmailAddresses: {
    type: String
  },
  UseCostPriceBaseForLivExBids: {
    type: Boolean
  },
  StockLossType: {
    type: Number
  },
  ReviewMailshotMode: {
    type: Number
  },
  ReviewCopyEmailAddresses: {
    type: String
  },
  PromotionMailshotMode: {
    type: Number
  },
  PromotionCopyEmailAddresses: {
    type: String
  },
  MinParkerScoreForDisplay: {
    type: Number
  },
  AWRSNumber: {
    type: String
  }
}

const GlobalVars = new Entity('GlobalVars', fields)

GlobalVars.createQuery('get', 'globalVars', fields)
GlobalVars.createMutation(
  'update',
  'globalVarsUpdate',
  Entity.allButKeys(fields, [
    'WineImagePath',
    'CrystalReportsPath',
    'DateLastRefreshedFromLive',
    'InitialYearProfit',
    'InitialYearSales',
    'InitialYearPurchase'
  ]),
  fields
)

export default GlobalVars

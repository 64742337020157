import AvailableStock from '~/models/availablestock'
import { client } from '../graphQLInstance'

export const getAvailableStock = async params => {
  const result = await client.query({
    query: AvailableStock.query('get', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[AvailableStock.queries.get.name]
}

<template>
  <b-form>
    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('ProducerName') }"
        >
          Producer Name
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="producerName"
          v-model="ProducerName"
          type="text"
          :state="!$v.ProducerName.$error && null"
          :class="{green: isDirty('ProducerName') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Producer name is required
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('ProducerOrder') }"
        >
          Order
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="producerOrder"
          v-model="ProducerOrder"
          type="text"
          :state="!$v.ProducerOrder.$error && null"
          :class="{green: isDirty('ProducerOrder') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Producer order is required and must be numeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('ProducerCat') }"
        >
          Category
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="producerCategory"
          v-model="ProducerCat"
          type="text"
          :state="!$v.ProducerCat.$error && null"
          :class="{green: isDirty('ProducerCat') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Producer category is required and must be numeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('ProducerCode') }"
        >
          Code
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="producerCode"
          v-model="ProducerCode"
          type="text"
          :state="!$v.ProducerCode.$error && null"
          :class="{green: isDirty('ProducerCode') }"
          trim
          size="sm"
          :disabled="viewMode"
        />
        <b-form-invalid-feedback>
          Producer code is required and must be alphanumeric
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row v-if="viewMode" class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('WineCardUsage') }"
        >
          Wine Card Usage
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-input
          id="wineCardUsage"
          v-model="WineCardUsage"
          type="text"
          :state="!$v.WineCardUsage.$error && null"
          :class="{green: isDirty('WineCardUsage') }"
          trim
          size="sm"
          disabled
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('UseOnBackOffice') }"
        >
          Visible On Back Office
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-checkbox
          v-model="UseOnBackOffice"
          type="text"
          :state="!$v.UseOnBackOffice.$error && null"
          :class="{green: isDirty('UseOnBackOffice') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="4"
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, integer, alphaNum } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapObjectToVModel([
      'ProducerName',
      'ProducerOrder',
      'ProducerCat',
      'ProducerCode',
      'WineCardUsage',
      'UseOnBackOffice'
    ])
  },
  validations: {
    ProducerName: {
      required
    },
    ProducerOrder: {
      required,
      integer,
      default: 0
    },
    ProducerCat: {
      required,
      integer,
      default: 0
    },
    ProducerCode: {
      alphaNum
    },
    WineCardUsage: {
      integer
    },
    UseOnBackOffice: {}
  }
}
</script>

import {
  getSearchResults,
  getWarehouseSearchResults,
  clearAllPromotions,
  updateBatchPromotions
} from '@/api/wineBrowser/wines'
import { reduce, concat, merge, filter, has } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  searchState: 'reset',
  viewMode: 'normal',
  page: 1,
  perPage: 100,
  total: 0,
  loadedPage: [],
  pageOffsets: {},
  results: [],
  indexedId: {},
  selectedForPromotion: {},
  orderBy: null,
  orderByDirection: 'ASC',
  activeId: null,
  orderKeyMap: {
    year: 'WineVintage',
    rp: 'WineParkerScore',
    wine_name_producer: 'WineName',
    format: 'FormatName',
    code: 'UsageCode',
    bh: 'WineBurghoundScore',
    duty_paid_stock: 'DutyPaidBottles',
    in_bond_stock: 'InBondBottles',
    unallocated_sales: 'UnallocatedSalesBottles',
    oversold_quantity: 'OversoldBottles',
    available_quantity: 'AvailableBottles',
    reserves_quantity: 'ReservesBottles',
    broking_quantity: 'BrokingBottles',
    reserve_level: 'ReservedBottles',
    average_cost: 'AverageCostPrice',
    listPrice: 'WineSellingPrice'
  }
})

export const getters = {
  getById: state => id => {
    return state.results[state.indexedId[id]]
  },
  getActiveCard: state => {
    return state.results[state.indexedId[state.activeId]]
  },
  promotionSelected: state => id => {
    if (state.selectedForPromotion[id] !== undefined) {
      return state.selectedForPromotion[id]
    }
    return null
  },
  pageResults: state => {
    const offset = state.pageOffsets[state.page] || 0
    return state.results.slice(offset, offset + state.perPage)
  },
  allResults: state => {
    return state.results
  },
  getPage: state => page => {
    const offset = state.pageOffsets[page]
    if (offset === undefined) return null
    return state.results.slice(offset, offset + state.perPage)
  },
  hasSearched: state => {
    return state.searchState === 'searched'
  },
  sameWineCards: state => item => {
    return filter(state.results, result => {
      return (
        item.year === result.year &&
        item.ComputedWineName === result.ComputedWineName
      )
    })
  }
}

export const mutations = {
  setViewMode(state, viewMode) {
    state.viewMode = viewMode === 'promotions' ? 'promotions' : 'normal'
  },
  setSearchState(state, mode) {
    state.searchState = mode
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setActiveId(state, id) {
    state.activeId = id
  },
  setResults(state, results) {
    state.results = results
    state.indexedId = reduce(
      results,
      (keyMap, r, i) => {
        keyMap[r.id] = i
        return keyMap
      },
      {}
    )
  },
  appendResults(state, results) {
    state.results = concat(state.results, results)
    state.indexedId = reduce(
      state.results,
      (keyMap, r, i) => {
        keyMap[r.id] = i
        return keyMap
      },
      {}
    )
  },
  replaceResultsForPage(state, { page, results }) {
    if (has(state.pageOffsets, page)) {
      const offset = state.pageOffsets[page]
      for (let i = 0; i < results.length; i++) {
        Vue.set(state.results, offset + i, results[i])
      }
    } else {
      Vue.set(state.pageOffsets, page, state.results.length)
      state.results = concat(state.results, results)
      state.indexedId = reduce(
        state.results,
        (keyMap, r, i) => {
          keyMap[r.id] = i
          return keyMap
        },
        {}
      )
    }
  },
  setNoteForWineCard(state, { WineCardID, Count, NoteString }) {
    const wineCardIdx = state.indexedId[WineCardID]
    if (wineCardIdx !== undefined && state.results[wineCardIdx]) {
      const wineCard = state.results[wineCardIdx]
      wineCard.note_count = Count
      wineCard.wine_card_notes_string = NoteString
      Vue.set(state.results, wineCardIdx, wineCard)
    }
  },
  setEmptyCasesForId(state, { WineCardID, EmptyCaseInfo }) {
    const wineCardIdx = state.indexedId[WineCardID]
    if (wineCardIdx !== undefined && state.results[wineCardIdx]) {
      const wineCard = state.results[wineCardIdx]
      wineCard.empty_cases = EmptyCaseInfo
      Vue.set(state.results, wineCardIdx, wineCard)
    }
  },
  setReserveLevel(state, { id, bottles, cases }) {
    const wineCardIdx = state.indexedId[id]
    if (wineCardIdx !== undefined && state.results[wineCardIdx]) {
      const wineCard = state.results[wineCardIdx]
      wineCard.reserve_level = { bottles, cases }
      Vue.set(state.results, wineCardIdx, wineCard)
    }
  },
  setAuditAmendedDate(state, { id, auditAmendedDate }) {
    const wineCardIdx = state.indexedId[id]
    if (wineCardIdx !== undefined && state.results[wineCardIdx]) {
      const wineCard = state.results[wineCardIdx]
      wineCard.auditAmendedDate = auditAmendedDate
      Vue.set(state.results, wineCardIdx, wineCard)
    }
  },
  setSellAtLoss(state, { id, sellAtLoss }) {
    const wineCardIdx = state.indexedId[id]
    if (wineCardIdx !== undefined && state.results[wineCardIdx]) {
      const wineCard = state.results[wineCardIdx]
      wineCard.sellAtLoss = sellAtLoss
      Vue.set(state.results, wineCardIdx, wineCard)
    }
  },
  updateById(state, { id, toMergeObject }) {
    const wineCardIdx = state.indexedId[id]
    if (wineCardIdx !== undefined && state.results[wineCardIdx]) {
      const wineCard = merge(state.results[wineCardIdx], toMergeObject)
      Vue.set(state.results, wineCardIdx, wineCard)
    }
  },
  replaceResultById(state, { id, result }) {
    const wineCardIdx = state.indexedId[id]
    const current = state.results[wineCardIdx]
    if (wineCardIdx !== undefined && current) {
      const merged = merge(current, result)
      Vue.set(state.results, wineCardIdx, merged)
    } else {
      state.results.push(result)
    }
  },
  resetSelection(state) {
    state.results = []
    state.indexedId = {}
    state.page = 1
    state.selectedForPromotion = {}
    state.loadedPage = []
    state.pageOffsets = {}
    state.setActiveId = null
    state.searchState = 'reset'
  },
  setPromotionValue(state, { id, value }) {
    if (value === '__default__') {
      Vue.delete(state.selectedForPromotion, id)
    } else {
      Vue.set(state.selectedForPromotion, id, value)
    }
  },
  resetPromotions(state) {
    state.selectedForPromotion = []
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setPageOffset(state, page) {
    Vue.set(state.pageOffsets, page, state.results.length)
  },
  setLoadedPage(state, page) {
    if (state.loadedPage.indexOf(page) < 0) {
      state.loadedPage.push(page)
    }
  },
  setOrderBy(state, key) {
    state.orderBy = key
  },
  setOrderByDirection(state, direction) {
    state.orderByDirection = direction === 'ASC' ? 'ASC' : 'DESC'
  }
}

export const actions = {
  async performSearch({ commit, state }, { searchParameters, forWarehouse }) {
    try {
      commit('setLoading', true)
      commit('setSearchState', 'reset')
      searchParameters.orderBy = state.orderBy
      searchParameters.orderByDirection = state.orderByDirection
      const apiMethod = forWarehouse
        ? getWarehouseSearchResults
        : getSearchResults
      const response = await apiMethod(
        state.page,
        state.perPage,
        searchParameters
      )
      commit('setSearchState', 'searched')
      commit('setPageOffset', state.page)
      commit('setResults', response.wineBrowserResults)
      commit('setTotal', response.total)
      commit('setLoadedPage', state.page)
      commit('setLoading', false)
    } catch (e) {
      commit('setSearchState', 'reset')
      commit('setPageOffset', 1)
      commit('setResults', [])
      commit('setTotal', 0)
      commit('setLoadedPage', 1)
      commit('setLoading', false)
      throw e
    }
  },
  async changePage(
    { commit, state },
    { page, searchParameters, forWarehouse }
  ) {
    if (state.loadedPage.indexOf(page) >= 0) {
      commit('setPage', page)
      return
    }
    commit('setLoading', true)
    searchParameters.orderBy = state.orderBy
    searchParameters.orderByDirection = state.orderByDirection
    const apiMethod = forWarehouse
      ? getWarehouseSearchResults
      : getSearchResults
    const response = await apiMethod(page, state.perPage, searchParameters)
    commit('setPageOffset', page)
    commit('appendResults', response.wineBrowserResults)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  },
  changeOrder(
    { commit, state, dispatch },
    { key, searchParameters, forWarehouse }
  ) {
    const searchKey = state.orderKeyMap[key] || null

    if (searchKey) {
      const doDesc =
        state.orderBy === searchKey && state.orderByDirection === 'ASC'
      commit('resetSelection')
      commit('setOrderBy', searchKey)
      commit('setOrderByDirection', doDesc ? 'DESC' : 'ASC')
      dispatch('performSearch', { searchParameters, forWarehouse })
    } else {
      console.error('No Key Map For ' + key)
    }
  },
  updateByWineCard({ state, commit }, toMergeObject) {
    const wineCardIdx = state.indexedId[toMergeObject.id]
    if (wineCardIdx !== undefined) {
      const tmpCard = JSON.parse(JSON.stringify(state.results[wineCardIdx]))
      commit('updateById', {
        id: toMergeObject.id,
        toMergeObject
      })
      return tmpCard
    }
  },
  async clearAllPromotions() {
    try {
      return await clearAllPromotions()
    } catch (e) {
      throw e
    }
  },
  async applyPromotions({ commit, state }) {
    commit('setLoading', true)
    const WineCards = Object.keys(state.selectedForPromotion).map(k => {
      return {
        WineCardID: parseInt(k),
        PromotionDate: state.selectedForPromotion[k]
      }
    })
    const updatedSet = await updateBatchPromotions({ WineCards })
    updatedSet.forEach(result => {
      commit('replaceResultById', {
        id: result.id,
        result
      })
    })
    commit('setLoading', false)
    commit('resetPromotions')
  },
  async refreshResults({ commit, state }, searchParameters) {
    let currentPage = state.page
    commit('setLoading', true)
    searchParameters.orderBy = state.orderBy
    searchParameters.orderByDirection = state.orderByDirection
    const apiMethod = getSearchResults
    const response = await apiMethod(
      currentPage,
      state.perPage,
      searchParameters
    )
    commit('replaceResultsForPage', {
      page: currentPage,
      results: response.wineBrowserResults
    })
    currentPage--
    const otherPageRequests = []
    for (let i = currentPage; i > 0; i--) {
      otherPageRequests.push(
        (page => {
          return new Promise(async (resolve, reject) => {
            try {
              const response = await apiMethod(
                page,
                state.perPage,
                searchParameters
              )
              commit('replaceResultsForPage', {
                page: page,
                results: response.wineBrowserResults
              })
              resolve()
            } catch (e) {
              reject(e)
            }
          })
        })(i)
      )
    }
    await Promise.all(otherPageRequests)

    commit('setLoading', false)
  }
}

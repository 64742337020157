import CustomersToReview from '../../models/customerstoreview'
import CustomerWinesToReview from '../../models/customerwinestoreview'
import Contact from '../../models/contact'
import { client } from '../graphQLInstance'

export const getCustomersToReview = async args => {
  const result = await client.query({
    query: CustomersToReview.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CustomersToReview.queries.get.name]
}

export const getCustomerWinesToReview = async args => {
  const result = await client.query({
    query: CustomerWinesToReview.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[CustomerWinesToReview.queries.get.name]
}

export const skipReviewCycleForContactsBatch = async args => {
  const result = await client.query({
    query: Contact.mutate('skipReviewCycleBatch', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Contact.mutations.skipReviewCycleBatch.name]
}

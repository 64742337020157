import Entity from './entity'

const fields = {
  FormatID: {
    type: Number
  },
  FormatName: {
    type: String
  },
  FormatCode: {
    type: String
  },
  Volume: {
    type: Number
  },
  CaseSize: {
    type: Number
  }
}

const LargeFormatDropDown = new Entity('LargeFormatDropDown', fields)

LargeFormatDropDown.createQuery('get', 'largeFormatDropDown', fields)

export default LargeFormatDropDown

<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :hide-footer="true"
    :ok-title="saveButtonText"
    :ok-disabled="saving"
    :cancel-disabled="saving"
    :title="title"
    @hidden="$emit('hidden')"
    @ok="save"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <template v-slot:default="{ ok }">
      <div v-if="loading" class="alert alert-info">
        Loading Please Wait...
      </div>
      <div v-else>
        <wine-note-form ref="form" :disabled="saving" :value="{note: note.Note }" @change="handleFormChange" />
        <b-button-group class="w-100">
          <b-button :disabled="saving" class="btn wv-btn btn-secondary btn-sm mr-1" @click="ok()">
            {{ saveButtonText }}
          </b-button>
          <b-button :disabled="saving" class="btn wv-btn btn-secondary btn-sm ml-1" @click="shown=false">
            Cancel
          </b-button>
        </b-button-group>
        <div v-if="formErrorMessage" class="alert alert-danger">
          {{ formErrorMessage }}
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import WineNoteForm from '~/components/Forms/WineNoteForm'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'
import { formResultMixin } from '~/mixins/forms'

export default {
  components: {
    WineNoteForm
  },
  mixins: [formResultMixin, TitleFromWineCard],
  props: {
    item: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: false,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    },
    saving: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    note: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formErrorMessage: null,
      titleSuffix: ''
    }
  },
  computed: {
    saveButtonText() {
      return this.saving ? 'Saving Please Wait' : 'OK'
    },
    hasActiveCard() {
      return this.item
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    reset() {
      this.formErrorMessage = null
      this.$refs.form.reset()
    },
    async save(e) {
      e.preventDefault()
      try {
        await this.$refs.form.validate()
        try {
          if (this.form.note) {
            const editedNote = await this.$store.dispatch(
              'wineCard/notes/updateNote',
              {
                Note: this.form.note,
                WineCardNoteID: this.note.WineCardNoteID,
                AuditAmendedDate: this.note.AuditAmendedDate,
                UpdateUser: this.note.UpdateUser
              }
            )
            this.$eventHub.$emit('edit-note', editedNote.note)
          }
          this.shown = false
        } catch (e) {
          this.formErrorMessage = e
        }
      } catch (e) {
        this.formErrorMessage = e
      }
    }
  }
}
</script>

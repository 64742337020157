import Entity from './entity'
// import { auditFields } from './helpers'
import { fields as wineBrowserFields } from './winebrowsersearchresult'

export const fields = {
  WineCardID: {
    type: Number
  },
  WineVintage: {
    type: Number
  },
  WineName: {
    type: String
  },
  ProducerName: {
    type: String
  },
  WineSellingPrice: {
    type: Number
  },
  PromotionDate: {
    type: String
  },
  OverrideLossCheck: {
    type: Boolean
  },
  CategoryID: {
    type: Number
  },
  ProducerID: {
    type: Number
  },
  CountryID: {
    type: Number
  },
  WineColourID: {
    type: Number
  },
  CountryRegionID: {
    type: Number
  },
  CountrySubRegionID: {
    type: Number
  },
  VineyardID: {
    type: Number
  },
  Exception: {
    type: String
  },
  MixedCase: {
    type: Boolean
  },
  LivExLWinCode: {
    type: Number
  },
  MixedCaseDefaultSize: {
    type: Number
  },
  AppellationName: {
    type: String
  },
  WineColour: {
    type: String
  },
  WineParkerScore: {
    type: String
  },
  WineBurghoundScore: {
    type: String
  },
  FormatID: {
    type: Number
  },
  FormatName: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  FormatCode: {
    type: String
  },
  FormatVolume: {
    type: Number
  },
  WineUsageCodeID: {
    type: Number
  },
  Category: {
    type: String
  },
  CategorySequence: {
    type: Number
  },
  UsageCode: {
    type: String
  },
  TotalBottles: {
    type: Number
  },
  DutyPaidBottles: {
    type: Number
  },
  InBondBottles: {
    type: Number
  },
  UnallocatedSalesBottles: {
    type: Number
  },
  OversoldBottles: {
    type: Number
  },
  AvailableBottles: {
    type: Number
  },
  ReservesBottles: {
    type: Number
  },
  BrokingBottles: {
    type: Number
  },
  SomeStockLoss: {
    type: Number
  },
  AverageCostPrice: {
    type: Number
  },
  WineCostPrice: {
    type: Number
  },
  LooseCostPrice: {
    type: Number
  },
  ReservedBottles: {
    type: Number
  },
  AuditCreatedDate: {
    type: String
  },
  AuditCreatedBy: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  AuditAmendedBy: {
    type: String
  },
  AuditAmendedByUserName: {
    type: String
  },
  ParkerSortValue: {
    type: Number
  },
  LooseBottlePrice: {
    type: Number
  }
}

const WineCardSearch = new Entity('WineCardSearch', fields)

WineCardSearch.createQuery('get', 'reservesWineBrowserSearch', {
  current_page: {
    type: Number
  },
  last_page: {
    type: Number
  },
  next_page_url: {
    type: String
  },
  per_page: {
    type: Number
  },
  prev_page_url: {
    type: String
  },
  total: {
    type: Number
  },
  wineBrowserResults: {
    type: Array,
    fields: wineBrowserFields
  }
})

export default WineCardSearch

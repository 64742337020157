<template>
  <b-form class="t-empty-case-form">
    <b-form-group
      label="Type *"
      label-size="sm"
      label-for="t-empty-case-form__input-sizeOrDefault"
      label-cols-md
      class="t-empty-case-form__group t-empty-case-form__group--1"
    >
      <b-input-group size="sm">
        <b-form-select
          id="t-empty-case-form__input-type"
          v-model="type"
          :options="emptyCaseOptions"
        />
      </b-input-group>
    </b-form-group>

    <b-form-group
      label="Case Size *"
      label-size="sm"
      label-for="t-empty-case-form__input-sizeOrDefault"
      label-cols-md
      class="t-empty-case-form__group t-empty-case-form__group--1"
    >
      <b-input-group size="sm">
        <b-form-input
          id="t-empty-case-form__input-sizeOrDefault"
          v-model="sizeOrDefault"
          type="number"
          size="sm"
          class="text-right"
          :state="!$v.sizeOrDefault.$error && null"
        />
        <b-input-group-append v-if="hasCaseSize">
          <b-input-group-text>{{ volume }}</b-input-group-text>
        </b-input-group-append>
        <b-form-invalid-feedback>
          A size is required and must be numeric
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>

    <b-form-group
      label="Notes *"
      label-size="sm"
      label-for="t-empty-case-form__input-notes"
      label-cols-md
      class="t-empty-case-form__group t-empty-case-form__group--2"
    >
      <b-form-input
        id="t-empty-case-form__input-notes"
        v-model="notes"
        size="sm"
        :state="!$v.notes.$error && null"
        maxlength="50"
      />
      <b-form-invalid-feedback>
        notes are required
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Location *"
      label-size="sm"
      label-for="t-empty-case-form__input-location"
      label-cols-md
      class="t-empty-case-form__group t-empty-case-form__group--3"
    >
      <b-input-group>
        <b-input-group-prepend>
          <b-form-select
            id="t-empty-case-form__input-type"
            v-model="locationPrefix"
            size="sm"
            :options="locationPrefixOptions"
            :state="!$v.locationPrefix.$error && null"
          />
          <div class="p-1">
            -
          </div>
        </b-input-group-prepend>
        <b-form-input
          id="t-empty-case-form__input-location"
          v-model="location"
          size="sm"
          :state="!$v.location.$error && null"
        />

        <b-form-invalid-feedback>
          A location is required
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>



    <b-form-group
      label="Comments"
      label-size="sm"
      label-for="t-empty-case-form__input-comments"
      label-cols-md
      class="t-empty-case-form__group t-empty-case-form__group--4"
    >
      <b-form-textarea
        id="t-empty-case-form__input-comments"
        v-model="comments"
        size="sm"
        rows="3"
        max-rows="6"
        maxlength="128"
      />
    </b-form-group>
  </b-form>
</template>

<script>
import { required, integer, minValue } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    volume: {
      type: String,
      required: false,
      default() {
        return null
      }
    },
    caseSize: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  computed: {
    locationPrefixOptions() {
      return this.$store.state.wineCard.emptyCases.locationPrefixes
    },
    emptyCaseOptions() {
      return [{ value: 1, text: 'Case' }, { value: 2, text: 'Label' }]
    },
    hasCaseSize() {
      return this.caseSize !== null
    },
    sizeOrDefault: {
      get() {
        if (this.size !== undefined) {
          return this.size
        }
        return this.caseSize
      },
      set(value) {
        this.size = value
      }
    },
    ...mapObjectToVModel([
      'size',
      'notes',
      'location',
      'comments',
      'type',
      'locationPrefix'
    ])
  },
  validations: {
    sizeOrDefault: {
      required,
      integer,
      minValue: minValue(1)
    },
    location: {
      required
    },
    locationPrefix: {
      required
    },
    notes: {
      required
    }
  }
}
</script>

<style lang="scss">
.t-empty-case-form {
  textarea.form-control {
    height: 31px !important;
  }
}

.t-empty-case-form__group {
  .col-md {
    flex: 0 0 95px;
    max-width: 95px;
  }

  &--1 {
    width: 200px;
  }

  &--2,
  &--3 {
    width: 275px;
  }

  &--4 {
    width: 325px;
  }
}
</style>

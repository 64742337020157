import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink, Observable } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'

let authToken

export const setToken = token => {
  authToken = token
}

const request = operation => {
  operation.setContext({
    headers: {
      authorization: 'Bearer ' + authToken
    }
  })
}

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      if (process.env.NODE_ENV !== 'production') {
        const args = operation.query.definitions[0].selectionSet.selections[0].arguments.reduce(
          (acc, item) => {
            acc[item.name.value] = item.value.value
            return acc
          },
          {}
        )
        console.log(
          '%c[GRAPHQL] ' +
            operation.query.definitions[0].operation +
            ' ' +
            operation.query.definitions[0].selectionSet.selections[0].name
              .value,
          'color:orange',
          args
        )
      }
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

export const client = new ApolloClient({
  link: ApolloLink.from([
    requestLink,
    new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error
      }
    }),
    new HttpLink({
      uri: process.env.apiHost + '/graphql',
      credentials: 'include'
    })
  ]),
  cache: new InMemoryCache(),
  defaultOptions
})

<template>
  <contact-search-modal
    v-model="showContactsSearchModal"
    :show-filters="showFilters"
    :filters="filters"
    @selected="handleSelectedContact"
    @new="handleNew"
  />
</template>

<script>
import ContactSearchModal from '~/components/Modal/ContactsSearchModal'

export default {
  components: {
    ContactSearchModal
  },
  computed: {
    showContactsSearchModal: {
      get() {
        return this.$store.state.modal.showContactsSearchModal
      },
      set(value) {
        this.$store.commit('modal/setShowContactsSearchModal', value)
      }
    },
    showFilters() {
      return this.$store.state.contacts.contacts.quickSearch.show
    },
    filters() {
      return this.$store.state.contacts.contacts.quickSearch.filters
    }
  },
  watch: {
    showContactsSearchModal(shown) {
      if (shown) {
        this.$store.dispatch('lists/refreshContactStatus', {})
        this.$store.dispatch('lists/refreshPromotionProfile', {})
      }
    }
  },
  methods: {
    handleSelectedContact(contact) {
      this.$eventHub.$emit('selected-contact', contact)
    },
    handleNew() {
      this.$eventHub.$emit('new-contact')
    }
  }
}
</script>

<template>
  <div>
    <b-input
      ref="inputPrice"
      v-model="priceDisplay"
      :size="size"
      :class="inputClass"
      :disabled="disabled"
      :state="state"
      @focus.native="handleFocus"
      @blur.native="handleConfirmation"
      @keydown.enter.native="$event.target.blur()"
      @keyup.native="handleKeyup"
    />
    <confirm v-if="showConfirmationModal" v-model="showConfirmationModal" @ok="handleUpdate" @cancel="reset">
      <slot name="confirmMessage" :value="price">
        Are You Sure?
      </slot>
    </confirm>
  </div>
</template>

<script>
import Confirm from '~/components/Modal/Confirm'
export default {
  components: {
    Confirm
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    inputClass: {
      type: [String, Object],
      required: false,
      default: 'text-right font-weight-bold'
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    showConfirm: {
      type: Boolean,
      default: true
    },
    confirmIfLower: {
      type: Number,
      required: false,
      default: -1
    },
    confirmIfGreater: {
      type: Number,
      required: false,
      default: -1
    },
    state: {
      type: Boolean,
      default: null
    },
    showIfEmpty: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      changedValue: null,
      showConfirmationModal: false
    }
  },
  computed: {
    price: {
      get() {
        if (this.changedValue !== null) {
          return this.changedValue
        }
        return this.value
      },
      set(value) {
        if (value !== this.value) {
          this.changedValue = value
        }
      }
    },
    priceDisplay: {
      get() {
        if (this.changedValue !== null) {
          return parseInt(this.changedValue) !== 0 || this.showIfEmpty
            ? this.changedValue
            : 'None'
        }

        return (this.value !== null && parseInt(this.value) !== 0) ||
          this.showIfEmpty
          ? this.$options.filters.currency(this.value)
          : 'None'
      },
      set(value) {
        this.price = value
      }
    }
  },
  watch: {
    value() {
      this.reset()
    }
  },
  methods: {
    handleConfirmation() {
      if (this.changedValue === null) {
        return
      }
      if (typeof this.price === 'string' && this.price.length === 0) {
        return this.reset()
      }
      this.price = this.$options.filters.decimalPlaces(
        this.formattedPrice(this.price, true),
        2,
        true
      )
      if (this.showConfirm === false) {
        this.handleUpdate()
        return this.$emit('blur')
      }
      const changedValue = parseFloat(this.formattedPrice(this.changedValue))
      if (parseFloat(this.value) !== changedValue) {
        if (this.confirmIfLower === -1 && this.confirmIfGreater === -1) {
          this.showConfirmationModal = true
          return
        }
        if (this.confirmIfLower > -1 && changedValue < this.confirmIfLower) {
          this.showConfirmationModal = true
          return
        }
        if (
          this.confirmIfGreater > -1 &&
          changedValue >= this.confirmIfGreater
        ) {
          this.showConfirmationModal = true
          return
        }
        this.handleUpdate()
        this.$emit('blur')
      }
    },
    handleUpdate() {
      this.$emit('input', this.formattedPrice(this.price), this.reset)
    },
    handleFocus() {
      this.$refs.inputPrice.select()
      this.$emit('focus')
    },
    reset() {
      this.changedValue = null
    },
    formattedPrice(price, floor) {
      if (typeof price === 'string') {
        price = parseFloat(price.replace(/[^0-9.]+/g, ''))
      }
      const method = floor ? 'floor' : 'round'
      return Math[method](price * 100) / 100
    },
    dpCount(price) {
      if (typeof price === 'string') {
        const parts = price.split('.')
        if (parts.length > 1) {
          return Math.min(parts[1].length, 2)
        }
      }
      return 0
    },
    handleKeyup() {
      // let endsWithPeriod = false
      if (typeof this.price === 'string') {
        this.price = this.price.replace(/[^0-9.,]+/g, '')
        if (this.price.length === 0) {
          return
        }
        // endsWithPeriod = this.price[this.price.length - 1] === '.'
      }
      /* if (endsWithPeriod === false) {
        this.price = this.$options.filters.decimalPlaces(
          this.formattedPrice(this.price, true),
          this.dpCount(this.price),
          true
        )
      } */
      this.$emit('keyup', this.formattedPrice(this.price))
    }
  }
}
</script>

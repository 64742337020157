/* eslint-disable no-unused-vars */
import {
  getWineNames,
  updateWineName,
  getWineName,
  addWineName,
  deleteWineName
} from '@/api/administration/winenames'
import { merge, find } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  wineNameLoading: false,
  list: [],
  searchTerm: '',
  activeWineName: null
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setWineNameLoading(state, loading) {
    state.wineNameLoading = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  resetList(state) {
    state.list = []
  },
  addWineNameToList(state, wineName) {
    state.list.unshift(wineName)
  },
  setActiveWineName(state, { WineNameID }) {
    state.activeWineName = find(state.list, { WineNameID })
  },
  updateWineName(state, wineName) {
    const idx = state.list.findIndex(w => w.WineNameID === wineName.WineNameID)
    if (idx > -1) {
      const newWineName = merge({}, state.list[idx], wineName)
      Vue.set(state.list, idx, newWineName)
    }
  },
  updateAuditAmendedDate(state, wineName) {
    const idx = state.list.findIndex(
      wn => wn.WineNameID === wineName.WineNameID
    )
    if (idx !== -1) {
      state.list[idx].AuditAmendedDate = wineName.AuditAmendedDate
    }
  },
  updateAppellationName(state, wineName) {
    const idx = state.list.findIndex(
      wn => wn.WineNameID === wineName.WineNameID
    )
    if (idx !== -1) {
      state.list[idx].AppellationName = wineName.AppellationName
    }
  },
  updateActiveWineNameAppellationName(state, appellationName) {
    if (state.activeWineName !== null) {
      state.activeWineName.AppellationName = appellationName
    }
  },
  clearActiveWineNameProperties(state) {
    state.activeWineName = {
      Name: '',
      DutyTypeID: 1,
      AppellationID: null,
      AppellationName: '',
      WineCode: ''
    }
  },
  removeWineNameFromList(state, wineNameID) {
    state.list = state.list.filter(wn => wn.WineNameID !== wineNameID)
  }
}

export const getters = {
  getWineNameList(state) {
    return state.list
  },
  getActiveWineName(state) {
    return state.activeWineName
  }
}

export const actions = {
  async loadList({ commit, state }) {
    commit('setLoading', true)
    const response = await getWineNames(state.searchTerm)
    commit('setList', response)
    commit('setLoading', false)
  },
  setActiveWineName({ commit }, wineName) {
    commit('setActiveWineName', wineName)
  },
  clearActiveWineNameProperties({ commit }) {
    commit('clearActiveWineNameProperties')
  },
  async addWineName({ commit }, wineName) {
    commit('setWineNameLoading', true)
    try {
      const result = await addWineName(wineName)
      commit('addWineNameToList', result)
      return result
    } catch (e) {
      throw e
    } finally {
      commit('setWineNameLoading', false)
    }
  },
  async updateWineName({ commit, rootState, state }, wineName) {
    commit('setWineNameLoading', true)
    try {
      commit('updateAppellationName', wineName)
      await updateWineName(wineName)
      const result = await getWineName(wineName)
      commit('updateWineName', result)
    } catch (e) {
      throw e
    } finally {
      commit('setWineNameLoading', false)
    }
  },
  async deleteWineName({ commit }, wineName) {
    commit('setWineNameLoading', true)
    try {
      const result = await deleteWineName(wineName)
      if (result) {
        commit('removeWineNameFromList', wineName.WineNameID)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setWineNameLoading', false)
    }
  }
}

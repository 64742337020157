import { client } from '../graphQLInstance'
import SalesOrder from '../../models/salesorder'
import PurchaseOrder from '../../models/purchaseorder'

export const getSalesOrderByCustomer = async args => {
  const result = await client.query({
    query: SalesOrder.query('getByCustomer', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrder.queries.getByCustomer.name]
}

export const getPurchaseOrderByCustomer = async args => {
  const result = await client.query({
    query: PurchaseOrder.query('getByCustomer', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PurchaseOrder.queries.getByCustomer.name]
}

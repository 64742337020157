<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :hide-footer="true"
    :ok-title="saveButtonText"
    :ok-disabled="saving"
    :cancel-disabled="saving"
    @hidden="$emit('hidden')"
    @shown="reset"
    @ok="save"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <template v-slot:default="{ ok }">
      <div v-if="loading" class="alert alert-info">
        Loading Please Wait...
      </div>
      <div v-else>
        <wine-note-form ref="form" v-model="form" />
        <b-button-group class="w-100">
          <b-button class="btn wv-btn btn-secondary btn-sm mr-1" @click="ok()">
            {{ saveButtonText }}
          </b-button>
          <b-button class="btn wv-btn btn-secondary btn-sm ml-1" @click="shown=false">
            Cancel
          </b-button>
        </b-button-group>
        <div v-if="formErrorMessage" class="alert alert-danger">
          {{ formErrorMessage }}
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import WineNoteForm from '~/components/Forms/WineNoteForm'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'
import { formResultMixin } from '~/mixins/forms'

export default {
  components: {
    WineNoteForm
  },
  mixins: [formResultMixin, TitleFromWineCard],
  props: {
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    saving: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      formErrorMessage: null,
      form: null,
      formValid: false,
      titleSuffix: 'Create Note'
    }
  },
  computed: {
    saveButtonText() {
      return this.saving ? 'Saving Please Wait' : 'Add Note'
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    reset() {
      this.formErrorMessage = null
      this.form = {}
      this.$refs.form.reset()
    },
    async save(e) {
      e.preventDefault()
      try {
        await this.$refs.form.validate()
        try {
          const id = this.item.id ? this.item.id : this.item.WineCardID
          const newNote = await this.$store.dispatch(
            'wineCard/notes/createNote',
            {
              Note: this.form.note,
              WineCardID: id
            }
          )
          this.$eventHub.$emit('create-note', newNote.note)
          this.shown = false
        } catch (e) {
          this.formErrorMessage = e
        }
      } catch (e) {
        this.error = e
      }
    }
  }
}
</script>

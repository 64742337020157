import Entity from './entity'

const fields = {
  SOID: {
    type: Number
  },
  SOLID: {
    type: Number
  },
  TransactionOrder: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  WineVintage: {
    type: Number
  },
  WineName: {
    type: String
  },
  ProducerName: {
    type: String
  },
  OrderedBottles: {
    type: Number
  },
  OversoldBottles: {
    type: Number
  },
  TotalBottlesAvailable: {
    type: Number
  },
  CaseSize: {
    type: Number
  }
}

const SalesOrderOversold = new Entity('SalesOrderOversold', fields)

SalesOrderOversold.createQuery('getAll', 'salesOrderOversold', fields)

export default SalesOrderOversold

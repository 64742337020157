import { client } from '../graphQLInstance'
import PromotionProfile from '../../models/promotionprofile'

export const getPromotionProfiles = async args => {
  const result = await client.query({
    query: PromotionProfile.query('getAll', [])
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PromotionProfile.queries.getAll.name]
}

export const getPromotionProfile = async code => {
  const result = await client.query({
    query: PromotionProfile.query('get', {
      RecordCode: code
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PromotionProfile.queries.get.name]
}

export const updatePromotionProfile = async args => {
  const result = await client.query({
    query: PromotionProfile.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PromotionProfile.mutations.update.name]
}

export const deletePromotionProfile = async args => {
  const result = await client.query({
    query: PromotionProfile.mutate('delete', {
      Code: args.PromotionProfileCode,
      AuditAmendedDate: args.AuditAmendedDate
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PromotionProfile.mutations.delete.name]
}

export const addPromotionProfile = async args => {
  const result = await client.query({
    query: PromotionProfile.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[PromotionProfile.mutations.insert.name]
}

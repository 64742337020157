import { without } from 'lodash'
import { WineUsage } from '~/constants/wineUsage'

export const state = () => ({
  currencies: ['GBP'],
  dutyStatus: 'allStock',
  hyperLinksInEmail: ['full'],
  looseBottleCheck: 'checkPubWfs',
  marginCheck: 'nonIndividualLoss',
  outputSettings: '',
  packaging: 'allStock',
  scores: 'orMore',
  usage: 'pub',
  grouping: 'ALL',
  minParkerScore: 85,
  usageCodeMap: {
    pub: 1,
    wfs: 2,
    pubWfs: 0,
    nfs: 4,
    pubBro: 0 // THIS IS NOT USED?!
  },
  dutyStatusCodeMap: {
    dutyPaid: 1,
    inBond: 2,
    allStock: 0
  },
  marginCheckMap: {
    allStock: 1,
    nonIndividualLoss: 2,
    noNetLoss: 3
  },
  categoryIds: [],
  owcSelection: [],
  usageCodes: [WineUsage.PUB, WineUsage.BRO]
})

export const getters = {
  reportParameters: state => {
    return {
      Currencies: state.currencies,
      Scores: state.scores,
      HyperLinks: state.hyperLinksInEmail,
      // WineUsageCodeID: state.usageCodeMap[state.usage],
      WineUsageCodes: state.usageCodes,
      DutyStatusID: state.dutyStatusCodeMap[state.dutyStatus],
      WithOrigPkg: state.packaging !== 'allStock',
      StockLossType: state.marginCheckMap[state.marginCheck],
      CheckLooseBottles: state.looseBottleCheck === 'checkPubWfs',
      LastReceiptDays: 0,
      CategoryIds: state.categoryIds,
      Grouping: state.grouping,
      OnlyOWCs: state.owcSelection
    }
  },
  searchParameters: state => {
    return {
      // WineUsageCodeID: state.usageCodeMap[state.usage],
      WineUsageCodes: state.usageCodes,
      DutyStatusID: state.dutyStatusCodeMap[state.dutyStatus],
      WithOrigPkg: state.packaging !== 'allStock',
      StockLossType: state.marginCheckMap[state.marginCheck],
      CheckLooseBottles: state.looseBottleCheck === 'checkPubWfs',
      LastReceiptDays: 0,
      CategoryIds: state.categoryIds,
      OnlyOWCs: state.owcSelection,
      Grouping: state.grouping
    }
  }
}

export const mutations = {
  setUsageById(state, usageId) {
    switch (usageId) {
      case WineUsage.PUB:
        state.usage = 'pub'
        state.usageCodes = [usageId]
        break
      case WineUsage.WFS:
        state.usage = 'wfs'
        state.usageCodes = [usageId]
        break
      case WineUsage.NFS:
        state.usage = 'nfs'
        state.usageCodes = [usageId]
        break
      default:
        state.usage = 'pubBro'
        state.usageCodes = [WineUsage.PUB, WineUsage.BRO]
    }
  },
  setCurrencies(state, currencies) {
    state.currencies = currencies
  },
  setDutyStatus(state, dutyStatus) {
    state.dutyStatus = dutyStatus
  },
  setHyperLinksInEmail(state, hyperLinksInEmail) {
    state.hyperLinksInEmail = hyperLinksInEmail
  },
  setLooseBottleCheck(state, looseBottleCheck) {
    state.looseBottleCheck = looseBottleCheck
  },
  setMarginCheck(state, marginCheck) {
    state.marginCheck = marginCheck
  },
  setOutputSettings(state, outputSettings) {
    state.outputSettings = outputSettings
  },
  setPackaging(state, packaging) {
    state.packaging = packaging
  },
  setScores(state, scores) {
    state.scores = scores
  },
  setGrouping(state, grouping) {
    state.grouping = grouping
  },
  setUsage(state, usage) {
    state.usage = usage
  },
  setUsageCodes(state, usageCodes) {
    state.usageCodes = usageCodes
  },
  setCategoryIds(state, categoryIds) {
    state.categoryIds = categoryIds
  },
  addOwc(state, owc) {
    if (state.owcSelection.indexOf(owc) < 0) {
      state.owcSelection.push(owc)
    }
  },
  removeOwc(state, owc) {
    if (state.owcSelection.indexOf(owc) >= 0) {
      state.owcSelection = without(state.owcSelection, owc)
    }
  },
  reset(state, looseBottlesOnly) {
    state.currencies = ['GBP']
    state.dutyStatus = 'allStock'
    state.hyperLinksInEmail = ['full']
    state.looseBottleCheck = 'checkPubWfs'
    state.marginCheck = 'nonIndividualLoss'
    state.outputSettings = null
    state.packaging = 'allStock'
    state.scores = 'orMore'
    state.usage = 'pub'
    state.usageCodes = [WineUsage.PUB, WineUsage.BRO]
    state.grouping = looseBottlesOnly ? 'BOTH' : 'ALL'
    state.categoryIds = []
    state.owcSelection = []
  }
}

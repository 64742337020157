<template>
  <div>
    <b-modal
      v-model="shown"
      centered
      modal-class="wv-modal"
      content-class="shadow"
      :hide-header-close="true"
      :hide-footer="true"
      size="xxl"
      @show="loadTemplates"
      @hide="handleHiding"
    >
      <template slot="modal-header">
        Email Promotion Templates
      </template>
      <template v-slot:default>
        <promotion-email-templates-form
          ref="form"
          :value="templates"
          :loading="loading"
          :using-defaults="usingDefaults"
          @reload="loadTemplates"
          @cancel="handleCancel"
          @reset="confirmResetForm"
          @change="handleFormChange"
          @save="handleSave"
        />
      </template>
    </b-modal>
    <confirm-modal
      v-model="boolConfirmCloseModal"
      @ok="emailTemplateConfirmation"
    >
      You have unsaved changes. Did you mean to discard these?
    </confirm-modal>
    <confirm-modal
      v-model="boolConfirmReset"
      @ok="resetForm"
    >
      This will reset the texts to the initial defaults, but you dont have to save them. Do you want to continue.
    </confirm-modal>
  </div>
</template>

<script>
import PromotionEmailTemplatesForm from '~/components/Forms/PromotionEmailTemplatesForm'
import ConfirmModal from '~/components/Modal/Confirm'
import { formResultMixin } from '~/mixins/forms'
export default {
  components: {
    PromotionEmailTemplatesForm,
    ConfirmModal
  },
  mixins: [formResultMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      boolConfirmCloseModal: false,
      boolConfirmReset: false,
      mode: 'normal'
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    templates() {
      if (this.mode === 'defaults') {
        return this.$store.state.administration.promotionprofiles
          .defaultTemplates
      }
      return this.$store.state.administration.promotionprofiles.templates
    },
    loading() {
      return this.$store.state.administration.promotionprofiles.templatesLoading
    },
    usingDefaults() {
      return this.mode === 'defaults'
    }
  },
  methods: {
    loadTemplates() {
      this.mode = 'normal'
      this.$store.dispatch('administration/promotionprofiles/getTemplates')
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    handleCancel() {
      this.shown = false
    },
    async handleSave() {
      try {
        this.$refs.form.validate()
        await this.$store.dispatch(
          'administration/promotionprofiles/saveTemplates',
          this.form
        )
        this.$refs.form.reset()
        this.shown = false
        this.$notify.success('Templates Updated!')
      } catch (e) {
        this.$notifyError(e)
      }
    },
    emailTemplateConfirmation() {
      this.$refs.form.reset()
      this.mode = 'normal'
      this.shown = false
    },
    confirmResetForm() {
      this.boolConfirmReset = true
    },
    resetForm() {
      this.mode = 'defaults'
      this.$refs.form.reset()
    },
    handleHiding(e) {
      if (this.$refs.form.isDirty() || this.usingDefaults) {
        e.preventDefault()
        this.shown = true
        this.boolConfirmCloseModal = true
      }
    }
  }
}
</script>

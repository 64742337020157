<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :hide-footer="true"
    size="xl"
    title=""
    body-class="px-2 py-0"
  >
    <template v-slot:modal-header>
      New Wine Card
    </template>
    <new-wine-card class="mt-3" :use-events="true" @saved="$emit('saved', $event)" @updated-using-lwin="$emit('updated-using-lwin', $event)" />
  </b-modal>
</template>

<script>
import NewWineCard from '../../pages/wine-card/index'
export default {
  components: {
    NewWineCard
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

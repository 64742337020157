<template>
  <export-email-modal v-model="showEmptyCaseModal" />
</template>

<script>
import ExportEmailModal from '~/components/Modal/ExportEmailModal'
export default {
  components: {
    ExportEmailModal
  },
  computed: {
    showEmptyCaseModal: {
      get() {
        return this.$store.state.modal.showExportEmailModal
      },
      set(value) {
        this.$store.commit('modal/setShowExportEmailModal', value)
      }
    }
  }
}
</script>

import { search, importCode } from '~/api/lwinDictionary'

export const state = () => ({
  results: [],
  loading: false,
  saving: false
})

export const mutations = {
  setResults(state, results) {
    state.results = results
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  },
  reset(state) {
    state.results = []
  }
}

export const actions = {
  async search({ commit }, { SearchTerm, Vintage }) {
    try {
      commit('setLoading', true)
      const results = await search({ SearchTerm, Vintage })
      commit('setResults', results)
      return results
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async importCode({ commit }, { LivExLWinCode, Vintage }) {
    try {
      commit('setSaving', true)
      const results = await importCode({ LivExLWinCode, Vintage })
      commit('setSaving', false)
      return results
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  }
}

import Entity from '../entity'

const fields = {
  POID: {
    type: Number
  },
  POLID: {
    type: Number
  },
  ContactID: {
    type: Number
  },
  ComputedWineName: {
    type: String
  },
  ProducerName: {
    type: String
  },
  WineVintage: {
    type: String
  },
  CompanyName: {
    type: String
  },
  AllocBottles: {
    type: Number
  },
  Bottles: {
    type: Number
  },
  UnallocatedBottles: {
    type: Number
  },
  ExpectedBottles: {
    type: Number
  },
  ReceivedBottles: {
    type: Number
  },
  FormatCaseSize: {
    type: Number
  },
  Format: {
    type: String
  },
  PhotoRequired: {
    type: Boolean
  },
  CreatedInitials: {
    type: String
  },
  OriginalPackaging: {
    type: Boolean
  },
  OrigPkgCaseSize: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  DutyStatusID: {
    type: Number
  },
  MixedCase: {
    type: Boolean
  },
  IsReserves: {
    type: Boolean
  }
}

const UnallocatedSearch = new Entity('UnallocatedSearch', fields)

UnallocatedSearch.createQuery(
  'unallocatedSearch',
  'unallocatedSearch',
  {
    ...fields,
    TallyCards: {
      type: Array,
      fields: {
        ETCID: {
          type: Number
        },
        Reference: {
          type: String
        }
      }
    }
  },
  {
    Search: {
      type: String
    }
  }
)

UnallocatedSearch.createQuery(
  'unallocatedSupplierPurchaseOrderLines',
  'unallocatedSupplierPurchaseOrderLines',
  fields,
  {
    ContactID: {
      type: Number
    }
  }
)

export default UnallocatedSearch

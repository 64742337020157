import { searchProducers } from '~/api/administration/producers'
export const state = () => ({
  selectedCountry: [],
  selectedRegion: [],
  selectedSubRegion: [],
  selectedProducers: [],
  producers: [],
  loadingProducers: false,
  producersLoading: null
})

export const getters = {
  searchParameters: state => {
    return {
      countryMultiple:
        state.selectedCountry.length > 0
          ? state.selectedCountry.join(',')
          : null,
      regionMultiple:
        state.selectedRegion.length > 0 ? state.selectedRegion.join(',') : null,
      subRegionMultiple:
        state.selectedSubRegion.length > 0
          ? state.selectedSubRegion.join(',')
          : null,
      producerMultiple:
        state.selectedProducers.length > 0
          ? state.selectedProducers.join(',')
          : null
    }
  }
}

export const mutations = {
  setSelectedCountry(state, selected) {
    state.selectedCountry = selected
    state.selectedRegion = []
    state.selectedSubRegion = []
    state.selectedProducers = []
  },
  setSelectedRegion(state, selected) {
    state.selectedRegion = selected
    state.selectedProducers = []
    state.selectedSubRegion = []
  },
  setSelectedSubRegion(state, selected) {
    state.selectedSubRegion = selected
    state.selectedProducers = []
  },
  setSelectedProducers(state, producers) {
    state.selectedProducers = producers
  },
  setProducers(state, producers) {
    state.producers = producers
  },
  setLoadingProducers(state, loading) {
    state.loadingProducers = loading === true
  },
  setProducersLoading(state, producers) {
    state.producersLoading = producers
  },
  resetSelection(state) {
    state.selectedCountry = []
    state.selectedRegion = []
    state.selectedSubRegion = []
    state.selectedProducers = []
    state.producers = []
    state.producersLoading = null
  }
}

export const actions = {
  async searchForProducers({ commit, state }, payload) {
    if (!payload) {
      payload = {}
      if (state.selectedCountry && state.selectedCountry.length > 0) {
        payload.CountryIdMultiple = state.selectedCountry.join(',')
      }
      if (state.selectedRegion && state.selectedRegion.length > 0) {
        payload.CountryRegionIdMultiple = state.selectedRegion.join(',')
      }
      if (state.selectedSubRegion && state.selectedSubRegion.length > 0) {
        payload.CountrySubRegionIdMultiple = state.selectedSubRegion.join(',')
      }
    }
    try {
      const payloadString = JSON.stringify(payload)
      commit('setLoadingProducers', true)
      commit('setProducersLoading', payloadString)
      const producers = await searchProducers(payload)
      if (state.producersLoading === payloadString) {
        commit('setProducers', producers)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingProducers', false)
    }
  }
}

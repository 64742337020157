import { client } from '../graphQLInstance'
import WebPurchaseOrder from '../../models/webpurchaseorder'
import WebPurchaseOrderLine from '../../models/webpurchaseorderline'
import WebPurchaseOrderAllocation from '../../models/webpurchaseorderallocation'

export const searchPurchaseOrders = async args => {
  const result = await client.query({
    query: WebPurchaseOrder.query('search', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrder.queries.search.name]
}

export const getPurchaseOrder = async args => {
  const result = await client.query({
    query: WebPurchaseOrder.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrder.queries.get.name]
}

export const getPurchaseOrderLines = async args => {
  const result = await client.query({
    query: WebPurchaseOrderLine.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrderLine.queries.get.name]
}

export const getPurchaseOrderAllocations = async args => {
  const result = await client.query({
    query: WebPurchaseOrderAllocation.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrderAllocation.queries.get.name]
}

export const updatePurchaseOrder = async args => {
  const result = await client.query({
    query: WebPurchaseOrder.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrder.mutations.update.name]
}

export const convertPurchaseOrder = async args => {
  const result = await client.query({
    query: WebPurchaseOrder.mutate('convert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrder.mutations.convert.name]
}

export const addWebPurchaseOrderAllocation = async args => {
  const result = await client.query({
    query: WebPurchaseOrderAllocation.mutate('add', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrderAllocation.mutations.add.name]
}

export const updateWebPurchaseOrderAllocation = async args => {
  const result = await client.query({
    query: WebPurchaseOrderAllocation.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrderAllocation.mutations.update.name]
}

export const deleteWebPurchaseOrderAllocation = async args => {
  const result = await client.query({
    query: WebPurchaseOrderAllocation.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrderAllocation.mutations.delete.name]
}

export const deleteWebPurchaseOrderLine = async args => {
  const result = await client.query({
    query: WebPurchaseOrderLine.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrderLine.mutations.delete.name]
}

export const importWebPurchaseOrder = async args => {
  const result = await client.query({
    query: WebPurchaseOrder.mutate('importCSV', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[WebPurchaseOrder.mutations.importCSV.name]
}

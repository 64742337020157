import Entity from '~/models/entity'
import { fields as billingRunContactFields } from '~/models/storage/billingRunContact'

export const fields = {
  BRID: {
    type: Number
  },
  BRSID: {
    type: Number
  },
  BRPID: {
    type: Number
  },
  FromDate: {
    type: String
  },
  ToDate: {
    type: String
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditCreatedDate: {
    type: String
  },
  Response: {
    type: String
  },
  Period: {
    type: String
  },
  Status: {
    type: String
  }
}

const updateFields = {
  BRID: {
    type: Number
  },
  BillingRunContacts: {
    type: Array,
    fields: {
      BRCID: {
        type: Number
      },
      Process: {
        type: Boolean
      },
      Waive: {
        type: Boolean
      }
    }
  },
  BillingRunInventories: {
    type: Array,
    fields: {
      BRIID: {
        type: Number
      },
      Process: {
        type: Boolean
      },
      FromDate: {
        type: String
      },
      Price: {
        type: Number
      },
      Waive: {
        type: Number
      }
    }
  }
}

const BillingRun = new Entity('BillingRun', fields)

BillingRun.createQuery('get', 'billingRunGet', fields)
BillingRun.createQuery(
  'getAll',
  'billingRunGetAll',
  {
    total: {
      type: Number
    },
    billingRunPaginatedResults: {
      type: Array,
      fields: {
        ...fields,
        Contacts: {
          type: Array,
          fields: billingRunContactFields
        }
      }
    }
  },
  {
    // Status: {
    //   type: Number
    // },
    // Search: {
    //   type: String
    // },
    PerPage: {
      type: Number
    },
    Page: {
      type: Number
    },
    BRIDS: {
      type: Array,
      fields: {
        BRID: {
          type: Number
        }
      }
    }
  }
)

BillingRun.createMutation(
  'add',
  'billingRunAdd',
  Entity.onlyKeys(fields, ['BRPID', 'FromDate', 'ToDate']),
  {
    ...fields,
    Contacts: {
      type: Array,
      fields: billingRunContactFields
    }
  }
)

BillingRun.createMutation('update', 'billingRunUpdate', updateFields, {
  ...fields,
  Contacts: {
    type: Array,
    fields: billingRunContactFields
  }
})

BillingRun.createMutation(
  'duplicate',
  'billingRunDuplicate',
  Entity.onlyKeys(fields, ['BRID']),
  {
    ...fields,
    Contacts: {
      type: Array,
      fields: billingRunContactFields
    }
  }
)

BillingRun.createMutation(
  'process',
  'billingRunProcess',
  {
    ...Entity.onlyKeys(fields, ['BRID']),
    Waive: {
      type: Boolean
    }
  },
  {
    ...fields,
    Contacts: {
      type: Array,
      fields: billingRunContactFields
    }
  }
)

BillingRun.createMutation(
  'delete',
  'billingRunDelete',
  Entity.onlyKeys(fields, ['BRID']),
  []
)

export default BillingRun

import { getStockWineCard } from '@/api/stockCard/stockCard'
import { getStockCardPurchases } from '@/api/stockCard/purchases'
import { getStockCardSales } from '@/api/stockCard/sales'

export const state = () => ({
  loading: false,
  stockCard: {},
  activeCard: {},
  purchases: [],
  loadingPurchases: false,
  sales: [],
  loadingSales: false
})

export const getters = {
  getStockCard: state => {
    return state.stockCard
  },
  getActiveCard: state => {
    return state.activeCard
  }
}

export const mutations = {
  setStockCard(state, stockCard) {
    state.stockCard = stockCard
  },
  resetStockCard(state) {
    state.stockCard = {}
  },
  setActiveCard(state, activeCard) {
    state.activeCard = activeCard
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setPurchases(state, purchases) {
    state.purchases = purchases
  },
  setLoadingPurchases(state, loading) {
    state.loadingPurchases = loading === true
  },
  setSales(state, sales) {
    state.sales = sales
  },
  setLoadingSales(state, loading) {
    state.loadingSales = loading === true
  },
  resetPurchases(state) {
    state.purchases = []
  },
  resetSales(state) {
    state.sales = []
  }
}

export const actions = {
  async getStockCard({ commit }, { id, silent }) {
    if (silent !== undefined && silent) commit('setLoading', true)
    try {
      const response = await getStockWineCard(id)
      commit('setStockCard', response.stockCard)
    } catch (e) {
      throw e
    } finally {
      if (silent !== undefined && silent) commit('setLoading', false)
    }
  },
  async getPurchases({ commit }, args) {
    commit('resetPurchases')
    commit('setLoadingPurchases', true)
    try {
      const response = await getStockCardPurchases(args)
      commit('setPurchases', response)
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingPurchases', false)
    }
  },
  async getSales({ commit }, args) {
    commit('resetSales')
    commit('setLoadingSales', true)
    try {
      const response = await getStockCardSales(args)
      commit('setSales', response)
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingSales', false)
    }
  },
  async reloadStockCard({ commit, dispatch }, id) {
    commit('resetStockCard')
    await dispatch('getStockCard', { id })
  }
}

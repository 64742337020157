import { getGlobals, updateGlobals } from '../api/global'

export const state = () => ({
  data: {},
  loading: false,
  saving: false,
  loaded: false,
  error: null,
  DEFAULT_UNLESS_PRICE: 3000,
  loadingPromises: [],
  wineBrowserStores: [
    'wineBrowser',
    'salesOrders/search',
    'purchaseOrders/search'
  ]
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading
  },
  setSaving(state, saving) {
    state.saving = saving
  },
  setLoaded(state, loaded) {
    state.loaded = loaded
  },
  setError(state, error) {
    state.error = error
  },
  setData(state, data) {
    state.data = data
  },
  appendPromise(state, promise) {
    state.loadingPromises.push(promise)
  },
  drainPromises(state) {
    do {
      const resolve = state.loadingPromises.pop()
      resolve(state.data)
    } while (state.loadingPromises.length > 0)
  }
}

export const actions = {
  async getGlobalsIfNotLoaded({ commit, state }, force) {
    if (state.loaded && force !== true) {
      return state.data
    }
    if (state.loading) {
      return new Promise((resolve, reject) => {
        commit('appendPromise', resolve)
      })
    }
    try {
      commit('setError', null)
      commit('setLoading', true)
      commit('setData', await getGlobals())
      commit('setLoaded', true)
      commit('drainPromises')
      return state.data
    } catch (e) {
      commit('setError', e)
    } finally {
      commit('setLoading', false)
    }
  },
  async updateGlobals({ commit, state, dispatch }, globals) {
    if (!state.loaded) {
      return
    }
    commit('setSaving', true)
    try {
      globals.ID = state.data.ID
      globals.AuditAmendedDate = state.data.AuditAmendedDate
      const newGlobals = await updateGlobals(globals)
      commit('setData', newGlobals)
    } catch (e) {
      throw e
    } finally {
      commit('setSaving', false)
    }
  }
}

<template>
  <b-modal
    v-model="shown"
    modal-class="wv-modal wv-modal--notes"
    content-class="shadow"
    footer-class="justify-content-center"
    :hide-header-close="true"
    size="xxl"
    centered
    @hidden="$emit('hidden')"
    @shown="$emit('shown')"
    @show="handleShow"
    @hide="$emit('hide')"
    @ok="$emit('ok')"
    @cancel="$emit('cancel')"
    @change="$emit('change')"
  >
    <template v-slot:modal-header>
      <span>
        LWIN Lookup
      </span>
    </template>
    <group-box class="mt-2">
      <template v-slot:header>
        Search
      </template>
      <b-row>
        <b-col cols="2">
          <b-form-group label="Wine Vintage" label-cols="6" label-size="sm" label-align="right">
            <b-input-group size="sm">
              <b-form-input v-model="vintage" @keyup.enter.prevent="findCode" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Wine Description" label-cols="2" label-size="sm" label-align="right">
            <b-input-group size="sm">
              <b-form-input v-model="searchTerm" @keyup.enter.prevent="findCode" />
              <b-input-group-append>
                <b-button
                  size="sm"
                  class="btn wv-btn mx-2"
                  :disabled="disabled || loading"
                  @click="findCode"
                >
                  Find
                </b-button>
                <slot name="actions" />
                <b-button id="LWINSearchPopover" variant="link" class="wv-btn wv-btn--info" size="sm">
                  <i class="material-icons">
                    info
                  </i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </group-box>
    <group-box class="mt-4 mb-0 wv-quick-search__contact-modal-matches">
      <template v-slot:header>
        Matches
      </template>
      <div v-if="loading" class="text-center text-primary my-2">
        <b-spinner type="grow" label="Loading..." />
      </div>
      <vue-custom-scrollbar
        v-else
        class="virtual-scroll t-contacts"
        :settings="settings"
      >
        <virtual-table
          class="h-100"
          :fields="tableFields"
          :items="sortedResults"
          :total="results.length"
          :per-page="100"
          :table-tr-class="getRowClass"
          primary-key="LivExLWinCode"
          @row-clicked="rowSelected"
          @row-dblclicked="rowDoubleClicked"
          @sort-changed="handleSortChanged"
        >
          <template slot="Abv" slot-scope="data">
            <template v-if="data.value">
              {{ data.value }}%
            </template>
          </template>
          <template slot="AbvVerified" slot-scope="data">
            <template v-if="data.value">
              Yes
            </template>
            <template v-else>
              No
            </template>
          </template>
        </virtual-table>
      </vue-custom-scrollbar>
    </group-box>
    <template v-slot:modal-footer>
      <b-button :disabled="selectedItem === null" size="sm" class="btn wv-btn" @click="ok()">
        OK
      </b-button>
      <b-button size="sm" class="btn wv-btn" @click="cancel()">
        Cancel
      </b-button>
    </template>
    <b-popover target="LWINSearchPopover" placement="bottom">
      <h5>Searching LivEx Lwin API</h5>
      <p class="text-left mb-0 "><strong>Use the vintage where applicable</strong></p>
      <p class="text-left ml-3 mb-0" style="font-weight: normal">Wine names can change per vintage. use the vintage to actually find the correct wine name. The name you search for and the returned matches may be different due to this.</p>
      <p class="text-left mb-0 "><strong>Be descriptive as possible</strong></p>
      <p class="text-left ml-3 mb-0" style="font-weight: normal">The more descriptive the description the faster the search will be. searching "Latour" will be significantly slower than "Latour a Pomerol" for example</p>
      <p class="text-left mb-0 "><strong>Only top 20 results are returned</strong></p>
      <p class="text-left ml-3 mb-0" style="font-weight: normal">To help with speed, only the first 20 valid results are returned. If your wine is not found. try to be more descriptive.</p>
      <div class="text-center mt-2">
        <b-button size="sm" class="wv-btn" @click="exitPopover">
          Exit
        </b-button>
      </div>
    </b-popover>
  </b-modal>
</template>

<script>
import { sortBy } from 'lodash'
import GroupBox from '~/components/Base/GroupBox'
import VirtualTable from '~/components/Base/VirtualTable'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'
import measureText from '../../mixins/measureText'

export default {
  components: {
    GroupBox,
    VirtualTable,
    vueCustomScrollbar
  },
  mixins: [measureText],
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      searchTerm: null,
      vintage: null,
      sortBy: 'LivExLWinCode',
      sortDesc: false,
      selectedItem: null,
      settings: {
        tagname: 'div',
        suppressScrollY: true
      }
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    disabled() {
      return !this.searchTerm || this.searchTerm.length < 1
    },
    loading() {
      return this.$store.state.lwinDictionary.loading
    },
    results() {
      return this.$store.state.lwinDictionary.results
    },
    sortedResults() {
      const r = sortBy(this.results, this.sortBy)
      if (this.sortDesc) {
        return r.reverse()
      }
      return r
    },
    formattedVintage() {
      if (this.vintage && this.vintage.match(/^[0-9]{4}$/)) {
        return this.vintage
      }
      return 1000
    },
    tableFields() {
      return {
        LWIN7: {
          label: 'LWIN',
          sortable: true,
          width: this.measureLongestInTable('LWIN', this.results, 'LWIN') + 'px'
        },
        ComputedWineName: {
          label: 'Name',
          sortable: true,
          width:
            this.measureLongestInTable(
              'ComputedWineName',
              this.results,
              'Name'
            ) + 'px'
        },
        Producer: {
          label: 'Producer',
          sortable: true,
          width:
            this.measureLongestInTable('Producer', this.results, 'Producer') +
            'px'
        },
        Abv: {
          label: 'Abv',
          sortable: false,
          width: this.measureLongestInTable('Abv', this.results, 'Abv') + 'px'
        },
        AbvVerified: {
          label: 'Abv Verified',
          sortable: false,
          width:
            this.measureLongestInTable(
              'AbvVerified',
              this.results,
              'Abv Verified'
            ) + 'px'
        },
        Country: {
          label: 'Country',
          sortable: true,
          width:
            this.measureLongestInTable('Country', this.results, 'Country') +
            'px'
        },
        Region: {
          label: 'Region',
          sortable: true,
          width:
            this.measureLongestInTable('Region', this.results, 'Region') + 'px'
        },
        SubRegion: {
          label: 'Sub Region',
          sortable: true,
          width:
            this.measureLongestInTable(
              'SubRegion',
              this.results,
              'Sub Region'
            ) + 'px'
        },
        Colour: {
          label: 'Colour',
          sortable: true,
          width:
            this.measureLongestInTable('Colour', this.results, 'Colour') + 'px'
        },
        Designation: {
          label: 'Designation',
          sortable: true,
          width:
            this.measureLongestInTable(
              'Designation',
              this.results,
              'Designation'
            ) + 'px'
        },
        Classification: {
          label: 'Classification',
          sortable: true,
          width:
            this.measureLongestInTable(
              'Classification',
              this.results,
              'Classification'
            ) + 'px'
        }
      }
    }
  },
  methods: {
    async findCode() {
      if (this.searchTerm) {
        const results = await this.$store.dispatch('lwinDictionary/search', {
          Vintage: this.formattedVintage,
          SearchTerm: this.searchTerm
        })
        this.$emit('results', results)
      }
    },
    getRowClass(item) {
      if (
        this.selectedItem &&
        this.selectedItem.LivExLWinCode === item.LivExLWinCode
      ) {
        return 'row-selected'
      }
      return ''
    },
    rowDoubleClicked(item) {
      this.$emit('selected', item)
      this.shown = false
    },
    rowSelected(item) {
      this.selectedItem = item
    },
    cancel() {
      this.shown = false
    },
    ok() {
      this.$emit('selected', this.selectedItem)
      this.shown = false
    },
    handleSortChanged(sorted) {
      this.sortBy = sorted.sortBy
      this.sortDesc = sorted.sortDesc
    },
    handleShow() {
      this.$store.commit('lwinDictionary/reset')
      this.$emit('show')
    },
    exitPopover() {
      this.$root.$emit('bv::hide::popover')
    }
  }
}
</script>
<style>
.t-lwin-lookup table {
  width: 1423px;
}

@media (min-width: 1630px) {
  .t-lwin-lookup .virtual-scroll__content {
    height: calc(100% - 19px);
  }
}
</style>

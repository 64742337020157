import { map, clone } from 'lodash'
export const state = () => ({
  sidebarItems: [
    {
      title: 'Quick Search',
      officeOnly: false,
      to: {
        name: 'quick-search'
      }
    },
    {
      title: 'Wine Browser',
      officeOnly: false,
      to: {
        name: 'wine-browser'
      }
    },
    {
      title: 'Purchases',
      officeOnly: false,
      usesPurchaseOrders: true,
      to: {
        name: 'purchases'
      }
    },
    {
      title: 'Sales',
      officeOnly: true,
      usesSalesOrders: true,
      to: {
        name: 'sales'
      }
    },
    {
      title: 'Web',
      officeOnly: true,
      class: '',
      usesWeb: true,
      to: {
        name: 'web-contacts'
      }
    },
    {
      title: 'Contacts',
      officeOnly: true,
      usesContact: true,
      to: {
        name: 'contacts'
      }
    },
    {
      title: 'Stock',
      officeOnly: false,
      usesWineCard: true,
      to: {
        name: 'stock-card'
      }
    },
    {
      title: 'Wine Card',
      officeOnly: false,
      usesWineCard: true,
      to: {
        name: 'wine-card'
      }
    },
    {
      title: 'Rotation',
      officeOnly: false,
      usesRotationCard: true,
      to: {
        name: 'rotation'
      }
    },
    {
      title: 'Deliveries',
      officeOnly: false,
      to: {
        name: 'deliveries'
      }
    },
    {
      title: 'Shipping',
      officeOnly: true,
      to: {
        name: 'shipping'
      }
    },
    {
      title: 'eTally',
      officeOnly: false,
      to: {
        name: 'etally'
      }
    },
    {
      title: 'Reports',
      officeOnly: true,
      to: {
        name: 'reports'
      }
    },
    {
      title: 'Reviews',
      officeOnly: true,
      to: {
        name: 'reviews'
      }
    },
    {
      title: 'Administration',
      officeOnly: true,
      to: {
        name: 'administration'
      }
    },
    {
      title: 'Reserves',
      officeOnly: false,
      usesReserves: true,
      class: 'reserves',
      to: {
        name: 'reserves'
      }
    },
    {
      title: 'Broking',
      officeOnly: false,
      usesBroking: true,
      class: 'reserves',
      to: {
        name: 'reserves-broking'
      }
    },
    {
      title: 'Storage',
      officeOnly: true,
      class: 'reserves',
      usesStorage: true,
      to: {
        name: 'reserves-storage'
      }
    }
  ],
  navbarItems: [],
  activeWineCardId: 0,
  activeContactId: 0,
  activeRotationNumber: null,
  activePOID: 0,
  activeSOID: 0,
  activeReserveWineCardId: null,
  activeReserveContactId: null,
  activeBillingRunId: null,
  activeBrokingContactId: null
})

export const getters = {
  getReserveContactId: state => {
    return state.activeReserveContactId
  },
  getReserveWineCardId: state => {
    return state.activeReserveWineCardId
  },
  getBillingRunId: state => {
    return state.activeBillingRunId
  },
  getBrokingContactId: state => {
    return state.activeBrokingContactId
  }
}

export const mutations = {
  clearNavbar(state) {
    state.navbarItems = []
  },
  setWebClass(state, className) {
    const idx = state.sidebarItems.findIndex(i => i.usesWeb)
    if (idx !== -1) {
      state.sidebarItems[idx].class = className
    }
  },
  appendNavbarButton(state, buttonOptions) {
    state.navbarItems.push(buttonOptions)
  },
  setNavbarDisabledState(state, args) {
    const idx = state.navbarItems[0].items.findIndex(i => i.label === args.for)
    if (idx !== -1) {
      state.navbarItems[0].items[idx].disabled = args.disabled
    }
  },
  setActiveWineCardId(state, wineCardId) {
    state.activeWineCardId = wineCardId
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesWineCard) {
        if (!menu.originalTo) {
          menu.originalTo = clone(menu.to)
        }
        menu.to = clone(menu.originalTo)
        if (wineCardId) {
          menu.to.name += '-id'
          menu.to.params = { id: wineCardId }
        }
      }
      return menu
    })
  },
  setActivePurchaseOrderId(state, POID) {
    if (POID === 0) {
      POID = null
    }
    if (typeof POID !== 'string' && POID !== null) {
      POID = String(POID)
    }
    state.activePOID = POID
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesPurchaseOrders) {
        if (!menu.originalTo) {
          menu.originalTo = clone(menu.to)
        }
        menu.to = clone(menu.originalTo)
        if (POID) {
          menu.to.name += '-id'
          menu.to.params = { id: POID }
        }
      }
      return menu
    })
  },
  setActiveSalesOrderId(state, SOID) {
    if (SOID === 0) {
      SOID = null
    }
    if (typeof SOID !== 'string' && SOID !== null) {
      SOID = String(SOID)
    }
    state.activeSOID = SOID
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesSalesOrders) {
        if (!menu.originalTo) {
          menu.originalTo = clone(menu.to)
        }
        menu.to = clone(menu.originalTo)
        if (SOID) {
          menu.to.name += '-id'
          menu.to.params = { id: SOID }
        }
      }
      return menu
    })
  },
  setActiveContactId(state, contactID) {
    if (contactID === 0) {
      contactID = null
    }
    if (typeof contactID !== 'string' && contactID !== null) {
      contactID = String(contactID)
    }
    state.activeContactId = contactID
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesContact) {
        if (!menu.originalTo) {
          menu.originalTo = clone(menu.to)
        }
        menu.to = clone(menu.originalTo)
        if (contactID) {
          menu.to.name += '-id'
          menu.to.params = { id: contactID }
        }
      }
      return menu
    })
  },
  setActiveRotationNumber(state, rotationNumber) {
    state.activeRotationNumber = rotationNumber
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesRotationCard) {
        if (!menu.originalTo) {
          menu.originalTo = clone(menu.to)
        }
        menu.to = clone(menu.originalTo)
        if (rotationNumber) {
          menu.to.name += '-id'
          menu.to.params = { id: rotationNumber }
        }
      }
      return menu
    })
  },
  setActiveReserveWineCardId(state, WineCardID) {
    if (WineCardID === 0) {
      WineCardID = null
    }
    if (typeof WineCardID !== 'string' && WineCardID !== null) {
      WineCardID = String(WineCardID)
    }
    state.activeReserveWineCardId = WineCardID
    state.activeReserveContactId = null
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesReserves) {
        if (!menu.originalTo) {
          menu.originalTo = { name: 'reserves' }
        }
        menu.to = clone(menu.originalTo)
        if (WineCardID) {
          menu.to.name += '-stock-card-wine-id'
          menu.to.params = { id: WineCardID }
        }
      }
      return menu
    })
  },
  setActiveReserveContactSearch(state) {
    state.activeReserveContactId = null
    state.activeReserveWineCardId = null
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesReserves) {
        if (!menu.originalTo) {
          menu.originalTo = { name: 'reserves' }
        }
        menu.to = clone(menu.originalTo)
        menu.to.name += '-stock-card-contact-search'
      }
      return menu
    })
  },
  setActiveReserveStockCard(state) {
    state.activeReserveContactId = null
    state.activeReserveWineCardId = null
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesReserves) {
        if (!menu.originalTo) {
          menu.originalTo = { name: 'reserves' }
        }
        menu.to = clone(menu.originalTo)
        menu.to.name += '-stock-card-wine-id'
      }
      return menu
    })
  },
  setActiveReserveContactId(state, ContactID) {
    if (ContactID === 0) {
      ContactID = null
    }
    if (typeof ContactID !== 'string' && ContactID !== null) {
      ContactID = String(ContactID)
    }
    state.activeReserveContactId = ContactID
    state.activeReserveWineCardId = null
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesReserves) {
        if (!menu.originalTo) {
          menu.originalTo = { name: 'reserves' }
        }
        menu.to = clone(menu.originalTo)
        if (ContactID) {
          menu.to.name += '-stock-card-contact-id'
          menu.to.params = { id: ContactID }
        }
      }
      return menu
    })
  },
  setActiveBillingRunId(state, BillingRunId) {
    if (BillingRunId === 0) {
      BillingRunId = null
    }
    if (typeof BillingRunId !== 'string' && BillingRunId !== null) {
      BillingRunId = String(BillingRunId)
    }
    state.activeBillingRunId = BillingRunId
    // state.activeReserveContactId = null
    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesStorage) {
        if (!menu.originalTo) {
          menu.originalTo = { name: 'reserves-storage' }
        }
        menu.to = clone(menu.originalTo)
        if (BillingRunId) {
          menu.to.name += '-id'
          menu.to.params = { id: BillingRunId }
        }
      }
      return menu
    })
  },
  setActiveBrokingContactId(state, ContactID) {
    if (ContactID === 0) {
      ContactID = null
    }
    if (typeof ContactID !== 'string' && ContactID !== null) {
      ContactID = String(ContactID)
    }
    state.activeBrokingContactId = ContactID

    state.sidebarItems = map(state.sidebarItems, menu => {
      if (menu.usesBroking) {
        if (!menu.originalTo) {
          menu.originalTo = { name: 'reserves-broking' }
        }
        menu.to = clone(menu.originalTo)
        if (ContactID) {
          menu.to.name += '-id'
          menu.to.params = { id: ContactID }
        }
      }
      return menu
    })
  }
}

import Entity from './entity'

export const fields = {
  WineNameID: {
    type: Number
  },
  Name: {
    type: String
  },
  AppellationID: {
    type: Number
  },
  AppellationName: {
    type: String
  },
  DutyTypeID: {
    type: Number
  },
  WineCode: {
    type: String
  },
  WineCardUsage: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  }
}

const WineName = new Entity('WineName', fields)

WineName.createQuery('search', 'wineName', fields)
WineName.createQuery('get', 'wineNameGet', fields)

WineName.createMutation(
  'insert',
  'wineNameAdd',
  Entity.onlyKeys(fields, ['Name', 'AppellationID', 'DutyTypeID', 'WineCode']),
  fields
)
WineName.createMutation(
  'update',
  'wineNameUpdate',
  Entity.allButKeys(fields, ['WineCardUsage', 'AppellationName']),
  Entity.onlyKeys(
    fields,
    ['Name', 'AppellationID', 'DutyTypeID', 'WineCode'],
    'AuditAmendedDate'
  )
)
WineName.createMutation(
  'delete',
  'wineNameDelete',
  {
    ID: fields.WineNameID,
    AuditAmendedDate: fields.AuditAmendedDate
  },
  []
)

WineName.on('post-query', wineName => {
  wineName.WineCardUsage =
    wineName.WineCardUsage === null ? 0 : wineName.WineCardUsage
})

export default WineName

<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :hide-footer="true"
    size="xl-alt"
    :title="title"
    body-class="px-2 py-0"
    @hide="checkForChanges"
  >
    <template v-slot:modal-header>
      {{ title }}
    </template>
    <div v-if="loading" class="text-center text-primary my-2">
      <b-spinner variant="primary" type="grow" label="Spinning" />
    </div>
    <template v-else>
      <b-row class="col__equalHeight">
        <b-col class="col-12 col-xxl-4">
          <b-row>
            <b-col class="col-12 col-xxl-12">
              <current-list-price
                :cost-price="item.average_cost"
                :selling-price="item.listPrice"
                :average-days-in-stock="item.average_days_in_stock"
                :promotion-date="item.promotion_date"
                :available-bottles="item.availableBottles"
                :promo-user-changed="promoUserChanged"
                :case-size="item.case_size"
                @change-list-price="updateListPrice"
                @change-promotional-date="updatePromotionalDate"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-12 col-xxl-8 d-flex flex-column">
          <b-row style="height: 100%">
            <b-col class="col-12 col-xxl-6">
              <last-purchase
                :last-purchase-price="parseFloat(item.last_purchase_price || 0)"
                :last-purchase-date="item.last_purchase_date || ''"
                :last-purchase-contact="item.last_purchase_contact || ''"
                class="mb-1 mt-xl-0"
              />
              <last-sale
                class="pt-3"
                :last-sale-price="parseFloat(item.last_price || 0)"
                :last-sale-date="item.last_order_date || ''"
                :last-sale-contact="item.last_sales_contact || ''"
              />
            </b-col>
            <b-col class="col-12 col-xxl-6 flex">
              <actions
                class="mt-xl-0"
                :has-changes="hasChanges"
                :is-valid="isValid"
                :item="item"
                @cancel="resetChanges"
                @ok="shown=false"
                @change="handleGetWineSearcher"
              />
              <div class="text-right">
                <empty-case-button class="mt-2 align-right ml-auto" :loading="wineCardLoading" :wine-card="wineCard" @click="$emit('empty-cases')">
                  Empty Cases Button.
                </empty-case-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-n2">
        <b-col class="col-12">
          <bid-offer
            @get-bid-offer="handleGetLivEx"
          />
        </b-col>
      </b-row>
      <b-row v-if="showBordeauxIndex">
        <b-col class="col-12">
          <bordeaux-index
            @get-bordeaux-index="handleGetBoardeauxIndex"
          />
        </b-col>
      </b-row>
      <b-row class="mt-n2">
        <b-col>
          <stock-table :list-price="listPrice" @get-wine-searcher="handleGetWineSearcher" />
        </b-col>
      </b-row>
    </template>
    <confirm-modal v-model="showConfirmation" @ok="emitChange" @cancel="handleCancelChange">
      This will change the Wine Card Price, are you sure?
    </confirm-modal>
  </b-modal>
</template>

<script>
import CurrentListPrice from './PriceLookup/CurrentListPrice'
import LastPurchase from './PriceLookup/LastPurchase'
import LastSale from './PriceLookup/LastSale'
import Actions from './PriceLookup/Actions'
import BordeauxIndex from './PriceLookup/BordeauxIndex'
import BidOffer from './PriceLookup/BidOffer'
import StockTable from './PriceLookup/StockTable'
import EmptyCaseButton from '~/components/Base/EmptyCaseButton'
import ConfirmModal from '~/components/Modal/Confirm'
import TitleFromWineCard from '~/mixins/modal/TitleFromWineCard'

export default {
  components: {
    CurrentListPrice,
    LastPurchase,
    LastSale,
    Actions,
    BordeauxIndex,
    BidOffer,
    StockTable,
    EmptyCaseButton,
    ConfirmModal
  },
  mixins: [TitleFromWineCard],
  props: {
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    wineCard: {
      type: Object,
      required: true
    },
    wineCardLoading: {
      type: Boolean,
      required: true
    },
    globals: {
      type: Object,
      required: true
    },
    globalLoading: {
      type: Boolean,
      required: true
    },
    promoUserChanged: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showWineCardInfo: true,
      changedListPrice: null,
      changedPromotionalDate: null,
      showConfirmation: false,
      isValid: false
    }
  },
  computed: {
    loading() {
      return (
        this.wineCardLoading || this.globalLoading || this.browserCardLoading
      )
    },
    browserCardLoading() {
      return this.$store.state.wineCard.activeCard.activeBrowserCardLoading
    },
    showBordeauxIndex() {
      return this.item.format.toUpperCase() === 'BOTTLE'
    },
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    hasChanges() {
      return (
        this.changedListPrice !== null || this.changedPromotionalDate !== null
      )
    },
    listPrice() {
      if (this.changedListPrice !== null) {
        return this.changedListPrice
      }
      return this.item.listPrice
    },
    promotionalDate() {
      if (this.changedPromotionalDate !== null) {
        return this.changedPromotionalDate
      }
      return this.item.promotion_date
    }
  },
  methods: {
    updateListPrice(value, isValid) {
      this.changedListPrice = value
      this.isValid = isValid
    },
    updatePromotionalDate(value, isValid) {
      this.changedPromotionalDate = value
      this.isValid = isValid
    },
    checkForChanges(evn) {
      if (this.hasChanges) {
        if (this.changedListPrice !== null) {
          evn.preventDefault()
          this.showConfirmation = true
          this.shown = true
        } else {
          this.emitChange()
        }
      }
    },
    emitChange() {
      this.showConfirmation = false
      if (this.changedListPrice !== null) {
        const changes = {
          listPrice: this.listPrice
        }
        this.$emit('change', this.item, changes)
      }
      if (this.changedPromotionalDate !== null) {
        this.$emit('change-promo-date', this.item, this.changedPromotionalDate)
      }
      this.resetChanges()
    },
    resetChanges() {
      this.changedListPrice = null
      this.changedPromotionalDate = null
      this.shown = false
    },
    handleGetBoardeauxIndex() {
      this.$emit('get-bordeaux-index', this.item)
    },
    handleGetLivEx() {
      this.$emit('get-liv-ex', this.item)
    },
    handleGetWineSearcher() {
      this.$emit(
        'get-wine-searcher',
        this.item,
        this.$store.state.user.settings.showAllSellers,
        this.$store.state.user.settings.showOtherCaseSizes
      )
    },
    handleCancelChange() {
      this.showConfirmation = false
      this.resetChanges()
    }
  }
}
</script>

<template>
  <div>
    <group-box class="h-100" body-class="d-flex h-100 flex-column">
      <template slot="header">
        <b-spinner v-if="loading" type="grow" label="Loading..." small />
        Content
      </template>
      <b-row>
        <b-col cols="12">
          <div class="d-flex m-0 p-0">
            <div class="flex-grow-1">
              <b-form v-if="canAttach || canEditCustomTemplate || canEditDefaultTemplate" inline class="justify-content-left flex-nowrap">
                <b-form-group class="mr-5">
                  <b-checkbox v-if="canAttach" :checked="canPDFAttach" disabled>
                    PDF Attached
                  </b-checkbox>
                </b-form-group>

                <b-form-group class="mr-5">
                  <b-checkbox v-if="canAttach" :checked="canXLSAttach" disabled>
                    XLS Attached
                  </b-checkbox>
                </b-form-group>

                <b-form-group>
                  <b-checkbox v-if="canAttach" :checked="canCSVAttach" disabled>
                    CSV Attached
                  </b-checkbox>
                </b-form-group>
              </b-form>
            </div>
            <div class="flex-shrink-1">
              <b-form v-if="canEditCustomTemplate" inline>
                <b-form-radio v-model="emailContent" class="mr-2" value="defaultText">
                  Default Text
                </b-form-radio>
                <b-button class="wv-btn btn-sm mr-2" :disabled="emailContent !== 'defaultText'" @click="showEmailDefaultTextModal">
                  Edit
                </b-button>
              </b-form>
            </div>
            <div class="flex-shrink-1">
              <b-form v-if="canEditDefaultTemplate" inline>
                <b-form-radio v-model="emailContent" class="mr-2" value="customText">
                  Custom Text
                </b-form-radio>
                <b-button class="wv-btn btn-sm" :disabled="emailContent !== 'customText'" @click="showEmailDefaultTextModal">
                  Edit
                </b-button>
              </b-form>
            </div>
          </div>

          <b-form inline>
            <b-form-group
              label="Subject"
              label-for="input-934723"
              label-class="mr-3 subject-input-group-label"
              class="w-100"
            >
              <b-input-group size="sm">
                <b-input v-model="subject" class="w-100" :disabled="loading" />
                <b-input-group-append v-if="hasCustomSubject">
                  <b-button variant="danger" @click.prevent="clearCustomSubject">
                    X
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mx-1 p-3 flex-grow-1 email-content">
        <b-col>
          <html-editor v-if="canEditBody" v-model="body">
            <template slot="menu">
              <button
                v-if="hasCustomBody"
                class="menubar__button"
                @click="clearCustomBody"
              >
                Reset Changes
              </button>
            </template>
          </html-editor>
          <iframe v-else ref="emailContents" :srcdoc="body" class="h-100 w-100 border-0 p-0 m-0" />
        </b-col>
      </b-row>
    </group-box>
    <email-default-text-modal
      v-model="boolEmailDefaultTextModal"
      :text="defaultTemplate"
      :saving="savingTemplate"
      @save="handleSaveDefaultTemplate"
    />
  </div>
</template>

<script>
import EmailDefaultTextModal from '../ExportEmailDefaultTextModal'
import GroupBox from '~/components/Base/GroupBox'
import HtmlEditor from '../../Base/HtmlEditor'
export default {
  components: {
    EmailDefaultTextModal,
    GroupBox,
    HtmlEditor
  },
  data() {
    return {
      emailContent: 'defaultText',
      customTemplate: '',
      boolEmailDefaultTextModal: false
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    loading() {
      return this.$store.state.export.email.loadingEmailContent
    },
    customSubject() {
      if (this.$store.state.export.email.options) {
        const subject = this.$store.state.export.email.options.Subject
        if (subject.length > 0) {
          return subject
        }
      }
      return ''
    },
    customBody() {
      if (this.$store.state.export.email.options) {
        const body = this.$store.state.export.email.options.Body
        if (body.length > 0) {
          return body
        }
      }
      return ''
    },
    templateCode() {
      if (this.$store.state.export.email.emailContent) {
        return this.$store.state.export.email.emailContent.TemplateCode
      }
      return ''
    },
    defaultTemplate() {
      if (this.emailContent === 'customText') {
        if (this.customTemplate.length > 0) {
          return this.customTemplate
        }
      }
      if (this.$store.state.export.email.emailContent) {
        return this.$store.state.export.email.emailContent.DefaultTemplate
      }
      return ''
    },
    hasCustomSubject() {
      return this.customSubject.length > 0
    },
    hasCustomBody() {
      return this.customBody.length > 0
    },
    subject: {
      get() {
        if (this.hasCustomSubject) {
          return this.customSubject
        }
        if (this.$store.state.export.email.emailContent) {
          return this.$store.state.export.email.emailContent.Subject
        }
        return ''
      },
      set(Subject) {
        this.$store.commit('export/email/mergeOptions', { Subject })
      }
    },
    body: {
      get() {
        if (this.hasCustomBody) {
          return this.customBody
        }
        if (this.$store.state.export.email.emailContent) {
          return this.$store.state.export.email.emailContent.Body
        }
        return ''
      },
      set(Body) {
        this.$store.commit('export/email/mergeOptions', { Body })
      }
    },
    canPDFAttach() {
      if (this.$store.state.export.email.emailContent) {
        return this.$store.state.export.email.emailContent.CanPDFAttach
      }
      return false
    },
    canXLSAttach() {
      if (this.$store.state.export.email.emailContent) {
        return this.$store.state.export.email.emailContent.CanXLSAttach
      }
      return false
    },
    canCSVAttach() {
      if (this.$store.state.export.email.emailContent) {
        return this.$store.state.export.email.emailContent.CanCSVAttach
      }
      return false
    },
    canAttach() {
      return this.canCSVAttach || this.canXLSAttach || this.canPDFAttach
    },
    canEditDefaultTemplate() {
      if (this.$store.state.export.email.emailContent) {
        return this.$store.state.export.email.emailContent
          .CanEditDefaultTemplate
      }
      return false
    },
    canEditCustomTemplate() {
      if (this.$store.state.export.email.emailContent) {
        return this.$store.state.export.email.emailContent.CanEditCustomTemplate
      }
      return false
    },
    canEditBody() {
      if (this.$store.state.export.email.emailContent) {
        return this.$store.state.export.email.emailContent.CanEditBody
      }
      return false
    },
    templateAuditAmendedDate() {
      if (this.$store.state.export.email.emailContent) {
        return this.$store.state.export.email.emailContent
          .DefaultTemplateAuditAmendedDate
      }
      return null
    },
    savingTemplate() {
      return this.$store.state.export.email.savingTextTemplate
    }
  },
  watch: {
    emailContent(value) {
      if (this.emailContent === 'defaultText') {
        this.$store.commit('export/email/mergeOptions', {
          CustomTemplate: ''
        })
      } else {
        this.$store.commit('export/email/mergeOptions', {
          CustomTemplate: this.customTemplate
        })
      }
      this.$emit('reload')
    }
  },
  methods: {
    showEmailDefaultTextModal() {
      this.boolEmailDefaultTextModal = true
    },
    clearCustomBody() {
      this.$store.commit('export/email/mergeOptions', { Body: '' })
    },
    clearCustomSubject() {
      this.$store.commit('export/email/mergeOptions', { Subject: '' })
    },
    handleSaveDefaultTemplate(template) {
      if (this.emailContent === 'defaultText') {
        this.saveDefaultTemplate(template)
      } else {
        this.customTemplate = template.Text
        this.$store.commit('export/email/mergeOptions', {
          CustomTemplate: template.Text
        })
        this.boolEmailDefaultTextModal = false
        this.$emit('reload')
      }
    },
    async saveDefaultTemplate(template) {
      try {
        await this.$store.dispatch('export/email/updateDefaultTemplate', {
          Text: template.Text,
          TextTemplateCode: this.templateCode,
          AuditAmendedDate: this.templateAuditAmendedDate
        })
        this.$emit('reload')
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.boolEmailDefaultTextModal = false
      }
    }
  }
}
</script>

<style>
.subject-input-group-label + div {
  width: calc(100% - 75px);
}
</style>

<style scoped>
.mb-1plus3 {
  margin-bottom: 7px;
}

.right-text-form {
  margin-bottom: 10px;
}

.right-text-form .custom-radio {
  width: 115px;
}

.right-text-form .btn {
  width: 120px;
}
</style>

import { integer } from 'vuelidate/lib/validators'

export const validateMultipleEmailsWithNames = value => {
  if (value === undefined || value === null) {
    return false
  }
  if (typeof value.split !== 'function') {
    return false
  }
  const emails = value.split(/[,;\r\n]+/)
  let validEmails = 0
  for (let i = 0; i < emails.length; i++) {
    if (emails[i].trim().length > 0) {
      if (isValidEmailWithName(emails[i]) === false) {
        return false
      } else {
        validEmails++
      }
    }
  }
  return validEmails > 0
}

export const isValidEmailWithName = email => {
  if (email === undefined || email === null) {
    return false
  }
  const pos1 = email.indexOf('(')
  if (pos1 >= 0) {
    email = email.substring(0, pos1)
  }
  return isEmail(email.trim())
}

export const wineVintageAllowNVValidator = value => {
  if (value === undefined || value === null) {
    return false
  }
  if (typeof value === 'string' && value.toUpperCase() === 'NV') {
    return true
  }
  const numericRegex = /^[0-9]{1,4}$/
  const date = new Date()
  return (
    numericRegex.test(value) &&
    parseInt(value) >= 0 &&
    parseInt(value) < date.getFullYear()
  )
}

export const isEmail = value => {
  const emailRegex = /(^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$)/
  return emailRegex.test(value)
}
export const dutyTypeValidForCategory = dutyTypeId => categoryId => {
  categoryId = parseInt(categoryId)
  dutyTypeId = parseInt(dutyTypeId)
  if (isNaN(categoryId) || isNaN(dutyTypeId)) {
    return false
  }
  if (categoryId === 9 && dutyTypeId !== 2) {
    return false
  }
  // Allow any category for spirits
  if (dutyTypeId === 10) {
    return true
  }
  if ([7, 10].indexOf(categoryId) >= 0 && dutyTypeId !== 3) {
    return false
  }
  if ([7, 9, 10].indexOf(categoryId) < 0 && dutyTypeId !== 1) {
    return false
  }
  return true
}
export const maxDecimalPlaces = decimals => value => {
  if (value === null || value === undefined || value.length === 0) {
    return true
  }
  const floatPrice = parseFloat(value)
  const floatPrice2 =
    Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  return floatPrice === floatPrice2
}

export const isRobertParkerScore = allowBlank => value => {
  if (value === undefined || value === null) {
    value = ''
  } else if (typeof value !== 'string') {
    value = '' + value
  }
  value = value.trim()
  if (value.length === 0) {
    return allowBlank
  }
  if (value === '-') {
    return true
  }
  if (value === '?') {
    return true
  }

  if (value.substring(value.length - 1, value.length) === '?') {
    value = value.substring(0, value.length - 1)
  }

  const isPlus = value.substring(value.length - 1, value.length) === '+'
  if (isPlus) {
    value = value.substring(0, value.length - 1)
  }

  const array = value.split('-')
  if (array.length < 1 || array.length > 2) {
    return false
  }

  if (array.length > 1 && isPlus) {
    return false
  }
  for (let i = 0; i < array.length; i++) {
    if (integer(array[i]) === false) {
      return false
    }
    const val = parseInt(array[i])
    if (val < 1 || val > 100) {
      return false
    }
  }

  if (array.length === 1) {
    if (parseInt(array[0]) === 100 && isPlus) {
      return false
    }
  } else if (parseInt(array[0]) >= parseInt(array[1])) {
    return false
  }
  return true
}

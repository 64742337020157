import Entity from '../entity'

const fields = {
  ID: {
    type: Number
  }
}

const ETallyCardLineImage = new Entity('ETallyCardLineImage', fields)

ETallyCardLineImage.createQuery(
  'get',
  'eTallyLineImagesGet',
  {
    Images: {
      type: Array,
      fields: {
        ETCLIID: {
          type: Number
        },
        UUID: {
          type: String
        },
        Size: {
          type: Number
        },
        Width: {
          type: Number
        },
        Height: {
          type: Number
        },
        Status: {
          type: String
        },
        Filename: {
          type: String
        },
        CaseNumber: {
          type: Number
        }
      }
    }
  },
  {
    ETCLID: {
      type: Number
    }
  }
)

ETallyCardLineImage.createMutation(
  'delete',
  'etallyLineImageDelete',
  {
    UUID: {
      type: String
    }
  },
  {
    ETCLIID: {
      type: Number
    },
    UUID: {
      type: String
    },
    Size: {
      type: Number
    },
    Width: {
      type: Number
    },
    Height: {
      type: Number
    },
    Status: {
      type: String
    },
    Filename: {
      type: String
    },
    CaseNumber: {
      type: Number
    }
  }
)

ETallyCardLineImage.createMutation(
  'update',
  'etallyLineImageUpdate',
  {
    UUID: {
      type: String
    },
    CaseNumber: {
      type: Number
    }
  },
  {
    ETCLIID: {
      type: Number
    },
    UUID: {
      type: String
    },
    Size: {
      type: Number
    },
    Width: {
      type: Number
    },
    Height: {
      type: Number
    },
    Status: {
      type: String
    },
    Filename: {
      type: String
    },
    CaseNumber: {
      type: Number
    }
  }
)

export default ETallyCardLineImage

<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    footer-class="d-none"
    :hide-header-close="true"
    size="xl"
    @ok="$emit('ok')"
  >
    <template v-slot:modal-header>
      Export Settings
    </template>
    <template v-slot:default="{ ok }">
      <group-box class="my-3">
        <template slot="header">
          Report Presets
        </template>
        <report-preset
          @change-usage-codes="changedUsageCodes = $event"
          @change-duty-status="changedDutyStatus = $event"
          @change-margin-check="changedMarginCheck = $event"
          @change-packaging="changedPackaging = $event"
          @change-loose-bottle-check="changedLooseBottleCheck = $event"
          @change-grouping="changedGrouping = $event"
        />
      </group-box>

      <group-box class="my-3">
        <template slot="header">
          Stock Filters
        </template>
        <b-row class="mt-2">
          <b-col>
            <usage v-model="changedUsageCodes" />
          </b-col>
          <b-col>
            <duty-status v-model="changedDutyStatus" />
          </b-col>
          <b-col>
            <margin-check v-model="changedMarginCheck" />
          </b-col>
          <b-col>
            <packaging v-model="changedPackaging" :loose-override="filters.looseBottlesOnly" />
          </b-col>
          <b-col>
            <loose-bottle-check v-model="changedLooseBottleCheck" />
          </b-col>
        </b-row>
      </group-box>

      <group-box class="mt-4 mb-0">
        <template slot="header">
          Display Options
        </template>
        <b-row class="mt-2">
          <b-col class="col col-xxl-10">
            <b-row>
              <b-col class="col col-xxl-3">
                <scores v-model="changedScore" />
              </b-col>
              <b-col class="col col-xxl-3">
                <currencies v-model="changeCurrencies" />
              </b-col>
              <b-col class="col col-xxl-3">
                <hyperlinks-in-email v-model="changedHyperlinks" />
              </b-col>
              <b-col class="col col-xxl-3">
                <grouping v-model="changedGrouping" :loose-override="filters.looseBottlesOnly" />
              </b-col>
              <b-col />
            </b-row>
          </b-col>

          <b-col class="col col-xxl-2 d-flex flex-column justify-content-end align-items-end">
            <b-button class="btn wv-btn" style="width: 80px;" @click="ok()">
              OK
            </b-button>
            <b-button class="btn wv-btn mt-3" style="width: 80px;" @click="shown=false">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </group-box>
    </template>
    <template v-slot:modal-footer>
      &nbsp;
    </template>
  </b-modal>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import Usage from './Export/Settings/Usage'
import DutyStatus from './Export/Settings/DutyStatus'
import MarginCheck from './Export/Settings/MarginCheck'
import Packaging from './Export/Settings/Packaging'
import LooseBottleCheck from './Export/Settings/LooseBottleCheck'
import Scores from './Export/Settings/Scores'
import Currencies from './Export/Settings/Currencies'
import HyperlinksInEmail from './Export/Settings/HyperlinksInEmail'
import Grouping from './Export/Settings/Grouping'
import ReportPreset from './Export/Settings/ReportPreset'

export default {
  components: {
    GroupBox,
    Usage,
    DutyStatus,
    MarginCheck,
    Packaging,
    LooseBottleCheck,
    Scores,
    Currencies,
    HyperlinksInEmail,
    Grouping,
    ReportPreset
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    usageCodes: {
      type: Array,
      required: true
    },
    dutyStatus: {
      type: String,
      required: true
    },
    marginCheck: {
      type: String,
      required: true
    },
    packaging: {
      type: String,
      required: true
    },
    looseBottleCheck: {
      type: String,
      required: true
    },
    scores: {
      type: String,
      required: true
    },
    grouping: {
      type: String,
      required: true
    },
    currencies: {
      type: Array,
      required: true
    },
    hyperLinksInEmail: {
      type: Array,
      required: true
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    changedUsageCodes: {
      get() {
        return this.usageCodes
      },
      set(value) {
        this.$emit('change-usage-codes', value)
      }
    },
    changedGrouping: {
      get() {
        return this.grouping
      },
      set(value) {
        if (this.filters.looseBottlesOnly) {
          value = 'BOTH'
        }
        this.$emit('change-grouping', value)
      }
    },
    changedDutyStatus: {
      get() {
        return this.dutyStatus
      },
      set(value) {
        this.$emit('change-duty-status', value)
      }
    },
    changedMarginCheck: {
      get() {
        return this.marginCheck
      },
      set(value) {
        this.$emit('change-margin-check', value)
      }
    },
    changedPackaging: {
      get() {
        return this.packaging
      },
      set(value) {
        if (this.filters.looseBottlesOnly) {
          value = 'allStock'
        }
        this.$emit('change-packaging', value)
      }
    },
    changedLooseBottleCheck: {
      get() {
        return this.looseBottleCheck
      },
      set(value) {
        this.$emit('change-loose-bottle-check', value)
      }
    },
    changedScore: {
      get() {
        return this.scores
      },
      set(value) {
        this.$emit('change-scores', value)
      }
    },
    changeCurrencies: {
      get() {
        return this.currencies
      },
      set(value) {
        this.$emit('change-currencies', value)
      }
    },
    changedHyperlinks: {
      get() {
        return this.hyperLinksInEmail
      },
      set(value) {
        this.$emit('change-hyper-links-in-email', value)
      }
    },
    filters() {
      return this.$store.getters['wineBrowser/filters/searchParameters']
    }
  }
}
</script>

export default {
  computed: {
    title() {
      if (!this.item && this.titleSuffix) {
        return this.titleSuffix
      }
      if (!this.item) {
        return ''
      }
      const wineName = this.item.ComputedWineName
        ? this.item.ComputedWineName
        : this.item.wine_name
      let title = wineName + ' (' + this.item.format + ')'
      if (this.item.year && this.item.year.length !== 0) {
        title = this.item.year + ': ' + title
      }
      if (this.titleSuffix) {
        title += ' - ' + this.titleSuffix
      }
      return title
    }
  }
}

<template>
  <loose-bottles-modal
    v-if="activeCard"
    v-model="showLooseBottlesModal"
    :item="activeCard"
    :wine-card-loading="wineCardLoading"
    :wine-card="wineCard"
    :globals="globals"
    :global-loading="globalLoading"
    :promo-user-changed="promoUserChanged"
    :purchases="purchases"
    :loading-purchases="loadingPurchases"
    @empty-cases="handleEmptyCase"
    @change="updateWineCard"
    @change-promo-date="setPromoDate"
    @get-bordeaux-index="handleGetBoardeauxIndex"
    @get-liv-ex="handleGetLivEx"
    @get-wine-searcher="handleGetWineSearcher"
    @click-rotation-number="clickRotationNumber"
  />
</template>

<script>
import LooseBottlesModal from '~/components/Modal/LooseBottles'
import FanOutToSearchStoresMixin from '~/mixins/winebrowser/FanOutToSearchStores'
import ChangesStore from '~/mixins/winebrowser/ChangesStore'
import { mapState } from 'vuex'

export default {
  components: {
    LooseBottlesModal
  },
  mixins: [FanOutToSearchStoresMixin, ChangesStore],
  computed: {
    showLooseBottlesModal: {
      get() {
        return this.$store.state.modal.showLooseBottlesModal
      },
      set(value) {
        this.$store.commit('modal/setShowLooseBottlesModal', value)
      }
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeBrowserCard
    },
    wineCardLoading() {
      return this.$store.state.wineCard.activeCard.loading
    },
    wineCard() {
      return this.$store.state.wineCard.activeCard.activeCard || {}
    },
    globals() {
      return this.$store.state.global.data
    },
    globalLoading() {
      return this.$store.state.global.loading
    },
    promoUserChanged() {
      const promoKey = this.getSearchStoreKey('wineCards/promotionSelected')
      return this.$store.getters[promoKey](this.activeCard.id)
    },
    ...mapState('stockCard/stockCard', {
      purchases: state => state.purchases,
      loadingPurchases: state => state.loadingPurchases
    })
  },
  watch: {
    activeCard(item) {
      if (!this.showLooseBottlesModal) return
      if (item) {
        this.$store.dispatch('wineCard/activeCard/getActiveCard', item.id)
        this.getPurchases(item)
      }
    }
  },
  mounted() {
    // NOOP
  },
  methods: {
    handleEmptyCase() {
      this.$store.commit('modal/setShowEmptyCaseModal', true)
    },
    async handleGetBoardeauxIndex(item) {
      try {
        await this.$store.dispatch(
          'wineCard/activeCard/getBordeauxIndexPrices',
          {
            WineCardID: item.id
          }
        )
      } catch (e) {
        this.$notifyError(e)
      }
    },
    async handleGetLivEx(item) {
      try {
        await this.$store.dispatch('wineCard/activeCard/getBidOffer', {
          WineCardID: item.id
        })
      } catch (e) {
        this.$notifyError(e)
      }
    },
    async handleGetWineSearcher(item, ShowAllSellers, ShowOtherCaseSizes) {
      try {
        await this.$store.dispatch(
          'wineCard/activeCard/getWineSearcherPrices',
          {
            WineCardID: item.id,
            ShowAllSellers,
            ShowOtherCaseSizes,
            BottleSize: 'B' // Bottles
          }
        )
      } catch (e) {
        // Noop
      }
    },
    async updateWineCard(item, updateDetails) {
      try {
        await this.saveWineCardAndSetActive({
          id: item.id,
          updatedCardDetails: {
            ...updateDetails,
            auditAmendedDate: item.auditAmendedDate
          }
        })
        this.$notify.success('Wine Card Updated updated successfully')
      } catch (e) {
        this.$notifyError(e.message)
      }
    },
    getPurchases(item) {
      if (item) {
        this.$store.dispatch('stockCard/stockCard/getPurchases', {
          id: item.id,
          bottleThreshold: 0,
          soldStock: 0,
          salesOrderLineId: 0,
          rotationNumber: '',
          includeReserves: false,
          looseOnly: false // Apparently show everything!
        })
      }
    },
    clickRotationNumber(item) {
      this.showLooseBottlesModal = false
      this.$store.dispatch(
        'rotationCard/rotationCard/loadFullRotationCardByNumber',
        item.RotationNumber
      )
      this.$router.push({
        path: '/rotation/' + encodeURIComponent(item.RotationNumber)
      })
    },
    clickContact(item) {
      this.showLooseBottlesModal = false
      this.$router.push({ name: 'contacts-id', params: { id: item.ContactID } })
    },
    clickPO(item) {
      this.showLooseBottlesModal = false
      this.$router.push({
        name: 'purchases-id',
        params: {
          id: String(item.POID),
          polid: item.POLID
        }
      })
    }
  }
}
</script>

<template>
  <div v-if="shown">
    <data-modal
      ref="dataModal"
      v-model="shown"
      heading="Wine Name Selection"
      search-placeholder="Wine Name Filter ..."
      primary-key="WineNameID"
      filter-key="Name"
      :loading="wineNamesLoading || dutyTypesLoading"
      :fields="fields"
      :items="displayItems"
      :selected="getActiveWineName"
      :delete-disabled="!isActiveItemDeletable"
      :deleting="deleting"
      scrollbar-class="t-wine-name"
      @shown="refresh"
      @new="toggleWineName"
      @edit="toggleEditWineName"
      @view="toggleViewWineName"
      @delete="toggleDeleteWineName"
      @selected="rowSelected"
      @dbl-click="rowDoubleClicked"
      @ok="handleOK"
    />
    <new-wine-name-modal
      v-model="boolNewWineNameModal"
      :duty-types-prop="getDutyTypeList"
      @saved="handleNewSave"
    />
    <edit-wine-name-modal
      v-if="getActiveWineName && !dutyTypesLoading"
      v-model="boolEditWineNameModal"
      :wine-name="getActiveWineName"
      :duty-types-prop="getDutyTypeList"
      :loading-prop="wineNameLoading"
    />
    <view-wine-name-modal
      v-if="getActiveWineName"
      v-model="boolViewWineNameModal"
      :duty-types-prop="getDutyTypeList"
      :loading-prop="wineNameLoading"
      :wine-name="getActiveWineName"
    />
    <confirm-modal
      :value="boolConfirmDeleteModal"
      @ok="deleteWineNameConfirm"
      @cancel="deleteWineNameCancel"
      @change="deleteWineNameChange"
    >
      Are you sure you want to delete the selected Wine name?
    </confirm-modal>
  </div>
</template>

<script>
import NewWineNameModal from '~/components/Modal/Administration/NewWineNameModal'
import EditWineNameModal from '~/components/Modal/Administration/EditWineNameModal'
import ViewWineNameModal from '~/components/Modal/Administration/ViewWineNameModal'
import { mapGetters, mapState } from 'vuex'
import DataModal from '../../Administration/DataModal'
import ConfirmModal from '~/components/Modal/Confirm'

export default {
  components: {
    NewWineNameModal,
    EditWineNameModal,
    ViewWineNameModal,
    DataModal,
    ConfirmModal
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    dblClickEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      boolNewWineNameModal: false,
      boolEditWineNameModal: false,
      boolViewWineNameModal: false,
      boolConfirmDeleteModal: false,
      deleting: false,
      fields: {
        Name: {
          label: 'Wine Name',
          sortable: true
        },
        AppellationName: {
          label: 'Appellation',
          sortable: true,
          width: '150px'
        },
        DutyTypeName: {
          label: 'Duty Type',
          sortable: true,
          width: '150px'
        },
        WineCardUsage: {
          label: 'Wine Card Usage',
          sortable: true,
          tdClass: 'text-right',
          width: '100px'
        }
      }
    }
  },
  computed: {
    ...mapGetters('administration/winenames', [
      'getActiveWineName',
      'getWineNameList'
    ]),
    ...mapState('administration/winenames', {
      wineNamesLoading: state => state.loading,
      wineNameLoading: state => state.wineNameLoading
    }),
    ...mapGetters('administration/dutytypes', [
      'getDutyTypeList',
      'getDutyTypeNameFromID'
    ]),
    ...mapState('administration/dutytypes', {
      dutyTypesLoading: state => state.loading
    }),
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    displayItems() {
      return this.getWineNameList.map(wn => {
        wn.DutyTypeName = this.getDutyTypeNameFromID(wn.DutyTypeID)
        return wn
      })
    },
    isActiveItemDeletable() {
      return (
        this.getActiveWineName && this.getActiveWineName.WineCardUsage === 0
      )
    }
  },
  methods: {
    requestPage(page) {
      this.page = page
    },
    handleSortChanged(sorted) {
      this.sortBy = sorted.sortBy
      this.sortDesc = sorted.sortDesc
    },
    toggleWineName() {
      this.boolNewWineNameModal = !this.boolNewWineNameModal
    },
    toggleEditWineName() {
      this.boolEditWineNameModal = !this.boolEditWineNameModal
    },
    toggleViewWineName() {
      this.boolViewWineNameModal = !this.boolViewWineNameModal
    },
    toggleDeleteWineName() {
      this.boolConfirmDeleteModal = !this.boolConfirmDeleteModal
    },
    rowSelected(item) {
      this.$store.dispatch('administration/winenames/setActiveWineName', item)
    },
    handleNewSave(item) {
      this.$refs.dataModal.clearSearchMethod()
      this.rowSelected(item)
    },
    rowDoubleClicked(item) {
      this.$emit('selected', item)
      if (this.dblClickEdit) {
        this.toggleEditWineName()
      }
    },
    refresh() {
      this.$store.dispatch('administration/winenames/loadList')
      this.$store.dispatch('administration/dutytypes/loadList')
      this.$store.dispatch('administration/appellations/resetActiveAppellation')
      this.$emit('shown')
    },
    updateWineName(wineName) {
      this.$store.dispatch('administration/winenames/updateWineName', wineName)
    },
    async deleteWineNameConfirm() {
      try {
        this.deleting = true
        this.boolConfirmDeleteModal = false
        const wineNameID = this.getActiveWineName.WineNameID
        await this.$store.dispatch(
          'administration/winenames/deleteWineName',
          this.getActiveWineName
        )
        this.$refs.dataModal.clearSearchMethod()
        this.$notify.success('Wine Name was deleted')
        this.$emit('delete-wine-name', wineNameID)
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.deleting = false
      }
    },
    deleteWineNameCancel() {
      this.boolConfirmDeleteModal = false
    },
    deleteWineNameChange() {
      this.boolConfirmDeleteModal = false
    },
    handleOK(item) {
      if (item) {
        this.$emit('selected', item)
      }
      this.$emit('ok', item)
      this.shown = false
    }
  }
}
</script>

<style>
@media (min-width: 1630px) {
  .t-wine-name .virtual-scroll__content {
    height: 100%;
  }
}
</style>

import Entity from './entity'

const scopePermissionFields = {
  Scopes: {
    type: Array,
    fields: {
      Key: {
        type: String
      },
      Description: {
        type: String
      },
      Enabled: {
        type: Boolean
      }
    }
  },
  Permissions: {
    type: Array,
    fields: {
      Key: {
        type: String
      },
      Description: {
        type: String
      },
      Enabled: {
        type: Boolean
      }
    }
  }
}

const fields = {
  OAuthClientID: {
    type: Number
  },
  Name: {
    type: String
  },
  ClientID: {
    type: String
  },
  ClientSecret: {
    type: String
  },
  Enabled: {
    type: Boolean
  },
  AuditAmendedDate: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  ...scopePermissionFields
}

const OAuthClients = new Entity('OAuthClients', fields)
OAuthClients.createQuery(
  'getAll',
  'oAuthClientGetAll',
  Entity.allButKeys(fields, ['ClientSecret'])
)

OAuthClients.createQuery(
  'getPermissions',
  'oAuthClientPermissions',
  scopePermissionFields
)

OAuthClients.createMutation(
  'delete',
  'oAuthClientDelete',
  {
    OAuthClientID: {
      type: Number
    }
  },
  {}
)

OAuthClients.createMutation(
  'add',
  'oAuthClientAdd',
  Entity.onlyKeys(fields, ['Name', 'Enabled', 'Scopes', 'Permissions']),
  fields
)

OAuthClients.createMutation(
  'update',
  'oAuthClientUpdate',
  Entity.onlyKeys(fields, [
    'Name',
    'Enabled',
    'OAuthClientID',
    'Scopes',
    'Permissions'
  ]),
  Entity.allButKeys(fields, ['ClientSecret'])
)

OAuthClients.createMutation(
  'changeSecret',
  'oAuthClientChangeSecret',
  {
    OAuthClientID: {
      type: Number
    },
    RevokeAccessTokens: {
      type: Number
    }
  },
  fields
)

export default OAuthClients

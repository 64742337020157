import { client } from './graphQLInstance'
import User from '~/models/user'

export const getUsersList = async activeOnly => {
  const result = await client.query({
    query: User.query('get')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[User.queries.get.name]
}

export const getUserOptions = async () => {
  const result = await client.query({
    query: User.query('getOptions')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[User.queries.getOptions.name]
}

export const userAdd = async args => {
  const result = await client.query({
    query: User.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[User.mutations.insert.name]
}

export const userUpdate = async args => {
  const result = await client.query({
    query: User.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[User.mutations.update.name]
}

export const userDelete = async args => {
  const result = await client.query({
    query: User.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.userDelete
}

import Entity from './entity'
import PurchaseOrderTransaction from './purchaseordertransaction'
import purchaseOrderFields from './fields/purchaseOrder'
import { fields as allocField } from './purchaseorderallocation'

const fields = {
  POLID: {
    type: Number
  },
  POID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  FormatID: {
    type: Number
  },
  Bottles: {
    type: Number
  },
  Price: {
    type: Number
  },
  ReservePrice: {
    type: Number
  },
  DutyStatusID: {
    type: Number
  },
  IsVAT: {
    type: Boolean
  },
  PhotoRequired: {
    type: Boolean
  },
  UseEmptyOWC: {
    type: Boolean
  },
  WineUsageCodeID: {
    type: Number
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedBy: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  SOORotationNumber: {
    type: String
  },
  UsageCode: {
    type: String
  },
  WineDescription: {
    type: String
  },
  StockTotalBottles: {
    type: Number
  },
  AllocatedTotalBottles: {
    type: Number
  },
  UnallocatedTotalBottles: {
    type: Number
  },
  SageUpdatedDate: {
    type: Number
  },
  SageUpdatedBy: {
    type: Number
  },
  AuditAmendedByUserName: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  SalesAllocationBottles: {
    type: Number
  },
  TransactionTotalBottles: {
    type: Number
  },
  Transaction: {
    type: Object,
    fields: PurchaseOrderTransaction.fields
  },
  PurchaseOrder: {
    type: Object,
    fields: purchaseOrderFields
  },
  Allocations: {
    type: Array,
    fields: allocField
  }
}
const PurchaseOrderLine = new Entity('PurchaseOrderLine', fields)

PurchaseOrderLine.createMutation(
  'add',
  'purchaseOrderLineAdd',
  {
    ...Entity.onlyKeys(fields, [
      'POID',
      'WineCardID',
      'FormatID',
      'Bottles',
      'Price',
      'WineUsageCodeID',
      'PhotoRequired',
      'UseEmptyOWC'
    ]),
    AsDutyPaid: {
      type: Boolean
    },
    OriginalPackaging: {
      type: Boolean
    },
    OrigPkgCaseSize: {
      type: Number
    }
  },
  fields
)

PurchaseOrderLine.createMutation(
  'update',
  'purchaseOrderLineUpdate',
  {
    ...Entity.onlyKeys(fields, [
      'POLID',
      'POID',
      'WineCardID',
      'FormatID',
      'Bottles',
      'Price',
      'ReservePrice',
      'DutyStatusID',
      'IsVAT',
      'PhotoRequired',
      'UseEmptyOWC',
      'WineUsageCodeID',
      'AuditAmendedDate',
      'SOORotationNumber'
    ]),
    OriginalPackaging: {
      type: Boolean
    },
    OrigPkgCaseSize: {
      type: Number
    },
    TransactionOrder: {
      type: Number
    },
    EmptyCases: {
      type: Array,
      fields: {
        EmptyCaseID: {
          type: Number
        }
      }
    }
  },
  fields
)

PurchaseOrderLine.createMutation(
  'delete',
  'purchaseOrderLineDelete',
  Entity.onlyKeys(fields, ['POLID', 'AuditAmendedDate']),
  []
)

export default PurchaseOrderLine

<template>
  <group-box>
    <template slot="header">
      Last Sale
    </template>
    <b-row>
      <b-col class="col-12 col-xxl-6">
        <b-form-group
          label="Price"
          label-for="lastSalePrice"
          label-size="sm"
          label-cols-xxl="4"
        >
          <b-form-input
            id="lastSalePrice"
            :value="lastSalePrice | round(2) | withCommas"
            size="sm"
            disabled
            class="font-weight-bold text-right"
          />
        </b-form-group>
      </b-col>
      <b-col class="col-12 col-xxl-6">
        <b-form-group>
          <b-form-input
            id="formattedLastSaleDate"
            :value="formattedLastSaleDate"
            size="sm"
            disabled
            class="font-weight-bold"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <b-form-group
          label="Buyer"
          label-for="lastSaleContact"
          label-size="sm"
          label-cols-xxl="2"
          class="mb-0"
        >
          <b-form-input
            id="lastSaleContact"
            :value="lastSaleContact"
            size="sm"
            disabled
            class="font-weight-bold"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import moment from 'moment'

export default {
  components: {
    GroupBox
  },
  props: {
    lastSalePrice: {
      type: Number,
      required: true
    },
    lastSaleDate: {
      type: String,
      required: true
    },
    lastSaleContact: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedLastSaleDate() {
      if (this.lastSaleDate && this.lastSaleDate.length > 0) {
        const dateFormat = this.$store.state.user.settings.dateFormat
        return moment(this.lastSaleDate).format(dateFormat)
      }
      return ''
    }
  }
}
</script>

import gql from 'graphql-tag'
import { map } from 'lodash'
import { client } from '~/api/graphQLInstance'
import { adaptWineCardFromAPI } from '~/helpers/apiWineCardAdaptor'
import WineCard from '~/models/winecard'

export const clearAllPromotions = async () => {
  const result = await client.query({
    query: gql`
      mutation RootMutationType {
        wineCardClearPromotions
      }
    `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.wineCardClearPromotions
}

export const updateBatchPromotions = async args => {
  const result = await client.query({
    query: WineCard.mutate('updateBatchPromotion', args)
  })

  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return map(
    result.data[WineCard.mutations.updateBatchPromotion.name],
    adaptWineCardFromAPI
  )
}

import Entity from './entity'

const fields = {
  PromotionProfileCode: {
    type: String
  },
  PromotionProfileName: {
    type: String
  },
  IsEnabled: {
    type: Boolean
  },
  IsAListProfile: {
    type: Boolean
  },
  IsDefaultProfile: {
    type: Boolean
  },
  MinReviewSales: {
    type: Number
  },
  MaxReviewMonths: {
    type: Number
  },
  ReviewFrequency: {
    type: Number
  },
  MinWineIntervalDays: {
    type: Number
  },
  HighPriceCheckDays: {
    type: Number
  },
  MinPromotionSales: {
    type: Number
  },
  MaxPromotionMonths: {
    type: Number
  },
  PromotionFrequency: {
    type: Number
  },
  HistoryMonths: {
    type: Number
  },
  SeparateWineLists: {
    type: Boolean,
    default: false
  },
  MaxReviewWineCount: {
    type: Number
  },
  MinReviewWineMatch: {
    type: Number
  },
  MinPeerCount: {
    type: Number
  },
  MaxManualWineCount: {
    type: Number
  },
  MaxRandomWineCount: {
    type: Number
  },
  MaxAttemptCount: {
    type: Number
  },
  SmallerFormatPct: {
    type: Number
  },
  LargerFormatPct: {
    type: Number
  },
  SmallerScorePct: {
    type: Number
  },
  LargerScorePct: {
    type: Number
  },
  CategoryFillInPct: {
    type: Number
  },
  SmallerPricePct: {
    type: Number
  },
  LargerPricePct: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  ContactUsage: {
    type: Number
  },
  Status: {
    type: String
  }
}

const PromotionProfile = new Entity('PromotionProfile', fields)

PromotionProfile.createQuery(
  'get',
  'promotionProfileGet',
  Entity.allButKeys(fields, ['UsageCount', 'Status'])
)
PromotionProfile.createQuery(
  'getAll',
  'promotionProfileGetAll',
  Entity.onlyKeys(fields, [
    'PromotionProfileCode',
    'PromotionProfileName',
    'ReviewFrequency',
    'PromotionFrequency',
    'MaxReviewWineCount',
    'MaxManualWineCount',
    'MaxRandomWineCount',
    'ContactUsage'
  ])
)

PromotionProfile.createMutation(
  'update',
  'promotionProfileUpdate',
  Entity.allButKeys(fields, ['ContactUsage', 'Status']),
  []
)

PromotionProfile.createMutation(
  'delete',
  'promotionProfileDelete',
  {
    Code: fields.PromotionProfileCode,
    AuditAmendedDate: fields.AuditAmendedDate
  },
  []
)

PromotionProfile.createMutation(
  'insert',
  'promotionProfileAdd',
  Entity.allButKeys(fields, ['ContactUsage', 'Status', 'AuditAmendedDate']),
  []
)

export default PromotionProfile

<template>
  <div>
    <b-form-group>
      <b-checkbox
        id="mandatory"
        class="wv-check"
        :checked="requireMandatory"
        @input="handleOrigPkg"
      >
        <div class="wv-check__label text-left">
          Orig. Pkg. Mandatory
        </div>
      </b-checkbox>
    </b-form-group>
    <b-form-group
      label-size="sm"
      :label="maxQuantityText"
      label-for="max-qty"
      :class="maxQuantityClass"
    >
      <b-form-input
        id="max-qty"
        block
        disabled
        class="mt-1 text-center"
        :class="maxQuantityClass"
        :value="totals.maxQuantityWithoutLoss|bottlesToCase(totals.defaultCaseSize)|toString"
      />
    </b-form-group>
    <b-form-group>
      <b-checkbox
        id="sell-at-loss"
        :class="{'wv-check':true, 'red':sellAtLossComputed}"
        :checked="sellAtLossComputed"
        @change="sellAtLossComputed = $event"
      >
        <div class="wv-check__label text-left">
          SELL AT A LOSS
        </div>
      </b-checkbox>
    </b-form-group>
    <confirm v-model="showSellAtLossConfirm" :title="sellAtLossModalTitle" @ok="changeSellAtLoss" @hide="resetSellAtLoss">
      <template v-if="sellAtLossComputed">
        This override will show ALL STOCK for this Wine Card <br>
        as sellable in the Price Lists and Customer Promotions,  <br>
        EVEN IF WOULD MAKE A NET LOSS.<br> <br>
        Press OK to update the Wine Card."
      </template>
      <template v-else>
        This will reset the Wine Card to only show <br>
        profitable stock levels as sellable. <br><br>
        Press OK to update the Wine Card.
      </template>
    </confirm>
  </div>
</template>

<script>
import Confirm from '~/components/Modal/Confirm'
export default {
  components: {
    Confirm
  },
  props: {
    totals: {
      type: Object,
      required: true
    },
    requireMandatory: {
      type: Boolean,
      required: false,
      default: false
    },
    sellAtLoss: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      sellAtLossChoice: null,
      showSellAtLossConfirm: false,
      sellAtLossOK: false
    }
  },
  computed: {
    sellAtLossModalTitle() {
      return this.sellAtLossComputed ? 'Apply Override' : 'Apply Default'
    },
    sellAtLossComputed: {
      get() {
        return this.sellAtLossChoice === null
          ? this.sellAtLoss
          : this.sellAtLossChoice
      },
      set(value) {
        this.sellAtLossChoice = value
        this.showSellAtLossConfirm = true
      }
    },
    maxQuantityText() {
      if (this.totals.maxIncludesLineLoss) {
        return 'Max. Quantity w/o NET Loss'
      } else {
        return 'Max. Quantity w/o ANY Loss'
      }
    },
    maxQuantityClass() {
      if (this.totals.maxIncludesLineLoss) {
        return 'danger'
      } else {
        return ''
      }
    },
    sellAtLossClass() {
      return this.sellAtLossComputed ? 'danger' : ''
    }
  },
  methods: {
    handleOrigPkg(isChecked) {
      this.$emit('mandatory-orig-pkg', isChecked)
    },
    changeSellAtLoss() {
      this.sellAtLossOK = true
      this.$emit('update-sell-at-loss', this.sellAtLossChoice)
    },
    resetSellAtLoss() {
      if (this.sellAtLossOK === false) {
        this.sellAtLossComputed = !this.sellAtLossComputed
      }
      this.sellAtLossOK = false
    }
  }
}
</script>

import UnallocatedSearch from '../models/etally/unallocatedSearch'
import ETallyCard from '../models/etally/card'
import ETallyCardImportCSV from '../models/etally/import'
import ETallyCardLineImage from '../models/etally/image'
import ETallyCardDocument from '../models/etally/document'
import { client } from './graphQLInstance'

export const getUnallocatedSearch = async Search => {
  const result = await client.query({
    query: UnallocatedSearch.query('unallocatedSearch', { Search })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[UnallocatedSearch.queries.unallocatedSearch.name]
}

export const unallocatedSupplierPurchaseOrderLines = async ContactID => {
  const result = await client.query({
    query: UnallocatedSearch.query('unallocatedSupplierPurchaseOrderLines', {
      ContactID
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key =
    UnallocatedSearch.queries.unallocatedSupplierPurchaseOrderLines.name
  return result.data[key]
}

export const createTallyCard = async data => {
  const result = await client.query({
    query: ETallyCard.mutate('create', data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.create.name
  return result.data[key]
}

export const createTallyCardFromWineCard = async data => {
  const result = await client.query({
    query: ETallyCard.mutate('createFromWineCard', data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.createFromWineCard.name
  return result.data[key]
}

export const createTallyCardFromNoWineCard = async data => {
  const result = await client.query({
    query: ETallyCard.mutate('createFromNoWineCard', data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.createFromNoWineCard.name
  return result.data[key]
}

export const updateTallyCard = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('update', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.update.name
  return result.data[key]
}

export const returnTallyLine = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('returnLine', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.returnLine.name
  return result.data[key]
}

export const searchReturnTallyLines = async Data => {
  const result = await client.query({
    query: ETallyCard.query('returnSearch', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.queries.returnSearch.name
  return result.data[key]
}

export const updateRotations = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('updateRotations', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.updateRotations.name
  return result.data[key]
}

export const clearRotations = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('clearRotations', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.clearRotations.name
  return result.data[key]
}

export const manuallySetRotation = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('manualRotation', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.manualRotation.name
  return result.data[key]
}

export const updateTallyLine = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('updateLine', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.updateLine.name
  return result.data[key]
}

export const updateTallyGroup = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('updateGroup', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.updateGroup.name
  return result.data[key]
}

export const deleteTally = async ETCID => {
  const result = await client.query({
    query: ETallyCard.mutate('delete', { ETCID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.delete.name
  return result.data[key]
}

export const deleteTallyLine = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('deleteLine', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.deleteLine.name
  return result.data[key]
}

export const receiveAll = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('receiveAll', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.receiveAll.name
  return result.data[key]
}

export const finalise = async Data => {
  const result = await client.query({
    query: ETallyCard.mutate('finalise', Data)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.finalise.name
  return result.data[key]
}

export const getTallyCard = async ID => {
  const result = await client.query({
    query: ETallyCard.query('get', {
      ID
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.queries.get.name
  return result.data[key]
}

export const searchTallyCard = async params => {
  const result = await client.query({
    query: ETallyCard.query('search', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.queries.search.name
  return result.data[key]
}

export const updateImportCSV = async params => {
  const result = await client.query({
    query: ETallyCardImportCSV.mutate('update', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCardImportCSV.mutations.update.name
  return result.data[key]
}

export const getImages = async ETCLID => {
  const result = await client.query({
    query: ETallyCardLineImage.query('get', { ETCLID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCardLineImage.queries.get.name
  return result.data[key]
}

export const deleteImage = async UUID => {
  const result = await client.query({
    query: ETallyCardLineImage.mutate('delete', { UUID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCardLineImage.mutations.delete.name
  return result.data[key]
}

export const getDocuments = async ETCID => {
  const result = await client.query({
    query: ETallyCardDocument.query('get', { ETCID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCardDocument.queries.get.name
  return result.data[key]
}

export const deleteDocument = async UUID => {
  const result = await client.query({
    query: ETallyCardDocument.mutate('delete', { UUID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCardDocument.mutations.delete.name
  return result.data[key]
}

export const updateImage = async ({ UUID, CaseNumber }) => {
  const result = await client.query({
    query: ETallyCardLineImage.mutate('update', { UUID, CaseNumber })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCardLineImage.mutations.update.name
  return result.data[key]
}

export const sendPreAdvice = async ETCID => {
  const result = await client.query({
    query: ETallyCard.mutate('sendPreAdvice', { ETCID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = ETallyCard.mutations.sendPreAdvice.name
  return result.data[key]
}

<template>
  <group-box :header="true" class="h-100">
    <template slot="header">
      Currency
    </template>
    <b-row class="my-1">
      <b-col>
        <d-form-checkbox v-model="currencies" value="GBP" disabled class="text-left">
          UK Sterling
        </d-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-checkbox v-model="currencies" value="EUR" class="text-left">
          Euro
        </d-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-checkbox v-model="currencies" value="HKD" class="text-left">
          Hong-Kong
        </d-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-checkbox v-model="currencies" value="SGD" class="text-left">
          Singapore
        </d-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-checkbox v-model="currencies" value="CHF" class="text-left">
          Switzerland
        </d-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-checkbox v-model="currencies" value="USD" class="text-left">
          United States
        </d-form-checkbox>
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'

export default {
  components: {
    GroupBox
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    currencies: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

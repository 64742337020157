import { client } from '../graphQLInstance'
import Format from '../../models/format'

export const getFormats = async searchTerm => {
  const result = await client.query({
    query: Format.query('search', {
      SearchTerm: searchTerm
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Format.queries.search.name]
}

export const addFormat = async args => {
  const result = await client.query({
    query: Format.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Format.mutations.insert.name]
}

export const getFormat = async args => {
  const result = await client.query({
    query: Format.query('get', {
      RecordId: args.FormatID
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const results = result.data[Format.queries.get.name]
  Format.triggerEvent('post-query', results)
  return results
}

export const updateFormat = async args => {
  const result = await client.query({
    query: Format.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Format.mutations.update.name]
}

export const deleteFormat = async args => {
  const result = await client.query({
    query: Format.mutate('delete', {
      ID: args.FormatID,
      AuditAmendedDate: args.AuditAmendedDate
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Format.mutations.delete.name]
}

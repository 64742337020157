import Entity from './entity'

const fields = {
  Text: {
    type: String
  },
  TextTemplateCode: {
    type: String
  },
  DefaultText: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  }
}

const TextTemplate = new Entity('TextTemplate', fields)

TextTemplate.createQuery('get', 'textTemplateGet', fields)

TextTemplate.createQuery('all', 'textTemplateGetAll', fields)

TextTemplate.createMutation(
  'update',
  'textTemplateUpdate',
  Entity.allButKeys(fields, ['DefaultText'])
)

export default TextTemplate

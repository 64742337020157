<template>
  <reserve-level-modal
    v-if="activeCard"
    v-model="showReserveStockModal"
    :item="activeCard"
    @error="handleError"
  />
</template>

<script>
import ReserveLevelModal from '~/components/Modal/ReserveLevel'

export default {
  components: {
    ReserveLevelModal
  },
  computed: {
    showReserveStockModal: {
      get() {
        return this.$store.state.modal.showReserveLevelModal
      },
      set(value) {
        this.$store.commit('modal/setShowReserveLevelModal', value)
      }
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeBrowserCard
    }
  },
  methods: {
    handleError(error) {
      this.$notifyError('Could not update reserve stock: ' + error)
    }
  }
}
</script>

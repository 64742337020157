<template>
  <b-form>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Sub Region Name"
      label-for="Region"
      :label-class="{green: isDirty('Region') }"
    >
      <b-form-input
        id="SubRegion"
        v-model="SubRegion"
        :state="!$v.SubRegion.$error && null"
        trim
        size="sm"
        :disabled="viewMode"
        :class="{green: isDirty('SubRegion') }"
      />
      <b-form-invalid-feedback>
        Country Sub Region is required
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Region"
      label-for="CountryRegionID"
      :label-class="{green: isDirty('CountryRegionID') }"
    >
      <b-form-select
        id="CountryRegionID"
        v-model="CountryRegionID"
        :state="!$v.CountryRegionID.$error && null"
        trim
        size="sm"
        :disabled="viewMode || regionDisabled"
        :options="regionList"
        :class="{green: isDirty('CountryRegionID') }"
      />
      <b-form-invalid-feedback>
        Region is required
      </b-form-invalid-feedback>
    </b-form-group>
    <b-row class="mb-2">
      <b-col xl="4" lg="4" md="4" sm="4">
        <label
          class="col-form-label-sm p-0 m-0"
          :class="{green: isDirty('UseOnBackOffice') }"
        >
          Visible On Back Office
        </label>
      </b-col>
      <b-col xl="8" lg="8" md="8" sm="8">
        <b-checkbox
          v-model="UseOnBackOffice"
          type="text"
          :state="!$v.UseOnBackOffice.$error && null"
          :class="{green: isDirty('UseOnBackOffice') }"
          trim
          size="sm"
          :disabled="viewMode"
          maxlength="4"
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      required: false
    },
    regions: {
      type: Array,
      required: true
    },
    regionId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapObjectToVModel(['SubRegion', 'CountryRegionID', 'UseOnBackOffice']),
    regionList() {
      return this.regions.map(c => {
        if (c.value && c.text) {
          return c
        }
        return {
          value: c.CountryRegionID,
          text: c.Region
        }
      })
    },
    regionDisabled() {
      return this.regionId !== null
    }
  },
  validations: {
    SubRegion: {
      required
    },
    CountryRegionID: {
      required
    },
    UseOnBackOffice: {}
  }
}
</script>

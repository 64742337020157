import Entity from './entity'

const fields = {
  ContractID: {
    type: Number
  },
  TransactionType: {
    type: String
  },
  Vintage: {
    type: Number
  },
  WineName: {
    type: String
  },
  Region: {
    type: String
  },
  UnitSize: {
    type: String
  },
  Quantity: {
    type: Number
  },
  Price: {
    type: Number
  },
  PriceType: {
    type: String
  },
  LivexWineCode: {
    type: String
  },
  YourWineID: {
    type: String
  },
  LastChangeOn: {
    type: String
  },
  TransactionDate: {
    type: String
  },
  BottleVolume: {
    type: Number
  },
  CaseUnits: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  ListPrice: {
    type: Number
  },
  DateUpdated: {
    type: String
  },
  IsCancelled: {
    type: Boolean
  },
  WineDescription: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  AvailableBottles: {
    type: Number
  },
  AdjustedPrice: {
    type: Number
  },
  PercentDifference: {
    type: Number
  },
  SortDifference: {
    type: Number
  },
  AvgStockPrice: {
    type: Number
  },
  PercentMarkUp: {
    type: Number
  }
}

const LiveExTransactions = new Entity('LiveExLatestTransactions', fields)

LiveExTransactions.createQuery(
  'get',
  'wineCardLiveExLatestTransactions',
  fields
)

export default LiveExTransactions

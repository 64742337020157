<template>
  <add-wine-note-modal
    v-if="activeCard"
    v-model="showWineNotesCreateModal"
    :item="activeCard"
    :saving="notesLoading"
  />
</template>

<script>
import AddWineNoteModal from '~/components/Modal/AddWineNote'

export default {
  components: {
    AddWineNoteModal
  },
  computed: {
    showWineNotesCreateModal: {
      get() {
        return this.$store.state.modal.showWineNotesCreateModal
      },
      set(value) {
        this.$store.commit('modal/setShowWineNotesCreateModal', value)
      }
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeBrowserCard
    },
    notesLoading() {
      return this.$store.state.wineCard.notes.loading
    }
  }
}
</script>

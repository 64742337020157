import Entity from './entity'

export const fields = {
  WSOLID: {
    type: Number
  },
  WSOID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  BottlesSnapshot: {
    type: String
  },
  BottlesWanted: {
    type: String
  },
  WineSellingPrice: {
    type: Number
  },
  UnitSellingPrice: {
    type: Number
  },
  LineTotal: {
    type: Number
  },
  Quantity: {
    type: Number
  },
  CaseFormat: {
    type: String
  },
  CaseType: {
    type: String
  },
  WineVintage: {
    type: String
  },
  DutyStatusID: {
    type: Number
  },
  ComputedWineName: {
    type: String
  },
  FormatName: {
    type: String
  },
  UsageCode: {
    type: String
  },
  DefaultCaseSize: {
    type: String
  },
  Available: {
    type: String
  },
  AuditDeletedDate: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  }
}

const WebSalesOrderLine = new Entity('WebSalesOrderLine', fields)

WebSalesOrderLine.createQuery(
  'get',
  'webSalesOrderLineItems',
  fields,
  Entity.onlyKeys(fields, ['WSOID'])
)

export default WebSalesOrderLine

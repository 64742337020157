<template>
  <div>
    <data-modal
      v-model="shown"
      heading="Appellation Selection"
      search-placeholder="Appellation Filter ..."
      primary-key="AppellationID"
      filter-key="AppellationName"
      :loading="appellationsLoading"
      :fields="fields"
      :items="getAppellationsList"
      :selected="getActiveAppellation"
      :delete-disabled="!isActiveItemDeletable"
      scrollbar-class="t-appellation"
      :deleting="deleting"
      @shown="refresh"
      @new="toggleNewAppellation"
      @edit="toggleEditAppellation"
      @view="toggleViewAppellation"
      @delete="toggleDeleteAppellation"
      @selected="rowSelected"
      @dbl-click="rowDoubleClicked"
      @ok="handleOK"
    >
      <template slot="UseOnWebsite" slot-scope="data">
        <span v-if="data.item.UseOnWebsite">
          Yes
        </span>
        <span v-else>
          No
        </span>
      </template>
      <template slot="UseOnBackOffice" slot-scope="data">
        <span v-if="data.item.UseOnBackOffice">
          Yes
        </span>
        <span v-else>
          No
        </span>
      </template>
    </data-modal>

    <confirm-modal
      :value="boolConfirmDeleteModal"
      @ok="deleteAppellationConfirm"
      @cancel="deleteAppellationCancel"
      @change="deleteAppellationChange"
    >
      Are you sure you want to delete the selected appellation?
    </confirm-modal>
    <new-appellation-modal
      v-model="boolNewAppellationModal"
      :loading-prop="appellationLoading"
      @new-appellation-form-cancel="toggleNewAppellation"
      @new-appellation-form-submit-success="toggleNewAppellation"
      @saved="rowSelected"
    />
    <edit-appellation-modal
      v-if="getActiveAppellation"
      v-model="boolEditAppellationModal"
      :loading-prop="appellationLoading"
      :appellation="getActiveAppellation"
    />
    <view-appellation-modal
      v-if="getActiveAppellation"
      v-model="boolViewAppellationModal"
      :loading-prop="appellationLoading"
      :appellation="getActiveAppellation"
    />
  </div>
</template>

<script>
import NewAppellationModal from '~/components/Modal/Administration/NewAppellationModal'
import EditAppellationModal from '~/components/Modal/Administration/EditAppellationModal'
import ViewAppellationModal from '~/components/Modal/Administration/ViewAppellationModal'
import ConfirmModal from '~/components/Modal/Confirm'
import DataModal from '../../Administration/DataModal'

import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    NewAppellationModal,
    EditAppellationModal,
    ViewAppellationModal,
    ConfirmModal,
    DataModal
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    dblClickEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      boolNewAppellationModal: false,
      boolConfirmDeleteModal: false,
      boolEditAppellationModal: false,
      boolViewAppellationModal: false,
      deleting: false,
      fields: {
        AppellationName: {
          label: 'Appellation Name',
          sortable: true
        },
        UseOnWebsite: {
          label: 'On Website',
          sortable: false,
          class: 'text-center'
        },
        UseOnBackOffice: {
          label: 'On Backoffice',
          sortable: false,
          class: 'text-center'
        },
        WineNameUsage: {
          label: 'Wine Name Usage',
          sortable: true,
          class: 'text-center'
        }
      }
    }
  },
  computed: {
    ...mapGetters('administration/appellations', [
      'getActiveAppellation',
      'getAppellationsList'
    ]),
    ...mapState('administration/appellations', {
      appellationLoading: state => state.appellationLoading,
      appellationsLoading: state => state.loading
    }),
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isActiveItemDeletable() {
      return (
        this.getActiveAppellation &&
        this.getActiveAppellation.WineNameUsage === 0
      )
    }
  },
  methods: {
    toggleNewAppellation() {
      this.boolNewAppellationModal = !this.boolNewAppellationModal
    },
    cancel() {
      this.$emit('appellations-modal-cancel')
    },
    ok() {
      this.$emit('appellations-modal-ok')
    },
    handleEnterTerm() {
      this.$emit('search')
    },
    toggleDeleteAppellation() {
      this.boolConfirmDeleteModal = !this.boolConfirmDeleteModal
    },
    toggleEditAppellation() {
      this.boolEditAppellationModal = !this.boolEditAppellationModal
    },
    toggleViewAppellation() {
      this.boolViewAppellationModal = !this.boolViewAppellationModal
    },
    async deleteAppellationConfirm() {
      this.boolConfirmDeleteModal = false
      try {
        this.deleting = true
        await this.$store.dispatch(
          'administration/appellations/deleteAppellation',
          this.getActiveAppellation
        )
        this.$notify.success('Appellation was deleted')
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.deleting = false
      }
    },
    deleteAppellationCancel() {
      this.boolConfirmDeleteModal = false
    },
    deleteAppellationChange() {
      this.boolConfirmDeleteModal = false
    },
    rowSelected(item) {
      this.$store.dispatch(
        'administration/appellations/setActiveAppellation',
        item
      )
    },
    rowDoubleClicked(item) {
      this.$emit('selected', item)
      if (this.dblClickEdit) {
        this.toggleEditAppellation()
      }
    },
    refresh() {
      this.$store.dispatch('administration/appellations/loadList')
      this.$emit('shown')
    },
    handleOK(item) {
      if (item) {
        this.$emit('selected', item)
      }
      this.$emit('ok', item)
      this.shown = false
    }
  }
}
</script>
<style>
@media (min-width: 1630px) {
  .t-appellation .virtual-scroll__content {
    height: 100%;
  }
}
</style>

import Vue from 'vue'
import { getFormat } from '../api/administration/formats'

export const state = () => ({
  formats: {},
  loading: false,
  error: null
})

export const getters = {
  getById: state => id => {
    return state.formats[id]
  }
}

export const mutations = {
  setFormatList(state, formatOptions) {
    state.formatOptions = formatOptions
    state.loadedFormatOptions = true
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setError(state, error) {
    state.error = error
  },
  setFormat(state, format) {
    Vue.set(state.formats, format.FormatID, format)
  }
}

export const actions = {
  async getFormatIfNotExists({ commit, state, getters }, id) {
    const loadedFormat = getters.getById(id)
    if (loadedFormat) {
      return loadedFormat
    }
    try {
      commit('setError', null)
      commit('setLoading', true)
      const format = await getFormat({ FormatID: id })
      commit('setFormat', format)
      return format
    } catch (e) {
      commit('setError', e)
    } finally {
      commit('setLoading', false)
    }
  }
}

import { client } from '../graphQLInstance'
import Designation from '../../models/designation'

export const getDesignations = async args => {
  const result = await client.query({
    query: Designation.query('get', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Designation.queries.get.name]
}

export const updateDesignation = async args => {
  const result = await client.query({
    query: Designation.mutate('update', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Designation.mutations.update.name]
}

export const addDesignation = async args => {
  const result = await client.query({
    query: Designation.mutate('insert', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Designation.mutations.insert.name]
}

export const deleteDesignation = async args => {
  const result = await client.query({
    query: Designation.mutate('delete', args)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[Designation.mutations.delete.name]
}

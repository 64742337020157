<template>
  <form ref="form" method="post" :action="actionUrl" :target="target">
    <input type="hidden" name="token" :value="$store.state.user.documentToken">
    <input type="hidden" name="WPOID" :value="wpoid">
    <input type="hidden" name="Type" :value="type">
    <slot name="form-override">
      <b-button block type="submit" class="wv-btn wv-btn--xs " size="sm" :disabled="disabled" @click.prevent="submit">
        <slot>
          PDF
        </slot>
      </b-button>
    </slot>
  </form>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    wpoid: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      default: 'pdf'
    },
    target: {
      type: String,
      default: 'self'
    }
  },
  computed: {
    actionUrl() {
      return process.env.apiHost + '/document/webPurchaseOrder'
    }
  },
  methods: {
    submit() {
      this.$emit('click')
      this.$refs.form.submit()
    }
  }
}
</script>

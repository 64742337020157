import Entity from './entity'
import { auditFields } from './helpers'

const fields = {
  AppellationID: {
    type: Number
  },
  AppellationName: {
    type: String
  },
  AppellationOrder: {
    type: Number
  },
  AppellationCat: {
    type: Number
  },
  AppellationCode: {
    type: String
  },
  WineNameUsage: {
    type: Number
  },
  UseOnWebsite: {
    type: Boolean
  },
  UseOnBackOffice: {
    type: Boolean
  },
  ...auditFields
}

const Appellation = new Entity('Appellation', fields)

Appellation.createMutation(
  'insert',
  'appellationAdd',
  Entity.onlyKeys(fields, [
    'AppellationName',
    'AppellationOrder',
    'AppellationCat',
    'AppellationCode'
  ]),
  fields
)

Appellation.createMutation(
  'update',
  'appellationUpdate',
  Entity.allButKeys(fields, [
    'AuditCreatedBy',
    'AuditCreatedDate',
    'AuditAmendedBy',
    'WineNameUsage'
  ]),
  fields
)

Appellation.createMutation(
  'delete',
  'appellationDelete',
  {
    AuditAmendedDate: fields.AuditAmendedDate,
    ID: {
      type: Number
    }
  },
  []
)

Appellation.createQuery('get', 'appellationGet', fields)

Appellation.createQuery('search', 'appellationSearch', fields)

export default Appellation

<template>
  <div>
    <b-modal
      id="exportEmailDefaultTextModal"
      v-model="shown"
      centered
      hide-footer
      size="xl"
      title="Email body text for price emails to be sent to multiple recipients."
      modal-class="wv-modal"
      content-class="shadow"
      footer-class="justify-content-center"
      @hidden="updateHiddenEvent"
    >
      <div v-if="loading" class="alert alert-info">
        Loading Please Wait...
      </div>
      <template v-else>
        <div class="d-block">
          <b-row>
            <b-col md="10">
              <default-text :value="formData" @change-Text="handleTemplateChange" />
            </b-col>
            <b-col md="2">
              <b-button variant="outline-primary w-100 my-1" :disabled="saving" @click="handleOk">
                <b-spinner v-if="saving" type="grow" label="Loading..." small />
                Ok
              </b-button>
              <b-button variant="outline-danger w-100" :disabled="saving" @click="shown = false">
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DefaultText from './Export/DefaultText'

export default {
  components: {
    DefaultText
  },
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    saving: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      editedTemplate: null
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    formData() {
      return {
        Text: this.editedTemplate ? this.editedTemplate : this.text
      }
    }
  },
  methods: {
    updateHiddenEvent() {
      if (!this.silenceHidden) {
        this.$emit('hidden')
      }
    },
    handleTemplateChange(template) {
      this.editedTemplate = template
    },
    handleOk() {
      this.$emit('save', this.formData)
    }
  }
}
</script>

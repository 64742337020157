export const state = () => ({
  loading: false,
  saving: false
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSaving(state, saving) {
    state.saving = saving === true
  }
}

export const actions = {}

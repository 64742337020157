<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    footer-class="justify-content-center"
    :hide-header-close="true"
    size="md"
  >
    <template v-slot:modal-header>
      View Wine Name
    </template>
    <div v-if="loadingProp" class="alert alert-info">
      <p class="m-0">Loading Please Wait...</p>
    </div>
    <div v-if="error" class="alert alert-danger">
      {{ error }}
    </div>
    <wine-name-form
      view-mode
      :duty-type-options="dutyTypes"
      :value="formData"
      @reset-appellation="resetAppellation"
    />
    <template v-slot:modal-footer>
      <b-button class="btn wv-btn btn-sm" @click="shown=false">
        OK
      </b-button>
      <b-button class="btn wv-btn btn-sm" @click="shown=false">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import WineNameForm from '~/components/Forms/WineNameForm'
import { map } from 'lodash'

export default {
  components: {
    WineNameForm
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loadingProp: {
      type: Boolean,
      required: false,
      default: false
    },
    wineName: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    dutyTypesProp: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      error: null
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    formData() {
      return {
        Name: this.wineName.Name,
        DutyTypeID: this.wineName.DutyTypeID,
        WineCode: this.wineName.WineCode,
        AppellationName: this.wineName.AppellationName,
        WineCardUsage: this.wineName.WineCardUsage
      }
    },
    dutyTypes() {
      return map(this.dutyTypesProp, dutyType => {
        return {
          value: dutyType.DutyTypeID,
          text: dutyType.DutyTypeName
        }
      })
    }
  },
  methods: {
    async resetAppellation() {
      try {
        this.saving = true
        const mergedForm = Object.assign({}, this.wineName, {
          AppellationID: null
        })
        await this.$store.dispatch(
          'administration/winenames/updateWineName',
          mergedForm
        )
        this.shown = false
        this.$notify.success('Wine Name Updated')
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

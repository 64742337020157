<template>
  <new-purchase-order-modal
    v-if="activeCard"
    v-model="showNewPurchaseOrderModal"
    :item="activeCard"
    :wine-card-loading="wineCardLoading || usageOptionLoading"
    :wine-card="wineCard || {}"
    :usage-options="filteredUsageOptions"
    @save="saveNewPurchaseOrder"
  />
</template>

<script>
import NewPurchaseOrderModal from '~/components/Modal/NewPurchaseOrder'
import { WineUsage } from '~/constants/wineUsage'

export default {
  components: {
    NewPurchaseOrderModal
  },
  data() {
    return {
      isReserves: false,
      reservesUnavailable: false
    }
  },
  computed: {
    showNewPurchaseOrderModal: {
      get() {
        return this.$store.state.modal.showNewPurchaseOrderModal
      },
      set(value) {
        this.$store.commit('modal/setShowNewPurchaseOrderModal', value)
      }
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeBrowserCard
    },
    wineCardLoading() {
      return this.$store.state.wineCard.activeCard.loading
    },
    wineCard() {
      return this.$store.state.wineCard.activeCard.activeCard || {}
    },
    usageOptions() {
      return this.$store.state.lists.usageOptions
    },
    usageOptionLoading() {
      return this.$store.state.lists.loadingUsageOptions
    },
    activePOID() {
      // prettier-ignore
      return this.$store.getters['purchaseOrders/purchaseOrder/getActivePOID'];
    },
    activePurchaseOrder() {
      // prettier-ignore
      return this.$store.getters['purchaseOrders/purchaseOrder/getActivePurchaseOrder'];
    },
    filteredUsageOptions() {
      if (this.isReserves) {
        return this.usageOptions.filter(function(item) {
          return WineUsage.ReserveCodes.includes(item.value)
        })
      } else if (this.reservesUnavailable) {
        return this.usageOptions.filter(function(item) {
          return !WineUsage.ReserveCodes.includes(item.value)
        })
      }
      return this.usageOptions
    }
  },
  watch: {
    showNewPurchaseOrderModal(shown) {
      if (shown) {
        this.$store.dispatch('lists/refreshUsage', {})
        if (this.activeCard) {
          this.$store.dispatch(
            'wineCard/activeCard/getActiveCard',
            this.activeCard.id
          )
        }
        this.isReserves = false
        this.reservesUnavailable = false

        if (this.activePurchaseOrder) {
          this.isReserves = this.activePurchaseOrder.PurchaseOrderTypeID === 2
          if (!this.isReserves) {
            this.reservesUnavailable =
              this.activePurchaseOrder.PurchaseOrderLinesCount > 0
          }
        }
      }
    }
  },
  methods: {
    saveNewPurchaseOrder(saveData) {
      this.$eventHub.$emit('create-purchase-order', saveData)
    }
  }
}
</script>

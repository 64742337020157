import Entity from './entity'
import SalesOrder from './salesorder'

const fields = {
  SOLIID: {
    type: Number
  },
  SOID: {
    type: Number
  },
  TransactionOrder: {
    type: Number
  },
  Description: {
    type: String
  },
  Price: {
    type: Number
  },
  // IsVAT: {
  //   type: Number
  // },
  AuditCreatedDate: {
    type: String
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  AuditAmendedBy: {
    type: Number
  }
}

const SalesOrderLineItem = new Entity('SalesOrderLineItem', fields)

SalesOrderLineItem.createQuery('get', 'salesOrderLineItemsGet', fields)

SalesOrderLineItem.createMutation(
  'update',
  'salesOrderLineItemUpdate',
  {
    ...Entity.onlyKeys(fields, [
      'SOLIID',
      // 'WineCardID',
      // 'FormatID',
      // 'TransactionOrder',
      'Description',
      'Price'
      // 'DutyStatusIDBought',
      // 'DutyStatusIDSold',
      // 'WineUsageCodeID',
      // 'AuditAmendedDate'
    ])
  },
  {
    ...Entity.onlyKeys(fields, [
      'SOLIID',
      // 'WineCardID',
      // 'FormatID',
      // 'TransactionOrder',
      'Description',
      'Price'
      // 'DutyStatusIDBought',
      // 'DutyStatusIDSold',
      // 'WineUsageCodeID',
      // 'AuditAmendedDate'
    ]),
    SalesOrder: {
      type: Object,
      fields: SalesOrder.fields
    }
  }
)

// SalesOrderLineItem.createMutation(
//   'delete',
//   'salesOrderLineDelete',
//   Entity.onlyKeys(fields, ['SOLID']),
//   []
// )

// SalesOrderLineItem.createMutation(
//   'create',
//   'salesOrderLineAdd',
//   {
//     ...Entity.onlyKeys(fields, ['SOID', 'WineCardID', 'FormatID', 'Price']),
//     Bottles: {
//       type: Number
//     },
//     AsDutyPaid: {
//       type: Number
//     }
//   },
//   {
//     ...Entity.onlyKeys(fields, [
//       'SOLID',
//       'SOID',
//       'WineCardID',
//       'FormatID',
//       'Bottles',
//       'Price',
//       'DutyStatusIDBought',
//       'DutyStatusIDSold',
//       'IsVAT',
//       'WineUsageCodeID',
//       'WebOrderLineID',
//       'AuditCreatedBy',
//       'AuditAmendedDate'
//     ]),
//     Transaction: {
//       type: Object,
//       fields
//     },
//     SalesOrder: {
//       type: Object,
//       fields: SalesOrder.fields
//     }
//   }
// )

export default SalesOrderLineItem

import { getCustomerStockCard } from '~/api/reserves/customerStockCard'
import { getBrokingWineCards } from '~/api/broking/brokingWineCard'
import { updateBrokingPrice } from '~/api/broking/brokingPrice'
import { getWineCardsByIds } from '~/api/wineBrowser/wines'

export const state = () => ({
  contact: {},
  wines: [],
  activeWineCardId: null,
  allocations: {},
  loading: false,
  updatingBrokingPrice: false,
  wineCards: []
})

export const getters = {
  getActiveAllocations: state => {
    return state.allocations[state.activeWineCardId] || []
  },
  getAllocationsForWineCardID: state => wineCardId => {
    return state.allocations[wineCardId] || []
  },
  getActiveWine: state => {
    for (let i = 0; i < state.wines.length; i++) {
      if (state.wines[i].WineCardID === state.activeWineCardId) {
        return state.wines[i]
      }
    }
    return null
  },
  getActiveWineForWineCardID: state => wineCardId => {
    for (let i = 0; i < state.wines.length; i++) {
      if (state.wines[i].WineCardID === wineCardId) {
        return state.wines[i]
      }
    }
    return null
  },
  getActiveWineCard: state => {
    for (let i = 0; i < state.wineCards.length; i++) {
      if (state.wineCards[i].id === state.activeWineCardId) {
        return state.wineCards[i]
      }
    }
    return null
  },
  getActiveWineCardForWineCardID: state => wineCardId => {
    for (let i = 0; i < state.wineCards.length; i++) {
      if (state.wineCards[i].id === wineCardId) {
        return state.wineCards[i]
      }
    }
    return null
  },
  getWineCardIds: (state, _, rootState) => {
    return rootState.reserves.customerStockCard.brokingWines.map(
      wine => wine.WineCardID
    )
  },
  hasWineCards: state => {
    return state.wineCards.length > 0
  }
}

export const mutations = {
  setContact(state, contact) {
    state.contact = contact
  },
  setWines(state, wines) {
    state.wines = wines
  },
  setActiveWineCardId(state, wineCardId) {
    state.activeWineCardId = wineCardId
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setUpdatingBrokingPrice(state, bool) {
    state.updatingBrokingPrice = bool === true
  },
  updateBrokingPrice(state, result) {
    if (state.wines) {
      const index = state.wines.findIndex(
        item => item.WineCardID === result.WineCardID
      )

      const wine = Object.assign({}, state.wines[index], {
        BrokingPrice: result.Price
      })

      state.wines.splice(index, 1, wine)
    }
  },
  setWineCards(state, wineCards) {
    state.wineCards = wineCards
  }
}

export const actions = {
  async getContact({ commit, dispatch }, id) {
    const result = await getCustomerStockCard(id)
    if (result) {
      commit('setContact', result)
      dispatch('broking/tabs/appendBrokingContact', result, {
        root: true
      })
    }
    return result
  },
  async getBrokingWines({ commit }, id) {
    const result = await getBrokingWineCards({
      ContactID: id
    })
    if (result) {
      commit('setWines', result)
    }
    return result
  },
  async updateBrokingPrice({ commit, dispatch }, payload) {
    commit('setUpdatingBrokingPrice', true)
    try {
      const result = await updateBrokingPrice(payload)
      if (result) {
        commit('updateBrokingPrice', result)
        commit('reserves/customerStockCard/updateBrokingPrice', result, {
          root: true
        })
      }
      return result
    } catch (e) {
      // commit('setInventoryValidity', { BRIID: payload.BRIID, valid: false })
      throw e
    } finally {
      commit('setUpdatingBrokingPrice', false)
    }
  },
  async getBrowserCards({ commit, getters }) {
    if (getters.getWineCardIds.length > 0) {
      commit('setLoading', true)
      try {
        const result = await getWineCardsByIds(getters.getWineCardIds)
        await commit('setWineCards', result)
        return true
      } catch (e) {
        throw e
      } finally {
        commit('setLoading', false)
      }
    }
  }
}

<template>
  <b-modal
    v-model="shown"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    @hidden="$emit('hidden')"
    @ok="$emit('ok')"
    @shown="$emit('shown')"
  >
    <template v-slot:modal-header>
      OWC Selection
    </template>
    <div v-if="loading" class="text-center my-2">
      <b-spinner class="align-middle" />
    </div>
    <b-table
      v-else
      striped
      hover
      :items="owcList"
      :fields="fields"
      :table-busy="loading"
    >
      <template slot="OrigPkgCaseSize" slot-scope="data">
        OWC{{ data.item.OrigPkgCaseSize }}
      </template>
      <template slot="OrigPkgCaseSizeSelect" slot-scope="data">
        <b-form-checkbox :checked="has(data.item)" @change="handleSelection(data.item, $event)" />
      </template>
    </b-table>
    <template v-slot:modal-footer="{ok,cancel}">
      <b-button class="btn wv-btn btn-secondary btn-sm" @click="ok()">
        OK
      </b-button>
      <b-button class="btn wv-btn btn-secondary btn-sm" @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    loading() {
      return this.$store.state.export.wineList.loadingOWCs
    },
    owcList() {
      return this.$store.state.export.wineList.selectableOWCs
    },
    selected() {
      return this.$store.state.export.settings.owcSelection
    },
    fields() {
      return [
        {
          key: 'OrigPkgCaseSizeSelect',
          sortable: false,
          label: 'Select',
          tdClass: 'text-center'
        },
        {
          key: 'OrigPkgCaseSize',
          sortable: true,
          label: 'OWC',
          tdClass: 'text-center'
        },
        {
          key: 'WineCardCount',
          sortable: true,
          label: 'Wines',
          tdClass: 'text-center'
        }
      ]
    }
  },
  watch: {
    shown(shown) {
      if (shown) {
        this.$eventHub.$emit('get-owc-for-export-settings')
      }
    }
  },
  methods: {
    has(item) {
      return this.selected.indexOf(item.OrigPkgCaseSize) >= 0
    },
    handleSelection(item) {
      if (this.has(item)) {
        this.$store.commit('export/settings/removeOwc', item.OrigPkgCaseSize)
      } else {
        this.$store.commit('export/settings/addOwc', item.OrigPkgCaseSize)
      }
    }
  }
}
</script>

<template>
  <new-sales-order-modal
    v-if="activeCard"
    v-model="showNewSalesOrderModal"
    :item="activeCard"
    :wine-card-loading="wineCardLoading"
    :wine-card="wineCard || {}"
    :is-uk-delivery="isUKDelivery"
    @save="saveNewSalesOrder"
  />
</template>

<script>
import NewSalesOrderModal from '~/components/Modal/NewSalesOrder'

export default {
  components: {
    NewSalesOrderModal
  },
  computed: {
    showNewSalesOrderModal: {
      get() {
        return this.$store.state.modal.showNewSalesOrderModal
      },
      set(value) {
        this.$store.commit('modal/setShowNewSalesOrderModal', value)
      }
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeBrowserCard
    },
    wineCardLoading() {
      return this.$store.state.wineCard.activeCard.loading
    },
    wineCard() {
      return this.$store.state.wineCard.activeCard.activeCard || {}
    },
    isUKDelivery() {
      return this.$store.state.modal.salesOrderIsUkDelivery
    }
  },
  watch: {
    showNewSalesOrderModal(shown) {
      if (shown && this.activeCard) {
        this.$store.dispatch(
          'wineCard/activeCard/getActiveCard',
          this.activeCard.id
        )
      }
    }
  },
  methods: {
    saveNewSalesOrder(saveData) {
      this.$eventHub.$emit('create-sales-order', saveData)
    }
  }
}
</script>

import Entity from './entity'

const fields = {
  sellerName: {
    type: String
  },
  vintage: {
    type: Number
  },
  wineName: {
    type: String
  },
  winePrice: {
    type: Number
  },
  caseInfo: {
    type: String
  },
  casePrice: {
    type: Number
  },
  caseSize: {
    type: Number
  },
  format: {
    type: String
  },
  sellerUrl: {
    type: String
  },
  wineDescription: {
    type: String
  },
  inBond: {
    type: Boolean
  },
  flags: {
    type: String
  }
}

const WineSearcherPrices = new Entity('WineSearcherPrices', fields)

WineSearcherPrices.createQuery('get', 'wineSearcherPricesGet', fields)

export default WineSearcherPrices

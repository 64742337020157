<template>
  <price-lookup-modal
    v-if="activeCard"
    v-model="showPriceLookupModal"
    :item="activeCard"
    :wine-card-loading="wineCardLoading"
    :wine-card="wineCard"
    :globals="globals"
    :global-loading="globalLoading"
    :promo-user-changed="promoUserChanged"
    @empty-cases="handleEmptyCase"
    @change="updateWineCard"
    @change-promo-date="setPromoDate"
    @get-bordeaux-index="handleGetBoardeauxIndex"
    @get-liv-ex="handleGetLivEx"
    @get-wine-searcher="handleGetWineSearcher"
  />
</template>

<script>
import PriceLookupModal from '~/components/Modal/PriceLookup'
import FanOutToSearchStoresMixin from '~/mixins/winebrowser/FanOutToSearchStores'
import ChangesStore from '~/mixins/winebrowser/ChangesStore'

export default {
  components: {
    PriceLookupModal
  },
  mixins: [FanOutToSearchStoresMixin, ChangesStore],
  computed: {
    showPriceLookupModal: {
      get() {
        return this.$store.state.modal.showPriceLookupModal
      },
      set(value) {
        this.$store.commit('modal/setShowPriceLookupModal', value)
      }
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeBrowserCard
    },
    wineCardLoading() {
      return this.$store.state.wineCard.activeCard.loading
    },
    wineCard() {
      return this.$store.state.wineCard.activeCard.activeCard || {}
    },
    globals() {
      return this.$store.state.global.data
    },
    globalLoading() {
      return this.$store.state.global.loading
    },
    promoUserChanged() {
      const promoKey = this.getSearchStoreKey('wineCards/promotionSelected')
      return this.$store.getters[promoKey](this.activeCard.id)
    }
  },
  watch: {
    activeCard(item) {
      if (!this.showPriceLookupModal) return
      if (item) {
        this.$store.dispatch('wineCard/activeCard/getActiveCard', item.id)
      }
    }
  },
  methods: {
    handleEmptyCase() {
      this.$store.commit('modal/setShowEmptyCaseModal', true)
    },
    async handleGetBoardeauxIndex(item) {
      try {
        await this.$store.dispatch(
          'wineCard/activeCard/getBordeauxIndexPrices',
          {
            WineCardID: item.id
          }
        )
      } catch (e) {
        this.$notifyError(e)
      }
    },
    async handleGetLivEx(item) {
      try {
        await this.$store.dispatch('wineCard/activeCard/getBidOffer', {
          WineCardID: item.id
        })
      } catch (e) {
        this.$notifyError(e)
      }
    },
    async handleGetWineSearcher(item, ShowAllSellers, ShowOtherCaseSizes) {
      try {
        await this.$store.dispatch(
          'wineCard/activeCard/getWineSearcherPrices',
          {
            WineCardID: item.id,
            ShowAllSellers,
            ShowOtherCaseSizes,
            BottleSize: 'C' // Cases
          }
        )
      } catch (e) {
        // Noop
      }
    },
    async updateWineCard(item, updateDetails) {
      try {
        await this.saveWineCardAndSetActive({
          id: item.id,
          updatedCardDetails: {
            ...updateDetails,
            auditAmendedDate: item.auditAmendedDate
          }
        })
        this.$notify.success('Wine Card Updated updated successfully')
      } catch (e) {
        this.$notifyError(e.message)
      }
    }
  }
}
</script>

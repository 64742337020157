import Vue from 'vue'
import { merge } from 'lodash'
import { login, refreshToken } from '../api/user'
import { setToken } from '../api/graphQLInstance'
import { setToken as setAxiosToken, setInstance } from '../api/axios'
const jwtDecode = require('jwt-decode')
const localStorageKey = 'wv_user'
const localStorageSettingsKey = 'wv_user_settings'

export const state = () => ({
  user: {},
  documentTokenDetails: {},
  authenticated: false,
  token: null,
  documentToken: null,
  loading: false,
  loginError: null,
  returnToPath: null,
  officeAuthorisationIDs: [1, 2, 3, 4, 5, 8],
  settings: {
    hideZeroStock: true,
    emptyCasesOnly: false,
    looseBottlesOnly: false,
    loosePriceOnly: false,
    showCategoriesAndAppellations: false,
    dateFormat: 'DD MMM YYYY',
    wineBrowserPdfFilePreview: false,
    wineBrowserPdfExportType: 'file',
    wineBrowserPdfFileType: 'pdf',
    wineBrowserPdfHeading: 'Selected Wines',
    purchaseOrderPdfFilePreview: false,
    purchaseOrderPdfExportType: 'file',
    purchaseOrderPdfFileType: 'pdf',
    sortExclusions: false,
    purchaseOrderPdfHeading: 'New Stock',
    showOtherCaseSizes: false,
    showAllSellers: false,
    showOrderLines: false,
    showMonthlyTotals: false,
    sortByWine: false,
    landscapeShippingInvoice: true,
    reportPriceListContent: 'full_list',
    reportPriceListRecipientDays: 'fourteen',
    reportPriceListFileType: 'pdf',
    defaultPurchaseOrderUsageId: 2,
    defaultSalesOrderUsageId: 1,
    customerReviewCompanyPrefix: '',
    customerReviewMinEmailDays: 7,
    customerReviewMinAutoEmailDays: 7,
    customerReviewMinWineDays: 30,
    customerReviewMaxHighPriceCheckDays: 60,
    customerReviewExistingSageAccount: true,
    customerReviewAListOnly: true,
    customerReviewFastStock: true,
    sendPromoEmailCopy: false,
    copyInEmails: false
  }
})

export const getters = {
  isOfficeUser: state =>
    state.officeAuthorisationIDs.indexOf(state.user.authorisationId) >= 0,
  isWarehouseUser: state =>
    state.officeAuthorisationIDs.indexOf(state.user.authorisationId) <= 0,
  userCan: state => (accessKey, permissionKey) => {
    for (let i = 0; i < state.user.authorisation.length; i++) {
      if (state.user.authorisation[i].AccessBlockControlKey === accessKey) {
        return state.user.authorisation[i][permissionKey]
      }
    }
    return false
  }
}

export const mutations = {
  setReturnToPath(state, returnTo) {
    state.returnToPath = returnTo
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setLoginError(state, error) {
    state.loginError = error
  },
  setToken(state, token) {
    state.token = token
    state.user = jwtDecode(token)
  },
  setDocumentToken(state, token) {
    state.documentToken = token
    state.documentTokenDetails = jwtDecode(token)
  },
  setAuthenticated(state, authenticated) {
    state.authenticated = authenticated
  },
  setSetting(state, { key, value }) {
    Vue.set(state.settings, key, value)
  },
  setSettingObj(state, settings) {
    // Do not apply these and always leave as the default.
    delete settings.defaultPurchaseOrderUsageId
    delete settings.defaultSalesOrderUsageId
    // merging to settings
    state.settings = merge({}, state.settings, settings)
  }
}

export const actions = {
  loadTokenFromLocal({ commit }) {
    if (window && window.localStorage) {
      const encodedUser = window.localStorage.getItem(localStorageKey)
      if (encodedUser) {
        const userData = JSON.parse(encodedUser)
        const user = jwtDecode(userData.token)
        const expired = Date.now() >= user.exp * 1000
        if (user.id && !expired) {
          commit('setToken', userData.token)
          commit('setDocumentToken', userData.documentToken)
          commit('setAuthenticated', true)
        }
      }
    }
  },
  loadSettingsFromLocal({ commit }) {
    if (window && window.localStorage) {
      const encodedSettings = window.localStorage.getItem(
        localStorageSettingsKey
      )
      if (encodedSettings) {
        const settings = JSON.parse(encodedSettings)
        commit('setSettingObj', settings.settings)
      }
    }
  },
  setSetting({ commit, state }, args) {
    commit('setSetting', args)
    if (window && window.localStorage) {
      const encoded = JSON.stringify({
        settings: state.settings
      })
      window.localStorage.setItem(localStorageSettingsKey, encoded)
    }
  },
  saveTokenLocally({ state }) {
    const encoded = JSON.stringify({
      token: state.token,
      documentToken: state.documentToken
    })
    if (window && window.localStorage) {
      window.localStorage.setItem(localStorageKey, encoded)
    }
  },
  async login({ commit, state, dispatch }, credentials) {
    commit('setLoading', true)
    commit('setLoginError', null)
    try {
      const response = await login(credentials)
      if (response.data.success) {
        commit('setToken', response.data.token)
        commit('setDocumentToken', response.data.documentToken)
        setToken(response.data.token)
        setInstance(this.$axios)
        setAxiosToken(response.data.token)
        commit('setAuthenticated', true)
        dispatch('saveTokenLocally')
      } else {
        commit('setLoginError', response.data.error)
      }
      return response.data.success
    } catch (e) {
      console.error(e)
      commit('setLoginError', 'Could not log you in')
    } finally {
      commit('setLoading', false)
    }
  },
  removeToken() {
    if (window && window.localStorage) {
      window.localStorage.removeItem(localStorageKey)
    }
  },
  async refreshToken({ commit, dispatch, state }) {
    try {
      const response = await refreshToken(state.token)
      if (response.data.success) {
        commit('setToken', response.data.token)
        commit('setDocumentToken', response.data.documentToken)
        commit('setAuthenticated', true)
        dispatch('saveTokenLocally')
        return true
      } else {
        commit('setAuthenticated', false)
      }
    } catch (e) {
      commit('setAuthenticated', false)
    }
    if (window && window.localStorage) {
      window.localStorage.removeItem(localStorageKey)
    }
    return false
  }
}

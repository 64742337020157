import Entity from './entity'

const fields = {
  SellerNameMatch: {
    type: String
  }
}

const SellerNameMatch = new Entity('SellerNameMatch', fields)

SellerNameMatch.createQuery('getAll', 'sellerNameMatchGetAll', fields)
SellerNameMatch.createMutation('insert', 'sellerNameMatchAdd', fields, fields)
SellerNameMatch.createMutation('delete', 'sellerNameMatchDelete', fields, [])

export default SellerNameMatch

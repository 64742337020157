import Entity from './entity'

const fields = {
  SOID: {
    type: Number
  },
  SOLID: {
    type: Number
  },
  POLID: {
    type: Number
  },
  OrderDate: {
    type: String
  },
  Customer: {
    type: String
  },
  ContactID: {
    type: Number
  },
  SalesPrice: {
    type: String
  },
  CostPrice: {
    type: String
  },
  AllocatedBottles: {
    type: Number
  },
  UnallocatedBottles: {
    type: Number
  },
  RotationNumber: {
    type: String
  },
  UsageCode: {
    type: Number
  },
  Profit: {
    type: String
  },
  PercentageProfit: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  MatchedTransaction: {
    type: Number
  },
  IsTemporaryHold: {
    type: Number
  },
  LCBLocation: {
    type: String
  },
  ETCID: {
    type: Number
  },
  SageSupplierAcctNo: {
    type: String
  }
}

const StockCardSale = new Entity('StockCardSale', fields)

StockCardSale.createQuery('get', 'stockCardSalesGet', fields)

export default StockCardSale

import {
  getContactsToReview,
  getContactWinesToReview,
  skipReviewCycleForContactsBatch
} from '@/api/reviews/reviews'
import { updateContact } from '@/api/contacts/contacts'
import { batchUpdateContactWinePromotions } from '@/api/reviews/contactwinepromotions'
import { reduce, concat, merge, map } from 'lodash'
import Vue from 'vue'
import moment from 'moment'

export const state = () => ({
  loading: false,
  saving: false,
  savingContactId: 0,
  wineListLoading: false,
  list: [],
  wineList: [],
  page: 1,
  perPage: 30,
  total: 0,
  pageOffsets: {},
  results: [],
  indexedId: {},
  loadedPage: [],
  orderBy: null,
  orderByDirection: 'ASC'
})

export const getters = {
  pageResults: state => {
    const offset = state.pageOffsets[state.page] || 0
    return state.results.slice(offset, offset + state.perPage)
  },
  allResults: state => {
    return state.results
  },
  getPage: state => page => {
    const offset = state.pageOffsets[page]
    if (offset === undefined) return null
    return state.results.slice(offset, offset + state.perPage)
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setResults(state, results) {
    state.results = results
    state.indexedId = reduce(
      results,
      (keyMap, r, i) => {
        keyMap[r.ContactID] = i
        return keyMap
      },
      {}
    )
  },
  appendResults(state, results) {
    state.results = concat(state.results, results)
    state.indexedId = reduce(
      state.results,
      (keyMap, r, i) => {
        keyMap[r.ContactID] = i
        return keyMap
      },
      {}
    )
  },
  resetSelection(state) {
    state.results = []
    state.indexedId = {}
    state.page = 1
    // state.selectedForPromotion = {}
    state.loadedPage = []
    state.pageOffsets = {}
    state.setActiveId = null
    // state.searchState = 'reset'
  },

  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    // console.log('set Page', page)
    state.page = page
  },
  setPageOffset(state, page) {
    Vue.set(state.pageOffsets, page, state.results.length)
  },
  setLoadedPage(state, page) {
    if (state.loadedPage.indexOf(page) < 0) {
      state.loadedPage.push(page)
    }
  },
  setOrderBy(state, key) {
    state.orderBy = key
  },
  setOrderByDirection(state, direction) {
    state.orderByDirection = direction === 'ASC' ? 'ASC' : 'DESC'
  },
  setSearchState(state, mode) {
    state.searchState = mode
  },
  setSavingContactId(state, contactId) {
    if (contactId) {
      state.saving = true
      state.savingContactId = contactId
    } else {
      state.saving = false
      state.savingContactId = null
    }
  },
  setWineListLoading(state, loading) {
    state.wineListLoading = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  setWineList(state, list) {
    state.wineList = list
  },
  updateCustomerById(state, contact) {
    const idx = state.list.findIndex(p => p.ContactID === contact.ContactID)
    if (idx > -1) {
      const merged = merge(state.list[idx], contact)
      Vue.set(state.list, idx, merged)
    }
  },
  removeCustomerById(state, contact) {
    const idx = state.list.findIndex(p => p.ContactID === contact.ContactID)
    if (idx > -1) {
      Vue.delete(state.list, idx)
    }
  },
  updateWineListPromotionOption(
    state,
    { WineCardID, ContactID, PromotionOption }
  ) {
    const idx = state.wineList.findIndex(
      p => p.ContactID === ContactID && p.WineCardID === WineCardID
    )
    if (idx > -1) {
      const merged = merge(state.wineList[idx], {
        WineCardID,
        ContactID,
        PromotionOption: parseInt(PromotionOption)
      })
      Vue.set(state.wineList, idx, merged)
    }
  }
}

export const actions = {
  async performSearch({ commit, state }, searchParameters) {
    try {
      // console.log('Loading Page', state.page)
      commit('setLoading', true)
      commit('setSearchState', 'reset')
      // searchParameters.orderBy = state.orderBy
      // searchParameters.orderByDirection = state.orderByDirection
      const response = await getContactsToReview(
        state.page,
        state.perPage,
        searchParameters
      )
      commit('setSearchState', 'searched')
      commit('setPageOffset', state.page)
      commit('setResults', response.contactsToReviewPaginatedResults)
      commit('setTotal', response.total)
      commit('setLoadedPage', state.page)
      commit('setLoading', false)
    } catch (e) {
      commit('setSearchState', 'reset')
      commit('setPageOffset', 1)
      commit('setResults', [])
      commit('setTotal', 0)
      commit('setLoadedPage', 1)
      commit('setLoading', false)
      throw e
    }
  },
  async changePage({ commit, state }, { page, searchParameters }) {
    if (state.loadedPage.indexOf(page) >= 0) {
      commit('setPage', page)
      return
    }
    // console.log('reviews/changePage', page, searchParameters)
    commit('setLoading', true)
    // searchParameters.orderBy = state.orderBy
    // searchParameters.orderByDirection = state.orderByDirection
    const response = await getContactsToReview(
      page,
      state.perPage,
      searchParameters
    )
    commit('setPageOffset', page)
    commit('appendResults', response.contactsToReviewPaginatedResults)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  },
  async loadList({ commit }, args) {
    commit('setLoading', true)
    try {
      // console.log(args)
      const response = await getContactsToReview(args)
      // console.log(response)
      if (response) {
        commit('setList', response.contactsToReviewPaginatedResults)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadWineList({ commit }, args) {
    commit('setWineListLoading', true)
    try {
      const response = await getContactWinesToReview(args)
      if (response) {
        commit('setWineList', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setWineListLoading', false)
    }
  },
  async updateContact({ commit, dispatch }, contact) {
    commit('setSavingContactId', contact.ContactID)
    try {
      const newContact = await updateContact(contact)
      commit('updateCustomerById', newContact)
      return newContact
    } catch (e) {
      throw e
    } finally {
      commit('setSavingContactId', null)
      commit('setLoading', false)
    }
  },
  async skipReviewCycleForContact(
    { commit, dispatch },
    { ContactID, DoNotReview, WinePromotions }
  ) {
    commit('setSavingContactId', ContactID)
    try {
      await batchUpdateContactWinePromotions({
        ContactID,
        DoNotReview,
        WinePromotions
      })
      return true
    } catch (e) {
      throw e
    } finally {
      commit('setSavingContactId', null)
      commit('setLoading', false)
    }
  },
  async skipReviewCycleForLoadedCustomers({ commit, state }) {
    const now = moment().format('YYYY-MM-DD HH:mm:ss')
    const ids = map(state.list, 'ContactID')
    commit('setLoading', true)
    try {
      return await skipReviewCycleForContactsBatch({
        Contacts: ids,
        Date: now
      })
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

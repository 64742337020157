<template>
  <span>
    <a v-if="anchor" href="#" :disabled="disabled" @click="openUrl">
      <slot>
        Robert Parker Website
      </slot>
    </a>
    <button v-else :class="buttonClass" :disabled="disabled" @click="openUrl">
      <slot>
        Robert Parker Website
      </slot>
    </button>
  </span>
</template>

<script>
export default {
  props: {
    producerName: {
      type: String,
      required: false,
      default: ''
    },
    wineName: {
      type: String,
      required: false,
      default: ''
    },
    vintage: {
      type: [String, Number],
      required: false,
      default: ''
    },
    width: {
      type: String,
      required: false,
      default: ''
    },
    anchor: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: 'btn wv-button wv-button-gray-gradient wb-button-no-padding'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url() {
      let url = 'https://www.robertparker.com/search/wines?q={keywords}'
      url = url.replace('{keywords}', encodeURIComponent(this.keywords))
      return url
    },
    keywords() {
      return (
        this.wineNameProcessed +
        ' ' +
        this.producerNameProcessed +
        ' ' +
        this.vintageProcessed
      ).trim()
    },
    producerNameProcessed() {
      if (
        this.producerName &&
        this.producerName.toUpperCase() !== 'UNSPECIFIED'
      ) {
        return this.producerName.trim()
      }
      return ''
    },
    wineNameProcessed() {
      if (this.wineNameStartsWithCH) {
        return this.wineName.substring(3, 47).trim()
      }
      return this.wineName.trim()
    },
    vintageProcessed() {
      const intVinage = parseInt(this.vintage)
      if (isNaN(intVinage)) {
        return ''
      }
      return String(this.vintage).trim()
    },
    wineNameStartsWithCH() {
      return this.wineName.toUpperCase().substring(0, 3) === 'CH '
    },
    imageStyle() {
      return {
        width: this.width + 'px'
      }
    }
  },
  methods: {
    openUrl() {
      if (this.disabled === false) {
        window.open(this.url)
        this.$emit('click')
      } else {
        this.$emit('disabled-click')
      }
    }
  }
}
</script>

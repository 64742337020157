<template>
  <all-wine-notes v-model="showAllNotesModal" />
</template>

<script>
import AllWineNotes from '~/components/Modal/AllWineNotesModal'

export default {
  components: {
    AllWineNotes
  },
  computed: {
    showAllNotesModal: {
      get() {
        return this.$store.state.modal.showAllNotesModal
      },
      set(value) {
        this.$store.commit('modal/setShowAllNotesModal', value)
      }
    }
  },
  watch: {
    showAllNotesModal(shown) {
      if (shown) {
        this.$store.dispatch('wineCard/notes/getAllNotes')
      }
    }
  }
}
</script>

import gql from 'graphql-tag'
import axios from 'axios'
import { client } from './graphQLInstance'

export const login = creds => {
  return axios.post(process.env.apiHost + '/api/login', creds)
}

export const refreshToken = currentToken => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + currentToken
    }
  }
  return axios.get(process.env.apiHost + '/api/refresh', config)
}

export const getUser = async id => {
  const result = await client.query({
    query: gql`
      query RootQueryType{
        user(RecordId: ${id}) {
          UserID
          UserName
          LoginName
          Password
          Disabled
          Superuser
          Hide
          Developer
          SageLoginName
          SagePassword
          AuditCreatedDate
          AuditCreatedBy
          AuditAmendedDate
          AuditAmendedBy
          AuditAmendedByUserName
          Initials
          EmailAddress
          LivExLoginName
          LivExPassword
          WineSearcherLoginName
          WineSearcherPassword
          BordeauxIndexLoginName
          BordeauxIndexPassword
          DisplayDebugs  
       }       
     }    
    `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.user
}

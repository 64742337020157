<template>
  <div>
    <b-form-group
      label="Subject Line"
      label-size="sm"
      label-cols="1"
      :label-class="{green: isDirty('cpEmlSn')}"
    >
      <b-form-input v-model="cpEmlSn" :class="{green: isDirty('cpEmlSn')}" size="sm" :disabled="loading" />
    </b-form-group>
    <group-box class="mt-3">
      <template slot="header">
        Body (Merged Lists)
      </template>
      <b-row>
        <b-col cols="2">
          Wines bought by customers with similar tastes, and some wines you might like to try
        </b-col>
        <b-col>
          <b-textarea v-model="cpEmlBb" :class="{green: isDirty('cpEmlBb')}" :disabled="loading" />
        </b-col>
      </b-row>
    </group-box>
    <group-box class="mt-3">
      <template slot="header">
        Body (Separate Lists)
      </template>
      <b-row>
        <b-col cols="2">
          Wines bought by customers with similar tastes
        </b-col>
        <b-col>
          <b-textarea v-model="cpEmlBp" :class="{green: isDirty('cpEmlBp')}" :disabled="loading" />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="2">
          Some wines you might like to try
        </b-col>
        <b-col>
          <b-textarea v-model="cpEmlBm" :class="{green: isDirty('cpEmlBm')}" :disabled="loading" />
        </b-col>
      </b-row>
    </group-box>
  </div>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import { formObjectMixin } from '~/mixins/forms'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '~/helpers/helpers'

export default {
  components: {
    GroupBox
  },
  mixins: [formObjectMixin, validationMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapObjectToVModel(['cpEmlSn', 'cpEmlBb', 'cpEmlBp', 'cpEmlBm'])
  }
}
</script>

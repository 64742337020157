import Entity from './entity'

export const fields = {
  id: {
    type: String
  },
  expires_at: {
    type: String
  },
  success: {
    type: Boolean
  },
  errored: {
    type: Boolean
  },
  messages: {
    type: Array
  },
  queued: {
    type: Number
  },
  completed: {
    type: Number
  }
}

const JobProcessor = new Entity('JobProcessor', fields)
JobProcessor.createQuery('get', 'jobGet')
export default JobProcessor

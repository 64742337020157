<template>
  <group-box :body-class="isValidClass" :header="false">
    <b-row>
      <b-col class="col-12 py-2">
        <b-form-group
          label="Average Cost Price"
          label-for="costPrice"
          label-size="sm"
          label-cols-xxl="6"
          class="form-control--input-121"
        >
          <b-form-input
            id="costPrice"
            :value="costPrice | round(2) | currency"
            size="sm"
            disabled
            class="font-weight-bold text-right"
          />
        </b-form-group>
        <b-form-group
          label="List Price"
          label-for="listPriceClass"
          label-size="sm"
          label-cols-xxl="6"
          class="form-control--input-121"
        >
          <price-confirmation-change
            id="listPriceClass"
            v-model="editedListPrice"
            :input-class="{listPriceClass: true, 'text-right': true, 'font-weight-bold': true, 'form-control form-control-sm': true}"
            :show-confirm="false"
            :disabled="isWarehouseUser"
            @keyup="changedPriceForMarkup = $event"
          />
        </b-form-group>
        <b-form-group
          label="Markup"
          label-for="markupPercentage"
          label-size="sm"
          label-cols-xxl="6"
          class="form-control--input-121"
        >
          <b-form-input
            id="listPriceClass"
            :class="{'text-danger' : markupBelowThreshold, 'font-weight-bold text-right' : true}"
            :value="markupPercentage"
            size="sm"
            disabled
          />
        </b-form-group>
        <b-form-group
          id="total-stock"
          v-b-tooltip.hover.topright="'(Avail/WFS/NFS/OOC)'"
          label="Total Stock"
          label-for="availableBottles"
          label-size="sm"
          label-cols-xxl="6"
          class="form-control--input-121"
        >
          <b-form-input
            id="availableStock"
            :value="availableBottles | bottlesToCase(Number(caseSize)) | toString"
            size="sm"
            class="text-center font-weight-bold"
            disabled
          />
        </b-form-group>
        <b-form-group
          v-if="displayDepeciatedMarkup"
          label="Depreciated Markup"
          label-for="depreciatedMarkupPercentage"
          label-size="sm"
          label-cols-xxl="6"
          class="form-control--input-121"
        >
          <b-form-input
            id="depreciatedMarkupPercentage"
            :class="{'text-danger' : depreciatedMarkupBelowThreshold, 'font-weight-bold text-right' : true}"
            :value="depreciatedMarkupPercentage"
            size="sm"
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Avg Days In Stock"
          label-for="averageDaysInStock"
          label-size="sm"
          label-cols-xxl="6"
          class="form-control--input-121"
        >
          <b-form-input
            id="averageDaysInStock"
            :value="averageDaysInStock"
            size="sm"
            disabled
            class="font-weight-bold text-right"
          />
        </b-form-group>
        <b-form-group
          :class="{ 'text-right': true, ...promotionalPriceClass}"
          label="Promote"
          label-for="promotionalDateFormatted"
          label-size="sm"
          label-cols-xxl="5"
          class="mb-0"
        >
          <b-row>
            <b-col class="col-2 pb-0">
              <b-form-checkbox
                :checked="isPromoChecked"
                :indeterminate="isPromoIndeterminate"
                :disabled="isWarehouseUser"
                size="sm"
                class="mt-1 ml-1"
                @click.native="handlePromoChange"
              />
            </b-col>
            <b-col class="col-10 pb-0">
              <b-form-input
                :value="promotionalDateFormatted"
                disabled
                :class="{'form-control':true, 'form-control-sm': true, ...promotionalPriceClass, 'font-weight-bold': true}"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
  </group-box>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, minValue, decimal } from 'vuelidate/lib/validators'
import moment from 'moment'
import GroupBox from '~/components/Base/GroupBox'
import PriceConfirmationChange from '../../Base/PriceConfirmationChange'
import userMixin from '~/mixins/user'

export default {
  components: {
    GroupBox,
    PriceConfirmationChange
  },
  mixins: [validationMixin, userMixin],
  props: {
    costPrice: {
      type: Number,
      required: true
    },
    sellingPrice: {
      type: Number,
      required: true
    },
    averageDaysInStock: {
      type: Number,
      required: true
    },
    availableBottles: {
      type: Number,
      required: true
    },
    promotionDate: {
      type: String,
      required: false,
      default: ''
    },
    promoUserChanged: {
      type: String,
      default: null
    },
    caseSize: {
      type: Number,
      default: 12
    },
    cssClass: {
      type: String,
      default: 'h-100'
    }
  },
  data() {
    return {
      changedListPrice: null,
      changedPromotionalDate: null,
      promotionalDateState: null,
      changedPriceForMarkup: null
    }
  },
  computed: {
    userHasEnabledPromote() {
      return (
        this.promoUserChanged !== '1900-01-01' && this.promoUserChanged !== null
      )
    },
    userHasDisabledPromote() {
      return (
        this.promoUserChanged !== null && this.promoUserChanged === '1900-01-01'
      )
    },
    promotionalDateFormatted() {
      if (this.editedPromotionalDate) {
        const dateFormat = this.$store.state.user.settings.dateFormat
        return moment(this.editedPromotionalDate, 'YYYY-MM-DD').format(
          dateFormat
        )
      }
      if (
        this.promotionalDateState === 'indeterminate' &&
        this.hasPromotionDate
      ) {
        const dateFormat = this.$store.state.user.settings.dateFormat
        return moment(this.promotionDate, 'YYYY-MM-DD').format(dateFormat)
      }
      return ''
    },
    hasPromotionDate() {
      return this.promotionDate !== null
    },
    editedPromotionalDate() {
      if (this.changedPromotionalDate !== null) {
        return this.changedPromotionalDate
      }
      if (this.userHasEnabledPromote) {
        return moment(new Date()).format('YYYY-MM-DD')
      }
      if (this.userHasDisabledPromote) {
        return null
      }
      return this.promotionDate
    },
    isPromoChecked() {
      if (this.promotionalDateState === null) {
        if (this.userHasEnabledPromote) {
          return true
        }
        if (this.userHasDisabledPromote) {
          return false
        }
      }
      return this.promotionalDateState === 'checked'
    },
    isPromoIndeterminate() {
      if (this.promotionalDateState === null) {
        if (this.userHasEnabledPromote || this.userHasDisabledPromote) {
          return false
        }
      }
      return (
        (this.promotionalDateState === null && this.hasPromotionDate) ||
        this.promotionalDateState === 'indeterminate'
      )
    },
    displayDepeciatedMarkup() {
      return (
        this.$store.state.global.data.AnnualDepreciationRate > 0 ||
        this.$store.state.global.data.AnnualBottleStorageCost > 0
      )
    },
    markupPercentage() {
      return this.markUp + '%'
    },
    markupBelowThreshold() {
      return this.markUp < this.$store.state.global.data.MarkUpPercent
    },
    depreciatedMarkupBelowThreshold() {
      return (
        this.depreciatedMarkUp < this.$store.state.global.data.MarkUpPercent
      )
    },
    depreciatedMarkupPercentage() {
      return this.depreciatedMarkUp + '%'
    },
    depreciatedMarkUp() {
      if (this.costPrice === 0) {
        return 0
      }
      if (this.changedEditedListPrice === '') {
        return 0
      }
      const costOfStorage =
        (this.$store.state.global.data.AnnualBottleStorageCost *
          this.averageDaysInStock *
          this.availableBottles) /
        365.25

      const costOfMoney =
        (this.$store.state.global.data.AnnualDepreciationRate *
          this.averageDaysInStock *
          this.costPrice) /
        365.25

      const markup =
        ((this.changedEditedListPrice -
          this.costPrice -
          costOfStorage -
          costOfMoney) *
          100) /
        this.costPrice
      return Math.round(markup)
    },

    markUp() {
      if (this.costPrice === 0) {
        return 0
      }
      if (this.changedEditedListPrice === '') {
        return 0
      }
      const markup =
        ((this.changedEditedListPrice - this.costPrice) * 100) / this.costPrice
      return Math.round(markup * 10) / 10
    },
    changedEditedListPrice() {
      if (this.changedPriceForMarkup === null) {
        return this.editedListPrice
      }
      return this.changedPriceForMarkup
    },
    editedListPrice: {
      get() {
        if (this.changedListPrice === null) {
          return this.sellingPrice
        }
        return this.changedListPrice
      },
      set(value) {
        if (value === this.sellingPrice) {
          value = null
        }
        this.changedListPrice = parseFloat(value)
        if (isNaN(this.changedListPrice)) {
          this.changedListPrice = ''
        } else {
          this.$emit('change-list-price', parseFloat(value), this.isValid)
        }
      }
    },
    promotionalDateChanged() {
      if (this.promotionalDateState === null) {
        if (this.userHasEnabledPromote || this.userHasDisabledPromote) {
          return true
        }
      }
      const checkState = this.hasPromotionDate ? 'indeterminate' : 'unchecked'
      if (checkState !== 'indeterminate') {
        if (this.promoUserChanged !== null) {
          return true
        }
      }
      return (
        this.promotionalDateState !== null &&
        this.promotionalDateState !== checkState
      )
    },
    anyChanges() {
      return this.promotionalDateChanged || this.changedListPrice !== null
    },
    promotionalPriceClass() {
      return {
        'dark-green': this.changedPromotionalDate !== null
      }
    },
    listPriceClass() {
      return {
        'dark-green': this.changedListPrice !== null
      }
    },
    isValidClass() {
      return {
        'light-green-background': !this.$v.$invalid && this.anyChanges,
        'pink-background': this.$v.$invalid && this.anyChanges,
        'p-1': true,
        [this.cssClass]: this.cssClass.length > 0
      }
    },
    isValid() {
      return this.anyChanges && this.$v.$invalid === false
    },
    promotionDateShort() {
      return moment(this.promotionDate).format('YYYY-MM-DD')
    }
  },
  watch: {
    markUp(value) {
      this.$emit('change-markup', value)
    },
    promotionalDateState(state) {
      if (state === 'checked') {
        this.changedPromotionalDate = moment(new Date()).format('YYYY-MM-DD')
        this.$emit(
          'change-promotional-date',
          this.changedPromotionalDate,
          this.isValid
        )
      }
      if (state === 'unchecked') {
        this.changedPromotionalDate = ''
        this.$emit('change-promotional-date', '1900-01-01', this.isValid)
      }
      if (state === 'indeterminate') {
        this.changedPromotionalDate = null
        const isValid =
          this.userHasDisabledPromote ||
          this.userHasEnabledPromote ||
          this.isValid
        this.$emit('change-promotional-date', '__default__', isValid)
      }
    }
  },
  methods: {
    handlePromoChange(e) {
      e.preventDefault()
      if (this.promotionalDateState === null) {
        if (this.userHasEnabledPromote && this.hasPromotionDate) {
          this.promotionalDateState = 'indeterminate'
        } else if (this.userHasEnabledPromote && !this.hasPromotionDate) {
          this.promotionalDateState = 'unchecked'
        } else if (this.userHasDisabledPromote && this.hasPromotionDate) {
          this.promotionalDateState = 'indeterminate'
        } else {
          this.promotionalDateState = 'checked'
        }
      } else if (this.promotionalDateState === 'checked') {
        if (this.hasPromotionDate) {
          this.promotionalDateState = 'indeterminate'
        } else {
          this.promotionalDateState = 'unchecked'
        }
      } else if (this.promotionalDateState === 'indeterminate') {
        this.promotionalDateState = 'unchecked'
      } else {
        this.promotionalDateState = 'checked'
      }
    }
  },
  validations: {
    editedListPrice: {
      required,
      minValue: minValue(0.1),
      decimal
    }
  }
}
</script>

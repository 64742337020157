import Entity from './entity'

const fields = {
  DeliveryInstructionsID: {
    type: Number
  },
  DeliveryInstructions: {
    type: String
  },
  IsPhysicalDelivery: {
    type: Boolean
  }
}

const DeliveryInstructionsDropDown = new Entity(
  'DeliveryInstructionsDropDown',
  fields
)
DeliveryInstructionsDropDown.createQuery('get', 'deliveryInstructionDropDown')

export default DeliveryInstructionsDropDown

export default {
  methods: {
    measureText(pText, pStyle) {
      const lDiv = document.createElement('div')

      document.body.appendChild(lDiv)

      if (pStyle != null) {
        lDiv.style = pStyle
      }
      lDiv.style.fontSize = '1rem'
      lDiv.style.position = 'absolute'
      lDiv.style.left = -1000
      lDiv.style.top = -1000

      lDiv.innerHTML = pText
      const width = lDiv.clientWidth
      document.body.removeChild(lDiv)
      return width
    },
    measureLongestInTable(key, items, title) {
      const headingSize = this.measureText(title)
      const biggestLine = items.reduce((a, l) => {
        const rowSize = this.measureText(l[key])
        if (rowSize > a) {
          return rowSize
        }
        return a
      }, 0)
      return headingSize > biggestLine ? headingSize : biggestLine
    }
  }
}

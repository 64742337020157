import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _15c1dc23 = () => interopDefault(import('../pages/administration/index.vue' /* webpackChunkName: "pages/administration/index" */))
const _1fd31550 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _4fbbf7d4 = () => interopDefault(import('../pages/deliveries.vue' /* webpackChunkName: "pages/deliveries" */))
const _43f6d2a8 = () => interopDefault(import('../pages/etally/index.vue' /* webpackChunkName: "pages/etally/index" */))
const _77841523 = () => interopDefault(import('../pages/old-reviews.vue' /* webpackChunkName: "pages/old-reviews" */))
const _6a04a07a = () => interopDefault(import('../pages/purchases/index.vue' /* webpackChunkName: "pages/purchases/index" */))
const _67bfe04a = () => interopDefault(import('../pages/quick-search.vue' /* webpackChunkName: "pages/quick-search" */))
const _6444fe6d = () => interopDefault(import('../pages/reports.vue' /* webpackChunkName: "pages/reports" */))
const _9bed5988 = () => interopDefault(import('../pages/reserves/index.vue' /* webpackChunkName: "pages/reserves/index" */))
const _77f3da6e = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _5e5d6ac3 = () => interopDefault(import('../pages/rotation/index.vue' /* webpackChunkName: "pages/rotation/index" */))
const _f14af086 = () => interopDefault(import('../pages/sales/index.vue' /* webpackChunkName: "pages/sales/index" */))
const _4111861a = () => interopDefault(import('../pages/shipping/index.vue' /* webpackChunkName: "pages/shipping/index" */))
const _07922cec = () => interopDefault(import('../pages/stock-card/index.vue' /* webpackChunkName: "pages/stock-card/index" */))
const _5e3d17ae = () => interopDefault(import('../pages/wine-browser/index.vue' /* webpackChunkName: "pages/wine-browser/index" */))
const _b042d7f6 = () => interopDefault(import('../pages/wine-card/index.vue' /* webpackChunkName: "pages/wine-card/index" */))
const _7c28166d = () => interopDefault(import('../pages/administration/company-information.vue' /* webpackChunkName: "pages/administration/company-information" */))
const _a10da232 = () => interopDefault(import('../pages/administration/lcb-delivery-charges.vue' /* webpackChunkName: "pages/administration/lcb-delivery-charges" */))
const _40f6ee4f = () => interopDefault(import('../pages/administration/live-ex-alerts.vue' /* webpackChunkName: "pages/administration/live-ex-alerts" */))
const _d02e05a6 = () => interopDefault(import('../pages/administration/photo-rules.vue' /* webpackChunkName: "pages/administration/photo-rules" */))
const _aa3e058c = () => interopDefault(import('../pages/administration/pricing-groups.vue' /* webpackChunkName: "pages/administration/pricing-groups" */))
const _23e61929 = () => interopDefault(import('../pages/administration/profiles.vue' /* webpackChunkName: "pages/administration/profiles" */))
const _53d7ba64 = () => interopDefault(import('../pages/administration/rates.vue' /* webpackChunkName: "pages/administration/rates" */))
const _5b2bb659 = () => interopDefault(import('../pages/administration/users.vue' /* webpackChunkName: "pages/administration/users" */))
const _6df487e5 = () => interopDefault(import('../pages/administration/web.vue' /* webpackChunkName: "pages/administration/web" */))
const _6240e32d = () => interopDefault(import('../pages/administration/wine-matcher.vue' /* webpackChunkName: "pages/administration/wine-matcher" */))
const _7ae2a46a = () => interopDefault(import('../pages/administration/wine-searcher.vue' /* webpackChunkName: "pages/administration/wine-searcher" */))
const _2d062cf8 = () => interopDefault(import('../pages/purchases/import.vue' /* webpackChunkName: "pages/purchases/import" */))
const _ccb34518 = () => interopDefault(import('../pages/purchases/returns.vue' /* webpackChunkName: "pages/purchases/returns" */))
const _7f3e26ba = () => interopDefault(import('../pages/reserves/broking/index.vue' /* webpackChunkName: "pages/reserves/broking/index" */))
const _381fcaa8 = () => interopDefault(import('../pages/reserves/storage/index.vue' /* webpackChunkName: "pages/reserves/storage/index" */))
const _33b707e6 = () => interopDefault(import('../pages/sales/oversold.vue' /* webpackChunkName: "pages/sales/oversold" */))
const _3b27a21a = () => interopDefault(import('../pages/web/contacts/index.vue' /* webpackChunkName: "pages/web/contacts/index" */))
const _235f2988 = () => interopDefault(import('../pages/web/purchases/index.vue' /* webpackChunkName: "pages/web/purchases/index" */))
const _c7bca7fc = () => interopDefault(import('../pages/web/sales/index.vue' /* webpackChunkName: "pages/web/sales/index" */))
const _4b2dbdee = () => interopDefault(import('../pages/reserves/stock-card/contact/search.vue' /* webpackChunkName: "pages/reserves/stock-card/contact/search" */))
const _3526f729 = () => interopDefault(import('../pages/reserves/stock-card/contact/_id.vue' /* webpackChunkName: "pages/reserves/stock-card/contact/_id" */))
const _34a17246 = () => interopDefault(import('../pages/reserves/stock-card/wine/_id.vue' /* webpackChunkName: "pages/reserves/stock-card/wine/_id" */))
const _e110886a = () => interopDefault(import('../pages/reserves/broking/_id.vue' /* webpackChunkName: "pages/reserves/broking/_id" */))
const _02f05710 = () => interopDefault(import('../pages/reserves/storage/_id.vue' /* webpackChunkName: "pages/reserves/storage/_id" */))
const _8de15bca = () => interopDefault(import('../pages/web/contacts/_id.vue' /* webpackChunkName: "pages/web/contacts/_id" */))
const _cb8c2420 = () => interopDefault(import('../pages/web/purchases/_id.vue' /* webpackChunkName: "pages/web/purchases/_id" */))
const _34f1edc0 = () => interopDefault(import('../pages/contacts/_id.vue' /* webpackChunkName: "pages/contacts/_id" */))
const _64e05414 = () => interopDefault(import('../pages/etally/_id.vue' /* webpackChunkName: "pages/etally/_id" */))
const _caaa722a = () => interopDefault(import('../pages/purchases/_id.vue' /* webpackChunkName: "pages/purchases/_id" */))
const _68bd41eb = () => interopDefault(import('../pages/rotation/_id.vue' /* webpackChunkName: "pages/rotation/_id" */))
const _2e715565 = () => interopDefault(import('../pages/sales/_id.vue' /* webpackChunkName: "pages/sales/_id" */))
const _95430b58 = () => interopDefault(import('../pages/stock-card/_id.vue' /* webpackChunkName: "pages/stock-card/_id" */))
const _20c858a6 = () => interopDefault(import('../pages/wine-card/_id.vue' /* webpackChunkName: "pages/wine-card/_id" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/administration",
      component: _15c1dc23,
      name: "administration"
    }, {
      path: "/contacts",
      component: _1fd31550,
      name: "contacts"
    }, {
      path: "/deliveries",
      component: _4fbbf7d4,
      name: "deliveries"
    }, {
      path: "/etally",
      component: _43f6d2a8,
      name: "etally"
    }, {
      path: "/old-reviews",
      component: _77841523,
      name: "old-reviews"
    }, {
      path: "/purchases",
      component: _6a04a07a,
      name: "purchases"
    }, {
      path: "/quick-search",
      component: _67bfe04a,
      name: "quick-search"
    }, {
      path: "/reports",
      component: _6444fe6d,
      name: "reports"
    }, {
      path: "/reserves",
      component: _9bed5988,
      name: "reserves"
    }, {
      path: "/reviews",
      component: _77f3da6e,
      name: "reviews"
    }, {
      path: "/rotation",
      component: _5e5d6ac3,
      name: "rotation"
    }, {
      path: "/sales",
      component: _f14af086,
      name: "sales"
    }, {
      path: "/shipping",
      component: _4111861a,
      name: "shipping"
    }, {
      path: "/stock-card",
      component: _07922cec,
      name: "stock-card"
    }, {
      path: "/wine-browser",
      component: _5e3d17ae,
      name: "wine-browser"
    }, {
      path: "/wine-card",
      component: _b042d7f6,
      name: "wine-card"
    }, {
      path: "/administration/company-information",
      component: _7c28166d,
      name: "administration-company-information"
    }, {
      path: "/administration/lcb-delivery-charges",
      component: _a10da232,
      name: "administration-lcb-delivery-charges"
    }, {
      path: "/administration/live-ex-alerts",
      component: _40f6ee4f,
      name: "administration-live-ex-alerts"
    }, {
      path: "/administration/photo-rules",
      component: _d02e05a6,
      name: "administration-photo-rules"
    }, {
      path: "/administration/pricing-groups",
      component: _aa3e058c,
      name: "administration-pricing-groups"
    }, {
      path: "/administration/profiles",
      component: _23e61929,
      name: "administration-profiles"
    }, {
      path: "/administration/rates",
      component: _53d7ba64,
      name: "administration-rates"
    }, {
      path: "/administration/users",
      component: _5b2bb659,
      name: "administration-users"
    }, {
      path: "/administration/web",
      component: _6df487e5,
      name: "administration-web"
    }, {
      path: "/administration/wine-matcher",
      component: _6240e32d,
      name: "administration-wine-matcher"
    }, {
      path: "/administration/wine-searcher",
      component: _7ae2a46a,
      name: "administration-wine-searcher"
    }, {
      path: "/purchases/import",
      component: _2d062cf8,
      name: "purchases-import"
    }, {
      path: "/purchases/returns",
      component: _ccb34518,
      name: "purchases-returns"
    }, {
      path: "/reserves/broking",
      component: _7f3e26ba,
      name: "reserves-broking"
    }, {
      path: "/reserves/storage",
      component: _381fcaa8,
      name: "reserves-storage"
    }, {
      path: "/sales/oversold",
      component: _33b707e6,
      name: "sales-oversold"
    }, {
      path: "/web/contacts",
      component: _3b27a21a,
      name: "web-contacts"
    }, {
      path: "/web/purchases",
      component: _235f2988,
      name: "web-purchases"
    }, {
      path: "/web/sales",
      component: _c7bca7fc,
      name: "web-sales"
    }, {
      path: "/reserves/stock-card/contact/search",
      component: _4b2dbdee,
      name: "reserves-stock-card-contact-search"
    }, {
      path: "/reserves/stock-card/contact/:id?",
      component: _3526f729,
      name: "reserves-stock-card-contact-id"
    }, {
      path: "/reserves/stock-card/wine/:id?",
      component: _34a17246,
      name: "reserves-stock-card-wine-id"
    }, {
      path: "/reserves/broking/:id",
      component: _e110886a,
      name: "reserves-broking-id"
    }, {
      path: "/reserves/storage/:id",
      component: _02f05710,
      name: "reserves-storage-id"
    }, {
      path: "/web/contacts/:id",
      component: _8de15bca,
      name: "web-contacts-id"
    }, {
      path: "/web/purchases/:id",
      component: _cb8c2420,
      name: "web-purchases-id"
    }, {
      path: "/contacts/:id",
      component: _34f1edc0,
      name: "contacts-id"
    }, {
      path: "/etally/:id",
      component: _64e05414,
      name: "etally-id"
    }, {
      path: "/purchases/:id",
      component: _caaa722a,
      name: "purchases-id"
    }, {
      path: "/rotation/:id",
      component: _68bd41eb,
      name: "rotation-id"
    }, {
      path: "/sales/:id",
      component: _2e715565,
      name: "sales-id"
    }, {
      path: "/stock-card/:id?",
      component: _95430b58,
      name: "stock-card-id"
    }, {
      path: "/wine-card/:id?",
      component: _20c858a6,
      name: "wine-card-id"
    }, {
      path: "/",
      component: _f334fd00,
      name: "index"
    }],

    fallback: false
  })
}

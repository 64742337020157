import Entity from './entity'

const fields = {
  EntityId: {
    type: Number
  },
  EntityType: {
    type: Number
  },
  DisplayText: {
    type: String
  },
  LastAccessDateTime: {
    type: String
  }
}

const EntityAccess = new Entity('EntityAccess', fields)

EntityAccess.createQuery('get', 'entityAccessRecordsGet', fields)

export default EntityAccess

import {
  getSearchResults,
  getCategoryList
} from '@/api/purchaseOrders/purchaseMatches'
import { reduce, concat, without } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  loadingCategories: false,
  page: 1,
  perPage: 50,
  total: 0,
  loadedPage: [],
  pageOffsets: {},
  results: [],
  indexedId: {},
  orderBy: null,
  orderByDirection: 'ASC',
  selected: [],
  selectedAll: false,
  categoryList: [],
  excludedCategories: [],
  lnd: false,
  searchParameters: {
    term: '',
    wine: '',
    lnd: false,
    from: null,
    to: null,
    unallocated: false,
    unsoldStock: false,
    wfsOnly: false,
    WithReserves: false,
    OnlyReserveOrders: false
  }
})

export const getters = {
  pageResults: state => {
    const offset = state.pageOffsets[state.page] || 0
    return state.results.slice(offset, offset + state.perPage)
  },
  selected: state => id => {
    return state.selected.indexOf(id) >= 0
  },
  includedCategoryIds: state => {
    return state.categoryList
      .filter(c => state.excludedCategories.indexOf(c.CategoryID) < 0)
      .map(c => c.CategoryID)
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLnd(state, lnd) {
    state.loading = lnd === true
  },
  setLoadingCategories(state, loading) {
    state.loadingCategories = loading === true
  },
  setResults(state, results) {
    state.results = results
    state.indexedId = reduce(
      results,
      (keyMap, r, i) => {
        keyMap[r.id] = i
        return keyMap
      },
      {}
    )
  },
  appendResults(state, results) {
    state.results = concat(state.results, results)
    state.indexedId = reduce(
      results,
      (keyMap, r, i) => {
        keyMap[r.id] = i
        return keyMap
      },
      state.indexedId
    )
  },
  resetSelection(state) {
    state.results = []
    state.indexedId = {}
    state.page = 1
    state.loadedPage = []
    state.pageOffsets = {}
    state.setActiveId = null
    state.selected = []
    state.selectedAll = false
    state.categoryList = []
    state.excludedCategories = []
  },
  resetSearchParameters(state) {
    state.lnd = false
    state.searchParameters = {
      term: '',
      wine: '',
      lnd: false,
      from: null,
      to: null,
      unallocated: false,
      unsoldStock: false,
      wfsOnly: false
    }
  },
  resetJustSearchFilters(state) {
    state.lnd = false
    state.searchParameters = {
      term: state.searchParameters.term,
      wine: '',
      lnd: false,
      from: null,
      to: null,
      unallocated: false,
      unsoldStock: false,
      wfsOnly: false
    }
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setPageOffset(state, page) {
    Vue.set(state.pageOffsets, page, state.results.length)
  },
  setLoadedPage(state, page) {
    if (state.loadedPage.indexOf(page) < 0) {
      state.loadedPage.push(page)
    }
  },
  setOrderBy(state, key) {
    state.orderBy = key
  },
  setOrderByDirection(state, direction) {
    state.orderByDirection = direction === 'ASC' ? 'ASC' : 'DESC'
  },
  setSelectedValue(state, { id, value }) {
    if (value) {
      if (state.selected.indexOf(id) < 0) {
        state.selected.push(id)
      }
    } else {
      state.selected = without(state.selected, id)
    }
  },
  setSearchParameter(state, { key, value }) {
    if (key === 'LND') {
      state.lnd = value
    } else {
      Vue.set(state.searchParameters, key, value)
    }
  },
  setSelectedAll(state, selectedAll) {
    state.selectedAll = selectedAll === true
    state.selected = []
  },
  setCategoryList(state, categories) {
    state.categoryList = categories
  },
  setExcludeCategory(state, { CategoryID, exclude }) {
    if (exclude && state.excludedCategories.indexOf(CategoryID) < 0) {
      state.excludedCategories.push(CategoryID)
    } else {
      state.excludedCategories = without(state.excludedCategories, CategoryID)
    }
  }
}

export const actions = {
  async performSearch({ commit, state }, { searchParameters }) {
    commit('setLoading', true)
    commit('resetSelection')
    searchParameters.orderBy = state.orderBy
    searchParameters.orderByDirection = state.orderByDirection
    const response = await getSearchResults(
      state.page,
      state.perPage,
      searchParameters
    )
    commit('setPageOffset', state.page)
    commit('setResults', response.purchaseOrderSearchResult)
    commit('setTotal', response.total)
    commit('setLoadedPage', state.page)
    commit('setLoading', false)
    return response
  },
  async getCategoryList({ commit, state }, { searchParameters }) {
    commit('setLoadingCategories', true)
    const response = await getCategoryList(searchParameters)
    commit('setCategoryList', response)
    commit('setLoadingCategories', false)
    return response
  },
  async changePage({ commit, state }, { page, searchParameters }) {
    if (state.loadedPage.indexOf(page) >= 0) {
      commit('setPage', page)
      return
    }
    commit('setLoading', true)
    commit('setPage', page)
    searchParameters.orderBy = state.orderBy
    searchParameters.orderByDirection = state.orderByDirection
    const response = await getSearchResults(
      page,
      state.perPage,
      searchParameters
    )
    commit('setPageOffset', page)
    commit('appendResults', response.purchaseOrderSearchResult)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  },
  changeOrder(
    { commit, state, dispatch },
    { key, searchParameters, forWarehouse }
  ) {
    const doDesc = state.orderBy === key && state.orderByDirection === 'ASC'
    commit('resetSelection')
    commit('setOrderBy', key)
    commit('setOrderByDirection', doDesc ? 'DESC' : 'ASC')
    dispatch('performSearch', { searchParameters, forWarehouse })
  }
}

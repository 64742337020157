import Entity from './entity'

const fields = {
  WSOID: {
    type: Number
  },
  SOID: {
    type: Number
  },
  WebContactUUID: {
    type: String
  },
  GuestContactUUID: {
    type: String
  },
  WebReference: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  AuditAttachedToWebContactDate: {
    type: String
  },
  AuditRequestedDate: {
    type: String
  },
  AuditConvertedDate: {
    type: String
  },
  FirstName: {
    type: String
  },
  LastName: {
    type: String
  },
  CompanyName: {
    type: String
  },
  InvoiceAddressID: {
    type: Number
  },
  DeliveryAddressID: {
    type: Number
  },
  WarehouseAddressID: {
    type: Number
  },
  WebContactID: {
    type: Number
  },
  DeliveryInstructionsID: {
    type: Number
  },
  Archived: {
    type: Boolean
  },
  IsExportsSelected: {
    type: Boolean
  },
  IsInvoiceAddressSelected: {
    type: Boolean
  },
  IsDeliveryAddressSelected: {
    type: Boolean
  },
  IsWarehouseAddressSelected: {
    type: Boolean
  }
}

const WebSalesOrder = new Entity('WebSalesOrder', fields)
WebSalesOrder.createQuery(
  'search',
  'webSalesOrderSearch',
  {
    current_page: {
      type: Number
    },
    per_page: {
      type: Number
    },
    total: {
      type: Number
    },
    webSalesOrderPaginatedResults: {
      type: Array,
      fields: fields
    }
  },
  {
    WebContactID: {
      type: Number
    },
    OnlyIncomplete: {
      type: Boolean
    },
    Page: {
      type: Number
    },
    PerPage: {
      type: Number
    }
  }
)

WebSalesOrder.createMutation('create', 'webSalesOrderCreate', {
  ...Entity.onlyKeys(fields, ['WSOID']),
  SalesOrderLines: {
    type: Array,
    fields: {
      WSOLID: {
        type: Number
      },
      DutyStatusID: {
        type: Number
      }
    }
  }
})

WebSalesOrder.createMutation(
  'update',
  'webSalesOrderUpdate',
  {
    WSOID: {
      type: Number
    },
    Archived: {
      type: Boolean
    }
  },
  fields
)
export default WebSalesOrder

<template>
  <b-modal
    v-model="shown"
    size="md"
    modal-class="wv-modal"
    content-class="shadow"
    footer-class="justify-content-center"
    centered
    @ok="save"
  >
    <template v-slot:modal-header>
      New Producer
    </template>
    <producer-form ref="form" :value="formData" @change="handleFormChange" />
    <template v-slot:modal-footer="{ ok }">
      <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="ok()">
        <b-spinner v-if="saving" type="grow" label="Loading..." small />
        OK
      </b-button>
      <b-button class="btn wv-btn btn-sm" :disabled="saving" @click="shown=false">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { formResultMixin } from '../../../mixins/forms'
import ProducerForm from '~/components/Forms/ProducerForm'

export default {
  components: {
    ProducerForm
  },
  mixins: [formResultMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      formData: {
        ProducerName: '',
        ProducerOrder: 0,
        ProducerCat: 0,
        ProducerCode: ''
      },
      saving: false
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async save(e) {
      e.preventDefault()
      try {
        await this.$refs.form.validate()
        this.saving = true
        const producer = await this.$store.dispatch(
          'administration/producers/addProducer',
          this.form
        )
        this.shown = false
        this.$notify.success('Producer has been added')
        this.$emit('saved', producer)
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

import BillingRun from '~/models/storage/billingRun'
import { client } from '~/api/graphQLInstance'

export const getBillingRuns = async params => {
  // console.log('PARAMS', params)

  const result = await client.query({
    query: BillingRun.query('getAll', params)
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BillingRun.queries.getAll.name]
}

export const getBillingRun = async BRID => {
  const result = await client.query({
    query: BillingRun.query('get', { BRID: BRID })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[BillingRun.queries.get.name]
}

export const generateBillingRun = async params => {
  // console.log('generateBillingRun', params)
  const result = await client.query({
    query: BillingRun.mutate('add', params)
  })
  // console.log('generateBillingRun Results', result)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = BillingRun.mutations.add.name
  return result.data[key]
}

export const updateBillingRun = async params => {
  // console.log('updateBillingRun', params)
  const result = await client.query({
    query: BillingRun.mutate('update', params)
  })
  // console.log('updateBillingRun Results', result)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = BillingRun.mutations.update.name
  return result.data[key]
}

export const processBillingRun = async params => {
  // console.log('processBillingRun', params, params.BRID, params.Waive)
  const result = await client.query({
    query: BillingRun.mutate('process', {
      BRID: params.BRID,
      Waive: params.Waive
    })
  })
  // console.log('generateBillingRun Results', result)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = BillingRun.mutations.process.name
  return result.data[key]
}

export const duplicateBillingRun = async BRID => {
  // console.log('duplicateBillingRun', BRID)
  const result = await client.query({
    query: BillingRun.mutate('duplicate', { BRID: BRID })
  })
  // console.log('duplicateBillingRun Results', result)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = BillingRun.mutations.duplicate.name
  return result.data[key]
}

export const deleteBillingRun = async BRID => {
  // console.log('deleteBillingRun', BRID)
  const result = await client.query({
    query: BillingRun.mutate('delete', { BRID: BRID })
  })
  // console.log('deleteBillingRun Results', result)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  const key = BillingRun.mutations.delete.name
  return result.data[key]
}

<template>
  <b-modal
    ref="modal"
    v-model="value"
    centered
    modal-class="wv-modal"
    content-class="shadow"
    :hide-header-close="true"
    :ok-only="okOnly"
    :size="size"
    @hidden="hidden"
    @ok="ok"
    @cancel="cancel"
  >
    <template v-slot:modal-header>
      Are you Sure?
    </template>
    <slot>
      {{ prompt }}
      <span v-if="showContinue">
        Are you sure you want to continue?
      </span>
    </slot>
    <template v-slot:modal-footer="{ok,cancel}">
      <slot name="buttonsBefore" />
      <b-button ref="okButton" size="sm" class="wv-btn" @click="ok()">
        <slot name="okText">
          OK
        </slot>
      </b-button>
      <slot name="buttonsBetween" />
      <b-button size="sm" class="wv-btn" @click="cancel()">
        <slot name="cancelText">
          Cancel
        </slot>
      </b-button>
      <slot name="buttonsAfter" />
    </template>
    <div style="overflow: hidden; height:0; width: 0">
      <input ref="keyHandler" @keydown.enter.prevent="handleEnterEvent">
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    okOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    focusOk: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: false,
      confirmed: false,
      resolvePromise: null,
      title: null,
      prompt: null,
      showContinue: true,
      suppressPromise: false
    }
  },
  computed: {
    shown: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    shown(shown) {
      if (shown && this.focusOk) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.keyHandler.focus()
          }, 300)
        })
      }
    }
  },
  methods: {
    hide() {
      this.suppressPromise = true
      this.value = false
    },
    hidden() {
      if (this.suppressPromise) {
        this.suppressPromise = false
        return
      }
      if (this.confirmed) {
        this.resolvePromise(true)
      } else {
        this.resolvePromise(false)
      }
    },
    ok() {
      this.confirmed = true
    },
    cancel() {
      this.confirmed = false
    },
    confirm(title, prompt, showContinue) {
      this.confirmed = false
      this.value = true
      this.title = title
      this.prompt = prompt
      this.showContinue = showContinue === undefined ? true : showContinue
      return new Promise(resolve => {
        this.resolvePromise = resolve
      })
    },
    handleEnterEvent() {
      if (this.focusOk) {
        this.$refs.modal.onOk()
      }
    }
  }
}
</script>

// import gql from 'graphql-tag'
import { map } from 'lodash'
import { client } from '../graphQLInstance'
import WineCardSearch from '../../models/winecardsearch'
import {
  adaptWineCardFromAPI,
  adaptWineBrowserSearchParameters
} from '../../helpers/apiWineCardAdaptor'

export const getWineCardSearch = async (page, perPage, searchParameters) => {
  if (searchParameters.vintage1 === '') {
    searchParameters.vintage1 = null
  }
  if (searchParameters.vintage2 === '') {
    searchParameters.vintage2 = null
  }
  const result = await client.query({
    query: WineCardSearch.query('get', {
      Page: page,
      PerPage: perPage,
      ...adaptWineBrowserSearchParameters(searchParameters)
    })
  })

  if (result.errors) {
    throw new Error(result.errors[0].message)
  }

  const adaptedWineCardResults = map(
    result.data[WineCardSearch.queries.get.name].wineBrowserResults,
    adaptWineCardFromAPI
  )
  return {
    total: result.data[WineCardSearch.queries.get.name].total,
    wineBrowserResults: adaptedWineCardResults
  }
}

import { fields as jobFields } from './job'
import Entity from './entity'
import { fields as allocationFields } from './webpurchaseorderallocation'
import { fields as polFields } from './webpurchaseorderline'

const fields = {
  WPOID: {
    type: Number
  },
  POID: {
    type: Number
  },
  ContactID: {
    type: Number
  },
  WebContactUUID: {
    type: String
  },
  GuestContactUUID: {
    type: String
  },
  WebReference: {
    type: String
  },
  DeliveryCompany: {
    type: String
  },
  Title: {
    type: String
  },
  FirstName: {
    type: String
  },
  LastName: {
    type: String
  },
  EmailAddress: {
    type: String
  },
  Telephone: {
    type: String
  },
  Addr1: {
    type: String
  },
  Addr2: {
    type: String
  },
  Addr3: {
    type: String
  },
  Addr4: {
    type: String
  },
  Postcode: {
    type: String
  },
  CountryCode: {
    type: String
  },
  WarehouseAddressID: {
    type: Number
  },
  ReviewDate: {
    type: String
  },
  AuditAmendedDate: {
    type: String
  },
  AuditRequestedDate: {
    type: String
  },
  WebContactID: {
    type: Number
  },
  StatusID: {
    type: Number
  },
  OwnerUserID: {
    type: Number
  },
  PublicNotes: {
    type: String
  },
  PrivateNotes: {
    type: String
  },
  RejectionNotes: {
    type: String
  },
  SuppressRejectionEmail: {
    type: Boolean
  },
  Archived: {
    type: Boolean
  }
}

const purchaseOrderResponseFields = {
  WebPurchaseOrder: {
    type: Object,
    fields: fields
  },
  WebPurchaseOrderLines: {
    type: Array,
    fields: polFields
  },
  WebPurchaseOrderLineAllocations: {
    type: Array,
    fields: allocationFields
  }
}

const WebPurchaseOrder = new Entity('WebSalesOrder', fields)
WebPurchaseOrder.createQuery(
  'search',
  'webPurchaseOrderSearch',
  {
    current_page: {
      type: Number
    },
    per_page: {
      type: Number
    },
    total: {
      type: Number
    },
    webPurchaseOrderPaginatedResults: {
      type: Array,
      fields: fields
    }
  },
  {
    WebContactID: {
      type: Number
    },
    OnlyIncomplete: {
      type: Boolean
    },
    StatusID: {
      type: Number
    },
    OwnerUserID: {
      type: Number
    },
    Page: {
      type: Number
    },
    PerPage: {
      type: Number
    }
  }
)

WebPurchaseOrder.createQuery(
  'orderCount',
  'webPurchaseOrderSearch',
  {
    total: {
      type: Number
    }
  },
  {
    WebContactID: {
      type: Number
    },
    OnlyIncomplete: {
      type: Boolean
    },
    StatusID: {
      type: Number
    },
    OwnerUserID: {
      type: Number
    },
    Page: {
      type: Number
    },
    PerPage: {
      type: Number
    }
  }
)

WebPurchaseOrder.createQuery('get', 'webPurchaseOrderGet', fields, {
  WPOID: {
    type: Number
  }
})

WebPurchaseOrder.createMutation(
  'update',
  'webPurchaseOrderUpdate',
  {
    WPOID: {
      type: Number
    },
    StatusID: {
      type: Number
    },
    OwnerUserID: {
      type: Number
    },
    PublicNotes: {
      type: String
    },
    PrivateNotes: {
      type: String
    },
    RejectionNotes: {
      type: String
    },
    Title: {
      type: String
    },
    FirstName: {
      type: String
    },
    LastName: {
      type: String
    },
    EmailAddress: {
      type: String
    },
    Telephone: {
      type: String
    },
    Addr1: {
      type: String
    },
    Addr2: {
      type: String
    },
    Addr3: {
      type: String
    },
    Addr4: {
      type: String
    },
    Postcode: {
      type: String
    },
    CountryCode: {
      type: String
    },
    AddressAction: {
      type: String
    },
    ContactAction: {
      type: String
    },
    DeliveryCompany: {
      type: String
    },
    ContactID: {
      type: Number
    },
    SuppressRejectionEmail: {
      type: Boolean
    },
    Archived: {
      type: Boolean
    },
    WebContactAction: {
      type: Object,
      fields: {
        WebContactAction: {
          type: Number
        },
        Password: {
          type: String
        }
      }
    }
  },
  purchaseOrderResponseFields
)

WebPurchaseOrder.createMutation(
  'convert',
  'webPurchaseOrderConvert',
  {
    WPOID: {
      type: Number
    },
    Allocations: {
      type: Array,
      fields: {
        ...Entity.onlyKeys(allocationFields, [
          'AsDutyPaid',
          'Bottles',
          'FormatID',
          'OrigPkgCaseSize',
          'OriginalPackaging',
          'PhotoRequired',
          'Price',
          'UseEmptyOWC',
          'WPOLID',
          'WineCardID',
          'WineUsageCodeID'
        ]),
        Images: {
          type: Array
        }
      }
    },
    WebPurchaseOrder: {
      type: Object,
      fields: {
        ...Entity.onlyKeys(fields, [
          'ContactID',
          'Title',
          'FirstName',
          'LastName',
          'EmailAddress',
          'Telephone',
          'Addr1',
          'Addr2',
          'Addr3',
          'Addr4',
          'Postcode'
        ]),
        DeliveryCompany: {
          type: String
        },
        ForAttentionOf: {
          type: String
        }
      }
    },
    AddressAction: {
      type: String
    },
    ContactAction: {
      type: String
    },
    WebContactAction: {
      type: Object,
      fields: {
        WebContactAction: {
          type: Number
        },
        Password: {
          type: String
        }
      }
    },
    ReservePO: {
      type: Boolean
    }
  },
  purchaseOrderResponseFields
)

WebPurchaseOrder.createMutation(
  'importCSV',
  'purchaseOrderCSVColumnsImport',
  {
    Rows: {
      type: Array,
      fields: {
        WineCardID: {
          type: Number
        },
        FormatID: {
          type: Number
        },
        Price: {
          type: Number
        },
        DutyStatusID: {
          type: Number
        },
        TotalBottles: {
          type: Number
        },
        OrigPkgCaseSize: {
          type: Number
        },
        WineUsageCodeID: {
          type: Number
        },
        WineName: {
          type: String
        },
        WineVintage: {
          type: String
        }
      }
    },
    Headings: {
      type: Array
    },
    Name: {
      type: String
    },
    Currency: {
      type: String
    },
    SelectedColumns: {
      type: Array,
      fields: {
        Section: {
          type: String
        },
        Key: {
          type: String
        },
        Column: {
          type: String
        }
      }
    }
  },
  {
    ...jobFields,
    OwnerID: {
      type: Number
    }
  }
)

export default WebPurchaseOrder

import { client } from '../graphQLInstance'
import EntityAccess from '../../models/entityaccess'

export const getEntityAccess = async ({ topN, entityType }) => {
  const result = await client.query({
    query: EntityAccess.query('get', {
      TopN: topN,
      EntityType: entityType
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[EntityAccess.queries.get.name]
}

import { client } from '../graphQLInstance'
import StockCardPurchase from '../../models/stockcardpurchase'

export const getStockCardPurchases = async args => {
  const result = await client.query({
    query: StockCardPurchase.query('get', {
      StockCardId: args.id,
      StockBottleThreshold: args.bottleThreshold,
      SoldStock: args.soldStock,
      SalesOrderLineId: args.salesOrderLineId,
      RotationNumber: args.rotationNumber,
      IncludeReserves:
        args.includeReserves !== undefined ? args.includeReserves : false,
      LooseOnly: args.looseOnly !== undefined ? args.looseOnly : false
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[StockCardPurchase.queries.get.name]
}

import Notify from 'vue2-notify'
import Vue from 'vue'
import '@/assets/scss/notify/notify.scss'

Vue.use(Notify, { position: 'top-right' })

const ErrorMessage = {
  install: function(Vue, options) {
    Vue.prototype.$notifyError = function(message) {
      this.$notify.danger(message, {
        permanent: true,
        closeButtonClass: 'close'
      })
    }
  }
}

Vue.use(ErrorMessage)

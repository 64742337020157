<template>
  <group-box :header="true" class="h-100">
    <template slot="header">
      Duty Status
    </template>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="dutyStatus" value="allStock" class="text-left">
          All Stock
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="dutyStatus" value="dutyPaid" class="text-left">
          Duty Paid
        </d-form-radio>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <d-form-radio v-model="dutyStatus" value="inBond" class="text-left">
          In-Bond
        </d-form-radio>
      </b-col>
    </b-row>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'

export default {
  components: {
    GroupBox
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    dutyStatus: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<template>
  <vue-custom-scrollbar
    :settings="settings"
    @ps-scroll-y="$emit('ps-scroll-y')"
    @ps-scroll-x="$emit('ps-scroll-x')"
    @ps-scroll-up="$emit('ps-scroll-up')"
    @ps-scroll-down="$emit('ps-scroll-down')"
    @ps-scroll-left="$emit('ps-scroll-left')"
    @ps-scroll-right="$emit('ps-scroll-right')"
    @ps-y-reach-start="$emit('ps-y-reach-start')"
    @ps-y-reach-end="$emit('ps-y-reach-end')"
    @ps-x-reach-start="$emit('ps-x-reach-start')"
    @ps-x-reach-end="$emit('ps-x-reach-end')"
  >
    <slot />
  </vue-custom-scrollbar>
</template>

<script>
import { debounce } from 'lodash'
import vueCustomScrollbar from 'vue-custom-scrollbar'
export default {
  components: {
    vueCustomScrollbar
  },
  props: {
    settings: {
      type: Object,
      required: false,
      default() {
        return {
          swicher: true
        }
      }
    },
    refreshOnWindowResize: {
      type: Boolean,
      default: true
    },
    throttle: {
      type: Number,
      required: false,
      default: 100
    },
    refreshDelay: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      throttledEvent: null,
      timer: null
    }
  },
  created() {
    this.throttledEvent = debounce(this.refresh, this.throttle, {
      trailing: true
    })
  },
  destroyed() {
    this.$eventHub.$off('resize-window', this.onWindowResize)
  },
  mounted() {
    this.$eventHub.$on('resize-window', this.onWindowResize)
  },
  methods: {
    onWindowResize() {
      this.throttledEvent()
    },
    refresh() {
      if (this.timer !== null) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.$set(this.settings, 'swicher', false)
      this.timer = setTimeout(() => {
        this.$set(this.settings, 'swicher', true)
      }, this.refreshDelay)
    }
  }
}
</script>

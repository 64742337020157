<template>
  <b-form>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Wine Name"
      label-for="winename"
      :label-class="{green: isDirty('Name') }"
    >
      <b-form-textarea
        id="winename"
        v-model="Name"
        rows="2"
        max-rows="2"
        style="height: 150px;"
        :state="!$v.Name.$error && null"
        trim
        size="sm"
        :disabled="viewMode"
        :class="{green: isDirty('Name') }"
      />
      <b-form-invalid-feedback>
        Wine name is required
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Appellation"
      label-for="appellation"
      :label-class="{green: isDirty('AppellationName') }"
    >
      <b-input-group size="sm">
        <b-input
          id="appellation"
          v-model="AppellationName"
          :state="!$v.AppellationName.$error && null"
          :class="{green: isDirty('AppellationName') }"
          disabled
        />
        <b-form-invalid-feedback>
          Appellation is required
        </b-form-invalid-feedback>
        <b-input-group-append>
          <b-button :disabled="viewMode" class="wv-btn mx-2" size="sm" @click="$emit('toggle-appellations-modal')">
            Select
          </b-button>
          <b-button class="wv-btn" size="sm" @click="$emit('reset-appellation')">
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Duty Type"
      label-for="dutytype"
      :label-class="{green: isDirty('DutyTypeID') }"
    >
      <b-form-select
        id="dutytype"
        v-model="DutyTypeID"
        :options="dutyTypeOptions"
        :state="!$v.DutyTypeID.$error && null"
        :class="{green: isDirty('DutyTypeID') }"
        size="sm"
        :disabled="viewMode"
      />
      <b-form-invalid-feedback>
        Duty type is required
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      v-if="false"
      label-cols="4"
      label-size="sm"
      label=" Wine Code"
      label-for="winecode"
      :label-class="{green: isDirty('WineCode') }"
    >
      <b-input
        id="winecode"
        v-model="WineCode"
        type="text"
        :state="!$v.WineCode.$error && null"
        trim
        size="sm"
        :disabled="viewMode"
        :class="{green: isDirty('WineCode') }"
      />
      <b-form-invalid-feedback>
        Wine code is required and must be alphanumeric
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-size="sm"
      label="Wine Card Count"
      label-for="WineCardUsage"
      :label-class="{green: isDirty('WineCardUsage') }"
    >
      <b-input
        id="code"
        v-model="WineCardUsage"
        type="text"
        :state="!$v.WineCardUsage.$error && null"
        :class="{green: isDirty('WineCardUsage') }"
        trim
        size="sm"
        disabled
      />
    </b-form-group>
  </b-form>
</template>

<script>
import { required, integer, alphaNum } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '../../helpers/helpers'
import { formObjectMixin } from '../../mixins/forms'

export default {
  mixins: [validationMixin, formObjectMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      required: false
    },
    dutyTypeOptions: {
      type: Array,
      required: false,
      default() {
        return [
          {
            value: '0',
            text: 'Empty option'
          }
        ]
      }
    }
  },
  computed: {
    ...mapObjectToVModel([
      'Name',
      'AppellationName',
      'DutyTypeID',
      'WineCode',
      'WineCardUsage'
    ])
  },
  methods: {
    setAppellationName(name) {
      this.AppellationName = name
    }
  },
  validations: {
    Name: {
      required
    },
    DutyTypeID: {
      required,
      integer
    },
    AppellationName: {},
    WineCode: {
      alphaNum
    },
    WineCardUsage: {
      integer
    }
  }
}
</script>

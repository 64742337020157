<template>
  <b-row class="my-1">
    <b-col>
      <b-form-textarea
        v-model="Text"
        placeholder="Enter something..."
        rows="6"
        max-rows="8"
      />
    </b-col>
  </b-row>
</template>

<script>
import { formObjectMixin } from '~/mixins/forms'
import { mapObjectToVModel } from '~/helpers/helpers'

export default {
  mixins: [formObjectMixin],
  computed: {
    ...mapObjectToVModel(['Text'])
  }
}
</script>

import Entity from './entity'

const fields = {
  BaseDate: {
    type: String
  },
  TotalSales: {
    type: Number
  },
  TotalProfit: {
    type: Number
  },
  StartDate: {
    type: String
  },
  SalesStats: {
    type: Array,
    fields: ['TotalSales', 'TotalProfit', 'StartDate']
  },
  PurchaseStats: {
    type: Array,
    fields: {
      TotalPurchases: {
        type: Number
      }
    }
  },
  ReservesStats: {
    type: Array,
    fields: {
      TotalReserves: {
        type: Number
      }
    }
  },
  stockValues: {
    type: Array,
    fields: {
      StockPurchaseValue: {
        type: Number
      },
      StockSalesValue: {
        type: Number
      }
    }
  },
  reserveStockValues: {
    type: Array,
    fields: {
      StockReserveValue: {
        type: Number
      }
    }
  }
}

const KPIStats = new Entity('KPIStats', fields)

KPIStats.createQuery(
  'get',
  'kPIStatsGetAll',
  {
    salesDay: fields.SalesStats,
    salesWeek: fields.SalesStats,
    salesMonth: fields.SalesStats,
    salesYear: fields.SalesStats,
    purchaseDay: fields.PurchaseStats,
    purchaseWeek: fields.PurchaseStats,
    purchaseMonth: fields.PurchaseStats,
    purchaseYear: fields.PurchaseStats,
    reservesDay: fields.ReservesStats,
    reservesWeek: fields.ReservesStats,
    reservesMonth: fields.ReservesStats,
    reservesYear: fields.ReservesStats,
    stockValues: fields.stockValues,
    reserveStockValues: fields.reserveStockValues,
    lastCalculated: {
      type: String
    }
  },
  Entity.onlyKeys(fields, ['BaseDate'])
)

export default KPIStats

import {
  getShipperAddresses,
  updateShipperAddress,
  addShipperAddress,
  deleteShipperAddress
} from '@/api/administration/shipperaddresses'
import { find, findIndex, merge } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: false,
  loadingShipperAddress: false,
  list: [],
  activeShipperAddress: null,
  searchTerm: ''
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoadingShipperAddress(state, loading) {
    state.loadingShipperAddress = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  setActiveShipperAddress(state, { ShipperAddrID }) {
    state.activeShipperAddress = find(state.list, { ShipperAddrID })
  },
  removeShipperAddressFromList(state, { ShipperAddrID }) {
    const idx = findIndex(state.list, { ShipperAddrID })
    if (idx >= 0) {
      if (
        state.activeShipperAddress &&
        state.activeShipperAddress.ShipperAddrID === ShipperAddrID
      ) {
        state.activeShipperAddress = null
      }
      Vue.delete(state.list, idx)
    }
  },
  updateShipperAddressData(state, shipperAddress) {
    const idx = findIndex(state.list, {
      ShipperAddrID: shipperAddress.ShipperAddrID
    })
    if (idx >= 0) {
      const mergedAddr = merge({}, state.list[idx], shipperAddress)
      Vue.set(state.list, idx, mergedAddr)
    }
  },
  addShipperAddressToList(state, shipperAddress) {
    state.list.push(shipperAddress)
  }
}

export const getters = {
  getShipperAddressList(state) {
    return state.list
  },
  getActiveShipperAddress(state) {
    return state.activeShipperAddress
  }
}

export const actions = {
  async loadList({ commit, state }) {
    commit('setLoading', true)
    try {
      const response = await getShipperAddresses(state.searchTerm)
      commit('setList', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  setActiveShipperAddress({ commit }, shipperAddress) {
    commit('setActiveShipperAddress', shipperAddress)
  },
  async addShipperAddress({ commit }, shipperAddress) {
    commit('setLoadingShipperAddress', true)
    try {
      const response = await addShipperAddress(shipperAddress)
      commit('addShipperAddressToList', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingShipperAddress', false)
    }
  },
  async updateShipperAddress({ commit }, shipperAddress) {
    commit('setLoadingShipperAddress', true)
    try {
      const response = await updateShipperAddress(shipperAddress)
      commit('updateShipperAddressData', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingShipperAddress', false)
    }
  },
  async deleteShipperAddress({ commit }, shipperAddress) {
    commit('setLoadingShipperAddress', true)
    try {
      await deleteShipperAddress(shipperAddress)
      commit('removeShipperAddressFromList', shipperAddress)
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingShipperAddress', false)
    }
  }
}

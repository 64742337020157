import gql from 'graphql-tag'
import { client } from '../graphQLInstance'

export const getDeliveryPostcodes = async () => {
  const result = await client.query({
    query: gql`
      query RootQueryType {
        lCBDeliveryPostcodeGetAll {
          Postcode
          DistrictFrom
          DistrictTo
          RateBand
        }
      }
    `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.lCBDeliveryPostcodeGetAll
}

export const getDeliveryCharges = async () => {
  const result = await client.query({
    query: gql`
      query RootQueryType {
        lCBDeliveryChargesGetAll {
          CasesFrom
          CasesTo
          Band1
          Band2
          Band3
          Band4
          Band5
          Band6
          Band7
          Band8
          Band9
          Band10
        }
      }
    `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data.lCBDeliveryChargesGetAll
}

export const importFile = async (file, axios, token) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('token', token)
  const result = await axios.$post(
    process.env.apiHost + '/document/import/lcbdeliverycharges',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  if (result.success) {
    return result
  }
  throw new Error(result.message)
}

export default {
  methods: {
    getSearchStoreKey(path) {
      const prefix = this.searchStore ? this.searchStore : 'wineBrowser'
      const spacer = path[0] === '/' || prefix.substr(-1) === '/' ? '' : '/'
      return prefix + spacer + path
    },
    getSearchState(path) {
      const prefix = this.searchStore ? this.searchStore : 'wineBrowser'
      let currentStoreState = this.$store.state
      const prefixPaths = prefix.split('/').filter(function(path) {
        return path.length > 0
      })
      for (let i = 0; i < prefixPaths.length; i++) {
        currentStoreState = currentStoreState[prefixPaths[i]]
      }
      const pathPaths = path.split('/')
      for (let i = 0; i < pathPaths.length; i++) {
        currentStoreState = currentStoreState[pathPaths[i]]
      }
      return currentStoreState
    }
  }
}

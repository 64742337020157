<template>
  <div>
    <b-form-group
      label="Subject Line"
      label-size="sm"
      label-cols="1"
      :label-class="{green: isDirty('cpEmlSr')}"
    >
      <b-form-input v-model="cpEmlSr" :class="{green: isDirty('cpEmlSr')}" size="sm" :disabled="loading" />
    </b-form-group>
    <group-box class="mt-3">
      <template slot="header">
        Body
      </template>
      <b-textarea v-model="cpEmlBr" :class="{green: isDirty('cpEmlBr')}" :disabled="loading" />
    </group-box>
  </div>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import { formObjectMixin } from '~/mixins/forms'
import { validationMixin } from 'vuelidate'
import { mapObjectToVModel } from '~/helpers/helpers'

export default {
  components: {
    GroupBox
  },
  mixins: [formObjectMixin, validationMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapObjectToVModel(['cpEmlSr', 'cpEmlBr'])
  }
}
</script>

import Entity from './entity'
import SalesOrderTransaction from './salesordertransaction'
import SalesOrder from './salesorder'

const fields = {
  SOID: {
    type: Number
  },
  SOLID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  InventoryID: {
    type: Number
  },
  RotationCardID: {
    type: Number
  },
  RotationNumber: {
    type: String
  },
  OriginalPackaging: {
    type: String
  },
  SAID: {
    type: Number
  },
  UsageCode: {
    type: String
  },
  WineUsageCodeID: {
    type: Number
  },
  InventoryDutyStatusID: {
    type: Number
  },
  InventoryDutyStatus: {
    type: String
  },
  SaleDutyStatusID: {
    type: Number
  },
  LineAllocatedTotalBottles: {
    type: Number
  },
  LineAllocatedCases: {
    type: Number
  },
  LineAllocatedBottles: {
    type: Number
  },
  DateAllocated: {
    type: String
  },
  InventoryCases: {
    type: Number
  },
  InventoryBottles: {
    type: Number
  },
  TotalAllocatedCases: {
    type: Number
  },
  TotalAllocatedBottles: {
    type: Number
  },
  OutstandingTotalBottles: {
    type: Number
  },
  OutstandingCases: {
    type: Number
  },
  OutstandingBottles: {
    type: Number
  },
  CostPrice: {
    type: Number
  },
  POID: {
    type: Number
  },
  POLID: {
    type: Number
  },
  PurchaseOrderDate: {
    type: String
  },
  Supplier: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  InventoryStatusID: {
    type: Number
  },
  SalesAllocAuditAmendedDate: {
    type: String
  },
  ComputedWineName: {
    type: String
  },
  format: {
    type: String
  },
  year: {
    type: Number
  },
  NoteCount: {
    type: Number
  },
  WineCardNotesString: {
    type: String
  }
}

const SalesAllocation = new Entity('SalesAllocation', fields)

SalesAllocation.createQuery('getForSOID', 'salesOrderAllocationsGetAll', {
  ...Entity.onlyKeys(fields, ['CaseSize', 'CostPrice']),
  SalePrice: {
    type: Number
  },
  AllocationBottles: {
    type: Number
  }
})

SalesAllocation.createQuery(
  'getForSOLID',
  'salesOrderLineAllocationGet',
  fields
)

SalesAllocation.createMutation(
  'create',
  'salesAllocAdd',
  {
    SOLID: {
      type: Number
    },
    Bottles: {
      type: Number
    },
    InventoryID: {
      type: Number
    },
    IsOversell: {
      type: Boolean
    }
  },
  {
    SAID: {
      type: Number
    },
    SOLID: {
      type: Number
    },
    InventoryID: {
      type: Number
    },
    DateAlloc: {
      type: String
    },
    Bottles: {
      type: Number
    },
    AuditCreatedBy: {
      type: Number
    },
    AuditAmendedDate: {
      type: String
    },
    AuditAmendedBy: {
      type: Number
    },
    AuditAmendedByUserName: {
      type: String
    },
    Transaction: {
      type: Object,
      fields: SalesOrderTransaction.fields
    },
    Allocation: {
      type: Object,
      fields: fields
    },
    SalesOrder: {
      type: Object,
      fields: SalesOrder.fields
    }
  }
)

SalesAllocation.createMutation(
  'delete',
  'salesAllocDelete',
  {
    SAID: {
      type: Number
    },
    AuditAmendedDate: {
      type: String
    }
  },
  []
)

SalesAllocation.createMutation(
  'update',
  'salesAllocUpdate',
  {
    SAID: {
      type: Number
    },
    Bottles: {
      type: Number
    },
    AuditAmendedDate: {
      type: String
    }
  },
  {
    SAID: {
      type: Number
    },
    Bottles: {
      type: Number
    },
    AuditAmendedDate: {
      type: String
    },
    SOLID: {
      type: Number
    },
    InventoryID: {
      type: Number
    },
    DateAlloc: {
      type: String
    },
    Transaction: {
      type: Object,
      fields: SalesOrderTransaction.fields
    },
    Allocation: {
      type: Object,
      fields: fields
    },
    SalesOrder: {
      type: Object,
      fields: SalesOrder.fields
    }
  }
)

export default SalesAllocation

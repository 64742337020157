<template>
  <wine-notes-modal
    v-if="hasActiveCard"
    v-model="showWineNotesModal"
    :item="activeCard"
    :loading="notesLoading"
    :notes="notes"
    :selected-note="selectedNote"
    @select="setSelectedNote"
    @remove="removeSelectedNote"
  />
</template>

<script>
import WineNotesModal from '~/components/Modal/WineNotes'

export default {
  components: {
    WineNotesModal
  },
  computed: {
    showWineNotesModal: {
      get() {
        return this.$store.state.modal.showWineNotesModal
      },
      set(value) {
        this.$store.commit('modal/setShowWineNotesModal', value)
      }
    },
    hasActiveCard() {
      return this.activeCard && this.activeCard !== {}
    },
    activeCard() {
      return this.$store.state.wineCard.activeCard.activeBrowserCard
    },
    notesLoading() {
      return this.$store.state.wineCard.notes.loading
    },
    notes() {
      return this.$store.getters['wineCard/notes/getNotesForCardId'](
        this.wineCardId
      )
    },
    selectedNote() {
      return this.$store.getters['wineCard/notes/getSelectedNote']
    },
    wineCardId() {
      if (this.hasActiveCard) {
        return this.activeCard.id
          ? this.activeCard.id
          : this.activeCard.WineCardID
      }
      return null
    }
  },
  watch: {
    showWineNotesModal(shown) {
      if (shown) {
        this.$store.dispatch(
          'wineCard/notes/getNotesForCardId',
          this.wineCardId
        )
      }
    }
  },
  methods: {
    setSelectedNote(note) {
      this.$store.commit('wineCard/notes/setSelectedNoteId', note.id)
    },
    async removeSelectedNote(note) {
      try {
        await this.$store.dispatch('wineCard/notes/removeNoteId', note.id)
      } catch (e) {
        this.$notifyError('Could not Delete Note')
      }
    }
  }
}
</script>

import { getUsersList, userDelete, userUpdate, userAdd } from '@/api/users'
import { find, findIndex } from 'lodash'
import Vue from 'vue'

export const state = () => ({
  loading: true,
  loaded: false,
  list: [],
  activeUserId: 0
})

export const getters = {
  activeUser: state => {
    return find(state.list, { UserID: state.activeUserId })
  },
  dropdownOptions: state => {
    return state.list.filter(u => !u.Disabled).map(u => {
      return {
        value: u.UserID,
        text: u.UserName
      }
    })
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoaded(state, loaded) {
    state.loaded = loaded === true
  },
  setList(state, list) {
    state.list = list
  },
  appendUser(state, user) {
    state.list.push(user)
  },
  replaceUser(state, user) {
    const idx = findIndex(state.list, { UserID: user.UserID })
    if (idx >= 0) {
      Vue.set(state.list, idx, user)
    }
  },
  setActiveUser(state, { UserID }) {
    state.activeUserId = UserID
  },
  setDeleteUserFromList(state, { UserID }) {
    const idx = findIndex(state.list, { UserID })
    if (idx >= 0) {
      Vue.delete(state.list, idx)
    }
  }
}

export const actions = {
  async getListIfNotLoaded({ commit, state }) {
    if (state.loaded) {
      return
    }
    commit('setLoading', true)
    try {
      const response = await getUsersList()
      commit('setList', response)
      commit('setLoaded', true)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getList({ commit }) {
    commit('setLoading', true)
    try {
      const response = await getUsersList()
      commit('setList', response)
      commit('setLoaded', true)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async deleteUser({ commit }, { UserID, AuditAmendedDate }) {
    await userDelete({
      ID: UserID,
      AuditAmendedDate
    })
    commit('setDeleteUserFromList', { UserID })
  },
  async updateUser({ commit, dispatch }, user) {
    commit('setLoading', true)
    try {
      const response = await userUpdate(user)
      commit('replaceUser', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async addUser({ commit, dispatch }, user) {
    commit('setLoading', true)
    try {
      const response = await userAdd(user)
      commit('appendUser', response)
      return response
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}

import { isEmail } from '~/helpers/validators'
export default {
  methods: {
    parseEmailStringWithName(email) {
      const contact = {
        email: null,
        name: null
      }
      if (typeof email === 'string') {
        const idxOfBrace = email.indexOf('(')
        if (idxOfBrace >= 0) {
          contact.email = email.substring(0, idxOfBrace).trim()
          contact.name = email.substring(idxOfBrace + 1).trim()
          const idxOfLastBrace = contact.name.indexOf(')')
          if (idxOfLastBrace >= 0) {
            contact.name = contact.name
              .substring(0, contact.name.indexOf(')'))
              .trim()
          }
        } else {
          contact.email = email
        }
        if (isEmail(contact.email) === false) {
          contact.email = null
        }
      }
      return contact
    },
    getEmailSenderContactDetails(ignoreOverride) {
      if (
        process.env.mailOverrideSender !== null &&
        process.env.mailOverrideSender.length > 0 &&
        ignoreOverride !== true
      ) {
        return this.parseEmailStringWithName(process.env.mailOverrideSender)
      } else {
        return {
          name: this.$store.state.user.user.name,
          email: this.$store.state.user.user.email
        }
      }
    },
    getEmailCopyContactDetails(ignoreOverride) {
      if (
        process.env.mailOverrideCopySender !== null &&
        process.env.mailOverrideCopySender.length > 0 &&
        ignoreOverride !== true
      ) {
        return this.parseEmailStringWithName(process.env.mailOverrideCopySender)
      } else {
        return {
          name: this.$store.state.user.user.name,
          email: this.$store.state.user.user.email
        }
      }
    }
  }
}

/* eslint-disable */
import Vue from 'vue'
import moment from 'moment'


Vue.filter('decimalPlaces', function(value, decimals, useGrouping) {
  if(typeof value === 'string') {
    value = value.replace(/[^0-9\.]+/g,'')
  }
  const formatter = new Intl.NumberFormat(process.env.locale, {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    useGrouping: !!useGrouping
  })
  return formatter.format(value)
})

Vue.filter('percentage', function(value, decimals) {
  const formatter = new Intl.NumberFormat(process.env.locale, {
    style: 'decimal',
    currency: process.env.currency_iso,
    minimumFractionDigits: decimals
  })
  return formatter.format(value) + '%'
})

Vue.filter('currency', function(value) {
  const formatter = new Intl.NumberFormat(process.env.locale, {
    style: 'currency',
    currency: process.env.currency_iso,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formatter.format(value) /* $2,500.00 */
})

Vue.filter('round', function(value, decimals) {
  if (!value) {
    value = 0
  }

  if (!decimals) {
    decimals = 0
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  return value
})

Vue.filter('withCommas', function(number, decimals = null) {
  const format = {
    style: 'decimal',
    currency: process.env.currency_iso,
  }
  if (decimals !== null) {
    format.minimumFractionDigits = decimals
    format.maximumFractionDigits = decimals
  } else {
    format.minimumFractionDigits = 2
  }
  const formatter = new Intl.NumberFormat(process.env.locale, format)
  return formatter.format(number) /* $2,500.00 */
})

Vue.filter('toString', function(obj) {
  if (obj===null) {
    return ''
  }
  return obj.toString ? obj.toString() : ''
})

Vue.filter('nl2br', function(obj) {
  return obj.replace(/\n+/g, '<br>')
})

Vue.filter('lowestRPScore', function(rpScore) {
  rpScore = rpScore.trim()
  if (rpScore.length > 0 && rpScore.substring(0, 1) !== '-') {
    const mPos = rpScore.indexOf('-')
    if (mPos >= 0) {
      return parseFloat(rpScore.substring(0, mPos))
    } else if (rpScore.substring(rpScore.length - 1, rpScore.length) === '+') {
      return parseFloat(rpScore.substring(0, rpScore.length - 1))
    } else{
      return parseFloat(rpScore)
    }
  }
  return 0
})

function StockLevel(cases, caseBottles, caseSize, showZero, showMinus, showCaseSize) {
  this.cases = cases
  this.caseBottles = caseBottles
  this.caseSize = caseSize
  this.showZero = showZero
  this.showMinus = showMinus
  this.showCaseSize = showCaseSize
}

StockLevel.prototype.getBottleUnit = function(overrideCaseSize) {
  const caseSize = overrideCaseSize ? overrideCaseSize : this.caseSize
  if (caseSize === 12) {
    return 'b'
  } else if (caseSize === 24) {
    return 'hb'
  } else if (caseSize === 6) {
    return 'm'
  } else if (caseSize === 3) {
    return 'dm'
  } else {
    return ''
  }
}

StockLevel.prototype.toString = function() {
  let outputString = ''
  if (this.cases > 0 || (this.showMinus && this.cases < 0)) {
    outputString += this.cases + 'cs'
    if (this.showCaseSize) {
      outputString += ' x ' + this.caseSize
    }
  }
  if (this.caseBottles > 0 || this.showZero || (this.showMinus && this.caseBottles < 0)) {
    outputString += ' ' + this.caseBottles + this.getBottleUnit()
  } else if (this.cases === 0 && this.caseBottles === 0) {
    outputString += ' ' + this.caseBottles + this.getBottleUnit()
  }

  return outputString.trim()
}



Vue.filter('bottlesToCase', function(bottles, caseSize, showZero, showMinus, showCaseSize) {
  if (bottles === null || bottles === undefined) {
    return null
  }
  if (caseSize > 1) {
    let cases = Math.floor(Math.abs(bottles) / caseSize)
    if(bottles < 0) {
      cases = -cases
    }
    const caseBottles = bottles % caseSize

    return new StockLevel(cases, caseBottles, caseSize, showZero, showMinus, showCaseSize)
  }
  return new StockLevel(0, bottles, caseSize, showZero, showMinus, showCaseSize)
})

Vue.filter('formatDate', function(value, format, ignore1900) {
  if (!format) {
    format = 'Do MMM YY'
  }
  const date = moment(value)
  if (date.isValid()) {
    if (ignore1900 && date.year() === 1900 && date.day() === 1) {
      return ''
    }
    return date.format(format)
  }
  return ''
})

Vue.filter('formatDateTime', function(value, format, ignore1900) {
  if (!format) {
    format = 'Do MMM YY h:mm:ss a'
  }
  if (value === undefined) {
    return ''
  }
  const date = moment(value)
  if (date.isValid()) {
    if (ignore1900 && date.year() === 1900 && date.day() === 1) {
      return ''
    }
    return date.format(format)
  }
  return ''
})

Vue.filter('addressToString', function(address) {
  if (!address) {
    return ''
  }
  let outputString = ''
  for (let i = 1; i < 5; i++) {
    const key = 'Addr' + i
    if (address[key] && address[key].length > 0) {
      outputString += '\n' + address[key]
    }
  }
  return outputString.trim()
})

<template>
  <b-form id="wineCardForm" class="main--full-width">
    <div v-if="loadingProp" class="text-center text-primary my-2">
      <b-spinner type="grow" label="Loading..." />
    </div>
    <b-row v-else>
      <b-col class="col col-12 col-xxl-6">
        <group-box :body-class="changedClass" color="wv-card__title--wine">
          <template v-slot:header>
            {{ title }}
          </template>

          <group-box :body-class="changedClass" class="mb-3" color="wv-card__title--none">
            <template v-slot:header />
            <lwin-lookup-form
              ref="lwinForm"
              v-model="livExLwinNoZero"
              :state="!$v.livExLwin.$error && null"
              :label-class="{green: isDirty('livExLwin')}"
              :input-class="{green: isDirty('livExLwin')}"
              :loading="lwinSaving"
              :is-editing="isEditing"
              :button-disabled="saving || isWarehouseUser"
              @selected="handleSelectedLWIN"
              @new="$emit('clickNew', hasChanged)"
            />
            <b-form-group
              :label-class="{green: isDirty('wineNameId')}"
              class="mb-2"
              label-cols="2"
              label-size="sm"
              label="Wine Name"
              label-for="winename"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="winename"
                  v-model="wine_name"
                  :state="!$v.wineNameId.$error && null"
                  :class="{green: isDirty('wineNameId')}"
                  :disabled="true"
                />
                <b-input-group-append>
                  <b-button
                    size="sm"
                    class="btn wv-btn ml-2"
                    :disabled="isEditing || saving || livExLwinSelected || isWarehouseUser"
                    @click="$emit('wine-card-wine-name-modal')"
                  >
                    Select...
                  </b-button>
                </b-input-group-append>
                <b-form-invalid-feedback>
                  Wine Name has not been chosen.
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
            <b-form-group
              :label-class="{green: isDirty('Exception')}"
              class="mb-2"
              label-cols="2"
              label-size="sm"
              label="Exception"
              label-for="Exception"
            >
              <b-form-input
                id="Exception"
                v-model="Exception"
                size="sm"
                :class="{green: isDirty('Exception')}"
                :disabled="saving || isWarehouseUser"
              />
            </b-form-group>
            <b-form-group
              :label-class="{green: isDirty('producerId')}"
              class="mb-2"
              label-cols="2"
              label-size="sm"
              label="Producer"
              label-for="producer"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="producer"
                  v-model="producer"
                  :class="{green: isDirty('producerId')}"
                  :disabled="isEditing || saving || livExLwinSelected || isWarehouseUser"
                />
                <b-input-group-append>
                  <b-button
                    size="sm"
                    class="btn wv-btn mx-2"
                    :disabled="isEditing || saving || livExLwinSelected || isWarehouseUser"
                    @click="clearProducer()"
                  >
                    Clear
                  </b-button>
                  <b-button
                    size="sm"
                    class="btn wv-btn"
                    :disabled="isEditing || saving || livExLwinSelected || isWarehouseUser"
                    @click="$emit('wine-card-producer-modal')"
                  >
                    Select...
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              :label-class="{green: isDirty('year')}"
              class="mb-2 form-control--input-100"
              label-cols="2"
              label-size="sm"
              label="Vintage"
              label-for="vintage"
            >
              <b-form-input
                id="vintage"
                :value="formattedYear"
                type="text"
                size="sm"
                :state="!$v.year.$error && null"
                :class="{green: isDirty('year'), 'text-right': true}"
                :disabled="isEditing || saving || isWarehouseUser"
                @blur="vintageBlur"
                @input="year=$event"
              />
              <b-form-invalid-feedback>
                Wine vintage must be set to NV for non vintage or must be numeric
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              :label-class="{green: isDirty('format_id')}"
              class="mb-2"
              label-cols="2"
              label-size="sm"
              label="Format"
              label-for="format"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="format"
                  v-model="format_id"
                  name="format"
                  size="sm"
                  :disabled="isEditing || saving || isWarehouseUser"
                  :state="!$v.format_id.$error && null"
                  :class="{green: isDirty('format_id')}"
                >
                  <option selected :value="null">
                    &lt;Please Choose&gt;
                  </option>
                  <option
                    v-for="(item, index) in formatOptionsProp"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.text }}
                  </option>
                </b-form-select>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    class="btn wv-btn ml-2"
                    :disabled="isEditing || saving || isWarehouseUser"
                    @click="addFormatModal"
                  >
                    New...
                  </b-button>
                </b-input-group-append>
                <b-form-invalid-feedback>
                  <template v-if="$v.format_id.regionCheck === false">
                    Format is not valid for this region
                  </template>
                  <template v-else>
                    Format is required.
                  </template>
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
            <b-form-group
              class="mb-2"
              label-cols="2"
              label-size="sm"
              label="Appellation"
              label-for="appellation"
            >
              <b-form-input
                id="appellation"
                v-model="originalAppellation"
                size="sm"
                disabled
              />
            </b-form-group>
          </group-box>
          <b-form-group
            :label-class="{green: isDirty('listPrice')}"
            class="mb-2 form-control--input-group-121"
            label-cols="2"
            label-size="sm"
            label="List Price £"
            label-for="listPrice"
          >
            <b-input-group size="sm">
              <price-confirmation-change
                id="listPrice"
                v-model="listPrice"
                :input-class="{green: isDirty('listPrice'), 'text-right': true, 'form-control': true}"
                :state="!$v.listPrice.$error && null"
                :disabled="saving || isWarehouseUser"
                :show-confirm="false"
              />
              <b-form-invalid-feedback>
                <template v-if="!$v.listPrice.required">
                  List price is required
                </template>
                <template v-else-if="!$v.listPrice.maxDecimalPlaces">
                  List price is limited to 2 decimal places
                </template>
                <template v-else-if="!$v.listPrice.decimal">
                  List price must be numeric
                </template>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <b-form-group
            class="mb-2"
            label-cols="2"
            label-size="sm"
          >
            <div class="d-flex">
              <div class="flex-shrink-1">
                <b-form-checkbox
                  v-model="mixedCase"
                  class="small mt-1 ml-3"
                  :class="{green: isDirty('mixedCase')}"
                  :disabled="saving || isWarehouseUser"
                >
                  Mixed Case
                </b-form-checkbox>
              </div>
              <div class="flex-shrink-1">
                <b-form-checkbox
                  v-model="LivExLWinExempt"
                  v-b-tooltip="'If ticked this will omit this wine card from the wine matcher export'"
                  class="small mt-1 ml-3"
                  :class="{green: isDirty('LivExLWinExempt')}"
                  :disabled="saving || isWarehouseUser"
                >
                  LWIN Exempt
                </b-form-checkbox>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            v-if="mixedCase"
            class="mb-2 form-control--input-121"
            label-cols="2"
            label-size="sm"
            label="Mixed Case Size"
            label-for="MixedCaseDefaultSize"
          >
            <b-form-input
              id="MixedCaseDefaultSize"
              v-model="MixedCaseDefaultSize"
              type="text"
              size="sm"
              :state="!$v.year.$error && null"
              :class="{green: isDirty('MixedCaseDefaultSize'), 'text-right': true}"
              :disabled="saving || isWarehouseUser"
            />
            <b-form-invalid-feedback>
              Default Mixed Case Size Must be Set
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            class="mb-2 form-control--input-121"
            label-cols="2"
            label-size="sm"
            label="Avg Cost Price £"
            label-for="averageCostPrice"
          >
            <price-confirmation-change
              id="averageCostPrice"
              :value="price_weighted_average"
              size="sm"
              disabled
              input-class="text-right form-control"
              :show-confirm="false"
            />
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('categoryId')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Category"
            label-for="category"
          >
            <b-form-select
              id="category"
              v-model="categoryId"
              :state="!$v.categoryId.$error && null"
              :class="{green: isDirty('categoryId'), 'font-weight-bold': true}"
              :disabled="saving || isWarehouseUser"
              size="sm"
              name="category"
            >
              <option selected :value="null">
                &lt;Please Choose&gt;
              </option>
              <option
                v-for="(item, index) in categoryOptionsProp"
                :key="index"
                :value="item.value"
              >
                {{ item.text }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback>
              <template v-if="!$v.categoryId.required">
                Category must have a value selected
              </template>
              <template v-else-if="!$v.categoryId.dutyTypeInvalid">
                Category is inconsistent with Duty Type of {{ dutyName }} for Wine Name
              </template>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('countryId')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Country"
            label-for="country"
          >
            <b-form-select
              id="country"
              v-model="countryId"
              size="sm"
              name="country"
              :disabled="countryDisabled || saving || livExLwinSelected || isWarehouseUser"
              :class="{green: isDirty('countryId')}"
            >
              <option selected :value="null">
                &lt;Please Choose&gt;
              </option>
              <option
                v-for="(item, index) in countryOptionsProp"
                :key="index"
                :value="item.value"
              >
                {{ item.text }}
              </option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('CountryRegionID')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Region"
            label-for="countryRegion"
          >
            <b-input-group>
              <b-form-select
                id="countryRegion"
                v-model="CountryRegionID"
                size="sm"
                name="countryRegion"
                :disabled="saving || livExLwinSelected || isWarehouseUser"
                :class="{green: isDirty('CountryRegionID')}"
              >
                <option selected :value="null">
                  &lt;Please Choose&gt;
                </option>
                <option
                  v-for="(item, index) in selectedCountryRegionDropdownOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                  size="sm"
                  class="btn wv-btn ml-2"
                  :disabled="saving || livExLwinSelected || isWarehouseUser"
                  @click="showNewRegion"
                >
                  New...
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('CountrySubRegionID')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Sub Region"
            label-for="countrySubRegion"
          >
            <b-input-group>
              <b-form-select
                id="countrySubREgion"
                v-model="CountrySubRegionID"
                size="sm"
                name="countrySubRegion"
                :disabled="!hasSelectedRegion || saving || livExLwinSelected || isWarehouseUser"
                :class="{green: isDirty('CountrySubRegionID')}"
              >
                <option selected :value="null">
                  &lt;Please Choose&gt;
                </option>
                <option
                  v-for="(item, index) in selectedCountrySubRegionDropdownOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                  size="sm"
                  class="btn wv-btn ml-2"
                  :disabled="!hasSelectedRegion || saving || livExLwinSelected || isWarehouseUser"
                  @click="showNewSubRegion"
                >
                  New...
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('colourId')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Colour"
            label-for="colour"
          >
            <b-form-select
              id="colour"
              v-model="colourIdConsistant"
              size="sm"
              name="colour"
              :disabled="colourDisabled || saving || livExLwinSelected || isWarehouseUser"
              :options="wineColourOptions"
              :class="{green: isDirty('colourId')}"
            />
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('usageCodeId')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Usage"
            label-for="usage"
          >
            <b-form-select
              id="usage"
              v-model="usageCodeId"
              size="sm"
              name="usage"
              :state="!$v.usageCodeId.$error && null"
              :class="{green: isDirty('usageCodeId'), 'font-weight-bold': true}"
              :disabled="saving || isWarehouseUser"
            >
              <option
                v-for="(item, index) in usageOptionsProp"
                :key="index"
                :value="item.value"
              >
                {{ item.text }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback>
              Usage is required
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('DesignationID')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Designation"
            label-for="designation"
          >
            <b-input-group>
              <b-form-select
                id="designation"
                v-model="DesignationID"
                size="sm"
                name="designation"
                :class="{green: isDirty('DesignationID')}"
                :disabled="saving || livExLwinSelected || isWarehouseUser"
              >
                <option selected :value="null">
                  &lt;Please Choose&gt;
                </option>
                <option
                  v-for="(item, index) in designationDropdownOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                  size="sm"
                  class="btn wv-btn ml-2"
                  :disabled="saving || livExLwinSelected || isWarehouseUser"
                  @click="showNewDesignation"
                >
                  New...
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('ClassificationID')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Classification"
            label-for="classification"
          >
            <b-input-group>
              <b-form-select
                id="classification"
                v-model="ClassificationID"
                size="sm"
                name="classification"
                :class="{green: isDirty('ClassificationID')}"
                :disabled="saving || livExLwinSelected || isWarehouseUser"
              >
                <option selected :value="null">
                  &lt;Please Choose&gt;
                </option>
                <option
                  v-for="(item, index) in classificationDropdownOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                  size="sm"
                  class="btn wv-btn ml-2"
                  :disabled="saving || livExLwinSelected || isWarehouseUser"
                  @click="showNewClassification"
                >
                  New...
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('VineyardID')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Vineyard"
            label-for="vineyard"
          >
            <b-input-group>
              <b-form-select
                id="vineyard"
                v-model="VineyardID"
                size="sm"
                name="vineyard"
                :class="{green: isDirty('VineyardID')}"
                :disabled="saving || isWarehouseUser"
              >
                <option selected :value="null">
                  &lt;Please Choose&gt;
                </option>
                <option
                  v-for="(item, index) in vineyardDropdownOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                  size="sm"
                  class="btn wv-btn ml-2"
                  :disabled="saving || isWarehouseUser"
                  @click="showNewVineyard"
                >
                  New...
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label-class="{green: isDirty('extract')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="Extract"
            label-for="extract"
          >
            <b-form-input
              id="extract"
              v-model="extract"
              size="sm"
              :class="{green: isDirty('extract')}"
              :disabled="saving || isWarehouseUser"
            />
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('fda')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="FDA"
            label-for="fda"
          >
            <b-form-input
              id="fda"
              v-model="fda"
              size="sm"
              :class="{green: isDirty('fda')}"
              :disabled="saving || isWarehouseUser"
            />
          </b-form-group>
          <b-form-group
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="% Alcohol"
            label-for="alcohol"
            :label-class="{green: isDirty('percentage')}"
          >
            <b-input-group>
              <b-form-input
                id="alcohol"
                v-model="percentageNoZero"
                size="sm"
                :state="!$v.percentage.$error && null"
                :class="{green: isDirty('percentage')}"
                :disabled="saving"
              />
              <b-input-group-append>
                <b-button :disabled="percentageNoZero==='' || percentageNoZero===null" title="Click to change" size="sm" :variant="abvVerifyStateVariant" @click="handleChangeAbvVerifiedState">
                  {{ abvVerifyStateVariantText }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-if="value && value.AbvVerified > 0" class="small">
              Verified By: {{ value.AbvVerifiedByName }}
            </div>
            <b-form-invalid-feedback>
              % Alcohol must be between 0 and 100
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('ph')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="pH"
            label-for="ph"
          >
            <b-form-input
              id="ph"
              v-model="ph"
              size="sm"
              :class="{green: isDirty('ph')}"
              :disabled="saving || isWarehouseUser"
            />
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('so2')}"
            class="mb-2 form-control--input-239"
            label-cols="2"
            label-size="sm"
            label="SO2"
            label-for="sotwo"
          >
            <b-form-input
              id="sotwo"
              v-model="so2"
              size="sm"
              :class="{green: isDirty('so2')}"
              :disabled="saving || isWarehouseUser"
            />
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('description')}"
            class="mb-2"
            label-cols="2"
            label-size="sm"
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              rows="2"
              max-rows="2"
              size="sm"
              :class="{green: isDirty('description')}"
              :disabled="saving || isWarehouseUser"
            />
          </b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label-cols="4"
                label-size="sm"
                label="Purchases Made"
                label-for="purchasesMade"
              >
                <b-form-input
                  id="purchasesMade"
                  :value="purchase_order_line_usage"
                  type="number"
                  size="sm"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label-cols="4"
                label-size="sm"
                label="Sales Made"
                label-for="salesMade"
              >
                <b-form-input
                  id="salesMade"
                  :value="sales_order_line_usage"
                  type="number"
                  size="sm"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col offset="2">
              <b-form-group class="mb-2">
                <b-form-checkbox
                  v-model="doNotPromote"
                  class="small wv-check__alt"
                  :class="{green: isDirty('doNotPromote')}"
                  :disabled="saving || isWarehouseUser"
                >
                  Do Not Promote
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="sellAtLoss"
                  class="small wv-check__alt"
                  :class="{red: isDirty('sellAtLoss')}"
                  :disabled="saving || isWarehouseUser"
                >
                  Sell Loss-Making Stock
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </group-box>
      </b-col>
      <b-col class="col col-12 col-xxl-5">
        <group-box :body-class="changedClass" class="mb-4" color="wv-card__title--wine">
          <template v-slot:header>
            Robert Parker
          </template>
          <b-form-group
            :label-class="{green: isDirty('rp')}"
            label-cols="2"
            label-size="sm"
            label="Score"
            label-for="rpScore"
          >
            <b-row>
              <b-col>
                <b-form-input
                  id="rpScore"
                  v-model="rp"
                  type="text"
                  size="sm"
                  :state="!$v.rp.$error && null"
                  :disabled="saving"
                  :class="{green: isDirty('rp')}"
                />
                <b-form-invalid-feedback>
                  <template v-if="!$v.rp.required">
                    All wines must now have a Parker Score, or else a zero to indicate none available.
                  </template>
                  <template v-else-if="!$v.rp.isRobertParkerScore">
                    Wine Parker Score must be of the form 99, 99+, 99-100, -, ?, 99?.
                  </template>
                </b-form-invalid-feedback>
              </b-col>
              <b-col class="text-right">
                <robert-parker-button
                  :wine-name="wine_name"
                  :vintage="year"
                  :producer-name="producer"
                  :anchor="true"
                  :disabled="!wineNameFilledOut || saving"
                  @disabled-click="handleDisabledClick"
                >
                  <small class="font-weight-bold">
                    Parker Web Site
                  </small>
                </robert-parker-button>
                <b-button id="button-rp-button" variant="link" class="wv-btn wv-btn--info" size="sm">
                  <i class="material-icons">
                    info
                  </i>
                </b-button>
                <b-popover
                  target="button-rp-button"
                  triggers="click"
                  placement="bottomleft"
                >
                  <p class="mb-1 text-danger h5">Robert Parker</p>
                  <p class="mb-1">If clicking on the <span class="text-primary font-weight-bold">Robert Parker We Site</span> link brings up the login-screen on that site, then log-in and click the link again.</p>
                  <div class="text-center">
                    <b-button size="sm" class="wv-btn" @click="exitPopover">
                      Exit
                    </b-button>
                  </div>
                </b-popover>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('rpTastingNotes')}"
            label-cols="2"
            label-size="sm"
            label="Tasting Notes"
            label-for="rpTastingNotes"
          >
            <b-form-textarea
              id="rpTastingNotes"
              v-model="rpTastingNotes"
              rows="2"
              max-rows="2"
              maxlength="1000"
              size="sm"
              style="height: 150px;"
              :class="{green: isDirty('rpTastingNotes')}"
              :disabled="saving || isWarehouseUser"
            />
          </b-form-group>
        </group-box>
        <group-box :body-class="changedClass" class="mb-4" color="wv-card__title--wine">
          <template v-slot:header>
            Burghound
          </template>
          <b-form-group
            :label-class="{green: isDirty('bh')}"
            label-cols="2"
            label-size="sm"
            label="Score"
            label-for="bgScore"
          >
            <b-row>
              <b-col>
                <b-form-input
                  id="bgScore"
                  v-model="bh"
                  type="text"
                  size="sm"
                  :state="!$v.bh.$error && null"
                  :class="{green: isDirty('bh')}"
                  :disabled="bhDisabled || saving"
                />
                <b-form-invalid-feedback>
                  <template v-if="!$v.bh.required">
                    All wines must now have Burghound Score , or else a zero to indicate none available.
                  </template>
                  <template v-else-if="!$v.bh.isBHScore">
                    Wine Burghound Score must be of the form 99, 99+, 99-100, -, ?, 99?.
                  </template>
                </b-form-invalid-feedback>
              </b-col>
              <b-col class="text-right">
                <burghound-button
                  :wine-name="wine_name"
                  :anchor="true"
                  :disabled="!wineNameFilledOut || saving"
                  @disabled-click="handleDisabledClick"
                >
                  <small class="font-weight-bold">
                    Burghound Web Site
                  </small>
                </burghound-button>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            :label-class="{green: isDirty('bhTastingNotes')}"
            label-cols="2"
            label-size="sm"
            label="Tasting Notes"
            label-for="bgTastingNotes"
          >
            <b-form-textarea
              id="bgTastingNotes"
              v-model="bhTastingNotes"
              rows="2"
              max-rows="2"
              size="sm"
              maxlength="1000"
              style="height: 150px;"
              :class="{green: isDirty('bhTastingNotes')}"
              :disabled="bhDisabled || saving || isWarehouseUser"
            />
          </b-form-group>
        </group-box>
        <group-box color="wv-card__title--wine">
          <template v-slot:header>
            Web Links
          </template>
          <wine-searcher-button
            :wine-name="computedWineName"
            :vintage="year"
            :anchor="true"
            :disabled="!wineNameFilledOut || saving"
            @disabled-click="handleDisabledClick"
          >
            <small class="font-weight-bold">
              Wine Searcher Web Site
            </small>
          </wine-searcher-button>
          <live-ex-button
            :lwin="livExLwinNoZero"
            :wine-name="wine_name"
            :vintage="year"
            :anchor="true"
            :disabled="!wineNameFilledOut || saving"
            @disabled-click="handleDisabledClick"
          >
            <small class="font-weight-bold">
              Liv Ex Web Site
            </small>
          </live-ex-button>
        </group-box>
        <group-box v-if="isEditing" color="wv-card__title--wine" class="mt-4">
          <template v-slot:header>
            Wine Card Info
          </template>
          <div>
            <small>
              <span class="font-weight-bold">
                Created At:
              </span>
              {{ value._original.AuditCreatedDate | formatDateTime }}
            </small>
          </div>
          <div>
            <small>
              <span class="font-weight-bold">
                Last Updated At:
              </span>
              {{ value._original.AuditAmendedDate | formatDateTime }}
            </small>
          </div>
          <div>
            <small v-if="value._original.AuditAmendedByUserName" class="font-weight-bold">
              <span class="font-weight-bold">
                Last Updated By:
              </span>
              {{ value._original.AuditAmendedByUserName }}
            </small>
          </div>
        </group-box>
      </b-col>
      <b-col class="col col-12 col-xxl-1">
        <b-button block size="sm" class="btn wv-btn" :disabled="!isEditing || saving" @click="$emit('clickReload', hasChanged)">
          Reload
        </b-button>
        <b-button block size="sm" class="btn wv-btn" :disabled="!hasChanged && !isEditing || saving || isWarehouseUser" @click="$emit('clickNew', hasChanged)">
          New
        </b-button>
        <b-button block size="sm" class="btn wv-btn" :disabled="!hasChanged || saving" @click="handleSave">
          Save
          <b-spinner v-if="saving" type="grow" small />
        </b-button>
        <b-button block size="sm" class="btn wv-btn" :disabled="!isEditing || activeCardHasOrders || saving || isWarehouseUser" @click="$emit('clickDelete')">
          Delete
        </b-button>
        <b-button block size="sm" class="btn wv-btn" :disabled="!isEditing || saving || isWarehouseUser" @click="$emit('clickClone')">
          Clone
        </b-button>
        <div class="mt-4">
          <b-button block size="sm" class="btn wv-btn" :disabled="!isEditing || saving || isWarehouseUser" @click="$emit('clickStockCard')">
            Stock Card
          </b-button>
          <b-button block size="sm" class="btn wv-btn" :disabled="!isEditing || saving || isWarehouseUser" @click="$emit('clickReservesCard')">
            Res Card
          </b-button>
        </div>
      </b-col>
    </b-row>
    <new-country-region-modal
      v-model="showNewRegionModal"
      :saving="regionSaving"
      :country-id="countryId"
      :countries="countryOptionsProp"
      @save="saveNewRegion"
    />
    <new-country-sub-region-modal
      v-model="showNewSubRegionModal"
      :saving="subRegionSaving"
      :region-id="CountryRegionID"
      :regions="selectedCountryRegionDropdownOptions"
      @save="saveNewSubRegion"
    />
    <new-classification-modal
      v-model="showNewClassificationModal"
      :saving="classificationSaving"
      @save="saveNewClassification"
    />
    <new-designation-modal
      v-model="showNewDesignationModal"
      :saving="designationSaving"
      @save="saveNewDesignation"
    />
    <new-vineyard-modal
      v-model="showNewVineyardModal"
      :saving="vineyardSaving"
      @save="saveNewVineyard"
    />
    <confirm-promise ref="confirm">
      <div style="color:red;">
        <p>
          <strong>
            LINKED WINE CARDS ARE GOING TO CHANGE
          </strong>
        </p>
        <p>
          If you want to change only this wine card. Clear the LWIN and save again. You can then apply the new LWIN after.
        </p>
        <p>
          If you want to change all linked wine cards, choose Save All. BUT BE AWARE!! Changing all wine cards will affect
          all the wine cards linked to this LWIN across the whole of Back Office. This could be many wine cards,
          so please stop and check that you really want to do that. If you are not sure discuss with PR or OW
        </p>
        <p>
          If you’ve changed a Wine card already and made a mistake and are about to attempt to fix it,
          <strong>
            STOP! YOU ARE ABOUT TO CHANGE THE HISTORY OF THE WINE CARD AND THE PREVIOUS CHANGE WILL BE LOST IF
            YOU CHANGE THE WINE CARD AGAIN.  UNLESS YOU ARE A 100% CERTAIN ABOUT WHAT YOU NEED TO CHANGE, DO NOT GO ANY FURTHER!
          </strong>
        </p>
      </div>
      <p>
        Linked Wine Cards are going to change. If you want to change only this wine card. Clear the LWIN and save again. You can then apply the new LWIN after.
      </p>

      <template slot="buttonsBefore">
        <b-button size="sm" class="wv-btn" @click.prevent="removeAndSave">
          Remove LWIN & Save
        </b-button>
      </template>
      <template slot="okText">
        Save All
      </template>
    </confirm-promise>
    <confirm-promise ref="confirm2" />
  </b-form>
</template>
<script>
import GroupBox from '~/components/Base/GroupBox'
import ConfirmPromise from '../Modal/ConfirmPromise'
import {
  required,
  minValue,
  maxValue,
  requiredUnless,
  decimal,
  requiredIf,
  integer
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { formObjectMixin } from '../../mixins/forms'
import {
  wineVintageAllowNVValidator,
  isRobertParkerScore,
  maxDecimalPlaces,
  dutyTypeValidForCategory
} from '../../helpers/validators'
import { mapObjectToVModel } from '../../helpers/helpers'
import LiveExButton from '~/components/Base/LiveExButton'
import RobertParkerButton from '~/components/Base/RobertParkerButton'
import WineSearcherButton from '~/components/Base/WineSearcherButton'
import BurghoundButton from '~/components/Base/BurghoundButton'
import PriceConfirmationChange from '../Base/PriceConfirmationChange'
import NewCountryRegionModal from '../Modal/Administration/NewCountryRegionModal'
import NewCountrySubRegionModal from '../Modal/Administration/NewCountrySubRegionModal'
import NewClassificationModal from '../Modal/Administration/NewClassificationModal'
import NewDesignationModal from '../Modal/Administration/NewDesignationModal'
import NewVineyardModal from '../Modal/Administration/NewVineyardModal'
import LwinLookupForm from './LwinLookupForm'
import UserMixin from '~/mixins/user'

export default {
  components: {
    GroupBox,
    LiveExButton,
    RobertParkerButton,
    WineSearcherButton,
    BurghoundButton,
    PriceConfirmationChange,
    NewCountryRegionModal,
    NewCountrySubRegionModal,
    NewClassificationModal,
    NewDesignationModal,
    NewVineyardModal,
    LwinLookupForm,
    ConfirmPromise
  },
  mixins: [validationMixin, formObjectMixin, UserMixin],
  props: {
    value: {
      type: Object,
      default: null,
      required: false
    },
    loadingProp: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    chosenWineName: {
      type: Object,
      required: false,
      default: null
    },
    chosenProducer: {
      type: Object,
      required: false,
      default: null
    },
    formatOptionsProp: {
      type: Array,
      default: () => []
    },
    categoryOptionsProp: {
      type: Array,
      default: () => []
    },
    countryOptionsProp: {
      type: Array,
      default: () => []
    },
    wineColourOptionsProp: {
      type: Array,
      default: () => []
    },
    usageOptionsProp: {
      type: Array,
      default: () => []
    },
    activeCardHasOrders: {
      type: Boolean,
      default: false
    },
    designationOptions: {
      type: Array,
      default: () => []
    },
    classificationOptions: {
      type: Array,
      default: () => []
    },
    countryRegionOptions: {
      type: Array,
      default: () => []
    },
    countrySubRegionOptions: {
      type: Array,
      default: () => []
    },
    vineyardOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      scoresCleared: false,
      countryDisabled: false,
      colourDisabled: false,
      bhDisabled: true,
      showNewRegionModal: false,
      showNewSubRegionModal: false,
      showNewDesignationModal: false,
      showNewClassificationModal: false,
      showNewVineyardModal: false,
      doubleMagRegions: [29],
      methuselahRegions: [3, 20]
    }
  },
  computed: {
    abvVerifyStateVariant() {
      if (this.AbvVerified === 2) {
        return 'success'
      }
      return 'danger'
    },
    abvVerifyStateVariantText() {
      if (this.AbvVerified === 2) {
        return 'Verified'
      }
      return 'Unverified'
    },
    computedWineName() {
      if (this.isEditing) {
        let wineName = this.wine_name
        if (wineName.indexOf(this.value.producer) < 0) {
          wineName += ', ' + this.value.producer
        }
        return wineName
      }
      return ''
    },
    lwinSaving() {
      return this.$store.state.lwinDictionary.saving
    },
    hasOrders() {
      return (
        this.purchase_order_line_usage > 0 || this.sales_order_line_usage > 0
      )
    },
    hasLinkedOrders() {
      if (this.isEditing) {
        return (
          this.value.LinkedPurchaseOrderLinesUsage > 0 ||
          this.value.LinkedSalesOrderLinesUsage > 0
        )
      }
      return false
    },
    selectedCountryRegionDropdownOptions() {
      return this.countryRegionDropdownOptions.filter(c => {
        return c.CountryID === this.countryId
      })
    },
    selectedCountrySubRegionDropdownOptions() {
      return this.countrySubRegionDropdownOptions.filter(c => {
        return c.CountryRegionID === this.CountryRegionID
      })
    },
    designationDropdownOptions() {
      return this.designationOptions.map(d => {
        return {
          value: d.DesignationID,
          text: d.Designation
        }
      })
    },
    classificationDropdownOptions() {
      return this.classificationOptions.map(c => {
        return {
          value: c.ClassificationID,
          text: c.ClassificationName
        }
      })
    },
    vineyardDropdownOptions() {
      return this.vineyardOptions.map(c => {
        return {
          value: c.VineyardID,
          text: c.VineyardName
        }
      })
    },
    wineColourOptions() {
      const options = this.wineColourOptionsProp.map(c => {
        return {
          value: c.WineColourID,
          text: c.WineColour
        }
      })
      options.unshift({
        value: 0,
        text: '<Please Choose>'
      })
      return options
    },
    countryRegionDropdownOptions() {
      return this.countryRegionOptions.map(c => {
        return {
          value: c.CountryRegionID,
          text: c.Region,
          CountryID: c.CountryID
        }
      })
    },
    countrySubRegionDropdownOptions() {
      return this.countrySubRegionOptions.map(c => {
        return {
          value: c.CountrySubRegionID,
          text: c.SubRegion,
          CountryRegionID: c.CountryRegionID
        }
      })
    },
    hasSelectedRegion() {
      return !!this.CountryRegionID
    },
    title() {
      let title = 'Wine'
      if (this.hasChanged) {
        title += ' *'
      }
      return title
    },
    changedClass() {
      return {
        'light-green-background': this.hasChanged
      }
    },
    wineNameFilledOut() {
      return (
        this.wine_name !== null &&
        this.wine_name !== undefined &&
        this.wine_name.length > 0
      )
    },
    livExLwinSelected() {
      return this.livExLwin > 0
    },
    isEditing() {
      return this.value && isNaN(parseInt(this.value.id)) === false
    },
    regionSaving() {
      return this.$store.state.administration.countryregions.saving
    },
    subRegionSaving() {
      return this.$store.state.administration.countrysubregions.saving
    },
    designationSaving() {
      return this.$store.state.administration.designations.saving
    },
    classificationSaving() {
      return this.$store.state.administration.classifications.saving
    },
    vineyardSaving() {
      return this.$store.state.administration.vineyards.saving
    },
    dutyName() {
      if (parseInt(this.dutyTypeId) === 1) {
        return 'Wine'
      }
      if (parseInt(this.dutyTypeId) === 2) {
        return 'Sparkling Wine'
      }
      if (parseInt(this.dutyTypeId) === 3) {
        return 'Port'
      }
      if (parseInt(this.dutyTypeId) === 10) {
        return 'Spirit'
      }
      return 'UNKNOWN'
    },
    formattedYear() {
      return this.year === 0 ? 'NV' : this.year
    },
    originalAppellation() {
      if (this.value && this.value._original) {
        return this.value._original.AppellationName
      }
      return ''
    },
    percentageNoZero: {
      get() {
        if (this.percentage === 0 || this.percentage === '0') {
          return ''
        }
        return this.percentage
      },
      set(value) {
        this.percentage = value
      }
    },
    livExLwinNoZero: {
      get() {
        if (this.livExLwin === 0 || this.livExLwin === '0') {
          return ''
        }
        return this.livExLwin
      },
      set(value) {
        this.livExLwin = value
      }
    },
    colourIdConsistant: {
      get() {
        if (this.colourId === null || this.colourId === undefined) {
          return 0
        }
        return this.colourId
      },
      set(value) {
        this.colourId = value
      }
    },
    linkedAttributesAreDirty() {
      const linkedAttributes = [
        'livExLwin',
        'wineNameId',
        'producerId',
        'countryId',
        'colourId',
        'CountryRegionID',
        'CountrySubRegionID',
        'DesignationID',
        'ClassificationID'
      ]
      return linkedAttributes.filter(attr => this.isDirty(attr)).length > 0
    },
    ...mapObjectToVModel([
      'id',
      'wine_name',
      'wineNameId',
      'producer',
      'producerId',
      'year',
      'format_id',
      'listPrice',
      'mixedCase',
      'bookPrice',
      'categoryId',
      'countryId',
      'colourId',
      'usageCodeId',
      'extract',
      'fda',
      'percentage',
      'ph',
      'so2',
      'description',
      'purchasesMade',
      'salesMade',
      'doNotPromoteAndSellLossMaking',
      'livExLwin',
      'rp',
      'rpTastingNotes',
      'bh',
      'bhTastingNotes',
      'sellAtLoss',
      'doNotPromote',
      'appellation',
      'price_weighted_average',
      'dutyTypeId',
      'purchase_order_line_usage',
      'sales_order_line_usage',
      'DesignationID',
      'ClassificationID',
      'CountryRegionID',
      'CountrySubRegionID',
      'Exception',
      'VineyardID',
      'LivExLWinExempt',
      'AbvVerified',
      'MixedCaseDefaultSize'
    ])
  },
  watch: {
    chosenWineName(wineName) {
      if (wineName) {
        this.wine_name = wineName.Name
        this.wineNameId = wineName.WineNameID
        this.appellation = wineName.AppellationName
        this.dutyTypeId = wineName.DutyTypeID
      } else {
        this.wine_name = null
        this.wineNameId = null
        this.appellation = null
        this.dutyTypeId = null
      }
    },
    chosenProducer(producer) {
      this.producer = producer.ProducerName
      this.producerId = producer.ProducerID
    },
    categoryId(categoryId) {
      this.handleChangeCategory(categoryId)
      this.$v.$touch()
    }
  },
  mounted() {
    this.handleChangeCategory(this.categoryId, true)
    this.$eventHub.$on('set-wine-card-form', this.handleSetWineCard)
    this.$eventHub.$on('lwin-search', this.handleEventHubLwinSearch)
  },
  destroyed() {
    this.$eventHub.$off('lwin-search', this.handleEventHubLwinSearch)
    this.$eventHub.$off('set-wine-card-form', this.handleSetWineCard)
  },
  methods: {
    removeAndSave() {
      this.$refs.confirm.hide()
      this.$refs.lwinForm.clear()
      this.handleSave()
    },
    exitPopover() {
      this.$root.$emit('bv::hide::popover')
    },
    setYear(value) {
      if (value && value.toUpperCase && value.toUpperCase() === 'NV') {
        return 'NV'
      }
      value = parseInt(value)
      if (isNaN(value)) {
        return ''
      }
      if (value < 50) {
        return String(value + 2000)
      }
      if (value >= 50 && value < 100) {
        return String(value + 1900)
      }
      return String(value)
    },
    clearScoresAndNotes() {
      let scoreCleared = false
      if (this.rp !== null && this.rp !== undefined && this.rp.length > 0) {
        this.rp = ''
        scoreCleared = true
      }
      if (
        this.rpTastingNotes !== null &&
        this.rpTastingNotes !== undefined &&
        this.rpTastingNotes.length > 0
      ) {
        this.rpTastingNotes = ''
        scoreCleared = true
      }
      if (
        this.bh !== null &&
        this.bh !== undefined &&
        this.bh.length > 0 &&
        this.bh !== '0'
      ) {
        this.bh = ''
        scoreCleared = true
      }
      if (
        this.bhTastingNotes !== null &&
        this.bhTastingNotes !== undefined &&
        this.bhTastingNotes.length > 0
      ) {
        this.bhTastingNotes = ''
        scoreCleared = true
      }
      this.percentage = ''
      this.AbvVerified = null
      if (scoreCleared) {
        this.$emit('scores-cleared')
      }
    },
    vintageBlur() {
      this.year = this.setYear(this.year)
      if (this.isDirty('year')) {
        this.clearScoresAndNotes()
      }
    },
    clearProducer() {
      this.producer = null
      this.producerId = 0
    },
    handleChangeCategory(id, ignoreNullColourChange) {
      const category = this.$store.getters['lists/getCategoryByValue'](id)
      if (category && category.category) {
        if (this.livExLwinSelected === false) {
          this.countryDisabled = category.category.CountryID > 0
          this.countryId = category.category.CountryID
          this.colourDisabled = category.category.WineColourID > 0
          if (
            ignoreNullColourChange !== true ||
            category.category.WineColourID !== null
          ) {
            this.colourId = category.category.WineColourID
          }
        }
        this.bhDisabled = !category.category.BurghoundScorable
      }
    },
    addFormatModal() {
      this.$emit('add-wine-card-format')
    },
    afterValidate() {
      if (this.bhDisabled) {
        this.bh = ''
      }
      if (
        this.livExLwin === null ||
        this.livExLwin === undefined ||
        this.livExLwin.length === 0
      ) {
        this.livExLwin = 0
      }
      this.triggerValueEvents()
    },
    handleDisabledClick() {
      this.$emit('disabled-link-click')
    },
    showNewRegion() {
      this.showNewRegionModal = true
    },
    showNewSubRegion() {
      this.showNewSubRegionModal = true
    },
    showNewDesignation() {
      this.showNewDesignationModal = true
    },
    showNewClassification() {
      this.showNewClassificationModal = true
    },
    showNewVineyard() {
      this.showNewVineyardModal = true
    },
    handleChangeAbvVerifiedState() {
      switch (this.AbvVerified) {
        case 0:
        case 1:
          this.AbvVerified = 2
          break
        case 2:
          this.AbvVerified = 0
          break
      }
    },
    async saveNewRegion(region) {
      try {
        const savedRegion = await this.$store.dispatch(
          'administration/countryregions/add',
          region
        )
        this.showNewRegionModal = false
        this.CountryRegionID = savedRegion.CountryRegionID
        this.$store.commit('lists/appendCountryRegion', savedRegion)
      } catch (e) {
        this.$notifyError(e)
      }
    },
    async saveNewSubRegion(region) {
      try {
        const savedRegion = await this.$store.dispatch(
          'administration/countrysubregions/add',
          region
        )
        this.showNewSubRegionModal = false
        this.CountrySubRegionID = savedRegion.CountrySubRegionID
        this.$store.commit('lists/appendCountrySubRegion', savedRegion)
      } catch (e) {
        this.$notifyError(e)
      }
    },
    async saveNewDesignation(desc) {
      try {
        const savedDesignation = await this.$store.dispatch(
          'administration/designations/add',
          desc
        )
        this.showNewDesignationModal = false
        this.DesignationID = savedDesignation.DesignationID
        this.$store.commit('lists/appendDesignation', savedDesignation)
      } catch (e) {
        this.$notifyError(e)
      }
    },
    async saveNewClassification(classification) {
      try {
        const savedClassification = await this.$store.dispatch(
          'administration/classifications/add',
          classification
        )
        this.showNewClassificationModal = false
        this.ClassificationID = savedClassification.ClassificationID
        this.$store.commit('lists/appendClassification', savedClassification)
      } catch (e) {
        this.$notifyError(e)
      }
    },
    async saveNewVineyard(vineyard) {
      try {
        const savedVineyard = await this.$store.dispatch(
          'administration/vineyards/add',
          vineyard
        )
        this.showNewVineyardModal = false
        this.VineyardID = savedVineyard.VineyardID
        this.$store.commit('lists/appendVineyard', savedVineyard)
      } catch (e) {
        this.$notifyError(e)
      }
    },
    handleEventHubLwinSearch(data) {
      this.handleSelectedLWIN(data)
    },
    handleSetWineCard(data) {
      const keys = Object.keys(data)
      keys.forEach(k => {
        this[k] = data[k]
      })
    },
    async handleSelectedLWIN(item) {
      try {
        const response = await this.$store.dispatch(
          'lwinDictionary/importCode',
          item
        )
        if (response.Region) {
          this.$store.commit('lists/appendCountryRegion', response.Region)
        }
        if (response.SubRegion) {
          this.$store.commit('lists/appendCountrySubRegion', response.SubRegion)
        }
        if (response.Designation) {
          this.$store.commit('lists/appendDesignation', response.Designation)
        }
        if (response.Classification) {
          this.$store.commit(
            'lists/appendClassification',
            response.Classification
          )
        }

        this.$store.commit('lists/appendWineColour', response.WineColour)
        if (this.isEditing === false) {
          this.year = item.Vintage
        }
        this.percentageNoZero = response.Abv
        this.AbvVerified = response.AbvVerified
        this.wine_name = response.WineName.Name
        this.wineNameId = response.WineName.WineNameID
        this.appellation = response.WineName.AppellationName
        this.producer = response.Producer.ProducerName
        this.producerId = response.Producer.ProducerID
        this.dutyTypeId = response.WineName.DutyTypeID
        this.countryId = response.Country.CountryID
        this.colourId = response.WineColour.WineColourID
        if (response.Region) {
          this.CountryRegionID = response.Region.CountryRegionID
        } else {
          this.CountryRegionID = 0
        }
        if (response.SubRegion) {
          this.CountrySubRegionID = response.SubRegion.CountrySubRegionID
        } else {
          this.CountrySubRegionID = 0
        }
        if (response.Designation) {
          this.DesignationID = response.Designation.DesignationID
        } else {
          this.DesignationID = 0
        }
        if (response.Classification) {
          this.ClassificationID = response.Classification.ClassificationID
        } else {
          this.ClassificationID = 0
        }
        this.$emit('updated-using-lwin', response)
      } catch (e) {
        this.$notifyError(e)
      }
    },
    async handleSave() {
      if (
        this.isEditing &&
        this.linkedAttributesAreDirty &&
        this.livExLwinNoZero !== '' &&
        this.livExLwinNoZero !== null &&
        this.value.livExLwin !== '' &&
        this.value.livExLwin !== null &&
        this.value.livExLwin !== 0 &&
        this.value.LinkedCardCount > 0
      ) {
        const wantToChange = await this.$refs.confirm.confirm(
          'STOP. Changing Linked Wine Cards',
          '',
          false
        )
        if (wantToChange === false) {
          return
        }
      }

      if (this.isEditing && (this.hasOrders || this.hasLinkedOrders)) {
        let linkedMessage =
          'This wine card has orders against it. Are you really sure you want to change this wine card?'
        if (this.hasOrders && this.hasLinkedOrders) {
          linkedMessage =
            'This wine card and linked wine cards have orders against them. Are you really sure you want to change this and the linked wine cards?'
        } else if (this.hasLinkedOrders) {
          linkedMessage =
            'Linked wine cards have orders against them. Are you really sure you want to change this and linked wine cards?'
        }
        const wantToChange = await this.$refs.confirm2.confirm(
          'STOP. Changing Wine Cards',
          linkedMessage,
          false
        )
        if (wantToChange === false) {
          return
        }
      }

      this.$emit('clickSave')
    }
  },
  validations() {
    return {
      wineNameId: {
        required
      },
      year: {
        required,
        wineVintageAllowNVValidator
      },
      listPrice: {
        required,
        decimal,
        maxDecimalPlaces: maxDecimalPlaces(2)
      },
      categoryId: {
        required,
        dutyTypeInvalid: dutyTypeValidForCategory(this.dutyTypeId)
      },
      livExLwin: {
        decimal,
        minValue: minValue(0)
      },
      rp: {
        required,
        isRobertParkerScore: isRobertParkerScore(false)
      },
      bh: {
        required: requiredUnless('bhDisabled'),
        isBHScore: isRobertParkerScore(true)
      },
      format_id: {
        required,
        minValue: minValue(1),
        regionCheck: function() {
          if (this.CountryRegionID) {
            if (
              this.format_id === 13 && // imp
              this.methuselahRegions.indexOf(this.CountryRegionID) >= 0
            ) {
              return false
            }
            if (
              this.format_id === 17 && // jero3
              this.doubleMagRegions.indexOf(this.CountryRegionID) >= 0
            ) {
              return false
            }
            if (
              this.format_id === 5 && // jero
              this.methuselahRegions.indexOf(this.CountryRegionID) >= 0
            ) {
              return false
            }
          }
          return true
        }
      },
      usageCodeId: {
        required
      },
      percentage: {
        decimal,
        minValue: minValue(0),
        maxValue: maxValue(100)
      },
      MixedCaseDefaultSize: {
        requiredIf: requiredIf(function() {
          return this.mixedCase === true
        }),
        integer
      }
    }
  }
}
</script>

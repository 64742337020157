/* eslint-disable no-unused-vars */
import { getDutyTypes } from '@/api/administration/dutytypes'
import { find } from 'lodash'

export const state = () => ({
  loading: false,
  list: []
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  resetList(state) {
    state.list = []
  }
}

export const getters = {
  getDutyTypeList(state) {
    return state.list
  },
  getDutyTypeNameFromID(state) {
    return DutyTypeID => {
      const DutyType = find(state.list, { DutyTypeID })
      if (DutyType !== undefined) {
        return DutyType.DutyTypeName
      }
      return ''
    }
  }
}

export const actions = {
  async loadList({ commit, state }) {
    if (state.list.length > 0) return
    commit('setLoading', true)
    const response = await getDutyTypes()
    commit('setList', response)
    commit('setLoading', false)
  }
}

import { client } from '../graphQLInstance'
import SalesOrderSearch from '~/models/salesordersearch'

export const getSearchResults = async (page, perPage, searchParameters) => {
  const result = await client.query({
    query: SalesOrderSearch.query('get', {
      ...searchParameters,
      Page: page,
      PerPage: perPage
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[SalesOrderSearch.queries.get.name]
}

import Entity from './entity'

const fields = {
  WorkflowStatusDescription: {
    type: String
  },
  WorkflowStatusID: {
    type: Number
  }
}

const WorkFlowStatus = new Entity('WorkflowDropDown', fields)
WorkFlowStatus.createQuery('get', 'workflowDropDown')

export default WorkFlowStatus

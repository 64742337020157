import Entity from './entity'

const fields = {
  CountryCode: {
    type: String
  },
  CountryName: {
    type: String
  }
}

const IsoCountryDropDown = new Entity('IsoCountryDropDown', fields)
IsoCountryDropDown.createQuery('get', 'iSOCountryDropDown')
IsoCountryDropDown.createQuery('getInstastat', 'iSOCountryIntrastatDropDown')
export default IsoCountryDropDown

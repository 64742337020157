import Vue from 'vue'

import { each } from 'lodash'

export const state = () => ({
  brokingContacts: [],
  brokingContactsIndex: {}
})

export const mutations = {
  appendBrokingContact(state, brokingContact) {
    const currentIndex = state.brokingContactsIndex[brokingContact.ContactID]
    if (currentIndex !== undefined) {
      Vue.set(state.brokingContacts, currentIndex, brokingContact)
    } else {
      const currentLength = state.brokingContacts.length
      state.brokingContacts.push(brokingContact)
      Vue.set(
        state.brokingContactsIndex,
        brokingContact.ContactID,
        currentLength
      )
    }
  },
  removeBrokingContactById(state, ContactID) {
    const currentIndex = state.brokingContactsIndex[ContactID]
    if (currentIndex !== undefined) {
      Vue.delete(state.brokingContacts, currentIndex)
      state.brokingContactsIndex = {}
      each(state.brokingContacts, (brokingContact, idx) => {
        Vue.set(state.brokingContactsIndex, brokingContact.ContactID, idx)
      })
    }
  }
}

export const actions = {
  appendBrokingContact({ commit }, brokingContact) {
    commit('appendBrokingContact', brokingContact)
  },
  closeBrokingContact({ commit }, brokingContact) {
    commit('removeBrokingContactById', brokingContact)
  }
}
